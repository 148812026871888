import { useOrganization, useOrganizationList } from "@clerk/clerk-react";
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useMemo, useRef, useState, useEffect } from "react";
import { capitalizeFirstLetter } from "../../utils/TextUtils";
import { useVetRec } from "../../providers/VetRecProvider";

interface TeamPickerProps {
  onCreateTeamClicked: () => void;
}

export function TeamPicker({ onCreateTeamClicked }: TeamPickerProps) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { setActive, userMemberships } = useOrganizationList({
    userMemberships: {
      pageSize: 500
    }
  })
  const [query, setQuery] = useState<string>()
  const { organization } = useOrganization()
  const { createMultipleTeamsEnabled } = useVetRec()

  const organizations = useMemo(() => {
    let teams = userMemberships.data?.map((membership) => membership.organization) || []
    if (query) {
      teams = teams.filter((team) => team.name.toLowerCase().includes(query.toLowerCase()))
    }
    return teams.sort((a, b) => a.name.localeCompare(b.name))
  }, [userMemberships, query])

  const canOpen =  useMemo(() => {
    return (userMemberships.data && userMemberships.data.length > 1) || createMultipleTeamsEnabled
  }, [userMemberships, createMultipleTeamsEnabled])

  const selectOrg = (team: any) => {
    if (setActive) {
      setActive({ organization: team })
      setQuery(undefined)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleToggle = () => setIsOpen(!isOpen)

  const handleSelect = (option: any) => {
    selectOrg(option)
    setIsOpen(false)
  }

  const handleCreateTeam = () => {
    onCreateTeamClicked()
    setIsOpen(false)
  }

  return (
    <div className="relative w-full" ref={dropdownRef}>
      {isOpen && (
        <div className="absolute bottom-full left-0 right-0 z-10 w-full mb-4 bg-white rounded-md shadow-lg border-gray-300 border">
          <div className="px-1 pt-2">
            <div className="relative">
              <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                type="text"
                className="w-full pl-10 pr-3 py-2 text-sm leading-5 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500 h-8"
                placeholder="Find team"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          <ul
            className="py-2 overflow-auto text-base max-h-56 focus:outline-none sm:text-sm thin-scrollbar"
            role="listbox"
          >
            {organizations.map((team, teamidx) => (
              <li
                key={teamidx}
                className="relative py-2 px-2 text-gray-700 cursor-default select-none hover:bg-gray-300 flex flex-row gap-x-3 items-center"
                role="option"
                onClick={() => handleSelect(team)}
                title={team.name}
              >
                <img src={team.imageUrl} className="w-6 rounded-full" />
                <span className="block truncate">{capitalizeFirstLetter(team.name)}</span>
                {team.id === organization?.id && <CheckIcon className="h-6 w-8" />}
              </li>
            ))}
            {organizations.length === 0 && (
              <li className="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9">
                No options found
              </li>
            )}
          </ul>
          {createMultipleTeamsEnabled && <button
            className="flex flex-row gap-x-3 items-center w-full px-4 py-2 text-sm text-blue-500 bg-blue-50 hover:bg-blue-100"
            onClick={handleCreateTeam}
          >
            <PlusCircleIcon className="h-6 w-6 inline-block" />
            Create New Team
          </button>}
        </div>
      )}
      {organization && <div className="relative">
        <button
          type="button"
          className={`flex items-center w-full px-4 py-2 text-sm font-medium text-left rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 h-10 gap-x-2 ${canOpen ? 'cursor-pointer hover:bg-gray-50/20 justify-between' : 'justify-center'}`}
          onClick={handleToggle}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          disabled={!canOpen}
        >
          <img src={organization.imageUrl} className="w-8" />
          <span className="block truncate text-white">
            {organization.name}
          </span>
          {canOpen && <ChevronUpDownIcon className="w-8 h-8 ml-2 -mr-1 text-white stroke-2" aria-hidden="true" />}
        </button>
      </div>}
    </div>
  )
}
