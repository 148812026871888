import { useCallback, useState } from "react";
import { Tag, Team } from "../types/user";
import TeamBadge from "./TeamBadge";
import TeamSelector from "./TeamSelector";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";

interface TagRowProps {
    tag: Tag;
    teams: Team[];
    onEdit: (newName: string) => void;
    onDelete: () => void;
    onUpdateTeams: (tag:Tag, teamsToAdd:string[], teamsToRemove:string[]) => Promise<void>;
    getTeamById: (teamId: string) => Team | undefined;
}

const TagRow: React.FC<TagRowProps> = ({ tag, teams, onEdit, onDelete, onUpdateTeams, getTeamById }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState(tag.name);

    const handleSave = useCallback(() => {
        if (editName.trim()) {
            onEdit(editName);
            setIsEditing(false);
        }
    }, [editName, onEdit]);

    // Handle team selection changes
    const handleTeamToggle = useCallback(async (teamsToAdd:string[], teamsToRemove:string[]) => {
        await onUpdateTeams(tag, teamsToAdd, teamsToRemove);
    }, [onUpdateTeams, tag]);

    return (
        <tr style={{ position: 'relative' }}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {isEditing ? (
                    <input
                        type="text"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                        autoFocus
                    />
                ) : (
                    tag.name
                )}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500">
                <div className="flex flex-wrap gap-2">
                    {tag.teams.map((teamId) => {
                        const team = getTeamById(teamId);
                        return team && (
                            <TeamBadge
                                key={teamId}
                                team={team}
                            />
                        );
                    })}
                </div>
            </td>
            <td className="px-3 py-4 text-sm text-gray-500">
                <div className="flex flex-wrap gap-2">
                    {tag.users.length}
                </div>
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex gap-x-2 justify-end">
                <div className="flex justify-end space-x-2">
                    <div>
                        <TeamSelector
                            teams={teams}
                            selectedTeams={tag.teams}
                            onSelectTeam={handleTeamToggle}
                        />
                    </div>
                </div>
                {isEditing ? (
                    <div className="flex space-x-2 justify-end">
                        <button
                            type="button"
                            onClick={handleSave}
                            className="p-2 rounded-md bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            <CheckIcon className="h-4 w-4 text-white" aria-hidden="true" />
                            <span className="sr-only">Save</span>
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setIsEditing(false);
                                setEditName(tag.name);
                            }}
                            className="p-2 rounded-md bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                            <XMarkIcon className="h-4 w-4 text-white" aria-hidden="true" />
                            <span className="sr-only">Cancel</span>
                        </button>
                    </div>
                ) : (
                    <div className="flex space-x-2 justify-end">
                        <button
                            type="button"
                            onClick={() => setIsEditing(true)}
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 p-2 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                            <span className="sr-only">Edit</span>
                        </button>
                        <button
                            type="button"
                            onClick={onDelete}
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 p-2 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                            <span className="sr-only">Delete</span>
                        </button>
                    </div>
                )}
            </td>
        </tr>
    );
};

export default TagRow;