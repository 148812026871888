/**
 * Converts a JavaScript object to a JSON file
 * @param data The object to convert to a JSON file
 * @param filename The name of the file to create
 * @param fileType The MIME type of the file (default: 'text/plain')
 * @returns A File object representing the JSON data
 */
export function objectToJsonFile<T>(
    data: T,
    filename: string,
    fileType: string = 'text/plain'
): File {
    // Replace undefined values with null for proper JSON serialization
    const replacer = (key: any, value: any) => value === undefined ? null : value;
    
    // Convert the object to a JSON string
    const jsonString = JSON.stringify(data, replacer);
    
    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: fileType });
    
    // Create and return a File from the Blob
    return new File([blob], filename, { type: fileType });
}