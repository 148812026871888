import React from 'react';

interface BulkUploadSectionProps {
    fileInputRef: React.RefObject<HTMLInputElement>;
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onCancel?: () => void;
}

const BulkUploadSection: React.FC<BulkUploadSectionProps> = ({
    fileInputRef, 
    handleFileUpload,
    onCancel
}) => {
    return (
        <div>
            <div className="mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Upload File
                </label>
                <p className="text-sm text-gray-500 mb-4">
                    Upload a CSV or Excel file containing at minimum: email column. Optionally include teams and role.
                </p>
                <div className="flex items-center justify-center w-full">
                    <label className="flex flex-col w-full h-32 border-2 border-dashed rounded-md border-gray-300 cursor-pointer hover:bg-gray-50">
                        <div className="flex flex-col items-center justify-center pt-7">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                            <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                {fileInputRef.current?.files?.length
                                    ? fileInputRef.current.files[0].name
                                    : 'Upload a CSV or Excel file'}
                            </p>
                        </div>
                        <input
                            ref={fileInputRef}
                            type="file"
                            className="opacity-0"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={handleFileUpload}
                        />
                    </label>
                </div>
            </div>

            <div className="flex justify-between space-x-3 items-center">
                <a
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    href="https://auth.vetworks.io/storage/v1/object/sign/assets/VetRec/VetRec%20-%20Bulk%20Invite%20Template.csv?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJhc3NldHMvVmV0UmVjL1ZldFJlYyAtIEJ1bGsgSW52aXRlIFRlbXBsYXRlLmNzdiIsImlhdCI6MTc0MjUxODQ4MiwiZXhwIjoyMDU3ODc4NDgyfQ.u0fLdXAQSSjlBC-JMqvmjbSekZsOLRV180zoqjjVD6M"
                >
                    Sample CSV
                </a>
                {onCancel && (
                    <button
                        type="button"
                        onClick={onCancel}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                        Cancel
                    </button>
                )}
            </div>
        </div>
    );
};

export default BulkUploadSection;
