import { Confirmation } from "./ConsentsUtils"
import { MemberObject } from "./MemberUtils"

export interface SessionStatus {
    recording:string,
    transcript:string,
    notes:string,
    comms:string,
    manual_notes:string
    discharge?:string
    consents?:string
    general?:string
    prehistory?: string
    recording_files?:boolean
    recording_backup?:boolean
}

export interface PreHistoryMetadata {
    version?: string,
    num_merges_required?: number,
    template_id?: string,
}

export interface OnPremIntegrationMetadata {
    integration_id?: string,
    remote_id?: string, // Appointment ID
    provider_id?: string, // Doctor or Staff Member ID
    contact_id?: string, // Patient Owner ID
    animal_id?: string, // Patient ID
    note_remote_id?: string // ID of notes after they've been transferred to PIMS
}

export interface SessionObject {
    id:string,
    consent?:boolean
    summary?:string,
    consents?: Confirmation[],
    created_timestamp:number,
    modified_timestamp:number,
    name:string,
    pets:string[],
    pet_to_soap_node_mapping?:{[key:string]:string},
    pet_to_discharge_node_mapping?:{[key:string]:string},
    organization?:string,
    owner:string,
    owner_id?:string,
    has_metagraph:boolean,
    status:SessionStatus,
    prehistory_metadata?: PreHistoryMetadata,
    on_prem_integration_metadata?:OnPremIntegrationMetadata,
    source?:string,
    language?:string,
    spoken_language?:string,
    template_id?:string,
    external_patient_id?:string,
    external_visit_id?:string,
    external_record_id?:string
    external_template_id?:string,
    display_owner?:string
    diagram?:string 
}

export interface AuditLogs {
    item_id:string,
    item_type:string,
    action: string,
    actor:string,
    timestamp:number,
    metadata: {},
    is_impersonation:boolean
}

export interface RecordingTranscriptPair {
    recording_id: string;
    recording_timestamp?: string;
    recording_url?: string;
    transcript_id?: string;
    transcript_status: string;
    transcript_title?: string;
    transcript_content: string;
}

export enum SessionStateEnum {
    ALL = "All",
    NOT_COMPLETED = "Not Completed",
    COMPLETED = "Completed",
}

export function displaySessionsInOrder(sessions: SessionObject[]) {
    const nonCancelledSession = sessions.filter((session) => session.status.recording !== "Cancelled")
    const sortedSessions = nonCancelledSession.sort((a, b) => b.created_timestamp - a.created_timestamp);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today.getTime() - 86400000); // 24 hours in milliseconds
    
    // Calculate the start of this week (assuming the week starts on Sunday)
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(today.getDate() - today.getDay());
    
    // Calculate the start of last week
    const lastWeekStart = new Date(thisWeekStart);
    lastWeekStart.setDate(thisWeekStart.getDate() - 7);

    // Calculate the start of this month
    const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);

    let buckets: SessionObject[]  = []

    sortedSessions.forEach(session => {
        const sessionDate = new Date(session.created_timestamp * 1000); // Convert Unix timestamp to JavaScript Date object
        if (sessionDate >= today) {
            buckets.push(session);
        } else if (sessionDate >= yesterday && sessionDate < today) {
            buckets.push(session);
        } else if (sessionDate >= thisWeekStart && sessionDate < today) {
            buckets.push(session);
        } else if (sessionDate >= lastWeekStart && sessionDate < thisWeekStart) {
            buckets.push(session);
        } else if (sessionDate >= thisMonthStart) {
            buckets.push(session);
        } else {
            buckets.push(session);
        }
    });

    return buckets;
}

export function filterCancelledSessions(sessions:SessionObject[]){
    const nonCancelledSession = sessions.filter((session) => session.status.general !== "Cancelled")
    return nonCancelledSession
}

export function filterSessions(sessions: SessionObject[], userSearch: MemberObject | undefined, sortLatest:boolean, filterState: SessionStateEnum) {

    let matchedSession = sessions.filter(session => {
        // Check if userSearch is provided and not equal to "All doctors"
        const isUserSearchValid = userSearch && userSearch.identifier.toLowerCase() !== "all doctors";
        const displayOwnerMatch = isUserSearchValid && session.display_owner ? (session.display_owner.toLowerCase().includes(userSearch.identifier.toLowerCase())) : false;
        const ownerMatch = isUserSearchValid && ((!session.display_owner ? session.owner.toLowerCase().includes(userSearch.identifier.toLowerCase()) : false) || (userSearch.clerk_id ? session.owner_id?.toLowerCase().includes(userSearch.clerk_id.toLowerCase()) : false));
        const matchesUserSearch = isUserSearchValid ? (displayOwnerMatch || ownerMatch): true;
        return matchesUserSearch;
    });

    if (filterState === SessionStateEnum.NOT_COMPLETED) {
        matchedSession = matchedSession.filter(session => session.status.general?.toLowerCase() !== "completed");
    } else if (filterState === SessionStateEnum.COMPLETED) {
        matchedSession = matchedSession.filter(session => session.status.general?.toLowerCase() === "completed");
    }

    if (sortLatest) {
        return matchedSession
    } else {
        return matchedSession.sort((a, b) => a.created_timestamp - b.created_timestamp);
    }
}

export function displaySessionsInOrderInBuckets(sessions: SessionObject[]) {
    const sortedSessions = sessions.sort((a, b) => b.created_timestamp - a.created_timestamp);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today.getTime() - 86400000); // 24 hours in milliseconds
    
    // Calculate the start of this week (assuming the week starts on Sunday)
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(today.getDate() - today.getDay());
    
    // Calculate the start of last week
    const lastWeekStart = new Date(thisWeekStart);
    lastWeekStart.setDate(thisWeekStart.getDate() - 7);

    // Calculate the start of this month
    const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);

    let buckets: { [key: string]: SessionObject[] } = {
        today: [],
        yesterday: [],
        thisWeek: [],
        lastWeek: [],
        thisMonth: [],
        catchAll: []
    };

    sortedSessions.forEach(session => {
        const sessionDate = new Date(session.created_timestamp * 1000); // Convert Unix timestamp to JavaScript Date object
        if (sessionDate >= today) {
            buckets.today.push(session);
        } else if (sessionDate >= yesterday && sessionDate < today) {
            buckets.yesterday.push(session);
        } else if (sessionDate >= thisWeekStart && sessionDate < today) {
            buckets.thisWeek.push(session);
        } else if (sessionDate >= lastWeekStart && sessionDate < thisWeekStart) {
            buckets.lastWeek.push(session);
        } else if (sessionDate >= thisMonthStart) {
            buckets.thisMonth.push(session);
        } else {
            buckets.catchAll.push(session);
        }
    });

    return buckets;
}
