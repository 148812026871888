import config from '../config.json'

const url = import.meta.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

export async function updateUserMetadata(metadata:{[key:string]: string | boolean}, action:string, jwt:string) {
    const endpoint = url + '/v1/update_member_metadata';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ metadata, action })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function updateOrganizationMetadata(metadata:{[key:string]: string | boolean | undefined | null}, action:string, jwt:string) {
    const endpoint = url + '/v1/update_organization_metadata';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ metadata, action })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}