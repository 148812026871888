import React, { useState, useEffect, useCallback } from 'react';
import { User, UserStatus, Tag } from './types/user';
import { UserRole } from '../settings/UserRoles';
import { useAdmin } from '../../providers/AdminProvider';
import UserInviteForm from './subcomponents/UserInviteForm';
import UserFilters from './subcomponents/UserFilters';
import UserTable from './subcomponents/UserTable';
import BulkActions from './subcomponents/BulkActions';
import Reminder from '../ReminderToast';
import { getAlertBackgroundColor, getAlertIcon, getAlertIconColor } from './utils/adminUtils';

const UserManagement: React.FC = () => {
  const { users, teams, tags, updateUserRole, bulkUpdateUsersTag, bulkUpdateUsersTeams, hideAlertBanner, alertBanner, alertMessage, alertTitle, alertType } = useAdmin()
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterTeam, setFilterTeam] = useState('');
  const [filterTag, setFilterTag] = useState('');
  const [filterRole, setFilterRole] = useState('');
  const [filterStatus, setFilterStatus] = useState('active');
  const [selectAll, setSelectAll] = useState(false);
  const [showInviteForm, setShowInviteForm] = useState(false);

  // Filter users when filter criteria change
  useEffect(() => {
    let result = users;

    if (filterName) {
      result = result.filter(user =>
        user.name && user.name.toLowerCase().includes(filterName.toLowerCase())
      );
    }

    if (filterEmail) {
      result = result.filter(user =>
        user.email.toLowerCase().includes(filterEmail.toLowerCase())
      );
    }

    if (filterTeam) {
      result = result.filter(user =>
        user.teams.includes(filterTeam)
      );
    }

    if (filterTag) {
      result = result.filter(user =>
        user.tags.includes(filterTag)
      );
    }

    if (filterRole) {
      result = result.filter(user =>
        user.role === filterRole as UserRole
      );
    }

    if (filterStatus) {
      result = result.filter(user =>
        user.status === filterStatus as UserStatus
      );
    }

    setFilteredUsers(result);
  }, [users, filterName, filterEmail, filterTeam, filterTag, filterRole, filterStatus]);

  // Handle "select all" functionality
  useEffect(() => {
    if (selectAll) {
      setSelectedUsers(filteredUsers
        .filter(user => user.status !== UserStatus.Invited)
        .map(user => user.id));
    } else {
      setSelectedUsers([]);
    }
  }, [selectAll, filteredUsers]);

  const handleSelectUser = useCallback((userId: string) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  }, [selectedUsers]);

  const handleToggleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const clearFilters = () => {
    setFilterName('');
    setFilterEmail('');
    setFilterTeam('');
    setFilterTag('');
    setFilterRole('');
    setFilterStatus('');
  };

  const handleInviteSuccess = () => {
    setShowInviteForm(false);
  };

  const handleCancelInvite = () => {
    setShowInviteForm(false);
  };

  // New function to directly update a user's teams
  const handleUpdateUserTeams = async (usersToUpdate: User[] | User, teamsToAdd: string[], teamsToRemove: string[]) => {
    const usersArray = Array.isArray(usersToUpdate) ? usersToUpdate : [usersToUpdate];
    await bulkUpdateUsersTeams(usersArray, teamsToAdd, teamsToRemove);
  };

  // New function to directly update a user's tags
  const handleUpdateUserTags = async (usersToUpdate: User[] | User, tagToAdd: Tag) => {

    const userToUpdateArray = Array.isArray(usersToUpdate) ? usersToUpdate : [usersToUpdate];

    // TODO check if tag change will leave any teams without any users or without admins

    await bulkUpdateUsersTag(userToUpdateArray, tagToAdd);
  };

  // Add function to handle role updates
  const handleUpdateUsersRole = async (users: User[], newRole: UserRole) => {
    await updateUserRole(users, newRole);
  };

  const handleClearSelection = () => {
    setSelectedUsers([]);
    setSelectAll(false);
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-4 py-8">
      <Reminder show={alertBanner} hide={hideAlertBanner} text={alertMessage} title={alertTitle} iconColor={getAlertIconColor(alertType)} icon={getAlertIcon(alertType)} lineColor={getAlertBackgroundColor(alertType)} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all users in your organization including their name, email, team and tag assignments.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => setShowInviteForm(!showInviteForm)}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
          >
            {showInviteForm ? 'Cancel Invite' : 'Invite User'}
          </button>
        </div>
      </div>

      {/* User Invite Form */}
      {showInviteForm && (
        <div className="mt-6">
          <UserInviteForm
            onInviteSuccess={handleInviteSuccess}
            onCancel={handleCancelInvite}
          />
        </div>
      )}

      {/* Filters */}
      {!showInviteForm && (
        <UserFilters
          filterName={filterName}
          filterEmail={filterEmail}
          filterTeam={filterTeam}
          filterTag={filterTag}
          filterRole={filterRole}
          filterStatus={filterStatus}
          teams={teams}
          tags={tags}
          onFilterNameChange={setFilterName}
          onFilterEmailChange={setFilterEmail}
          onFilterTeamChange={setFilterTeam}
          onFilterTagChange={setFilterTag}
          onFilterRoleChange={setFilterRole}
          onFilterStatusChange={setFilterStatus}
          onClearFilters={clearFilters}
        />
      )}

      {/* Bulk actions */}
      {!showInviteForm && selectedUsers.length > 0 && (
        <BulkActions
          selectedUsers={users.filter(user => selectedUsers.includes(user.id))}
          selectedUsersCount={selectedUsers.length}
          teams={teams}
          tags={tags}
          onBulkAddTeam={handleUpdateUserTeams}
          onBulkAddTag={handleUpdateUserTags}
          onBulkUpdateRole={handleUpdateUsersRole}
          clearSelection={() => handleClearSelection()}
        />
      )}

      {/* Users table */}
      {!showInviteForm && (
        <UserTable
          users={filteredUsers}
          selectedUsers={selectedUsers}
          selectAll={selectAll}
          teams={teams}
          tags={tags}
          onSelectAll={handleToggleSelectAll}
          onSelectUser={handleSelectUser}
          onUpdateUserTeams={handleUpdateUserTeams}
          onUpdateUserTags={handleUpdateUserTags}
          onUpdateUserRole={handleUpdateUsersRole}
        />
      )}
      {!showInviteForm && <div className='flex justify-end'>
        <div className='text-sm text-gray-700 mt-2 px-2 bg-white shadow-sm border border-gray-300 rounded-md py-1'>
          <span className='font-semibold'>User Count:</span> {filteredUsers.length}
        </div>
      </div>}
    </div>
  );
};

export default UserManagement;
