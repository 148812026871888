import React, { useState } from 'react';
import { Tag, Team } from './types/user';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useAdmin } from '../../providers/AdminProvider';
import TagRow from './subcomponents/TagRow';
import Reminder from '../ReminderToast';
import { getAlertBackgroundColor, getAlertIcon, getAlertIconColor } from './utils/adminUtils';
import { Spinner } from '../../utils/Spinner';
import ConfirmationModal from './subcomponents/ConfirmationModal';
import CreateTagModal from './subcomponents/CreateTagModal';

const TagManagement: React.FC = () => {
    const { tags, teams, createTag, updateTag, deleteTag, bulkUpdateTagTeams, alertBanner, alertMessage, alertTitle, alertType, hideAlertBanner, loading } = useAdmin();
    const [isAddingTag, setIsAddingTag] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState<string>();
    const [confirmMessage, setConfirmMessage] = useState<string>();
    const [confirmAction, setConfirmAction] = useState<(() => Promise<void>)>();

    const handleEditTag = async (tag: Tag, newName: string) => {
        if (newName.trim()) {
            await updateTag(tag, newName);
        }
    };

    const handleDeleteTag = async (tag: Tag) => {
        setConfirmTitle('Delete Tag');
        setConfirmMessage(`Are you sure you want to delete tag "${tag.name}"?`);
        setConfirmAction(() => async () => {
            await deleteTag(tag);
            setConfirmTitle(undefined);
            setConfirmMessage(undefined);
            setConfirmAction(undefined);
        });
    };

    const handleUpdateTagTeams = async (tag: Tag, teamsToAdd: string[], teamsToRemove: string[]) => {
        // Check if teams to remove include all teams in the tag
        await bulkUpdateTagTeams(tag, teamsToAdd, teamsToRemove);
    };

    const getTeamById = (teamId: string): Team | undefined => {
        return teams.find(team => team.id === teamId);
    };

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-4 py-8">
            <CreateTagModal showModal={isAddingTag} hideModal={() => setIsAddingTag(false)} />
            {confirmAction !== undefined && <ConfirmationModal showModal={confirmTitle !== undefined && confirmAction !== undefined} confirmTitle={confirmTitle} confirmMessage={confirmMessage} confirmAction={confirmAction} rejectAction={() => { setConfirmTitle(undefined); setConfirmMessage(undefined); setConfirmAction(undefined) }} color='red'/>}
            <Reminder show={alertBanner} hide={hideAlertBanner} text={alertMessage} title={alertTitle} iconColor={getAlertIconColor(alertType)} icon={getAlertIcon(alertType)} lineColor={getAlertBackgroundColor(alertType)} />
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-2xl font-semibold text-gray-900">Tags</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all tags in your organization and their assigned teams.
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        onClick={() => setIsAddingTag(true)}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Create Tag
                    </button>
                </div>
            </div>
            <div className='bg-blue-50 border-l-4 border-blue-400 p-4 rounded-lg flex items-center space-x-2 mt-4 gap-x-4 text-sm text-gray-700'>
                <InformationCircleIcon className='h-6 w-6 text-gray-500' />
                Tags can be used to organize users and move them in between teams. Tags are not visible to users, they are simply used for administrative purposes.
            </div>
            <div className="mt-4 flex flex-col">
                <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-visible border border-gray-300 ring-opacity-5 rounded-md max-w-full overflow-y-auto h-[75vh] thin-scrollbar">
                            <table className="min-w-full max-w-full divide-y divide-gray-600 rounded-md" style={{ position: 'relative', isolation: 'isolate' }}>
                                <thead className="bg-gray-50 sticky top-0 z-10 border-b-1 border-gray-300 shadow-md">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Tag Name
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Teams
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Members
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {tags.length > 0 && !loading ? (
                                        tags.map((tag) => (
                                            <TagRow
                                                key={tag.id}
                                                tag={tag}
                                                teams={teams}
                                                onEdit={(newName) => handleEditTag(tag, newName)}
                                                onDelete={() => handleDeleteTag(tag)}
                                                onUpdateTeams={handleUpdateTagTeams}
                                                getTeamById={getTeamById}
                                            />
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                                <div className="flex flex-col items-center gap-y-2 w-full">
                                                    {loading && <Spinner timer={false} size='h-5 w-5' />}
                                                    {loading ? "Loading tags..." : "No tags found"}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TagManagement;