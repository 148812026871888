export enum DentalType {
    NOT_EVALUATED = 'NOT_EVALUATED',
    EVALUATED = 'EVALUATED',
    MISSING = 'MISSING',
    EXTRACTED = 'EXTRACTED',
    TREATMENT = 'TREATMENT',
}

export const getToothStyling = (fill?: string, stroke: string = "none", strokeWidth: number = 1, strokeMiterlimit: number = 4, hover:boolean = false) => {
    return {
        stroke: stroke,
        strokeWidth: strokeWidth,
        strokeDasharray: 'none',
        strokeLinecap: 'butt' as 'butt',        // explicitly set as literal type
        strokeDashoffset: 0,
        strokeLinejoin: 'miter' as 'miter',
        strokeMiterlimit: strokeMiterlimit,
        fill: fill,
        fillRule: 'nonzero' as 'nonzero',       // explicitly set as literal type
        opacity: 1,
    }
}

export const getNextDentalType = (current: DentalType): DentalType => {
    switch (current) {
        case DentalType.NOT_EVALUATED:
            return DentalType.EVALUATED;
        case DentalType.EVALUATED:
            return DentalType.MISSING;
        case DentalType.MISSING:
            return DentalType.EXTRACTED;
        case DentalType.EXTRACTED:
            return DentalType.TREATMENT;
        case DentalType.TREATMENT:
            return DentalType.NOT_EVALUATED;
        default:
            return DentalType.EVALUATED;
    }
}

export type ToothInfo = { [toothNumber: string]: string };

export function parseDentalInfo(input: string): ToothInfo {
    const lines = input.split("\n");
    const toothInfo: ToothInfo = {};

    for (let line of lines) {
        line = line.trim();
        const match = line.match(/^-?\s*\**\s*(\d{3})\**:\s(.+)$/);

        if (match) {
            const toothNumber = match[1];
            const status = match[2];
            toothInfo[toothNumber] = status;
        }
    }

    return toothInfo;
}

export enum ToothColor {
    MISSING = 'rgb(128, 128, 128)',       // gray
    EVALUATED = 'rgb(0, 0, 0)',           // black
    EXTRACTED = 'rgb(255, 0, 0)',         // red
    NOT_EVALUATED = 'rgb(128, 128, 128)', // gray
    TREATMENT = 'rgb(0, 0, 255)'          // blue
}

export enum ToothFillColor {
    MISSING = 'rgb(55, 65, 81)',      // fill-gray-300
    EVALUATED = 'rgb(147, 197, 253)',    // fill-purple-300
    EXTRACTED = 'rgb(252, 165, 165)',    // fill-red-300
    NOT_EVALUATED = 'rgb(255, 255, 255)', // fill-white
    TREATMENT = 'rgb(253, 186, 116)'     // fill-blue-300
}

export enum ToothFillColorTW {
    MISSING = 'bg-gray-700',      // fill-gray-300
    EVALUATED = 'bg-blue-500',  // fill-purple-300
    EXTRACTED = 'bg-red-300',     // fill-red-300
    NOT_EVALUATED = 'bg-white',   // fill-white
    TREATMENT = 'bg-orange-500'     // fill-blue-300
}

export class CanineDentalInfo {
    101: DentalType = DentalType.EVALUATED
    102: DentalType = DentalType.EVALUATED
    103: DentalType = DentalType.EVALUATED
    104: DentalType = DentalType.EVALUATED
    105: DentalType = DentalType.EVALUATED
    106: DentalType = DentalType.EVALUATED
    107: DentalType = DentalType.EVALUATED
    108: DentalType = DentalType.EVALUATED
    109: DentalType = DentalType.EVALUATED
    110: DentalType = DentalType.EVALUATED
    201: DentalType = DentalType.EVALUATED
    202: DentalType = DentalType.EVALUATED
    203: DentalType = DentalType.EVALUATED
    204: DentalType = DentalType.EVALUATED
    205: DentalType = DentalType.EVALUATED
    206: DentalType = DentalType.EVALUATED
    207: DentalType = DentalType.EVALUATED
    208: DentalType = DentalType.EVALUATED
    209: DentalType = DentalType.EVALUATED
    210: DentalType = DentalType.EVALUATED
    301: DentalType = DentalType.EVALUATED
    302: DentalType = DentalType.EVALUATED
    303: DentalType = DentalType.EVALUATED
    304: DentalType = DentalType.EVALUATED
    305: DentalType = DentalType.EVALUATED
    306: DentalType = DentalType.EVALUATED
    307: DentalType = DentalType.EVALUATED
    308: DentalType = DentalType.EVALUATED
    309: DentalType = DentalType.EVALUATED
    310: DentalType = DentalType.EVALUATED
    311: DentalType = DentalType.EVALUATED
    401: DentalType = DentalType.EVALUATED
    402: DentalType = DentalType.EVALUATED
    403: DentalType = DentalType.EVALUATED
    404: DentalType = DentalType.EVALUATED
    405: DentalType = DentalType.EVALUATED
    406: DentalType = DentalType.EVALUATED
    407: DentalType = DentalType.EVALUATED
    408: DentalType = DentalType.EVALUATED
    409: DentalType = DentalType.EVALUATED
    410: DentalType = DentalType.EVALUATED
    411: DentalType = DentalType.EVALUATED

    constructor(dentalStatus: { [key: string]: string }) {

        const standardizedDentalStatus: { [key: string]: DentalType } = Object.fromEntries(
            Object.entries(dentalStatus).map(([key, value]) => {
                switch (typeof value === 'string' ? value.toUpperCase() : "") {
                    case 'TREATMENT':
                        return [key, DentalType.TREATMENT];
                    case 'EXTRACTED':
                        return [key, DentalType.EXTRACTED];
                    case 'MISSING':
                        return [key, DentalType.MISSING];
                    case 'EVALUATED':
                        return [key, DentalType.EVALUATED];
                    case 'NOT_EVALUATED':
                        return [key, DentalType.NOT_EVALUATED];
                    default:
                        return [key, DentalType.EVALUATED];
                }
            })
        );

        Object.assign(this, standardizedDentalStatus);
    }
}

export class FelineDentalInfo {
    101: DentalType = DentalType.EVALUATED
    102: DentalType = DentalType.EVALUATED
    103: DentalType = DentalType.EVALUATED
    104: DentalType = DentalType.EVALUATED
    106: DentalType = DentalType.EVALUATED
    107: DentalType = DentalType.EVALUATED
    108: DentalType = DentalType.EVALUATED
    109: DentalType = DentalType.EVALUATED
    201: DentalType = DentalType.EVALUATED
    202: DentalType = DentalType.EVALUATED
    203: DentalType = DentalType.EVALUATED
    204: DentalType = DentalType.EVALUATED
    206: DentalType = DentalType.EVALUATED
    207: DentalType = DentalType.EVALUATED
    208: DentalType = DentalType.EVALUATED
    209: DentalType = DentalType.EVALUATED
    301: DentalType = DentalType.EVALUATED
    302: DentalType = DentalType.EVALUATED
    303: DentalType = DentalType.EVALUATED
    304: DentalType = DentalType.EVALUATED
    307: DentalType = DentalType.EVALUATED
    308: DentalType = DentalType.EVALUATED
    309: DentalType = DentalType.EVALUATED
    401: DentalType = DentalType.EVALUATED
    402: DentalType = DentalType.EVALUATED
    403: DentalType = DentalType.EVALUATED
    404: DentalType = DentalType.EVALUATED
    407: DentalType = DentalType.EVALUATED
    408: DentalType = DentalType.EVALUATED
    409: DentalType = DentalType.EVALUATED

    constructor(dentalStatus: { [key: string]: string }) {

        const standardizedDentalStatus: { [key: string]: DentalType } = Object.fromEntries(
            Object.entries(dentalStatus).map(([key, value]) => {
                switch (typeof value === 'string' ? value.toUpperCase() : "") {
                    case 'TREATMENT':
                        return [key, DentalType.TREATMENT];
                    case 'EXTRACTED':
                        return [key, DentalType.EXTRACTED];
                    case 'MISSING':
                        return [key, DentalType.MISSING];
                    case 'EVALUATED':
                            return [key, DentalType.EVALUATED];
                    case 'NOT_EVALUATED':
                            return [key, DentalType.NOT_EVALUATED];
                    default:
                        return [key, DentalType.EVALUATED];
                }
            })
        );

        Object.assign(this, standardizedDentalStatus);
    }
}