export const languages = [
  "English (US)",
  "English (UK)",
  "English (AU)",
  "Spanish",
];

export const languagesDischarge = [
  "English",
  "Spanish",
  "French",
  "Portuguese",
  "Russian",
  "Hindi",
  "Chinese",
  "Cantonese",
  "Mandarin",
  "Korean",
];

export const getLanguageShortForm = (language: string) => {
  switch (language) {
    case "English (US)":
      return "EN (US)";
    case "English (UK)":
      return "EN (UK)";
    case "English (AU)":
      return "EN (AU)";
    case "Spanish":
      return "ES";
    default:
      return "";
  }
};
