import { useState, useRef, useLayoutEffect, useEffect, } from "react";
import { Spinner } from "../../utils/Spinner";
import { ChevronDownIcon, ChevronUpIcon, ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { addMissingIndentLevels, fixIndentation, preprocessText, revertPreprocessedText, revertPreprocessedTextNoStyling } from "../../utils/TextUtils";
import { formatPreHistorySectionForCopy } from "../../utils/PreHistoryUtils";
import { useVetRec } from "../../providers/VetRecProvider";
import { executeCopyToClipboard } from "../../utils/CopyUtils";
import QuillEditorWithToolbar from "../history/QuillEditorWithToolbar";
import { useHistory } from "../../providers/HistoryProvider";

interface SummarizedHistoryEntryViewProps {
  title: string;
  value: string;
  didValueChange: boolean;
  onValueChange: (value: string) => void;
  forceExpand: boolean;
}

export const SummarizedHistoryEntryView: React.FC<SummarizedHistoryEntryViewProps> = ({ title, value, didValueChange, onValueChange, forceExpand }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [shouldShowExpand, setShouldShowExpand] = useState<boolean>(false)
  const [isCopying, setIsCopying] = useState<boolean>(false)
  const toggleExpandCollapse = () => setIsExpanded(!isExpanded)
  const quillRef = useRef<HTMLDivElement>(null);
  const truncatedHeight = 40

  const { handleSelectionChange, handleThumbsInput, preHistoryStreaming } = useHistory()

  const [displayRecapEntry, setDisplayRecapEntry] = useState<string>("")

  useEffect(
    () => setDisplayRecapEntry(preprocessText(value ?? "").replace(/^<br\/>|<br\/>$/g, "").trim()), 
    [value])

  // const processedValue = useMemo(() => preprocessText(value ?? "").replace(/^<br\/>|<br\/>$/g, "").trim(), [title, value])  

  const { richtextRemoveSpacesBetweenSections, richtextFontSize, copyBehavior, pims } = useVetRec()

  const copyToClipboardSection = async () => {
    try {
      setIsCopying(true)
      if(value === undefined) return
      // value contains the raw markdown based text
      // processedValue contains the html textx
      const clipboardPlaintextValue = pims === "ezyvet" || copyBehavior === "markdown" ? formatPreHistorySectionForCopy(value) /*markdown*/ : revertPreprocessedTextNoStyling(displayRecapEntry) // plain text

      let processIntoHtml = addMissingIndentLevels(displayRecapEntry)                
      let clipboardRichtextValue = fixIndentation(processIntoHtml, richtextRemoveSpacesBetweenSections) // rich text

      await executeCopyToClipboard(clipboardPlaintextValue, clipboardRichtextValue, richtextFontSize)
    } catch (err) {
    } finally {
      setIsCopying(false)
    }
  }

  useLayoutEffect(() => {
    if (!forceExpand) {
      setShouldShowExpand(quillRef.current !== null && quillRef.current.clientHeight >= (4 * truncatedHeight) - 1)
    }
  }, [forceExpand, displayRecapEntry])

  const formatSectionTitle = (title: string) => title.replace(/\*\*(.*?)\*\*/g, "$1");

  return (
    <div className="flex flex-col gap-y-2 relative">
      <div className="flex flex-row gap-x-4 items-center">
          <span className="font-semibold text-gray-900">
            {formatSectionTitle(title)}
          </span>
          <div>
              <button
              type="button"
              className="relative -ml-px inline-flex justify-center items-center gap-x-1.5 rounded-md px-3 py-1 text-xs font-semibold text-main-text ring-1 ring-inset ring-gray-400 bg-main hover:bg-main-lighter w-full"
              onClick={() => copyToClipboardSection()}
              >
                  {!isCopying && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4" aria-hidden="true" />}
                  {isCopying && <Spinner size="h-4 w-4" timer={false}/>}
                  Copy
              </button>
          </div>
      </div>
      <div ref={quillRef} className={`relative ${forceExpand || isExpanded ? '' : `max-h-${truncatedHeight} overflow-auto thin-scrollbar`}`}>
        <QuillEditorWithToolbar
          theme='snow'
          className={`block w-full rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none ${didValueChange ? 'bg-yellow-100' : ''}`}
          value={displayRecapEntry}
          modules={{toolbar: false}}
          onChange={(content, delta, source) => {
            setDisplayRecapEntry(content);
            if (source === 'user') {
              onValueChange(revertPreprocessedText(content));
            }
          }}
          onChangeSelection={handleSelectionChange}
          onThumbsDownPressed={() => handleThumbsInput(false, "PreHistory")}
          readOnly={preHistoryStreaming}
        />
      </div>
      {!forceExpand && shouldShowExpand && <button
        onClick={toggleExpandCollapse}
        className="flex justify-between items-center p-2 w-full bg-blue-100 hover:bg-blue-200 text-blue-900 rounded-md focus:outline-none"
      >
        {isExpanded ? <p>Show less</p> : <p>Show more</p>}
        {isExpanded ? (
          <ChevronUpIcon className="w-5 h-5 text-blue-900" />
        ) : (
          <ChevronDownIcon className="w-5 h-5 text-blue-900" />
        )}
      </button>}
    </div>
  );
};