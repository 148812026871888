import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { DocumentDuplicateIcon, DocumentPlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { TemplateObject } from '../../utils/TemplateObject'
import { classNames } from '../../utils/ClassUtils'
import { TemplateTabs } from '../../Templates'
import { getTemplateTypeColor, getTemplateTypeDisplayName } from './TemplateUtils'

interface TemplateCreateModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  templateLibrary?: TemplateObject[]
}

export default function TemplateCreateModal({ open, setOpen, templateLibrary = [] }: TemplateCreateModalProps) {
  const navigate = useNavigate()
  const [showLibrary, setShowLibrary] = useState(false)
  const [type, setType] = useState<string>("all")
  const [search, setSearch] = useState<string>("")
    const handleClose = () => {
    setOpen(false)

  }

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (!open) {
      // Wait for the animation to complete (300ms is the duration-200 in Tailwind)
      timeout = setTimeout(() => {
        setShowLibrary(false)
        setType("all")
        setSearch("")
      }, 200)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [open])

  const handleSelection = (type: 'scratch' | 'library') => {
    if (type === 'scratch') {
      navigate('/templateBuilder')
      setOpen(false)
    } else {
      setShowLibrary(true)
    }

  }

  const handleTypeChange = (type: string) => {
    setType(type)
  }

  const handleBack = () => {
    setShowLibrary(false)
    setType("all")
    setSearch("")
  }

  const handleTemplateSelect = (template: TemplateObject) => {
    navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}&duplicate=true`)
    setOpen(false)
  }

  const filterTemplates = (templates: TemplateObject[], type: string, search: string = "") => {
    let filteredTemplates = templates;

    if (type !== "all") {
      filteredTemplates = filteredTemplates.filter(template => template.type === type || template.tag?.includes(type));
    }

    if (search) {
      const regex = new RegExp(search, 'i');
      filteredTemplates = filteredTemplates.filter(template => regex.test(template.name));
    }

    return filteredTemplates;
  }

  const filteredTemplates = filterTemplates(templateLibrary, type, search)

  function capitalize(s: string) {
    if (typeof s !== 'string') return ''
    if (s.length === 2) return s.toUpperCase()
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel data-tour-id="create-template-modal-library" className={classNames(
                "relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all",
                "sm:my-8 w-full",
                showLibrary ? "sm:max-w-5xl" : "sm:max-w-2xl",
                "p-6 sm:p-8"
              )}>
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {!showLibrary ? (
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                        Create New Template
                      </Dialog.Title>
                      <p className="mt-2 text-sm text-gray-500">
                        Choose how you'd like to start creating your new template.
                      </p>
                      <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <button
                          onClick={() => handleSelection('scratch')}
                          className="relative flex flex-col items-center justify-center rounded-xl border-2 border-gray-200 bg-white p-4 text-center hover:border-main hover:bg-main/5 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 transition-all duration-200"
                        >
                          <DocumentPlusIcon className="mx-auto h-16 w-16 text-gray-400 group-hover:text-main" />
                          <span className="mt-4 block text-lg font-semibold text-gray-900">Start from Scratch</span>
                          <span className="mt-2 block text-sm text-gray-500">Create a new template from scratch</span>
                        </button>
                        <button
                        data-tour-id="create-template-modal"
                          onClick={() => handleSelection('library')}
                          className="relative flex flex-col items-center justify-center rounded-xl border-2 border-gray-200 bg-white p-4 text-center hover:border-main hover:bg-main/5 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 transition-all duration-200"
                        >
                          <DocumentDuplicateIcon className="mx-auto h-16 w-16 text-gray-400 group-hover:text-main" />
                          <span className="mt-4 block text-lg font-semibold text-gray-900">Use Template Library</span>
                          <span className="mt-2 block text-sm text-gray-500">Start from an existing template</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div data-tour-id="create-template-modal-library" className="flex flex-col w-full">
                    <div className="flex justify-between items-center mb-6">
                      <button
                        onClick={handleBack}
                        className="flex items-center gap-2 text-gray-500 hover:text-gray-700 transition-colors px-3 py-1.5 rounded-lg hover:bg-gray-100"
                      >
                        <span>Back</span>
                      </button>
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                        Template Library
                      </Dialog.Title>
                      <div className="w-20" />
                    </div>

                    <TemplateTabs 
                      tab="library"
                      handleTabChange={() => {}}
                      type={type}
                      handleTypeChange={handleTypeChange}
                      handleSearchChange={setSearch}
                      showTabs={false}
                    />

                    <div className=" mt-4 w-full">
                      <div className="relative w-full">
                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 min-h-[460px] max-h-[460px] overflow-y-auto thin-scrollbar content-start pr-2 -mr-2">
                          {filteredTemplates.map((template) => (
                            <button
                              data-tour-id={template.name === "Discharge" ? "create-template-modal-library-discharge-template" : ""}
                              key={template.id}
                              onClick={() => handleTemplateSelect(template)}
                              className="flex flex-col h-[120px] p-4 border border-gray-200 rounded-xl hover:border-main hover:bg-main/5 transition-all duration-200 text-left group"
                            >
                              <div className="flex items-start justify-between mb-3">
                                <h3 className="text-lg font-medium text-gray-900 group-hover:text-main transition-colors line-clamp-2">
                                  {template.name}
                                </h3>
                              </div>
                              <div className="flex flex-wrap gap-2 mt-auto">
                                <span className={classNames(
                                  "px-2.5 py-1 rounded-md text-sm font-medium",
                                  `bg-${getTemplateTypeColor(template.type)}-100 text-${getTemplateTypeColor(template.type)}-800`
                                  )}>
                                  {getTemplateTypeDisplayName(template.type)}
                                </span>
                                {template.tag && (
                                  <span className="px-2.5 py-1 bg-gray-100 text-gray-800 rounded-md text-sm font-medium">
                                    {capitalize(template.tag[0])}
                                  </span>
                                )}
                              </div>
                            </button>
                          ))}
                          {filteredTemplates.length === 0 && (
                            <div className="col-span-full text-center py-12 bg-gray-50 rounded-xl">
                              <DocumentDuplicateIcon className="mx-auto h-12 w-12 text-gray-400 mb-3" />
                              <p className="text-lg font-medium text-gray-900">No templates found</p>
                              <p className="text-sm text-gray-500">Try adjusting your search or filter criteria</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}