import React from 'react';
import { Team, Tag, UserStatus } from '../types/user';
import { UserRole } from '../../settings/UserRoles';
import SingleSelectListbox from './listbox/SingleSelectListbox';
import { capitalize } from 'lodash';

interface UserFiltersProps {
  filterName: string;
  filterEmail: string;
  filterTeam: string;
  filterTag: string;
  filterRole: string;
  filterStatus: string;
  teams: Team[];
  tags: Tag[];
  onFilterNameChange: (value: string) => void;
  onFilterEmailChange: (value: string) => void;
  onFilterTeamChange: (value: string) => void;
  onFilterTagChange: (value: string) => void;
  onFilterRoleChange: (value: string) => void;
  onFilterStatusChange: (value: string) => void;
  onClearFilters: () => void;
}

const UserFilters: React.FC<UserFiltersProps> = ({
  filterName,
  filterEmail,
  filterTeam,
  filterTag,
  filterRole,
  filterStatus,
  teams,
  tags,
  onFilterNameChange,
  onFilterEmailChange,
  onFilterTeamChange,
  onFilterTagChange,
  onFilterRoleChange,
  onFilterStatusChange,
  onClearFilters
}) => {
  const hasActiveFilters = filterName || filterEmail || filterTeam || filterTag || filterRole || filterStatus;
  
  // Convert roles to options format required by SingleSelectListbox
  const roleOptions = Object.values(UserRole).map((role) => ({
    id: role,
    name: capitalize(role.replace("org:", "").replace(/_/g, ' '))
  }));

  // Convert statuses to options format required by SingleSelectListbox
  const statusOptions = Object.values(UserStatus).map((status) => ({
    id: status,
    name: capitalize(status.replace(/_/g, ' '))
  }));
  
  return (
    <div className="mt-3 bg-white shadow-sm rounded-md border border-gray-300 px-4 py-2">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4">
        <div>
          <label htmlFor="filter-name" className="block text-xs font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            id="filter-name"
            value={filterName}
            onChange={(e) => {onFilterNameChange(e.target.value)}}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1.5 px-3 text-sm h-[38px]"
          />
        </div>
        
        <div>
          <label htmlFor="filter-email" className="block text-xs font-medium text-gray-700">
            Email
          </label>
          <input
            type="text"
            id="filter-email"
            value={filterEmail}
            onChange={(e) => {onFilterEmailChange(e.target.value)}}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-1.5 px-3 text-sm h-[38px]"
          />
        </div>
        
        <div>
          <label htmlFor="filter-team" className="block text-xs font-medium text-gray-700">
            Team
          </label>
          <SingleSelectListbox
            options={[{ id: '', name: 'All Teams' }, ...teams]}
            selected={filterTeam}
            onChange={onFilterTeamChange}
            placeholder="All Teams"
            className="mt-1"
          />
        </div>
        
        <div>
          <label htmlFor="filter-tag" className="block text-xs font-medium text-gray-700">
            Tag
          </label>
          <SingleSelectListbox
            options={[{ id: '', name: 'All Tags' }, ...tags]}
            selected={filterTag}
            onChange={onFilterTagChange}
            placeholder="All Tags"
            className="mt-1"
          />
        </div>
        
        <div>
          <label htmlFor="filter-role" className="block text-xs font-medium text-gray-700">
            Role
          </label>
          <SingleSelectListbox
            options={[{ id: '', name: 'All Roles' }, ...roleOptions]}
            selected={filterRole}
            onChange={onFilterRoleChange}
            placeholder="All Roles"
            className="mt-1"
          />
        </div>
        
        <div>
          <label htmlFor="filter-status" className="block text-xs font-medium text-gray-700">
            Status
          </label>
          <SingleSelectListbox
            options={[{ id: '', name: 'All Statuses' }, ...statusOptions]}
            selected={filterStatus}
            onChange={onFilterStatusChange}
            placeholder="All Statuses"
            className="mt-1"
          />
        </div>
      </div>
      
      {hasActiveFilters && (
        <div className="mt-3 flex justify-end">
          <button
            type="button"
            onClick={onClearFilters}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Clear All Filters
          </button>
        </div>
      )}
    </div>
  );
};

export default UserFilters;
