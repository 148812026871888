import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"

import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface WarningBannerProps {
    children?: React.ReactNode;
    warningText: string;
    closeButtonEnabled?: boolean;
    className?: string;
}

export const WarningBanner: React.FC<WarningBannerProps> = (props) => {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) return null;

    return (
        <div className={`rounded-lg bg-yellow-100 px-4 py-3 shadow grow flex items-center justify-center w-full ${props.className}`}>
            <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                <div className="flex flex-row gap-x-4 items-center">
                    <div>
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="text-sm text-yellow-900 font-semibold">{props.warningText}</div>
                </div>
                <div className="flex flex-row gap-x-6 items-center">
                    {props.children}
                    {props.closeButtonEnabled && (
                        <button onClick={() => setIsVisible(false)}>
                            <XMarkIcon className="h-5 w-5 text-yellow-600" aria-hidden="true" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}