import { useState } from 'react';
import joeyMic from '../../assets/joey-mic.png'
import joeyNotes from '../../assets/joey-notes.png'
import joeySitting from '../../assets/joey-sitting.png'
import puppyJoey from '../../assets/puppy-joey.png'
import amira from '../../assets/Dr. Amira.png'
import sumo from '../../assets/Dr. Sumo.png'
import jimmy from '../../assets/Dr.Jimmy.png'
import chewy from '../../assets/Dr.Chewy.png'
import BB from '../../assets/Dr.BB.png'

const images = [joeyMic, joeyNotes, joeySitting, puppyJoey, amira, sumo, jimmy, chewy, BB];

images.forEach(src => {
  const img = new Image();
  img.src = src;
});

const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};

interface RandomPetGraphicProps {
    heightStyling?: string;
    containerClassName?: string;
}

const RandomPetGraphic: React.FC<RandomPetGraphicProps> = ({
    heightStyling,
    containerClassName = "flex justify-center items-center"
}) => {
    const [randomImage] = useState(getRandomImage());
    const [imageLoaded, setImageLoaded] = useState(false);
    
    return (
        <div className={`${containerClassName}`}>
            <img 
                src={randomImage}
                alt="Randomly selected pet"
                className={`rounded-lg ${heightStyling ?? "max-h-64 max-w-full w-auto h-auto object-contain"} ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
                onLoad={() => setImageLoaded(true)}
                style={{ transition: 'opacity 0.1s ease-in-out' }}
            />
        </div>
    );
};
  
export default RandomPetGraphic;