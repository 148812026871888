import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowsRightLeftIcon, LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid'
import { useHistory } from '../../providers/HistoryProvider'
import { Loading } from '../../utils/Loading'
import va from '@vercel/analytics';

export default function FindReplaceModal() {
    const { displayActiveNotes, uploadActiveNotes, setDisplayActiveNotes, findReplaceModal, highlightedText, setHighlightedText, setFindReplaceModal, notifyCompleted, setError, getLoggingProperties } = useHistory()

    const [findText, setFindText] = useState<string>('')
    const [replaceText, setReplaceText] = useState<string>('')
    const [submitting, setSubmitting] = useState(false)
    const [caseSensitive, setCaseSensitive] = useState(false);

    const isSubmitDisabled = useMemo(() => submitting || findText === "" || replaceText === "", [submitting, findText, replaceText]);

    useEffect(() => setFindText(highlightedText?.trim() ?? ""), [highlightedText])


    async function handleFindReplace(shouldSave: boolean) {
        try {
            setSubmitting(true)

            const flags = caseSensitive ? 'g' : 'gi'; // 'g' for global, 'i' for case-insensitive
            const regex = new RegExp(`\\b${findText}\\b`, flags); // \\b ensures word boundaries
            const notesWithReplacement = displayActiveNotes.replace(regex, replaceText);
            
            setDisplayActiveNotes(displayActiveNotes.replace(regex, replaceText));

            if (shouldSave) {
                await uploadActiveNotes(notesWithReplacement)
            }

            notifyCompleted(`Replacement ${shouldSave ? "Saved" : "Completed"}`, `Replaced all occurrences of "${findText}" with "${replaceText}".`)

            va.track("FindReplace_Success", getLoggingProperties())

            onClose()
            setSubmitting(false)
        } catch (error) {
            setError("There was an issue replacing your text. Please try again.")
            va.track("FindReplace_Fail", getLoggingProperties())
            setSubmitting(false)
        }
    }

    function onClose() {
        setFindReplaceModal(false)
        setHighlightedText(undefined)
        setFindText("")
        setReplaceText("")
        setCaseSensitive(false) 
    }

    return (
        <Transition.Root show={findReplaceModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                        <ArrowsRightLeftIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Find and Replace
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Find and replace all instances of a particular word or phrase in your notes.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="find" className="block text-sm font-medium text-gray-700">
                                        Find all occurences of
                                    </label>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="text"
                                            name="find"
                                            id="find"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 mt-1"
                                            placeholder="Text you want to find"
                                            value={findText}
                                            onChange={(event) => setFindText(event.target.value)}
                                        />
                                        <div className="flex items-center mt-1">
                                            <button
                                                type="button"
                                                className={`text-gray-500 hover:text-gray-700 border border-gray-300 py-1.5 px-2 rounded-md h-8 flex shadow-md ${caseSensitive ? 'bg-red-600 hover:bg-red-500' : 'bg-blue-600 hover:bg-blue-500'}`}
                                                onClick={() => setCaseSensitive(!caseSensitive)}
                                                title="Change case sensitivity"
                                            >
                                                {caseSensitive ? <LockClosedIcon className="h-4 w-4 text-white" aria-hidden="true" /> : <LockOpenIcon className="h-4 w-4 text-white" aria-hidden="true" />}
                                                <span
                                                    className={`inline-flex items-center justify-center rounded text-white`}
                                                    aria-hidden="true"
                                                >
                                                    Aa
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`text-xs font-medium ${caseSensitive ? 'text-red-600' : 'text-blue-600'} mt-2 border border-gray-200 ${caseSensitive ? 'bg-red-100' : 'bg-blue-100'} rounded-md px-2 py-1.5`}>
                                        {caseSensitive ? 'Exact match - upper and lowercase matters. E.g., \'Dog\' will not match \'dog\'' : 'Upper and lowercase letters don\'t matter. E.g., \'Dog\' will match \'dog\''}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="replace" className="block text-sm font-medium text-gray-700">
                                        And replace them with
                                    </label>
                                    <input
                                        type="text"
                                        name="replace"
                                        id="replace"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 mt-1"
                                        placeholder="Text you want to replace it with"
                                        value={replaceText}
                                        onChange={(event) => setReplaceText(event.target.value)}
                                    />
                                </div>
                                {submitting && (
                                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-10">
                                        <Loading />
                                    </div>
                                )}
                                <div className="flex space-x-4 mt-4 relative">
                                    {/* <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
                                        onClick={() => handleFindReplace(false)}
                                        disabled={isSubmitDisabled || submitting}
                                    >
                                        Replace
                                    </button> */}
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-blue-600 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
                                        onClick={() => handleFindReplace(true)}
                                        disabled={isSubmitDisabled || submitting}
                                    >
                                        Replace and Save
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
