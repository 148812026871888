import { useEffect, useRef } from 'react';
import 'tailwindcss/tailwind.css';

interface MicrophoneWaveProps{
    width:string
    mediaStream:MediaStream
    setCanWeHearYou: (arg0: boolean) => void
    canWeHearYou: boolean
}

function MicrophoneWave(props:MicrophoneWaveProps) {
  const audioRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let audioContext: AudioContext | undefined = undefined;
    let silenceTimeout: NodeJS.Timeout | undefined = undefined; // Timeout for checking silence
    let silenceStart: number | undefined = undefined;

    if (props.mediaStream) {
        let stream = props.mediaStream;
        audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);
        analyser.fftSize = 512;
        analyser.smoothingTimeConstant = 0.5;
        const bufferLength = analyser.frequencyBinCount;

        const canvas = audioRef.current;
        if (!canvas) return;
        const canvasContext = canvas.getContext('2d');
        if (!canvasContext) return;

        const draw = () => {
            requestAnimationFrame(draw);

            const canvas = audioRef.current;
            if (!canvas) return;
            const canvasContext = canvas.getContext('2d');
            if (!canvasContext) return;

            let dataArray = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteTimeDomainData(dataArray);

            canvasContext.fillStyle = 'rgb(255, 255, 255, 1)';
            canvasContext.fillRect(0, 0, canvas.width, canvas.height);

            const gradient = canvasContext.createLinearGradient(0, 0, canvas.width, 0);
            gradient.addColorStop(0, 'white');
            gradient.addColorStop(0.3, 'rgb(220, 38, 38, 0.5)');
            gradient.addColorStop(0.7, 'rgb(220, 38, 38, 0.5)');
            gradient.addColorStop(1, 'white');

            canvasContext.lineWidth = 3;
            canvasContext.strokeStyle = gradient;
            canvasContext.beginPath();

            let sliceWidth = canvas.width * 1.0 / dataArray.length;
            let x = 0;
            let isSilent = true;

            for (let i = 0; i < dataArray.length; i++) {
                let v = dataArray[i] / 128.0;
                let y = v * canvas.height / 2;

                if (!props.canWeHearYou && y > 105) {
                    props.setCanWeHearYou(true);
                    if (silenceTimeout) clearTimeout(silenceTimeout); // Reset timeout if we detect sound
                    silenceStart = undefined;
                }

                if (y >= 105) {
                    isSilent = false; // Not silent if any value is above 105
                }

                if (y < 101 && y > 99) y = canvas.height / 2;

                if (i === 0) {
                    canvasContext.moveTo(x, y);
                } else {
                    let x_mid = (x + (x - sliceWidth)) / 2;
                    let y_mid = (y + (dataArray[i - 1] / 128.0 * canvas.height / 2)) / 2;
                    let cp_x = (x_mid + (x - sliceWidth)) / 2;
                    let cp_y = (y_mid + (dataArray[i - 1] / 128.0 * canvas.height / 2)) / 2;

                    if (i > 1) {
                        let previous_v = dataArray[i - 2] / 128.0;
                        let previous_y = previous_v * canvas.height / 2;
                        cp_y = y_mid + (y - previous_y) / 2;
                    }

                    canvasContext.quadraticCurveTo(cp_x, cp_y, x, y);
                }

                x += sliceWidth;
            }

            canvasContext.lineTo(canvas.width, canvas.height / 2);
            canvasContext.stroke();

            // Start or reset the silence timeout if no sound detected
            if (isSilent && !silenceStart) {
                silenceStart = Date.now();
            }
            
            if (isSilent && silenceStart && Date.now() - silenceStart >= 5000) {
                silenceStart = undefined;
                props.setCanWeHearYou(false);
            }
        };

        draw();
    }

    // Cleanup function
    return () => {
        if (audioContext) {
            audioContext.close();
        }
        if (silenceTimeout) {
            clearTimeout(silenceTimeout);
        }
    };
  }, []);

  return (
    <div className="flex justify-center items-center">
      <canvas ref={audioRef} className="bg-gray-100" width={props.width} height="200"></canvas>
    </div>
  );
};

export default MicrophoneWave;

