import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { SessionObject } from "../../utils/SessionUtils";
import { Spinner } from "../../utils/Spinner";
import { classNames } from "../../utils/ClassUtils";

interface MultiPetTabsProps {
  activePetName: string;
  session: SessionObject;
  setActivePetName: (petName: string) => void;
  notesNotReady: boolean;
}

export const MultiPetTabs: React.FC<MultiPetTabsProps> = ({
  activePetName,
  session,
  setActivePetName,
  notesNotReady,
}) => {
  const sessionPets = session.pets ?? []
  // TODO: this is not updating when the session changes the pets, adding a warning banner for now
  const petsWithoutMapping = sessionPets.filter(petName => 
    !session.pet_to_soap_node_mapping || !session.pet_to_soap_node_mapping[petName]
  )

  // Handle tab selection.
  const handleTabClick = (petName: string) => {
    setActivePetName(petName);
  };

  return (
    <div className="">
      {/* Notification banner for pets without mappings */}
      {petsWithoutMapping.length > 0 && !notesNotReady && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4 rounded-md">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                {petsWithoutMapping.length === 1 
                  ? <>There are no notes available for patient <span className="font-bold">"{petsWithoutMapping[0]}"</span>.</>
                  : <>Notes are unavailable for {petsWithoutMapping.length} patients (<span className="font-bold">{petsWithoutMapping.join(', ')}</span>).</>}
                {' '}If you just updated the patient list, please refresh the page. If you added a new patient, please <span className="font-bold">Regenerate the Notes</span>.
              </p>
            </div>
          </div>
        </div>
      )}
      
      {/* Mobile screens: dropdown selector */}
      <div className="xl:hidden relative mb-4">
        <label
          htmlFor="pet-tabs"
          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
        >
          Patient
        </label>
        <select
          id="pet-tabs"
          name="pet-tabs"
          className="block w-full rounded-md border-gray-300 focus:border-main-lighter focus:ring-main-lighter"
          value={activePetName}
          onChange={(e) => handleTabClick(e.target.value)}
          disabled={notesNotReady}
        >
          {sessionPets?.map((petName) => {
            const petDoesNotHaveNotes = petsWithoutMapping.includes(petName);
            return (
              <option 
                key={petName} 
                value={petName}
                disabled={petDoesNotHaveNotes}
              >
                {petName}{petDoesNotHaveNotes ? " (No Notes)" : ""}
              </option>
            );
          })}
        </select>
        {notesNotReady && (
          <div className="absolute top-1/2 right-8 transform -translate-y-1/2">
            <Spinner size="w-4 h-4" timer={true}/>
          </div>
        )}
      </div>

      {/* Desktop (>= xl) screens: We show the compact tabs */}
      <div className="hidden xl:block w-full mb-2">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex overflow-x-auto thin-scrollbar-x" aria-label="Patient tabs">
            {sessionPets?.map((petName) => {
              const isActive = activePetName === petName && !notesNotReady;
              const petDoesNotHaveNotes = petsWithoutMapping.includes(petName);
              return (
                <button
                  key={petName}
                  disabled={notesNotReady || petDoesNotHaveNotes}
                  onClick={() => handleTabClick(petName)}
                  className={classNames(
                    isActive
                      ? 'border-main-lighter text-main'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'group inline-flex items-center border-b-2 py-2 px-3 text-sm font-medium cursor-pointer transition-colors duration-200 ease-in-out min-w-[90px]'
                  )}
                  aria-current={isActive ? 'page' : undefined}
                >
                  <div className="flex items-center gap-x-2 w-full justify-center">
                    {/* Fixed-size container for all icon types */}
                    <div className="w-5 h-5 flex items-center justify-center shrink-0">
                      {!notesNotReady && (
                        petDoesNotHaveNotes ? (
                          <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400" />
                        ) : (
                          <div className="h-5 w-5 flex rounded-full bg-main items-center justify-center">
                            <span className="text-center text-main-text text-xs leading-none">
                              {petName[0].toUpperCase()}
                            </span>
                          </div>
                        )
                      )}
                      {notesNotReady && <Spinner size="w-4 h-4" timer={true}/>}
                    </div>
                    {/* Fixed width for text to prevent layout shifts */}
                    <span className="whitespace-nowrap font-medium">
                      {petName}
                    </span>
                  </div>
                </button>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};