import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { Spinner } from '../../../utils/Spinner';

interface ConfirmationModalProps {
    showModal: boolean;
    confirmTitle: string | undefined;
    confirmMessage: string | undefined;
    confirmAction: () => Promise<void>;
    rejectAction: () => void;
    color?: string;
}

export default function ConfirmationModal({
    showModal,
    rejectAction,
    confirmAction,
    confirmMessage,
    confirmTitle,
    color = 'blue'
}: ConfirmationModalProps) {

    const [loading, setLoading] = useState(false)

    const handleConfirm = async () => {
        setLoading(true)
        await confirmAction()
        setLoading(false)
    }

    return (
        <Transition.Root show={showModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={rejectAction}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-${color}-100`}>
                                        <QuestionMarkCircleIcon className={`h-6 w-6 text-${color}-600`} aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {confirmTitle}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {confirmMessage}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-x-4 w-full'>
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:col-start-2 mt-4"
                                        onClick={rejectAction}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md bg-${color}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color}-600 sm:col-start-2 mt-4`}
                                        onClick={() => void handleConfirm()}
                                        disabled={loading}
                                    >
                                        {!loading && "Confirm"}
                                        {loading && <Spinner size={'w-5 h-5'} timer={false} />}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
