import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { TemplateObject } from "../../utils/TemplateObject"
import { fetchOrgTemplates, fetchSuperOrgTemplates, fetchTemplates } from "../../ServerActions"
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { preBuildRecordsRecapTemplate, preBuiltDischargeTemplate, preBuiltTemplate, preBuiltTemplateSpanish } from "../../utils/prebuiltTemplates";
import va from '@vercel/analytics';
import { getTemplateTypeDefaultKey, getTemplateTypeFallbackDefault, isVetRecOwner, TemplateType } from "./TemplateUtils";
import { executeWithRetries } from "../../utils/RetryUtils";
import { getTemplateFromListById } from "../../utils/TemplateUtils";
import { Spinner } from "../../utils/Spinner";
import { StarIcon } from "@heroicons/react/24/solid";
import { MemberObject } from "../../utils/MemberUtils";
import { useVetRec } from "../../providers/VetRecProvider";

interface TemplatePickerProps{
    template:TemplateObject | undefined
    templateId_force?: string | undefined
    external_template_id?: string | undefined
    actAsUser?: MemberObject | undefined
    setTemplate: ((value: TemplateObject) => void) | undefined
    className:string
    templateBackground:string
    pickerWidth?:string
    type?:TemplateType
    pickerDirection?: string
    onlyShowOrg?: boolean | undefined
    dataTourId?: string
    raiseZIndex?: boolean
}

export default function TemplatePicker(props: TemplatePickerProps) {
    const { template, templateId_force, setTemplate, className, templateBackground, pickerWidth, type, pickerDirection, onlyShowOrg, actAsUser, dataTourId, raiseZIndex, external_template_id } = props
    const { getToken} = useAuth();
    const { organization } = useOrganization()
    const { orgId } = useAuth()
    
    // Template Variables
    const [loadingTemplates , setLoadingTemplates] = useState<boolean>(false)
    const [templates, setTemplates] = useState<TemplateObject[]>()
    const [query, setQuery] = useState('');
    const { user } = useUser()
    const { prebuiltEnabled, emailAddress, superOrg } = useVetRec()

    const defaultTemplateRaw = useMemo<string>(() => {
        const userTemplate = user?.publicMetadata[getTemplateTypeDefaultKey(type)] as string;
        const orgTemplate = organization?.publicMetadata[getTemplateTypeDefaultKey(type)] as string;
    
        return userTemplate ?? orgTemplate;
    }, [orgId]);

    const defaultTemplate = useMemo<string>(() => {
        return defaultTemplateRaw ?? getTemplateTypeFallbackDefault(type);
    }, [defaultTemplateRaw]);

    const language = useMemo<string>(() => (user?.publicMetadata['language'] as string) ?? 'English', []);

    const defaultTemplates = useMemo<TemplateObject[]>(
        () => {
            if (type === TemplateType.COMMUNICATIONS) {
                return preBuiltDischargeTemplate
            } else if (type === TemplateType.RECORDSRECAP) {
                return preBuildRecordsRecapTemplate
            } else {
                return language === 'Spanish' ? preBuiltTemplateSpanish : preBuiltTemplate
            }
        }, 
        [language])
    
    let width = pickerWidth ? pickerWidth : "xl:w-60 lg:w-40 md:w-40 sm:w-40 w-full"
    const direction = pickerDirection ? pickerDirection : "below"
    
    const getTemplates = async () => {
        try{
            setLoadingTemplates(true)
           
            await executeWithRetries(async () => {
                let temp_templates : TemplateObject[] = onlyShowOrg ? [] : await fetchTemplates(await getToken({template:"supabase"}) ?? '', type)
                let org_templates : TemplateObject[] = orgId ? await fetchOrgTemplates(await getToken({template:"supabase"}) ?? '', type) : []
                let super_org_templates : TemplateObject[] = superOrg ? await fetchSuperOrgTemplates(await getToken({template:"supabase"}) ?? "", type) : []
                temp_templates = temp_templates.filter(template => !org_templates.some(template2 => template2.id === template.id))
                temp_templates = temp_templates.filter(template => !super_org_templates.some(template2 => template2.id === template.id))
                org_templates = org_templates.filter(template => !super_org_templates.some(template2 => template2.id === template.id))
    
                let list_templates = [...temp_templates, ...org_templates, ...super_org_templates, ...defaultTemplates]
                const templateToSet = getTemplateFromListById(list_templates, templateId_force, external_template_id) ?? list_templates.find(t => t.id === defaultTemplate) ?? list_templates[0]
                if (setTemplate) setTemplate(templateToSet)
                
                //move the selected template to the top of the list
                const index = list_templates.findIndex(t => t.id === templateToSet.id)
                list_templates.splice(index, 1)
                list_templates = [templateToSet, ...list_templates]
                
                setTemplates(list_templates)
            })
            
            setLoadingTemplates(false)
        }catch{
            console.error("Failed to fetch templates.")
        }
    }

    useEffect(() => {
        getTemplates()
    }, [templateId_force, orgId, external_template_id])

    useEffect(() => {
        if (template && templates) {            
            // Gather default template if it is not the selected template
            const defaultTemplate = defaultTemplateRaw ? templates.filter(t => t.id === defaultTemplateRaw && t.id !== template.id) : [];
            
            // Filter out the selected and default template
            const filteredTemplates = templates.filter(t => t !== template && t.id !== defaultTemplateRaw);

            // Separate out user owned and non-user owned templates. Sort each alphabetically.
            const userOwnedTemplates = filteredTemplates
                .filter(t => t.owner === emailAddress)
                .sort((a, b) => a.name.localeCompare(b.name));
            const otherTemplates = filteredTemplates
                .filter(t => t.owner !== emailAddress)
                .sort((a, b) => a.name.localeCompare(b.name));

            // Concetenate in order: selected -> default -> user owned -> other
            const newTemplates = [template, ...defaultTemplate, ...userOwnedTemplates, ...otherTemplates];

            setTemplates(newTemplates);
        }
    }, [template])

    useEffect(() => {
        async function setTemplateUsingActAs() {
            if (template && templates && actAsUser) {
                const actAsDefaultTemplate = (actAsUser.get_default_template && actAsUser.clerk_id !== user?.id) ? await actAsUser.get_default_template(type) : undefined
                const templateToSet = templates.find(t => t.id === actAsDefaultTemplate) ?? templates.find(t => t.id === defaultTemplate) ?? templates[0]
                if (setTemplate) setTemplate(templateToSet)
            }    
        }

        setTemplateUsingActAs()
    }, [actAsUser])

    const handleTemplateChange =  (template: TemplateObject) => {
        if (setTemplate) setTemplate(template)
        let properties =  {
            date:(new Date()).toUTCString(),
            template:template.id
        }
        setQuery("")
        va.track("Template Changed", properties)
    }

    const filteredTemplates = useMemo(() => {
        if (!templates) return undefined;
    
        const cleanQuery = query.toLowerCase().replace(/\s+/g, '');

        let templatesToFilter = prebuiltEnabled ? templates : templates.filter(t => !isVetRecOwner(t.owner) || t.id === defaultTemplateRaw);
        
        // If no templates in filter, default to all pre-built templates
        if (templatesToFilter.length === 0) {
            templatesToFilter = templates
        }

        if (template && setTemplate && !templatesToFilter.includes(template)) setTemplate(templatesToFilter[0]);
    
        return query === ''
            ? templatesToFilter
            : templatesToFilter.filter((temp) => {
                const nameMatch = temp.name?.toLowerCase().replace(/\s+/g, '').includes(cleanQuery);
                const idMatch = temp.id.toLowerCase().replace(/\s+/g, '').includes(cleanQuery);
                return nameMatch || idMatch;
            });
    }, [query, templates, prebuiltEnabled]);
    
    const buttonRef = useRef<HTMLButtonElement>(null);
    

    return(<>
        <div data-tour-id={props.dataTourId} className={`shrink-0 ${width} relative ${raiseZIndex ? 'z-[999999]' : ''}`}>
            <label
                htmlFor="name"
                className={`absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-main-text-darker z-[2] ${templateBackground} rounded-md`}
            >
                Template
            </label>
            <Combobox value={template} onChange={(template) => handleTemplateChange(template)}>
                {({open}) => (
                    <div className="relative z-[1]">
                        <div className={`relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm border border-gray-300 ${className} flex flex-row items-center`}>
                            <Combobox.Input
                                className={`w-full border-none py-2 pl-3 mr-10 text-sm leading-5 text-gray-900 focus:outline-none focus:ring-0 h-full rounded-md border-blue-600`}
                                displayValue={(template: TemplateObject | undefined) => template ? template.name : ""}
                                onChange={(event) => setQuery(event.target.value)}
                                onClick={() => {
                                    if(!open){
                                        buttonRef.current?.click()
                                    }
                                }}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" ref={buttonRef}>
                                {loadingTemplates && <Spinner size='h-5 w-5' timer={false}/>}
                                {!loadingTemplates && <div>
                                    <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>}
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Combobox.Options className={`absolute ${direction === "below" ? "mt-1" : "-mt-52"} max-h-40 thin-scrollbar w-full min-w-60 right-0 overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-gray-300 focus:outline-none sm:text-sm cursor-pointer`}>
                            {filteredTemplates?.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                    </div>
                            ) : (
                                filteredTemplates && filteredTemplates.map((template, templateIdx) => (
                                    <Combobox.Option
                                    key={templateIdx}
                                    className={({ active }) =>
                                        `relative select-none py-2 pl-4 flex flex-row gap-x-2 pr-4 cursor-pointer ${
                                        active ? 'bg-accent text-main-text-darker' : 'text-main-text-darker'
                                        }`
                                    }
                                    value={template}
                                    >
                                    {({ selected }) => (
                                        <>
                                        <span
                                            className={`block truncate ${
                                            selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                            {template.name}
                                        </span>
                                        {selected && <span className="flex items-center pl-3 text-gray-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>}
                                        {!selected && defaultTemplateRaw && template.id === defaultTemplateRaw && <span className="flex items-center pl-3 text-gray-600">
                                            <StarIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>}
                                        </>
                                    )}
                                </Combobox.Option>
                                ))
                            )}
                            </Combobox.Options>
                        </Transition>
                    </div>
                )}
            </Combobox>
        </div>
    </>)
}
