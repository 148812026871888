import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface SingleSelectListboxProps {
    options: Array<{ id: string; name: string }>;
    selected: string;
    onChange: (selected: string) => void;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    placeholderInOptions?:boolean
    maxHeight?: string;
}

const SingleSelectListbox: React.FC<SingleSelectListboxProps> = ({
    options,
    selected,
    onChange,
    disabled = false,
    className = '',
    placeholder = 'Select an option',
    maxHeight = 'max-h-60',
    placeholderInOptions = false
}) => {
    const selectedOption = options.find(option => option.id === selected);
    
    // Check if the empty option already exists in the options array
    const hasEmptyOption = options.some(option => option.id === '');

    return (
        <Listbox value={selected} onChange={onChange} disabled={disabled}>
            <div className={`relative ${className}`}>
                <Listbox.Button className="relative w-full cursor-pointer rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm text-sm">
                    <span className="block truncate">
                        {selectedOption ? selectedOption.name : placeholder}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className={`absolute z-[100] mt-1 ${maxHeight} w-auto max-w-96 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm thin-scrollbar`}>
                        {/* Only add placeholder option if it doesn't already exist and selected is not empty */}
                        {!hasEmptyOption && selected !== '' && placeholderInOptions && (
                            <Listbox.Option
                                value=""
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                                    }`
                            }
                            >
                                <span className="block truncate font-normal">{placeholder}</span>
                            </Listbox.Option>
                        )}
                        {options.map((option) => (
                            <Listbox.Option
                                key={option.id}
                                value={option.id}
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                                    }`
                                }
                                title={option.name}
                            >
                                {({ selected: isSelected, active }) => (
                                    <>
                                        <span
                                            className={`block truncate ${isSelected ? 'font-medium' : 'font-normal'
                                                }`}
                                        >
                                            {option.name}
                                        </span>
                                        {isSelected ? (
                                            <span
                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-blue-600' : 'text-blue-600'
                                                    }`}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
};

export default SingleSelectListbox;
