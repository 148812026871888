import { Spinner } from "./Spinner"

interface LoadingProps {
    text?: string
}

export const Loading = (props:LoadingProps) => {
    return(
        <div className='flex flex-col justify-center items-center gap-y-6 mb-8'>
            {props.text}
            <Spinner size="w-10 h-10" timer={false}/>
        </div>
    )
}