import React from 'react';
import { Tag } from '../types/user';

interface TagBadgeProps {
  tag: Tag;
}

const TagBadge: React.FC<TagBadgeProps> = ({ tag }) => {
  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800 text-xs">
      {tag.name}
    </span>
  );
};

export default TagBadge;
