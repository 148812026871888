import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/solid';
import React, { ChangeEvent, DragEvent, FC } from 'react';

// Define the type for the props
interface FileDropZoneProps {
  files: File[]
  onFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  invalidFilesUploaded: boolean;
  compact?: boolean;
}

const FileDropZone: FC<FileDropZoneProps> = ({ files, invalidFilesUploaded, onFileUpload, compact }) => {
  compact = compact ?? false

  // Handler for drag over event
  const handleDragOver = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault(); // Prevent default behavior
  };

  // Handler for file drop event
  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      onFileUpload({ target: { files } } as ChangeEvent<HTMLInputElement>);
    }
  };

  const accepted_file_formats = [
    "application/pdf", // PDF
    "image/*", // Images
    // "application/msword", // Word .doc (Legacy)
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Word .docx
  ]

  // Convert bytes to human readable format
  const formatFileSize = (bytes: number): string => {
    try {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseInt((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;  
    } catch (error) {
      return '';
    }
  };

  return (
    <div className="flex-col items-center justify-center w-full mt-4">
      {files.length > 0 && <div className="flex flex-col items-start justify-start w-full rounded-lg mb-4">
        <div className="flex flex-col w-full">
          <span className="font-medium text-sm text-gray-700">Files Selected</span>
          <div className={`text-sm text-gray-500 dark:text-gray-400 mt-4 flex flex-col gap-y-4 border rounded-md border-gray-300 p-2 py-3 ${compact ? 'max-h-32 overflow-y-auto' : ''}`}>
            {files.map((file, index) => (
              <div key={index} className='flex flex-row justify-between w-full'>
                <span key={index} className='text-sm'>{file.name}</span>
                <div className='flex flex-row justify-between'>
                  <span key={index} className='text-sm'>{formatFileSize(file.size)}</span>
                  <button
                    type="button"
                    className="ml-4"
                    onClick={() => {
                      files.splice(index, 1);
                      onFileUpload({ target: { files } } as unknown as ChangeEvent<HTMLInputElement>);
                    }}
                  >
                    <XCircleIcon className='h-4 w-4 text-red-600' />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
      <label
        htmlFor="dropzone-file"
        className={`flex flex-col items-center justify-center w-full ${compact ? 'py-1' : 'h-40'} border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 ${invalidFilesUploaded ? 'border-red-500' : 'border-gray-300'}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className={`flex flex-col items-center justify-center ${compact ? 'pt-1 pb-1' : 'pt-5 pb-6 text-center'}`}>
          <CloudArrowUpIcon className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" />
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            PDFs, images, and Word documents supported.
          </p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          accept={accepted_file_formats.join(',')}
          onChange={onFileUpload}
          multiple
        />
      </label>
    </div>
  );
};

export default FileDropZone;
