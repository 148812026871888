import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { SUPER_HIGH_Z_INDEX } from './tours/constants';

interface EnumProps{
    enum:Record<string, string>;
    selectedOption:string
    setSelectedOption: (type:string) => void
    width?:string
    text?:string
    labelBackground?:string
    enumToDisplayName?: (value: string) => string
    raiseZIndexOnOptions?: boolean
    disabled?:boolean
}

export default function EnumPicker(props:EnumProps) {

    function capitalize(word: string): string {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const enumEntries = Object.values(props.enum).filter((value) => isNaN(Number(value)));

    let width = props.width ? props.width : "w-40";

    return (
        <div className="h-8 w-fit relative">
            <label
                htmlFor="name"
                className={`absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-main-text-darker z-[1] ${props.labelBackground}`}
            >
                {props.text ?? ""}
            </label>
            <Listbox value={props.selectedOption} onChange={props.setSelectedOption} disabled={props.disabled}>
                <div className="relative">
                <Listbox.Button className={`relative rounded-md ${props.disabled ? "bg-gray-200" : "bg-white"} py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm cursor-pointer items-center flex border border-gray-300 h-8 z-[0] ${width}`}>
                    <span className="block truncate">{props.enumToDisplayName ? props.enumToDisplayName(props.selectedOption) : capitalize(props.selectedOption.toString())}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className={`absolute mt-1 max-h-60 ${width} overflow-auto thin-scrollbar rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm cursor-pointer ${props.raiseZIndexOnOptions ? SUPER_HIGH_Z_INDEX : "z-[99]"}`}>
                    {enumEntries.map((option, optionIdx) => (
                        <Listbox.Option
                        key={optionIdx}
                        className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 h-8 ${
                            active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                            } z-[999]`
                        }
                        value={option}
                        >
                        {({ selected }) => (
                            <>
                            <span
                                className={`block truncate ${
                                selected ? 'font-normal' : 'font-normal'
                                }`}
                            >
                                {props.enumToDisplayName ? props.enumToDisplayName(option) : capitalize(option)}
                            </span>
                            {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                            </>
                        )}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </Listbox>
        </div>
    )
}