import { ACTIONS, CallBackProps } from "react-joyride";
import { TourConfig, Step } from "../types";
import WelcomeScreen from "../modals/WelcomeScreen";
import EndScreen from "../modals/EndScreen";
import { TOUR_IDS, tourStyles } from "../constants";
import { MicrophoneIcon, DocumentTextIcon, SparklesIcon, BoltIcon, ArrowsRightLeftIcon, HandThumbDownIcon, BookOpenIcon, BoldIcon, ItalicIcon, UnderlineIcon } from '@heroicons/react/24/solid';

export enum ScribeBasicTourIdentifiers {
  TYPING_PATIENT_NAME = 0,
  SELECTING_TEMPLATE_ON_SCRIBE = 1,
  SELECTING_MICROPHONE_ON_SCRIBE = 2,
  START_RECORDING = 3,
  STOP_RECORDING = 4,
  RESUME_RECORDING = 5,
  START_PROCESSING_NOTES = 6,
  NOTES_LOADING = 7,
  NOTES_READY = 8,
  COPY_FEEDBACK_TOGGLE = 9,
  DAVID_INTERACTION = 10,
  ADD_TO_VISIT_BUTTON = 11,
  ACTIONS_BUTTON_MENU = 12,
  RECORDS_RECAP_TAB = 13,
  CLIENT_DISCHARGE_TAB = 14,
}

const DEMO_TOOLBAR = (
  <div
    className="bg-blue-50 p-2 rounded-lg flex space-x-2 min-h-12 pointer-events-none
              ring-2 ring-blue-200 ring-opacity-50
              shadow-[0_0_15px_rgba(59,130,246,0.3)]"
  >
    <button
      className="px-2 py-2 bg-white text-gray-900 rounded shadow-sm ring-1 ring-inset ring-gray-300"
      title="Bold"
    >
      <BoldIcon className="h-4 w-4"/>
    </button>
    <button
      className="px-2 py-2 bg-white text-gray-900 rounded shadow-sm ring-1 ring-inset ring-gray-300"
      title="Italics"
    >
      <ItalicIcon className="h-4 w-4"/>
    </button>
    <button
      className="px-2 py-2 bg-white text-gray-900 rounded shadow-sm ring-1 ring-inset ring-gray-300"
      title="Underline"
    >
      <UnderlineIcon className="h-4 w-4"/>
    </button>
    <div className="w-px bg-gray-300 mx-2"></div>
    <button
      className="px-2 py-2 bg-main-button text-main-button-text rounded shadow-sm"
      title="Add Custom Vocabulary"
    >
      <BookOpenIcon className="h-4 w-4 text-white"/>
    </button>
    <button
      className="px-2 py-2 bg-main-button text-main-button-text rounded shadow-sm"
      title="Find and replace"
    >
      <ArrowsRightLeftIcon className="h-4 w-4 text-white"/>
    </button>
    <button
      className="px-2 py-2 bg-main-button text-main-button-text rounded shadow-sm"
      title="Provide negative feedback on selected text"
    >
      <HandThumbDownIcon className="h-4 w-4 text-white"/>
    </button>
  </div>
);

export const SCRIBE_BASIC_STEPS_DEFINITION: Step[] = [
  {
    id: 'TYPING_PATIENT_NAME',
    target: '[data-tour-id="patient-name-input"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>First things first! Enter your <span className="text-blue-600 font-semibold">patient's name</span> here to set the stage.</span>
      </div>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    placement: 'bottom',
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: 'SELECTING_TEMPLATE_ON_SCRIBE',
    target: '[data-tour-id="template-picker"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Next, pick the right <span className="text-blue-600 font-semibold">template</span> for VetRec to use. 🎨</span>
      </div>
    ),
    spotlightClicks: true,
    placement: 'left',
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: 'SELECTING_MICROPHONE_ON_SCRIBE',
    target: '[data-tour-id="microphone-picker"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Got multiple mics? Choose your <span className="text-blue-600 font-semibold">microphone</span> here. 🎤</span>
      </div>
    ),
    spotlightClicks: true,
    placement: 'top',
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: 'START_RECORDING',
    target: '[data-tour-id="record-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Hit this button to <span className="text-blue-600 font-semibold">start recording</span> when you’re all set. 🎬</span>
        <span>We’ll capture every detail while you focus on the patient.</span>
      </div>
    ),
    spotlightClicks: true,
    spotlightPadding: 0,
    styles: {
      buttonNext: tourStyles.buttons.next.disabled,
      spotlight: {
        ...tourStyles.spotlight.pulsing,
        borderRadius: '9999px',
      },
    },
  },
  {
    id: 'STOP_RECORDING',
    target: '[data-tour-id="record-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>All done? Click here to <span className="text-blue-600 font-semibold">stop recording</span>. ⏹️</span>
      </div>
    ),
    spotlightClicks: true,
    spotlightPadding: 0,
    styles: {
      buttonNext: tourStyles.buttons.next.disabled,
      buttonBack: tourStyles.buttons.back.disabled,
      spotlight: {
        ...tourStyles.spotlight.pulsing,
        borderRadius: '9999px',
      },
    },
  },
  {
    id: 'RESUME_RECORDING',
    target: '[data-tour-id="record-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>You can <span className="text-blue-600 font-semibold">resume recording</span> as many times as you need and keep adding to your notes! 🔄</span>
      </div>
    ),
    spotlightClicks: true,
    spotlightPadding: 0,
    styles: {
      spotlight: {
        ...tourStyles.spotlight.pulsing,
        borderRadius: '9999px',
      },
      buttonBack: tourStyles.buttons.back.disabled,
    },
  },
  {
    id: 'START_PROCESSING_NOTES',
    target: '[data-tour-id="process-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Once you're ready, click here to <span className="text-blue-600 font-semibold">generate your notes</span>! ⚡</span>
        <span>We’ll turn your conversation into polished notes in no time.</span>
      </div>
    ),
    spotlightClicks: true,
    spotlightPadding: 0,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
      buttonNext: tourStyles.buttons.next.disabled,
    },
  },
  {
    id: 'NOTES_LOADING',
    target: '[data-tour-id="notes-loading"]',
    content: (
      <div className="flex flex-col items-center justify-center space-y-3 text-center leading-relaxed">
        <p className="text-gray-700">
          Hang tight! Your notes are on the way. We’re weaving together your words into your <span className="text-blue-600 font-semibold">notes</span>.
        </p>
        <p className="text-gray-700">
          Grab a quick sip of coffee while we handle the heavy lifting! ☕
        </p>
      </div>
    ),
    styles: {
      buttonNext: tourStyles.buttons.next.disabled,
      buttonBack: tourStyles.buttons.back.disabled,
      spotlight: tourStyles.spotlight.transparent,
    },
  },
  {
    id: 'NOTES_READY',
    target: '.quill',
    content: (
      <div className="flex flex-col items-center justify-center space-y-3 text-center leading-relaxed">
        <div className="text-lg font-medium">
          Voilà! Your <span className="text-blue-600 font-semibold">notes</span> are ready. ✨
        </div>
        <div className="text-gray-700">
          Review and edit your notes directly in the editor. Try <span className="text-blue-600 font-semibold">highlighting text</span> to access <span className="text-blue-600 font-semibold">formatting</span>, <span className="text-blue-600 font-semibold">custom vocabulary</span>, and other powerful tools!
        </div>
        {DEMO_TOOLBAR}
      </div>
    ),
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
      buttonBack: tourStyles.buttons.back.disabled,
    },
  },
  {
    id: 'COPY_FEEDBACK_TOGGLE',
    target: '[data-tour-id="copy-feedback-toggle"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Use these controls to <span className="text-blue-600 font-semibold">copy</span> notes, <span className="text-blue-600 font-semibold">provide feedback</span>, or toggle sections.</span>
      </div>
    ),
    spotlightPadding: 8,
    placement: 'left-start',
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: 'DAVID_INTERACTION',
    target: '[data-tour-id="chat-button"]',
    spotlightClicks: true,
    spotlightPadding: 8,
    content: (
      <div className="flex flex-col items-center justify-center space-y-3 text-center leading-relaxed">
        <div className="text-lg font-medium">
          Meet <span className="text-blue-600 font-semibold">D.A.V.I.D.</span>! 🤖
        </div>
        <div className="text-gray-700">
          Click here to chat with D.A.V.I.D.! Ask questions about the case, get <span className="text-blue-600 font-semibold">differential diagnoses</span>, or explore insights about the case. Go ahead, give it a try! 💬
        </div>
        <div className="pt-2">
          <a 
            href="https://help.vetrec.io/en/articles/10140373-chat-with-d-a-v-i-d"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-main-button rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Learn more about D.A.V.I.D.
          </a>
        </div>
      </div>
    ),
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: 'ADD_TO_VISIT_BUTTON',
    target: '[data-tour-id="add-to-visit-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <div className="pt-2">
          <span>Use <span className="text-blue-600 font-semibold">Add to Visit</span> to add more recordings to the visit and generate new notes with the updated information.</span>
        </div>
      </div>
    ),
    spotlightPadding: 8,
    placement: 'left',
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: 'ACTIONS_BUTTON_MENU',
    target: '[data-tour-id="actions-button-menu"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Perform actions like <span className="text-blue-600 font-semibold">Regenerate Notes</span>, <span className="text-blue-600 font-semibold">Export to your PIMS</span>, and more by expanding the <span className="text-blue-600 font-semibold">Actions</span> menu.</span>
      </div>
    ),
    spotlightPadding: 8,
    placement: 'left',
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: 'RECORDS_RECAP_TAB',
    target: '[data-tour-id="records-recap-tab"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Need to review previous records? Access <span className="text-blue-600 font-semibold">Records Recap</span> from this tab or the left menu to get quick summaries of <span className="text-blue-600 font-semibold">external records</span>! 📋</span>
        <div className="pt-2">
          <a 
            href="https://help.vetrec.io/en/articles/9745614-summarizing-prior-records-with-records-recap"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-main-button rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Learn more about Records Recap
          </a>
        </div>
      </div>
    ),
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.default,
      buttonBack: tourStyles.buttons.back.disabled
    },
  },
  {
    id: 'CLIENT_DISCHARGE_TAB',
    target: '[data-tour-id="client-tab"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Use the <span className="text-blue-600 font-semibold">Client</span> tab to generate client <span className="text-blue-600 font-semibold">discharge notes</span> and other client related notes.</span>
      </div>
    ),
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  }
];

const clickActionsButton = async () => {
  const actionsButton = document.querySelector('[data-tour-id="actions-button"]');
  if (actionsButton instanceof HTMLElement) {
    actionsButton.click();
    await new Promise(resolve => setTimeout(resolve, 120)); 
  }
}

const stepHandler = {
  [ScribeBasicTourIdentifiers.ADD_TO_VISIT_BUTTON]: async (data: CallBackProps) => {
    if (data.action === ACTIONS.NEXT) {
      await clickActionsButton();
    }
  },
  [ScribeBasicTourIdentifiers.RECORDS_RECAP_TAB]: async (data: CallBackProps) => {
    if (data.action === ACTIONS.PREV) {
      await clickActionsButton();
    }
  }
};

const welcomeScreenProps = {
  icon: <BoltIcon />,
  title: (
    <>
      Welcome to{' '}
      <span className="font-bold">
        <span className="text-blue-600">VetRec</span>!
      </span>{' '}🐶🐱🩺
    </>
  ),
  description: "We've built VetRec to help veterinarians focus on what matters most — caring for pets. Our AI-powered system transforms your visits into detailed SOAP notes in seconds.",
  steps: [
    {
      icon: <MicrophoneIcon />,
      title: "1. Start Recording",
      description: "Begin your visit and let VetRec listen."
    },
    {
      icon: <DocumentTextIcon />,
      title: "2. Conduct Visit",
      description: "Focus on the patient while we take notes."
    },
    {
      icon: <SparklesIcon />,
      title: "3. Get SOAP Notes",
      description: "Review and edit the generated notes."
    }
  ],
};

export const ScribeBasicTourConfig: TourConfig = {
  steps: SCRIBE_BASIC_STEPS_DEFINITION,
  tourId: TOUR_IDS.SCRIBE_BASIC,
  stepHandler: stepHandler,
  WelcomeModal: (props) => <WelcomeScreen {...props} {...welcomeScreenProps} />,
  EndModal: (props) => (
    <EndScreen
      {...props}
      icon="🎊"
      title="That’s it!"
      description="Ready to customize your templates or learn more?"
      primaryButtonText="All done for now"
      secondaryActions={[
        { text: "Take me to templates", navigateTo: "/templates" },
        { text: "Read more about templates", url: "https://help.vetrec.io/en/collections/8918950-templates" }
      ]}
    />
  ),
  showConfetti: true,
};
