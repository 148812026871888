import { useState, type FC, useRef, useEffect, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { SectionType, SectionTypes, TemplatePermissions, TemplateType, communicationTemplateTones, doesTemplateTypeSupportIntegrations, getTemplateTypeDisplayName, jsonToText } from './TemplateUtils'
import { ChatBubbleLeftEllipsisIcon, CloudIcon, Cog6ToothIcon, DocumentDuplicateIcon, DocumentPlusIcon, ExclamationTriangleIcon, EyeIcon, LinkIcon, PaintBrushIcon, PencilIcon, ShareIcon, StarIcon, WrenchIcon } from '@heroicons/react/24/solid'
import { Section } from './Section'
import { useTemplate } from './TemplateProvider'
import { MainButton } from '../MainButton'
import Reminder from '../ReminderToast'
import { Spinner } from '../../utils/Spinner'
import Error from '../Error'
import { physical_exam, simpleSection, vitals, simpleAssessment, fearfree, CanineDentalChart, FelineDentalChart, advancedSubjective, advancedPlan, timelineSection, spacesSection, generalInstructionsSection } from './SectionDefinitions'
import { chronicIssuesEntry, currentDietEntry, currentMedicationEntry, currentPreventatitvesEntry, diagnosticTestsEntry, dietEntry, medicationEntry, owner_discussion, simpleEntry, treatmentsEntry, vaccinesEntry } from './EntryDefinitions'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { preprocessText } from '../../utils/TextUtils'
import MoreMenu, { MoreMenuOption } from '../moreMenu'
import TemplateGenerateModal from './TemplateGenerateModal'
import TemplateConfigModal from './TemplateConfigModal'
import TemplateStylingModal from './TemplateStylingModal'
import { UserAction } from '../../utils/vercelAnaytics'
import { useUser } from '@clerk/clerk-react'
import EnumPicker from '../EnumPicker'
import { Loading } from '../../utils/Loading'
import { RadioGroup } from '@headlessui/react'
import DraggableSections from './DraggableSections'
import va from '@vercel/analytics';
import TemplateShareModal from './TemplateShareModal'
import { useTour } from '../../providers/TourProvider'
import { TOUR_IDS } from '../tours/constants'
import { useVetRec } from '../../providers/VetRecProvider'
import { isMobileDevice } from '../../utils/deviceUtils'
import { TemplateEditorTourIdentifiers } from '../tours/templateEditor/config'
import { CallBackProps } from 'react-joyride'
import { ACTIONS } from 'react-joyride'
import { useNavigate } from 'react-router-dom';
import TemplateUpdatedGenerateModal from './TemplateUpdatedGenerateModal'
import { Switch } from '@headlessui/react'
import UndoAlert from './UndoAlert'
import UnsavedChangesBanner from './UnsavedChangesBanner'
import { WarningBanner } from '../common/WarningBanner'
import LockUnlockButton from '../common/LockUnlockButton'

export const MainTemplate: FC = () => {
  const {items, addSection, name, updateName, saveTemplate, validateTemplate, overrideTemplate, styling, handleSectionOver, sectionHoverIndex, addEntryToItem, handleSectionDragged, handleEntryDragged, templateType, updateTemplateType, templateLoading, templateTone, handleTemplateTone, templateId, shouldSetAsDefault, updateShouldSetAsDefault, existingTemplate, isSuperOrgTemplate, shouldApplySuperOrgPermissions, duplicate, editLockedToOwnerLoading, editLockedToOwner, isPersonalTemplate, isTemplateOwner, templateOwner, onEditLockedToOwnerChanged, hasPermission, undoMessage, dismissUndo, handleUndo, templateCreateEnabledPermission, templateEditEnabledPermission} = useTemplate()
  const  navigate = useNavigate()
  const itemsRef = useRef<SectionType[]>(items)
  const [remainder, setRemainder] = useState<boolean>(false)
  const [remainderText, setRemainderText] = useState<string>()
  const [remainderLineColor, setRemainderLineColor] = useState<string>()
  const [remainderIconColor, setRemainderIconColor] = useState<string>()
  const [saving, setSaving] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [preview, setPreview] = useState<boolean>(false)
  const [previewNotes, setPreviewNotes] = useState<string>()
  const [templateGenerate, setTemplateGenerate] = useState<boolean>(false)
  const [templateConfig, setTemplateConfig] = useState<boolean>(false)
  const [templateStyling, setTemplateStyling] = useState<boolean>(false)
  const [templateShowAdvancedInSettings, setTemplateShowAdvancedInSettings] = useState<boolean>(true)
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
  const [templateToShare, setTemplateToShare] = useState<string>()
  const [moreMenuOptions, setMoreMenuOptions] = useState<MoreMenuOption[]>([])
  const { user } = useUser()
  const {isTourRunning, currentStep, setCurrentStep, startTour} = useTour()
  const {checkTourStatus} = useVetRec()
  const isMobile = isMobileDevice()
  const [initialTemplateState, setInitialTemplateState] = useState<string>("");
  const [showUnsavedBanner, setShowUnsavedBanner] = useState<boolean>(false);

  useEffect(() => {
    itemsRef.current = items

    // Update templateShowAdvancedInSettings based on whether there's a general instructions section
    setTemplateShowAdvancedInSettings(!items.some(section => section.type === SectionTypes.GENERAL_INSTRUCTIONS))
  }, [items])

  useEffect(() => {
    if (templateLoading) return;

    const hasCompletedTour = checkTourStatus(TOUR_IDS.TEMPLATE_EDITOR)
    if (isTourRunning || hasCompletedTour) return;

    if (!isMobile) {
      startTour({ tourId: TOUR_IDS.TEMPLATE_EDITOR, stepHandler: {
        [TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_PREVIEW_CONTENT]: async (data: CallBackProps) => {
          if (data.action === ACTIONS.NEXT || data.action === ACTIONS.PREV) {
            setPreview(false)
            await new Promise(resolve => setTimeout(resolve, 100)); 
            if (data.action === ACTIONS.NEXT) {
              const sectionDropdown = document.querySelector('[data-tour-id="template-section-entries-button"]')
              if (sectionDropdown instanceof HTMLElement) {
                sectionDropdown.click()
              }
            }
          }
        }, 
        [TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_TYPE_SELECTION]: async (data: CallBackProps) => {
          if (data.action === ACTIONS.NEXT) {
            updateTemplateType(TemplateType.COMMUNICATIONS)
          }
        }, 
        [TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_CLIENT_TYPE_TONE]: async (data: CallBackProps) => {
          if (data.action === ACTIONS.NEXT) {
            updateTemplateType(TemplateType.MEDICAL)
          }
        }, 
        [TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_SECTION_NAME_EDIT]: async (data: CallBackProps) => {
          if (data.action === ACTIONS.NEXT) {
            const sectionDropdown = document.querySelector('[data-tour-id="template-section-type-selection-button"]')
            if (sectionDropdown instanceof HTMLElement) {
              sectionDropdown.click()
              await new Promise(resolve => setTimeout(resolve, 100)); 
            }
          }
        },
        [TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_ENTRY_HIGHLIGHT]: async (data: CallBackProps) => {
          if (data.action === ACTIONS.NEXT) {
            const entryDropdown = document.querySelector('[data-tour-id="template-entry-type-edit-button"]')
            if (entryDropdown instanceof HTMLElement) {
              entryDropdown.click()
            }
          }
        },
      }})
    }
  }, [templateLoading, isTourRunning, startTour, isMobile, checkTourStatus])
  
  useEffect(() => {
    if (!isTourRunning) return;

    if (currentStep === TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_NAME_INPUT + 1) {
      if (!name) {
        updateName("Onboarding Tutorial Template")
      } 
    }
  }, [isTourRunning, currentStep, name, updateName])

  useEffect(() => {
    if (!templateLoading) {
      setInitialTemplateState(JSON.stringify({ name, items, styling }));
    }
  }, [templateLoading]);

  const currentTemplateState = JSON.stringify({ name, items, styling });
  const unsavedChanges = currentTemplateState !== initialTemplateState;

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        e.preventDefault();
        (e as any).returnValue = '';
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [unsavedChanges]);

  useEffect(() => {
    if (unsavedChanges) {
      setShowUnsavedBanner(true);
    }
  }, [currentTemplateState]);

  function handleSectionDrop(item: { name: string }){
    let item_name = item.name.toLowerCase().replace(/\s+/g, '_')
    if(item_name === "section"){
      addSection(simpleSection)
    }
    else if(item_name === "timeline"){
      addSection(timelineSection)
    }
    else if (item_name === "vitals"){
      addSection(vitals)
    }
    else if (item_name === "physical_exam"){
      addSection(physical_exam)
    }
    else if (item_name === "subjective"){
      addSection(advancedSubjective)
    }
    else if (item_name === "assessment"){
      addSection(simpleAssessment)
    }
    else if (item_name === "plan"){
      addSection(advancedPlan)
    }
    else if (item_name === "fear_free"){
      addSection(fearfree)
    }
    else if (item_name === "canine_dental_chart"){
      addSection(CanineDentalChart)
    }
    else if (item_name === "feline_dental_chart"){
      addSection(FelineDentalChart)
    } 
    else if (item_name === "spaces"){
      addSection(spacesSection)
    }
  }

  function handleEntryDrop(item: { name: string }){
    let item_name = item.name.toLowerCase().replace(" ", "_")

    if(item_name === "entry"){
      addEntryToItem(simpleEntry)
    }
    else if (item_name === "current_medication"){
      addEntryToItem(currentMedicationEntry)
    }
    else if (item_name === "current_diet"){
      addEntryToItem(currentDietEntry)
    }
    else if (item_name === "current_preventatives"){
      addEntryToItem(currentPreventatitvesEntry)
    }
    else if (item_name === "chronic_issues"){
      addEntryToItem(chronicIssuesEntry)
    }
    else if (item_name === "diagnostic_tests"){
      addEntryToItem(diagnosticTestsEntry)
    }
    else if (item_name === "treatments"){
      addEntryToItem(treatmentsEntry)
    }
    else if (item_name === "vaccines"){
      addEntryToItem(vaccinesEntry)
    }
    else if (item_name === "medication"){
      addEntryToItem(medicationEntry)
    }
    else if (item_name === "diet"){
      addEntryToItem(dietEntry)
    }
    else if (item_name === "owner_discussion"){
      addEntryToItem(owner_discussion)
    }
  }
  
  const [{isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.SECTION, ItemTypes.TIMELINE, ItemTypes.RENDERED_SECTION, ItemTypes.RENDERED_ENTRY, ItemTypes.ENTRY, ItemTypes.VITALS, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.OWNER_DISCUSSION, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.MEDICATION, ItemTypes.DIET, ItemTypes.CANINE_DENTAL_CHART, ItemTypes.FELINE_DENTAL_CHART, ItemTypes.SPACES],
    drop: (item:any, monitor) => {
      if([ItemTypes.SECTION, ItemTypes.TIMELINE, ItemTypes.VITALS, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.CANINE_DENTAL_CHART, ItemTypes.FELINE_DENTAL_CHART, ItemTypes.SPACES].includes(item['name'].toLowerCase().replace(/\s+/g, '_'))){
        handleSectionDrop(item)
      }
      else if (sectionHoverIndex !== "999999999" && [ItemTypes.ENTRY, ItemTypes.CHRONIC_ISSUES, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.OWNER_DISCUSSION, ItemTypes.TREATMENTs, ItemTypes.VACCINES].includes(item['name'].toLowerCase().replace(/\s+/g, '_'))){
        handleEntryDrop(item)
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item, monitor) {
      if([ItemTypes.SECTION, ItemTypes.TIMELINE, ItemTypes.VITALS, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.FELINE_DENTAL_CHART, ItemTypes.CANINE_DENTAL_CHART, ItemTypes.SPACES].includes(item['name'].toLowerCase().replace(/\s+/g, '_'))){
        handleSectionDragged(true)
        handleSectionOver("999999999")
      }
      else{
        handleEntryDragged(true)
      }
    },
  }))

  const handleTemplateSave = async () => {
    try {
      let [validationMessage, validation] = validateTemplate()
      if(!validation){
        setErrorMessage(validationMessage)
      }
      else if (!saving){
        setSaving(true)
        setRemainder(true)
        setRemainderLineColor("bg-blue-500")
        setRemainderIconColor("text-blue-400")
        setRemainderText("Saving template...")
        await saveTemplate()
        setInitialTemplateState(JSON.stringify({ name, items, styling }));
        navigate("/templateBuilder?templateId=" + templateId)
        setRemainderLineColor("bg-green-500")
        setRemainderIconColor("text-green-400")
        setRemainderText("Template saved successfully.")
        setSaving(false)
      }
    } catch (ex) {
      setErrorMessage("There was an issue saving the template. Please try again.")
    }
  }

  const handleTemplatePreview = () => {
    UserAction(user?.primaryEmailAddress?.emailAddress ?? "", "TemplatePreview")
    setPreview(!preview)
    setPreviewNotes(preview ? undefined : preprocessText(jsonToText(items, styling)))
    if (isTourRunning && currentStep === TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_PREVIEW) {
      setCurrentStep(TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_PREVIEW + 1)
    }
  }

  const handleTemplateGenerate = () => {
    UserAction(user?.primaryEmailAddress?.emailAddress ?? "", "TemplateGenerate")
    setTemplateGenerate(true)
  }

  const handleTemplateIntegration = () => {
    UserAction(user?.primaryEmailAddress?.emailAddress ?? "", "TemplateIntegration")
    setTemplateConfig(true)
  }

  const handleTemplateStyling = () => {
    UserAction(user?.primaryEmailAddress?.emailAddress ?? "", "TemplateStyling")
    setTemplateStyling(true)
  }

  const handleTemplateAdvanced = () => {
    addSection(generalInstructionsSection, true)
  }

  useEffect(() => {
    setPreviewNotes(preview ? preprocessText(jsonToText(items, styling)) : undefined)
  }, [items, styling])

  useEffect(() => {
    const options = []
    
    if (doesTemplateTypeSupportIntegrations(templateType) && hasPermission(TemplatePermissions.EDIT_INTEGRATIONS)) {
      options.push({
        name:"Integrations",
        action: () => handleTemplateIntegration(),
        icon: <LinkIcon className=" mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"/>
      })
    }

    if(hasPermission(TemplatePermissions.EDIT_STYLING)){
      options.push({
        name:"Styling",
        action: () => handleTemplateStyling(),
        icon: <PaintBrushIcon className=" mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"/>
      })
    }

    if (templateShowAdvancedInSettings && hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS)){
      options.push({
        name:"General Instructions",
        action: () => handleTemplateAdvanced(),
        icon: <ChatBubbleLeftEllipsisIcon className=" mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"/>
      })
    }

    setMoreMenuOptions(options)
  }, [templateType, isSuperOrgTemplate, duplicate, editLockedToOwner, templateCreateEnabledPermission, templateShowAdvancedInSettings])

  async function handleShareTemplate(): Promise<void> {
    setShareModalOpen(true)
    setTemplateToShare("dynamic_" + templateId)
    let properties =  {
        date:(new Date()).toUTCString(),
        template: templateId,
    }
    va.track(`Template_Shared`, properties)
  }

  async function handleDuplicateTemplate(): Promise<void> {
    const url = `/templateBuilder?templateId=${templateId}&duplicate=true`;
    const properties = {
      date: new Date().toUTCString(),
      template: templateId,
    };
  
    va.track(`Template_Duplicate`, properties);
  
    // Trigger a full page refresh
    window.location.href = url;
  }

  async function handleTemplateLockChange(locked: boolean) {
    try {
      await onEditLockedToOwnerChanged(locked)
    } catch (error) {
      setErrorMessage(`There was an issue ${locked ? "locking" : "unlocking"} the template. Please try again.`)
    }
  }

  const isOriginalSuperOrgTemplate = useMemo(() => isSuperOrgTemplate && !duplicate, [isSuperOrgTemplate, duplicate])
  const isTemplateEditLocked = useMemo(() => existingTemplate && !isTemplateOwner && editLockedToOwner, [existingTemplate, isTemplateOwner, editLockedToOwner])

  return (
  <>
    {templateLoading && <Loading text='Loading template...'/>}
    {!templateLoading && <div className="flex flex-col gap-y-4 h-full">
      {isSuperOrgTemplate && !duplicate && <div className='bg-yellow-100 font-semibold p-4 rounded-md shadow flex items-center gap-x-2 justify-center'>
        <ExclamationTriangleIcon className='h-4 w-4 text-orange-600'/>
        This template is managed by your organization. To make changes, please duplicate.
      </div>}
      {((isSuperOrgTemplate && duplicate) || shouldApplySuperOrgPermissions) && !hasPermission(...Object.values(TemplatePermissions)) && <div className='bg-yellow-100 font-semibold p-4 rounded-md shadow flex items-center gap-x-2 justify-center'>
        <ExclamationTriangleIcon className='h-4 w-4 text-yellow-600'/>
        This template is a duplicate of a template managed by your organization, some fields might be locked for editing.
      </div>}
      {(!templateCreateEnabledPermission || !templateEditEnabledPermission) && <div className={`bg-yellow-100 w-full shadow-lg rounded-lg px-4 flex flex-col sm:flex-row justify-between items-center gap-x-8 overflow-hidden py-6`}>
        <div className='flex flex-row gap-x-2 items-center'>
          <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
          <div className='text-center lg:text-left'>
            {!templateCreateEnabledPermission && !templateEditEnabledPermission && <><span className='font-semibold'>Template creation and editing are disabled.</span> Contact your team admin to enable this feature.</>}
            {!templateCreateEnabledPermission && templateEditEnabledPermission && <><span className='font-semibold'>Template creation is disabled.</span> Contact your team admin to enable this feature.</>}
            {templateCreateEnabledPermission && !templateEditEnabledPermission && <><span className='font-semibold'>Template editing is disabled.</span> Contact your team admin to enable this feature.</>}
          </div>
        </div>
      </div>}
      {isPersonalTemplate && <WarningBanner warningText="This is a personal template. Your teammates cannot view it and will have limited access to notes you generate with this" />}
      {!isOriginalSuperOrgTemplate && isTemplateEditLocked && <WarningBanner warningText={`This template can only be edited by the template's owner (${templateOwner}).`} />}
      <div className='flex flex-col sm:flex-row justify-between items-center gap-y-4 gap-x-4'>
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row gap-y-2 gap-x-4 items-center mb-2'>
              <input data-tour-id="template-name-input" placeholder="Template Name" className={`focus:rounded-md border-gray-300 h-8 ${!name ? "ring-2 ring-red-600 rounded-md" : "border-t-0 border-r-0 border-l-0"} ${hasPermission(TemplatePermissions.EDIT_NAME) ? "text-gray-900":"text-gray-400"} w-full sm:w-48 lg:w-80`} disabled={!hasPermission(TemplatePermissions.EDIT_NAME)} value={name} onChange={(event) => updateName(event.target.value)}/>
              {!isPersonalTemplate && !isOriginalSuperOrgTemplate && templateEditEnabledPermission && <LockUnlockButton
                disabled={!isTemplateOwner && existingTemplate}
                isLoading={editLockedToOwnerLoading} 
                isLocked={editLockedToOwner} 
                onIsLockedChanged={handleTemplateLockChange} 
                tooltipText={editLockedToOwner ? `Only ${isTemplateOwner ? "you" : "owner"} can edit` : "Any teammates can edit"} />}
            </div>
            <div data-tour-id="template-type-selection" className='flex flex-col sm:flex-row gap-y-2 gap-x-4 items-start sm:items-center'>
              <div className='flex flex-row gap-x-2 items-center'>
                <span className='truncate'>Template Type: </span>
                <EnumPicker  
                  enum={TemplateType} 
                  selectedOption={templateType} 
                  setSelectedOption={(value) => updateTemplateType(TemplateType[value.toUpperCase().replace(" ", "_") as keyof typeof TemplateType])} 
                  width='min-w-44 sm:w-auto'
                  enumToDisplayName={getTemplateTypeDisplayName}
                  raiseZIndexOnOptions={isTourRunning && currentStep === TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_TYPE_SELECTION}
                  disabled={!hasPermission(TemplatePermissions.EDIT_TEMPLATE_TYPE)}
                />
              </div>

              {!existingTemplate && <div className='flex flex-row gap-x-2 items-center'>
                <span>Set as default:</span>
                <Switch
                  checked={shouldSetAsDefault}
                  onChange={updateShouldSetAsDefault}
                  className={`
                    ${shouldSetAsDefault ? 'bg-blue-600' : 'bg-gray-200'}
                    relative inline-flex h-6 w-11 items-center rounded-full
                    transition-colors ease-in-out duration-200
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 
                    disabled:opacity-50
                  `}
                >
                  <span className="sr-only">Set this template as default</span>
                  <StarIcon
                    className={`
                      ${shouldSetAsDefault ? 'translate-x-6' : 'translate-x-1'}
                      h-4 w-4 transform transition-transform
                      ${shouldSetAsDefault ? 'text-white' : 'text-gray-400'}
                    `}
                  />
                </Switch>
              </div>}
            </div>
          </div>              
          <div className='flex flex-col gap-y-2'>
            {hasPermission(TemplatePermissions.EDIT_SECTION_NAMES, TemplatePermissions.EDIT_ENTRY_NAMES, TemplatePermissions.EDIT_DEFAULTS, TemplatePermissions.EDIT_INSTRUCTIONS, TemplatePermissions.EDIT_ORDER, TemplatePermissions.EDIT_INPUT_TYPE) && <div data-tour-id="template-import-with-ai" className='flex flex-row gap-x-2 border-blue-600 border rounded-md items-center justify-center px-3.5 py-2.5 text-sm font-semibold h-8 cursor-pointer hover:bg-blue-100 shadow-sm mb-2' onClick={() => handleTemplateGenerate()} title='Import existing templates with VetRec AI'>
              <span className='truncate'>Import Template with AI</span>
              <DocumentPlusIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-300 sm:hidden xl:block"/>
            </div>}
            <div className='flex flex-row gap-x-2 items-center w-full'>
              <MainButton dataTourId={!preview ? "template-preview-button" : undefined} title={preview ? "Edit notes in builder" : "Preview notes"} text={preview ? "Edit" : "Preview"} icon={preview ? <PencilIcon className='h-4 w-4 text-white' /> : <EyeIcon className='h-4 w-4 text-white' />} action={handleTemplatePreview} size='h-8'/>
              {(isOriginalSuperOrgTemplate || isTemplateEditLocked) && <MainButton 
                title={"Duplicate"} 
                text="Duplicate" 
                icon={<DocumentDuplicateIcon className='h-4 w-4 text-white' />} 
                action={handleDuplicateTemplate} 
                size='h-8'/>}
              {!isOriginalSuperOrgTemplate && !isTemplateEditLocked && templateCreateEnabledPermission && <MainButton 
                dataTourId='template-save-button' 
                title={"Save template"} 
                text="Save" 
                icon={remainder ? <Spinner size='h-4 w-4' timer={false} /> : <CloudIcon className='h-4 w-4 text-white' />} 
                action={handleTemplateSave} 
                size='h-8' 
                disabled={saving}/>}
              {!isSuperOrgTemplate && <MainButton
                dataTourId='template-share-button'
                title={existingTemplate ? "Share template" : "Save this template to enable sharing"}
                text="Share"
                icon={<ShareIcon className='h-4 w-4 text-white' />}
                action={handleShareTemplate}
                disabled={!existingTemplate}
                size='h-8' />}
              {moreMenuOptions.length > 0 && <MoreMenu
                buttonDataTourId='template-settings-button'
                raiseMenuZIndex={isTourRunning && currentStep === TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_SETTINGS_BUTTON}
                moreText="Settings" 
                moreIcon={<Cog6ToothIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />} 
                options={moreMenuOptions} height='h-8'/>}
            </div>
          </div>
      </div>
      <div className='grid grid-cols-4 mt-2 gap-x-4 border-t-2 border-gray-300/30 h-[50vh] sm:h-[60vh] lg:h-[65vh] xl:h-[75vh]'>
        <div className={`${hasPermission(TemplatePermissions.EDIT_ORDER) ? "col-span-3" : "col-span-4"} h-full overflow-y-auto thin-scrollbar`}>
          {templateType === TemplateType.COMMUNICATIONS && <div data-tour-id="template-client-type-tone" className='w-full'>
            <RadioGroup value={templateTone} onChange={handleTemplateTone} className="mt-2 w-full" disabled={!hasPermission(TemplatePermissions.EDIT_TEMPLATE_TYPE)}>
              <RadioGroup.Label className="font-medium">What tone and style should the information be in?</RadioGroup.Label>
              <div className='flex flex-row gap-x-4 mt-4 w-full'>
              {communicationTemplateTones.map((tone) => (
                <RadioGroup.Option
                  key={tone.value}
                  value={tone.value}
                  className={`${templateTone === tone.value ? 'bg-blue-600 text-white' : 'bg-blue-100 text-gray-900 hover:bg-blue-300'} rounded-md px-3 py-2 text-sm font-medium cursor-pointer `}
                >
                  {tone.name}<br/>
                  <span className='font-light text-xs'>{tone.description}</span>
                </RadioGroup.Option>
              ))}
              </div>
            </RadioGroup>
          </div>}
          <div className={`mt-4`}>
            <div className='h-full'>
              <div style={{ overflow: 'hidden', clear: 'both' }} className='h-full'>
                {!preview && <div ref={drop} className="w-full h-full flex flex-col gap-y-4 overflow-y-auto overflow-x-hidden thin-scrollbar pb-20" data-testid="main-template">
                  {items.length > 0 && items.map((item, index) => (
                    <Section 
                      sectionDataTourId={index === 0 ? "template-section" : undefined} 
                      entryDataTourId={index === 0 ? "template-entry" : undefined} 
                      key={item.sectionKey} 
                      sectionKey={item.sectionKey} 
                      name={item.name}
                      emptyName={item.emptyName}
                      entries={item.entries} 
                      type={item.type} 
                      description={item.description} 
                      defaultValue={item.defaultValue} 
                      hardcodedValue={item.hardcodedValue} 
                      emptyHardcodedValue={item.emptyHardcodedValue}
                      raiseSectionTypePickerZIndex={index === 0 && isTourRunning && currentStep === TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_SECTION_TYPE_EDIT} 
                      raiseEntryTypePickerZIndex={index === 0 && isTourRunning && currentStep === TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_ENTRY_TYPE_EDIT}
                      ignoreSectionTypeUpdate={index === 0 && isTourRunning && currentStep === TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_SECTION_TYPE_EDIT}
                      ignoreEntryTypeUpdate={index === 0 && isTourRunning && currentStep === TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_ENTRY_TYPE_EDIT}
                      shouldExpandInstructions={index === 0 && isTourRunning && (currentStep >= TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_ENTRY_TYPE_EDIT && currentStep < TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_DRAG_AND_DROP)}
                      shouldExpandDefaults={index === 0 && isTourRunning && (currentStep >= TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_ENTRY_TYPE_EDIT && currentStep < TemplateEditorTourIdentifiers.TEMPLATE_EDITOR_DRAG_AND_DROP)}
                    />
                  ))}
                  {sectionHoverIndex === "999999999" && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
                  {items.length === 0 && <div className='flex flex-col items-center justify-center h-full'>
                    <div className='flex flex-col items-center gap-y-2'>
                      <div className='text-gray-500 text-lg'>Drag and drop a section</div>
                        <div className='text-gray-500 text-sm'>
                          Choose from empty sections or pre-built ones.
                        </div>
                      </div>
                    </div>
                  }
                </div>}
                {preview && <div>
                  <ReactQuill
                      theme='snow'
                      placeholder='Mr.Wiggles is looking mighty well today...'
                      className='block w-full h-[50vh]  sm:h-[60vh] lg:h-[65vh] xl:h-[70vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                      value={previewNotes}
                      readOnly
                      modules={{toolbar: false}}
                  />
                </div>}
              </div>
            </div>
          </div>
        </div>
        {hasPermission(TemplatePermissions.EDIT_ORDER) && <DraggableSections isTimelineSupported={templateType === TemplateType.RECORDSRECAP} />}
      </div>
    </div>}
    <Reminder show={remainder} hide={() => setRemainder(false)} title={remainderText ?? ""} text='' lineColor={remainderLineColor} iconColor={remainderIconColor}/>
    <Error error={errorMessage} setError={setErrorMessage}/>
    {unsavedChanges && showUnsavedBanner && (
      <UnsavedChangesBanner
        show={showUnsavedBanner}
        onSave={handleTemplateSave}
        onDismiss={() => setShowUnsavedBanner(false)}
      />
    )}
    <UndoAlert message={undoMessage} onUndo={handleUndo} onDismiss={dismissUndo} />
    <TemplateGenerateModal items={items} setItems={overrideTemplate} open={templateGenerate} setOpen={setTemplateGenerate} />
    <TemplateConfigModal open={templateConfig} setOpen={setTemplateConfig} items={items} setErrorMessage={setErrorMessage}/>
    <TemplateStylingModal open={templateStyling} setOpen={setTemplateStyling} />
    <TemplateShareModal open={shareModalOpen} setOpen={setShareModalOpen} templateToShare={templateToShare} setTemplateToShare={setTemplateToShare}/>
    <TemplateUpdatedGenerateModal/>
  </>
  )
}
