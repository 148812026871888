import { ArrowDownTrayIcon, BoltIcon, BookmarkIcon, ChatBubbleLeftEllipsisIcon, CheckBadgeIcon, CloudArrowUpIcon, DocumentCheckIcon, LanguageIcon, MicrophoneIcon, PlusCircleIcon, SquaresPlusIcon, UserCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaw } from "@fortawesome/free-solid-svg-icons"

type AuditType = 'VISIT_CREATED' | 'RECORDING_STARTED' | 'RECORDING_STOPPED' | 'VISIT_OWNER_CHANGED' | 'VISIT_UPDATED' | 'VISIT_PROCESSED' | 'VISIT_REPROCESSED' | 'VISIT_COMPLETED' | 'VISIT_NOTES_REPROCESSED' | 'VISIT_PREHISTORY' | 'VISIT_PREHISTORY_REPROCESSED' | 'VISIT_DISCHARGE' | 'VISIT_DISCHARGE_REPROCESSED' | 'VISIT_DISCHARGE_TRANSLATED' | 'VISIT_FEEDBACK' | 'VISIT_NOTES_SAVED' | 'VISIT_DISCHARGE_SAVED' | 'VISIT_RECAP_SAVED' | 'EXPORT_VETSPIRE' | 'EXPORT_EZYVET' | 'EXPORT_PULSE'  | 'EXPORT_INSTINCT' | 'VISIT_NOTES_PDF_GENERATED' | 'VISIT_DISCHARGE_PDF_GENERATED' | 'VISIT_PREHISTORY_PDF_GENERATED' | 'VISIT_DELETED' | 'COMMENT_ADDED'; 

export const iconAuditMapping = {
    VISIT_CREATED: <PlusCircleIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    RECORDING_STARTED: <MicrophoneIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    RECORDING_STOPPED: <MicrophoneIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_OWNER_CHANGED: <UserCircleIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    PATIENTS_UPDATED: <FontAwesomeIcon icon={faPaw} className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_UPDATED: <SquaresPlusIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_PROCESSED: <DocumentCheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_REPROCESSED: <DocumentCheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_COMPLETED: <CheckBadgeIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_NOTES_REPROCESSED: <DocumentCheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_PREHISTORY: <BoltIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_PREHISTORY_REPROCESSED: <BoltIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_PREHISTORY_DELETED: <BoltIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_DISCHARGE: <DocumentCheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_DISCHARGE_REPROCESSED: <DocumentCheckIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_DISCHARGE_TRANSLATED: <LanguageIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_FEEDBACK: <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_NOTES_SAVED: <BookmarkIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_DISCHARGE_SAVED: <BookmarkIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_RECAP_SAVED: <BookmarkIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    EXPORT_VETSPIRE: <CloudArrowUpIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    EXPORT_EZYVET: <CloudArrowUpIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    EXPORT_PULSE: <CloudArrowUpIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    EXPORT_INSTINCT: <CloudArrowUpIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    EXPORT_CORNERSTONE: <CloudArrowUpIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    COMMENT_ADDED: <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_NOTES_PDF_GENERATED: <ArrowDownTrayIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_DISCHARGE_PDF_GENERATED: <ArrowDownTrayIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_PREHISTORY_PDF_GENERATED: <ArrowDownTrayIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    VISIT_DELETED: <XCircleIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
    EXPORT_CLAUDE: <CloudArrowUpIcon className="h-5 w-5 text-white" aria-hidden="true"/>,
} as const;

export const colorAuditMapping = {
    VISIT_CREATED: "bg-[#5383FF]",
    RECORDING_STARTED: "bg-[#5383FF]",
    RECORDING_STOPPED: "bg-[#5383FF]",
    VISIT_OWNER_CHANGED: "bg-[#2463ED]",
    PATIENTS_UPDATED: "bg-[#2463ED]",
    VISIT_UPDATED: "bg-[#2463ED]",
    VISIT_PROCESSED: "bg-[#02CF94]",
    VISIT_REPROCESSED: "bg-[#02CF94]",
    VISIT_COMPLETED: "bg-[#02CF94]",
    VISIT_NOTES_REPROCESSED: "bg-[#FAC503]",
    VISIT_PREHISTORY: "bg-[#0A3593]",
    VISIT_PREHISTORY_REPROCESSED: "bg-[#FAC503]",
    VISIT_PREHISTORY_DELETED: "bg-[#F87171]",
    VISIT_DISCHARGE: "bg-[#02CF94]",
    VISIT_DISCHARGE_REPROCESSED: "bg-[#FAC503]",
    VISIT_DISCHARGE_TRANSLATED: "bg-[#02CF94]",
    VISIT_FEEDBACK: "bg-[#AC6626]",
    VISIT_NOTES_SAVED: "bg-[#2463ED]",
    VISIT_DISCHARGE_SAVED: "bg-[#2463ED]",
    VISIT_RECAP_SAVED: "bg-[#2463ED]",
    EXPORT_VETSPIRE: "bg-[#B1B1B1]",
    EXPORT_EZYVET: "bg-[#B1B1B1]",
    EXPORT_PULSE: "bg-[#B1B1B1]",
    EXPORT_INSTINCT: "bg-[#B1B1B1]",
    EXPORT_CORNERSTONE: "bg-[#B1B1B1]",
    EXPORT_CLAUDE: "bg-[#B1B1B1]",
    COMMENT_ADDED: "bg-[#2463ED]",
    VISIT_NOTES_PDF_GENERATED: "bg-[#B1B1B1]",
    VISIT_DISCHARGE_PDF_GENERATED: "bg-[#B1B1B1]",
    VISIT_PREHISTORY_PDF_GENERATED: "bg-[#B1B1B1]",
    VISIT_DELETED: "bg-[#F87171]",
} as const;

export const nameAuditMapping = {
    VISIT_PREHISTORY: "Records Recap",
    VISIT_PREHISTORY_REPROCESSED: "Records Recap Reprocessed",
    VISIT_PREHISTORY_DELETED: "Records Recap Deleted",
    VISIT_PREHISTORY_PDF_GENERATED: "Records Recap PDF Generated",
    EXPORT_VETSPIRE: "Exported to VetsPire",
    EXPORT_EZYVET: "Exported to EzyVet",
    EXPORT_PULSE: "Exported to Pulse",
    EXPORT_INSTINCT: "Exported to Instinct",
    EXPORT_CORNERSTONE: "Exported to Cornerstone",
    EXPORT_CLAUDE: "Exported to Claude",
} as const;

interface ConsentIconWithNameComponentProps {
    consentType: AuditType;
}
  
const ConsentIconWithNameComponent: React.FC<ConsentIconWithNameComponentProps> = ({ consentType }) => {
    const Icon = iconAuditMapping[consentType]

    // Converts consentType to "Word Word" format
    const formatConsentTypeName = (consentType: AuditType): string => {
        return consentType
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b(\w)/g, char => char.toUpperCase()); // Capitalize first letter of each word
    };

    return (
        <div className="flex items-center space-x-2">
            <div className='h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white bg-gray-500'>
                {Icon}
            </div>
            <span>{formatConsentTypeName(consentType)}</span>
        </div>
    );
};

// Main component to display all consent types
export const AllConsentTypes: React.FC = () => {
    return (
      <div className='grid grid-cols-2 sm:grid-cols-1 gap-y-3'>
        {Object.keys(iconAuditMapping).map((consentType) => (
          <ConsentIconWithNameComponent key={consentType} consentType={consentType as AuditType} />
        ))}
      </div>
    );
  };