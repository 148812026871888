import { useHistory } from '../../providers/HistoryProvider'
import AddCustomSpellingModalBasic from '../settings/AddCustomSpellingModalBasic';

export default function AddCustomSpellingModal() {
    const { displayActiveNotes, uploadActiveNotes, setDisplayActiveNotes, addCustomSpellingModal, highlightedText, setHighlightedText, setAddCustomSpellingModal, setNotification, setNotificationText, setNotificationTitle } = useHistory()

    async function refresh(currentSpelling?: string, desiredSpelling?: string, caseSensitive?: boolean) {

        if(currentSpelling && desiredSpelling) {
            // Replace for existing notes
            const flags = caseSensitive ? 'g' : 'gi'; // 'g' for global, 'i' for case-insensitive
            const regex = new RegExp(`\\b${currentSpelling}\\b`, flags); // \\b ensures word boundaries
            const notesWithReplacement = displayActiveNotes.replace(regex, desiredSpelling);

            setDisplayActiveNotes(notesWithReplacement);

            await uploadActiveNotes(notesWithReplacement)
        }
    }

    function onClose() {
        setAddCustomSpellingModal(false)
        setHighlightedText(undefined)
    }

    return (
        <AddCustomSpellingModalBasic
            setNotification={setNotification}
            setNotificationTitle={setNotificationTitle}
            setNotificationText={setNotificationText}
            show={addCustomSpellingModal}
            close={onClose}
            refresh={refresh}
            highlightedText={highlightedText}
        />
    )
}

