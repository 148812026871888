import { classNames } from "../../utils/ClassUtils"
import { useAnalytics } from "../../providers/AnalyticsProvider";

export const TimeFrameTabs: React.FC = () => {
    const {timeframe, handleTimeFrame} = useAnalytics()
    
    return(<div className="w-full sm:w-auto">
        <div className="xl:hidden relative">
            <label
            htmlFor="tabs"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
            >
                Timeframe
            </label>
            <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-main-lighter focus:ring-main-lighter"
            value={timeframe}
            onChange={(value) => handleTimeFrame(value.target.value)}
            >
                <option key={"daily"} value={"daily"}>Daily</option>
                <option key={"weekly"} value={"weekly"}>Weekly</option>
                <option key={"monthly"} value={"monthly"}>Monthly</option>
            </select>
        </div>
        <div className="hidden xl:block">
            <div className="flex flex-row items-center gap-x-4">
                <span className="font-semibold">Timeframe:</span>
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex flex-row space-x-6 w-full" aria-label="Tabs">
                        <div
                            key={"daily"}
                            onClick={() => handleTimeFrame("daily")}
                            className={classNames(
                            timeframe === "daily"
                                ? 'border-main-lighter text-main'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                            )}
                            aria-current={timeframe === "daily" ? 'page' : undefined}
                        >
                            <span>{"Daily"}</span>
                        </div>
                        <div
                            key={"weekly"}
                            onClick={() => handleTimeFrame("weekly")}
                            className={classNames(
                            timeframe === "weekly"
                                ? 'border-main-lighter text-main'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                            )}
                            aria-current={timeframe === "weekly" ? 'page' : undefined}
                        >
                            <span>{"Weekly"}</span>
                        </div>
                        <div
                            key={"monthly"}
                            onClick={() => handleTimeFrame("monthly")}
                            className={classNames(
                            timeframe === "monthly"
                                ? 'border-main-lighter text-main'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                            )}
                            aria-current={timeframe === "monthly" ? 'page' : undefined}
                        >
                            <span>{"Monthly"}</span>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>)
}