import { useState } from 'react';
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { useAuth } from '@clerk/clerk-react';
import { Loading } from '../../utils/Loading';
import { uploadFilesAndStartRecap } from '../../utils/PreHistoryUtils';
import { useHistory } from '../../providers/HistoryProvider';
import { FileUploader } from '../recordsrecap/FileUploader';
import { useSupabase } from '../../supabase/SupabaseContext';
import { BetaBanner } from '../common/BetaBanner';
import { TemplateObject } from '../../utils/TemplateObject';
import TemplatePicker from '../templates/TemplatePicker';
import { TemplateType } from '../templates/TemplateUtils';
import { RecapView } from '../recordsrecap/RecapView';

export default function PreHistory() {
    const { getToken} = useAuth();
    const {uploadToSupabaseSignedURL} = useSupabase();

    const [processing, setProcessing] = useState<boolean>(false)
    const [template, setTemplate] = useState<TemplateObject>()
    
    const {activeSession, preHistoryLoading, preHistoryFailureMessage, preHistoryStreaming, preHistoryProcessingMessage, activePreHistory, shouldShowPrehistoryScannedDocumentsMessage, refreshActiveSession, setError} = useHistory()

    async function handleSubmit(files: File[]) {
        if(!activeSession){
            setError("There was an issue processing the pre-history. Please try again.")
            return
        }

        setProcessing(true)

        await uploadFilesAndStartRecap(
            activeSession,
            files,
            uploadToSupabaseSignedURL,
            (await getToken({ template: "supabase" })) ?? "",
            false, // isRegen
            true, // onlySummarizeLatest
            undefined, // additionalInstructions
            template
        );

        await refreshActiveSession()

        setProcessing(false)
    }

    return (<div className='mb-8 flex flex-col gap-y-4'>
        {preHistoryLoading && !activePreHistory && <Loading text={preHistoryLoading}/>}
        {!processing && preHistoryFailureMessage &&  <div className="px-4 py-5 sm:p-6 flex items-center bg-red-100 rounded-md text-center text-sm text-red-900">
            <ExclamationCircleIcon className="mr-3 h-6 w-6" />
            <p className="ml-3">{preHistoryFailureMessage}</p>
        </div>}
        {!preHistoryLoading && !activePreHistory && <>
            {!processing && !preHistoryProcessingMessage && !preHistoryStreaming && <div className='flex flex-col gap-y-4'>
                <div className="flex sm:flex-row flex-col w-full gap-x-4 gap-y-4 pt-2">
                    <BetaBanner>
                        <span className="font-semibold">Records Recap</span> now supports templates!{" "} 
                        <a target="_blank" rel="noopener noreferrer" className="underline hover:text-blue-700" href="templates">Create your own custom template</a>.
                    </BetaBanner>
                    <TemplatePicker 
                        template={template} 
                        templateId_force={activeSession?.prehistory_metadata?.template_id}
                        setTemplate={setTemplate} 
                        className='h-10 sm:h-14' 
                        templateBackground='bg-white' 
                        type={TemplateType.RECORDSRECAP}/>
                </div>
                <FileUploader sessionId={activeSession?.id} onSubmitButtonClick={handleSubmit} />
            </div>}
            {(processing || preHistoryProcessingMessage || preHistoryStreaming) && <div className='mt-8 mb-8'>
                <Loading text={preHistoryProcessingMessage ?? "Processing prior records"}/>
                {preHistoryProcessingMessage && <div className='flex flex-col justify-center items-center -mt-4 text-sm'>Feel free to navigate away if you need to</div>}
            </div>}
            {shouldShowPrehistoryScannedDocumentsMessage && <div className='mt-8 mb-8'>
                <div className='flex flex-col justify-center items-center text-sm bg-amber-50 rounded-lg p-4 border border-amber-200 max-w-2xl mx-auto'>
                    <div className='flex items-center gap-x-2 text-amber-700'>
                        <InformationCircleIcon className="h-5 w-5" />
                        <span>Want to summarize even faster? Use text-based PDFs</span>
                    </div>
                    <div className='text-amber-600 mt-1'>
                        We detected some scanned documents with images that need extra processing.
                    </div>
                    <a 
                        href="https://help.vetrec.io/en/articles/10180436-record-s-recap-types-of-pdfs"
                        target="_blank"
                        rel="noopener noreferrer" 
                        className="text-amber-700 hover:text-amber-800 underline"
                    >
                        Learn more about document types
                    </a>
                </div>
            </div>}
        </>}
        {activeSession && activePreHistory && <RecapView />}
    </div>)
}
