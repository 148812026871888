import Avimark from './logos/Avimark.png'
import EzyVet from './logos/EzyVet.png'
import Pulse from './logos/Pulse.png'
import VetSpire from './logos/VetSpire.png'
import Cornerstone from './logos/Cornerstone.png'
import Shepherd from './logos/Shepherd.png'
import Digitail from './logos/Digitail.png'
import Instinct from './logos/Instinct.png'
import { integrationRequest } from './ServerActions'
import va from '@vercel/analytics';
import { useAuth } from '@clerk/clerk-react'
import { useState } from 'react'
import Reminder from './components/ReminderToast'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

// Define the types for your props
interface TemplatesProps {

}

interface Integration {
    name:string
    description:string
    img:string
}

const integrations: Integration[] = [
    {name:"ezyVet", description:"Cloud-based PIMS system by IDEXX", img:EzyVet},
    {name:"Vetspire", description:"Cloud-based PIMS system", img:VetSpire},
    {name:"Instinct", description:"Cloud-based PIMS system", img:Instinct},
    {name:"Pulse", description:"Cloud-based PIMS system by Covetrus", img:Pulse},
    {name:"Avimark", description:"Server-based PIMS system by Covetrus", img:Avimark},
    {name:"Cornerstone", description:"Server-based PIMS system by IDEXX", img:Cornerstone},
    {name:"Shepherd", description:"Cloud-based PIMS system", img:Shepherd},
    {name:"Digitail", description:"Cloud-based PIMS system", img:Digitail},
]

export default function Integrations(props:TemplatesProps) {

    const { getToken} = useAuth();
    const [request, setRequest] = useState<boolean>(false)
    const [requestedInt, setRequestedInt] = useState<string>("")
    const [sending, setSending] = useState<boolean>(false)
    const [sent, setSent] = useState<boolean>(false)
    const [missingIntegration, setMissingIntegration] = useState<string>()

    function sleep(ms: number | undefined) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function handleIntegrationRequest(name:string){
        setRequest(true)
        setRequestedInt(name)
        setSending(true)
        await integrationRequest(name, await getToken({template:"supabase"}) ?? "")
        await sleep(500)
        setSent(true)
        setSending(false)
        await sleep(500)
        setSent(false)
        setMissingIntegration(undefined)
        let properties =  {
            date:(new Date()).toUTCString(),
            requested_pims: name
        }
        va.track(`Integration_Request`, properties)
    }

    function handleIntegrationGetStarted(integrationName:string): void {
        if(integrationName === "ezyVet"){
            window.open("https://intercom.help/vetrec/en/articles/9673797-export-notes-to-ezyvet", '_blank');
        }
        else if(integrationName === "Pulse"){
            window.open("https://intercom.help/vetrec/en/articles/9673801-export-notes-to-pulse-evet", '_blank');
        }
        else if(integrationName === "Instinct"){
            window.open("https://intercom.help/vetrec/en/articles/9673804-export-notes-to-instinct", '_blank');
        }
        else if (integrationName === "Vetspire"){
            window.open("https://help.vetrec.io/en/articles/9796814-export-notes-to-vetspire", '_blank');
        }

        let properties =  {
            date:(new Date()).toUTCString(),
            requested_pims: integrationName
        }
        va.track(`Integartion_GetStarted`, properties)
    }

    return(<>
        <Reminder show={request} hide={() => setRequest(false)} title='Notification Sent' text='The team will get back to you in 24H with instructions.'/>
        <div className="border-b border-gray-400 pb-5 mb-5 grid gap-y-2 py-4 sm:pt-10">
            <div className='grid grid-cols-[1fr_auto] justify-between items-center'>
                <div>
                    <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                        Integrations
                    </h2>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">
                        Integrate VetRec with your PIMS to automatically sync information.
                    </p>
                </div>
            </div>
        </div>
        <div className='grid gap-y-10 mb-10 mt-10'>
            {integrations && integrations?.length > 0 && <div>
                <div className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 auto-rows-fr">
                    {integrations.map((integration) => (
                    <div key={integration.name} className="col-span-1 rounded-md shadow-sm w-full h-full">
                        <div className="grid grid-cols-[auto_1fr] rounded-md border border-gray-300 bg-white px-6 py-4 gap-x-8 w-full h-full">
                            <div className="flex items-center justify-center h-full">
                                <img className="w-16 h-16" src={integration.img} />
                            </div>
                            <div className="grid grid-rows-[auto_1fr_auto] gap-y-4">
                                <div className='grid gap-y-1'>
                                    <div className="flex-1 truncate text-lg">
                                        {integration.name}
                                    </div>
                                    <div className="flex-shrink-0 text-sm text-gray-500">
                                        {integration.description}
                                    </div>
                                </div>
                                <div>{/* Spacer */}</div>
                                <div>
                                    {integration.name !== "ezyVet" && integration.name !== "Pulse" && integration.name !== "Instinct" && integration.name !== "Vetspire" && <a
                                        className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center w-full"
                                        onClick={() => handleIntegrationRequest(integration.name)}
                                        href='https://calendly.com/d/cmr2-zkh-nvq/call-with-vetrec-support-team'
                                        target='_blank' rel="noreferrer"
                                    >
                                        {sending && requestedInt === integration.name ? "Notifying" : sent && requestedInt === integration.name ? "Notified" : "Request"}
                                    </a>}
                                    {(integration.name === "ezyVet" || integration.name === "Pulse" || integration.name === "Instinct" || integration.name === "Vetspire") && <button
                                        type="button"
                                        className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center w-full"
                                        onClick={() => handleIntegrationGetStarted(integration.name)}
                                        disabled={sending || sent}
                                    >
                                        Get Started
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                    <div key={"missing"} className="col-span-1 rounded-md shadow-sm w-full h-full">
                        <div className="grid grid-cols-[auto_1fr] rounded-md border border-gray-300 bg-white px-6 py-4 gap-x-8 w-full h-full">
                            <div className="flex items-center justify-center h-full">
                                <QuestionMarkCircleIcon className="w-16 h-16 text-blue-600" />
                            </div>
                            <div className="grid grid-rows-[auto_1fr_auto] gap-y-4">
                                <div className='grid gap-y-1'>
                                    <div className="flex-1 truncate text-lg">
                                        Missing an integration?
                                    </div>
                                    <input className="rounded-md text-sm h-8 w-full border-gray-300" placeholder='Name of PIMS' onChange={(event) => setMissingIntegration(event.target.value)}/>
                                </div>
                                <div>{/* Spacer */}</div>
                                <div>
                                    <a
                                        className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center w-full"
                                        onClick={() => handleIntegrationRequest(missingIntegration ?? "")}
                                        href='https://calendly.com/d/cmr2-zkh-nvq/call-with-vetrec-support-team'
                                        target='_blank' rel="noreferrer"
                                    >
                                        {sending && requestedInt === missingIntegration ? "Notifying" : sent && requestedInt === missingIntegration ? "Notified" : "Request"}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    </>)
}
