import { v4 as uuidv4 } from 'uuid';
import { ContentType, EntryType } from './TemplateUtils';

export const simpleEntry:EntryType = {
    entryKey:uuidv4(),
    name:undefined,
    description:undefined,
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries: []
}

export const owner_discussion:EntryType = {
    entryKey:uuidv4(),
    name:"Owner Discussion",
    description:"Detailed summary of information or instructions communicated to the client as part of the plan for the patient. Include quantitative information provided including prognosis, cost estimates ($), treatment duration. Add each topic of conversation on a separate bullet point. (e.g. Discussed the treatment options for Y. Treatment requires 5 sessions done once a week. Treatment cost estimated to be $5000. Explained benefits of using X medication for inflammation. Medication needs to be applied 5 times a week.)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries: []
}

export const currentMedicationEntry:EntryType = {
    entryKey:uuidv4(),
    name:"Current Medication",
    description:"What current medications is the patient taking? Including dosage and frequency.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries: []
}

export const currentDietEntry:EntryType = {
    entryKey:uuidv4(),
    name:"Current Diet",
    description:"What is the current diet of the patient? Including type of food, brand, servings, etc.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries: []
}

export const currentPreventatitvesEntry:EntryType = {
    entryKey:uuidv4(),
    name:"Current Preventatives",
    description:"Any steps that have been taken to deal with the issue?",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries: []
}

export const chronicIssuesEntry:EntryType = {
    entryKey:uuidv4(),
    name:"Medical History",
    description:"Document in detail the patient's medical history including any previous illnesses, surgeries, hospitalizations, and any chronic conditions.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
}

export const diagnosticTestsEntry:EntryType = {
    entryKey:uuidv4(),
    name:"Diagnostics",
    description:"Provide a detailed LIST of diagnostic tests performed, including the following details for each test: type of test (e.g., fecal examination, blood work, X-rays, or other imaging studies), sedation details if applicable (type, dosage, concentration), owner’s decision (approved, declined), and test results if available. For example, 'Blood Work - Approved - Results: Pending' or 'X-ray - Declined' or 'X-ray with sedation (10mg, IV) - Performed - Results: No fractures.'",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }

export const treatmentsEntry:EntryType = {
    entryKey:uuidv4(),
    name:"In-Hospital Treatments",
    description:"LIST of recommended in-hospital treatments for the patient. Include name, dosage, concentration, location and approval / decline decision by owner. (e.g. Fluids 300ml SQ - Approved , Ear Cleaning - Approved, F/T preventatives - Declined - Not permitted by patient temperament). Include additional information provided about the treatments like side effects.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }

export const vaccinesEntry:EntryType = {
    entryKey:uuidv4(),
    name:"Vaccines",
    description:"LIST of required vaccines by the patient, whether it was approved or declined by the owner and the body location where it was administered. Include any discussed side effects. (e.g. DAPP - Administered - SQ Left Hind Leg)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries: []
}

export const medicationEntry:EntryType = {
    entryKey:uuidv4(),
    name:"Medications",
    description:"LIST of drugs / medications prescribed or dispensed by the doctor, including dosage, frequency, concentration and duration. Include any side effects discussed. Provide instructions for administration. (e.g. 2mg/kg of Cerenia once daily)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }

export const dietEntry:EntryType = {
    entryKey:uuidv4(),
    name:"Diet",
    description:"Recommended dietary changes provided by doctor. Include any recommended foods as well as any foods to be avoided. Include feeding instructions like servings.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries: []
}