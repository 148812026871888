import { v4 as uuidv4 } from 'uuid';
import { ContentType, SectionType, SectionTypes } from './TemplateUtils'

export const simpleSection:SectionType = {
    sectionKey: uuidv4(),
    name:undefined,
    type: SectionTypes.NORMAL,
    entries: [{
      entryKey:uuidv4(),
      name:undefined,
      description:undefined,
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const spacesSection:SectionType = {
  sectionKey: uuidv4(),
  name:undefined,
  type: SectionTypes.SPACES,
  entries: [],
  hardcodedValue:'1',
}

export const generalInstructionsSection:SectionType = {
  sectionKey: uuidv4(),
  name:"General Instructions",
  type: SectionTypes.GENERAL_INSTRUCTIONS,
  entries: [],
}

export const timelineSection:SectionType = {
  sectionKey: uuidv4(),
  name:"Timeline",
  type: SectionTypes.TIMELINE,
  entries: [{
    entryKey:uuidv4(),
    name:"Referrers",
    description:"LIST ALL doctors who either explicitly signed off on or clearly authored the medical history report for the specified date. Include the names of the veterinary professional/group and the clinic/hospital they work at (e.g. Dr. John Doe, DVM - Animal Clinic). Limit to 20. Only include each name and affiliation once.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }, {
    entryKey:uuidv4(),
    name:"Physical Exams",
    description:"Detail physical examinations findings for the patient on the specified date. Write a summary of objective findings for different body systems. E.g., Heart: Normal, Lungs: Clear, Abdomen: Mild tenderness, etc.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  }, {
    entryKey:uuidv4(),
    name:"Prior Issues",
    description:"LIST ALL the prior issues for the patient that started on the specified date. Include details like how it has evolved (better or worse), and any treatments or medications prescribed and effect. (e.g. Arthritis - Getting worse - Gabapentin prescribed, no effect).",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }, {
    entryKey:uuidv4(),
    name:"Prior Vaccines",
    description:`LIST ALL vaccines administered to the patient on the specified date. Include the location and method if applicable. (e.g. "Bordetella - Intransal" or "Rabies - SQ Right Leg "or "DHPP - Location not specified").`,
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }, {
    entryKey:uuidv4(),
    name:"Prior Medications",
    description:"LIST ALL the existing medications or preventatives prescribed to the patient on the specified date, including the dose and type (tablet or injection). E.g., Gabapentin - 600mg tablets - 1.5 tablets every 8 hours. ",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }, {
    entryKey:uuidv4(),
    name:"Prior Test Reports",
    description:"LIST of ALL TEST RESULTS in the MEDICAL HISTORY on the specified date. For each test, include the test type, lab values, and provided conclusions (if available). Format each entry as follows: Test Type - Lab Values - Conclusions (e.g. Bloodwork - T4 1.5 - Hypothyroidism suspected or CBC - WBC 9.6K, HCT 42.5, retic HGB 22.2). Include all tests performed, such as CHEMISTRY, SEROLOGY, X-RAYS, ULTRASOUNDS, URINARY, FECAL, BIOPSIES, and any other tests provided.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }, {
    entryKey:uuidv4(),
    name:"Prior Treatments",
    description:"LIST ALL treatments, procedures and surgeries performed on the patient on the specified date. Include the name and a summary of the outcome. (e.g. Spay and Neuter - Successful, no complications).",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }]
}

export const vitals:SectionType = {
    sectionKey: uuidv4(),
    name:"Vitals",
    type: SectionTypes.COLLECTION,
    entries: [{
      entryKey:uuidv4(),
      name:"Weight",
      description:"Weight of patient in pounds. (e.g. 10 lbs)",
      defaultValue:"",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Temperature",
      description:"Body temperature of the patient in degrees fahrenheit. (e.g. 101.5 F)",
      defaultValue:"",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Heart Rate",
      description:"Patient's heart rate in bpm. (e.g. 120 bpm)",
      defaultValue:"",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Respiratory Rate",
      description:"Patient's respiratory rate in bpm. (e.g. 20 bpm)",
      defaultValue:"",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Blood Pressure",
      description:"Patient's blood pressure. Formatted as 120/80.",
      defaultValue:"No significant findings",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"CRT",
      description:"Patient's capillary refill time as one of the following values: <1 sec , 1-2 sec, >2 sec",
      defaultValue:"1-2 sec",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"MM",
      description:"Patient's mucous membranes condition as one of the following values: Blue, Grey, White, Pale, Yellow, Pigmented, Tacky, Pale Pink, Pink, Injected, Red",
      defaultValue:"Pink",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Pulse Quality",
      description:"Patient's pulse quality. Choose from: Bounding, Normal, Fair, Difficult to Palpate, Weak, No Pulse Detected, Dropped Pulse",
      defaultValue:"No significant findings",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Respiratory Effort",
      description:"Patient's respiratory effort. Choose from: Normal, Laboured Breathing, Shallow",
      defaultValue:"No significant findings",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },
    {
      entryKey:uuidv4(),
      name:"Additional Vitals",
      description:"Any additional notes vitals information collected.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const physical_exam:SectionType = {
    sectionKey: uuidv4(),
    name:"Physical Exam",
    type: SectionTypes.COLLECTION,
    entries: [{
      entryKey:uuidv4(),
      name:"General Appearance & Behavior",
      description:"Document objective findings regarding the patient's general appearance and behavior. (e.g. BAR, friendly)",
      defaultValue:"BAR, friendly",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Hydration Status",
      description:"Document objective findings regarding skin tent and mucous membranes for hydration status.",
      defaultValue:"Euhydrated, normal skin tent, MM moist",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"BCS",
      description:"Document objective findings regarding the patient's body condition score.",
      defaultValue: "/9",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Eyes",
      description:"Document objective findings from the patient's eyes (OU, OD, OS) examination.",
      defaultValue:"NSF, clear and free of discharge, pupils normal size, globes symmetrical OU",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Ears",
      description:"Document objective findings from the patient's ears (AU, AD, AS) examination.",
      defaultValue:"NSF, no discharge observed, no erythema present AU",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Oral Cavity",
      description:"Document objective findings from the patient's oral cavity examination. Include grade for calculus, tartar or gingivitis in a scale of I to IV. Format as: Grade II/IV.",
      defaultValue:"NSF, no significant plaque, tartar, gingivitis noted",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Nasal Cavity",
      description:"Document objective findings from the patient's nasal cavity.",
      defaultValue:"NSF, no nasal discharge noted",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Integument",
      description:"Document objective findings from the patient's skin, pads and digits examination. Include specifics like condition, quality, presence of parasites, masses, tumors, peeling, lesions, blisters, burns, etc. and the location in the body.",
      defaultValue:"NSF, skin appears normal; hair coat in good condition, no ectoparasites or masses noted",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Lymph nodes",
      description:"Document objective findings from the patient's lymphatic examination.",
      defaultValue:"NSF, no peripheral lymphadenopathy noted",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Cardiovascular",
      description:"Document objective findings from the patient's cardiovascular system examination including heart sounds, pulse and rhythm.",
      defaultValue:"NSF, no murmurs or arrhythmias noted, pulses strong and synchronous",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Respiratory",
      description:"Document objective findings from the patient's respiratory system examination including lungs and trachea.",
      defaultValue:"NSF, eupneic, lungs sounds clear in all four quadrants; no cough elicited on tracheal palpation",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Abdomen",
      description:"Document objective findings from the patient's gastrointestinal system examination including abdomen.",
      defaultValue:"NSF, no pain notes in abdominal palpation; no masses or organomegaly noted",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Urogenital",
      description:"Document objective findings from the patient's urogenital system examination including bladder and kidneys.",
      defaultValue:"NSF, no discharge noted. No abnormalities noted on bladder or kidney palpation",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Musculoskeletal",
      description:"Document objective findings from the patient's musculoskeletal system examination including bones and muscles. Include detailed information for each area: muscles, spine, carpus, stifle, tarsus, hips, joints, limbs, elbows and shoulders.",
      defaultValue:"NSF, normal ambulation x4, normal muscle mass",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Neurologic",
      description:"Document objective findings from the patient neurologic system examination including brain, nerves, reflexes, gait / posture and pain.",
      defaultValue:"NSF, normal mentation, no obvious deficits notes, full neuro exam not performed",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Rectal",
      description:"Document objective findings from the patient's rectal examination including palpations of anal glands and discharges.",
      defaultValue:"Rectal exam not performed",
      type:ContentType.PARAGRAPH,
      subEntries:[]
    },{
      entryKey:uuidv4(),
      name:"Additional Notes",
      description:"Any additional findings from the patient's physical examination.",
      defaultValue:undefined,
      type:ContentType.PARAGRAPH,
      subEntries:[]
    }]
}

export const simpleAssessment:SectionType = {
    sectionKey:uuidv4(),
    name: "Assessments",
    type: SectionTypes.NORMAL,
    entries: [{
      entryKey:uuidv4(),
      name:"Problem List with DDX",
      description:"LIST all health problems based on the patient's reason for the visit, physical exam abnormalities, and diagnostic findings. For each problem, ONLY if the doctor explicitly mentions any differential diagnoses, include them the same line after r/o (e.g., 'Diarrhea r/o dietary indiscretion vs infectious causes vs parasites vs other gastrointestinal disorders'). If no differential diagnoses are explicitly mentioned, just mention the problem (e.g. 'Diarrhea' )",
      defaultValue:undefined,
      type:ContentType.LIST,
      subEntries:[]
    }]
}

export const advancedSubjective:SectionType = {
  sectionKey:uuidv4(),
  name: "Subjective",
  type: SectionTypes.NORMAL,
  entries: [{
    entryKey:uuidv4(),
    name:"Presenting for",
    description:"Document reason for visit including if the patient is seeing the doctor for a wellness check or vaccines. (Ex. Fluffy presenting with diarrhea for 2 days. or Fluffy presenting for vaccines). Limit to 1 sentence.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"E/D/U/D, C/S/V/D",
    description:"Document whether the patient is eating, drinking, urinating, defecating, coughing, sneezing, vomiting, or having diarrhea. Include frequency and any changes in behavior.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Onset",
    description:"How long has the illness being present?",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Medical History",
    description:"Document in detail the patient's medical history including any previous illnesses, surgeries, hospitalizations, and any chronic conditions.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Current Diet",
    description:"What is the current diet of the patient? Include type of food, brand and servings if available.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Current Medications",
    description:"What current medications is the patient taking? Including dosage and frequency.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Current Preventatives",
    description:"Any actions or treatments that have been taken to deal with the issue?",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Other History",
    description:"Document additional details about the patient. Limit to details provided by owner regarding the pets condition before the consultation.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  }]
}

export const advancedPlan:SectionType = {
  sectionKey:uuidv4(),
  name: "Plan",
  type: SectionTypes.NORMAL,
  entries: [{
    entryKey:uuidv4(),
    name:"Owner Discussion",
    description:"Detailed summary of information or instructions communicated to the client as part of the plan for the patient. Include quantitative information provided including prognosis, cost estimates ($), treatment duration. Add each topic of conversation on a separate bullet point. (e.g. Discussed the treatment options for Y. Treatment requires 5 sessions done once a week. Treatment cost estimated to be $5000. Explained benefits of using X medication for inflammation. Medication needs to be applied 5 times a week.)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Diagnostics",
    description:"Provide a detailed LIST of diagnostic tests performed, including the following details for each test: type of test (e.g., fecal examination, blood work, X-rays, or other imaging studies), sedation details if applicable (type, dosage, concentration), owner’s decision (approved, declined), and test results if available. For example, 'Blood Work - Approved - Results: Pending' or 'X-ray - Declined' or 'X-ray with sedation (10mg, IV) - Performed - Results: No fractures.'",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"In-Hospital Treatments",
    description:"LIST of recommended in-hospital treatments for the patient. Include name, dosage, concentration, location and approval / decline decision by owner. (e.g. Fluids 300ml SQ - Approved , Ear Cleaning - Approved, F/T preventatives - Declined - Not permitted by patient temperament). Include additional information provided about the treatments like side effects.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Medications",
    description:"LIST of drugs / medications prescribed or dispensed by the doctor, including dosage, frequency, concentration and duration. Include any side effects discussed. Provide instructions for administration. (e.g. 2mg/kg of Cerenia once daily)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Vaccines",
    description:"LIST of required vaccines by the patient, whether it was approved or declined by the owner and the body location where it was administered. Include any discussed side effects. (e.g. DAPP - Administered - SQ Left Hind Leg)",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Diet",
    description:"Recommended dietary changes provided by doctor. Include any recommended foods as well as any foods to be avoided. Include feeding instructions like servings.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Monitoring",
    description:"Doctor provided specifications for how often the owner should monitor the pet's condition and what signs to watch for.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Follow Up",
    description:"Capture follow up information like additional appointments, surgery or procedure prescribed by the doctor and approved by the owner.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Additional Instructions",
    description:"Any additional information, recommendations, instructions and complication warnings provided by the doctor for the owner. (e.g. Keeping the pet hydrated, Isolating the pet from other animals, Post-operation instructions or potential complications like infection.) Be verbose and thorough based on the information provided by the doctor.",
    defaultValue:undefined,
    type:ContentType.PARAGRAPH,
    subEntries:[]
  }]
}

export const fearfree:SectionType = {
  sectionKey:uuidv4(),
  name: "Fear Free",
  type: SectionTypes.NORMAL,
  entries: [{
    entryKey:uuidv4(),
    name:"Declined Items",
    description:"LIST of items that the owner declined during the consult including labwork, radiographs, ultrasounds, treatments, procedures and surgery, medications, vaccines.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  },{
    entryKey:uuidv4(),
    name:"Forward Booking",
    description:"Any appointments or procedures that were scheduled for the patient during the appointment. Include rechecks, surgeries, procedures, and other appointments.",
    defaultValue:undefined,
    type:ContentType.LIST,
    subEntries:[]
  }]
}

export const CanineDentalChart:SectionType = {
  sectionKey:uuidv4(),
  name: "Canine Dental Chart",
  type: SectionTypes.DIAGRAM,
  entries: [],
  diagram:"CanineDentalExam"
}

export const FelineDentalChart:SectionType = {
  sectionKey:uuidv4(),
  name: "Feline Dental Chart",
  type: SectionTypes.DIAGRAM,
  entries: [],
  diagram:"FelineDentalExam"
}