import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { SessionObject } from "../utils/SessionUtils";
import { v4 as uuidv4 } from "uuid";
import va from "@vercel/analytics";
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { MemberObject, sortMembersList } from "../utils/MemberUtils";
import { useNavigate } from "react-router-dom";
import { createSession, fetchSessionById } from "../ServerActions";
import { useSupabase } from "../supabase/SupabaseContext";
import { uploadFilesAndStartRecap } from "../utils/PreHistoryUtils";
import { TemplateObject } from "../utils/TemplateObject";
import { getTemplateTypeDefaultKey, TemplateType } from "../components/templates/TemplateUtils";
import { useVetRec } from "./VetRecProvider";

export enum RecordsRecapStatus {
  NOTSTARTED = "not_started",
  UPLOADING = "uploading",
  SUBMITTED = "submitted",
  FAILED = "failed",
  FAILED_NAME = "failed_name",
  FAILED_UPLOAD = "failed_upload",
}

interface RecordsRecapContextType {
  sessionId: string;
  recordsRecapState: RecordsRecapStatus;
  setRecordsRecapState: (state: RecordsRecapStatus) => void;
  name: string;
  updateName: (name: string) => void;
  updateRecordAs: (recordAs: string) => void;
  membersList: MemberObject[];
  recordAs: string;
  error: string | undefined;
  setError: (value: string | undefined) => void;
  startNewRecordsRecap: (files: File[], template: TemplateObject | undefined) => Promise<void>;
}

const RecordsRecapContext = createContext<RecordsRecapContextType | undefined>(
  undefined
);

export const useRecordsRecap = () => {
  const context = useContext(RecordsRecapContext);
  if (!context) {
    throw new Error(
      "useRecordsRecap must be used within a RecordsRecapProvider"
    );
  }
  return context;
};

interface RecordsRecapProviderProps {
  children: ReactNode;
}

export const RecordsRecapProvider: React.FC<RecordsRecapProviderProps> = ({
  children,
}) => {
  const { user } = useUser();
  const { orgId, getToken } = useAuth();
  const { organization } = useOrganization();
  const { uploadToSupabaseSignedURL } = useSupabase();
  const navigate = useNavigate();
  const { getTeamMemberPublicMetadata } = useVetRec();

  const [sessionId] = useState<string>(uuidv4());
  const [recordsRecapState, setRecordsRecapState] =
    useState<RecordsRecapStatus>(RecordsRecapStatus.NOTSTARTED);
  const [name, setName] = useState<string>("");

  const [recordAs, setRecordAs] = useState<string>(
    user?.primaryEmailAddress?.emailAddress ?? ""
  );
  const [membersList, setMembersList] = useState<MemberObject[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const getLoggingProperties = () => {
    return {
      date: new Date().toUTCString(),
      sessionId: sessionId ?? "undefined",
      recordAs: recordAs,
    };
  };

  const updateName = async (name: string) => {
    if (recordsRecapState === RecordsRecapStatus.FAILED_NAME) {
      setRecordsRecapState(RecordsRecapStatus.NOTSTARTED);
    }
    setName(name);
  };

  const updateRecordAs = async (recordAs: string) => {
    setRecordAs(recordAs);

    va.track("RecordAs_Select", getLoggingProperties());
  };

  async function createNewSession(): Promise<SessionObject> {
    const token = (await getToken({ template: "supabase" })) ?? "";

    await createSession({
      session: sessionId,
      name,
      token,
      manual_notes: false,
      recording: true,
      consent: false,
      pets: [name],
      owner: recordAs === user?.primaryEmailAddress?.emailAddress ? undefined : recordAs
    });

    return await fetchSessionById(sessionId, token);
  }

  async function getOrganizationMember() {
    if (orgId) {
      let members = await organization?.getMemberships({ pageSize: 500 });
      
      let memberList: MemberObject[] | undefined = members?.data.map((member) => {
        let name;
        if (member.publicUserData.firstName && member.publicUserData.lastName) {
          name =
            member.publicUserData.firstName +
            " " +
            member.publicUserData.lastName;
        } else {
          name = undefined;
        }
        return {
          name: name,
          identifier: member.publicUserData.identifier,
          clerk_id: member.publicUserData.userId,
          get_default_template: async (templateType: TemplateType | undefined) => {
            const userMetadata = await getTeamMemberPublicMetadata(member.publicUserData.userId ?? "")
            return userMetadata[getTemplateTypeDefaultKey(templateType) as keyof typeof userMetadata] as string
        }
        };
      });

      sortMembersList(memberList)

      if (memberList) setMembersList(memberList);
    }
  }

  async function startNewRecordsRecap(files: File[], template: TemplateObject | undefined) {
    setRecordsRecapState(RecordsRecapStatus.UPLOADING);

    const newSession = await createNewSession();

    if (newSession === undefined) {
      setError("There was an issue processing the records. Please try again.");
      setRecordsRecapState(RecordsRecapStatus.FAILED);

      return;
    }

    await uploadFilesAndStartRecap(
      newSession,
      files,
      uploadToSupabaseSignedURL,
      (await getToken({ template: "supabase" })) ?? "",
      false, // isRegen
      true, // onlySummarizeLatest
      undefined, // additionalInstructions
      template
    );

    setRecordsRecapState(RecordsRecapStatus.SUBMITTED);

    navigate(`/history?session_id=${sessionId}&tab=prehistory`);
  }

  useEffect(() => {
    getOrganizationMember();
  }, [orgId]);

  return (
    <RecordsRecapContext.Provider
      value={{
        sessionId,
        recordsRecapState,
        setRecordsRecapState,
        name,
        updateName,
        updateRecordAs,
        membersList,
        recordAs,
        error,
        setError,
        startNewRecordsRecap,
      }}
    >
      {children}
    </RecordsRecapContext.Provider>
  );
};
