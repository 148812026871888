import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaw } from "@fortawesome/free-solid-svg-icons"

interface MultiPetBadgeProps {
    petCount: number
}

export const MultiPetBadge = ({ petCount }: MultiPetBadgeProps) => {
    return (
        <div className="group relative">
            <span className="inline-flex items-center rounded-md bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 gap-x-1 shrink-0 ml-2">
                <FontAwesomeIcon icon={faPaw} className="w-3 h-3" />
                +{petCount}
            </span>
            <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 hidden group-hover:block w-max z-10">
                <div className="bg-gray-900 text-white text-xs rounded py-1 px-2">
                    Multi-pet visit with {petCount} patients.
                </div>
                <div className="w-2 h-2 bg-gray-900 transform rotate-45 absolute left-1/2 -translate-x-1/2 -top-1"></div>
            </div>
        </div>
    )
}