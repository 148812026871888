import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { PaintBrushIcon } from '@heroicons/react/24/solid'
import { doesTemplateTypeSupportAbnormals, getTemplateTypeNotMentionedLabel, TemplateStyling } from './TemplateUtils'
import { useTemplate } from './TemplateProvider'

interface TemplateGenerateModalProps{
    open:boolean,
    setOpen: (value:boolean) => void
}

export default function TemplateStylingModal(props:TemplateGenerateModalProps) {

    const { styling, saveStyling, templateType } = useTemplate()
    const [tempStyling, setTempStyling] = useState<TemplateStyling>(styling)

    const supportsAbnormals = useMemo<boolean>(() => doesTemplateTypeSupportAbnormals(templateType), [templateType])
    const notMentionedLabel = useMemo(() => getTemplateTypeNotMentionedLabel(templateType), [templateType])

    useEffect(() => {
        setTempStyling(styling)
    }, [styling])

    const changeTitleBold = () => {
        setTempStyling(prevState => ({
          ...prevState,
          title_bold: !prevState?.title_bold
        }));
    };
      
    const changeAbnormalsBolded = () => {
        setTempStyling(prevState => ({
          ...prevState,
          abnormals_bolded: !prevState?.abnormals_bolded
        }));
    };

    const changeAbnormalsColored = () => {
        setTempStyling(prevState => ({
          ...prevState,
          abnormals_color: !prevState?.abnormals_color
        }));
    };

    const changeAbnormalsUppercased = () => {
        setTempStyling(prevState => ({
          ...prevState,
          abnormals_uppercase: !prevState?.abnormals_uppercase
        }));
    };
      
    const changeShowNotMentioned = () => {
        setTempStyling(prevState => ({
          ...prevState,
          show_not_mentioned: !prevState?.show_not_mentioned
        }));
    };

    const changeBulletPoints = () => {
        setTempStyling(prevState => ({
          ...prevState,
          bullet_points: !prevState?.bullet_points
        }));
    };
    
    const changeListBulletPoints = () => {
        setTempStyling(prevState => ({
          ...prevState,
          list_bullet_points: !prevState?.list_bullet_points
        }));
    };

    async function handleSaveStyling(){
        saveStyling(tempStyling)
        props.setOpen(false)
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <PaintBrushIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5 flex flex-col items-center justify-center gap-y-4">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Style your notes
                                </Dialog.Title>
                                <div className="">
                                    <p className="text-sm text-gray-500">
                                        Pick the styling configuration of your notes
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-4 mt-8">
                            <div className="rounded-md w-full flex flex-col gap-y-4 items-center justify-between">
                                <div className="flex flex-row items-center gap-x-4 justify-start ">
                                    Title Bolded
                                    <Switch
                                        checked={tempStyling.title_bold}
                                        onChange={changeTitleBold}
                                        className={`${tempStyling.title_bold ? 'bg-green-600' : 'bg-red-600'}
                                        relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                        aria-hidden="true"
                                        className={`${tempStyling.title_bold ? 'translate-x-6' : 'translate-x-0'}
                                            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                </div>
                                {supportsAbnormals && <div className="flex flex-row items-center gap-x-4">
                                    Abnormals Bolded
                                    <Switch
                                        checked={tempStyling.abnormals_bolded}
                                        onChange={changeAbnormalsBolded}
                                        className={`${tempStyling.abnormals_bolded ? 'bg-green-600' : 'bg-red-600'}
                                        relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                        aria-hidden="true"
                                        className={`${tempStyling.abnormals_bolded ? 'translate-x-6' : 'translate-x-0'}
                                            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                </div>}
                                {supportsAbnormals && <div className="flex flex-row items-center gap-x-4">
                                    Abnormals Colored
                                    <Switch
                                        checked={tempStyling.abnormals_color}
                                        onChange={changeAbnormalsColored}
                                        className={`${tempStyling.abnormals_color ? 'bg-green-600' : 'bg-red-600'}
                                        relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                        aria-hidden="true"
                                        className={`${tempStyling.abnormals_color ? 'translate-x-6' : 'translate-x-0'}
                                            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                </div>}
                                {supportsAbnormals && <div className="flex flex-row items-center gap-x-4">
                                    Abnormals Upper Cased
                                    <Switch
                                        checked={tempStyling.abnormals_uppercase}
                                        onChange={changeAbnormalsUppercased}
                                        className={`${tempStyling.abnormals_uppercase ? 'bg-green-600' : 'bg-red-600'}
                                        relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                        aria-hidden="true"
                                        className={`${tempStyling.abnormals_uppercase ? 'translate-x-6' : 'translate-x-0'}
                                            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                </div>}
                                {notMentionedLabel && <div className="flex flex-row items-center gap-x-4">
                                    <span>{notMentionedLabel} shown</span>
                                    <Switch
                                        checked={tempStyling.show_not_mentioned}
                                        onChange={changeShowNotMentioned}
                                        className={`${tempStyling.show_not_mentioned ? 'bg-green-600' : 'bg-red-600'}
                                        relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                        aria-hidden="true"
                                        className={`${tempStyling.show_not_mentioned ? 'translate-x-6' : 'translate-x-0'}
                                            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                </div>}
                                <div className="flex flex-row items-center gap-x-4">
                                    Use bullet points for each entry
                                    <Switch
                                        checked={tempStyling.bullet_points}
                                        onChange={changeBulletPoints}
                                        className={`${tempStyling.bullet_points ? 'bg-green-600' : 'bg-red-600'}
                                        relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                        aria-hidden="true"
                                        className={`${tempStyling.bullet_points ? 'translate-x-6' : 'translate-x-0'}
                                            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                </div>
                                <div className="flex flex-row items-center gap-x-4">
                                    Use bullet points for lists
                                    <Switch
                                        checked={tempStyling.list_bullet_points}
                                        onChange={changeListBulletPoints}
                                        className={`${tempStyling.list_bullet_points ? 'bg-green-600' : 'bg-red-600'}
                                        relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                                    >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                        aria-hidden="true"
                                        className={`${tempStyling.list_bullet_points ? 'translate-x-6' : 'translate-x-0'}
                                            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center">
                                <button
                                    type="button"
                                    className="inline-flex w-60 justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    onClick={() => handleSaveStyling()}
                                >
                                    Save Configuration
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}