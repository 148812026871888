import { Fragment, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BuildingOfficeIcon } from '@heroicons/react/24/solid'
import { useAdmin } from '../../../providers/AdminProvider';
import { Spinner } from '../../../utils/Spinner';
import { ExistingTeamSelector } from './ExistingTeamSelector';

interface ExistingTeamModalProps {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
}

export default function ExistingTeamModal({
    showModal,
    setShowModal,
}: ExistingTeamModalProps) {

    const [loading, setLoading] = useState(false)
    const [selectedTeams, setSelectedTeams] = useState<string[]>([])
    const { addTeam, teams } = useAdmin()

    const onAddPressed = async () => {
        setLoading(true)
        await addTeam(selectedTeams)
        setLoading(false)
        setShowModal(false)
    }

    return (
        <Transition.Root show={showModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                        <BuildingOfficeIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Add Existing Teams
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Add existing teams to your organization.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='relative mt-4 overflow-visible'>
                                    <ExistingTeamSelector setSelectedTeams={setSelectedTeams} teamsInSuperOrg={teams}/>
                                </div>
                                <div className='flex flex-row gap-x-4 w-full'>
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:col-start-2 mt-4"
                                        onClick={() => setShowModal(false)}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-4"
                                        onClick={onAddPressed}
                                        disabled={loading}
                                    >
                                        {loading ? <Spinner size="w-5 h-5" timer={false} /> : 'Add'}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
