import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { useEffect, useMemo, useState } from "react";
import LanguagePicker from "../LanguagePicker";
import { Switch } from "@headlessui/react";
import Reminder from "../ReminderToast";
import { CopyBehavior, getCopyBehaviorFromString } from "./copyBehavior";
import { updateUserMetadata } from "../../methods/clerk";
import EnumPicker from "../EnumPicker";
import { BetaBanner } from "../common/BetaBanner";
import { languages } from "@neum/frontend-shared/utils/LanguageUtils";
import { PhysicalExamModes, getPhysicalExamModes } from "../../utils/NotesUtils";
import { capitalize } from "lodash";
import { isDoctorRole } from "./UserRoles";

export const UserControlsPage = () => {
    const [language, setLanguage] = useState<string>("English (US)");
    const { user } = useUser();
    const { getToken, orgId, userId } = useAuth();
    const [saved, setSaved] = useState(false);
    const [savedText, setSavedText] = useState<string>("")
    const [signature, setSignature] = useState<string>("");
    const [copyBehavior, setCopyBehavior] = useState<CopyBehavior>(CopyBehavior.plain);
    const [prebuiltEnabled, setPrebuiltEnabled] = useState<boolean>(true);
    const [preBuiltControlledByOrg, setPreBuiltControlledByOrg] = useState<boolean>(false);
    const [physicalExamMode, setPhysicalExamMode] = useState<PhysicalExamModes>(PhysicalExamModes.ABNORMAL_ONLY);
    const {organization, membership} = useOrganization()

    const suggestedSignature = useMemo(() => {
        return `${membership?.role && isDoctorRole(membership.role) ? "Dr. " : ""}${user?.fullName ?? "Your Name"}`
    }, [userId]);

    useEffect(() => {
        setLanguage(user?.publicMetadata?.language as string || "English (US)");
        setSignature(user?.publicMetadata?.custom_signature as string);
        setCopyBehavior(getCopyBehaviorFromString(user?.publicMetadata?.copyBehavior as string ?? organization?.publicMetadata?.copyBehavior ?? "plain"));
        setPrebuiltEnabled(organization?.publicMetadata?.prebuiltEnabled as boolean ?? user?.publicMetadata?.prebuiltEnabled as boolean ?? true);
        setPreBuiltControlledByOrg(organization?.publicMetadata?.prebuiltEnabled !== undefined ? true : false);
        setPhysicalExamMode(getPhysicalExamModes(user?.publicMetadata?.physical_exam_mode as string ?? organization?.publicMetadata?.physical_exam_mode as string ?? "abnormal_only"));
    }, [user, orgId]);

    const handleCopyBehaviorChange = (value:string) => {
        setCopyBehavior(getCopyBehaviorFromString(value))
    }

    const handlePhysicalExamMode = (value:string) => {
        setPhysicalExamMode(getPhysicalExamModes(value))
    }

    const handlePrebuiltChange = (value:boolean) => {
        setPrebuiltEnabled(value);
    };

    const handleSaveControls = async () => {
        setSavedText("Saving settings...");
        setSaved(true);
        await updateUserMetadata(
            { 
                prebuiltEnabled: prebuiltEnabled,
                copyBehavior: copyBehavior,
                language: language,
                custom_signature: signature,
                physical_exam_mode: physicalExamMode
            },
            "update_user_controls",
            (await getToken({ template: "supabase" })) ?? ""
        );
        await user?.reload()
    }


    return (<>
        <div className="mb-20 flex flex-col gap-y-4 w-full">
            <BetaBanner>
                <span className="font-semibold">User Controls</span> available now! For any questions or feedback, please reach out to <a href="mailto:support@vetrec.io" className="underline">support@vetrec.io</a>
            </BetaBanner>
            <div className="flex flex-row justify-between mt-4">
                <div className="flex flex-col gap-y-4">
                    <h1 className="text-3xl font-semibold">User Controls</h1>
                    <div>Configure your user options.</div>
                </div>
                <div>
                    <button className="bg-blue-600 px-4 py-2 text-white rounded-md" onClick={() => handleSaveControls()}>Save</button>
                </div>
            </div>
            <div className="text-xl font-semibold mt-4">
                Note Configurations
            </div>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col gap-y-2">
                        <div className="font-semibold">Signature:</div>
                        <div className="text-sm text-gray-600">
                            Tell VetRec how you would like your name displayed in documents (e.g., downloaded notes).
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-4 items-center">
                        <input
                            type="text"
                            placeholder={suggestedSignature}
                            className="border border-gray-300 rounded-md p-2 w-60 text-sm"
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col gap-y-2">
                        <div className="font-semibold">Copy Behavior:</div>
                        <div className="text-sm text-gray-600">
                            Choose between copying notes as <span className="p-1 border bg-gray-600 rounded-md text-white">plain</span> text or <span className="p-1 border bg-gray-600 rounded-md text-white">**markdown**</span>
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-4 items-center">
                        <EnumPicker enum={CopyBehavior} selectedOption={copyBehavior} setSelectedOption={(value) => handleCopyBehaviorChange(value)} width="w-60"/>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col gap-y-2">
                        <div className="font-semibold">Default Language</div>
                        <div className="text-sm text-gray-600">
                            Default language used to transcribe and generate notes.
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-4 items-center">
                        <LanguagePicker language={language} setLanguage={setLanguage} className="" labelBackground="bg-white" languages={languages}/>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col gap-y-2">
                        <div className="font-semibold">Physical Exam Behavior</div>
                        <div className="text-sm text-gray-600">
                            Do you want the physical exam to only document abnormals and use default values for normals or would you want it to document everything you say verbatim?
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-4 items-center">
                        <EnumPicker 
                            enum={PhysicalExamModes} 
                            selectedOption={physicalExamMode} 
                            setSelectedOption={(value) => handlePhysicalExamMode(value)} 
                            width="w-60" 
                            enumToDisplayName={(value) => capitalize(value.replace(/_/g, " "))}/>
                    </div>
                </div>
            </div>
            <div className="text-xl font-semibold mt-4">
                Template Configurations
            </div>
            <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                <div className="flex flex-col gap-y-2">
                    <div className="font-semibold">Pre-built Templates:</div>
                    <div className="text-sm text-gray-600">
                        Configure whether pre-built templates are enabled for your account. If disabled, pre-built templates won't be shown unless added.
                    </div>
                </div>
                <Switch
                checked={prebuiltEnabled}
                onChange={handlePrebuiltChange}
                disabled={preBuiltControlledByOrg}
                className={`${
                    prebuiltEnabled ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full flex-shrink-0`}
                >
                    <span className="sr-only">Are pre-built templates enabled?</span>
                    <span
                        className={`${
                        prebuiltEnabled ? 'translate-x-6' : 'translate-x-1'
                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </Switch>
            </div>
        </div>
        <Reminder show={saved} hide={() => setSaved(false)} title={savedText} text=""/>
    </>);
};