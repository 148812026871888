import { useState, useEffect } from 'react';
import { ClipboardDocumentIcon, ArrowDownCircleIcon, HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/solid';
import { Spinner } from '../../utils/Spinner';
import { convertPreHistoryToString } from '../../utils/PreHistoryUtils';
import { useVetRec } from '../../providers/VetRecProvider';
import { addMissingIndentLevels, fixIndentation, preprocessText, revertPreprocessedTextNoStyling } from '../../utils/TextUtils';
import { executeCopyToClipboard } from '../../utils/CopyUtils';

interface RecapInteractiveWidgetsProps {
  classes: string;
  preHistoryStreaming: boolean;
  preHistoryProcessingMessage?: string;
  activePreHistory?: any; // Type should be adjusted based on actual PreHistory type
  handleThumbsInput: (isPositive: boolean, source: string) => void;
  handleDownloadPDF: () => Promise<void>;
  pdfDownloading: boolean;
}

export function RecapInteractiveWidgets({
  classes,
  preHistoryStreaming,
  preHistoryProcessingMessage,
  activePreHistory,
  handleThumbsInput,
  handleDownloadPDF,
  pdfDownloading
}: RecapInteractiveWidgetsProps) {
  const { richtextFontSize, richtextRemoveSpacesBetweenSections, copyBehavior, pims } = useVetRec();
  const [copySuccess, setCopySuccess] = useState<boolean>(false);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (copySuccess) {
      timer = setTimeout(() => {
        setCopySuccess(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [copySuccess]);

  const copyToClipboard = async () => {
    try {
      if (activePreHistory === undefined) return;
      setCopySuccess(true);

      const rawContent = convertPreHistoryToString(activePreHistory);

      let processIntoHtml = addMissingIndentLevels(preprocessText(rawContent));
      let clipboardRichtextValue = fixIndentation(processIntoHtml, richtextRemoveSpacesBetweenSections);

      // raw content contains markdown based text
      // processIntoHtml contains the html text
      let clipboardPlaintextValue = pims === "ezyvet" || copyBehavior === "markdown" ? rawContent /*markdown*/ : revertPreprocessedTextNoStyling(processIntoHtml);

      await executeCopyToClipboard(clipboardPlaintextValue, clipboardRichtextValue, richtextFontSize);
    } catch (err) {
      console.error("Copy failed:", err);
    }
  };

  if (preHistoryStreaming || preHistoryProcessingMessage) {
    return null;
  }

  return (
    <div className={classes}>
      <button
        type="button"
        title="Copy the recap"
        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
        onClick={() => copyToClipboard()}
      >
        {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
        {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
      </button>
      <button
        type="button"
        title="Download as PDF"
        disabled={pdfDownloading}
        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
        onClick={() => handleDownloadPDF()}
      >
        {!pdfDownloading && <ArrowDownCircleIcon className="h-5 w-5 text-white hover:text-blue-400"/>}
        {pdfDownloading && <Spinner size="h-5 w-5" timer={false}/>}
      </button>
      <button
        type="button"
        title="Provide positive feedback on records recap"
        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
        onClick={() => handleThumbsInput(true, "PreHistory")}
      >
        <HandThumbUpIcon className="h-5 w-5 text-white hover:text-blue-400"/>
      </button>
      <button
        type="button"
        title="Provide negative feedback on records recap"
        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
        onClick={() => handleThumbsInput(false, "PreHistory")}
      >
        <HandThumbDownIcon className="h-5 w-5 text-white hover:text-blue-400"/>
      </button>
    </div>
  );
}
