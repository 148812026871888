import config from '../config.json'

const url = import.meta.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

export interface SignedUrlResponse {
    token: string;
    bucket: string;
    file_path: string;
}

export async function GetNotesSignedURL(session: string, jwt: string, note_id?: string) {
    const endpoint = note_id
        ? `${url}/v2/uploadNotes/${session}?note_id=${note_id}`
        : `${url}/v2/uploadNotes/${session}`;
        
    let response;
    try {
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for notes from the API.`);
    }
}

export async function GetDiagramSignedURL(session:string, jwt:string, note_id?:string){
    const endpoint = note_id
        ? `${url}/v2/uploadDiagram/${session}?note_id=${note_id}`
        : `${url}/v2/uploadDiagram/${session}`;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for diagram from the API.`);
    }
}

export async function GetDischargeSignedURL(session:string, jwt:string, discharge_id?:string){
    const endpoint = discharge_id 
    ? `${url}/v2/uploadDischarge/${session}?discharge_id=${discharge_id}` 
    : `${url}/v2/uploadDischarge/${session}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for discharge from the API.`);
    }
}

export async function GetTemplateSignedURL(template_id:string, jwt:string){
    const endpoint = `${url}/v2/uploadTemplate/${template_id}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for template from the API.`);
    }
}

export async function GetNotes(session:string, jwt:string){
    const endpoint = url + '/v2/notes/' + session;
    let response: Response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const notes = await response.text();
        return notes;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading notes from the API.`);
    }
}


export async function GetNotesForSessionWithPetArray(session:string, jwt:string){
    const endpoint = url + '/v2/pet_array_notes/' + session;
    let response: Response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            console.error(`HTTP error! Status: ${response.status}`);
            return {};
        }
        const data = await response.json();
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading notes for pet array session from the API.`);
    }
}

export async function GetDischargeForSessionWithPetArray(session:string, jwt:string){
    const endpoint = url + '/v2/pet_array_discharge/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const discharge = await response.json();
        return discharge;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading discharge for pet array session from the API.`);
    }
}

export async function GetOriginalNotes(session:string, jwt:string){
    const endpoint = url + '/v2/original_notes/' + session;
    let response: Response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const notes = await response.text();
        return notes;
    }
    catch (error)
    {
        // Silent fail
    }
}

export async function GetDiagramForSessionWithPetArray(session:string, jwt:string){
    const endpoint = url + '/v2/pet_array_diagrams/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const diagrams = await response.json();
        return diagrams;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading diagrams from the API.`);
    }
}

export async function GetDiagram(session:string, jwt:string){
    const endpoint = url + '/v2/diagrams/' + session;
    let response: Response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const diagram = await response.json();
        return diagram;
    }
    catch (error)
    {
        return []
        //throw new Error(`There was an error downloading notes from the API.`);
    }
}

export async function GetRecordingsAndTranscripts(session:string, jwt:string){
    const endpoint = url + '/v2/recordings_transcripts_pairs/' + session;
    let response: Response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading recordings and transcripts from the API.`);
    }   
}

export async function GetTranscript(session:string, jwt:string){
    const endpoint = url + '/v2/transcript/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const transcript = await response.text();
        return transcript;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading transcript from the API.`);
    }
}

export async function GetDischarge(session:string, jwt:string){
    const endpoint = url + '/v3/discharge/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const discharge = await response.text();
        return discharge;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading discharge from the API.`);
    }
}

export async function GetAuditLogs(session:string, jwt:string){
    const endpoint = url + '/v2/audit_logs/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const audit_logs = await response.json();
        return audit_logs;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading audit logs from the API. E: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetPreHistory(session:string, jwt:string){
    const endpoint = url + '/v2/prehistory/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const prehistory = await response.text();
        return prehistory;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading prehistory from the API.`);
    }
}

export async function GetTemplate(template_id:string, jwt:string, accessKey?:string){
    const endpoint = url + `/v2/template_definition/${template_id}` + (accessKey ? `?access_key=${accessKey}` : "");
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const template = await response.text();
        return template;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading template from the API.`);
    }
}

export async function GetQuestionaire(session:string, jwt:string){
    const endpoint = url + '/v2/questionaire/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const questionaire = await response.text();
        return questionaire;
    }
    catch (error)
    {
        throw new Error(`There was an error downloading questionaire from the API.`);
    }
}

export async function CheckRecordings(session:string, jwt:string){
    const endpoint = url + '/v2/check_recordings/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error getting signed urls for recordings from the API.`);
    }
}


export async function GetRecordings(session:string, jwt:string){
    const endpoint = url + '/v2/recordings/' + session;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;  // Assuming the API returns an object with a 'urls' array
    }
    catch (error)
    {
        throw new Error(`There was an error getting signed urls for recordings from the API.`);
    }
}

export async function GetRecordingsSignedURL(session:string, recording_id:string, extension:string, jwt:string){
    const endpoint = `${url}/v2/uploadRecordings/${session}/${recording_id}/${extension}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error creating full recording signed URL from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetUploadAudioSignedURL(session:string, recording_id:string, extension:string, jwt:string){
    const endpoint = `${url}/v2/uploadAudioFile/${session}/${recording_id}/${extension}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error creating upload audio signed URL from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetRecordingsPartialBackupSignedURL(session:string, recording_id:string, partial_id:string, extension:string, jwt:string){
    const endpoint = `${url}/v2/uploadRecordingsPartialBackup/${session}/${recording_id}/${partial_id}/${extension}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error creating partial backup signed URL from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetRecordingFullBackupSignedURL(session:string, recording_id:string, extension:string, jwt:string){
    const endpoint = `${url}/v2/uploadRecordingsFullBackup/${session}/${recording_id}/${extension}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error creating full backup signed URL from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetPreHistoryUploadURL(session:string, upload_id:string, extension:string, index:string, jwt:string){
    const endpoint = `${url}/v2/uploadPreHistory/${session}/${upload_id}/${extension}/${index}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for prehistory from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}

export async function GetRecapUploadURL(session:string, jwt:string) {
    const endpoint = `${url}/v2/uploadRecap/${session}`
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json() as SignedUrlResponse;
        return data;
    }
    catch (error)
    {
        throw new Error(`There was an error getting the signed URL for recap from the API. Error: ${error instanceof Error ? error.message : error}`);
    }
}