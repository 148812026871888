import { FC } from "react";
import { QuickNoteTypes, VetspireTypes } from "../templates/TemplateUtils";
import { useTemplate } from "../templates/TemplateProvider";
import EnumPicker from "../EnumPicker";
import { useVetRec } from "../../providers/VetRecProvider";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

interface VetspireConfigProps {
    open: (val: boolean) => void;
}

export const VetspireConfig: FC<VetspireConfigProps> = ({ open }) => {
    const { vetspireMapping, updateVetspireType, saveMapping } = useTemplate();
    const { disableExportConfiguration, pims } = useVetRec()

    async function handleTemplateConfig() {
        await saveMapping();
        open(false);
    }

    return (
        <div className="flex flex-col gap-y-4 mt-4 max-h-[60vh] thin-scrollbar overflow-y-auto px-8 border-t border-gray-300 pt-4 items-center">
            {disableExportConfiguration && <div className="mt-2 bg-yellow-200 rounded-md p-4 text-sm text-gray-900 text-center flex flex-row gap-x-3 justify-center items-center mx-auto">
                <ExclamationCircleIcon className="h-4 w-4 inline-block text-yellow-700" />
                Export configuration for template is disabled by you administrator.
            </div>}
            {!pims.includes("vetspire") && !disableExportConfiguration && <div className="mt-2 bg-yellow-200 rounded-md p-4 text-sm text-gray-900 text-center flex flex-row gap-x-3 justify-center items-center mx-auto">
                <ExclamationCircleIcon className="h-4 w-4 inline-block text-yellow-700" />
                <span>You haven't configured Vetspire. Please contact our <a href="mailto:support@vetrec.io" className="underline">support team</a>.</span>
            </div>}
            <div className="flex flex-row items-center gap-x-4 relative mx-auto">
                Mapping:
                <EnumPicker
                    width="w-80"
                    enum={VetspireTypes}
                    selectedOption={vetspireMapping.type}
                    setSelectedOption={(value) => {
                        updateVetspireType(Object.values(VetspireTypes).find((enumValue) => enumValue === value) ?? VetspireTypes.ENCOUNTER)
                    }}
                    disabled={disableExportConfiguration}
                />
            </div>
            {[VetspireTypes.ENCOUNTER.toLowerCase(), VetspireTypes.QUICK_NOTE.toLowerCase()].includes(vetspireMapping.type.toLowerCase()) && <div className="flex flex-row items-center gap-x-4 relative mx-auto">
                Type:
                {vetspireMapping.type === VetspireTypes.QUICK_NOTE && <EnumPicker
                    width="w-80"
                    enum={QuickNoteTypes}
                    selectedOption={vetspireMapping.id ?? QuickNoteTypes.NOTE}
                    setSelectedOption={(value) => {
                        updateVetspireType(Object.values(VetspireTypes).find((enumValue) => enumValue === vetspireMapping.type) ?? VetspireTypes.ENCOUNTER, value)
                    }}
                    disabled={disableExportConfiguration}
                />}
                {vetspireMapping.type === VetspireTypes.ENCOUNTER && <input className="rounded-md h-8 border border-gray-300 px-2 w-80 text-sm" onChange={(event) => {
                    updateVetspireType(Object.values(VetspireTypes).find((enumValue) => enumValue === vetspireMapping.type) ?? VetspireTypes.ENCOUNTER, event.target.value)
                }} placeholder="Optional encounter type ID" disabled={disableExportConfiguration} value={vetspireMapping.id} />}
            </div>}
            <div className="flex flex-col gap-y-4 mx-auto text-sm mt-2">
                <div>
                    <span className="font-semibold">🧑‍⚕️ Encounter</span> - Notes will be written to Encounter
                </div>
                <div>
                    <span className="font-semibold">📝 Quick Note</span> - Notes will be written to Quick Note
                </div>
                <div>
                    <span className="font-semibold">🖇️ Attachment</span> - Notes will be attached to Encounter
                </div>
                <div className="text-center">
                    <span className="font-semibold">For more information:</span> <a href="https://help.vetrec.io/en/collections/11066976-vetspire" target="_blank" className="underline" rel="noreferrer">Vetspire Documentation</a>
                </div>
            </div>
            <div className="mt-3 sm:mt-3 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center w-full sm:w-96">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => handleTemplateConfig()}
                >
                    Save Configuration
                </button>
            </div>
        </div>
    );
};
