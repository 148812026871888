import { useEffect, useState } from "react";
import {  LanguageIcon, LockClosedIcon, Cog6ToothIcon, DocumentTextIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import va from '@vercel/analytics';
import { useLocation, useNavigate } from "react-router-dom";
import TemplatesSettings from "./subcomponents/settings/TemplatesSettings";
import ControlsSettings from "./subcomponents/settings/ControlsSettings";
import SSOSettings from "./subcomponents/settings/SSOSettings";
import GeneralSettings from "./subcomponents/settings/GeneralSettings";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const tabs = [
    { id: 'General', name: 'General', icon: Cog6ToothIcon, current: true, comingSoon: false },
    { id: 'SSO', name: 'SSO', icon: LockClosedIcon, current: true, comingSoon: false },
    { id: 'Templates', name: 'Templates (Coming Soon)', icon: DocumentTextIcon, current: true, comingSoon: true },
    { id: 'Controls', name: 'Controls (Coming Soon)', icon: AdjustmentsHorizontalIcon, current: true, comingSoon: true },
]

export default function Settings() {
    const [selectedTab, setSelectedTab] = useState<string>("General")

    function handleTabChange(value:string): void {
        setSelectedTab(value)
    }

    return(<div className="p-4">
        {/*Header*/}
        <div className="border-b border-gray-400 pb-5 mb-3 py-4 sm:pt-10 flex flex-row items-center justify-between">
            <div className="flex flex-row gap-x-8 items-center">
                <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:text-3xl sm:tracking-tight">
                    Settings
                </h2>
                <SettingsTabs selectedTab={selectedTab} handleTabChange={handleTabChange}/>
            </div>
        </div>
        <div>
            {selectedTab == 'General' && <GeneralSettings />}
            {selectedTab == 'SSO' && <SSOSettings />}
            {selectedTab == 'Templates' && <TemplatesSettings />}
            {selectedTab == 'Controls' && <ControlsSettings />}
        </div>
    </div>)
}

interface SettingsTabsProps {
    selectedTab: string;
    handleTabChange: (value:string) => void;
}

function SettingsTabs(props:SettingsTabsProps) {
    const {selectedTab, handleTabChange} = props
    return(<div>
        <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
                Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current)?.name}
                onChange={(event) => handleTabChange(event.target.value)}
            >
                {tabs.map((tab) => (
                    <option key={tab.id} value={tab.id}>{tab.name}</option>
                ))}
            </select>
        </div>
        <div className="hidden sm:block">
            <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                        <button
                            key={tab.name}
                            onClick={() => handleTabChange(tab.id)}
                            className={classNames(
                                selectedTab == tab.id
                                    ? 'border-main-lighter text-main'
                                    : `border-transparent text-gray-500 ${tab.comingSoon ? "" : "hover:text-gray-700 hover:border-gray-300 cursor-pointer"}`,
                                'group inline-flex items-center border-b-2 py-2 px-1 text-sm font-medium'
                            )}
                            aria-current={selectedTab == tab.id ? 'page' : undefined}
                            disabled={tab.comingSoon}
                        >
                            <tab.icon
                                className={classNames(
                                    selectedTab == tab.id ? 'text-main-lighter' : `text-gray-400 ${tab.comingSoon ? "" : "group-hover:text-gray-500"}`,
                                    '-ml-0.5 mr-2 h-5 w-5'
                                )}
                                aria-hidden="true" />
                            <span>{tab.name}</span>
                        </button>
                    ))}
                </nav>
            </div>
        </div>
    </div>)
}