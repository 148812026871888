import type { MemberObject } from "../utils/MemberUtils";
import type { IMicrophone } from "../utils/RecordingUtils";
import type { SessionObject } from "../utils/SessionUtils";

export enum PermissionStatus {
  GRANTED = "granted",
  DENIED = "denied",
  CHECKING = "checking",
  FETCHING = "fetching",
  FAILED_TIMEOUT = "failed_timeout",
  FAILED = "failed",
}

export enum RecordingStatus {
  PREPARING = "preparing",
  NOTSTARTED = "not_started",
  RECORDING = "recording",
  STOPPED = "stopped",
  UPLOADING = "uploading",
  REUPLOADING = "reuploading",
  FAILED = "failed",
  FAILED_NAME = "failed_name",
  FAILED_UPLOAD = "failed_upload",
  FAILED_NOT_FOUND = "failed_not_found",
  FAILED_REPEATED_NAME_IN_MULTIPET = "failed_repeated_name_in_multipet",
}

export interface StartRecordingArgs {
  reset: boolean;
  pets: string[];
  session?: SessionObject;
  owner?: string;
  templateId?: string;
}

export interface StopRecordingArgs {
  templateId?: string;
}

export interface RecordingContextType {
  name: string;
  petNames: string[];
  seconds: number;
  sessionId: string;
  recordingAllowed: PermissionStatus;
  recordingState: RecordingStatus;
  microphones: IMicrophone[] | undefined;
  selectedMicrophone: IMicrophone | undefined;
  anyRecording: boolean;
  addToRecording: boolean;
  mediaStream?: MediaStream;
  consent: boolean;
  consentPending: boolean;
  membersList: MemberObject[];
  recordAs: string;
  existingTemplateId: string | undefined;
  externalTypeId: string | undefined;
  uploadPercentage: number;
  startRecording: (args: StartRecordingArgs) => Promise<void>;
  stopRecording: (args: StopRecordingArgs) => Promise<void>;
  updateConsent: (consent: boolean) => Promise<void>;
  updateName: (name: string) => void;
  updateSelectedMicrophone: (microphone: IMicrophone) => void;
  updateRecordAs: (recordAs: string) => void;
  handleUpload: (reupload: boolean) => Promise<void>;
  downloadRecording: () => void;
  setPetNames: (petNames: string[]) => void;
  spokenLanguage: string;
}
