import ReactQuill from "react-quill";
import { SpacingText } from "../../utils/TextUtils";
import { ArrowDownTrayIcon, ArrowPathIcon, CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import { Spinner } from "../../utils/Spinner";
import { RecordingTranscriptPair } from "../../utils/SessionUtils";
import { WarningBanner } from "../common/WarningBanner";
import { useVetRec } from "../../providers/VetRecProvider";
import { capitalize } from "lodash";
import { ErrorBanner } from "../common/ErrorBanner";

export const getFileExtensionFromUrl = (url: string) => {
    return url.split('?')[0].split('.').pop(); // Extract the extension from the URL before the query params
};

interface RecordingTranscriptPairBlockProps {
    recordingTranscriptPair: RecordingTranscriptPair;
    downloadFile: (url: string, fileName: string) => void;
    downloadFileName?: string;
    defaultOpen?: boolean;
    source?: string;
    sessionLanguage?: string;
    processRecordings: () => void
}

export const RecordingTranscriptPairBlock: React.FC<RecordingTranscriptPairBlockProps> = ({ recordingTranscriptPair, downloadFileName, downloadFile, defaultOpen, source, sessionLanguage, processRecordings }) => {
    const getFallbackRecordingName = (transcriptTitle: string | undefined, transcriptionStatus: string, recordingTimestampIso: string | undefined) => {
        try {
            const date_string = recordingTimestampIso ? (new Date(recordingTimestampIso)).toLocaleString() : undefined
            if (transcriptionStatus === "Completed" && transcriptTitle) 
                return date_string ? `${transcriptTitle} (from ${date_string})` : transcriptTitle;

            const recording_with_timestamp = date_string ? `recording from ${date_string}` : "Recording and Transcript"

            let fallback_title = recording_with_timestamp
            if (transcriptionStatus === "Not Started") {
                return capitalize(`${recording_with_timestamp} (Transcription not started)`) 
            } else if (transcriptionStatus === "Processing") {
                return `Processing ${recording_with_timestamp}`
            } else if (transcriptionStatus === "FailedNoData") {
                return capitalize(`Recording missing, skipped transcription (${recording_with_timestamp})`) 
            } else if (transcriptionStatus.includes("Failed")) {
                return capitalize(`Failed transcription (${recording_with_timestamp})`) 
            }

            return capitalize(fallback_title);    
        } catch (error) {
            return "Recording and Transcript";
        }
    }

    const recordingUrl = recordingTranscriptPair.recording_url;
    const isPrimaryRecordingAvailable = !!recordingUrl
    const transcriptStatus: string = recordingTranscriptPair.transcript_status;
    const title = getFallbackRecordingName(recordingTranscriptPair.transcript_title, transcriptStatus, recordingTranscriptPair.recording_timestamp);
    const transcript_id = recordingTranscriptPair.transcript_id

    const { isImpersonation } = useVetRec()

    const statusToColorDict = {
        "Completed": "green",
        "Processing": "blue",
        "Failed": "red",
        "FailedNoData": "yellow",
        "Not Started": "blue"
    }

    const color = statusToColorDict[transcriptStatus as keyof typeof statusToColorDict] ?? "blue"

    const shouldShowReprocess = transcriptStatus === "FailedNoData"

    return (
        <div className="w-full rounded-lg bg-white">
            <Disclosure defaultOpen={defaultOpen}>
                {({ open }) => (<>
                    <Disclosure.Button className={`flex w-full justify-between items-center ${open ? "rounded-t-lg" : "rounded-lg"} bg-${color}-100 px-4 py-2 text-left text-sm font-semibold text-${color}-900 hover:bg-${color}-200 focus:outline-none focus-visible:ring focus-visible:ring-${color}-500/75 h-12`}>
                        <div className="flex items-center gap-x-2">
                            {transcriptStatus === "Processing" && <Spinner size="h-4 w-4" timer={true} className={`fill-${color}-900`} />}
                            {transcriptStatus === "Failed" && <ExclamationCircleIcon className={`h-5 w-5 text-${color}-500`} />}
                            {transcriptStatus === "FailedNoData" && <ExclamationTriangleIcon className={`h-5 w-5 text-${color}-500`} />}
                            {transcriptStatus === "Completed" && <CheckCircleIcon className={`h-5 w-5 text-${color}-500`} />}
                            <span>{title}</span>
                        </div>
                        <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-${color}-500 stroke-[3]`} />
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-sm text-gray-500">
                        <div className="px-6 pb-4 pt-4 text-sm text-gray-500 border border-t-0 border-gray-300 rounded-b-lg shadow space-y-2">
                            {isImpersonation && <div className="px-4 py-2 flex flex-col items-center bg-yellow-100 rounded-md text-center text-sm text-yellow-900 border border-yellow-700 border-dashed">
                                <span>Recording ID: {recordingTranscriptPair.recording_id}</span>
                                {transcript_id && <span>Transcript ID: {transcript_id}</span>}
                            </div>}
                            {isPrimaryRecordingAvailable ? <div className="flex flex-row items-center gap-x-2">
                                <audio key={recordingUrl} controls src={recordingUrl} className="w-full my-4 fill-green-600">
                                    Your browser does not support the audio element.
                                </audio>
                                <button onClick={() => downloadFile(recordingUrl, downloadFileName + "." + getFileExtensionFromUrl(recordingUrl))} className="text-white p-2 bg-blue-600 rounded-md"><ArrowDownTrayIcon className="h-5 w-5" /></button>
                            </div> : (transcriptStatus !== "FailedNoData" && <div className="w-full text-center">
                                <WarningBanner warningText="We are unable to display this recording. For access to the raw recordings contact support@vetrec.io" />
                            </div>)}
                            {transcriptStatus === "Not Started" && <div className="w-full text-center">
                                There is no transcript for this recording. Please process the visit to see the transcript.
                            </div>}
                            {transcriptStatus === "Processing" && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-6 mb-8 font-semibold'>
                                <div>Transcribing recording 🤖...</div>
                                <Spinner size="w-12 h-12" timer={true}/>
                                <div className="text-sm text-center text-gray-700 font-normal">Transcript will be ready within 60s.<br/>Feel free to navigate away if you need to.</div>
                            </div>}
                            {transcriptStatus === "Failed" && <ErrorBanner errorText="There was an issue transcribing this recording. Reprocess your visit to try again." />}
                            {transcriptStatus === "FailedNoData" && <div className="flex flex-row gap-x-2">
                                <WarningBanner warningText={`Your recording has not yet been uploaded. ${(source && ["ios", "android"].includes(source)) ? "Please confirm the recording has been uploaded from your phone app and re-process." : "Please try again in a few seconds."} Reach out to support for any assistance.`} />
                                <button 
                                        onClick={processRecordings} 
                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    <ArrowPathIcon className="mr-2 h-4 w-4" />
                                    Reprocess
                                </button>
                            </div>}
                            {transcriptStatus === "Completed" && <ReactQuill
                                theme='snow'
                                placeholder='Mr.Wiggles is looking mighty well today...'
                                className='block w-full max-h-[40vh] overflow-y-auto rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                                value={SpacingText(recordingTranscriptPair.transcript_content)}
                                readOnly
                                modules={{toolbar: false}}
                            />}
                        </div>
                    </Disclosure.Panel>
                </>)}
            </Disclosure>
        </div>
    );
};