export async function executeWithRetries<T>(
  asyncFunc: () => Promise<T>,
  maxRetries: number = 3,
  initialBackoff: number = 500
): Promise<T | undefined> {
  let attempt = 1;

  while (attempt <= maxRetries) {
    try {
      return await asyncFunc();
    } catch (error) {
      if (attempt === maxRetries) {
        throw error;
      } else {
        const backoff = initialBackoff * Math.pow(2, attempt);
        await new Promise((resolve) => setTimeout(resolve, backoff));
      }

      attempt++;
    }  
  }

  return undefined;
}
