import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplatePicker from './TemplatePicker'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { useHistory } from '../../providers/HistoryProvider'
import { TemplateType } from './TemplateUtils'
import { isMobileDevice } from '../../utils/deviceUtils'

export default function CommunicationsTemplateModal() {

    const {changeDischargeTemplate, handleDischargeTemplateChange, templateDischarge, setTemplateDischarge, generateDischarge} = useHistory()
    const isMobile = useMemo(isMobileDevice, [])
    const [additionalInstructions, setAdditionalInstructions] = useState<string>()

    function handleTemplateChange(){
        handleDischargeTemplateChange(false)
        generateDischarge(true, additionalInstructions)
    }

    useEffect(() => {
        setAdditionalInstructions(undefined)
    }, [changeDischargeTemplate])
    
    return (
        <Transition.Root show={changeDischargeTemplate} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={handleDischargeTemplateChange}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <ArrowPathIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Generate Client Document
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Generate a client communications document for the patient.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex flex-col gap-x-2 gap-y-4">
                            <TemplatePicker template={templateDischarge} setTemplate={setTemplateDischarge} className={""} templateBackground={"bg-white"} type={TemplateType.COMMUNICATIONS} pickerDirection={isMobile ? 'above' : "below"} pickerWidth='w-full'/>
                            <div className='relative'>
                                <label
                                    htmlFor="name"
                                    className={`absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-main-text-darker z-[0] bg-white flex gap-x-2`}
                                >
                                    Additional Instructions <span className='bg-blue-600 text-white px-2 rounded-md'>Beta</span>
                                </label>
                                <textarea
                                    className='w-full border border-gray-300 rounded-md p-4 text-sm text-gray-600 z-[-1]'
                                    value={additionalInstructions}
                                    onChange={(event) => setAdditionalInstructions(event.target.value)}
                                    placeholder={"[Optional] Let VetRec know any additional instructions for the document."}
                                />
                            </div>
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10"
                                onClick={() => handleTemplateChange()}
                            >
                                Generate
                            </button>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}