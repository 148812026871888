import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { CheckIcon, GlobeAltIcon } from '@heroicons/react/24/solid'
import { languages } from '@neum/frontend-shared/utils/LanguageUtils';

interface LanguageSelectorButtonProps {
    selectedLanguage: string;
    setSelectedLanguage: (language: string) => void;
    showLanguageLabel?: boolean;
    className?: string;
}

export default function LanguageSelectorButton({ selectedLanguage, setSelectedLanguage, showLanguageLabel = true, className = '' }: LanguageSelectorButtonProps) {
    return (
        <Menu as="div" className={`relative inline-block text-left ${className}`}>
            <div>
                <Menu.Button className="inline-flex items-center justify-center gap-x-2 rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
                    <GlobeAltIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                    {showLanguageLabel && <span className="text-gray-700">{selectedLanguage}</span>}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="px-4 py-2 border-b border-gray-200">
                        <p className="text-sm text-gray-500 whitespace-nowrap">Language spoken while recording</p>
                    </div>
                    <div className="py-1">
                        {languages.map((language) => (
                            <Menu.Item key={language}>
                                {({ active, close }) => (
                                    <button
                                        onClick={() => {
                                            setSelectedLanguage(language);
                                            close();
                                        }}
                                        className={`w-full relative select-none py-2 px-4 flex flex-row justify-between ${
                                            active ? 'bg-accent text-main-text-darker' : 'text-main-text-darker'
                                        }`}
                                    >
                                        <span className={`block truncate ${
                                            selectedLanguage === language ? 'font-medium' : 'font-normal'
                                        }`}>
                                            {language}
                                        </span>
                                        {selectedLanguage === language && (
                                            <span className="flex items-center text-gray-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
} 