import { useOrganization, useOrganizationList } from "@clerk/clerk-react";
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useMemo, useRef, useState, useEffect } from "react";
import { capitalizeFirstLetter } from "../../../utils/TextUtils";
import { Team } from "../types/user";

interface ExistingTeamSelectorProps {
  setSelectedTeams: (teams: string[]) => void;
  initialSelectedTeams?: string[];
  teamsInSuperOrg?: Team[];
}

export function ExistingTeamSelector({ setSelectedTeams, initialSelectedTeams = [], teamsInSuperOrg }: ExistingTeamSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { userMemberships } = useOrganizationList({
    userMemberships: {
      pageSize: 500
    }
  });
  const [query, setQuery] = useState<string>("");
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>(initialSelectedTeams);

  const organizations = useMemo(() => {
    // Filter organizations where the user is an admin
    let teams = userMemberships.data
      ?.filter((membership) => membership.role === "org:admin" || membership.role === "org:medical_director")
      .map((membership) => membership.organization) || [];
    
    // Filter by search query if present
    if (query) {
      teams = teams.filter((team) => team.name.toLowerCase().includes(query.toLowerCase()));
    }
    
    // Filter out teams that are already in the super organization
    if(teamsInSuperOrg) {
      teams = teams.filter((team) => !teamsInSuperOrg.some((superTeam) => superTeam.id === team.id));
    }
    
    return teams.sort((a, b) => a.name.localeCompare(b.name));
  }, [userMemberships, query, teamsInSuperOrg]);

  // Update parent component with selected teams whenever selection changes
  useEffect(() => {
    setSelectedTeams(selectedTeamIds);
  }, [selectedTeamIds, setSelectedTeams]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add a state to control dropdown positioning
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  // Update the position of the dropdown when it opens
  const updateDropdownPosition = () => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY + 5,
        left: rect.left,
        width: rect.width
      });
    }
  };

  // Update position when toggling dropdown
  const handleToggle = () => {
    if (!isOpen) {
      // Update position before opening the dropdown
      updateDropdownPosition();
    }
    setIsOpen(!isOpen);
  };

  const handleSelect = (team: any) => {
    setSelectedTeamIds(prev => {
      if (prev.includes(team.id)) {
        return prev.filter(id => id !== team.id);
      } else {
        return [...prev, team.id];
      }
    });
  };

  // Find selected team objects
  const selectedTeams = useMemo(() => {
    return organizations.filter(team => selectedTeamIds.includes(team.id));
  }, [organizations, selectedTeamIds]);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div className="relative">
        <button
          type="button"
          className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left rounded-md shadow-sm hover:bg-gray-50/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 h-10 gap-x-2"
          onClick={handleToggle}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
        >
          {selectedTeams.length > 0 ? (
            <div className="flex items-center overflow-hidden">
              <div className="flex items-center -space-x-2 overflow-hidden mr-2">
                {selectedTeams.slice(0, 3).map((team, i) => (
                  <img key={i} src={team.imageUrl} className="w-8 h-8 rounded-full border-2 border-white" />
                ))}
              </div>
              <span className="block truncate text-gray-900">
                {selectedTeams.length === 1
                  ? selectedTeams[0].name
                  : `${selectedTeams.length} teams selected`}
              </span>
            </div>
          ) : (
            <span className="block truncate text-gray-900">Select Teams</span>
          )
          }
          <ChevronUpDownIcon className="w-8 h-8 ml-2 -mr-1 text-gray-900 stroke-2" aria-hidden="true" />
        </button>

        {isOpen && (
          <div 
            className="absolute z-50 mt-4 bg-white rounded-md shadow-lg border-gray-300 border"
            style={{
              position: 'absolute',
              top: '100%', // Position below the button
              left: 0,
              right: 0,
              marginTop: '10px',
              width: '100%'
            }}
          >
            <div className="px-1 pt-2">
              <div className="relative">
                <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                <input
                  type="text"
                  className="w-full pl-10 pr-3 py-2 text-sm leading-5 text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500 h-8"
                  placeholder="Find team"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="py-2 max-h-60 overflow-y-auto">
              <ul
                className="text-base focus:outline-none sm:text-sm"
                role="listbox"
              >
                {organizations.map((team, teamidx) => (
                  <li
                    key={teamidx}
                    className={`relative py-2 px-2 text-gray-700 cursor-pointer select-none hover:bg-gray-300 flex flex-row gap-x-3 items-center ${team.publicMetadata && "super_org" in team.publicMetadata ? 'opacity-50 pointer-events-none' : ''}`}
                    role="option"
                    onClick={() => handleSelect(team)}
                    title={team.name}
                  >
                    <img src={team.imageUrl} className="w-6 rounded-full" alt={team.name} />
                    <span className="block truncate max-w-72">{capitalizeFirstLetter(team.name)}</span>
                    {selectedTeamIds.includes(team.id) && <CheckIcon className="h-6 w-8" />}
                    {team.publicMetadata && "super_org" in team.publicMetadata && <span className="px-2 py-1 text-xs font-semibold text-white bg-blue-500 rounded-full w-auto">In Organization</span>}
                  </li>
                ))}
                {organizations.length === 0 && (
                  <li className="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9">
                    No options found
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
