import { UsersIcon } from '@heroicons/react/24/solid'
import { Fragment, useMemo, useState } from 'react';
import { useVetRec } from '../../providers/VetRecProvider';
import { useTour } from '../../providers/TourProvider';
import { Dialog, Transition } from '@headlessui/react';
import { UserOrganizationInvitationResource } from "@clerk/types";
import Banner, { BannerType } from '../common/Banner';
import { Spinner } from '../../utils/Spinner';

export interface InvitationModalProps {
    onDismiss: () => void;
}

export default function InvitationModal({ onDismiss }: InvitationModalProps) {
    const { invitations, acceptInvitation } = useVetRec()
    const { isTourRunning } = useTour()
    const [acceptingInvitation, setAcceptingInvitation] = useState<{ [key: string]: boolean }>({})
    const [error, setError] = useState<string>()
    const showInvitations = useMemo(() => {
        if (isTourRunning) {
            return false;
        }
        return invitations ? invitations.length > 0 : false;
    }, [invitations, isTourRunning]);

    const handleAcceptInvitation = async (invitation: UserOrganizationInvitationResource) => {
        try {
            setAcceptingInvitation(prev => ({ ...prev, [invitation.id]: true }))
            await acceptInvitation(invitation)
            setAcceptingInvitation(prev => ({ ...prev, [invitation.id]: false }))
        } catch (e) {
            setAcceptingInvitation(prev => ({ ...prev, [invitation.id]: false }))
            setError("Failed to accept invitation")
        }
    }

    return (
        <Transition.Root show={showInvitations} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={onDismiss}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:py-6 sm:px-8">
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                    <UsersIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Team Invitations
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Looks like you have some pending invitations to join teams 🎉🎉🎉 <br />
                                            Accept the invites to join the teams.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 flex flex-col gap-y-4 justify-center items-center">
                                    {showInvitations && (
                                        <ul className="divide-y divide-gray-200 w-full">
                                            {invitations && invitations.map((invitation) => (
                                                <li key={invitation.id} className="py-2">
                                                    <div className="flex items-center justify-between w-full">
                                                        <div className='flex items-center gap-2'>
                                                            <img className="h-8 w-8 rounded-full" src={invitation.publicOrganizationData.imageUrl} alt="" />
                                                            <div>
                                                                <div className="text-sm font-medium">{invitation.publicOrganizationData.name}</div>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center gap-4'>
                                                            <button className='cursor-pointer bg-blue-600 text-white hover:bg-blue-500 px-2 py-1 rounded-md text-sm' onClick={() => handleAcceptInvitation(invitation)} disabled={acceptingInvitation[invitation.id]}>
                                                                {acceptingInvitation[invitation.id] ? <Spinner size='w-4 h-4' timer={false} /> : 'Accept'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                {error && <Banner bannerType={BannerType.Error} message={error} />}
                                <button onClick={onDismiss} className='w-full mt-4 bg-gray-500 text-white hover:bg-gray-400 px-2 py-2 rounded-md text-sm'>
                                    Dismiss
                                </button>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
} 