import { ExclamationTriangleIcon, PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useMemo, useRef, useState } from "react";
import { Spinner } from "../../utils/Spinner";
import { useHistory } from "../../providers/HistoryProvider";
import { Message, sendChatbotMessage } from "../../serverActions/chatbot";
import { useAuth } from "@clerk/clerk-react";
import { marked, Tokens } from 'marked';
import 'katex/dist/katex.min.css';
import markedKatex from 'marked-katex-extension';
import RandomPetGraphic from "../common/RandomPetGraphic";
import va from '@vercel/analytics';
import { toTitleCase } from "../../utils/TextUtils";
import { v4 as uuidv4 } from 'uuid';

interface ChatBotProps {
    isOpen: boolean;
    onClose: () => void;
}

export const Chatbot: React.FC<ChatBotProps> = ({ isOpen, onClose }) => {

    const [conversationId, setConversationId] = useState<string>(uuidv4())
    const [message, setMessage] = useState<string>()
    const [streamedMessage, setStreamedMessage] = useState<string>()
    const [messages, setMessages] = useState<Message[]>([])
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const { activeSession, tab, chatBotContext } = useHistory()
    const { getToken } = useAuth()
    const streamedMessageRef = useRef<string>()
    const messagesRef = useRef<Message[]>([])
    const previousVisit = useRef(activeSession);
    const activeSessionRef = useRef(activeSession)
    const chatBotContextRef = useRef(chatBotContext)
    const conversationIdRef = useRef(conversationId)

    useEffect(() => {
        activeSessionRef.current = activeSession
    }, [activeSession])

    useEffect(() => {
        chatBotContextRef.current = chatBotContext
    }, [chatBotContext])

    useEffect(() => { 
        messagesRef.current = messages
    }, [messages])

    useEffect(() => {
        streamedMessageRef.current = streamedMessage
    }, [streamedMessage])

    const onMessageReceived = (data:string, messageConversationId:string) => {
        if(conversationIdRef.current === messageConversationId && (streamedMessageRef.current?.length ?? 0) < data.length){
            setStreamedMessage(data)
            streamedMessageRef.current = data
        }
    }

    const onMessageError = () => {
        setAwaitingResponse(false)
        setError("We couldn't process the message. Try again.")
    }

    const onMessageEnd = (messageConversationId:string) => {
        if(conversationIdRef.current === messageConversationId){
            setAwaitingResponse(false)
            const newMessages = [...messagesRef.current, {content: streamedMessageRef.current ?? "", isUser: false}]
            setMessages([...newMessages])
            setStreamedMessage(undefined)
            streamedMessageRef.current = undefined
        }
    }

    const handleSendMessage = async (message_force?:string) => {
        setError(undefined)
        setStreamedMessage(undefined)
        streamedMessageRef.current = undefined
        const message_to_send = message_force ? message_force : message
        if(message_to_send){
            setAwaitingResponse(true)
            setMessages((prev) => [...prev, {content: message_to_send, isUser: true}])
            va.track("Chatbot_Message_Sent", {visit: activeSessionRef.current?.id ?? ""})
            await sendChatbotMessage(
                [...messages, { content: message_to_send, isUser: true }],
                activeSessionRef.current?.id ?? "",
                chatBotContextRef.current,
                await getToken({ template: "supabase" }) ?? "",
                onMessageReceived,
                onMessageError,
                onMessageEnd,
                conversationIdRef.current,
                Object.values(activeSessionRef.current?.pet_to_soap_node_mapping ?? {})[0]
            )
            setMessage("")
        }
    }

    const handleStartChat = () => {
        va.track("Chatbot_Restart", {visit: activeSessionRef.current?.id ?? ""})
        const newConversationId = uuidv4()
        setConversationId(newConversationId)
        conversationIdRef.current = newConversationId
        setMessages([])
        setMessage(undefined)
        streamedMessageRef.current = undefined
        setStreamedMessage(undefined)
        setAwaitingResponse(false)
        setError(undefined)
    }

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (divRef.current && streamedMessageRef.current !== undefined) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
        }
    }, [streamedMessageRef.current]);

    useEffect(() => {
        setTimeout(() => {
            if (divRef.current) {
                divRef.current.scrollTop = divRef.current.scrollHeight;
            }
        }, 100);
    }, [messages]);

    useEffect(() => {
        if(activeSession?.id !== previousVisit.current?.id)
        {
            handleStartChat()
        }
        previousVisit.current = activeSession
    }, [activeSession])

    const notesRecs = [
        { label: "Generate differentials for case", text: "Generate differentials for the case" },
        { label: "Generate dosages for medications", text:"Generate dosages for the medications in the case"},
        { label: "Educational material for recommendations", text: "Generate educational material for recommendations" }
    ];
    
    const prehistoryRecs = [
        { label: "Change in diagnostic test results overtime", text:"Show the changes in diagnostic test results overtime"},
        { label: "Changes in weight overtime", text:"Show the changes in patient weight overtime"},
        { label: "Last vaccines given", text:"Show when the last vaccines were given"}
    ];

    const recs = useMemo(() => {
        return tab === "prehistory" ? prehistoryRecs : notesRecs;
    }, [tab]);

    return (
        <div className="z-[999999999]">
            <div
            className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${
                isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
            } flex flex-col h-screen`}
            onClick={onClose}
            >
                <div
                    className={`fixed top-0 right-0 w-full sm:w-[600px] h-full bg-white shadow-lg transform transition-transform ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                    } flex-col flex gap-y-2 pt-4 px-2`}
                    onClick={(e) => {e.stopPropagation()}}
                >
                    <div className="w-full flex flex-row justify-between items-center px-2">
                        <div className="font-semibold text-lg flex flex-row items-center gap-x-2">
                            Chat with D.A.V.I.D
                            <div className="text-white bg-blue-600 rounded-md px-2 text-sm">
                                Beta
                            </div>
                        </div>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            <XMarkIcon className="w-6 h-6"/>
                        </button>
                    </div>
                    <div className="px-2 flex flex-col gap-y-4 mt-4">
                        <div className="text-gray-500 text-sm">
                            <span className="font-semibold text-gray-900">D.A.V.I.D</span> (Diagnostic Assistant for Veterinary Insights and Differentials) is a virtual assistant that can help you with your medical questions.
                        </div>
                        <button className="w-full bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700 shadow" onClick={() => {handleStartChat()}}>
                            Start New Chat
                        </button>
                    </div>
                    <div className="px-2">
                        <div className="px-2 bg-yellow-100 rounded-md text-sm text-gray-900 text-center flex flex-row gap-x-2 items-center py-2">
                            <ExclamationTriangleIcon className="w-5 h-5 text-yellow-500 flex-shrink-0"/>
                            Double check all information provided by D.A.V.I.D as the system could make mistakes. D.A.V.I.D is still in beta.
                        </div>
                    </div>
                    <div className="px-2 mt-2">
                        <div className="px-2 bg-gray-100 rounded-md text-sm text-gray-600 text-center flex flex-row gap-x-2 items-center justify-center py-2">
                            <span className="font-semibold">Context:</span>{activeSession?.name} - {toTitleCase(chatBotContext.replace("_", " "))}
                        </div>
                    </div>
                    <div className="h-full bg-gray-100 rounded-md mx-2 mt-2 p-2 flex flex-col gap-y-4 overflow-y-auto thin-scrollbar" ref={divRef}>
                        {messages.length > 0 && messages.map((message, index) => (
                            <MessageComponent key={index} message={message} index={index}/>
                        ))}
                        {streamedMessageRef.current && <div key={9999} className={`flex flex-row gap-x-2 justify-start`}>
                            <MessageComponent key={9999} message={{
                                isUser: false,
                                content:streamedMessageRef.current
                            }} index={9999}/>
                        </div>}
                        {awaitingResponse && !streamedMessageRef.current && <div className="flex flex-row gap-x-2 justify-start">
                            <div className={`p-2 rounded-md bg-gray-200 text-gray-900 border border-gray-300 shadow flex flex-row gap-x-2`}>
                                Thinking
                                <Spinner size='h-5 w-5' timer={true}/>
                            </div>
                        </div>}
                        {messages.length === 0 && <div className="flex flex-col items-center justify-center gap-y-2 h-full">
                            <div className="text-gray-500 text-lg">How can we help you?</div>
                            <div className="flex justify-center items-center w-full">
                                <div className="w-[180px] h-[180px] flex items-center justify-center">
                                    <RandomPetGraphic 
                                        heightStyling="max-h-[160px] max-w-[160px] w-auto h-auto object-contain" 
                                        containerClassName="flex justify-center items-center" 
                                    />
                                </div>
                            </div>
                            <div className="pt-4 flex flex-col gap-y-4 text-center items-center">
                                {recs.map(rec => (
                                    <div className="text-md bg-blue-100 p-2 rounded-md shadow hover:bg-blue-300 cursor-pointer" onClick={() => {void handleSendMessage(rec.text)}}>
                                        {rec.label}
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                    <div className="flex flex-col gap-y-2 mb-20 px-2">
                        <div className='flex items-center gap-x-2 w-full'>
                            <input 
                            type="text" 
                            className="w-full h-10 border border-gray-300 rounded-md" placeholder="Send message to D.A.V.I.D" onChange={(event) => {setMessage(event.target.value)}} 
                            disabled={awaitingResponse} value={message} 
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSendMessage().catch(console.error)
                                }
                              }}/>
                            <button className="h-10 w-10 bg-main-button rounded-md flex items-center justify-center hover:bg-blue-500" onClick={() => {void handleSendMessage()}} disabled={awaitingResponse || message ? false : true}>
                                {!awaitingResponse && <PaperAirplaneIcon className="h-5 w-5 text-white" aria-hidden="true" />}
                                {awaitingResponse && <Spinner size='h-5 w-5' timer={false}/>}
                            </button>
                        </div>
                        {error && <div className="px-2 bg-red-100 rounded-md text-sm text-red-600 text-center flex flex-row gap-x-2 items-center justify-center py-2">
                            <ExclamationTriangleIcon className="w-5 h-5 text-red-500 flex-shrink-0"/>
                            {error}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

interface MessageComponentProps {
    message: Message;
    index: number;
}

const MessageComponent: React.FC<MessageComponentProps> = ({ message, index }) => {
    const [parsedContent, setParsedContent] = useState<string>('');

    const renderer = new marked.Renderer();

    // Customize the table renderer
    renderer.table = (token: Tokens.Table) => {
        const header = token.header
            .map((headerCell) => `<th class="px-4 py-2 bg-gray-200 border text-left">${headerCell.text}</th>`)
            .join('');
        
        const body = token.rows
            .map((row) =>
                `<tr>${row
                    .map((cell) => `<td class="px-4 py-2 border text-left">${cell.text}</td>`)
                    .join('')}</tr>`
            )
            .join('');
    
        return `
            <div class="overflow-x-auto">
                <table class="min-w-full bg-white border border-gray-300 mb-4">
                    <thead>
                        <tr>${header}</tr>
                    </thead>
                    <tbody>
                        ${body}
                    </tbody>
                </table>
            </div>`;
    };
    
    marked.use({ renderer });
    
    marked.use({ renderer });

    const preProcessChat = async (content: string) => {
        content = content 
            .replace(/\\\[/g, '$$$')  // Replace all occurrences of \[ with $$
            .replace(/\\\]/g, '$$$') // Replace all occurrences of \] with $$
            .replace(/\\\(/g, '$$$')  // Replace all occurrences of \( with $$
            .replace(/\\\)/g, '$$$'); // Replace all occurrences of \) with $$

        marked.use(markedKatex({ throwOnError: false }));
        let s = await marked(content);
        s = s.trim()
            .replace(/<ul>/g, '<ul class="list-disc pl-5">')
            .replace(/<ol>/g, '<ol class="list-decimal pl-5">')
            .replace(/<\/ol>/g, '</ol><br/>')
            .replace(/<\/ul>/g, '</ul><br/>')
            .replace(/<\/p>/g, '</p><br/>')
        
        if (s.trim().endsWith("</p><br/>")) {
            s = s.replace(/<\/p><br\/>\s*$/, "</p>");
        }

        return s;
    };

    useEffect(() => {
        const parseMarkdown = async () => {
            const html = await preProcessChat(message.content);
            setParsedContent(html);
        };
        parseMarkdown().catch(console.error);
    }, [message.content]);

    return (
        <div key={index} className={`flex flex-col gap-y-1`}>
            <div className={`text-sm ${message.isUser ? 'text-right' : 'text-left'} text-gray-500`}>
                {message.isUser ? 'You' : 'D.A.V.I.D'}
            </div>
            <div className={`flex flex-row ${message.isUser ? 'justify-end' : 'justify-start'}`}>
                <div
                    className={`p-2 rounded-md ${message.isUser ? 'bg-blue-600 text-white border-gray-300 shadow' : 'bg-gray-200 text-gray-900 border border-gray-300 shadow'}`}
                    dangerouslySetInnerHTML={{ __html: parsedContent }}
                ></div>
            </div>
        </div>
    );
};