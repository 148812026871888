import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, SparklesIcon } from '@heroicons/react/24/solid';
import React, { useMemo } from 'react';
import { useHistory } from '../../providers/HistoryProvider';
import { preprocessText } from '../../utils/TextUtils';
import ReactQuill from 'react-quill';

const RecordsRecapSummary: React.FC = (props) => {
    const { activePreHistory } = useHistory()
    const summary = useMemo(() => {
        if (!activePreHistory) return "";

        const summaryKeys = ["Summary", "summary", "**Summary**", "**summary**"];
        
        // Find the first matching key in activePreHistory
        const matchedKey = summaryKeys.find(key => key in activePreHistory);
        if (!matchedKey) return "";
        
        // Explicitly get the summary using the matched key
        const summaryText = activePreHistory[matchedKey];
        return preprocessText(summaryText).replace(/^<br\/>|<br\/>$/g, "").trim();
    }, [activePreHistory])

    return (<>
        {summary && <Disclosure defaultOpen>
            {({ open }) => (
                <div className="w-full rounded-md bg-gradient-to-r from-[#cce0ff] to-[#d9fff6] p-4 shadow-[0_6px_8px_rgba(0,0,0,0.2)]">
                    <Disclosure.Button className="flex w-full items-center justify-between rounded-md bg-white px-4 py-2 text-left text-sm font-semibold text-blue-800 hover:bg-gray-50 transition-all duration-300 ease-out shadow-sm">
                        <div className="flex flex-row gap-x-2 items-center">
                            <SparklesIcon className="h-5 w-5 text-blue-500 animate-pulse" aria-hidden="true" />
                            Records Recap Summary
                        </div>
                        <ChevronUpIcon
                            className={`${open ? 'rotate-180' : ''} h-5 w-5 text-blue-500 transition-transform duration-300`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="mt-2 rounded-md bg-white/70 text-sm text-gray-800 backdrop-blur shadow-inner transition-all duration-300 ease-in-out font-medium">
                        <ReactQuill
                            theme='snow'
                            placeholder='Mr.Wiggles is looking mighty well today...'
                            className='block w-full h-full rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar bg-white/60 backdrop-blur'
                            value={summary}
                            modules={{ toolbar: false }}
                            readOnly
                        />
                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>}
    </>);
};

export default RecordsRecapSummary;