import { Fragment, useEffect, useState, createRef, useRef, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { UserCircleIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useHistory } from '../../providers/HistoryProvider'
import UserPicker from '../UserPicker'
import { MemberObject } from '../../utils/MemberUtils'
import { useUser } from '@clerk/clerk-react'
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker'
import { Spinner } from '../../utils/Spinner'
import { useVetRec } from '../../providers/VetRecProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaw } from '@fortawesome/free-solid-svg-icons'

export default function CreateVisitModal() {

    const {user} = useUser()
    const {createVisitModal, setCreateVisitModal, handleCreatePatient, membersList} = useHistory()
    const { multipetEnabled } = useVetRec()
    const [inputValue, setInputValue] = useState<string>('')
    const [staff, setStaff] = useState<MemberObject | undefined>({
        name: (user?.firstName && user?.lastName) ? user.firstName + " " + user.lastName : undefined,
        identifier: user?.primaryEmailAddress?.emailAddress ?? ""
    })
    const [createDate, setCreateDate] = useState<DateValueType>({
        startDate: new Date(),
        endDate: new Date()
    }); 
    const [creating, setCreating] = useState<boolean>(false)
    const [time, setTime] = useState<string>(new Date().toTimeString().slice(0, 5))
    const [missingFields, setMissingFields] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    // Multi-pet state
    const [multipet, setMultipet] = useState<boolean>(false)
    const [petNames, setPetNames] = useState<string[]>([])
    const [isAddingNewPet, setIsAddingNewPet] = useState<boolean>(false)
    const inputRefs = useMemo(() => 
        Array(10).fill(null).map(() => createRef<HTMLInputElement>()), 
        []
    )
    const singleInputRef = useRef<HTMLInputElement>(null)

    // Function to check if a name is duplicated
    const isDuplicateName = (name: string, index: number): boolean => {
        return petNames.some((petName, i) => i !== index && petName.toLowerCase().trim() === name.toLowerCase().trim() && name.trim() !== '')
    }

    // Function to get input validation class
    const getValidationClass = (petName: string, index: number): string => {
        if (petName.trim() === '') return 'bg-red-50 ring-2 ring-red-500'
        if (isDuplicateName(petName, index)) return 'bg-red-50 ring-2 ring-red-500'
        return 'bg-blue-50 hover:bg-blue-100'
    }

    // Function to get input text validation class
    const getInputValidationClass = (petName: string, index: number): string => {
        const baseClass = "bg-transparent border-none focus:ring-0 p-0 w-[1ch] min-w-[8ch] max-w-[20ch]"
        if (petName.trim() === '' || isDuplicateName(petName, index)) {
            return `${baseClass} text-red-700 placeholder-red-500`
        }
        return `${baseClass} text-gray-900`
    }

    // Function to get delete button class
    const getDeleteButtonClass = (petName: string, index: number): string => {
        if (petName.trim() === '' || isDuplicateName(petName, index)) {
            return "ml-1 text-red-500 hover:text-red-700"
        }
        return "ml-1 text-blue-400 hover:text-blue-600"
    }

    // Parse multiple pet names from a single input
    const parsePetNames = (input: string): string[] => {
        if (!multipetEnabled) {
            return [input]
        }
        // Process for "and" (case insensitive)
        const lowerInput = input.toLowerCase()
        if (lowerInput.includes(' and ')) {
            // Use the original string's position to split to maintain original casing
            const andIndex = lowerInput.indexOf(' and ')
            const firstPart = input.slice(0, andIndex)
            const secondPart = input.slice(andIndex + 5) // 5 is length of " and "
            return [firstPart.trim(), secondPart.trim()]
        }
        return [input]
    }

    // Handle name input changes with automatic multi-pet detection
    const handleNameChange = (value: string) => {
        const detectedNames = parsePetNames(value)
        
        if (detectedNames.length > 1) {
            setIsAddingNewPet(true)
            setPetNames(detectedNames)
            setMultipet(true)
            setInputValue("")
        } else {
            setInputValue(value)
            setPetNames([])
            setMultipet(false)
        }
    }

    // Update a pet name in the array
    const updatePetName = (index: number, value: string) => {
        // Check for " and " (case insensitive)
        const lowerValue = value.toLowerCase()
        if (lowerValue.endsWith(' and ')) {
            // Remove the separator and any trailing spaces
            const cleanValue = value.slice(0, -4).trim() // Remove " and"
            
            // Update the current pet name
            const newPetNames = [...petNames]
            newPetNames[index] = cleanValue
            
            // Add a new empty pet name if we haven't reached the limit and current name is valid
            if (petNames.length < 10 && !isDuplicateName(cleanValue, index) && cleanValue.trim() !== '') {
                setIsAddingNewPet(true)
                newPetNames.push('')
                setPetNames(newPetNames)
            } else {
                setPetNames(newPetNames)
            }
        } else {
            const newPetNames = [...petNames]
            newPetNames[index] = value
            setPetNames(newPetNames)
        }
    }

    // Handle keydown events for pet name inputs
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && e.currentTarget.value === '' && petNames[index] === '') {
            e.preventDefault()
            const newPetNames = [...petNames]
            
            if (index > 0) {
                newPetNames.splice(index, 1)
                
                if (newPetNames.length <= 1) {
                    const finalName = newPetNames[0] ?? ""
                    setInputValue(finalName)
                    setPetNames([])
                    setMultipet(false)
                    
                    // Delay the focus until after state updates
                    setTimeout(() => {
                        if (singleInputRef.current) {
                            singleInputRef.current.focus()
                            const length = finalName.length
                            singleInputRef.current.setSelectionRange(length, length)
                        }
                    }, 0)
                } else {
                    setPetNames(newPetNames)
                    
                    // Focus on the previous input
                    const previousInput = inputRefs[index - 1]?.current
                    if (previousInput) {
                        previousInput.focus()
                        const length = previousInput.value.length
                        previousInput.setSelectionRange(length, length)
                    }
                }
            }
        }
    }

    // Called when the user clicks the delete icon on a pet tag
    const handleDeleteTag = (index: number) => {
        const newPetNames = petNames.filter((_, i) => i !== index)
        if (newPetNames.length <= 1) {
            setInputValue(newPetNames[0] ?? "")
            setPetNames([])
            setMultipet(false)
        } else {
            setPetNames(newPetNames)
        }
    }

    // Add a new pet to the list
    const addNewPet = () => {
        setIsAddingNewPet(true)
        setPetNames([...petNames, ""])
        setMultipet(true)
    }

    // Effect to focus on the last input when adding a new pet
    useEffect(() => {
        if (petNames && petNames.length > 1) {
            setMultipet(true)
            // Focus on the last input only when adding a new pet
            if (isAddingNewPet) {
                setTimeout(() => {
                    const lastRef = inputRefs.find(ref => ref.current?.value === '')?.current ?? inputRefs[petNames.length - 1]?.current
                    if (lastRef) {
                        lastRef.focus()
                    }
                }, 0)
                setIsAddingNewPet(false)
            }
        } else {
            setMultipet(false)
        }
    }, [petNames, isAddingNewPet, inputRefs])

    // Function to validate all pet names
    const validatePetNames = (): boolean => {
        // Check if there are any valid pet names after trimming
        const validPetNames = petNames.filter(name => name.trim() !== '')
        if (validPetNames.length === 0) {
            setMissingFields(true)
            setErrorMessage("Please enter at least one patient name")
            return false
        }
        
        // Check for duplicate names
        const hasDuplicates = petNames.some((name, index) => isDuplicateName(name, index))
        if (hasDuplicates) {
            setMissingFields(true)
            setErrorMessage("Please enter unique names for each patient")
            return false
        }
        
        return true
    }

    const createVisit = async () => {
        // Reset validation state
        setMissingFields(false)
        setErrorMessage("")
        
        // Check if we have valid input
        if (multipet) {
            // For multi-pet mode, validate pet names
            if (!validatePetNames()) {
                return
            }
            
            // Check for empty required fields
            if (!staff || !createDate || !time) {
                setMissingFields(true)
                setErrorMessage("Please fill out all required fields")
                return
            }
        } else {
            // For single-pet mode
            // Check if the name is empty after trimming
            if(!inputValue || inputValue.trim() === '') {
                setMissingFields(true)
                setErrorMessage("Please enter a patient name")
                return
            }
            
            if(!staff || !createDate || !time) {
                setMissingFields(true)
                setErrorMessage("Please fill out all required fields")
                return
            }
        }
        
        setMissingFields(false)
        setErrorMessage("")
        let date = new Date()
        if(createDate?.startDate){
            date = createDate.startDate
        }
        date.setHours(parseInt(time.split(':')[0]), parseInt(time.split(':')[1]), 0, 0)
        let date_in_float = Math.floor(date.getTime() / 1000)
        setCreating(true)
        
        // Use the appropriate pet names based on mode and filter out any empty names
        const petsToUse = multipet 
            ? petNames.filter(name => name.trim() !== '') 
            : [inputValue.trim()]
        
        // Format the display name
        let displayName = ""
        if (multipet) {
            // Filter out empty names and trim each name
            const validPetNames = petNames.filter(name => name.trim() !== '').map(name => name.trim())
            
            if (validPetNames.length === 1) {
                displayName = validPetNames[0]
            } else if (validPetNames.length === 2) {
                displayName = `${validPetNames[0]} and ${validPetNames[1]}`
            } else if (validPetNames.length > 2) {
                const allButLast = validPetNames.slice(0, -1).join(', ')
                const last = validPetNames[validPetNames.length - 1]
                displayName = `${allButLast}, and ${last}`
            }
        } else {
            displayName = inputValue.trim()
        }
        
        await handleCreatePatient(displayName, petsToUse, staff?.identifier, date_in_float, false)
        setCreating(false)
        setInputValue('')
        setPetNames([])
        setMultipet(false)
        setCreateVisitModal(false)
    }

    useEffect(() => {
        if(createVisitModal){
            setInputValue('')
            setPetNames([])
            setMultipet(false)
            setStaff({
                name: (user?.firstName && user?.lastName) ? user.firstName + " " + user.lastName : undefined,
                identifier: user?.primaryEmailAddress?.emailAddress ?? ""
            })
            setCreateDate({
                startDate: new Date(),
                endDate: new Date()
            })
            setTime(new Date().toTimeString().slice(0, 5))
            setMissingFields(false)
            setErrorMessage("")
        }
    }, [createVisitModal])

    return (
        <Transition.Root show={createVisitModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={setCreateVisitModal}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div className='px-8'>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <UserCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Create a visit
                                </Dialog.Title>
                                <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Add a visit to VetRec. Assign to a staff member and a date.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex flex-col gap-y-4 justify-center items-center">
                            <div className='flex flex-row gap-x-2 items-center'>
                                <span className="w-16 text-right">{multipet ? "Patients:" : "Patient:"}</span>
                                {!multipet ? (
                                    <div className="w-60 relative">
                                        <input
                                            type="text"
                                            value={inputValue}
                                            onChange={(e) => handleNameChange(e.target.value)}
                                            className={`border ${inputValue.trim() === '' && missingFields ? 'border-red-500 bg-red-50' : 'border-gray-300'} ${multipetEnabled ? 'rounded-lg pr-10' : 'rounded-lg'} text-gray-900 text-sm w-full p-2.5 focus:ring-blue-500 focus:border-blue-500`}
                                            placeholder="Name"
                                            ref={singleInputRef}
                                        />
                                        {multipetEnabled && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setIsAddingNewPet(true);
                                                    setMultipet(true);
                                                    setPetNames([inputValue, ""]);
                                                    setInputValue("");
                                                }}
                                                className="absolute right-0 top-0 h-full inline-flex items-center justify-center px-3 rounded-r-lg bg-accent-button text-sm font-medium text-accent-button-text hover:bg-accent-button-hover transition-colors border-l border-gray-300"
                                            >
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className="flex items-center">
                                                        <FontAwesomeIcon icon={faPaw} className="h-4 w-4" />
                                                        <span className="font-bold ml-0.5">+</span>
                                                    </div>
                                                </div>
                                            </button>
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex flex-col w-60">
                                        <div className="flex items-center w-full min-h-[2.5rem] border border-gray-300 rounded-lg bg-white p-2.5">
                                            <div className="flex flex-wrap gap-2 w-full justify-start">
                                                {petNames.map((petName, index) => (
                                                    <div
                                                        key={index}
                                                        className={`inline-flex items-center ${getValidationClass(petName, index)} rounded-md px-3 py-1 text-sm font-medium transition-colors`}
                                                    >
                                                        <input
                                                            type="text"
                                                            value={petName}
                                                            onChange={(e) => updatePetName(index, e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                                            className={`${getInputValidationClass(petName, index)} max-w-[120px] truncate text-sm`}
                                                            placeholder={`Patient ${index + 1}`}
                                                            ref={inputRefs[index]}
                                                        />
                                                        <button
                                                            onClick={() => handleDeleteTag(index)}
                                                            className={getDeleteButtonClass(petName, index)}
                                                        >
                                                            <XMarkIcon className="h-4 w-4" />
                                                        </button>
                                                    </div>
                                                ))}
                                                {petNames.length < 10 && (
                                                    <button
                                                        onClick={addNewPet}
                                                        className="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100 transition-colors"
                                                    >
                                                        <PlusCircleIcon className="h-4 w-4 mr-1" />
                                                        Add
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-end mt-1">
                                            <button
                                                onClick={() => {
                                                    setMultipet(false);
                                                    // Keep the first pet's name when switching back
                                                    const firstPetName = petNames[0] || "";
                                                    setInputValue(firstPetName);
                                                    setPetNames([]);
                                                }}
                                                className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100 transition-colors"
                                            >
                                                <FontAwesomeIcon icon={faPaw} className="h-3 w-3 mr-1" />
                                                Switch to Single Patient
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-row gap-x-2 items-center'>
                                <span className="w-16 text-right">Date:</span>
                                <div className='w-60 z-[9999]'>
                                    <Datepicker 
                                        inputClassName={`h-10 rounded-lg w-60 text-sm border-gray-300 border-1 placeholder:text-gray-400 text-left`}
                                        toggleClassName={"hidden"}
                                        asSingle={true} 
                                        useRange={false} 
                                        value={createDate} 
                                        onChange={async (value) => {
                                            setCreateDate(value)
                                        }} 
                                        popoverDirection="down"
                                        displayFormat={"MM/DD/YYYY"}  
                                        placeholder="All Dates"
                                        disabled={creating}
                                    /> 
                                </div>
                            </div>
                            <div className='flex flex-row gap-x-2 items-center'>
                                <span className="w-16 text-right">Time:</span>
                                <div className='w-60'>
                                    <input type="time" id="time" className="border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" min="09:00" max="18:00" value={time} onChange={(event) => {setTime(event.target.value)}} disabled={creating}/>
                                </div>
                            </div>
                            <div className='flex flex-row gap-x-2 items-center'>
                                <span className="w-16 text-right">Staff:</span>
                                <div className='w-60'>
                                    <UserPicker 
                                        userList={membersList} 
                                        userSearch={staff} 
                                        setUserSearch={(value) => setStaff(value)} 
                                        className="pr-6 h-10 w-60" // Ensure the width is the same as the input fields
                                        disabled={creating}
                                    />
                                </div>
                            </div>
                            <div className='mt-4 w-full text-center flex flex-col gap-y-2'>
                                {missingFields && <div className='text-red-500 text-sm'>
                                    {errorMessage}
                                </div>}
                                <button
                                    type="button"
                                    className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 overflow-hidden w-full justify-center h-10 sm:h-12"
                                    onClick={async () => createVisit()}
                                    disabled={creating}
                                >
                                    {creating ? <Spinner size='h-5 w-5' timer={false}/>  : "Create visit"}
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}