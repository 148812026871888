import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloudArrowUpIcon, InformationCircleIcon, LinkIcon } from '@heroicons/react/24/solid'
import { useHistory } from '../../providers/HistoryProvider'
import { transferToOnPremPims } from '../../ServerActions'
import { useAuth } from '@clerk/clerk-react'
import { Spinner } from '../../utils/Spinner'
import { getPimsDisplayName } from '../../utils/PimsUtils'
interface OnPremPimsSyncModalProps {
    pimsDisplayName: string
    onPremPimsSyncModalOpen: boolean
    handleExport: (open: boolean) => void
}

export default function OnPremPimsSyncModal(props: OnPremPimsSyncModalProps) {
    const { onPremPimsSyncModalOpen, handleExport, pimsDisplayName } = props

    const { getToken } = useAuth();
    const { 
        activeSession, 
        sessionWithPetArraySelectedMedicalNoteId, 
        multiPetSessionActive, 
        sessionWithPetArraySelectedName 
    } = useHistory()
    
    const [exporting, setExporting] = useState<boolean>(false)
    const [exported, setExported] = useState<boolean>(false)
    const [animalId, setAnimalId] = useState<string>("")
    const [staffId, setStaffId] = useState<string>("")
    const [clientId, setClientId] = useState<string>("")
    const [missing, setMissing] = useState<string | undefined>()
    const [shortDescription, setShortDescription] = useState<string>("")

    const canExport = !!animalId && !!staffId && !!clientId;

    useEffect(() => {
        if (activeSession && activeSession.on_prem_integration_metadata) {
            setAnimalId(activeSession.on_prem_integration_metadata.animal_id ?? "")
            const providerValue = activeSession.on_prem_integration_metadata.provider_id;
            const staffId = extractIdValue(providerValue ?? "");
            if (staffId) {
                setStaffId(staffId);
            }
            const contactValue = activeSession.on_prem_integration_metadata.contact_id;
            const clientId = extractIdValue(contactValue ?? "");
            if (clientId) {
                setClientId(clientId);
            }
        } 
    }, [activeSession])

    const extractIdValue = (providerValue: string) => {
        const match = providerValue.match(/(?:resources\/staff_|contacts\/)(.+)/);
        if (match && match[1]) {
            return match[1];
        }
        return "";
    }

    async function handleOnPremSend() {
        try {
            if (!activeSession?.id) {
                setMissing("Session ID is missing")
                return
            }
            if (!animalId) {
                setMissing("Animal ID is required")
                return
            }
            if (!staffId) {
                setMissing("Staff ID is required")
                return
            }
            if (!clientId) {
                setMissing("Client ID is required")
                return
            }

            setExporting(true)
            const description = shortDescription || `VetRec - ${activeSession.name}`
            
            await transferToOnPremPims(
                activeSession.id,
                animalId,
                await getToken({ template: "supabase" }) ?? "",
                description,
                sessionWithPetArraySelectedMedicalNoteId,
                staffId,
                clientId,
            )
            setExporting(false)
            setExported(true)
        }
        catch (error) {
            setExporting(false)
            console.error(error)
            setMissing(`Error transferring notes to ${pimsDisplayName}`)
        }
    }

    function handleClose() {
        handleExport(false)
    }

    function afterLeave() {
        setExported(false)
        setShortDescription("")
        setMissing(undefined)
    }

    return (
        <Transition.Root show={onPremPimsSyncModalOpen} as={Fragment} afterLeave={afterLeave}>
            <Dialog as="div" className="relative z-[999999]" onClose={() => handleExport(false)} >  
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                {!exported && <div>
                                    <div>
                                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                            <CloudArrowUpIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Export to {pimsDisplayName}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Notes will be exported as a clinical note in {pimsDisplayName}.
                                                </p>
                                                {multiPetSessionActive && sessionWithPetArraySelectedName && (
                                                    <p className="mt-2 text-sm bg-blue-50 rounded-md p-2 text-blue-700">
                                                        Exporting notes for: <span className="font-medium">{sessionWithPetArraySelectedName}</span>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {missing && <div className="mt-2 bg-red-100 rounded-md p-4 text-sm text-red-900 text-center flex flex-row gap-x-3 justify-center items-center mx-auto">
                                        <InformationCircleIcon className="h-5 w-5 text-red-600" />
                                        {missing}
                                    </div>}

                                    <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center relative w-full" title='Provide an animal ID to write notes to'>
                                        <label
                                            htmlFor="Patient ID"
                                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                                        >
                                            Patient ID:
                                        </label>
                                        <input className="rounded-md h-10 w-full border border-gray-300 px-2" onChange={(event) => {
                                            setMissing(undefined)
                                            setAnimalId(event.target.value)
                                        }} title='Patient ID' placeholder={`Patient ID from ${pimsDisplayName}`} disabled={exporting} value={animalId} />
                                    </div>

                                    <div className="mt-3 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center relative w-full" title='Provide a staff ID to write notes to'>
                                        <label
                                            htmlFor="Staff ID"
                                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                                        >
                                            Staff ID:
                                        </label>
                                        <input className="rounded-md h-10 w-full border border-gray-300 px-2" onChange={(event) => {
                                            setMissing(undefined)
                                            setStaffId(event.target.value)
                                        }} title='Staff ID' placeholder='Staff ID without resources/staff_ prefix' disabled={exporting} value={staffId} />
                                    </div>

                                    <div className="mt-3 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center relative w-full" title='Provide a Client ID to write notes to'>
                                        <label
                                            htmlFor="Client ID"
                                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                                        >
                                            Client ID (Owner) :
                                        </label>
                                        <input className="rounded-md h-10 w-full border border-gray-300 px-2" 
                                            onChange={(event) => setClientId(event.target.value)}
                                            title='Client ID'
                                            placeholder={`Client ID from ${pimsDisplayName}`}
                                            disabled={exporting}
                                            value={clientId} />
                                    </div>
                                    <div className="mt-3 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center relative w-full" title='Provide a short description for the note'>
                                        <label
                                            htmlFor="Short Description"
                                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                                        >
                                            Short Description (optional):
                                        </label>
                                        <input className="rounded-md h-10 w-full border border-gray-300 px-2" 
                                            onChange={(event) => setShortDescription(event.target.value)}
                                            title='Short Description'
                                            placeholder={`VetRec - ${activeSession?.name || "Visit"}`} 
                                            disabled={exporting}
                                            value={shortDescription} />
                                    </div>

                                    {exporting && <div className='mt-5 w-full flex bg-blue-200 rounded-md p-2 shadow text-sm gap-x-2'>
                                        <InformationCircleIcon className='h-6 w-6 text-blue-600' />
                                        Once exported, refresh {pimsDisplayName} to see the notes.
                                    </div>}

                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 items-center mt-4 ${
                                            exporting || !canExport ? 'cursor-not-allowed bg-gray-600' : 'cursor-pointer bg-blue-600 hover:bg-blue-500'
                                        }`}
                                        onClick={() => handleOnPremSend()}
                                        disabled={exporting || !canExport}
                                    >
                                        {exporting ? <Spinner size='h-5 w-5' timer={false} /> : "Export"}
                                    </button>
                                </div>}
                                {exported && <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CloudArrowUpIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Notes exported to {pimsDisplayName}
                                        </Dialog.Title>
                                        <div className="mt-2 text-sm text-gray-900 bg-blue-100 p-2 rounded-md">
                                            <span className="font-semibold">Note:</span> Refresh {pimsDisplayName} to see the notes.
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 items-center mt-4 cursor-pointer bg-blue-600 hover:bg-blue-500`}
                                        onClick={() => handleClose()}
                                    >
                                        Close
                                    </button>
                                </div>}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
} 