import React, { useEffect } from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import {
  ClockIcon,
  PencilSquareIcon,
  NewspaperIcon,
  Cog6ToothIcon,
  XMarkIcon,
  Bars3Icon,
  RocketLaunchIcon,
  BoltIcon,
} from '@heroicons/react/24/outline'
import config from './config.json'
import Scribe from './Scribe';
import Settings from './Settings';
import History from './History';
import Templates from './Templates';
import Home from './Home';
import { useAuth, useUser, useOrganizationList, useOrganization } from "@clerk/clerk-react"
import { BuildingLibraryIcon, ChartBarIcon, ChevronUpIcon, DevicePhoneMobileIcon, GiftIcon, LifebuoyIcon, LinkIcon } from '@heroicons/react/24/solid';
import Integrations from './Integrations';
import TemplateBuilder from './TemplateBuilder';
import { IntercomProvider } from 'react-use-intercom';
import PayWall from './components/Paywall';
import logo from './assets/Logo.svg'
import { RecordingProvider } from './providers/RecordingProvider';
import { HistoryProvider } from './providers/HistoryProvider';
import RecordsRecapPage from './RecordsRecapPage';
import { RecordsRecapProvider } from './providers/RecordsRecapProvider';
import Analytics from './Analytics';
import { AnalyticsProvider } from './providers/AnalyticsProvider';
import { useVetRec } from './providers/VetRecProvider';
import { TeamPicker } from './components/teams/TeamPicker';
import { CreateOrganizationModal } from './components/settings/CreateOrganizationModal';
import InvitationModal from './components/settings/InvitationModal';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

// Define the types for your props
interface DashboardProps {
  capability: string
}

function Offer() {
  const [offer] = useState<boolean>(Math.random() < 0.5)
  return (<>
    {offer ? <a className='py-2 rounded-md items-center flex flex-row text-white font-semibold gap-x-3 bg-[#0066ff] text-center ring ring-[#56ffd6]  text-sm hover:ring-4 transition-all duration-300 hover:shadow-lg hover:shadow-[#56ffd6]/50 justify-center' href='https://help.vetrec.io/en/collections/10367770-downloads' target='_blank' rel="noreferrer">
      <DevicePhoneMobileIcon className='h-6 w-6' />
      Download Mobile Apps
    </a> :
      <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=3X0tE5ck0UW5oxKvkzeRVw7YFQP8NXpLlBwTZHDmQEZUQzFaUkFaTUdGOFExTFhINzlNR0kzNzU1RC4u' className='py-2 rounded-md items-center flex flex-row text-white font-semibold gap-x-3 bg-[#0066ff] text-center ring ring-[#56ffd6]  text-sm hover:ring-4 transition-all duration-300 hover:shadow-lg hover:shadow-[#56ffd6]/50 justify-center' target='_blank' rel="noreferrer">
        <GiftIcon className='h-6 w-6' />
        Refer and Earn $$
      </a>}
  </>)
}

export default function Dashboard({capability}:DashboardProps) {
  const { signOut, orgId } = useAuth();
  const { user } = useUser()
  const { organizationAdmin, multiTeam, trialExpired, suspend, setTrialExpired, superOrgOwner, adminCenterEnabled, isImpersonation} = useVetRec()
  const { organization } = useOrganization()
  const { setActive } = useOrganizationList()

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
  const [createTeamModalVisible, setCreateTeamModalVisible] = useState<boolean>(false)
  const [isTall, setIsTall] = useState(window.innerHeight > 800);
  const [invitationDismissed, setInvitationDismissed] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => setIsTall(window.innerHeight > 800);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigation = [
    { name: 'Scribe', href: '/scribe', icon: PencilSquareIcon, current: capability === "Scribe", openInNewTab: false },
    { name: "Records Recap", href: "/prehistory", icon: BoltIcon, current: capability === "PreHistory", openInNewTab: false },
    { name: 'Active Visits', href: '/active', icon: RocketLaunchIcon, current: capability === "Active", openInNewTab: false },
    { name: 'All Visits', href: '/history', icon: ClockIcon, current: capability === "History", openInNewTab: false },
    { name: 'Templates', href: '/templates', icon: NewspaperIcon, current: capability === "Templates", openInNewTab: false },
    ...(organizationAdmin ? [{ name: 'Analytics', href: '/analytics', icon: ChartBarIcon, current: capability === "Analytics", openInNewTab: false }] : [])
  ]

  const configurations = [
    { name: 'Integrations', href: '/integrations', icon: LinkIcon, current: capability === "Integrations", openInNewTab: false },
    { name: 'Help Center', href: 'https://help.vetrec.io/', icon: LifebuoyIcon, current: capability === "Help Center", openInNewTab: true },
    { name: 'Settings', href: '/settings', icon: Cog6ToothIcon, current: capability === "Settings", openInNewTab: false },
    ...((superOrgOwner && adminCenterEnabled) || (organizationAdmin && isImpersonation) ? [{ name: 'Admin Center', href: '/admin', icon: BuildingLibraryIcon, current: capability === "Admin", openInNewTab: false }] : [])
  ]

  useEffect(() => {
    if (!orgId) {
      if (setActive && user?.organizationMemberships && user.organizationMemberships.length > 0 && capability != "Settings") {
        setActive({ organization: user?.organizationMemberships[0].organization })
      }
    }
  }, [])

  return (
    <>
      <CreateOrganizationModal create={createTeamModalVisible} setCreate={setCreateTeamModalVisible} />
      <div className='overflow-hidden'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative lg:hidden z-[9999]" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-main-text" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className={`flex grow flex-col gap-y-5 h-screen overflow-y-auto thin-scrollbar bg-main px-6 pt-2 pb-2`}>
                    <div className="text-2xl text-main-text font-bold flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Company logo"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col justify-between">
                        <li className="flex flex-1 flex-col gap-y-7">
                          <div>
                            <div className="-mx-2 space-y-1">
                              {navigation.map((item) => (
                                <div key={item.name}>
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      item.current
                                        ? `bg-main-darker text-main-text`
                                        : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                                      'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current ? `text-main-text` : `text-accent-text group-hover:text-main-text`,
                                        'h-6 w-6 shrink-0'
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                    {item.name === "Analytics" && <div className='text-white rounded-lg px-2 py-0.5 bg-blue-900 text-xs'>
                                      Beta
                                    </div>}
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div>
                            <Disclosure defaultOpen={isTall}>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button className="flex w-full justify-between text-left text-sm font-medium text-white items-center hover:bg-gray-100/30 rounded-md -mx-2 space-y-1 px-2 py-2">
                                    <span>Configurations</span>
                                    <ChevronUpIcon
                                      className={`${open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-white font-semibold`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                                    <li>
                                      <ul className="-mx-2 space-y-1 mt-1">
                                        {configurations.map((config_item) => (
                                          <li key={config_item.name}>
                                            <a
                                              href={config_item.href}
                                              target={config_item.openInNewTab ? '_blank' : ''}
                                              className={classNames(
                                                config_item.current
                                                  ? `bg-main-darker text-main-text`
                                                  : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                                                'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                              )} rel="noreferrer"
                                            >
                                              <config_item.icon
                                                className={classNames(
                                                  config_item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                                                  'h-6 w-6 shrink-0'
                                                )}
                                                aria-hidden="true"
                                              />
                                              <span className="truncate">{config_item.name}</span>
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          </div>
                          <Offer />
                        </li>
                        <li className="w-full">
                          <div className="relative w-full">
                            <div className="flex flex-col gap-y-4 items-center gap-x-4 py-3 text-sm font-semibold leading-6 w-full">
                              {organization && <div className="rounded-md flex items-center pt-2 w-full justify-center overflow-visible relative">
                                <TeamPicker onCreateTeamClicked={() => setCreateTeamModalVisible(true)} />
                              </div>}
                              <button onClick={() => signOut()} className='flex bg-accent-button text-accent-button-text border-accent-button border rounded-md text-md p-1 hover:bg-accent-button-hover w-full items-center justify-center h-12 font-semibold'>
                                Sign out
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col h-screen overflow-y-hidden">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className={`flex grow flex-col gap-y-5 overflow-y-auto thin-scrollbar bg-main px-6`}>
            <div className="flex h-16 shrink-0 items-center pt-8">
              <img
                className="h-8 w-auto"
                src={logo}
                alt="Company logo"
              />
            </div>
            <nav className="flex flex-1 flex-col mt-4">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? `bg-main-darker text-main-text`
                              : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                            'group flex gap-x-3 items-center justify-start rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                          {item.name === "Analytics" && <div className='text-white rounded-lg px-2 py-0.5 bg-blue-900 text-xs'>
                            Beta
                          </div>}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <div>
                  <Disclosure defaultOpen={isTall}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full justify-between text-left text-sm font-medium text-white items-center hover:bg-gray-100/30 rounded-md -mx-2 space-y-1 px-2 py-2">
                          <span>Configurations</span>
                          <ChevronUpIcon
                            className={`${open ? 'rotate-180 transform' : ''
                              } h-5 w-5 text-white font-semibold`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                          <li>
                            <ul className="-mx-2 space-y-1 mt-1">
                              {configurations.map((config_item) => (
                                <li key={config_item.name}>
                                  <a
                                    href={config_item.href}
                                    target={config_item.openInNewTab ? '_blank' : ''}
                                    className={classNames(
                                      config_item.current
                                        ? `bg-main-darker text-main-text`
                                        : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                                      'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )} rel="noreferrer"
                                  >
                                    <config_item.icon
                                      className={classNames(
                                        config_item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                                        'h-6 w-6 shrink-0'
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="truncate">{config_item.name}</span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
                <Offer />
                <li className="mt-auto w-full">
                  <div className="relative w-full">
                    <div className="flex flex-col items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-main-text w-full gap-y-4 justify-center">
                      <div className='flex flex-row items-center justify-center w-full gap-x-4'>
                        <img
                          className={`h-8 w-8 rounded-full bg-main-darker`}
                          src={user?.imageUrl}
                          alt=""
                        />
                        <span className="hidden lg:flex lg:items-center text-main-text font-semibold">
                          <span aria-hidden="true">{user?.fullName}</span>
                        </span>
                      </div>
                      {organization && <div className="rounded-md flex items-center pt-2 w-full justify-center overflow-visible relative">
                        <TeamPicker onCreateTeamClicked={() => setCreateTeamModalVisible(true)} />
                      </div>}
                      <button onClick={() => signOut()} className='shadow-md flex justify-center items-center text-accent-text bg-accent-button rounded-md text-sm p-1 hover:bg-accent-button-hover font-semibold w-full h-12'>
                        Sign out
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className={`sticky top-0 z-40 flex items-center gap-x-6 bg-main px-4 py-4 shadow-sm sm:px-6 lg:hidden`}>
          <button type="button" className={`-m-2.5 p-2.5 text-accent-text lg:hidden`} onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-main-text">{config.BRANDED ? config.NAME : "VetRec"}</div>
          <div className='flex flex-row items-center gap-x-4'>
            <span className="lg:flex lg:items-center text-main-text text-sm">
              <span aria-hidden="true"><span className='font-semibold'>{user?.firstName}</span></span>
            </span>
            <img
              className={`h-8 w-8 rounded-full bg-main-darker`}
              src={user?.imageUrl}
              alt=""
            />
          </div>
        </div>
        <main className="lg:pl-64 overflow-hidden">
          <div className="px-4 sm:px-4 lg:px-8 overflow-hidden">{
            <IntercomProvider appId='okpouicg' autoBoot>
              {["Active", "Scribe", "PreHistory", "Templates", "TemplateBuilder", "Analytics"].includes(capability) && <PayWall />}
              {!invitationDismissed && <InvitationModal onDismiss={() => setInvitationDismissed(true)}/>}
              {capability == "Active" && <RecordingProvider>
                <Home />
              </RecordingProvider>}
              {capability === "Scribe" && <RecordingProvider>
                <Scribe />
              </RecordingProvider>}
              {capability === "History" && <HistoryProvider>
                <History />
              </HistoryProvider>}
              {capability === "PreHistory" && <RecordsRecapProvider>
                <RecordsRecapPage />
              </RecordsRecapProvider>}
              {capability === "Templates" && <Templates />}
              {capability === "TemplateBuilder" && <TemplateBuilder />}
              {capability === "Integrations" && <Integrations />}
              {capability === "Analytics" && <AnalyticsProvider>
                <Analytics />
              </AnalyticsProvider>}
              {capability === "Settings" && <Settings />}
            </IntercomProvider>
          }</div>
        </main>
      </div>
    </>
  )
}
