import { useAuth, useOrganization, useOrganizationList, useUser } from '@clerk/clerk-react';
import { useIntercom } from 'react-use-intercom';
import React, { createContext, useContext, useState, ReactNode, useEffect, useMemo } from 'react';
import { updateUserMetadata } from '../methods/clerk';
import { fetchSuperOrgMetadata} from '../ServerActions';
import { TemplatePermissions, VetspireTypes } from '../components/templates/TemplateUtils';
import { isAdminRole, metadataStringToUserRoleList, UserRole } from '../components/settings/UserRoles';
import { useLocation } from 'react-router-dom';
import { getUserFlag } from '../utils/MemberUtils';
import { fetchTeamMemberPublicMetadata, fetchUsagePerUser, TeamMemberPublicMetadata } from '../serverActions/user';
import { UserOrganizationInvitationResource } from "@clerk/types";

interface VetRecContextProps {
    organizationAdmin: boolean;
    isImpersonation: boolean;
    prebuiltEnabled: boolean
    createEnabled: boolean
    createTemplateEnabledRoles: UserRole[]
    editEnabled: boolean
    editTemplateEnabledRoles: UserRole[]
    shareEnabled: boolean
    recordAsEnabled: boolean
    inOrganization: boolean
    copyBehavior: string
    richtextFontSize: number | undefined
    richtextRemoveSpacesBetweenSections: boolean
    preBuiltControlledByOrg: boolean
    pims: string
    multiTeam: boolean
    emailAddress: string
    useMetagraphEnabled: boolean
    chooseTemplateUsingActAs: boolean
    superOrgOwner: boolean
    superOrg: string
    superOrgMetadata: Record<string, any> | undefined
    defaultTemplate: string
    defaultDischargeTemplate: string
    defaultRecordsRecapTemplate: string
    trialExpired?: boolean
    suspend: boolean
    createMultipleTeamsEnabled: boolean
    syncVetspireEnabled: boolean
    defaultVetspireBehavior: VetspireTypes
    createEncounterEnabled: boolean
    disableExportConfiguration: boolean
    superOrgTemplatePermissions: TemplatePermissions[]
    davidEnabled: boolean
    language: string
    multipetEnabled: boolean
    syncOnPremPimsEnabled: boolean
    adminCenterEnabled: boolean
    invitations?: UserOrganizationInvitationResource[]
    acceptInvitation: (invitation: UserOrganizationInvitationResource) => Promise<void>
    setDefaultTemplate: (templateId: string) => void
    setDefaultDischargeTemplate: (templateId: string) => void
    setDefaultRecordsRecapTemplate: (templateId: string) => void
    getTeamMemberPublicMetadata: (userId: string) => Promise<TeamMemberPublicMetadata>
    completeTour: (tourId: string) => Promise<void>;
    checkTourStatus: (tourId: string) => boolean;
    setTrialExpired: (value:boolean) => void
    updatePreBuiltEnabled: (value:boolean) => void
}

const VetRecContext = createContext<VetRecContextProps | undefined>(undefined);

export const VetRecProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { orgId, userId, getToken, actor } = useAuth();
    const { user } = useUser()
    const {organization, membership} = useOrganization()
    const { boot, show } = useIntercom();
    const location = useLocation();
    const { setActive } = useOrganizationList()
    
    const [organizationAdmin, setOrganizationAdmin] = useState<boolean>(false)
    const [prebuiltEnabled, setPrebuiltEnabled] = useState<boolean>(false)
    const shareEnabled = useMemo<boolean>(() => ((user?.publicMetadata['shareEnabled'] as boolean ?? true)),[user])
    const recordAsEnabled = useMemo<boolean>(() => ((organization?.publicMetadata['recordAsEnabled'] as boolean ?? true)),[orgId])
    const inOrganization = useMemo<boolean>(() => (organization !== undefined && organization !== null),[orgId])
    const copyBehavior = useMemo<string>(() => (user?.publicMetadata?.copyBehavior as string ?? organization?.publicMetadata?.copyBehavior as string ?? "") , [user, orgId]);
    const richtextFontSize = useMemo<number  | undefined>(() => (user?.publicMetadata?.richtextFontSize as number ?? organization?.publicMetadata?.richtextFontSize as number) , [userId, orgId]);
    const richtextRemoveSpacesBetweenSections = useMemo<boolean>(() => (user?.publicMetadata?.richtextRemoveSpacesBetweenSections as boolean ?? organization?.publicMetadata?.richtextRemoveSpacesBetweenSections as boolean ?? false) , [userId, orgId]);
    const preBuiltControlledByOrg = useMemo<boolean>(() => organization?.publicMetadata?.prebuiltEnabled === false ? true : false, [orgId]);
    const chooseTemplateUsingActAs = useMemo<boolean>(() => (user?.publicMetadata["set_template_using_act_as"] as boolean ?? organization?.publicMetadata['set_template_using_act_as'] as boolean ?? true), [userId, orgId])
    const createMultipleTeamsEnabled = useMemo<boolean>(() => ((user?.publicMetadata['createMultipleTeamsEnabled'] as boolean ?? false)), [userId])
    const [teamMemberPublicMetadataCache, setTeamMemberPublicMetadataCache] = useState<Record<string, TeamMemberPublicMetadata>>({});
    const pims = useMemo<string>(() => (user?.publicMetadata?.pims as string ?? organization?.publicMetadata?.pims as string ?? ""), [user, orgId]);
    const isImpersonation = useMemo<boolean>(() => !!actor, [actor]);
    const multiTeam = useMemo<boolean>(() => (user?.organizationMemberships.length ?? 0) > 1, [user]);
    const superOrg = useMemo<string>(() => (organization?.publicMetadata?.super_org as string ?? user?.publicMetadata?.super_org as string ?? ""), [organization, user]); // check if org is part of super org. Org can only belong to one super org
    const language = useMemo<string>(() => (user?.publicMetadata?.language as string ?? "English (US)"), [user]);
    const superOrgOwner = useMemo<boolean>(() => {
        const superOrgOwnerArray = user?.publicMetadata?.super_org_owner as string[] | undefined;
        return !!superOrg && Array.isArray(superOrgOwnerArray) && superOrgOwnerArray.includes(superOrg);
    }, [superOrg, user]);

    const [superOrgMetadata, setSuperOrgMetadata] = useState<Record<string, any> | undefined>(undefined)
    useEffect(() => {
        async function loadSuperOrgMetadata() {
            if (superOrgMetadata === undefined || superOrg !== superOrgMetadata["id"]) {
                const superOrgMetadata = await fetchSuperOrgMetadata(await getToken({template:"supabase"}) ?? "")
                setSuperOrgMetadata(superOrgMetadata)
            }
        }

        if (superOrg) {
            const pathsToLoadSuperOrgMetadata = ["/scribe", "/history", "/templates", "/settings", "/templateBuilder", "/admin"]
                if (pathsToLoadSuperOrgMetadata.some(path => location.pathname.includes(path))) {
                loadSuperOrgMetadata()
            }    
        } else {
            setSuperOrgMetadata(undefined)
        }
    }, [superOrg, location.pathname])

    const [defaultTemplate, setDefaultTemplate] = useState<string>('dynamic_soap_advanced');
    const [defaultDischargeTemplate, setDefaultDischargeTemplate] = useState<string>('dynamic_simple_discharge');
    const [defaultRecordsRecapTemplate, setDefaultRecordsRecapTemplate] = useState<string>('dynamic_records_recap_basic');
    const [trialExpired, setTrialExpired] = useState<boolean | undefined>(undefined)
    const [suspend, setSuspend] = useState<boolean>(false)
    const emailAddress = useMemo<string>(() => user?.primaryEmailAddress?.emailAddress ?? "", [userId]);

    const [createTemplateEnabledRoles, setCreateTemplateEnabledRoles] = useState<UserRole[]>([])
    const [createEnabled, setCreateEnabled] = useState<boolean>(true)

    const [editTemplateEnabledRoles, setEditTemplateEnabledRoles] = useState<UserRole[]>([])
    const [editEnabled, setEditEnabled] = useState<boolean>(true)

    const useMetagraphEnabled = useMemo<boolean>(() => getUserFlag(user, "use_metagraph", true), [userId]);

    const [invitations, setInvitations] = useState<UserOrganizationInvitationResource[]>();

    useEffect(() => {
        const fetchInvitations = async () => {
          const result = await user?.getOrganizationInvitations({
              pageSize: 100,
          });
          setInvitations(result?.data.filter((invitation) => invitation.status === "pending"));
        };
      
        if (user) {
          fetchInvitations();
        }
      }, [user]);

    useEffect(() => {
        if (orgId) {
            // Setting create permissions
            const createTemplateEnabledRolesStr = organization?.publicMetadata['createTemplateEnabledRoles'] as string ?? undefined
            let createTemplateEnabledRolesList: UserRole[]
            if (createTemplateEnabledRolesStr) {
                createTemplateEnabledRolesList = metadataStringToUserRoleList(createTemplateEnabledRolesStr)
            } else {
                // Backwards compatibility for legacy createEnabled
                const legacyCreateEnabled = organization?.publicMetadata['createEnabled'] as boolean ?? true
                createTemplateEnabledRolesList = legacyCreateEnabled ? Object.values(UserRole) : [UserRole.ADMIN]
            }
    
            setCreateTemplateEnabledRoles(createTemplateEnabledRolesList)
            
            const createTemplateEnabledRolesListStrings = createTemplateEnabledRolesList.map(role => role.toString());
            setCreateEnabled(membership ? createTemplateEnabledRolesListStrings.includes(membership?.role) : false)    

            // Setting edit permissions
            const editTemplateEnabledRolesStr = organization?.publicMetadata['editTemplateEnabledRoles'] as string ?? undefined
            let editTemplateEnabledRolesList: UserRole[]
            if (editTemplateEnabledRolesStr) {
                editTemplateEnabledRolesList = metadataStringToUserRoleList(editTemplateEnabledRolesStr)
            } else {
                // Backwards compatibility for legacy editEnabled
                const legacyEditEnabled = organization?.publicMetadata['editEnabled'] as boolean ?? true
                editTemplateEnabledRolesList = legacyEditEnabled ? Object.values(UserRole) : [UserRole.ADMIN]
            }
    
            setEditTemplateEnabledRoles(editTemplateEnabledRolesList)
            
            const editTemplateEnabledRolesListStrings = editTemplateEnabledRolesList.map(role => role.toString());
            setEditEnabled(membership ? editTemplateEnabledRolesListStrings.includes(membership?.role) : false)    
        }
    }, [orgId])

    // Vetspire options
    const syncVetspireEnabled = useMemo(() => {
        return (user?.publicMetadata['syncVetspireEnabled'] as boolean ?? true)  || (organization?.publicMetadata['syncVetspireEnabled'] as boolean ?? true) // Default to true if not set
    }, [user, orgId])
    const defaultVetspireBehavior = useMemo<VetspireTypes>(() => organization?.publicMetadata['defaultVetspireBehavior'] as VetspireTypes ?? VetspireTypes.ENCOUNTER , [orgId]);
    const createEncounterEnabled = useMemo<boolean>(() => (organization?.publicMetadata['createEncounterEnabled'] as boolean ?? true) , [orgId]);
    const disableExportConfiguration = useMemo<boolean>(() => (organization?.publicMetadata['disableExportConfiguration'] as boolean ?? false) , [orgId]);
    const superOrgTemplatePermissions = useMemo(() => {
        if (superOrgMetadata) {
            const permissionsObject = superOrgMetadata?.superOrgTemplatePermissions ?? {"default": ["all"]};
            let permission = (permissionsObject['default'] as string[] ?? ["all"])
            let mapped_permissions: TemplatePermissions[] = permission.map((perm) => perm as TemplatePermissions)
            if (mapped_permissions.includes(TemplatePermissions.ALL)) {
                return Object.values(TemplatePermissions);
            }
            else{
                return mapped_permissions
            }
        } else {
            return Object.values(TemplatePermissions);
        }
    }, [superOrgMetadata]);
    const davidEnabled = useMemo<boolean>(() => {
        return superOrgMetadata?.davidEnabled ?? organization?.publicMetadata['davidEnabled'] as boolean ?? user?.publicMetadata['davidEnabled'] ?? true
    }, [superOrgMetadata, organization, user]);

    const multipetEnabled = useMemo<boolean>(() => {
        return superOrgMetadata?.multipetEnabled ?? organization?.publicMetadata['multipetEnabled'] as boolean ?? user?.publicMetadata['multipetEnabled'] ?? true
    }, [superOrgMetadata, organization, user]);

    const adminCenterEnabled = useMemo<boolean>(() => {
        return organization?.publicMetadata['adminCenterEnabled'] as boolean ?? user?.publicMetadata['adminCenterEnabled'] ?? false
    }, [organization, user]);

    const updatePreBuiltEnabled = async (value:boolean) => {
        await updateUserMetadata({prebuiltEnabled: value}, "updated_prebuilt_templates", await getToken({template:"supabase"}) ?? "")
        setPrebuiltEnabled(value)
    }

    //Paywall
    useEffect(() => {
        // Only run this effect if `user` is loaded
        if (!user) return;
        const getTierAndUsage = async ():Promise<void> => {
          if ((user.publicMetadata.hasOwnProperty("tier")) || organization?.publicMetadata.hasOwnProperty("tier") || isImpersonation){
            setTrialExpired(false)
            setSuspend(false)
          }
          else{
            // Don't have tier which means they are free users, then check sessions
            let num_sessions = (await fetchUsagePerUser("Web", await getToken({template:"supabase"}) ?? "")).num_sessions
            if(num_sessions >= 15 && num_sessions <= 20){
              //They have no more sessions left.
              setTrialExpired(true)
            }
            else if(num_sessions >= 20){
              setTrialExpired(true)
              setSuspend(true)
            }
            else{
              //They have session left
              setTrialExpired(false)
              setSuspend(false)
            }
          }
        }
        getTierAndUsage()
      }, [userId, trialExpired, suspend, isImpersonation])

    const clerkDashboardBase = "https://dashboard.clerk.com/apps/app_2bYh4dzXnNFg9nW8YIaFjaChFry/instances/ins_2bbIn2frNSfjP73AUmLqOiX2ROI"

    function reorderDoctorTitle(name: string | undefined): string {
        if (name === undefined) return "";

        const doctorPrefixes = ["Dr ", "Dr. "];
        for (const prefix of doctorPrefixes) {
            if (name.startsWith(prefix)) {
                return `${name.replace(prefix, "").trim()} (${prefix.trim()})`;
            }
        }
        return name;
    }

    // Pass user/org info to Intercom
    const initializeIntercom = () => {
        try {
            if (userId) {
                boot({
                    userId: userId ?? undefined,
                    email: user?.primaryEmailAddress?.emailAddress,
                    name: reorderDoctorTitle(user?.fullName ?? undefined), // Otherwise intercom will show the user as just "Dr"
                    company: inOrganization ? {
                        companyId: orgId ?? "",
                        name: organization?.name,
                        plan: organization?.publicMetadata["tier"] as string,
                        customAttributes: {
                            "Org Clerk Link": `${clerkDashboardBase}/organizations/${orgId}`,
                        }
                    } : undefined,
                    customAttributes: {
                        "Clerk Link": `${clerkDashboardBase}/users/${userId}`,
                        "Subscription Tier": user?.publicMetadata["tier"] as string ?? organization?.publicMetadata["tier"] as string,
                        "Org Tier": user?.publicMetadata["org_tier"] as string ?? organization?.publicMetadata["org_tier"] as string,
                    }
                });
            }
        } catch (e) {
            // Silent fail
        }
    };

    const checkIfOpenSupport = () => {
        const params = new URLSearchParams(location.search);
        if(params.get('openSupport') === 'true')
            show();
    }

    async function acceptInvitation(invitation: UserOrganizationInvitationResource): Promise<void> {
        await invitation.accept()
        if(setActive){
            setActive({organization: invitation.publicOrganizationData.id})
        }
        user?.reload()
        organization?.reload()
    }

    useEffect(() => {
        initializeIntercom();
        checkIfOpenSupport();

    }, [userId, orgId]);

    async function checkIfAdmin(){
        if(orgId && membership){
            setOrganizationAdmin(false)
            if(isAdminRole(membership.role)){
                setOrganizationAdmin(true)
            }
        }
    }

    async function getTeamMemberPublicMetadata(userId: string): Promise<TeamMemberPublicMetadata> {
        const cache_key = `${orgId}_${userId}`
        const cachedMetadata = teamMemberPublicMetadataCache[cache_key]

        if (cachedMetadata) {
            return cachedMetadata
        } else {
            const token = await getToken({template:"supabase"}) ?? ""
            const publicMetadata = await fetchTeamMemberPublicMetadata(userId, token)
            
            setTeamMemberPublicMetadataCache(prevState => ({
                ...prevState,
                [cache_key]: publicMetadata, // Add or update the member
            }));    

            return publicMetadata
        }
    }

    function checkPrebuiltEnabled() {
        setPrebuiltEnabled((preBuiltControlledByOrg ? (organization?.publicMetadata['prebuiltEnabled'] as boolean) : (user?.publicMetadata['prebuiltEnabled'] as boolean)) ??  true);
    }

    useEffect(() => {
        checkIfAdmin();
        checkPrebuiltEnabled();
    }, [userId, orgId, preBuiltControlledByOrg]);

    // Tour
    const completeTour = async (tourId: string) => {
        await updateUserMetadata({[`onboarded_${tourId}`]: true}, "completed_tour", await getToken({template:"supabase"}) ?? "")
    };

    const checkTourStatus = (tourId: string) => {
        return user?.publicMetadata[`onboarded_${tourId}`] as boolean ?? false
    }

    useEffect(() => {
        if (user?.publicMetadata['default_template_id']) {
            setDefaultTemplate(user.publicMetadata['default_template_id'] as string);
        }
        if (user?.publicMetadata['default_discharge_template_id']) {
            setDefaultDischargeTemplate(user.publicMetadata['default_discharge_template_id'] as string);
        }
        if (user?.publicMetadata['default_recordsrecap_template_id']) {
            setDefaultRecordsRecapTemplate(user.publicMetadata['default_recordsrecap_template_id'] as string);
        }
    }, [user])

    const syncOnPremPimsEnabled = useMemo(() => {
        return (user?.publicMetadata['syncOnPremPimsEnabled'] as boolean ?? false) || (organization?.publicMetadata['syncOnPremPimsEnabled'] as boolean ?? false)
    }, [user, orgId])

    return (
        <VetRecContext.Provider value={{
            pims,
            organizationAdmin,
            isImpersonation,
            createEnabled,
            createTemplateEnabledRoles,
            editEnabled, 
            editTemplateEnabledRoles,
            prebuiltEnabled,
            updatePreBuiltEnabled,
            inOrganization,
            recordAsEnabled,
            copyBehavior,
            richtextFontSize,
            richtextRemoveSpacesBetweenSections,
            preBuiltControlledByOrg,
            chooseTemplateUsingActAs,
            getTeamMemberPublicMetadata,
            checkTourStatus,
            completeTour,
            setDefaultDischargeTemplate,
            setDefaultRecordsRecapTemplate, 
            setDefaultTemplate,
            shareEnabled,
            multiTeam,
            superOrg,
            superOrgOwner,
            superOrgMetadata,
            defaultDischargeTemplate,
            defaultRecordsRecapTemplate,
            defaultTemplate,
            emailAddress,
            useMetagraphEnabled,
            createMultipleTeamsEnabled,
            trialExpired,
            suspend,
            setTrialExpired,
            syncVetspireEnabled,
            createEncounterEnabled,
            defaultVetspireBehavior,
            disableExportConfiguration,
            superOrgTemplatePermissions,
            davidEnabled,
            language,
            multipetEnabled,
            syncOnPremPimsEnabled,
            adminCenterEnabled,
            invitations,
            acceptInvitation
        }}>
            {children}
        </VetRecContext.Provider>
    );
};

export const useVetRec = () => {
    const context = useContext(VetRecContext);
    if (context === undefined) {
        throw new Error('useVetRec must be used within a VetRecProvider');
    }
    return context;
};
