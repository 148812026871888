import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/24/solid'
import { useHistory } from '../../providers/HistoryProvider'
import FileDropZone from './FileDropZone'
import va from "@vercel/analytics";
import { useSupabase } from '../../supabase/SupabaseContext'
import { uploadFilesAndStartRecap } from '../../utils/PreHistoryUtils'
import { useAuth } from '@clerk/clerk-react'
import { WarningBanner } from '../common/WarningBanner'
import { Spinner } from '../../utils/Spinner'
import { TemplateObject } from '../../utils/TemplateObject'
import TemplatePicker from '../templates/TemplatePicker'
import { TemplateType } from '../templates/TemplateUtils'

export default function RegenerateRecapModal() {

    const { regenerateRecapModal, regenRecapShouldUploadFilesBeOpen, setRegenerateRecapModal, activeSession, refreshActiveSession } = useHistory()
    const { uploadToSupabaseSignedURL } = useSupabase();
    const { getToken } = useAuth();

    const [additionalInstructions, setAdditionalInstructions] = useState<string>()
    const [files, setFiles] = useState<File[]>([])
    const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState<string | undefined>(undefined);
    const [isUploadSectionOpen, setIsUploadSectionOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [template, setTemplate] = useState<TemplateObject>()

    useEffect(() => {
        setIsUploadSectionOpen(regenRecapShouldUploadFilesBeOpen)
    }, [regenerateRecapModal, regenRecapShouldUploadFilesBeOpen])

    const getLoggingProperties = () => {
        return {
            date: new Date().toUTCString(),
            sessionId: activeSession?.id ?? "undefined",
        };
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileUploadErrorMessage(undefined);
        const uploadedFiles = event.target.files;
        if (uploadedFiles) {
            let filesArray = Array.from(uploadedFiles);
            const combinedFiles = [...files, ...filesArray];
            const deduplicatedFiles = combinedFiles.reduce((acc, currentFile) => {
            if (!acc.some(file => file.name === currentFile.name)) {
                acc.push(currentFile);
            }
            return acc;
            }, [] as File[]);
            setFiles(deduplicatedFiles);
        }

        va.track("RecordsRecap_Regen_Upload", getLoggingProperties());
    };

    function onClose(): void {
        setRegenerateRecapModal(false)
        setAdditionalInstructions(undefined)
        setFiles([])
        setIsUploadSectionOpen(false)
        setIsLoading(false)
    }

    function validateFiles(): void {
        const empty_file = files.find(f => f.size === 0)

        if (empty_file) {
            setFileUploadErrorMessage(`The following file is empty: ${empty_file.name}. Please remove it and try again. If your PDF is compressed (e.g., zipped), please decompress (e.g., unzip) and try again.`)
            return
        }
    }
    
    async function handleRegenRecap(): Promise<void> {
        setIsLoading(true)
        validateFiles()

        if (!activeSession) {
            setFileUploadErrorMessage("There was an issue processing the records. Please try again.");
            return;
        }
        
        try {
            const token = await getToken({ template: "supabase" }) ?? "";

            await uploadFilesAndStartRecap(
                activeSession,
                files,
                uploadToSupabaseSignedURL,
                token,
                true, // isRegen
                false, // onlySummarizeLatest
                additionalInstructions,
                template
            );

            await refreshActiveSession()

            va.track("RecordsRecap_Regen_Success", getLoggingProperties());
        } catch (error) {
            console.error(error)
            va.track("RecordsRecap_Regen_Error", getLoggingProperties());
        } finally {
            onClose()
        }
    }

    return (
        <Transition.Root show={regenerateRecapModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={onClose}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <ArrowPathIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Regenerate Recap
                                </Dialog.Title>
                                <div className="mt-1">
                                    <p className="text-sm text-gray-500">
                                        Regenerate your recap: you may choose a different template, upload additional files or provide additional regeneration instructions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-x-2 gap-y-6 pt-6">
                            <div className="">
                                {!isUploadSectionOpen && <button
                                    onClick={() => setIsUploadSectionOpen(!isUploadSectionOpen)}
                                    className="text-sm flex justify-between items-center p-2 w-full bg-blue-100 hover:bg-blue-200 text-blue-900 rounded-md focus:outline-none"
                                >
                                    [Optional] Upload new files
                                    <PlusIcon className="w-5 h-5 text-blue-900" />
                                </button>}
                                {isUploadSectionOpen && (
                                    <div className="relative">
                                        {files.length === 0 && <label
                                            className={`absolute -top-2 left-2 px-1 text-xs font-medium text-main-text-darker z-[0] flex gap-x-2`}
                                        >
                                            Additional Files
                                        </label>}
                                        <FileDropZone
                                            files={files}
                                            onFileUpload={handleFileUpload}
                                            invalidFilesUploaded={fileUploadErrorMessage !== undefined}
                                            compact
                                        />
                                        {fileUploadErrorMessage && (
                                            <span className="text-xs text-red-600">
                                            {fileUploadErrorMessage}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div>
                                <TemplatePicker 
                                    template={template} 
                                    templateId_force={activeSession?.prehistory_metadata?.template_id}
                                    setTemplate={setTemplate} 
                                    className='h-12'
                                    pickerWidth='w-full' 
                                    templateBackground='bg-white' 
                                    type={TemplateType.RECORDSRECAP}/>
                            </div>
                            <div>
                                <div className="relative">
                                    <label
                                        htmlFor="additionalInstructions"
                                        className={`absolute -top-2 left-2 px-1 text-xs font-medium text-main-text-darker z-[0] bg-white flex gap-x-2`}
                                    >
                                        Additional Instructions <span className='bg-blue-600 text-white px-2 rounded-md'>Beta</span>
                                    </label>
                                    <textarea
                                        id="additionalInstructions"
                                        name="additionalInstructions"
                                        className='w-full border border-gray-300 rounded-md p-4 text-sm text-gray-600 z-[-1]'
                                        value={additionalInstructions}
                                        onChange={(event) => setAdditionalInstructions(event.target.value)}
                                        placeholder={"[Optional] Let VetRec know any additional instructions for Records Recap."}
                                    />
                                </div>
                            </div>
                            {files.length > 0 && <WarningBanner warningText="VetRec will summarize the newly uploaded records in addition to already uploaded records. To summarize from scratch, delete the existing recap first." />}
                            <div className="flex flex-col">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    onClick={() => handleRegenRecap()}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <><Spinner size="w-5 h-5" timer={false} /><span className="ml-2">Regenerating...</span></> : "Regenerate recap"}
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}