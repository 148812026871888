import React, { useState, createContext, useContext } from 'react';
import Joyride, { CallBackProps, EVENTS, STATUS } from 'react-joyride';
import ReactConfetti from 'react-confetti';
import { useVetRec } from './VetRecProvider';
import { useNavigate } from 'react-router-dom';
import va from '@vercel/analytics';
import { TourConfig, TourContextProps } from '../components/tours/types';
import { getTourConfig } from '../components/tours/registry';
import { TourId } from '../components/tours/constants';

const TourContext = createContext<TourContextProps | undefined>(undefined);

interface TourProviderProps {
  children: React.ReactNode;
}

export interface StartTourOptions {
  tourId: TourId;
  redirectPath?: string;
  onComplete?: () => void;
  onSkip?: () => void;
  stepHandler?: Record<number, (data: CallBackProps) => Promise<void>>;
}

export const TourProvider: React.FC<TourProviderProps> = ({ children }) => {
  const [activeTourConfig, setActiveTourConfig] = useState<TourConfig | null>(null);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [isTourHidden, setIsTourHidden] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [completedTours, setCompletedTours] = useState<Record<string, boolean>>({});
  
  const { completeTour, checkTourStatus, isImpersonation } = useVetRec();
  const navigate = useNavigate();

  const startTour = async ({ tourId, redirectPath, onComplete, onSkip, stepHandler: startTourStepHandler  }: StartTourOptions) => {
    const hasCompletedTour = completedTours[tourId] ?? checkTourStatus(tourId);
    
    if (isTourRunning || hasCompletedTour || isImpersonation) {
      return;
    }
    
    const config = getTourConfig(tourId);
    
    setActiveTourConfig({
      ...config,
      onTourComplete: onComplete,
      onTourSkip: onSkip,
      stepHandler: startTourStepHandler || config.stepHandler,
    });
    setIsTourRunning(true);
    setRedirectPath(redirectPath);
    setShowWelcomeModal(true);
  };

  const handleStartTour = () => {
    setShowWelcomeModal(false);
    if (redirectPath) {
      navigate(redirectPath);
    }

    if (activeTourConfig) {
      const properties = {
        tourId: activeTourConfig.tourId,
        date: new Date().toUTCString(),
        totalSteps: activeTourConfig.steps.length,
      };
      va.track(`Tour_${activeTourConfig.tourId}_Started`, properties);
    }
  };

  const handleJoyrideCallback = async (data: CallBackProps) => {
    if (!activeTourConfig) return;
    
    const { action, index, type, status } = data;

    if (type === EVENTS.STEP_AFTER) {
      try {
        await activeTourConfig.stepHandler?.[index]?.(data);
      } catch (error) {
        console.error('Error in step handler:', error);
      }
    }
    
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setIsTourRunning(false);
      
      const properties = {
        tourId: activeTourConfig.tourId,
        date: new Date().toUTCString(),
        lastStep: index,
        totalSteps: activeTourConfig.steps.length,
        status: status,
      };

      if (status === STATUS.FINISHED) {
        if (activeTourConfig.EndModal) {
          setShowEndModal(true);
        }
        if (activeTourConfig.showConfetti) {
          setShowConfetti(true);
        }
        va.track(`Tour_${activeTourConfig.tourId}_Completed`, properties); // TODO Fix this
        activeTourConfig.onTourComplete?.();
      } else if (status === STATUS.SKIPPED) {
        va.track(`Tour_${activeTourConfig.tourId}_Skipped_from_${index}/${activeTourConfig.steps.length}`, properties);
        activeTourConfig.onTourSkip?.();
      }

      completeTour(activeTourConfig.tourId);
      setCompletedTours(prev => ({
        ...prev,
        [activeTourConfig.tourId]: true
      }));
      
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const nextIndex = index + (action === 'prev' ? -1 : 1);
      setCurrentStep(nextIndex);
    }
  };

  const handleTourComplete = () => {
    setShowConfetti(false);
    setShowEndModal(false);
    setActiveTourConfig(null);
    setIsTourRunning(false);
  };

  const handleWelcomeSkip = async () => {
    if (!activeTourConfig) return;

    const properties = {
      tourId: activeTourConfig.tourId,
      date: new Date().toUTCString(),
      action: "skip_from_welcome",
    };
    va.track(`Tour_${activeTourConfig.tourId}_Skipped`, properties);
    
    await completeTour(activeTourConfig.tourId);
    activeTourConfig.onTourSkip?.();
    setActiveTourConfig(null);
    setIsTourRunning(false);
    setCompletedTours(prev => ({
      ...prev,
      [activeTourConfig.tourId]: true
    }));
  };

  return (
    <TourContext.Provider
      value={{
        tourId: activeTourConfig?.tourId as TourId,
        isTourRunning,
        startTour, 
        currentStep,
        steps: activeTourConfig?.steps ?? [],
        setCurrentStep,
        handleJoyrideCallback,
        setIsTourHidden,
      }}
    >
      {children}
      {showConfetti && activeTourConfig?.showConfetti && (
        <ReactConfetti style={{ zIndex: 999998 }} />
      )}
      {activeTourConfig?.WelcomeModal && (
        <activeTourConfig.WelcomeModal
          isOpen={showWelcomeModal && !isTourHidden}
          setIsOpen={setShowWelcomeModal}
          startTour={handleStartTour}
          onSkip={handleWelcomeSkip}
        />
      )}
      {activeTourConfig?.EndModal && (
        <activeTourConfig.EndModal 
          isOpen={showEndModal && !isTourHidden}
          setIsOpen={setShowEndModal}
          onComplete={handleTourComplete}
        />
      )}
      {activeTourConfig && (
        <Joyride
          debug={true}
          steps={activeTourConfig.steps}
          run={isTourRunning && !isTourHidden && !showWelcomeModal}
          stepIndex={currentStep}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          hideCloseButton={true}
          disableScrolling={true}
          scrollToFirstStep={false}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: '#0066ff',
              textColor: '#333',
              overlayColor: 'rgba(0, 0, 0, 0.35)',
            },
          }}
          locale={{
            last: 'End tour',
          }}
        />
      )}
    </TourContext.Provider>
  );
};

export const useTour = () => {
  const context = useContext(TourContext);
  if (context === undefined) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};
