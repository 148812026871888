import { useAuth } from "@clerk/clerk-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { filterNotMentioned, filterNotMentionedHTML, fixIndentation, revertPreprocessedText, revertPreprocessedTextNoStyling } from "../../utils/TextUtils";
import va from '@vercel/analytics';
import { ArrowDownCircleIcon, ChatBubbleBottomCenterTextIcon, CheckCircleIcon, ClipboardDocumentIcon, CloudIcon, DocumentTextIcon, HandThumbDownIcon, HandThumbUpIcon, LanguageIcon, MicrophoneIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { Spinner } from "../../utils/Spinner";
import MoreMenu from "../moreMenu";
import { Loading } from "../../utils/Loading";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles"
import { useHistory } from "../../providers/HistoryProvider";
import { downloadDischargePDF } from "../../ServerActions";
import TemplatePicker from "../templates/TemplatePicker";
import { TemplateType } from "../templates/TemplateUtils";
import { WarningBanner } from "../common/WarningBanner";
import { useVetRec } from "../../providers/VetRecProvider";
import { executeCopyToClipboard } from "../../utils/CopyUtils";
import { MultiPetTabs } from "./MultiPetTabs";
import { DeltaStatic, Sources } from "quill";
import QuillEditorWithToolbar from "./QuillEditorWithToolbar";

export const Discharge: React.FC = () => {
    const { getToken } = useAuth()
    const [savingDischarge, setSavingDischarge] = useState<boolean>(false)
    const [copySuccess, setCopySuccess] = useState<boolean>(false)
    const [dischargeChanged, setDischargedChanged] = useState<boolean>(false)
    const [didLastSaveFail, setDidLastSaveFail] = useState<boolean>(false)
    const {
        // Session state
        activeSession,
        activeSessionUpdated,
        
        // Discharge state
        activeDischarge,
        displayActiveDischarge,
        dischargeLoading,
        dischargeProcessing,
        dischargeStreaming,
        feedbackDischarge,
        templateDischarge,

        // Notes state
        activeNotes,
        notesLoading,
        notesProcessing,
        notesStreaming,
        template,

        // Transcript state
        transcriptProcessing,
        activeTranscriptExists,

        // Loading states
        loadingSessionInfo,

        // Multi-pet state
        multiPetSessionActive,
        sessionWithPetArraySelectedName,
        sessionWithPetArraySelectedDischargeNoteId,
        dischargeNotesEditCache,
        

        // Actions
        generateDischarge,
        handleAddToSession,
        handleDischargeTemplateChange,
        handleLanguageChange,
        handleMarkComplete,
        handleSelectedPetChange,
        handleSelectionChange,
        handleThumbsInput,
        processTranscript,
        uploadActiveDischarge,

        // State setters
        setDisplayActiveDischarge,
        setDischargeNotesEditCache,
        setError,
        clearNotification,
        notifyInProgress,
        notifyCompleted,
        setShowChatBot,
        setTemplate,
        setTemplateDischarge,

        // Utilities
        getLoggingProperties,
    } = useHistory()
    
    const { copyBehavior, pims, richtextRemoveSpacesBetweenSections, richtextFontSize, davidEnabled } = useVetRec()
    const previousVisit = useRef(activeSession);

    useEffect(() => {
        if(activeSession?.id !== previousVisit.current?.id)
        {
            setDischargedChanged(false)
        }
        previousVisit.current = activeSession
    }, [activeSession])

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (copySuccess) {
            timer = setTimeout(() => {
                setCopySuccess(false);
            }, 1000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [copySuccess]);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
    
        if (savingDischarge) {
            timer = setTimeout(() => {
                setSavingDischarge(false)
            }, 3000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
        };
    }, [savingDischarge]);

    const copyDischargeToClipboard = async () => {
        try {
            setCopySuccess(true)
            let text_temp_rich = filterNotMentionedHTML(fixIndentation(displayActiveDischarge, richtextRemoveSpacesBetweenSections)) // rich text
            let text_temp_plain
            
            if (pims === "ezyvet" || copyBehavior === "markdown") {
                text_temp_plain = filterNotMentioned(revertPreprocessedText(displayActiveDischarge)); // plain with markdown
            } else {
                text_temp_plain = filterNotMentioned(revertPreprocessedTextNoStyling(displayActiveDischarge)); // plain with no markdown
            }

            await executeCopyToClipboard(text_temp_plain, text_temp_rich, richtextFontSize);

            notifyCompleted("Notes copied to clipboard")
            va.track("HistoryPage_CopyDischarge", getLoggingProperties())
        } catch (err) {
            clearNotification()
            setError("There was an issue copying the communications document. Please try again.")
            va.track("HistoryPage_CopyDischarge_Failed", getLoggingProperties())
        }
    };
    
    const saveDischarge = async () => {
        if(activeSession){
            try {
                setDidLastSaveFail(false)
                setSavingDischarge(true)
                notifyInProgress("Saving discharge notes...")

                await uploadActiveDischarge(displayActiveDischarge)

                notifyCompleted("Saved discharge notes!")
                va.track("History_SaveDischarge", getLoggingProperties())  
            } catch (err) {
                clearNotification()
                setError("There was an issue saving the communications document. Please try again.")
                setDidLastSaveFail(true)
                va.track("History_SaveDischarge_Failed", getLoggingProperties())
            }
        }
    }


    const handleQuillChange = useCallback(
        (value: string, delta: DeltaStatic, source: Sources) => {
            if (sessionWithPetArraySelectedDischargeNoteId) {
                setDischargeNotesEditCache({
                    ...dischargeNotesEditCache,
                    [sessionWithPetArraySelectedDischargeNoteId]: value,
                });
            }
            setDisplayActiveDischarge(value)
            if (source === "user") {
                setDischargedChanged(true)
            }
        },
        [sessionWithPetArraySelectedDischargeNoteId, dischargeNotesEditCache]
      );

    
    async function handleDownloadPDF(): Promise<void> {
        try {
            notifyInProgress("Downloading PDF...")
            const correspondingNoteId = activeSession?.pet_to_discharge_node_mapping?.[sessionWithPetArraySelectedName ?? ""] // If it doesn't exist, will be undefined which is fine
            let signed_url = await downloadDischargePDF(activeSession?.id ?? "", await getToken({template:"supabase"}) ?? "", correspondingNoteId)
            if(signed_url){
                // Trigger the PDF download
                const link = document.createElement('a');
                link.href = signed_url['signed_url'];
                link.target = '_blank';
                link.setAttribute('download', `${activeSession?.name}_discharge.pdf`); // You can specify the file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                va.track("HistoryPage_DownloadDischargePDF", getLoggingProperties())
                notifyCompleted("PDF downloaded!")
            }
            else {
                clearNotification()
                setError("There was an issue downloading the PDF. Please try again.")
                va.track("HistoryPage_DownloadDischargePDF_FailedNoSignedUrl", getLoggingProperties())
            }    
        } catch (err) {
            clearNotification()
            setError("There was an issue downloading the PDF. Please try again.")
            va.track("HistoryPage_DownloadDischargePDF_Failed", getLoggingProperties())
        }
    }


    return(<>
        {activeSession && <div>
            {(dischargeLoading || loadingSessionInfo) && !activeDischarge && !transcriptProcessing && !notesProcessing && !dischargeProcessing && <Loading text={dischargeLoading ?? loadingSessionInfo ?? ""}/>}
            {( notesProcessing || notesStreaming || (dischargeProcessing && !activeDischarge) || transcriptProcessing) && !dischargeStreaming && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-6 mb-8 font-semibold'>
                {transcriptProcessing && <div>Transcribing recording 🤖....</div>}
                {!dischargeProcessing && !transcriptProcessing && <div>Generating notes 🤖....</div>}
                {dischargeProcessing && !transcriptProcessing && <div>Generating client communications 🤖....</div>}
                <Spinner size="w-12 h-12" timer={true}/>
                <div className="text-sm text-center text-gray-700 font-normal">Notes will be ready within 60s.<br/>Feel free to navigate away if you need to.</div>
            </div>}
            {!activeSessionUpdated && activeTranscriptExists && !activeNotes && !activeDischarge && !dischargeLoading && !dischargeProcessing && !dischargeStreaming && !loadingSessionInfo && !notesProcessing && !notesStreaming && !notesLoading && <div className="bg-blue-50 shadow-xl sm:rounded-lg p-4">
                <div className="px-4 py-5 sm:p-6 xl:flex xl:flex-row xl:justify-between xl:item-center">
                    <div>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Generate notes</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>Generate medical notes for the visit.</p>
                        </div>
                    </div>
                    <div className="xl:mt-0 mt-5 flex flex-col sm:flex-row md:items-center gap-x-4 gap-y-4">
                        <TemplatePicker template={template} templateId_force={activeSession.template_id} setTemplate={setTemplate} className="h-10" templateBackground='bg-transparent' type={TemplateType.MEDICAL}/>
                        <button
                            type="button"
                            className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center"
                            onClick={() => processTranscript(activeNotes ? true : false)}
                            disabled={!template}
                        >
                            {!template && <Spinner size="h-5 w-5" timer={false}/>}
                            {template && <div>Generate</div>}
                            {template && <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                        </button>
                    </div>
                </div>
            </div>}
            {!activeSessionUpdated && activeNotes && !activeDischarge && !dischargeLoading && !dischargeProcessing && !dischargeStreaming && !loadingSessionInfo && !notesProcessing && !notesStreaming && !notesLoading && <>
                <div className="bg-blue-50 sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6 xl:flex xl:flex-row xl:justify-between xl:item-center">
                        <div>
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Generate Client document</h3>
                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                <p>Generate client communication documents for the visit.</p>
                            </div>
                        </div>
                        <div className="xl:mt-0 mt-5 flex flex-col sm:flex-row md:items-center gap-x-4 gap-y-4">
                            <TemplatePicker 
                                template={templateDischarge} 
                                setTemplate={setTemplateDischarge} 
                                className="h-10" 
                                templateBackground='bg-[linear-gradient(to_bottom,transparent_50%,white_50%)]'
                                type={TemplateType.COMMUNICATIONS}
                            />
                            <button
                                type="button"
                                className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center"
                                onClick={() => generateDischarge(activeDischarge ? true : false)}
                            >
                                Generate
                                <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </>}
            {activeDischarge && !dischargeLoading && !notesProcessing && !notesStreaming && !notesLoading && <div className="flex flex-col gap-y-4 text-sm text-gray-500">
                <div  className="flex xl:flex-row flex-col gap-y-4 justify-end gap-x-4 xl:items-center items-end">
                    {didLastSaveFail && <WarningBanner warningText="There was an issue saving the communications document. Please try again." />}
                    {!didLastSaveFail && dischargeStreaming && <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                        <div className="flex grow items-center justify-center">
                            <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                                <div className="flex flex-row gap-x-4 items-center">
                                    <div>
                                        <DocumentTextIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                    </div>
                                    <div className="text-sm text-blue-900 font-semibold">We are finishing your client notes. Feel free to navigate away, your notes will be ready within 60s or less.</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="flex flex-row gap-x-2 w-full sm:w-auto justify-end">
                        <button
                            type="button"
                            title={activeSession && activeSession.status.general && activeSession.status.general === "Completed" ? "This session is completed" : "Mark this session as completed"}
                            className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-xs sm:text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 sm:min-w-fit ${activeSession && activeSession.status.general && activeSession.status.general === "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-blue-600 text-white hover:bg-main-button-hover'}`}
                            onClick={() => handleMarkComplete()}
                        >
                            <CheckCircleIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                            <span className="hidden sm:block">{activeSession && activeSession.status.general && activeSession.status.general === "Completed" ?  "Completed" : "Mark completed"}</span>
                        </button>
                        {davidEnabled && <div className="relative group">
                            <button
                                disabled={multiPetSessionActive}
                                data-tour-id="chat-button"
                                type="button"
                                className={`inline-flex items-center justify-center gap-x-2 rounded-md px-2 py-2.5 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 text-sm grow w-full sm:max-w-52 ${
                                    multiPetSessionActive 
                                    ? 'bg-main-button/50 text-main-button-text/50 cursor-not-allowed hover:bg-main-button/50' 
                                    : 'bg-main-button text-main-button-text hover:bg-main-button-hover'
                                }`}
                                onClick={() => setShowChatBot(true)}
                            >
                                <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-main-button-text" aria-hidden="true" />
                                <span className="hidden sm:block">D.A.V.I.D</span>
                            </button>
                            {multiPetSessionActive && (
                                <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 hidden group-hover:block w-max z-10">
                                    <div className="bg-gray-900 text-white text-xs rounded py-1 px-2">
                                        D.A.V.I.D for multi-pet sessions coming soon!
                                    </div>
                                    <div className="w-2 h-2 bg-gray-900 transform rotate-45 absolute left-1/2 -translate-x-1/2 -top-1"></div>
                                </div>
                            )}
                        </div>}
                        <button
                            type="button"
                            title="Record additional information for this session which will be added to the notes."
                            className="whitespace-nowrap inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52"
                            onClick={() => handleAddToSession()}
                        >
                            <MicrophoneIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                            <span className="hidden sm:block">Add to visit</span>
                        </button>
                        <div className="sm:hidden flex flex-row gap-x-2">
                            {!dischargeProcessing && <button
                                type="button"
                                title="Save the notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => saveDischarge()}
                            >
                                {!savingDischarge && (
                                    <div className="flex flex-col items-center">
                                        <CloudIcon className="h-4 w-4 sm:h-4 sm:w-4" aria-hidden="true" />
                                        <span className="text-[10px] font-bold leading-none">Save</span>
                                    </div>
                                )}
                                {savingDischarge && <Spinner size="h-5 w-5" timer={false}/>}
                            </button>}
                            <button
                                type="button"
                                title="Copy discharge notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => copyDischargeToClipboard()}
                            >
                                {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                                {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
                            </button>
                            {!feedbackDischarge && <button
                                type="button"
                                title="Provide positive feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(true, "Discharge")}
                            >
                                <HandThumbUpIcon className="h-5 w-5 text-white"/>
                            </button>}
                            {!feedbackDischarge && <button
                                type="button"
                                title="Provide negative feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(false, "Discharge")}
                            >
                                <HandThumbDownIcon className="h-5 w-5 text-white"/>
                            </button>}
                        </div>
                        <MoreMenu 
                            disabled={dischargeStreaming || dischargeProcessing}
                            moreText="Actions" 
                            options={[{
                                name:"Generate Document",
                                action: () => handleDischargeTemplateChange(true),
                                icon: <DocumentTextIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
                            },{
                                name:"Change Language",
                                action: () => handleLanguageChange(true),
                                icon: <LanguageIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
                            },
                            {
                                name:"Download PDF",
                                action: () => handleDownloadPDF(),
                                icon: <ArrowDownCircleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
                            }]} 
                            height="h-10 sm:h-12"/>
                    </div>
                </div>
                {multiPetSessionActive && activeSession && (
                    <MultiPetTabs
                        session={activeSession}
                        activePetName={sessionWithPetArraySelectedName ?? ""}
                        setActivePetName={handleSelectedPetChange}
                        notesNotReady={(dischargeProcessing || (transcriptProcessing && !activeDischarge))}
                    />
                )}
                <div className="relative h-full">
                    <div className="flex flex-col sm:flex-row w-full">
                        <div className="flex flex-col items-end justify-end w-full">
                            {dischargeStreaming && <div className="absolute top-2 sm:top-2 right-4 sm:right-4 flex flex-col gap-y-2">
                                <div
                                    className="flex flex-row relative items-center gap-x-2 rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold border border-gray-300 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 h-10 overflow-hidden justify-center z-[2] shadow"
                                >
                                    <Spinner size="h-5 w-5" timer={false}/>
                                    Writing notes... 
                                </div>
                            </div>}
                            <div className="relative w-full">
                                {/* {!dischargeStreaming && !dischargeProcessing && (
                                    <SaveButton
                                        isMinimized={saveButtonMinimized}
                                        setIsMinimized={setSaveButtonMinimized}
                                        isOriginalNotesView={false}
                                        savingNotes={savingDischarge}
                                        onSave={saveDischarge}
                                    />
                                )} */}
                                <QuillEditorWithToolbar
                                    theme='snow'
                                    placeholder='Mr.Wiggles is looking mighty well today...'
                                    className='block w-full lg:h-[68vh] h-[65vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                                    value={displayActiveDischarge}
                                    key={sessionWithPetArraySelectedDischargeNoteId}
                                    modules={{toolbar: false}}
                                    onChange={handleQuillChange}
                                    onChangeSelection={handleSelectionChange}
                                    onThumbsDownPressed={() => handleThumbsInput(false, "Discharge")}
                                    readOnly={dischargeStreaming}
                                />
                            </div>
                        </div>
                        {!dischargeStreaming && <div className="hidden sm:flex flex-col gap-y-2 pl-2 items-center">
                            {!dischargeProcessing && <button
                                type="button"
                                title="Save the notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => saveDischarge()}
                            >
                                {!savingDischarge && (
                                    <div className="flex flex-col items-center">
                                        <CloudIcon className="h-4 w-4 sm:h-4 sm:w-4" aria-hidden="true" />
                                        <span className="text-[10px] font-bold leading-none">Save</span>
                                    </div>
                                )}
                                {savingDischarge && <Spinner size="h-5 w-5" timer={false}/>}
                            </button>}
                            <button
                                type="button"
                                title="Copy discharge notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => copyDischargeToClipboard()}
                            >
                                {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                                {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
                            </button>
                            {!feedbackDischarge && <button
                                type="button"
                                title="Provide positive feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(true, "Discharge")}
                            >
                                <HandThumbUpIcon className="h-5 w-5 text-white hover:text-blue-400"/>
                            </button>}
                            {!feedbackDischarge && <button
                                type="button"
                                title="Provide negative feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(false, "Discharge")}
                            >
                                <HandThumbDownIcon className="h-5 w-5 text-white hover:text-blue-400"/>
                            </button>}
                        </div>}
                    </div>
                </div>
            </div>}
        </div>}
    </>)
}