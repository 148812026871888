import { ChevronRightIcon } from "@heroicons/react/24/solid"
import { convertTimestampToDate } from "../../utils/time"
import { useUser } from "@clerk/clerk-react"
import { SessionObject } from "../../utils/SessionUtils"
import { SessionAvatar } from './SessionAvatar'
import { MultiPetBadge } from "./MultiPetBadge"

interface SessionListItemProps {
    session: SessionObject
    activeSession: SessionObject | undefined
    sessionFromParam?: string
    scrollToItemRef: (node: HTMLLIElement | null) => void
    selectSession: (session: SessionObject) => void
    compressed?: boolean
}

export default function SessionListItem({ 
    session, 
    activeSession, 
    sessionFromParam, 
    scrollToItemRef, 
    selectSession,
    compressed: collapsible 
}: SessionListItemProps) {
    const { user } = useUser()
    const isActive = activeSession && session.id === activeSession.id
    const isDeleted = session.status.general === "Deleted"

    return (
        <li 
            ref={session.id === sessionFromParam ? scrollToItemRef : null} 
            key={session.id} 
            className={`flex items-center gap-x-6 ${collapsible ? "py-2" : "py-5"} ${isActive ? 'bg-selected rounded-md' : 'hover:bg-gray-300'} cursor-pointer hover:rounded-md border-b border-gray-200 pr-4`} 
            onClick={() => selectSession(session)}
        >
            <div className="flex gap-x-4 pl-4 justify-center items-center flex-1 min-w-0">
                <SessionAvatar 
                    session={session} 
                    userEmail={user?.primaryEmailAddress?.emailAddress}
                />
                <div className="self-center min-w-0 flex-1">
                    <div className="flex items-center min-w-0">
                        <div className="flex-1 min-w-0">
                            <p className={`text-sm ${activeSession && session.id === activeSession.id ? 'font-bold' : 'font-semibold'} leading-6 text-main-text-darker truncate ${isDeleted ? 'line-through' : ''}`}>
                                {session.name === "" ? "Pet" : session.name}
                            </p>
                        </div>
                        {session.pets && session.pets.length > 1 && <MultiPetBadge petCount={session.pets.length} />}
                    </div>
                    <p className="text-xs">{convertTimestampToDate(session.created_timestamp)}</p>
                </div>
            </div>
            {!collapsible && <div className="shrink-0">
                <ChevronRightIcon className="h-5 w-5"/>
            </div>}
        </li>
    )
}