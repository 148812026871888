import { TemplateType } from "../components/templates/TemplateUtils";
import type { TemplateObject } from "./TemplateObject";

export const preBuiltTemplate : TemplateObject[] = [{
    id: "dynamic_soap_notes",
    name: "SOAP Notes",
    owner: "VetRec",
    organization:"VetRec",
    created_timestamp: Date.now(),
    modified_timestamp: Date.now(),
    type: TemplateType.MEDICAL
},
{
    id: "dynamic_phone_summary",
    name: "Phone Call - Summary",
    owner: "VetRec",
    organization:"VetRec",
    created_timestamp: Date.now(),
    modified_timestamp: Date.now(),
    type: TemplateType.MEDICAL
},
{
    id: "dynamic_soap_advanced",
    name: "SOAP w/ Physical",
    owner: "VetRec",
    organization:"VetRec",
    created_timestamp: Date.now(),
    modified_timestamp: Date.now(),
    type: TemplateType.MEDICAL
}]

export const preBuildRecordsRecapTemplate : TemplateObject[] = [
    {
        id: "dynamic_records_recap_basic",
        name: "Records Summary",
        owner: "VetRec_RecordsRecap",
        organization:"VetRec_RecordsRecap",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now(),
        type: TemplateType.RECORDSRECAP
    },
    {
        id: "dynamic_records_recap_timeline",
        name: "Records Timeline",
        owner: "VetRec_RecordsRecap",
        organization:"VetRec_RecordsRecap",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now(),
        type: TemplateType.RECORDSRECAP
    }
]

export const preBuiltDischargeTemplate : TemplateObject[] = [{
    id: "dynamic_simple_discharge",
    name: "Discharge",
    owner: "VetRec_Comms",
    organization:"VetRec_Comms",
    created_timestamp: Date.now(),
    modified_timestamp: Date.now(),
    type: TemplateType.COMMUNICATIONS
},{
    id: "dynamic_email_communications",
    name: "Email Communications",
    owner: "VetRec_Comms",
    organization:"VetRec_Comms",
    created_timestamp: Date.now(),
    modified_timestamp: Date.now(),
    type: TemplateType.COMMUNICATIONS
},{
    id: "dynamic_rdvm_letter",
    name: "rDVM Letter",
    owner: "VetRec_Comms",
    organization:"VetRec_Comms",
    created_timestamp: Date.now(),
    modified_timestamp: Date.now(),
    type: TemplateType.COMMUNICATIONS
},
{
    id: "dynamic_kids_story",
    name: "Kids Story",
    owner: "VetRec_Comms",
    organization:"VetRec_Comms",
    created_timestamp: Date.now(),
    modified_timestamp: Date.now(),
    type: TemplateType.COMMUNICATIONS
}]

export const preBuiltTemplateSpanish : TemplateObject[] = [
    {
        id: "dynamic_soap_notes_spanish",
        name: "Notas SOAP",
        owner: "VetRec",
        organization:"VetRec",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now(),
        type: TemplateType.MEDICAL
    },
    {
        id: "dynamic_soap_advanced_spanish",
        name: "Notas SOAP con Examen Físico",
        owner: "VetRec",
        organization:"VetRec",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now(),
        type: TemplateType.MEDICAL
    },
]

export const allPrebuiltTemplateIds: string[] = [
  ...preBuiltTemplate.map(t => t.id),
  ...preBuildRecordsRecapTemplate.map(t => t.id),
  ...preBuiltDischargeTemplate.map(t => t.id),
  ...preBuiltTemplateSpanish.map(t => t.id)
];