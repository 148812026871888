import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Team } from '../types/user';

interface TeamSelectorProps {
  teams: Team[];
  selectedTeams: string[];
  onSelectTeam: (teamsToAdd:string[], teamsToRemove:string[]) => Promise<void>;
  className?: string;
  disabled?: boolean;
}

const TeamSelector: React.FC<TeamSelectorProps> = ({ teams, selectedTeams, onSelectTeam, className = '', disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [localSelectedTeams, setLocalSelectedTeams] = useState<string[]>(selectedTeams);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState<'bottom' | 'top'>('bottom');

  // Update local state when props change
  useEffect(() => {
    setLocalSelectedTeams(selectedTeams);
  }, [selectedTeams]);

  // Filter teams based on search term
  const filteredTeams = useMemo(() =>
    teams.filter((team) => team.name.toLowerCase().includes(searchTerm.toLowerCase()))
  , [teams, searchTerm]);

  // Toggle team selection
  const toggleTeamSelection = (teamId: string) => {
    let updatedSelectedTeams;
    if (localSelectedTeams.includes(teamId)) {
      updatedSelectedTeams = localSelectedTeams.filter(id => id !== teamId);
    } else {
      updatedSelectedTeams = [...localSelectedTeams, teamId];
    }
    
    setLocalSelectedTeams(updatedSelectedTeams);
  };

  // Save changes
  const saveChanges = async () => {
    // Calculate what teams were added and removed
    const teamsToRemove = selectedTeams.filter(id => !localSelectedTeams.includes(id));
    const teamsToAdd = localSelectedTeams.filter(id => !selectedTeams.includes(id));
    
    // Send all changes in a single operation
    await onSelectTeam(
      teamsToAdd,
      teamsToRemove
    );
    
    setIsOpen(false);
  };

  // Separate selected and unselected teams
  const selectedTeamObjects = useMemo(() => {
    return filteredTeams.filter(team => localSelectedTeams.includes(team.id))
  }, [filteredTeams, localSelectedTeams]);
  
  const unselectedTeamObjects = useMemo(() => {
    return filteredTeams.filter(team => !localSelectedTeams.includes(team.id))
  }, [filteredTeams, localSelectedTeams]);

  const hasChanges = useMemo(() => {
    return JSON.stringify(selectedTeams) !== JSON.stringify(localSelectedTeams);
  }, [selectedTeams, localSelectedTeams]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        // Reset local selection if closed without saving
        if (hasChanges) {
          setLocalSelectedTeams(selectedTeams);
        }
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hasChanges, selectedTeams]);

  // Function to determine dropdown position
  const toggleDropdown = () => {
    if (!isOpen) {
      // Check position before opening
      const buttonRect = dropdownRef.current?.getBoundingClientRect();
      if (buttonRect) {
        const spaceBelow = window.innerHeight - buttonRect.bottom;
        const menuHeight = Math.min(filteredTeams.length * 40 + 110, 350); // Height including search + items + save button
        setDropdownPosition(spaceBelow < menuHeight ? 'top' : 'bottom');
      }
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative inline-block text-left ${className}`} ref={dropdownRef} style={{ zIndex: isOpen ? 999 : 1 }}>
      <button
        type="button"
        className={`inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm ${disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-50'}`}
        onClick={toggleDropdown}
        disabled={disabled}
      >
        Teams
        <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>
      {isOpen && (
        <div 
          className={`${dropdownPosition === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'} absolute right-0 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
          style={{ zIndex: 1000 }}
        >
          <div className="px-4 py-3">
            <input
              type="text"
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Search teams..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          
          <div className="max-h-60  overflow-y-auto thin-scrollbar overflow-x-hidden" style={{ zIndex: 100 }}>
            <div className="py-1">
              <p className="px-4 py-2 text-xs font-semibold text-gray-500">SELECTED TEAMS</p>
              {selectedTeamObjects.length > 0 ? (
                selectedTeamObjects.map((team) => (
                  <button
                    key={team.id}
                    className="group flex items-center px-4 py-2 text-sm w-full text-left text-gray-900 hover:bg-gray-100"
                    onClick={() => toggleTeamSelection(team.id)}
                    title={team.name}
                  >
                    <svg className="mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                    <span className='whitespace-nowrap'>{team.name}</span>
                  </button>
                ))
              ) : (
                <div className="py-2 px-4 text-left w-full break-words whitespace-normal">
                  <p className="text-xs text-red-500 italic">Select at least one team</p>
                </div>
              )}
            </div>
            
            {unselectedTeamObjects.length > 0 && (
              <div className="py-1">
                <div className="py-1 border-t border-gray-100"></div>
                <p className="px-4 py-2 text-xs font-semibold text-gray-500">AVAILABLE TEAMS</p>
                {unselectedTeamObjects.map((team) => (
                  <button
                    key={team.id}
                    className="group flex items-center px-4 py-2 text-sm w-full text-left text-gray-700 hover:bg-gray-100"
                    onClick={() => toggleTeamSelection(team.id)}
                    title={team.name}
                  >
                    <span className="whitespace-nowrap">{team.name}</span>
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="py-2 px-4 border-t border-gray-100">
            <button
              type="button"
              className={`inline-flex justify-center w-full rounded-md border px-4 py-2 bg-blue-600 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${!hasChanges || selectedTeamObjects.length == 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={saveChanges}
              disabled={!hasChanges || selectedTeamObjects.length == 0}
            >
              Save Changes
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamSelector;
