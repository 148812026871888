import { FC } from "react";
import { EzyVetCategories, EzyVetVitalsCategories, SectionType } from "../templates/TemplateUtils";
import EnumPicker from "../EnumPicker";
import { useTemplate } from "../templates/TemplateProvider";

interface EzyVetConfigProps {
    items: SectionType[];
    open: (val: boolean) => void;
}

export const EzyVetConfig: FC<EzyVetConfigProps> = ({ items, open }) => {
    const { mapping, updateEzyVetSectionCategory, updateEzyVetVitalCategory, saveMapping } = useTemplate();

    async function handleTemplateConfig() {
        await saveMapping();
        open(false);
    }

    return (
        <div className="flex flex-col gap-y-4 mt-4 justify-between max-h-[60vh] thin-scrollbar overflow-y-auto pr-2">
            <div className="flex flex-col gap-y-4">
                <div>
                    <div className="w-full flex flex-row items-center justify-between border-b border-gray-300 pb-1">
                        <div>Section Name</div>
                        <div className="flex flex-row gap-x-4">
                            <div className="w-96">Category</div>
                        </div>
                    </div>
                </div>
                {mapping && items.map((item, item_index) => (<div key={item_index}>
                    <div key={item_index} className="rounded-md w-full flex flex-row items-center justify-between">
                        <div>{item.name?.includes("vetrec_empty") ? "[Empty Name]" : item.name}</div>
                        <div className="flex flex-row gap-x-4 relative w-96">
                            <EnumPicker 
                                enum={EzyVetCategories} 
                                selectedOption={mapping[item.name?.toLowerCase() ?? ""].category ?? EzyVetCategories.NO_MAPPING} 
                                setSelectedOption={(value) => {
                                    updateEzyVetSectionCategory(item.name?.toLowerCase() ?? "", EzyVetCategories[value.toUpperCase().replaceAll(" ", "_") as keyof typeof EzyVetCategories])
                                }} 
                                width="w-96"
                            />
                        </div>
                    </div>
                    {mapping[item.name?.toLowerCase() ?? ""].category === EzyVetCategories.VITALS && <div className="flex flex-col gap-y-4 my-4">
                        <div className="ml-8 mr-8">
                            <div className="w-full flex flex-row items-center border-b border-gray-300 pb-1 justify-between gap-x-8">
                                <div className="shrink-0">Entry Name</div>
                                <div className="flex flex-row w-96">
                                    <div className="">Map to</div>
                                </div>
                            </div>
                        </div>
                        {item.entries.map((entry, entry_idx) => (
                            <div key={entry_idx} className="ml-8 mr-8">
                                <div className="w-full flex flex-row items-center justify-between gap-x-8">
                                    <div className="shrink-0">{entry.name}</div>
                                    <div className="flex flex-row w-96">
                                        <EnumPicker 
                                            width="w-60"
                                            enum={EzyVetVitalsCategories} 
                                            selectedOption={mapping[item.name?.toLowerCase() ?? ""].inputs[entry.name?.toLowerCase() ?? ""]?.inputName ?? EzyVetVitalsCategories.NO_MAPPING} 
                                            setSelectedOption={(value) => {
                                                updateEzyVetVitalCategory(item.name?.toLowerCase() ?? "", entry.name?.toLowerCase() ?? "", EzyVetVitalsCategories[value.toUpperCase().replace(" ", "") as keyof typeof EzyVetVitalsCategories])
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>))}
            </div>
            <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={() => handleTemplateConfig()}
                >
                    Save Configuration
                </button>
            </div>
        </div>
    );
};
