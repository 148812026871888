import React, { useState, Fragment, useRef, useEffect, useCallback, useMemo } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Tag } from '../types/user';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

interface TagSelectorProps {
  tags: Tag[];
  selectedTags: Tag[];
  onSelectTag: (tagToAdd:Tag) => Promise<void>;
  className?: string;
  disabled?: boolean;
}

const NO_TAG:Tag = { id: 'none', name: 'No tag', teams: [], users: [] };

const TagSelector: React.FC<TagSelectorProps> = ({
  tags,
  selectedTags,
  onSelectTag,
  className = '',
  disabled = false
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  // For tags, we'll only ever have one selection, so simplify to a string
  const [localSelectedTag, setLocalSelectedTag] = useState<Tag>(selectedTags[0] || NO_TAG);
  const [hasChanges, setHasChanges] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [menuPlacement, setMenuPlacement] = useState<'bottom' | 'top'>('bottom');
  
  // Update local state when props change
  useEffect(() => {
    setLocalSelectedTag(selectedTags[0] || '');
    setHasChanges(false);
  }, [selectedTags]);

  // Filter tags based on search term
  const filteredTags = tags.filter(
    (tag) => tag.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Select tag (only one at a time)
  const selectTag = useCallback( (tag: Tag) => {
    // If selecting "No tag" or clicking on the currently selected tag, clear selection
    if (tag.id === 'none' || tag.id === localSelectedTag?.id) {
      setLocalSelectedTag(NO_TAG);
    } else {
      setLocalSelectedTag(tag);
    }
    setHasChanges(true);
  }, [localSelectedTag]);
  
  // Save changes
  const saveChanges = useCallback(async () => {
    if (localSelectedTag && localSelectedTag !== selectedTags[0]) {
      await onSelectTag(localSelectedTag);
    }
    setIsOpen(false);
    setHasChanges(false);
  }, [localSelectedTag, selectedTags, onSelectTag]);
  
  // Find the currently selected tag object
  const selectedTagObject = useMemo(() =>
    localSelectedTag ? filteredTags.find(tag=> tag.id === localSelectedTag.id) : undefined
  , [filteredTags, localSelectedTag]);
  
  // Filter out the selected tagfrom available options
  const availableTags = useMemo(() =>
    localSelectedTag ? filteredTags.filter(tag=> tag.id !== localSelectedTag.id) : filteredTags
  , [filteredTags, localSelectedTag]);  
  
  // Check position before opening menu
  const checkPosition = () => {
    if (menuButtonRef.current) {
      const buttonRect = menuButtonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const menuHeight = Math.min((filteredTags.length + 1) * 40 + 110 + 100, 350); // Height including search + items + "No tag" option + save button
      setMenuPlacement(spaceBelow < menuHeight ? 'top' : 'bottom');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && 
          menuButtonRef.current && !menuButtonRef.current.contains(event.target as Node)) {
        // Reset local selection if closed without saving
        if (hasChanges) {
          setLocalSelectedTag(selectedTags[0] || '');
          setHasChanges(false);
        }
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', checkPosition, true);
    window.addEventListener('resize', checkPosition);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', checkPosition, true);
      window.removeEventListener('resize', checkPosition);
    };
  }, [hasChanges, selectedTags]);

  const toggleMenu = () => {
    if (!isOpen) {
      checkPosition();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative inline-block text-left ${className}`} ref={menuRef}>
      <button 
        className={`inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm ${disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-50'}`}
        ref={menuButtonRef}
        onClick={toggleMenu}
        disabled={disabled}
      >
        Tag
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </button>

      {isOpen && (
        <div 
          className={`absolute ${menuPlacement === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'} right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50`}
        >
          <div className="px-4 py-3">
            <input
              type="text"
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Search tags..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          
          <div className="max-h-60 overflow-y-auto">
            {/* Selected tagsection */}
            {selectedTagObject && (
              <div className="py-1">
                <p className="px-4 py-2 text-xs font-semibold text-gray-500">SELECTED TAG</p>
                <button
                  className="group flex items-center px-4 py-2 text-sm w-full text-left text-gray-900 hover:bg-gray-100"
                  onClick={() => selectTag(selectedTagObject)}
                >
                  <svg className="mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  {selectedTagObject.name}
                </button>
              </div>
            )}
            {/* No tag selected */}
            {!selectedTagObject && (
              <div className="py-1">
                <p className="px-4 py-2 text-xs font-semibold text-gray-500">NO TAG SELECTED</p>
                <p className="px-4 py-2 text-xs text-gray-500 italic">User will not be assigned a tag</p>
              </div>
            )}
            
            {/* Available options section */}
            <div className="py-1 border-t border-gray-100">
              <p className="px-4 py-2 text-xs font-semibold text-gray-500">AVAILABLE OPTIONS</p>           
              
              {/* Available tags */}
              {availableTags.map((tag) => (
                <button
                  key={tag.id}
                  className="group flex items-center px-4 py-2 text-sm w-full text-left text-gray-700 hover:bg-gray-100"
                  onClick={() => selectTag(tag)}
                >
                  <span className="ml-8">{tag.name}</span>
                </button>
              ))}
              
              {searchTerm && availableTags.length === 0 && !selectedTagObject && (
                <div className="text-center py-2 text-sm text-gray-500">
                  No tags found
                </div>
              )}
            </div>
          </div>
          
          <div className="py-2 px-4 border-t border-gray-100 flex flex-col gap-y-2">
            <button
              type="button"
              className={`inline-flex justify-center w-full rounded-md border px-4 py-2 bg-blue-600 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${!hasChanges ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={saveChanges}
              disabled={!hasChanges}
            >
              Save Changes
            </button>
            <span className='text-xs text-gray-500 italic whitespace-pre-line text-center'>
              Teams assigned to tag will be applied to the user.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagSelector;
