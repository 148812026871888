import React, { useState, useEffect, useMemo } from 'react';
import MultiSelectListbox from '../listbox/MultiSelectListbox';
import { useAdmin } from '../../../../providers/AdminProvider';
import { useVetRec } from '../../../../providers/VetRecProvider';

const GeneralSettings: React.FC = () => {
    const { superOrgMetadata } = useVetRec()
    const { users, updateSuperAdmins } = useAdmin()
    const [admins, setAdmins] = useState<string[]>(superOrgMetadata?.owner)

    const filteredUsers = useMemo(() => {
        return users.filter((user) => user.name !== "" && user.name !== undefined && user.name !== null && !user.id.includes("orginv_"))
    }, [users])

    const unsavedChanges = useMemo(() => {
        return admins.slice().sort().join(',') !== superOrgMetadata?.owner.slice().sort().join(',')
    }, [admins, superOrgMetadata])

    const handleUpdateSuperAdmins = async (selected: string[]) => {
        setAdmins(selected)
    }

    const saveSuperAdmins = async () => {
        await updateSuperAdmins(admins)
    }

    return (
        <div className="mt-8 p-4 flex flex-col space-y-4">
            <div className='flex flex-row justify-between items-center'>
                <div className='flex flex-col space-y-2'>
                    <h2 className="text-xl font-bold">Organization Administrators</h2>
                    <p className="text-sm text-gray-500">
                        Add or remove organization administrators
                    </p>
                </div>
                <button onClick={saveSuperAdmins} className={`inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm ${!unsavedChanges ? "bg-gray-300" : "bg-blue-600 hover:bg-blue-700"} focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto h-10`} disabled={!unsavedChanges}>Save</button>
            </div>
            <MultiSelectListbox options={filteredUsers} selected={admins} onChange={handleUpdateSuperAdmins}/>
        </div>
    );
};

export default GeneralSettings;