import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { TagIcon } from '@heroicons/react/24/solid'
import { useAdmin } from '../../../providers/AdminProvider';
import { Spinner } from '../../../utils/Spinner';
import MultiSelectListbox from './listbox/MultiSelectListbox';

interface CreateTagModalProps {
    showModal: boolean;
    hideModal: () => void;
}

export default function CreateTagModal({ showModal, hideModal }: CreateTagModalProps) {

    const [loading, setLoading] = useState(false)
    const [newTagName, setNewTagName] = useState('');
    const [selectedTeams, setSelectedTeams] = useState<string[]>([])
    const { createTag, teams } = useAdmin();

    const handleCreateTag = async () => {
        setLoading(true)
        if (newTagName.trim()) {
            await createTag(newTagName, selectedTeams);
            setNewTagName('');
            hideModal();
        }
        setLoading(false)
    }

    const canCreateTag = useMemo(() => {
        return newTagName.trim() && selectedTeams.length > 0;
    }, [newTagName, selectedTeams]);

    useEffect(() => {
        if (showModal) {
            setNewTagName('');
            setSelectedTeams([]);
        }
    }, [showModal]);

    return (
        <Transition.Root show={showModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={() => {}}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100`}>
                                        <TagIcon className={`h-6 w-6 text-blue-600`} aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Create Tag
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Create a new tag.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='relative mt-4 overflow-visible'>
                                    <div className='flex flex-col gap-y-2'>
                                        <label htmlFor="newTagName" className="block text-sm font-semibold text-gray-700">Tag Name</label>
                                        <input
                                            type="text"
                                            id="newTagName"
                                            name="newTagName"
                                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            value={newTagName}
                                            onChange={(e) => setNewTagName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='relative mt-4 overflow-visible'>
                                    <div className='flex flex-col gap-y-2'>
                                        <label htmlFor="selectedTeams" className="block text-sm font-semibold text-gray-700">Select Teams</label>
                                        <MultiSelectListbox options={teams} selected={selectedTeams} onChange={setSelectedTeams}/>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-x-4 w-full'>
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 sm:col-start-2 mt-4"
                                        onClick={() => hideModal()}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-4 ${canCreateTag ? "hover:bg-blue-500 bg-blue-600" : "bg-gray-600 bg-opacity-50 cursor-not-allowed"}`}
                                        onClick={handleCreateTag}
                                        disabled={loading || !canCreateTag} 
                                    >
                                        {loading ? <Spinner size='w-5 h-5' timer={false}/> : "Create"}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
