import { DateType } from "react-tailwindcss-datepicker";

export const DateTypeToFloat = ( dateTime:DateType, start:boolean ) => {
    let date = new Date()
    if(dateTime){
        date = new Date(dateTime.getTime())
    }
    if(start) date.setHours(0, 0, 0, 0);
    else date.setHours(23, 59, 59, 999);
    let date_in_float = Math.floor(date.getTime() / 1000)
    return date_in_float
}
