import React, { useCallback } from 'react';
import { User, Team, Tag, UserStatus } from '../types/user';
import TeamBadge from './TeamBadge';
import TagBadge from './TagBadge';
import TeamSelector from './TeamSelector';
import TagSelector from './TagSelector';
import RoleSelector from './RoleSelector';
import { UserRole } from '../../settings/UserRoles';
import { capitalize } from 'lodash';

interface UserTableRowProps {
  user: User;
  teams: Team[];
  tags: Tag[];
  isSelected: boolean;
  onSelectUser: (userId: string) => void;
  onUpdateUserTeams: (user: User, teamsToAdd:string[], teamsToRemove:string[]) => Promise<void>;
  onUpdateUserTags: (user: User, tagToAdd:Tag) => Promise<void>;
  getTeamById: (teamId: string) => Team | undefined;
  getTagById: (tagId: string) => Tag | undefined;
  onUpdateUserRole: (user: User[], newRole: UserRole) => Promise<void>;
}

const UserTableRow: React.FC<UserTableRowProps> = ({
  user,
  teams,
  tags,
  isSelected,
  onSelectUser,
  onUpdateUserTeams,
  onUpdateUserTags,
  getTeamById,
  getTagById,
  onUpdateUserRole
}) => {
  // Helper function to get status badge color
  const getStatusBadgeColor = (status: UserStatus) => {
    switch (status) {
      case UserStatus.Active:
        return 'bg-green-100 text-green-800';
      case UserStatus.Invited:
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Simplified team handling - direct update
  const handleTeamToggle = useCallback(async (teamsToAdd:string[], teamsToRemove:string[]) => {
    await onUpdateUserTeams(user, teamsToAdd, teamsToRemove);
  }, [onUpdateUserTeams, user]);

  // Simplified tag handling for single selection
  const handleTagToggle = async (tagToAdd:Tag) => {
    await onUpdateUserTags(user, tagToAdd);
  };

  // Simplified tag handling for single selection
  const handleRoleToggle = async (role: UserRole) => {
    await onUpdateUserRole([user], role);
  };

  return (
    <tr className={isSelected ? 'bg-blue-50' : undefined}>
      <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 pl-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            className={`h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 mr-3 ${user.status === UserStatus.Invited ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
            checked={isSelected}
            onChange={() => onSelectUser(user.id)}
            disabled={user.status === UserStatus.Invited}
          />
          <div className='flex flex-row items-center gap-x-4'>
            <div className='flex flex-col gap-y-1 min-w-40'>
              {user.name}
              <span className='font-normal text-xs'>{user.email}</span>
            </div>
            <div className="flex items-center">
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(user.status)}`}>
                {capitalize(user.status)}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        <div className="flex items-center">
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800`}>
            {capitalize(user.role.replace('org:', '').replace('_', ' '))}
          </span>
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        <div className="flex flex-wrap gap-2">
          {user.teams.slice(0, 2).map((teamId) => {
            const team = getTeamById(teamId);
            return team && (
              <TeamBadge
                key={teamId}
                team={team}
              />
            );
          })}
          {user.teams.length > 2 && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800" title={user.teams.slice(2).map((teamId) => getTeamById(teamId)?.name).join('\n')} >
              +{user.teams.length - 2}
            </span>
          )}
        </div>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 min-w-28">
        <div className="flex flex-wrap gap-2">
          {user.tags.map((tagId) => {
            const tag = getTagById(tagId);
            return tag && (
              <TagBadge
                key={tagId}
                tag={tag}
              />
            );
          })}
        </div>
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <div className="flex justify-start space-x-2 items-center">
          <div className="static" style={{ position: 'static', zIndex: 'auto' }}>
            <RoleSelector
              selectedRole={user.role}
              onSelectRole={handleRoleToggle}
              disabled={user.status === UserStatus.Invited}
            />
          </div>
          <div className="static" style={{ position: 'static', zIndex: 'auto' }}>
            <TeamSelector
              teams={teams}
              selectedTeams={user.teams}
              onSelectTeam={handleTeamToggle}
              disabled={user.status === UserStatus.Invited}
            />
          </div>
          <div className="static" style={{ position: 'static', zIndex: 'auto' }}>
            <TagSelector
              tags={tags}
              selectedTags={user.tags[0] ? tags.filter((t) => t.id === user.tags[0]) : []}
              onSelectTag={handleTagToggle}
              disabled={user.status === UserStatus.Invited}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default UserTableRow;
