import React, { useCallback } from 'react';
import { Team, Tag, User } from '../types/user';
import TeamSelector from './TeamSelector';
import TagSelector from './TagSelector';
import RoleSelector from './RoleSelector';
import { UserRole } from '../../settings/UserRoles';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface BulkActionsProps {
  selectedUsers: User[];
  selectedUsersCount: number;
  teams: Team[];
  tags: Tag[];
  onBulkAddTeam: (user: User[], teamsToAdd:string[], teamsToRemove:string[]) => Promise<void>;
  onBulkAddTag: (user: User[], tagToAdd:Tag) => Promise<void>;
  onBulkUpdateRole: (users: User[], newRole: UserRole) => Promise<void>;
  clearSelection: () => void;
}

const BulkActions: React.FC<BulkActionsProps> = ({
  selectedUsers,
  selectedUsersCount,
  teams,
  tags,
  onBulkAddTeam,
  onBulkAddTag,
  onBulkUpdateRole,
  clearSelection
}) => {

  const handleBulkUpdateTeam = useCallback(async (teamsToAdd:string[], teamsToRemove:string[]) => {
    await onBulkAddTeam(selectedUsers, teamsToAdd, teamsToRemove);
  }, [selectedUsers, onBulkAddTeam]);

  const handleBulkUpdateTag = useCallback(async (tagToAdd:Tag) => {
    await onBulkAddTag(selectedUsers, tagToAdd);
  }, [selectedUsers, onBulkAddTag]);

  const handleBulkUpdateRole = useCallback(async (role: UserRole) => {
    await onBulkUpdateRole(selectedUsers, role);
  }, [selectedUsers, onBulkUpdateRole])


  return (
    <div className="bg-gray-50 p-4 rounded-md mb-4 flex items-center justify-between mt-4 shadow">
      <div className="text-sm text-gray-700 flex items-center gap-x-2">
        <span className="font-medium">{selectedUsersCount}</span> users selected
        <button className="flex items-center text-xs text-gray-500 hover:text-gray-700 border border-gray-300 bg-gray-100 rounded-full px-2" onClick={() => {clearSelection()}}>
          <XMarkIcon className="h-3 w-3 mr-1" />
          Clear selection
        </button>
      </div>
      <div className="flex space-x-2 items-center text-sm font-normal text-gray-700">
        Perform actions on all selected users:
        <RoleSelector
          selectedRole={undefined}
          onSelectRole={handleBulkUpdateRole}
          className="ml-2"
        />
        <TeamSelector
          teams={teams}
          selectedTeams={[]}
          onSelectTeam={handleBulkUpdateTeam}
          className="ml-2"
        />
        <TagSelector
          tags={tags}
          selectedTags={[]}
          onSelectTag={handleBulkUpdateTag}
          className="ml-2"
        />
      </div>
    </div>
  );
};

export default BulkActions;
