import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { SectionTypes } from './TemplateUtils'
import { SUPER_HIGH_Z_INDEX } from '../tours/constants'

const sectionTypeOptions = [
    { name: 'Section', value: SectionTypes.NORMAL },
    { name: 'Group', value: SectionTypes.LIST },
    { name: 'Paragraph', value: SectionTypes.PARAGRAPH },
    { name: 'Number', value: SectionTypes.NUMBER },
    { name: 'List', value: SectionTypes.SIMPLE_LIST },
    { name: 'Fixed Text', value: SectionTypes.HARDCODED }
]

const backwardsCompatibleSectionTypeOptions: { [key in SectionTypes]: string } = {
    [SectionTypes.NORMAL]: "Section",
    [SectionTypes.LIST]: "Group",
    [SectionTypes.PARAGRAPH]: "Paragraph",
    [SectionTypes.NUMBER]: 'Number',
    [SectionTypes.COLLECTION]: 'Section',
    [SectionTypes.SIMPLE_LIST]: 'List',
    [SectionTypes.HARDCODED]: 'Fixed Text',
    [SectionTypes.TIMELINE]: 'Timeline',
    [SectionTypes.DIAGRAM]: 'Chart',
    [SectionTypes.SPACES]: 'Spaces',
    [SectionTypes.GENERAL_INSTRUCTIONS]: 'General Instructions',
}

interface SectionTypePickerProps{
    sectionType:SectionTypes
    setSectionType: (type:SectionTypes) => void
    disabled?:boolean
    dataTourId?:string
    raiseZIndex?:boolean
}

export default function SectionTypePicker(props:SectionTypePickerProps) {
    return (<div data-tour-id={props.dataTourId} className={`w-36 lg:w-48 h-8 ${props.raiseZIndex ? SUPER_HIGH_Z_INDEX : ""}`}>
        <Listbox value={props.sectionType} onChange={props.setSectionType} disabled={props.disabled}>
            {({ open }) => (<>
                {open && <div className="absolute ml-52 p-2 text-gray-900 bg-gray-100 rounded-md shadow-lg border border-gray-300 w-72 lg:w-96 z-[999] text-sm">
                    <div className='flex flex-col gap-y-2'>
                        <div className='font-semibold'>Section Types</div>
                        <ul className='list-disc list-inside'>
                            <li><span className='font-semibold'>Section: </span>Simple section with multiple entries</li>
                            <li><span className='font-semibold'>Group: </span>Simple section with list of grouped entries</li>
                            <li><span className='font-semibold'>Paragraph: </span>Section with just one text based entry</li>
                            <li><span className='font-semibold'>Number: </span>Section with just one number based entry</li>
                            <li><span className='font-semibold'>List: </span>Section with one bullet point entry</li>
                            <li><span className='font-semibold'>Fixed Text: </span>Value will be used as provided</li>
                        </ul>
                    </div>
                </div>}
                <div className="relative">
                <Listbox.Button data-tour-id={props.dataTourId ? `${props.dataTourId}-button` : undefined} className={`relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm items-center  flex border border-gray-300 h-8 ${props.disabled ? "text-gray-400" : "cursor-pointer"}`}>
                    <span className="block truncate">{backwardsCompatibleSectionTypeOptions[props.sectionType]}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >   
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm cursor-pointer z-50">
                        {sectionTypeOptions.map((option, optionIdx) => (
                            <Listbox.Option
                                key={optionIdx}
                                className={({ active }) =>
                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                    active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                                    }`
                                }
                                value={option.value}
                            >
                                {({ selected }) => (
                                    <>
                                    <span
                                        className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                        }`}
                                    >
                                        {option.name}
                                    </span>
                                    {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </>)}
            </Listbox>
        </div>
    )
}