export const pimsMetadata = ["on-prem-cornerstone", "on-prem-avimark", "on-prem-intravet"];

export const getPimsDisplayName = (pimsId: string) => {
    switch (pimsId) {
        case "on-prem-cornerstone":
            return "Cornerstone";
        case "on-prem-avimark":
            return "Avimark";
        case "on-prem-intravet":
            return "Intravet";
        case "vetspire":
            return "Vetspire";
        default:
            return pimsId;
    }
}

