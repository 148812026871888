import React, { useState, useRef } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { UserRole } from '../../settings/UserRoles';
import { useAdmin } from '../../../providers/AdminProvider';

// Import the new components
import SingleUserFormSection from './invite/SingleUserFormSection';
import BulkUploadSection from './invite/BulkUploadSection';
import MappingSection from './invite/MappingSection';
import PreviewSection from './invite/PreviewSection';
import { UserInviteFormProps, CsvUser, ProcessedUser, HeaderMapping } from './invite/types';

const UserInviteForm: React.FC<UserInviteFormProps> = ({ onInviteSuccess, onCancel }) => {
    const [formData, setFormData] = useState({
        email: '',
        teams: [] as string[],
        role: UserRole.MEMBER // Default role
    });
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadMode, setUploadMode] = useState<'single' | 'bulk'>('single');
    const [csvData, setCsvData] = useState<CsvUser[]>([]);
    const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
    const [showMapping, setShowMapping] = useState(false);
    const [headerMapping, setHeaderMapping] = useState<HeaderMapping>({});
    const [showPreview, setShowPreview] = useState(false);
    const [csvProcessed, setCsvProcessed] = useState<ProcessedUser[]>([]);
    const [fileType, setFileType] = useState<'csv' | 'xlsx' | null>(null);
    const { teams, inviteUsers } = useAdmin()

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTeamChange = (selected: string[]) => {
        setFormData(prev => ({ ...prev, teams: selected }));
    };

    const handleRoleChange = (selected: string) => {
        setFormData(prev => ({ ...prev, role: selected as UserRole }));
    };

    const handleMappingChange = (field: string, headerValue: string) => {
        setHeaderMapping(prev => ({ ...prev, [field]: headerValue }));
    };

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError(null);
        const file = e.target.files?.[0];

        if (!file) {
            return;
        }

        // Determine file type based on extension
        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        if (fileExtension === 'csv') {
            setFileType('csv');
            processCsvFile(file);
        } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
            setFileType('xlsx');
            processExcelFile(file);
        } else {
            setError('Unsupported file format. Please upload a CSV or Excel file.');
        }
    };

    const processCsvFile = (file: File) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                processParseResults(results.data as CsvUser[], results.errors.length > 0 ? results.errors[0].message : null);
            },
            error: (error) => {
                setError(`Error parsing CSV: ${error.message}`);
            }
        });
    };

    const processExcelFile = (file: File) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const data = e.target?.result as ArrayBuffer;
                const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Convert Excel data to JSON
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                if (jsonData.length === 0) {
                    setError('The Excel file is empty');
                    return;
                }

                processParseResults(jsonData as CsvUser[], null);
            } catch (error) {
                setError(`Error parsing Excel file: ${error instanceof Error ? error.message : 'Unknown error'}`);
            }
        };

        reader.onerror = () => {
            setError('Error reading file');
        };

        reader.readAsArrayBuffer(file);
    };

    const processParseResults = (data: CsvUser[], errorMessage: string | null) => {
        if (errorMessage) {
            setError(`Error parsing file: ${errorMessage}`);
            return;
        }

        if (data.length === 0) {
            setError('The file is empty');
            return;
        }

        setCsvData(data);

        // Get headers from the first row
        const headers = Object.keys(data[0]);
        setCsvHeaders(headers);

        // Auto-detect column mapping based on common header names
        const emailHeaders = ['email', 'email address', 'mail'];
        const teamHeaders = ['team', 'teams', 'department'];
        const tagHeaders = ['tag', 'tags', 'label', 'labels'];
        const roleHeaders = ['role', 'roles', 'user role'];

        const mapping: { [key: string]: string } = {};

        headers.forEach(header => {
            const headerLower = header.toLowerCase();

            if (emailHeaders.includes(headerLower)) {
                mapping.email = header;
            } else if (teamHeaders.includes(headerLower)) {
                mapping.teams = header;
            } else if (tagHeaders.includes(headerLower)) {
                mapping.tags = header;
            } else if (roleHeaders.includes(headerLower)) {
                mapping.role = header;
            }
        });

        // If we found both required fields (email and team), skip the mapping step
        if (mapping.email && mapping.teams) {
            setHeaderMapping(mapping);
            processDataWithMapping(data, mapping);
        } else {
            // Show mapping interface
            setHeaderMapping(mapping);
            setShowMapping(true);
        }
    };

    const processDataWithMapping = (data: CsvUser[], mapping: HeaderMapping) => {
        if (!mapping.email) {
            setError('Email mappings are required');
            return;
        }
        const processed = data.map(row => {
            const email = mapping.email ? row[mapping.email] : '';

            let teams: string[] = [];
            if (mapping.teams && row[mapping.teams]) {
                // Assume teams are comma-separated values
                const teamNames = row[mapping.teams].split(',').map(t => t.trim());
                // Map team names to IDs
                teams = teamNames.map(teamName => {
                    const team = findTeamByName(teamName);
                    return team?.id || '';
                }).filter(id => id !== '');
            }

            let role = UserRole.MEMBER; // Default role
            if (mapping.role && row[mapping.role]) {
                const roleName = "org:" + row[mapping.role].toLowerCase().trim();
                // Map role names to UserRole enum
                if (Object.values(UserRole).includes(roleName as UserRole)) {
                    role = roleName as UserRole;
                }
            }

            const errors: string[] = [];
            if (!email) {
                errors.push('Email is required');
            }

            if (teams.length === 0) {
                errors.push('At least one team is required');
            }

            return {
                email,
                teams,
                role,
                errors
            };
        });

        setCsvProcessed(processed);
        setShowMapping(false);
        setShowPreview(true); // Go directly to preview
    };

    const findTeamByName = (name: string) => {
        return teams.find(team =>
            team.name.toLowerCase() === name.toLowerCase() || team.name.toLowerCase().includes(` ${name.toLowerCase()}`)
        );
    };

    const handleConfirmMapping = () => {
        // Check if required fields are mapped
        if (!headerMapping.email) {
            setError('Email mappings are required');
            return;
        }

        // Process data with the current mapping
        processDataWithMapping(csvData, headerMapping);
    };

    const handleUserTeamChange = (index: number, selectedTeams: string[]) => {
        setCsvProcessed(prev => {
            const updated = [...prev];
            updated[index] = {
                ...updated[index],
                teams: selectedTeams
            };

            // Update validation state
            const errors = [...updated[index].errors.filter(e => !e.includes('team'))];
            if (selectedTeams.length === 0) {
                errors.push('At least one team is required');
            }

            updated[index].errors = errors;

            return updated;
        });
    };

    const handleUserRoleChange = (index: number, selectedRole: UserRole) => {
        setCsvProcessed(prev => {
            const updated = [...prev];
            updated[index] = {
                ...updated[index],
                role: selectedRole
            };
            return updated;
        });
    };

    const handleBulkInvite = async () => {
        setIsSubmitting(true);
        setError(null);

        const validUsers = csvProcessed.filter(user => user.errors.length === 0);

        if (validUsers.length === 0) {
            setError('No valid users to invite');
            setIsSubmitting(false);
            return;
        }

        try {
            await inviteUsers(validUsers.map(user => ({
                email: user.email,
                teams: user.teams,
                role: user.role
            })));

            if(onInviteSuccess) {
                onInviteSuccess(validUsers);
            }

            // Reset form state
            setUploadMode('single');
            setCsvData([]);
            setCsvHeaders([]);
            setShowMapping(false);
            setHeaderMapping({});
            setShowPreview(false);
            setCsvProcessed([]);

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unexpected error occurred');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setIsSubmitting(true);

        try {
            if (!formData.email.trim()) {
                throw new Error('Email is required');
            }

            if (formData.teams.length === 0) {
                throw new Error('At least one team must be selected');
            }

            await inviteUsers([{
                email: formData.email,
                teams: formData.teams,
                role: formData.role
            }]);

            if (onInviteSuccess) {
                onInviteSuccess(formData);
            }

            // Reset form
            setFormData({
                email: '',
                teams: [],
                role: UserRole.MEMBER
            });
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An unexpected error occurred');
        } finally {
            setIsSubmitting(false);
        }
    };

    // Cancel CSV/Excel flow and return to single invite mode
    const handleCancelCsv = () => {
        setUploadMode('single');
        setCsvData([]);
        setCsvHeaders([]);
        setShowMapping(false);
        setHeaderMapping({});
        setShowPreview(false);
        setCsvProcessed([]);
        setFileType(null);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div className="bg-white shadow rounded-md p-6">
            <h2 className="text-xl font-semibold mb-4">Invite New User{uploadMode === 'bulk' ? 's' : ''}</h2>

            {error && (
                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-4">
                    {error}
                </div>
            )}

            {/* Toggle between single and bulk modes */}
            <div className="flex space-x-4 mb-6">
                <button
                    type="button"
                    onClick={() => {setUploadMode('single')}}
                    className={`px-4 py-2 rounded-md ${uploadMode === 'single'
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-700'
                        }`}
                >
                    Single User
                </button>
                <button
                    type="button"
                    onClick={() => {setUploadMode('bulk')}}
                    className={`px-4 py-2 rounded-md ${uploadMode === 'bulk'
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-200 text-gray-700'
                        }`}
                >
                    Bulk Upload (CSV/Excel)
                </button>
            </div>

            {uploadMode === 'single' && (
                <SingleUserFormSection 
                    formData={formData}
                    handleChange={handleChange}
                    handleTeamChange={handleTeamChange}
                    handleRoleChange={handleRoleChange}
                    handleSubmit={(e) => {handleSubmit(e).catch(() => {})}}
                    teams={teams}
                    isSubmitting={isSubmitting}
                    onCancel={onCancel}
                />
            )}

            {uploadMode === 'bulk' && !showMapping && !showPreview && (
                <BulkUploadSection 
                    fileInputRef={fileInputRef}
                    handleFileUpload={handleFileUpload}
                    onCancel={onCancel}
                />
            )}

            {uploadMode === 'bulk' && showMapping && (
                <MappingSection 
                    csvHeaders={csvHeaders}
                    headerMapping={headerMapping}
                    handleMappingChange={handleMappingChange}
                    handleConfirmMapping={handleConfirmMapping}
                    handleCancelCsv={handleCancelCsv}
                    fileType={fileType}
                />
            )}

            {uploadMode === 'bulk' && showPreview && (
                <PreviewSection 
                    csvProcessed={csvProcessed}
                    teams={teams}
                    handleUserTeamChange={handleUserTeamChange}
                    handleUserRoleChange={handleUserRoleChange}
                    handleBulkInvite={() => {handleBulkInvite().catch(() => {})}}
                    handleCancelCsv={handleCancelCsv}
                    isSubmitting={isSubmitting}
                />
            )}
        </div>
    );
};

export default UserInviteForm;
