import { useEffect, useMemo, useState } from "react";
import { Spinner } from "../../utils/Spinner";
import { SummarizedHistoryEntryView } from "./SummarizedHistoryViewEntry";
import UnsavedChangesBanner from "../templates/UnsavedChangesBanner";
import { useHistory } from "../../providers/HistoryProvider";

export const SummarizedHistoryView: React.FC = () => {
  const { activePreHistory, preHistoryStreaming, uploadLocalRecap } = useHistory()

  const [localPreHistory, setLocalPreHistory] = useState<{[key: string]: string} | undefined>(activePreHistory);
  const [changeTracker, setChangeTracker] = useState<{[key:string]:boolean}>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!preHistoryStreaming && activePreHistory) {
      setLocalPreHistory({...activePreHistory});
      const newComparer = Object.keys(activePreHistory).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as {[key: string]: boolean});
      setChangeTracker(newComparer);
    }
  }, [activePreHistory, preHistoryStreaming]);

  const anyChanges = useMemo(() => {
    return Object.values(changeTracker).some((value) => value);
  }, [changeTracker]);

  const updateLocalPreHistory = (key: string, value: string) => {
    if (activePreHistory && localPreHistory) {
      setLocalPreHistory({
        ...localPreHistory,
        [key]: value
      });
      setChangeTracker({
        ...changeTracker,
        [key]: activePreHistory[key].trim().replaceAll("\n -", "\n-") !== value.trim().replaceAll("\n -", "\n-")
      });        
    }
  };

  async function onSaveChanges() {
    setIsSaving(true);
    await uploadLocalRecap(localPreHistory);
    setIsSaving(false);
  }

  return (
    <div className={`flex flex-col gap-y-4 ${preHistoryStreaming ? "pt-6" : ""} relative w-full`}>
      <UnsavedChangesBanner 
        show={anyChanges} 
        onSave={onSaveChanges} 
        onDismiss={() => {}} 
        isSaving={isSaving}/>
      {preHistoryStreaming && (
        <div className="absolute top-0 sm:top-0 right-0 sm:right-0 flex flex-col gap-y-2">
          <div className="flex flex-row relative items-center gap-x-2 rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold border border-gray-300 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 h-10 overflow-hidden justify-center z-[2] shadow">
            <Spinner size="h-5 w-5" timer={false} />
            Writing summary...
          </div>
        </div>
      )}
      {activePreHistory && Object.entries(activePreHistory).map(([key, value]) => (
        <SummarizedHistoryEntryView
          key={key}
          title={key}
          value={value}
          didValueChange={changeTracker[key]}
          onValueChange={(newValue) => updateLocalPreHistory(key, newValue)}
          forceExpand={!!preHistoryStreaming}
        />
      ))}
    </div>
  );
};
