import ReactQuill from "react-quill";
import { SpacingText } from "../../utils/TextUtils";
import { ArrowDownTrayIcon, ChatBubbleBottomCenterTextIcon, CheckCircleIcon, ChevronUpIcon, CloudArrowUpIcon, InformationCircleIcon, LanguageIcon, MicrophoneIcon } from "@heroicons/react/24/solid";
import { Loading } from "../../utils/Loading";
import { Disclosure } from "@headlessui/react";
import { useHistory } from "../../providers/HistoryProvider";
import { Spinner } from "../../utils/Spinner";
import MoreMenu from "../moreMenu";
import { getFileExtensionFromUrl, RecordingTranscriptPairBlock } from "../transcript/RecordingTranscriptPairBlock";
import { useVetRec } from "../../providers/VetRecProvider";

export default function Transcript() {
    const {
        activeTranscript,
        activeRecordingsAndTranscripts,
        shouldSessionUseMetagraph,
        activeTranscriptExists,
        transcriptLoading,
        activeRecording,
        activeRecordingBackUp,
        recordingLoading,
        activeSession,
        handleMarkComplete,
        handleAddToSession,
        loadingSessionInfo,
        transcriptProcessing,
        setShowChatBot,
        handleLanguageChange,
        setUploadRecordingModal,
        multiPetSessionActive,
        processRecordings,
    } = useHistory()

    const { davidEnabled } = useVetRec()
    
    const downloadFile = async (url: string, fileName: string) => {
        
        const response = await fetch(url);
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);
        
        const a = document.createElement('a');
        a.href = urlBlob;
        a.setAttribute('download', fileName); // Force download
        document.body.appendChild(a);
        a.click();
        
        window.URL.revokeObjectURL(urlBlob);
        document.body.removeChild(a);
    };

    return(<div>
        {(transcriptLoading || loadingSessionInfo) && !activeTranscriptExists && !transcriptProcessing && <Loading text={transcriptLoading ?? loadingSessionInfo ?? ""}/>}
        {(!shouldSessionUseMetagraph || activeRecordingsAndTranscripts === undefined) && transcriptProcessing && !transcriptLoading && !activeTranscript && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-6 mb-8 font-semibold'>
            <div>Transcribing recording 🤖...</div>
            <Spinner size="w-12 h-12" timer={true}/>
            <div className="text-sm text-center text-gray-700 font-normal">Transcript will be ready within 60s.<br/>Feel free to navigate away if you need to.</div>
        </div>}
        {activeTranscriptExists && <div className="flex xl:flex-row flex-col gap-y-4 justify-between gap-x-4 mb-4 xl:items-center items-end">
            <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                <div className="flex grow items-center justify-center">
                    <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                        <div className="flex flex-row gap-x-4 items-center">
                            <div>
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="text-sm text-blue-900">Review your <span className="font-semibold">Recordings</span> and <span className="font-semibold">Transcripts</span> for the visit.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-x-2 w-full sm:w-auto justify-end">
                <button
                    type="button"
                    title={activeSession && activeSession.status.general && activeSession.status.general === "Completed" ? "This session is completed" : "Mark this session as completed"}
                    className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-xs sm:text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 sm:min-w-fit ${activeSession && activeSession.status.general && activeSession.status.general === "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-blue-600 text-white hover:bg-main-button-hover'}`}
                    onClick={() => handleMarkComplete()}
                >
                    <CheckCircleIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                    <span className="hidden sm:block">{activeSession && activeSession.status.general && activeSession.status.general === "Completed" ?  "Completed" : "Mark completed"}</span>
                </button>
                {davidEnabled && <div className="relative group">
                    <button
                        disabled={multiPetSessionActive}
                        type="button"
                        className={`inline-flex items-center justify-center gap-x-2 rounded-md px-2 py-2.5 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 text-sm grow w-full sm:max-w-52 ${
                            multiPetSessionActive 
                            ? 'bg-main-button/50 text-main-button-text/50 cursor-not-allowed hover:bg-main-button/50' 
                            : 'bg-main-button text-main-button-text hover:bg-main-button-hover'
                        }`}
                        onClick={() => setShowChatBot(true)}
                    >
                        <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-main-button-text" aria-hidden="true" />
                        <span className="hidden sm:block">D.A.V.I.D</span>
                    </button>
                    {multiPetSessionActive && (
                        <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 hidden group-hover:block w-max z-10">
                            <div className="bg-gray-900 text-white text-xs rounded py-1 px-2">
                                D.A.V.I.D for multi-pet sessions coming soon!
                            </div>
                            <div className="w-2 h-2 bg-gray-900 transform rotate-45 absolute left-1/2 -translate-x-1/2 -top-1"></div>
                        </div>
                    )}
                </div>}
                <button
                    type="button"
                    title="Record additional information for this session which will be added to the notes."
                    className="whitespace-nowrap inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52"
                    onClick={() => handleAddToSession()}
                >
                    <MicrophoneIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                    Add to visit
                </button>
                <MoreMenu
                    moreText="Actions" 
                    options={[
                        {
                            name:"Change Language",
                            action: () => handleLanguageChange(true),
                            icon: <LanguageIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
                        },
                        {
                            name: "Upload Recording",
                            action: () => setUploadRecordingModal(true),
                            icon: <CloudArrowUpIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                        },
                    ]} 
                    height="h-10 sm:h-12"/>
            </div>
        </div>}
        {shouldSessionUseMetagraph && activeRecordingsAndTranscripts && <div className="w-full mb-4">
            {activeRecordingsAndTranscripts.length > 0 && <div className="space-y-3">
                <div className="w-full text-center py-2">
                    Recordings displayed from oldest to newest.
                </div>
                {activeRecordingsAndTranscripts.map((recordingTranscriptPair, i) => (
                    <RecordingTranscriptPairBlock
                        key={i}
                        recordingTranscriptPair={recordingTranscriptPair}
                        downloadFile={downloadFile}
                        downloadFileName={activeSession?.name + "_" + i}
                        defaultOpen={i === 0}
                        source={activeSession?.source}
                        sessionLanguage={activeSession?.language}
                        processRecordings={() => processRecordings(true, activeSession?.language, undefined)}
                    />
                ))}
            </div>}
        </div>}
        {!shouldSessionUseMetagraph && (activeRecording || activeRecordingBackUp || recordingLoading) && <div className="w-full mb-4">
            <div className="w-full rounded-lg bg-white">
                <Disclosure>
                {({ open }) => (
                    <>
                    <Disclosure.Button className={`flex w-full justify-between items-center ${open ? "rounded-t-lg" : "rounded-lg"} bg-blue-100 px-4 py-2 text-left text-sm font-semibold text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75 h-12`}>
                        <span>Recordings</span>
                        <ChevronUpIcon
                        className={`${
                            open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-blue-500`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-sm text-gray-500">
                    <div className="px-6 pb-4 pt-4 text-sm text-gray-500 border border-t-0 border-gray-300 rounded-b-lg shadow">
                        {recordingLoading && <Loading text={recordingLoading}/>}
                        {!recordingLoading && <div className="w-full text-center">
                            Recordings displayed from oldest to newest.
                        </div>}
                        {activeRecording && activeRecording.map((r, i) => (
                            <div className="flex flex-row items-center gap-x-2">
                                <audio key={r} controls src={r} className="w-full my-4 fill-green-600">
                                    Your browser does not support the audio element.
                                </audio>
                                <button onClick={() => downloadFile(r, activeSession?.name + "_" + i + "." + getFileExtensionFromUrl(r))} className="text-white p-2 bg-blue-600 rounded-md"><ArrowDownTrayIcon className="h-5 w-5" /></button>
                            </div>
                        ))}
                        {activeRecording && activeRecording.length === 0 && !activeRecordingBackUp && <div className="w-full text-center">
                            Looks like there are no recordings for this session.
                            Create a recording by going to the <span className="font-semibold">Active Sessions</span> tab.
                        </div>}
                        {!recordingLoading && activeRecordingBackUp && activeRecording?.length == 0 && <div className="w-full text-center">
                            Some recordings might not be displayed, but they are stored. For access to the raw recordings contact support@vetrec.io
                        </div>}
                    </div>
                    </Disclosure.Panel>
                    </>
                )}
                </Disclosure>
            </div>
        </div>}
        {!shouldSessionUseMetagraph && activeTranscript && <ReactQuill
            theme='snow'
            placeholder='Mr.Wiggles is looking mighty well today...'
            className='block w-full h-[68vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
            value={SpacingText(activeTranscript)}
            readOnly
            modules={{toolbar: false}}
        />}
    </div>)
}