import { forEach } from "lodash";
import { convertTimestampToDateNoTime } from "./time";

export class dataPoint{
    x!: string;
    y!: number;
}

export class dataForGraph{
    label!: string;
    data!: dataPoint[];
    backgroundColor!: string;
}

const colors: { [key: string]: string } = {
    "Notes": "#2463ED",
    "Notes/Recap": "#EA4E70",
    "Recap": "#02CF94",
    "Notes/Discharge": "#FAC503"
};

export const DataToGraphFormat = (data: [], timeframe:string, order?:number) => {
    let dataDict: { [key: string]: dataPoint[] } = {}
    let totalCount = 0
    forEach(data, (value: any) => {
        let date:string
        if(timeframe === "weekly"){
            date = getDateFromWeek(value.week, value.year)
                        
        } else {
            date = convertTimestampToDateNoTime(value.date)
        }
        let type:string = value.type
        type = type.replace("Prehistory", "Recap")
        let count = value.count
        totalCount += count
        // check if type is a key in dataDict
        if (type in dataDict){
            dataDict[type].push({x: date, y: count})
        } else {
            dataDict[type] = [{x: date, y: count}]
        }
    })

    // Unify data points that have the same type and date by aggregating the count
    for (const [key, value] of Object.entries(dataDict)) {
        let dict: {[key:string] : number} = {}
        forEach(value, (dataPoint) => {
            if(dataPoint.x in dict){
                dict[dataPoint.x] = dict[dataPoint.x] + dataPoint.y
            } else {
                dict[dataPoint.x] = dataPoint.y
            }
        })
        let data_array: dataPoint[] = []
        for (const [key, value] of Object.entries(dict)) {
            data_array.push({x: key, y: value})
        }
        dataDict[key] = data_array
    }

    let graphData: dataForGraph[] = []

    for (const [key, value] of Object.entries(dataDict)) {
        let data = {
            type: 'bar' as const,
            label: key,
            data: value,
            backgroundColor: colors[key] ?? "#FAC503",
            order: order || 1
        }
        graphData.push(data)
    }

    return {graphData, totalCount}

}

export const UtilizationDataToGraphFormat = (data: [], timeframe:string) => {
    let data_array: { x: string; y: any; }[] = []
    forEach(data, (value: any) => {
        let date:string
        if(timeframe === "weekly"){
            date = getDateFromWeek(value.year, value.week)
                        
        } else {
            date = convertTimestampToDateNoTime(value.date)
        }
        let util = value.util
        data_array.push({x: date, y: util})
    })
    return {
        label: "Utilization",
        data: data_array,
        backgroundColor: "#FAC503",
        type: 'line' as const,
        order: 1

    }
}

export const UserDataIntoRows = (data: [], timePeriods:string[], timeframe:string) => {
    let rows : {[key:string] : {[key:string] : number}} = {}

    forEach(data, (value: any) => {
        let user:string = value.user
        let date:string
        if(timeframe === "weekly"){
            date = getDateFromWeek(value.year, value.week)
                        
        } else {
            date = convertTimestampToDateNoTime(value.date)
        }
        let count = value.count
        if(timePeriods.includes(date)){
            if(!(user in rows)){
                rows[user] = {}
            }
            if(!(date in rows[user])){
                rows[user][date] = count
            } else {    
                rows[user][date] = rows[user][date] + count
            }
        }
    })

    // Check if all time periods are in the rows, if not add them with 0
    forEach(Object.keys(rows), (user) => {
        forEach(timePeriods, (date) => {
            if(!(date in rows[user])){
                rows[user][date] = 0
            }
        })
    })

    // order the count by date
    forEach(Object.keys(rows), (user) => {
        let ordered = Object.keys(rows[user]).sort()
        let orderedDict: {[key:string] : number} = {}
        forEach(ordered, (date) => {
            orderedDict[date] = rows[user][date]
        })
        rows[user] = orderedDict
    })

    return rows
}

export function getDateFromWeek(week: number, year: number): string {
    // Create a date object set to January 1st of the given year
    const firstDayOfYear = new Date(year, 0, 1);
    // Find the day of the week for January 1st (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = firstDayOfYear.getDay();
    
    // Calculate the offset to the first Monday of the year
    const daysUntilFirstMonday = (dayOfWeek === 0) ? 1 : (8 - dayOfWeek);
    
    // Calculate the date of the Monday of the given week
    const daysToAdd = (week - 2) * 7 + daysUntilFirstMonday;
    const mondayOfWeek = new Date(firstDayOfYear);
    mondayOfWeek.setDate(firstDayOfYear.getDate() + daysToAdd);
    // Format the date in "MM/DD/YYYY"
    const humanReadableDate = mondayOfWeek.toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    return humanReadableDate;
}