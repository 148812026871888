import { Transition } from "@headlessui/react"
import { ArrowUturnLeftIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { Fragment, useEffect, useState } from "react"

interface UndoAlertProps {
    message: string | undefined
    onUndo: () => void
    onDismiss: () => void
}

export default function UndoAlert(props: UndoAlertProps) {
    // Holds the progress percentage for the progress bar
    const [timer, setTimer] = useState<number>(0);

    useEffect(() => {
        let dismissTimeout: NodeJS.Timeout | undefined;
    
        if (props.message) {
            setTimer(0);
            dismissTimeout = setTimeout(() => {
                props.onDismiss();
            }, 5000);
            
            // Use a slight timeout (instead of requestAnimationFrame) to ensure the DOM
            // has painted the initial 0% width before transitioning to 100%.
            setTimeout(() => {
                setTimer(100);
            }, 50);
        }
    
        return () => {
            if (dismissTimeout) clearTimeout(dismissTimeout);
        };
    }, [props.message]);
    
    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-x-0 bottom-0 flex items-end justify-center px-4 py-6 sm:p-6 z-[999]"
        >
            <div className="flex w-full max-w-sm items-center justify-center">
                <Transition
                    show={props.message !== undefined}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0"
                    enterTo="translate-y-0 opacity-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                    <ArrowUturnLeftIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-gray-900">
                                        {props.message?.includes('vetrec_') ? 'Undo deleted item' : props.message}
                                    </p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0 gap-x-2">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => {
                                            props.onUndo();
                                            props.onDismiss();
                                        }}
                                    >
                                        Undo
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => {
                                            props.onDismiss();
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='w-full bg-gray-200'>
                            <div
                                className="bg-blue-500 h-[2px]"
                                style={{
                                    width: `${timer}%`,
                                    transition: "width 5s linear"
                                }}
                            />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    )
} 