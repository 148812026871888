import { BoltIcon, BookOpenIcon, DocumentTextIcon, LanguageIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utils/ClassUtils"
import { Spinner } from "../../utils/Spinner";
import { useHistory } from "../../providers/HistoryProvider";
import {isMobileDevice } from "../../utils/deviceUtils";
interface HistoryTabsProps {
    disabled: boolean;
}

export const HistoryTabs: React.FC<HistoryTabsProps> = ({ disabled }) => {
    const {tab, activeNotes, transcriptProcessing, notesProcessing, dischargeProcessing, handleTabChange} = useHistory()
    const isMobile = isMobileDevice()

    const onTabChange = (value: string) => {
        if (!disabled) {
            handleTabChange(value)
        }
    }
    
    return(<div className="">
        <div className="xl:hidden relative">
            <label
            htmlFor="tabs"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
            data-tour-id={isMobile ? "notes-loading" : undefined}
            >
                Section
            </label>
            <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-main-lighter focus:ring-main-lighter"
            value={tab}
            disabled={disabled}
            onChange={(value) => onTabChange(value.target.value)}
            >
                <option key={"overview"} value={"overview"}>Overview</option>
                <option key={"prehistory"} value={"prehistory"}>Records Recap</option>
                <option key={"notes"} value={"notes"}>Notes</option>
                <option key={"discharge"} value={"discharge"}>Client</option>
                <option key={"transcript"} value={"transcript"}>Transcript</option>
            </select>
        </div>
        <div className="hidden xl:block">
            <div className="border-b border-gray-200">
                <nav className={`-mb-px flex flex-row space-x-6 w-full ${disabled ? "pointer-events-none opacity-50" : ""}`} aria-label="Tabs">
                    <div
                        key={"overview"}
                        onClick={() => onTabChange("overview")}
                        className={classNames(
                        tab === "overview"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab === "overview" ? 'page' : undefined}
                    >
                        <BookOpenIcon
                        className={classNames(
                            tab === "overview" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />
                        <span>{"Overview"}</span>
                    </div>
                    <div
                        data-tour-id={"records-recap-tab"}
                        key={"prehistory"}
                        onClick={() => onTabChange("prehistory")}
                        className={classNames(
                        tab === "prehistory"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab === "prehistory" ? 'page' : undefined}
                    >
                        <BoltIcon
                        className={classNames(
                            tab === "prehistory" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />
                        <span>{"Records Recap"}</span>
                    </div>
                    <div
                        data-tour-id={isMobile ? undefined : "notes-loading"}
                        key={"notes"}
                        onClick={() => onTabChange("notes")}
                        className={classNames(
                        tab === "notes"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab === "notes" ? 'page' : undefined}
                    >
                        {(notesProcessing || (transcriptProcessing && !activeNotes)) ? <Spinner size="w-4 h-4 mr-2" timer={false}/> : <DocumentTextIcon
                        className={classNames(
                            tab === "notes" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />}
                        <span>{"Notes"}</span>
                    </div>
                    <div
                        data-tour-id={"client-tab"}
                        onClick={() => onTabChange("discharge")}
                        key={"discharge"}
                        className={classNames(
                        tab === "discharge"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab === "discharge"? 'page' : undefined}
                    >
                        {(dischargeProcessing) ? <Spinner size="w-4 h-4 mr-2" timer={false}/> :   <UserCircleIcon
                        className={classNames(
                            tab === "discharge" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                            '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                        />}
                        <span>{"Client"}</span>
                    </div>
                    <div
                        key={"transcript"}
                        onClick={() => onTabChange("transcript")}
                        className={classNames(
                        tab === "transcript"
                            ? 'border-main-lighter text-main'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                        )}
                        aria-current={tab === "transcript" ? 'page' : undefined}
                    >
                        {transcriptProcessing ? <Spinner size="w-4 h-4 mr-2" timer={false}/> : <LanguageIcon
                            className={classNames(
                                tab === "transcript" ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                                '-ml-0.5 mr-2 h-5 w-5'
                            )}
                            aria-hidden="true"
                        />}
                        <span>{"Transcript"}</span>
                    </div>
                </nav>
            </div>
        </div>
    </div>)
}