export enum UserRole {
    ADMIN = "org:admin",
    MEDICAL_DIRECTOR = "org:medical_director",
    DOCTOR = "org:doctor",
    RELIEF_LOCUM = "org:relief_vet",
    MEMBER = "org:member",
    STUDENT = "org:student",
    CLINIC_HOSPITAL = "org:clinic",
}

export function getStringFromUserRole(role: string | UserRole): string {
    const mapping = {
        [UserRole.ADMIN]: "admin",
        [UserRole.CLINIC_HOSPITAL]: "clinic/hospital",
        [UserRole.MEDICAL_DIRECTOR]: "medical director",
        [UserRole.RELIEF_LOCUM]: "relief locum",
        [UserRole.DOCTOR]: "doctor",
        [UserRole.MEMBER]: "member",
        [UserRole.STUDENT]: "student",
    }

    return mapping[role.toLowerCase() as UserRole];
}

export function isAdminRole(role: string): boolean {
    return [UserRole.ADMIN.toString(), UserRole.MEDICAL_DIRECTOR.toString()].includes(role)
}

export function isDoctorRole(role: string): boolean {
    return [UserRole.RELIEF_LOCUM.toString(), UserRole.MEDICAL_DIRECTOR.toString(), UserRole.DOCTOR.toString()].includes(role)
}

export function getUserRoleFromString(str_array: string[]): UserRole[] {
    const role_array: UserRole[] = [];
    const available_roles = Object.values(UserRole) as string[];
    str_array.forEach((str) => {
        if (available_roles.includes(str)) {
            role_array.push(str as UserRole);
        }
    });

    return role_array
}

const all_roles = "org:all_roles"

export function metadataStringToUserRoleList(createTemplateRolesStr: string): UserRole[] {
    return createTemplateRolesStr === all_roles ? Object.values(UserRole) : createTemplateRolesStr.split(',').map((role) => role as UserRole);
}

export function userRoleListToMetadataString(roles: UserRole[]): string {
    return roles.length === Object.values(UserRole).length ? all_roles : roles.join(',');
}

export const lockedRoleSelections = [UserRole.ADMIN];
