import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

interface SecondaryAction {
  text: string;
  url?: string;      
  navigateTo?: string 
  onClick?: () => void; 
}

interface EndModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onComplete: () => void;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  primaryButtonText?: string;
  secondaryActions?: SecondaryAction[]; 
}

const EndScreen = ({
  isOpen,
  setIsOpen,
  onComplete,
  icon = "🎉",
  title = "That’s a wrap!",
  description = "You’ve successfully completed the tour.",
  primaryButtonText = "Got it!",
  secondaryActions = []
}: EndModalProps) => {
  
  const navigate = useNavigate();
  const handleClose = () => {
    setIsOpen(false);
    onComplete();
  };

  const handleActionClick = (action: SecondaryAction) => {
    if (action.onClick) {
      action.onClick();
    }

    if (action.navigateTo) {
      navigate(action.navigateTo);
      window.location.reload();
    }
    if (action.url) {
      window.open(action.url, '_blank', 'noopener,noreferrer');
    }
    // After performing the action, close the modal and complete the tour
    handleClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[999999]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
            >
              <Dialog.Panel 
                className="relative transform overflow-hidden rounded-lg bg-white px-8 py-10 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
              >
                <div className="flex flex-col items-center space-y-6">
                  <div className="flex items-center justify-center h-16 w-16 rounded-full bg-gradient-to-tr from-blue-600 to-blue-400 text-white text-2xl">
                    {icon}
                  </div>
                  {title && (
                    <Dialog.Title as="h3" className="text-2xl font-bold text-gray-900 text-center">
                      {title}
                    </Dialog.Title>
                  )}
                  {description && (
                    <p className="text-base text-gray-600 text-center">
                      {description}
                    </p>
                  )}
                </div>

                <div className="mt-8 flex flex-col space-y-4">
                  {secondaryActions.map((action, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={() => handleActionClick(action)}
                      className="inline-flex w-full justify-center items-center rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 transition"
                    >
                      {action.text}
                    </button>
                  ))}
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="inline-flex w-full justify-center items-center rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 transition"
                  >
                    {primaryButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EndScreen;
