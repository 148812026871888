import { TemplateObject } from "../../utils/TemplateObject"

export enum IntegrationType {
  NOT_MAPPED = "Not Mapped",
  EZYVET = "EzyVet",
  INSTINCT = "Instinct",
  VETSPIRE = "Vetspire",
}

export enum VetspireTypes {
  ENCOUNTER = "Encounter",
  QUICK_NOTE = "Quick Note",
  ATTACHMENT = "Attachment",
}

export enum QuickNoteTypes {
  NOTE = "note",
  PHONE_CALL = "phone call",
  COMMUNICATION_LOG = "communication log",
}

export enum VetspireAppendBehavior{
  TIMESTAMP = "timestamp",
  NO_TIMESTAMP = "no_timestamp"
}

export enum TemplatePermissions {
  ALL = "all",
  DUPLICATE = "duplicate",
  EDIT_NAME = "edit_name",
  EDIT_INSTRUCTIONS = "edit_instructions",
  EDIT_DEFAULTS = "edit_defaults",
  EDIT_ORDER = "edit_order",
  EDIT_INPUT_TYPE = "edit_input_type",
  EDIT_SECTION_NAMES = "edit_section_names",
  EDIT_ENTRY_NAMES = "edit_entry_names",
  EDIT_INTEGRATIONS = "edit_integrations",
  EDIT_STYLING = "edit_styling",
  EDIT_TEMPLATE_TYPE = "edit_template_type",
}

export enum ContentType {
    PARAGRAPH = "paragraph",
    LIST = "list",
    NUMBER = "number",
    SUBSECTION = "subsection",
    HARDCODED = "hardcoded",
}

export enum SectionTypes {
    NORMAL = "normal",
    LIST = "list",
    PARAGRAPH = "paragraph",
    NUMBER = "number",
    COLLECTION = "collection",
    SIMPLE_LIST = "simple_list",
    HARDCODED = "hardcoded",
    TIMELINE = "timeline",
    DIAGRAM = "diagram",
    SPACES = "spaces",
    GENERAL_INSTRUCTIONS = "general_instructions",
}
  
export interface EntryType{
    entryKey:string
    name:string| undefined
    emptyName?:boolean
    description?:string|undefined
    defaultValue?:string|undefined
    type:ContentType
    hardcodedValue?:string|undefined
    emptyHardcodedValue?:boolean
    subEntries?:EntryType[]
}
  
export interface SectionType{
    sectionKey:string
    type:SectionTypes
    name:string|undefined
    emptyName?:boolean
    entries:EntryType[]
    description?:string|undefined
    defaultValue?:string|undefined
    hardcodedValue?:string|undefined
    emptyHardcodedValue?:boolean
    diagram?:string|undefined
}

export enum EzyVetCategories{
  NO_MAPPING = "No Mapping",
  HISTORY = "history",
  VITALS = "vitals",
  EXAM = "exam",
  ASSESSMENT = "assessment",
  PLAN = "plan",
  REVISIT = "revisit",
  //OWNER_COMMUNICATIONS = "owner communications",
  THERAPEUTICS = "therapeutics",
  PERTINENT_HISTORY = "pertinent history",
  IN_CLINIC_NOTES = "in clinic notes",
}

export enum EzyVetVitalsCategories{
  NO_MAPPING = "No Mapping",
  WEIGHT = "weight",
  HEARTRATE = "heart rate",
  TEMPERATURE = "temperature",
  RESPIRATORYRATE = "respiratory rate",
  BCS = "BCS",
  PAINSCORE = "pain score",
  DENTALSCORE = "dental score",
  ATTITUDE = "attitude",
  CRT = "CRT",
  MM = "MM",
  PULSEQUALITY = "pulse quality",
  RESPIRATORYEFFORT = "respiratory effort",
}

export interface EzyVetSubInput{
  name:string
  inputName:string
}

export interface EzyVetSection{
  name:string
  ezyvet_section_name:string
  category: EzyVetCategories | undefined
  inputs: {[key:string] : EzyVetSubInput}
}

export interface VetspireMapping{
  type:string
  id?:string
}

export interface TemplateStyling{
  title_bold:boolean
  abnormals_bolded:boolean
  show_not_mentioned:boolean
  bullet_points: boolean
  list_bullet_points: boolean
  abnormals_color:boolean
  abnormals_uppercase:boolean
}

export enum TemplateType {
  MEDICAL = "medical",
  COMMUNICATIONS = "communications",
  RECORDSRECAP = "recordsrecap",
}

const templateTypeMetadata = {
  [TemplateType.MEDICAL]: {
    displayName: "Notes",
    owner: "VetRec",
    color: "red",
    defaultTemplateIdKey: "default_template_id",
    fallbackDefaultTemplateId: "dynamic_soap_advanced",
    supportsIntegrations: true,
    stylingMetadata: {
      supportsAbnormals: true,
      notMentionedLabel: "Not Mentioned",
    }
  },
  [TemplateType.COMMUNICATIONS]: {
    displayName: "Client",
    owner: "VetRec_Comms",
    color: "blue",
    defaultTemplateIdKey: "default_discharge_template_id",
    fallbackDefaultTemplateId: "dynamic_simple_discharge",
    supportsIntegrations: false,
    stylingMetadata: {
      supportsAbnormals: false,
      notMentionedLabel: undefined,
    }
  },
  [TemplateType.RECORDSRECAP]: {
    displayName: "Recap",
    owner: "VetRec_RecordsRecap",
    color: "yellow",
    defaultTemplateIdKey: "default_recordsrecap_template_id",
    fallbackDefaultTemplateId: "dynamic_records_recap_basic",
    supportsIntegrations: false,
    stylingMetadata: {
      supportsAbnormals: false,
      notMentionedLabel: "Not Provided",
    }
  }
}

export const getTemplateTypeDisplayName = (templateType: string | TemplateType | undefined) => templateTypeMetadata[templateType as TemplateType]?.displayName

export const getTemplateTypeColor = (templateType: string | TemplateType | undefined) => templateTypeMetadata[templateType as TemplateType]?.color

export const getTemplateTypeDefaultKey = (templateType: string | TemplateType | undefined) => templateTypeMetadata[templateType as TemplateType]?.defaultTemplateIdKey

export const getTemplateTypeFallbackDefault = (templateType: string | TemplateType | undefined) => templateTypeMetadata[templateType as TemplateType]?.fallbackDefaultTemplateId

export const doesTemplateTypeSupportIntegrations = (templateType: string | TemplateType | undefined) => templateTypeMetadata[templateType as TemplateType]?.supportsIntegrations

export const doesTemplateTypeSupportAbnormals = (templateType: string | TemplateType | undefined) => templateTypeMetadata[templateType as TemplateType]?.stylingMetadata.supportsAbnormals

export const getTemplateTypeNotMentionedLabel = (templateType: string | TemplateType | undefined) => templateTypeMetadata[templateType as TemplateType]?.stylingMetadata.notMentionedLabel

export const isVetRecOwner = (owner: string) => Object.values(templateTypeMetadata).some(metadata => metadata.owner === owner)

export function formatKey(key: string, bold: boolean, isEmpty: boolean = false, correspondingHardcodedValueEmpty: boolean = false): string {
  if (isEmpty || key.includes("vetrec_")) {
    return "";
  }
  // Replace double underscores with a placeholder, split by underscore, then capitalize each word
  // Only if it's not an acronym (all uppercase), then join with a space or slash as appropriate
  const formattedKey = key.replace('__', '/').split('_')
    .map(word => word === word.toUpperCase() ? word : word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  // Only add a colon if the corresponding value is not empty
  const suffix = correspondingHardcodedValueEmpty ? "" : ": ";

  if (bold) {
    return `**${formattedKey}**${suffix}`;
  } else {
    return `${formattedKey}${suffix}`;
  }
}

function generateIndent(indent: number = 0, includeBullet:boolean = true){
  let bullet = indent === 0 ? "" : indent === 1 ? "- " : "* ";
  if(!includeBullet) bullet = ""
  const indentSpace = " ".repeat(indent) + bullet;
  return indentSpace
}

function printEntry(entry:EntryType, styling:TemplateStyling, indent:number = 1){
  let textVersion = ""
  if(entry.type === ContentType.PARAGRAPH || entry.type === ContentType.NUMBER){
    textVersion += `${generateIndent(indent, styling.bullet_points)}${formatKey(entry.name ?? "", styling.title_bold) !== '**Problem**: ' ? formatKey(entry.name ?? "", styling.title_bold) : ''}${entry.defaultValue && entry.defaultValue !== "" ? entry.defaultValue : "Sample text, to be replaced by the AI when you generate notes."}\n`
  } else if (entry.type === ContentType.LIST){
    textVersion += `${generateIndent(indent, styling.bullet_points)}${formatKey(entry.name ?? "", styling.title_bold)}\n`
    textVersion += `${" ".repeat(2)}${generateIndent(2, styling.list_bullet_points).replace('-', '*')} ${"Sample text, to be replaced by the AI when you generate notes."}\n`;
  } else if (entry.type === ContentType.HARDCODED){
    const entryNameIsEmpty = (entry.name ?? "").trim() === "" || entry.name?.includes("vetrec_");
    const hardcodedValueIsEmpty = (entry.hardcodedValue ?? "").trim() === "" || entry.hardcodedValue?.includes("vetrec_");
    textVersion += `${generateIndent(indent, styling.bullet_points)}${formatKey(entry.name ?? "", styling.title_bold) !== '**Problem**: ' ? formatKey(entry.name ?? "", styling.title_bold, entryNameIsEmpty || entry.emptyName, hardcodedValueIsEmpty) : ''}${entry.hardcodedValue && entry.hardcodedValue !== "" ? entry.hardcodedValue : "Sample text, to be replaced by the AI when you generate notes."}\n`
  } else if (entry.type === ContentType.SUBSECTION){
    textVersion += `${generateIndent(indent, styling.bullet_points)}${formatKey(entry.name ?? "", styling.title_bold)}\n`
    if(entry.subEntries !== undefined){
      entry.subEntries.forEach(subEntry => {
        textVersion += printEntry(subEntry, styling, 2)
      })
    }
  }
  return textVersion
}
  
export function jsonToText(data: SectionType[], styling:TemplateStyling): string {
    let textVersion = "";

    data.forEach(((section) => {
      const sectionNameIsEmpty = (section.name ?? "").trim() === "" || section.name?.includes("vetrec_");
      const hardcodedValueIsEmpty = (section.hardcodedValue ?? "").trim() === "" || section.hardcodedValue?.includes("vetrec_");
      const formattedKey = formatKey(section.name ?? "", styling.title_bold, section.emptyName, section.type === SectionTypes.HARDCODED ? hardcodedValueIsEmpty : false);
      if(section.type === SectionTypes.LIST){
        textVersion += `${formattedKey}\n`;
        section.entries.forEach(entry => {
          textVersion += printEntry(entry, styling)
        })
        textVersion += `\n`
        section.entries.forEach(entry => {
          textVersion += printEntry(entry, styling)
        })
      }
      else if(section.type === SectionTypes.SIMPLE_LIST){
        textVersion += `${formattedKey}\n`;
        textVersion += `- ${"Sample text, to be replaced by the AI when you generate notes."}\n`;
      }
      else if(section.type === SectionTypes.PARAGRAPH || section.type === SectionTypes.NUMBER){
        textVersion += `${formattedKey}`;
        textVersion += `${"Sample text, to be replaced by the AI when you generate notes."}\n`
      }
      else if (section.type === SectionTypes.HARDCODED){
        textVersion += `${formattedKey}`;
        textVersion += `${section.hardcodedValue && section.hardcodedValue !== "" ? section.hardcodedValue : "Sample text, to be replaced by the AI when you generate notes."}\n`
      }
      else if (section.type === SectionTypes.SPACES){
        const numSpaces = parseInt(section.hardcodedValue ?? "1");
        textVersion += `${"\n".repeat(numSpaces)}`;
      }
      else if (section.type === SectionTypes.TIMELINE){
        if (!sectionNameIsEmpty) {
          ["11/14/2024", "4/23/2023", "12/22/2021"].forEach((date) => {
            const sectionName = section.name!!
            textVersion += `${formattedKey.replace(sectionName, date)}\n`;  
            section.entries.forEach(entry => {
              textVersion += printEntry(entry, styling, 2)
            })  
            textVersion += `\n`
          })
        }
      }
      else{
        textVersion += `${formattedKey}\n`;
        section.entries.forEach(entry => {
          textVersion += printEntry(entry, styling)
        })
      }
      textVersion += `\n`
    }));
  
    return textVersion;
}

function categoryToInputName(category:EzyVetCategories){
  switch(category){
    case EzyVetCategories.HISTORY:
      return "visithistorydata_comments"
    case EzyVetCategories.ASSESSMENT:
      return "consultassessmentdata_notes"
    case EzyVetCategories.EXAM:
      return "visitexamdata_comments"
    case EzyVetCategories.PLAN:
      return "consultplandata_notes"
    case EzyVetCategories.THERAPEUTICS:
      return "plantherapeuticdata_specifics"
    case EzyVetCategories.PERTINENT_HISTORY:
      return "consultpertinenthistorydata_notes"
    case EzyVetCategories.IN_CLINIC_NOTES:
      return "inclinicdata_notes"
    //case EzyVetCategories.OWNER_COMMUNICATIONS:
      // return ""
    case EzyVetCategories.REVISIT:
      return "revisitdata_notes"
  }
}

function inputNameToCategory(inputName: string): EzyVetCategories {
  switch(inputName) {
      case "visithistorydata_comments":
          return EzyVetCategories.HISTORY;
      case "consultassessmentdata_notes":
          return EzyVetCategories.ASSESSMENT;
      case "visitexamdata_comments":
          return EzyVetCategories.EXAM;
      case "consultplandata_notes":
          return EzyVetCategories.PLAN;
      //case "owner_communications":
          //return EzyVetCategories.OWNER_COMMUNICATIONS;
      case "consultpertinenthistorydata_notes":
          return EzyVetCategories.PERTINENT_HISTORY;
      case "inclinicdata_notes":
          return EzyVetCategories.IN_CLINIC_NOTES;
      case "plantherapeuticdata_specifics":
          return EzyVetCategories.THERAPEUTICS;
      case "revisitdata_notes":
          return EzyVetCategories.REVISIT;
      default:
          // Optionally handle unknown input names
          return EzyVetCategories.HISTORY;
  }
}

export function saveInstinctMapping(mapping:{[key:string]:EzyVetSection}) {
  let processed_mapping: {[key:string] : {[key:string] : string}} = {}
  Object.keys(mapping).forEach((key) => {
    processed_mapping[key.toLowerCase()] = {
      type:"one-to-one",
      button: "",
      addButton: "",
      inputType: "textarea",
      inputName: `${mapping[key].ezyvet_section_name}`,
      inputFormat: "string"
    }
  })
  return processed_mapping

}

export function saveEzyVetMapping(mapping:{[key:string]:EzyVetSection}){
  let processed_mapping: {[key:string] : {[key:string] : string | {[key:string] : {[key:string] : string }}}} = {}
  Object.keys(mapping).forEach((key) => {
    if(mapping[key].category !== EzyVetCategories.VITALS){
      processed_mapping[key.toLowerCase()] = {
        type:"one-to-one",
        displayName: mapping[key].ezyvet_section_name,
        button: `Add${mapping[key].ezyvet_section_name.replace(/[^a-zA-Z0-9]/g, '')}`,
        addButton: "Add",
        inputType: "textarea",
        inputName: categoryToInputName(mapping[key].category ?? EzyVetCategories.HISTORY) ?? "",
        inputFormat: "string"
      }
    }
    else{
      processed_mapping[key.toLowerCase()] = {
        type:"one-to-many",
        displayName: mapping[key].ezyvet_section_name,
        button: `Add${mapping[key].ezyvet_section_name.replace(/[^a-zA-Z0-9]/g, '')}`,
        addButton: "Add",
        inputName: "vitals",
        inputs: saveEzyVetInputs(mapping[key].inputs)
      }
    }
  })
  return processed_mapping
}

export function saveEzyVetInputs(inputs:{[key:string]:EzyVetSubInput}){
  let processed_inputs: {[key:string]:{[key:string]:string}} = {}
  Object.keys(inputs).forEach((key) => {
    let inputFormat = "string"
    let inputType = "input"
    if([EzyVetVitalsCategories.WEIGHT, EzyVetVitalsCategories.TEMPERATURE, EzyVetVitalsCategories.HEARTRATE, EzyVetVitalsCategories.RESPIRATORYRATE, EzyVetVitalsCategories.BCS, EzyVetVitalsCategories.PAINSCORE, EzyVetVitalsCategories.DENTALSCORE].includes(inputs[key].inputName as EzyVetVitalsCategories)){
      inputFormat = "number"
    }

    if([EzyVetVitalsCategories.ATTITUDE, EzyVetVitalsCategories.CRT, EzyVetVitalsCategories.MM, EzyVetVitalsCategories.RESPIRATORYEFFORT, EzyVetVitalsCategories.PULSEQUALITY ].includes(inputs[key].inputName as EzyVetVitalsCategories)){
      inputType = "select"
    }

    processed_inputs[key.toLowerCase()] = {
      inputType: inputType,
      inputFormat: inputFormat,
      inputName: `healthstatusdata_${inputs[key].inputName.replace(" ", "").toLowerCase()}`
    }
  })
  return processed_inputs
}

export function instinctMappingToObject(mapping: { [key: string]: { [key: string]: string | { [key: string]: string } } }) {
  let processed_mapping: { [key: string]: EzyVetSection } = {};
  Object.keys(mapping).forEach((key) => {
      const sectionNameVlaue = mapping[key]['inputName']
      const sectionName = typeof sectionNameVlaue === 'string' ? sectionNameVlaue : ''

      processed_mapping[key.toLowerCase()] = {
          name: key,
          ezyvet_section_name: sectionName,
          category: undefined,
          inputs: {}
      };
  });
  return processed_mapping;
}

export function ezyVetMappingToObject(mapping: { [key: string]: { [key: string]: string | { [key: string]: string } } }) {
  let processed_mapping: { [key: string]: EzyVetSection } = {};
  Object.keys(mapping).forEach((key) => {
      // Check if inputName is a string, otherwise use an empty string or handle accordingly
      const inputNameValue = mapping[key]['inputName'];
      const inputName = typeof inputNameValue === 'string' ? inputNameValue : '';
      let category = inputNameToCategory(inputName);  // This will now only be called with a string
      if(mapping[key]['type'] === "one-to-many"){
        category = EzyVetCategories.VITALS
      }

      const displayNameValue = mapping[key]["displayName"]
      const displayName = typeof displayNameValue === 'string' ? displayNameValue : undefined

      const sectionNameVlaue = mapping[key]['button']
      const sectionName = typeof sectionNameVlaue === 'string' ? sectionNameVlaue.replace("Add", "") : ''

      processed_mapping[key.toLowerCase()] = {
          name: key,
          ezyvet_section_name: displayName ?? sectionName,
          category: category,
          inputs: ezyVetInputsToObject(mapping[key]['inputs'])
      };
  });
  return processed_mapping;
}

export function ezyVetInputsToObject(inputs:any){
  let processed_inputs: {[key:string]:EzyVetSubInput} = {}
  if(inputs){
    Object.keys(inputs).forEach((key) => {
      processed_inputs[key.toLowerCase()] = {
        name: key,
        inputName: EzyVetVitalsCategories[(inputs[key]['inputName'].replace("healthstatusdata_", "").replace("data_", "").replace(" ", "_").toUpperCase()) as keyof typeof EzyVetVitalsCategories]
      }
    })
  }
  return processed_inputs
}

export function normalizeSectionOrEntryName(sectionName: string): string {
  return sectionName.trim().toLowerCase().replace(" ", "_")
    .replace("/", "_")
    .replace("?","")
    .replace("&","")
    .replace("(", "")
    .replace(")", "")
    .replace(",", "") 
    .replace("'", "")
    .replace(":","")
}

export function checkForDuplicateNames(sections: SectionType[]): [string, boolean] {
  const sectionSet = new Set<string>();

  // Function to process each entry and its subentries recursively
  // Checks to make sure entries don't share names with sections
  function processEntries(normalizedSectionName: string, entries: EntryType[], levelTitle: string): [string, boolean] {
    for (const entry of entries) {
      if (entry.name) {
        if (normalizedSectionName === normalizeSectionOrEntryName(entry.name)) {
          return [`Sections shouldn't have the same name as any ${levelTitle} within. "${entry.name}" is the name of a section and ${levelTitle}.`, false];
        }
      }
      if (entry.type === ContentType.SUBSECTION && entry.subEntries) {
        const [reason, result] = processEntries(normalizedSectionName, entry.subEntries, "sub-entry");
        if (!result) {
          return [reason, result];
        }
      }
    }

    return ["", true];
  }

  for (const section of sections) {
    if (section.name) {
      const normalizedSectionName = normalizeSectionOrEntryName(section.name);
      if (sectionSet.has(normalizedSectionName)) {
        return [`Please ensure all sections have unique names. "${section.name}" is repeated multiple times.`, false];
      }
      
      sectionSet.add(normalizedSectionName);
      if ([SectionTypes.NORMAL, SectionTypes.LIST, SectionTypes.COLLECTION].includes(section.type)) {
        const [reason, result] = processEntries(normalizedSectionName, section.entries, "entry")
        if (!result) {
          return [reason, result];
        }
      }  
    }
  }

  return ["", true];  // Return true if no duplicates are found
}

export const communicationTemplateTones = [
  {
    name: "Casual",
    value: "casual",
    description:"Communications will be informal and friendly. Using layman terms."
  },
  {
    name: "Professional",
    value: "professional",
    description:"Communications will be formal and professional. Using layman terms."
  },
  {
    name: "Technical",
    value: "technical",
    description:"Communications will be formal and professional. Using medical terms and jargon."
  }
]

export function showDeleteTemplateOption(template:TemplateObject, user_email:string | undefined){
  const isLocked = (template?.edit_locked_to_owner ?? false) && template.owner !== user_email
  const ownedByVetRec = isVetRecOwner(template.owner)
  return !isLocked && !ownedByVetRec
}