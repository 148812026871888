import React from 'react';
import { Team } from '../types/user';

interface TeamBadgeProps {
  team: Team;
  className?: string;
  truncateLength?: number;
}
const TeamBadge: React.FC<TeamBadgeProps> = ({ team, className = '', truncateLength = 15 }) => {
  // Limit team name to 15 characters
  const displayName = team.name.length > truncateLength 
    ? `${team.name.substring(0, truncateLength)}...` 
    : team.name;
    
  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 ${className}`} title={team.name}>
      {displayName}
    </span>
  );
};

export default TeamBadge;
