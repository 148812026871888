import { Switch } from "@headlessui/react"
import { useEffect, useState } from "react";
import TemplatePicker from "../templates/TemplatePicker";
import { TemplateObject } from "../../utils/TemplateObject";
import { updateOrganizationMetadata } from "../../methods/clerk";
import { useAuth, useOrganization } from "@clerk/clerk-react";
import Reminder from "../ReminderToast";
import EnumPicker from "../EnumPicker";
import { CopyBehavior, getCopyBehaviorFromString } from "./copyBehavior";
import { useVetRec } from "../../providers/VetRecProvider";
import { VetspireAppendBehavior, VetspireTypes } from "../templates/TemplateUtils";
import EnumMultiPicker from "../EnumMultiPicker";
import { getStringFromUserRole, getUserRoleFromString, lockedRoleSelections, UserRole, userRoleListToMetadataString } from "./UserRoles";
import { capitalize } from "lodash";

export const OrganizationControlsPage = () => {
    const {getToken, orgId} = useAuth();
    const { organization } = useOrganization()
    const { pims, createTemplateEnabledRoles } = useVetRec()
    const [prebuiltEnabled, setPrebuiltEnabled] = useState<boolean>(true);
    const [template, setTemplate] = useState<TemplateObject>();
    const [selectedCreateTemplateEnabledRoles, setSelectedCreateTemplateEnabledRoles] = useState<UserRole[]>([]);
    const [defaultTemplateEnabled, setDefaultTemplateEnabled] = useState<boolean>(false);
    const [copyBehavior, setCopyBehavior] = useState<CopyBehavior>(CopyBehavior.plain);
    const [recordAsEnabled, setRecordAsEnabled] = useState<boolean>(true);
    const [defaultVetspireBehavior, updateVetspireBehavior] = useState(VetspireTypes.ENCOUNTER);
    const [createEncounterEnabled, setCreateEncounterEnabled] = useState<boolean>(true);
    const [vetspireLocationId, setVetspireLocationId] = useState<string>();
    const [appendBehavior, setAppendBehavior] = useState<VetspireAppendBehavior>(VetspireAppendBehavior.NO_TIMESTAMP);
    const [saved, setSaved] = useState(false);
    const [savedText, setSavedText] = useState<string>("")

    useEffect(() => {
        setPrebuiltEnabled(organization?.publicMetadata['prebuiltEnabled'] as boolean ?? true);
        setSelectedCreateTemplateEnabledRoles(createTemplateEnabledRoles)
        setDefaultTemplateEnabled(organization?.publicMetadata['default_template_id'] as boolean ?? false);
        setCopyBehavior(getCopyBehaviorFromString(organization?.publicMetadata['copyBehavior'] as string ?? "plain"));
        setRecordAsEnabled(organization?.publicMetadata['recordAsEnabled'] as boolean ?? true);
        updateVetspireBehavior(organization?.publicMetadata['defaultVetspireBehavior'] as VetspireTypes ?? VetspireTypes.ENCOUNTER);
        setCreateEncounterEnabled(organization?.publicMetadata['createEncounterEnabled'] as boolean ?? true);
        setVetspireLocationId(organization?.publicMetadata['external_location_id'] as string ?? undefined);
        setAppendBehavior(organization?.publicMetadata['appendBehavior'] as VetspireAppendBehavior ?? VetspireAppendBehavior.NO_TIMESTAMP
        );
    }, [orgId, createTemplateEnabledRoles]);

    const handlePrebuiltChange = async (value:boolean) => {
        setPrebuiltEnabled(value);
    };

    const handleMembersCreateEditChange = async (value:string[]) => {
        const roles = getUserRoleFromString(value)
        const combinedRoles = Array.from(new Set([...roles, ...lockedRoleSelections]));
        setSelectedCreateTemplateEnabledRoles(combinedRoles);
    }

    const handleTemplateDefaultChange = async (value:boolean) => {
        setDefaultTemplateEnabled(value);
    }

    const handleTemplateChange = async (value:TemplateObject) => {
        setTemplate(value);
    }

    const handleCopyBehaviorChange = async (value:string) => {
        setCopyBehavior(getCopyBehaviorFromString(value))
    }

    const handleRecordAsChange = async (value:boolean) => {
        setRecordAsEnabled(value)
    }

    const handleSaveControls = async () => {
        setSavedText("Saving settings...");
        setSaved(true);
        const templateCreateEditRoles = userRoleListToMetadataString(selectedCreateTemplateEnabledRoles)
        await updateOrganizationMetadata(
            { 
                prebuiltEnabled: prebuiltEnabled,
                createTemplateEnabledRoles: templateCreateEditRoles,
                editTemplateEnabledRoles: templateCreateEditRoles,
                default_template_id: defaultTemplateEnabled && template?.organization && template?.id ? template.organization + "/" + template.id : null,
                copyBehavior: copyBehavior,
                recordAsEnabled: recordAsEnabled,
                defaultVetspireBehavior: defaultVetspireBehavior,
                createEncounterEnabled: createEncounterEnabled,
                external_location_id: vetspireLocationId,
                appendBehavior: appendBehavior
            },
            "update_organization_controls",
            (await getToken({ template: "supabase" })) ?? ""
        );
        setSavedText("Settings saved!");
    }

    return (<>
        <div className="w-full flex flex-row justify-between items-center p-4 bg-white border-b border-gray-300">
            <div className="flex flex-col gap-y-4">
                <h1 className="text-3xl font-semibold">Team Controls</h1>
                <div>Configure your team's options.</div>
            </div>
            <button className="bg-blue-600 px-4 py-2 text-white rounded-md" onClick={() => handleSaveControls()}>Save</button>
        </div>
        <div className="relative flex flex-col gap-y-4 h-[55vh] overflow-y-auto thin-scrollbar pb-28">
            <div className="text-xl font-semibold mt-4">
                Recording Configurations:
            </div>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Allow members to record as other members</div>
                        <div className="text-sm text-gray-600">
                            Configure if members can record notes as other members in VetRec. If disabled, only admins can record as other members.
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-4 items-center">
                        <Switch
                        checked={recordAsEnabled}
                        onChange={handleRecordAsChange}
                        className={`${
                            recordAsEnabled ? 'bg-blue-600' : 'bg-gray-300'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                            <span className="sr-only">Can members record notes as other members?</span>
                            <span
                                className={`${
                                    recordAsEnabled ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
            <div className="text-xl font-semibold mt-4">
                Note Configurations:
            </div>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Copy Behavior:</div>
                        <div className="text-sm text-gray-600">
                            Choose between copying notes as <span className="p-1 border bg-gray-600 rounded-md text-white">plain</span> text or <span className="p-1 border bg-gray-600 rounded-md text-white">**markdown**</span>
                        </div>
                    </div>
                    <div className="flex flex-row gap-x-4 items-center">
                        <EnumPicker enum={CopyBehavior} selectedOption={copyBehavior} setSelectedOption={(value) => handleCopyBehaviorChange(value)} width="w-60"/>
                    </div>
                </div>
            </div>
            <div className="text-xl font-semibold mt-4">
                Template Configurations:
            </div>
            <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Pre-built Templates:</div>
                        <div className="text-sm text-gray-600">
                            Configure whether pre-built templates are enabled for your team. If disabled, pre-built templates won't be shown unless added.
                        </div>
                    </div>
                    <Switch
                    checked={prebuiltEnabled}
                    onChange={handlePrebuiltChange}
                    className={`${
                        prebuiltEnabled ? 'bg-blue-600' : 'bg-gray-300'
                    } relative inline-flex h-6 w-11 items-center rounded-full flex-shrink-0`}
                    >
                        <span className="sr-only">Are pre-built templates enabled?</span>
                        <span
                            className={`${
                            prebuiltEnabled ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                    </Switch>
                </div>
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Who can create and edit templates?</div>
                        <div className="text-sm text-gray-600">
                            Configure which team members can create and edit templates in VetRec.
                        </div>
                    </div>
                    <EnumMultiPicker 
                        enum={UserRole}
                        selectedOptions={selectedCreateTemplateEnabledRoles} 
                        setSelectedOptions={(value) => handleMembersCreateEditChange(value)} 
                        lockedSelections={lockedRoleSelections}
                        allSelectedText="All users"
                        width="w-60"
                        enumToDisplayName={getStringFromUserRole}
                    />
                </div>
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Default Template:</div>
                        <div className="text-sm text-gray-600">
                            Configure the default notes template for all your team members.
                        </div>
                        {defaultTemplateEnabled && <div className="text-xs text-red-600">
                            If a user selects their own default template, VetRec will use that for them instead.
                        </div>}
                    </div>
                    <div className="flex flex-row gap-x-4 items-center">
                        <Switch
                        checked={defaultTemplateEnabled}
                        onChange={handleTemplateDefaultChange}
                        className={`${
                            defaultTemplateEnabled ? 'bg-blue-600' : 'bg-gray-300'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                            <span className="sr-only">Enable default template for team?</span>
                            <span
                                className={`${
                                    defaultTemplateEnabled ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </Switch>
                        {defaultTemplateEnabled && <TemplatePicker template={template} templateId_force={(organization?.publicMetadata['default_template_id'] as string)} setTemplate={(template) => handleTemplateChange(template)} className="" templateBackground="bg-gray-100 rounded-md" pickerDirection="up" onlyShowOrg={true}/>}
                    </div>
                </div>
            </div>
            {pims.includes('vetspire') && <div className="text-xl font-semibold mt-4">
                Vetspire Configurations:
            </div>}
            {pims.includes('vetspire') && <div className="flex flex-col gap-y-6">
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Vetspire Location ID</div>
                        <div className="text-sm text-gray-600">
                            Configure the location ID for Vetspire. This can be found under Locations in Vetspire settings. By default, not location is used.
                        </div>
                    </div>
                    <input type="text" value={vetspireLocationId} onChange={(e) => setVetspireLocationId(e.target.value)} className="w-60 p-2 border border-gray-300 rounded-md h-8" placeholder="Example: 123123"/>
                </div>
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Default Export Behavior:</div>
                        <div className="text-sm text-gray-600">
                            Configure the default export behavior for templates. Can be changed on a per-template basis. Options: Encounter, Quick Note or Attachment.
                        </div>
                    </div>
                    <EnumPicker 
                        width="w-60"
                        enum={VetspireTypes} 
                        selectedOption={defaultVetspireBehavior} 
                        setSelectedOption={(value) => {
                            updateVetspireBehavior(Object.values(VetspireTypes).find((enumValue) => enumValue === value) ?? VetspireTypes.ENCOUNTER)
                        }}
                    />
                </div>
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Allow to create new encounters:</div>
                        <div className="text-sm text-gray-600">
                            Can VetRec create new encounters in Vetspire when no encounter ID is provided during export? Disabling this option will require users to provide an encounter ID.
                        </div>
                    </div>
                    <div className="text-sm text-gray-600">
                        <Switch
                            checked={createEncounterEnabled}
                            onChange={setCreateEncounterEnabled}
                            className={`${
                                createEncounterEnabled ? 'bg-blue-600' : 'bg-gray-300'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                            <span className="sr-only">Can new VetRec create new ecounters?</span>
                            <span
                                className={`${
                                    createEncounterEnabled ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </Switch>
                    </div>
                </div>
                <div className="flex flex-row gap-x-4 items-center justify-between border border-gray-300 bg-gray-100 rounded-md p-4">
                    <div className="flex flex-col">
                        <div className="font-semibold">Append Behavior:</div>
                        <div className="text-sm text-gray-600">
                            Should VetRec add a timestamp when appending to a Vetspire field that already has information?
                        </div>
                    </div>
                    <div className="text-sm text-gray-600">
                        <EnumPicker 
                            width="w-60"
                            enum={VetspireAppendBehavior} 
                            selectedOption={appendBehavior} 
                            setSelectedOption={(value) => {
                                setAppendBehavior(Object.values(VetspireAppendBehavior).find((enumValue) => enumValue === value) ?? VetspireAppendBehavior.NO_TIMESTAMP)
                            }}
                            enumToDisplayName={(value) => capitalize(value.replace("_", " "))}
                        />
                    </div>
                </div>
            </div>}
        </div>
        <Reminder show={saved} hide={() => setSaved(false)} title={savedText} text=""/>
        </>
    )
}