import { ArrowsRightLeftIcon, BoldIcon, BookOpenIcon, HandThumbDownIcon, ItalicIcon, UnderlineIcon } from '@heroicons/react/24/solid';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactQuill from 'react-quill';
import { DeltaStatic, Sources } from 'quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

// Define prop types for the component
interface QuillEditorWithToolbarProps {
  value: string;
  onChange: (value: string, delta: DeltaStatic, source: Sources) => void;
  readOnly?: boolean | undefined;
  theme?: string | undefined;
  placeholder?: string | undefined;
  className?: string | undefined;
  modules?: object | undefined;
  onChangeSelection?: (range: { index: number; length: number } | null, source: string, editor: ReactQuill.UnprivilegedEditor) => void;
  onAddCustomSpellingPressed?: () => void;
  onThumbsDownPressed?: () => void;
  onFindReplacePressed?: () => void;
  onStylingChanged?: () => void;
}

const QuillEditorWithToolbar: React.FC<QuillEditorWithToolbarProps> = ({
  value,
  onChange,
  readOnly,
  onAddCustomSpellingPressed,
  onThumbsDownPressed,
  onFindReplacePressed,
  onStylingChanged,
  onChangeSelection,
  theme,
  placeholder,
  className,
  modules
}) => {
  const [showToolbar, setShowToolbar] = useState(false); // Toolbar visibility state
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 }); // Toolbar position state
  const [toolbarOverflow, setToolbarOverflow] = useState(0); // Toolbar overflow state
  const quillRef = useRef<ReactQuill | null>(null); // Ref to access Quill editor
  const toolbarRef = useRef<HTMLDivElement | null>(null); // Ref to access the toolbar div

  // Handle text selection
  const handleSelectionChange = (range: { index: number; length: number } | null, source: string, editor: ReactQuill.UnprivilegedEditor) => {
    if (range && range.length > 0) {
      setShowToolbar(true); // Show toolbar when text is selected
    } else {
      setShowToolbar(false); // Hide toolbar when selection is cleared
    }

    if (onChangeSelection) onChangeSelection(range, source, editor)
  };

  useEffect(() => {
    if (showToolbar) {
      setToolbarOverflow(0);

      // Get selected range from editor
      const editor = quillRef?.current?.getEditor(); // Get Quill editor instance
      if (!editor) return;

      const range = editor.getSelection();

      if (range && range.length > 0) {
        // Get bounds of the selected range
        const bounds = editor.getBounds(range.index, range.length); // Get the bounding box of the selection
        
        // Get toolbar height and width
        const toolbarHeight = toolbarRef?.current?.offsetHeight || 56; // Get toolbar height from ref or default to 56px
        const toolbarWidth = toolbarRef?.current?.offsetWidth || 216; // Get toolbar width from ref or default to 216px

        // Padding between toolbar and the highlighted text
        const padding = 8

        // Calculate positions and set
        let topPosition = bounds.top - toolbarHeight - padding;
        
        // Check if toolbar would go above the editor
        if (topPosition < 0) {
          topPosition = bounds.bottom + padding;
          
          // Check if toolbar would go below the editor
          const editorHeight = editor.root.parentElement?.offsetHeight || 0;
          if (topPosition + toolbarHeight > editorHeight) {
            setToolbarOverflow(topPosition + toolbarHeight - editorHeight);
          }
        }

        let leftPosition = bounds.left;
        const editorContainerWidth = editor.root.parentElement?.offsetWidth || 0;
        if (leftPosition + toolbarWidth > editorContainerWidth) {
          leftPosition = bounds.left - toolbarWidth;
        }

        setToolbarPosition({
          top: topPosition,
          left: leftPosition,
        });
      }
    }
  }, [showToolbar]);

  // Action handlers for toolbar buttons
  const applyBold = () => {
    const editor = quillRef.current?.getEditor();
    const isBold = editor?.getFormat().bold;
    editor?.format('bold', !isBold);
    if (onStylingChanged) onStylingChanged();
  };

  const applyItalic = () => {
    const editor = quillRef.current?.getEditor();
    const isItalic = editor?.getFormat().italic;
    editor?.format('italic', !isItalic);
    if (onStylingChanged) onStylingChanged();
  };

  const applyUnderline = () => {
    const editor = quillRef.current?.getEditor();
    const isUnderline = editor?.getFormat().underline;
    editor?.format('underline', !isUnderline);
    if (onStylingChanged) onStylingChanged();
  };

  const marginStyle = useMemo(() => {
    const overflow = Math.max(0, Math.min(10, Math.ceil(toolbarOverflow / 4)));
    return (showToolbar && toolbarOverflow > 0) ? `mb-${overflow}` : ""
  }, [showToolbar, toolbarOverflow]);

  return (
    <div className={`relative w-full ${marginStyle}`}>
      <ReactQuill
        theme={theme}
        placeholder={placeholder}
        className={className}
        modules={modules}
        readOnly={readOnly}
        ref={quillRef}
        value={value}
        onChange={(value: string, delta: DeltaStatic, source: Sources) => onChange(value, delta, source)}
        onChangeSelection={handleSelectionChange}
      />

      {/* Custom toolbar positioned above the selected text */}
      {showToolbar && (
        <div
          ref={toolbarRef}
          className="absolute bg-blue-100 p-2 rounded-lg flex space-x-2 min-h-12 shadow"
          style={{
            top: `${toolbarPosition.top}px`,
            left: `${toolbarPosition.left}px`,
          }}
        >
          <button
            className="px-2 py-2 bg-white hover:bg-gray-100 text-gray-900 rounded shadow-sm ring-1 ring-inset ring-gray-300"
            onClick={applyBold}
            title="Bold"
          >
            <BoldIcon className="h-4 w-4"/>
          </button>
          <button
            className="px-2 py-2 bg-white hover:bg-gray-100 text-gray-900 rounded shadow-sm ring-1 ring-inset ring-gray-300"
            onClick={applyItalic}
            title="Italics"
          >
            <ItalicIcon className="h-4 w-4"/>
          </button>
          <button
            className="px-2 py-2 bg-white hover:bg-gray-100 text-gray-900 rounded shadow-sm ring-1 ring-inset ring-gray-300"
            onClick={applyUnderline}
            title="Underline"
          >
            <UnderlineIcon className="h-4 w-4"/>
          </button>
          <div className="w-px bg-gray-300 mx-2"></div>
          {onAddCustomSpellingPressed && <button
            className="px-2 py-2 bg-main-button hover:bg-main-button-hover text-main-button-text rounded shadow-sm"
            onClick={onAddCustomSpellingPressed}
            title="Add Custom Vocabulary"
          >
            <BookOpenIcon className="h-4 w-4 text-white"/>
          </button>}
          {onFindReplacePressed && <button
            className="px-2 py-2 bg-main-button hover:bg-main-button-hover text-main-button-text rounded shadow-sm"
            onClick={onFindReplacePressed}
            title="Find and replace"
          >
            <ArrowsRightLeftIcon className="h-4 w-4 text-white"/>
          </button>}
          {onThumbsDownPressed && <button
            className="px-2 py-2 bg-main-button hover:bg-main-button-hover text-main-button-text rounded shadow-sm"
            onClick={onThumbsDownPressed}
            title="Provide negative feedback on selected text"
          >
            <HandThumbDownIcon className="h-4 w-4 text-white"/>
          </button>}
        </div>
      )}
    </div>
  );
};

export default QuillEditorWithToolbar;
