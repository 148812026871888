import { FC, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

interface UnsavedChangesBannerProps {
  show: boolean;
  onSave: () => void;
  onDismiss: () => void;
  top?: boolean;
  isSaving?: boolean;
}

const UnsavedChangesBanner: FC<UnsavedChangesBannerProps> = ({ 
  show, 
  onSave, 
  onDismiss, 
  top = true,
  isSaving = false 
}) => {
  return (
    <div aria-live="polite" className={`${top ? "fixed inset-x-0 top-0 " : ""} flex justify-center z-50`}>
      <Transition
        show={show}
        as={Fragment}
        enter="transform ease-out duration-300"
        enterFrom="translate-y-2 opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="bg-yellow-200 border border-yellow-300 shadow-lg rounded-md p-4 mx-4 mt-4 flex items-center justify-between w-full max-w-md gap-x-4">
          <div className="flex items-center gap-2">
            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" />
            <span className="text-sm font-medium text-yellow-800">You have unsaved changes.</span>
          </div>
          <div className="flex items-center gap-2">
            <button 
              onClick={onSave} 
              disabled={isSaving}
              className={`px-3 py-1 ${isSaving ? 'bg-blue-400' : 'bg-blue-600 hover:bg-blue-700'} text-white rounded-md text-sm flex items-center gap-1 transition-colors`}
            >
              {isSaving && (
                <svg className="animate-spin -ml-1 mr-1 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              )}
              {isSaving ? 'Saving...' : 'Save Now'}
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default UnsavedChangesBanner;