import React from 'react';
import { HeaderMapping } from './types';

interface MappingSectionProps {
    csvHeaders: string[];
    headerMapping: HeaderMapping;
    handleMappingChange: (field: string, headerValue: string) => void;
    handleConfirmMapping: () => void;
    handleCancelCsv: () => void;
    fileType: 'csv' | 'xlsx' | null;
}

const MappingSection: React.FC<MappingSectionProps> = ({
    csvHeaders,
    headerMapping,
    handleMappingChange,
    handleConfirmMapping,
    handleCancelCsv,
    fileType
}) => {
    return (
        <div>
            <h3 className="font-medium text-lg mb-4">Map CSV Columns</h3>
            <p className="text-sm text-gray-500 mb-4">
                Please map the columns from your {fileType === 'xlsx' ? 'Excel' : 'CSV'} file to the required fields
            </p>

            <div className="space-y-4 mb-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email *
                    </label>
                    <select
                        value={headerMapping.email || ''}
                        onChange={(e) => handleMappingChange('email', e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
                        required
                    >
                        <option value="">Select column</option>
                        {csvHeaders.map(header => (
                            <option key={header} value={header}>
                                {header}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Teams (Optional)
                    </label>
                    <select
                        value={headerMapping.teams || ''}
                        onChange={(e) => {handleMappingChange('teams', e.target.value)}}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
                    >
                        <option value="">Select column</option>
                        {csvHeaders.map(header => (
                            <option key={header} value={header}>
                                {header}
                            </option>
                        ))}
                    </select>
                    <p className="text-xs text-gray-500 mt-1">
                        Teams should be comma-separated. If not provided or empty for any user, you'll be able to select default teams.
                    </p>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Role (Optional)
                    </label>
                    <select
                        value={headerMapping.role || ''}
                        onChange={(e) => {handleMappingChange('role', e.target.value)}}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
                    >
                        <option value="">Select column</option>
                        {csvHeaders.map(header => (
                            <option key={header} value={header}>
                                {header}
                            </option>
                        ))}
                    </select>
                    <p className="text-xs text-gray-500 mt-1">
                        Valid roles: admin, manager, member, observer. Default: member
                    </p>
                </div>
            </div>

            <div className="flex justify-end space-x-3">
                <button
                    type="button"
                    onClick={handleCancelCsv}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={handleConfirmMapping}
                    disabled={!headerMapping.email}
                    className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 ${(!headerMapping.email) ? 'opacity-75 cursor-not-allowed' : ''
                        }`}
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default MappingSection;
