import { EyeIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { useHistory } from "../../providers/HistoryProvider"
import { classNames } from "../../utils/ClassUtils"
import { colorAuditMapping, iconAuditMapping, nameAuditMapping } from '../../utils/AuditCategories'
import { useState } from 'react'
import { logClientAudit } from '../../ServerActions'
import { get } from 'lodash'
import { useAuth } from '@clerk/clerk-react'
import { Spinner } from '../../utils/Spinner'
import { useVetRec } from '../../providers/VetRecProvider'


function getIcon(category: string) {
    category = category.toUpperCase()
    if (category in iconAuditMapping) {
      return iconAuditMapping[category as keyof typeof iconAuditMapping];
    }
    // Return a default icon or null if the category is not found
    return <div>Default Icon</div>; // Replace this with your actual default icon
}

function getColor(category: string) {
    category = category.toUpperCase()
    if (category in colorAuditMapping) {
      return colorAuditMapping[category as keyof typeof colorAuditMapping];
    }
    // Return a default icon or null if the category is not found
    return "bg-gray-300"; // Replace this with your actual default icon
}

function getActionName(category: string) {
  const categoryUpper: string = category.toUpperCase()
  if (categoryUpper in nameAuditMapping) {
    return nameAuditMapping[categoryUpper as keyof typeof nameAuditMapping];
  }

  category = category.replace(/_/g, ' ')
  return Capitalize(category)
}


const Capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const processEpochIntoTimestring = (epoch: number) => {
  const currentDate = new Date();
  const timestamp = new Date(epoch * 1000);

  const diffInMilliseconds = currentDate.getTime() - timestamp.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const currentYear = currentDate.getFullYear();
  const timestampYear = timestamp.getFullYear();

  const exactTimeString = timestamp.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });

  if (diffInMinutes < 60) {
    if(diffInMinutes < 1){
      return `just now`;
    }
    return `${diffInMinutes} minutes ago at ${exactTimeString}`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hours ago at ${exactTimeString}`;
  } else if (diffInDays < 365 && currentYear === timestampYear) {
    return `${timestamp.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} at ${exactTimeString}`;
  } else {
    return `${timestamp.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })} at ${exactTimeString}`;
  }
}

export default function Activity() {
    const {activeAuditLogs, activeSession, refreshAuditLogs} = useHistory()
    const { isImpersonation } = useVetRec()
    const [comment, setComment] = useState<string>('')
    const { getToken } = useAuth();
    const [sendingComment, setSendingComment] = useState<boolean>(false)
    
    const handleCommentSend = async () => {
      if(activeSession && comment && !sendingComment){
        setSendingComment(true)
        await logClientAudit('comment_added', "visit", activeSession.id ?? "", {comment: comment}, await getToken({template:"supabase"}) ?? "")
        await refreshAuditLogs(activeSession)
        setComment('')
        setSendingComment(false)
      }
    }

    return (
      <div className="flow-root pb-8">
        <ul className="pt-8 px-4 sm:px-8 max-h-[45vh] overflow-y-auto thin-scrollbar mr-6 mt-1">
          {activeAuditLogs && activeAuditLogs?.length > 0 && activeAuditLogs.map((log, index) => (
            <li key={index}>
              <div className="relative pb-8">
                {activeAuditLogs && index !== activeAuditLogs.length - 1 ? (
                  <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-3 items-center justify-center">
                  <div className='flex flex-row gap-x-2 items-center'>
                    <span
                      className={classNames(
                          getColor(log.action),
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                      )}
                    >
                      {getIcon(log.action)}
                    </span>
                  </div>
                  <div className="flex flex-col gap-y-2 min-w-0 flex-1 justify-start pt-1.5 text-gray-500 text-xs">
                    <div className='flex flex-row gap-x-2 items-center '>
                      <div className="text-xs sm:text-sm font-semibold text-gray-700 truncate">
                        {getActionName(log.action)}
                      </div>
                      by 
                      <div className='text-gray-700 text-xs sm:text-sm font-semibold truncate'>
                        {log.actor.replace(/_/g, ' ')}
                      </div>
                      {isImpersonation && log.is_impersonation && <span
                        className='bg-red-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                      >
                        <EyeIcon className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>}
                      <div className='truncate' title={new Date(log.timestamp * 1000).toLocaleString()}>
                        {processEpochIntoTimestring(log.timestamp)}
                      </div>
                    </div>
                    {log.action.toLowerCase() === "comment_added" && <div className='border rounded-md p-4'>
                      <div className='text-gray-700 text-xs sm:text-sm font-regular'>
                        {get(log.metadata, "comment", "")}
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </li>
          ))}
          {(!activeAuditLogs || activeAuditLogs.length === 0) && (
            <div className="flex flex-row justify-center items-center h-40">
              <div className="text-gray-400 text-lg">No activity</div>
            </div>
          )}
        </ul>
        <div className='flex items-center px-4 sm:px-8 gap-x-2'>
          <input type="text" className="w-full h-10 border border-gray-300 rounded-md" placeholder="Leave a comment..." onChange={(event) => setComment(event.target.value)} disabled={sendingComment} value={comment}/>
          <button className="h-10 w-10 bg-main-button rounded-md flex items-center justify-center hover:bg-blue-500" onClick={handleCommentSend} disabled={sendingComment || comment ? false : true}>
            {!sendingComment && <PaperAirplaneIcon className="h-5 w-5 text-white" aria-hidden="true" />}
            {sendingComment && <Spinner size='h-5 w-5' timer={false}/>}
          </button>
        </div>
      </div>
    )
  }
  