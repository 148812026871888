import { BuildingOfficeIcon, CheckIcon, TrashIcon } from "@heroicons/react/24/solid"
import { SessionObject } from "../../utils/SessionUtils"

const DEFAULT_INITIAL = "P"
const COMPLETED_STATUS = "Completed"
const DELETED_STATUS = "Deleted"

interface SessionAvatarProps {
    session: SessionObject
    userEmail?: string
}

export const SessionAvatar = ({ session, userEmail }: SessionAvatarProps) => {
    const isCompleted = session.status.general === COMPLETED_STATUS
    const isDeleted = session.status.general === DELETED_STATUS
    const isOwner = (session.display_owner && session.display_owner === userEmail) ||
                   (!session.display_owner && session.owner === userEmail)
    
    const backgroundColor = isCompleted ? "bg-green-600" : 
                          isDeleted ? "bg-gray-500" : "bg-main"
    
    return (
        <div className={`sm:h-12 sm:w-12 h-7 w-7 flex rounded-full ${backgroundColor} items-center justify-center shrink-0`}>
            <p className="text-center text-main-text font-semibold">
                {isCompleted && <CheckIcon className="h-5 w-5"/>}
                {isDeleted && <TrashIcon className="h-5 w-5"/>}
                {!isCompleted && !isDeleted && isOwner && (
                    <span className="text-center text-main-text">
                        {session.name === "" ? DEFAULT_INITIAL : session.name[0].toUpperCase()}
                    </span>
                )}
                {!isCompleted && !isDeleted && !isOwner && <BuildingOfficeIcon className="h-5 w-5"/>}
            </p>
        </div>
    )
}