import { TemplateType } from './components/templates/TemplateUtils';
import config from './config.json'
import { extractFirstName } from './utils/TextUtils';

const url = import.meta.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

export async function handleThumbsFeedbackServerSide(session:string, feedback:boolean, feedback_content:string, feedback_source: string, highlighted_text: string | undefined, data_access_consent: boolean | undefined, jwt:string){
    const endpoint = url + '/v2/thumbs_feedback';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, feedback, feedback_content, feedback_source, highlighted_text, data_access_consent })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error handling thumbs feedback notification from the API.`);
    }
}

export async function integrationRequest(name:string, jwt:string){
    const endpoint = url + '/v2/integration_request';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ name})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error handling integration request from the API.`);
    }
}

export async function templateRequest(jwt:string){
    const endpoint = url + '/v2/tempalate_request';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error handling template request from the API.`);
    }
}

export async function generateTemplate(sample_report:string, jwt:string){
    const endpoint = url + '/v1/generate_template';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({sample_report})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error handling generate template request from the API.`);
    }
}

export interface CreateSessionArgs {
    token: string,
    session: string,
    name: string,
    manual_notes: boolean,
    recording: boolean,
    consent: boolean,
    pets: string[],
    owner?: string,
    timestamp?: number,
    external_patient_id?: string,
    external_record_id?: string,
    external_visit_id?: string,
    external_template_id?: string
    template_id?: string
}

export async function createSession({ token: jwt, pets, ...args}: CreateSessionArgs) {
    const endpoint = url + '/v2/create_session';
    let source ="web"
    const trimmedPets = pets.map(pet => pet.trim());
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({source, pets: trimmedPets, ...args})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function processRecording(session:string, name:string, jwt:string, manual_notes:boolean, template_id:string|undefined, consent:boolean, pets?:string[], session_spoken_language?:string) {
    const endpoint = url + '/v2/process_recording';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, name, pets, manual_notes, consent, template_id, session_spoken_language })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }
}

export async function reprocessRecording(session:string, name:string,  jwt:string, manual_notes:boolean, template_id:string|undefined, consent:boolean, pets?:string[], session_spoken_language?:string) {
    const endpoint = url + '/v2/reprocess_session';
    let response;
    const trimmedPets = pets?.map(pet => pet.trim());
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, name, pets: trimmedPets, manual_notes, consent, template_id, session_spoken_language })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }
}

export async function generateSoapNotes(session:string, template_id:string, manual_notes:boolean, jwt:string, name?:string, additional_instructions?:string) {
    const endpoint = url + '/v2/soap_notes';
    try{
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, name: name, manual_notes, template_id, additional_instructions })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }

}

export async function generateDischargeNotes(session:string, name:string, template_id:string, jwt:string, additional_instructions?:string) {
    const endpoint = url + '/v2/discharge_notes';
    // Hacky way to get first name
    const firstName = name.split(' ')[0];
    try{
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, name: firstName, template_id, additional_instructions })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }

}

export async function translateDischargeNotes(language:string, session:string, jwt:string){
    const endpoint = url + '/v1/discharge_translation';
    try{
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, language })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }
}

export async function generateSOAPFollowup(session:string, msg:string, jwt:string) {
    const endpoint = url + '/v1/soap_notes_followup';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session, msg })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export interface message{
    role:string,
    message:string
}

export async function generateAssisstant(messages:Array<message>, jwt:string) {
    const endpoint = url + '/v1/assistant';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ messages })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

// Function to fetch all sessions
export async function fetchSessions(jwt:string) {
    return fetch(url + '/v1/all_sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`There was an error fetching sessions. Error: ${error}`);
    });
}

export async function fetchSessionsDate(jwt:string, startDate:string, endDate:string) {
    return fetch(url + '/v1/sessions_by_date/' + startDate + '/' + endDate, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`There was an error fetching sessions. Error: ${error}`);
    });
}

export async function fetchSessionsName(jwt:string, name:string) {
    return fetch(url + '/v1/sessions_by_name/' + name, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`There was an error fetching sessions. Error: ${error}`);
    });
}

// Function to fetch all organization sessions
export async function fetchOrganizationSessions(jwt:string) {
    return fetch(url + '/v1/organization_sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`Error fetching org sessions: ${error}`);
    });
}

// Function to fetch all active sessions
export async function fetchActiveSessions(jwt:string) {
    return fetch(url + '/v1/all_active_sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`There was an error fetching active sessions. Error: ${error}`);
    });
}

// Function to fetch all active sessions for org
export async function fetchOrganizationActiveSessions(jwt:string) {
    return fetch(url + '/v1/organization_active_sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`Error fetching org active sessions: ${error}`);
    });
}

// Function to fetch a specific session by ID
export async function fetchSessionById(sessionId:string, jwt:string) {
    return fetch(url + `/v1/session/${sessionId}`, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['session'];
    })
    .catch(error => {
        throw new Error(`Error fetching session: ${error}`);
    });
}

function createSessionName(petNames: string[]): string {
    if (petNames.length === 0) {
        throw new Error("No pets provided");
    } 
    if (petNames.length === 1) return petNames[0];
    if (petNames.length === 2) return `${petNames[0]} and ${petNames[1]}`;
    return `${petNames.slice(0, -1).join(", ")} and ${petNames[petNames.length - 1]}`;
}


export async function saveName(session:string, name:string, jwt:string, pets?:string[]) {

    if (!name && pets) {
        name = createSessionName(pets);
    }

    // Trim all pet names
    const trimmedPets = pets?.map(pet => pet.trim());

    return fetch(url + `/v1/update_name`, {
        method: 'POST',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ session, name, pets: trimmedPets })
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(`Error updating name: ${error}`);
    });
}

export async function saveDate(session: string, name: string, jwt: string, timestamp: number) {
    return fetch(url + `/v1/update_date`, {
        method: 'POST',
        headers: {
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ session, name, timestamp })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(`Error updating date: ${error}`);
    });
}

export async function savePatients(session:string, pets:string[],jwt:string, petUpdates?: {
    update: Record<string, string>,
    add: string[],
    remove: string[]
}) {
    const response = await fetch(url + `/v1/update_patients`, {
        method: 'POST',
        headers: {
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ session, pets, petUpdates })
    });

    if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error("Error updating patients: " + errorData?.message || `Server error: ${response.status}`);
    }

    return response.json();
}


export async function saveOwner(session:string, owner:string, jwt:string) {
    return fetch(url + `/v1/update_owner`, {
        method: 'POST',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ session, owner })
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(`Error updating name: ${error}`);
    });
}

// Function to fetch all templates
export async function fetchTemplates(jwt:string, type?:TemplateType) {
    return fetch(url + '/v1/templates' + (type ? `?type=${type}` : ""), {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['templates'];
    })
    .catch(error => {
        throw new Error(`Error fetching templates: ${error}`);
    });
}

// Function to fetch all templates for org
export async function fetchOrgTemplates(jwt:string, type?:TemplateType) {
    return fetch(url + '/v1/organization_templates' + (type ? `?type=${type}` : ""), {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['templates'];
    })
    .catch(error => {
        throw new Error(`Error fetching org templates: ${error}`);
    });
}

export async function fetchSuperOrgMetadata(jwt:string) {
    return fetch(url + '/v1/super_organization', {
        method: 'GET',
        headers: {
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .catch(error => {
        throw new Error(`Error fetching super org controls: ${error}`);
    });
}

export async function fetchSuperOrgTemplates(jwt:string, type?:TemplateType) {
    return fetch(url + '/v1/super_organization_templates' + (type ? `?type=${type}` : ""), {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['templates'];
    })
    .catch(error => {
        throw new Error(`Error fetching super org templates: ${error}`);
    });
}

// Function to fetch all templates for org
export async function fetchTemplateLibrary(jwt:string) {
    return fetch(url + '/v1/template_library', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['templates'];
    })
    .catch(error => {
        throw new Error(`Error fetching library templates: ${error}`);
    });
}

// Function to fetch a specific session by ID
export async function fetchTempalteById(templateId:string, jwt:string, accessKey?:string) {
    return fetch(url + `/v1/template/${templateId}` + (accessKey ? `?access_key=${accessKey}` : ""), {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(`Error fetching template: ${error}`);
    });
}

export async function createTemplate(template_id:string, name:string, jwt:string, integration: string | undefined, mapping: {} | undefined, styling: {} | undefined, templateType: TemplateType, tone:string, duplicated_from_template_id: string|undefined, apply_super_org_permissions: boolean, edit_locked_to_owner?: boolean) {
    const endpoint = url + '/v1/template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ name, template_id, integration: integration === undefined ? null : integration, mapping, styling, type: templateType, tone, duplicated_from_template_id, apply_super_org_permissions, edit_locked_to_owner})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function updateTemplate(template_id:string, name:string, jwt:string, integration: string | undefined, mapping: {} | undefined, styling: {} | undefined, templateType: TemplateType, tone:string) {
    const endpoint = url + '/v1/update_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ name, template_id, integration: integration === undefined ? null : integration, mapping, styling, type: templateType, tone})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function updateLockTemplate(jwt:string, template_id:string, edit_locked_to_owner:boolean) {
    const endpoint = url + '/v1/update_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ template_id, edit_locked_to_owner })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function deleteTemplate(template_id:string, jwt:string) {
    const endpoint = url + '/v1/delete_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function postDefaultTemplate(template_id:string, jwt:string) {
    const endpoint = url + '/v2/default_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function postDefaultDischargeTemplate(template_id:string, jwt:string) {
    const endpoint = url + '/v2/default_discharge_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function postDefaultRecordsRecapTemplate(template_id:string, jwt:string) {
    const endpoint = url + '/v2/default_recordsrecap_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function cancelSession(session:string, jwt:string) {
    const endpoint = url + '/v1/cancel_session';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export interface RecordSessionArgs {
    session: string,
    recording: boolean,
    manual_notes: boolean,
    microphone_name: string,
    recording_id: string
    token: string,
    template_id?: string
}
export async function recordSession({ token: jwt, ...args}: RecordSessionArgs) {
    try{
        const endpoint = url + '/v1/recording_session';
        const skip_create_node = true
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ skip_create_node, ...args })
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }catch(e){
        console.error(e);
    }
}

export async function RecordingFileStatus(session:string, recording_id:string, jwt:string) {
    try{
        const endpoint = url + '/v2/set_recordings_status';
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, recording_id })
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }catch{
        
    }
}

export async function RecordingBackupStatus(session:string, recording_id:string, jwt:string) {
    try{
        const endpoint = url + '/v2/set_recordings_backup_status';
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, recording_id })
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }catch{
        
    }
}

export async function RecordingConsent(session:string, jwt:string) {
    try{
        const endpoint = url + '/v2/set_recording_consent';
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session })
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }catch{
        
    }
}

export async function updatedSession(session:string, jwt:string) {
    try{
        const endpoint = url + '/v1/updated_session';
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session })
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }catch{
        
    }
}

export async function intakeMessage(session:string, message:string, first:boolean) {
    let url_intake = "https://vetrec-intake.fly.dev"
    const endpoint = url_intake + '/v2/process_message';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ session,  message, first})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function markSessionCompleted(session:string, completed:boolean, jwt:string) {
    const endpoint = url + '/v1/session_completed/' + session;
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ status: completed})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true
}

export async function processPDFSummary(session:string, jwt:string, is_regen: boolean, latest_upload_id: string | undefined, additional_instructions: string | undefined, template_id: string) {
    const endpoint = url + '/v1/processPDFSummary';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({session, is_regen, latest_upload_id, additional_instructions, template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true
}

export interface TeamMemberPublicMetadata {
    default_template_id?: string,
    default_discharge_template_id?: string
    default_recordsrecap_template_id?: string
}

export async function fetchTeamMemberPublicMetadata(user_id: string, jwt: string): Promise<TeamMemberPublicMetadata> {
    try {
        const response = await fetch(url + '/v1/team_member_public_metadata/' + user_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            }
        });
        
        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error getting team member public metadata: ${errorDetail.detail}`);
        }

        const userMetadata: TeamMemberPublicMetadata = await response.json();
        return userMetadata
    } catch (error) {
        return {};
    }
}

export async function updateLanguage(language:string, jwt:string) {
    try {
        const response = await fetch(url + '/v1/update_language', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ language })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error updating language: ${errorDetail.detail}`);
        }

        return true;
    } catch (error) {
        console.error('Failed to update language:', error);
        return false;
    }
}

export async function downloadSoapPDF(session:string, jwt:string, image?:string, medical_note_id?:string) {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await fetch(url + '/v1/generatePDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, timezone, image, medical_note_id })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error download soap PDF: ${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        console.error('Failed to download soap PDF:', error);
        return false;
    }
}

export async function downloadDischargePDF(session:string, jwt:string, discharge_id?:string) {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await fetch(url + '/v1/generateDischargePDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, timezone, discharge_id })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error download discharge PDF: ${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        console.error('Failed to download discharge PDF:', error);
        return false;
    }
}

export async function downloadPreHistoryPDF(session:string, jwt:string) {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await fetch(url + '/v1/generatePreHistoryPDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, timezone })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error download soap PDF: ${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        console.error('Failed to download soap PDF:', error);
        return false;
    }
}

export async function transferToVetspire(session:string, patientId:string, jwt:string, encounterId?:string, image?:string, medicalNoteId?:string) {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await fetch(url + '/v1/transferToVetspire', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, patientId, encounterId, timezone, image, medicalNoteId })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        throw new Error(`Error transferring to Vetspire: ${error}`);
    }
}

export async function transferToOnPremPims(session_id:string, animal_id:string, jwt:string, short_description?:string, medical_note_id?:string, provider?:string, client_id?:string) {
    try {
        const response = await fetch(url + '/v1/transferToOnPremPims', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session_id, animal_id, short_description, medical_note_id, provider, client_id })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        
        throw new Error(`Error transferring to PIMS`);
    }
}

export async function importVetspireVisits(start:number, end:number, jwt:string, onBehalfOf?:string) {
    const response = await fetch(url + '/v1/importVetspireVisits', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ start, end, onBehalfOf })
    });
    if (!response.ok) {
        const errorDetail = await response.json();
        throw new Error(`${errorDetail.detail}`);
    }

    return response.json();
}

export async function importOnPremPimsAppointments(start:number, end:number, jwt:string) {
    const response = await fetch(url + '/v1/importOnPremPimsAppointments', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ start, end })
    });
    if (!response.ok) {
        const errorDetail = await response.json();
        throw new Error(`${errorDetail.detail}`);
    }

    return response.json();
}

export async function updateVetspireVisit(session_id:string, appointment_id:string, jwt:string) {
    const response = await fetch(url + '/v1/updateVetspireVisit', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session_id, appointment_id })
    });
    if (!response.ok) {
        const errorDetail = await response.json();
        throw new Error(`${errorDetail.detail}`);
    }

    return response.json();
}

export async function logClientAudit(action:string, item_type:string, item_id:string, metadata:{[key: string]: string}, jwt:string) {
    const endpoint = url + '/v1/client_audit_log';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ action, item_type, item_id, metadata})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function deleteSession(session_id:string, jwt:string) {
    const endpoint = url + '/v1/delete_session';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session_id})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function deletePreHistory(session_id: string, jwt: string) {
    const endpoint = url + `/v1/delete_pre_history/${session_id}`;
    const response = await fetch(endpoint, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt
        },
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function shareTemplate(template_id: string, jwt: string) {
    const endpoint = url + `/v1/share_template/${template_id}`;
    const response = await fetch(endpoint, {
        method: 'GET', 
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt
        },
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function shareTemplateSuperOrg(template_id: string, add:boolean, jwt: string) {
    const endpoint = url + `/v1/move_template_to_super_org`;
    const action = add ? "add" : "remove"
    const response = await fetch(endpoint, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + jwt,
        },
        body: JSON.stringify({ template_id, action })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function GetVetspirePatientId(patient_id:string, jwt:string) {
    try{
        const endpoint = url + `/v1/vetspire_patient/${patient_id}`;
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return (await response.json()).patient;
    }catch{
        
    }
}


export async function improveInstructions(name:string, type:string, instructions:string, additional_context:string | undefined, jwt:string, template_type:string, defaultValue?:string) {
    const endpoint = url + '/v1/improve-instructions';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ name, type, instructions, additional_context, template_type, defaultValue })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function GetUserSignInToken(jwt:string){
    try{
        const endpoint = url + `/v2/get_signin_token`;
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return (await response.json()).token;
    }catch{
        
    }
}

export async function notifyAccountDeletion(feedback: string | undefined, jwt: string) {
    try {
        const endpoint = url + '/v2/account_deletion_notification';
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({ feedback: feedback || undefined })
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error sending account deletion notification:', error);
        // We don't throw here because we don't want to block account deletion
        return { success: false };
    }
}