import { useCallback, useEffect, useState } from "react";
import Reminder from "../ReminderToast";
import { BetaBanner } from "../common/BetaBanner";
import { getUserGlossary, updateCustomSpelling } from "../../serverActions/user";
import { useAuth } from "@clerk/clerk-react";
import ErrorAlert from "../Error";
import AddCustomSpellingModalSettings from "./AddCustomSpellingModalBasic";
import { XMarkIcon } from "@heroicons/react/24/solid";
import UnsavedChangesBanner from "../templates/UnsavedChangesBanner";

export const UserSpellingPage = () => {
    const { getToken } = useAuth();
    const [saved, setSaved] = useState(false);
    const [savedText, setSavedText] = useState<string>("")
    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState(true);
    const [customSpellings, setCustomSpellings] = useState<{[key:string]:any}>();
    const [showAddSpelling, setShowAddSpelling] = useState(false);
    const [changeNotSaved, setChangeNotSaved] = useState(false);

    const fetchUserGlossary = useCallback(async () => {
        setLoading(true);
        const response = await getUserGlossary(await getToken({template:"supabase"}) ?? "");
        if(response && "abbreviations" in response) {
            setCustomSpellings(response['abbreviations']);
        }
        setLoading(false);
    }, [getToken]);

    useEffect(() => {
        fetchUserGlossary();
    }, [fetchUserGlossary]);

    const removeSpelling = (key:string, spelling:string) => {
        if(customSpellings) {
            const newCustomSpellings = {...customSpellings};
            newCustomSpellings[key] = newCustomSpellings[key].filter((s:string) => s !== spelling);
            // if the key has no more spellings, remove the key
            if(newCustomSpellings[key].length === 0) {
                delete newCustomSpellings[key];
            }
            setCustomSpellings(newCustomSpellings);
            setChangeNotSaved(true);
        }
    }

    async function handleSaveSpellings(): Promise<void> {
        if(!customSpellings) {
            return;
        }
        let response = await updateCustomSpelling(customSpellings, await getToken({template:"supabase"}) ?? "")
        if (!response) {
            setError("Failed to save custom spellings");
        } else {
            setSaved(true);
            setSavedText("Custom spellings saved successfully");
            setChangeNotSaved(false);
        }
    }
    
    function cleanSpelling(spelling:string):string {
        return spelling.replace("<caseSensitive>","").replace("</caseSensitive>","");
    }

    return (<>
        <div className="mb-20 flex flex-col gap-y-4 w-full max-h-[60vh] overflow-y-auto thin-scrollbar">
            <BetaBanner>
                <span className="font-semibold">Manage your custom vocabulary!</span> For any questions or feedback, please reach out to <a href="mailto:support@vetrec.io" className="underline">support@vetrec.io</a>
            </BetaBanner>
            <div className="flex flex-row justify-between items-center mt-4">
                <div className="flex flex-col gap-y-4">
                    <h1 className="text-3xl font-semibold">Custom Vocabulary</h1>
                    <div>Configure your custom vocabulary.</div>
                </div>
                {changeNotSaved && <div className="relative">
                    <UnsavedChangesBanner show={changeNotSaved} onSave={handleSaveSpellings} onDismiss={() => setChangeNotSaved(false)} top={false}/>
                </div>}
                <div className="flex flex-row gap-x-4 items-center">
                    <button className="bg-blue-600 hover:bg-blue-500 px-4 py-2 text-white rounded-md" onClick={() => setShowAddSpelling(true)}>Add vocabulary</button>
                </div>
            </div>
            <div className="flex flex-row gap-x-4 items-center border-b border-gray-300 p-4">
                <div className="w-28 font-semibold">
                    Correct
                </div>
                <div className="font-semibold">
                    Incorrect
                </div>
            </div>
            {loading && <div className="flex flex-col gap-y-4 items-center justify-center p-4">
                <div className="text-gray-500">Loading...</div>
            </div>}
            {customSpellings && Object.keys(customSpellings).length > 0 && <div className="flex flex-col gap-y-4">
                <div className="flex flex-row gap-x-4 mt-4 mb-2">
                    <div className="text-sm text-gray-500 flex items-center gap-x-2">
                        <div className="w-3 h-3 bg-blue-100 border border-blue-600 rounded-full"></div>
                        Upper and lowercase letters do not matter.
                    </div>
                    <div className="text-sm text-gray-500 flex items-center gap-x-2">
                        <div className="w-3 h-3 bg-red-100 border border-red-600 rounded-full"></div>
                        Exact match - Upper and lowercase letters matter.
                    </div>
                </div>
                {Object.keys(customSpellings).map((key) => (
                    <div className="flex flex-row gap-x-4 items-center border border-gray-300 bg-gray-100 rounded-md p-4">
                        <div className="font-semibold w-28">{key}</div>
                        <div className="text-sm text-gray-900 flex flex-row gap-x-4">
                            {customSpellings[key].map((spelling:string) => {
                                const isCaseSensitive = spelling.includes("<caseSensitive>") && spelling.includes("</caseSensitive>");
                                const color = isCaseSensitive ? "red" : "blue";
                                return (<div 
                                key={spelling} 
                                className={`px-3 py-1 bg-${color}-100 border border-${color}-600 rounded-full flex flex-row gap-x-1 items-center`}
                                >
                                    {cleanSpelling(spelling)}
                                    <XMarkIcon className={`h-4 w-4 text-${color}-600 font-semibold cursor-pointer`} onClick={() => removeSpelling(key, spelling)}/>
                                </div>
                            )})}
                        </div>
                    </div>
                ))}
            </div>}
            {!loading && (!customSpellings || Object.keys(customSpellings).length === 0) && <div className="flex flex-col gap-y-4 items-center justify-center p-4">
                <div className="text-gray-500">No custom vocabulary added yet.</div>
            </div>}
        </div>
        <AddCustomSpellingModalSettings show={showAddSpelling} close={() => setShowAddSpelling(false)} setNotification={setSaved} setNotificationTitle={setSavedText} refresh={fetchUserGlossary}/>
        <Reminder show={saved} hide={() => setSaved(false)} title={savedText} text=""/>
        <ErrorAlert error={error} setError={setError}/>
    </>);
};