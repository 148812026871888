import React, { useState } from 'react';
import { LockOpenIcon, LockClosedIcon } from '@heroicons/react/24/solid';
import { Spinner } from '../../utils/Spinner';

interface LockUnlockButtonProps {
    isLocked: boolean;
    onIsLockedChanged: (setLockedTo: boolean) => void;
    className?: string;
    disabled?: boolean;
    tooltipText?: string;
    isLoading?: boolean;  // New prop for loading state
}

const LockUnlockButton: React.FC<LockUnlockButtonProps> = ({
    isLocked,
    onIsLockedChanged,
    className = '',
    disabled = false,
    tooltipText,
    isLoading = false,  // Default to false
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleClick = () => {
        if (!isLoading && !disabled) {
            onIsLockedChanged(!isLocked);
        }
    };

    return (
        <div className="relative inline-block">
            <button
                onClick={handleClick}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className={`p-2 rounded-full transition-colors duration-200 
                    ${isLocked ? 'bg-red-100 hover:bg-red-200' : 'bg-green-100 hover:bg-green-200'} 
                    ${(disabled || isLoading) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
                    ${className}`}
                aria-label={isLocked ? 'Unlock' : 'Lock'}
            >
                {isLoading ? (
                    <Spinner size="h-5 w-5" timer={true} className={`fill-main`} />
                ) : isLocked ? (
                    <LockClosedIcon className="w-5 h-5 text-red-600" />
                ) : (
                    <LockOpenIcon className="w-5 h-5 text-green-600" />
                )}
            </button>
            {tooltipText && showTooltip && (
                <div className="absolute left-1/2 transform -translate-x-1/2 mt-4 px-2 py-1 bg-gray-800 text-white text-sm rounded whitespace-nowrap z-[1]">
                    <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-gray-800 rotate-45" />
                    {tooltipText}
                </div>
            )}
        </div>
    );
};

export default LockUnlockButton;
