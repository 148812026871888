import { Fragment, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import { EzyVetConfig } from '../integrations/EzyVetConfig'
import { IntegrationType, SectionType } from './TemplateUtils'
import { useTemplate } from './TemplateProvider'
import { InstinctConfig } from '../integrations/InstinctConfig'
import EnumPicker from '../EnumPicker'
import { VetspireConfig } from '../integrations/VetspireConfig'
import { useVetRec } from '../../providers/VetRecProvider'

interface TemplateConfigModalProps{
    open:boolean,
    setOpen: (value: boolean) => void
    items: SectionType[]
    setErrorMessage: (value: string) => void
}

export default function TemplateConfigModal(props:TemplateConfigModalProps) {
    const {integration, updateIntegration, saveMapping} = useTemplate()
    const { pims } = useVetRec()
    const selectedIntegration = useMemo(() => {
        if(!integration){
            updateIntegration(pims.includes("vetspire") ? "Vetspire" : "Not Mapped")
            return pims.includes("vetspire") ? "Vetspire" : "Not Mapped"
        }
        return integration
    }, [integration])
    
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-[10]" onClose={props.setOpen}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <Cog6ToothIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5 flex flex-col items-center justify-center gap-y-4 w-full">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Configure integration
                                </Dialog.Title>
                                <div className="">
                                    <p className="text-sm text-gray-500">
                                        Pick a PiMS to configure the template for and configure the values.
                                    </p>
                                </div>
                                <div className='flex flex-row gap-x-4 items-center'>
                                    PiMS:
                                    <EnumPicker enum={IntegrationType} selectedOption={integration ?? (pims.includes("vetspire") ? "Vetspire" : "EzyVet")} setSelectedOption={updateIntegration} labelBackground='' width='w-96'/>
                                </div>
                            </div>
                        </div>
                        {selectedIntegration === "EzyVet" && <EzyVetConfig items={props.items} open={props.setOpen}/>}
                        {selectedIntegration === "Instinct" && <InstinctConfig items={props.items} open={props.setOpen}/>}
                        {selectedIntegration === "Vetspire" && <VetspireConfig open={props.setOpen}/>}
                        {selectedIntegration === "Not Mapped" && <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center">
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={async () => {
                                    try {
                                        await saveMapping();
                                        props.setOpen(false);    
                                    } catch (error) {
                                        props.setErrorMessage("Error saving mapping. Please try again.");
                                    }
                                }}
                            >
                                Save Configuration
                            </button>
                        </div>}
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}