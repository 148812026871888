import { useEffect, useState } from "react";
import { ExistingTeamSelector } from "./ExistingTeamSelector";
import { Spinner } from "../../../utils/Spinner";
import { create_super_org } from "../../../serverActions/admin";
import { useAuth } from "@clerk/clerk-react";
import Banner, { BannerType } from "../../common/Banner";

interface CreateSuperOrgProps { }

const CreateSuperOrg: React.FC<CreateSuperOrgProps> = ({ }) => {

    const [loading, setLoading] = useState(false)
    const [selectedTeams, setSelectedTeams] = useState<string[]>()
    const [tier, setTier] = useState<string>()
    const [name, setName] = useState<string>()
    const [supportTier, setSupportTier] = useState<string>()
    const [davidEnabled, setDavidEnabled] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const [success, setSuccess] = useState<boolean>()
    const { getToken } = useAuth()

    const onAddPressed = async () => {
        setLoading(true)
        // check that values name and selectTeams are set
        if (!name || selectedTeams?.length === 0) {
            setError("Please fill in name and select at least one team")
            setLoading(false)
            return
        }
        try{
            await create_super_org({ name: name || "", tier, teams: selectedTeams, support_tier: supportTier, domain: undefined, davidEnabled:davidEnabled }, await getToken({ template: "supabase" }) ?? "")
            setLoading(false)
            setSuccess(true)
        }
        catch(err){
            console.log(err)
            setError("There was an error creating the super organization")
            setLoading(false)
        }
    }

    useEffect(() => {
        if (error) {
            setError(undefined)
        }
    }, [name, selectedTeams])

    return (
        <div className='flex flex-row items-center justify-center h-full'>
            {success && <div className='flex flex-col gap-y-4 items-center'>
                <h1 className='text-2xl font-semibold text-gray-900'>Super Organization Created 🎉</h1>
                <Banner bannerType={BannerType.Success} message="Super organization created successfully! Refresh page if not automatically refreshed..." />
            </div>}
            {!success && <div className='flex flex-col gap-y-4 items-center'>
                <h1 className='text-2xl font-semibold text-gray-900'>No super organization detected</h1>
                <p className='text-gray-900 text-center'>Create a super organization for this user and add their teams </p>
                <input
                    type="text"
                    placeholder="Organization Name"
                    className="w-full border-2 border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:border-blue-400"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <div className='relative overflow-visible w-full'>
                    <ExistingTeamSelector setSelectedTeams={setSelectedTeams} teamsInSuperOrg={[]} />
                </div>
                <input
                    type="text"
                    placeholder="Tier"
                    className="w-full border-2 border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:border-blue-400"
                    value={tier}
                    onChange={e => setTier(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Support Tier (L0, L1, L2, etc.)"
                    className="w-full border-2 border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:border-blue-400"
                    value={supportTier}
                    onChange={e => setSupportTier(e.target.value)}
                />
                <div className='flex flex-row items-center gap-x-4 justify-start w-full py-2'>
                    <input
                        type="checkbox"
                        className="rounded-full border-gray-300 focus:ring-blue-500 h-4 w-4"
                        checked={davidEnabled}
                        onChange={e => setDavidEnabled(e.target.checked)}
                    />
                    <label className="text-gray-900 text-md">David Enabled (Uncheck to disable)</label>
                </div>
                {error && <p className='text-red-500'>{error}</p>}
                <div className='flex flex-row gap-x-4 w-full'>
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-2"
                        onClick={onAddPressed}
                        disabled={loading}
                    >
                        {loading ? <Spinner size="w-5 h-5" timer={false} /> : 'Create Super Organization'}
                    </button>
                </div>
            </div>}
        </div>
    )
}

export default CreateSuperOrg;