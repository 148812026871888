import { ArrowDownCircleIcon, ArrowsRightLeftIcon, ArrowTopRightOnSquareIcon, BookOpenIcon, ChatBubbleBottomCenterTextIcon, CheckIcon, ClipboardDocumentIcon, CloudIcon, DocumentTextIcon, HandThumbDownIcon, HandThumbUpIcon, LinkIcon, MicrophoneIcon, SparklesIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import ReactQuill from "react-quill";
import { Spinner } from "../../utils/Spinner";
import MoreMenu from "../moreMenu";
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { preprocessText, filterNotMentioned, filterNotMentionedHTML, revertPreprocessedText, revertPreprocessedTextNoStyling, fixIndentation, addMissingIndentLevels } from "../../utils/TextUtils";
import va from '@vercel/analytics';
import { downloadSoapPDF } from "../../ServerActions";
import TemplatePicker from "../templates/TemplatePicker";
import { Loading } from "../../utils/Loading";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles"
import { useHistory } from "../../providers/HistoryProvider";
import { TemplateType } from "../templates/TemplateUtils";
import { WarningBanner } from "../common/WarningBanner";
import VetSpireModal from "../integrations/VetspireModal";
import { CanineDentalVertical } from "./CanineDental";
import { useVetRec } from "../../providers/VetRecProvider";
import { FelineDentalVertical } from "./FelineDental";
import {useTour} from "../../providers/TourProvider";
import { ScribeBasicTourIdentifiers } from '../tours/scribeBasic/config';
import QuillEditorWithToolbar from "./QuillEditorWithToolbar";
import { executeCopyToClipboard } from "../../utils/CopyUtils";
import html2canvas from 'html2canvas';
import { allPrebuiltTemplateIds } from "../../utils/prebuiltTemplates";
import { MultiPetTabs } from "./MultiPetTabs";
import { DeltaStatic, Sources } from 'quill';
import { ErrorBanner } from "../common/ErrorBanner";
import { isMobileDevice } from "../../utils/deviceUtils";
import OnPremPimsSyncModal from '../integrations/OnPremPimsSyncModal';
import { getPimsDisplayName } from "../../utils/PimsUtils";
import RecordsRecapSummary from "./RecordsRecapSummary";

export const Notes: React.FC = () => {
    const { user } = useUser()
    const { getToken } = useAuth()
    const {organization} = useOrganization()
    const [savingNotes, setSavingNotes] = useState<boolean>(false)
    const [copySuccess, setCopySuccess] = useState<boolean>(false)
    const [copyKey, setCopyKey] = useState<string>("")
    const [didLastSaveFail, setDidLastSaveFail] = useState<boolean>(false)
    const [vetSpireExport, setVetSpireExport] = useState<boolean>(false)
    const [isOriginalNotesView, setIsOriginalNotesView] = useState<boolean>(false)
    const [notesToggle, setNotesToggle] = useState<boolean>(true)
    const [onPremPimsExport, setOnPremPimsExport] = useState<boolean>(false)
    const {
        // Session state
        activeSession,
        sessionTemplate,
        template,
        
        // Notes state
        activeNotes,
        activePreHistory,
        activeDiagram,
        displayActiveNotes, 
        displayOriginalNotes,
        notesProcessing,
        notesStreaming,
        notesStreamCompleted,
        notesSections,
        toggleSections,
        toggleSectionsError,
        didUserSaveNotesAfterLatestGeneration,

        // Transcript state
        transcriptProcessing,
        activeTranscriptExists,
        anyRecordingsWithNoData,

        // Loading states
        notesLoading,
        loadingSessionInfo,

        // Multi-pet state
        multiPetSessionActive,
        medicalNotesEditCache,
        sessionWithPetArraySelectedMedicalNoteId,
        sessionWithPetArraySelectedName,

        // Actions
        handleMarkComplete,
        handleAddToSession,
        handleThumbsInput,
        handleNotesTemplateChange,
        handleToggleSections,
        handleSelectionChange,
        handleSelectedPetChange,
        processTranscript,
        refreshActiveSession,
        uploadActiveNotes,
        handleTabChange,

        // State setters
        notifyInProgress,
        notifyCompleted,
        clearNotification,
        setError,
        setWarning,
        setDisplayActiveNotes,
        setTemplate,
        setActiveDiagram,
        setFindReplaceModal,
        setAddCustomSpellingModal,
        setShowChatBot,
        setMedicalNotesEditCache,

        // Utilities
        getLoggingProperties,
    } = useHistory()
    const previousVisit = useRef(activeSession);
    const { copyBehavior, pims, isImpersonation, richtextFontSize, richtextRemoveSpacesBetweenSections, davidEnabled , syncOnPremPimsEnabled } = useVetRec()
    
    // Tour
    const { isTourRunning, currentStep, setIsTourHidden, setCurrentStep } = useTour();
    const isLoading = notesLoading || loadingSessionInfo;

    // Add a ref to track the current pet selection
    const currentMedicalNoteIdRef = useRef<string | undefined>(sessionWithPetArraySelectedMedicalNoteId);
    const currentSelectedPetRef = useRef<string | undefined>(sessionWithPetArraySelectedName);

    const pimsDisplayName = useMemo(() => {
        return getPimsDisplayName(pims)
    }, [pims])

    // Update the ref whenever the selected pet changes
    useEffect(() => {
        currentMedicalNoteIdRef.current = sessionWithPetArraySelectedMedicalNoteId;
        currentSelectedPetRef.current = sessionWithPetArraySelectedName;
    }, [sessionWithPetArraySelectedMedicalNoteId, sessionWithPetArraySelectedName]);

    const activeSessionTemplateUri = useMemo(() => {
        const templateIdSplit = activeSession?.template_id?.split("/")
        if (templateIdSplit && templateIdSplit.length > 0) {
            const templateIdWithDynamic = templateIdSplit.length === 1 ? templateIdSplit[0] : templateIdSplit[1]
            const templateId = templateIdWithDynamic.replace("dynamic_", "")

            if (templateId) {

                let uri = `templateBuilder?templateId=${templateId}`
                if (allPrebuiltTemplateIds.includes(templateIdWithDynamic)) {
                    uri += "&duplicate=true"
                }
                
                return uri + "&reprocess_session=true" + "&session_id=" + String(activeSession?.id)
            }    
        }

        return undefined
    }, [activeSession])

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (copySuccess || copyKey) {
            timer = setTimeout(() => {
                setCopySuccess(false);
                setCopyKey("")
            }, 1000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [copySuccess, copyKey]);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
    
        if (savingNotes) {
            timer = setTimeout(() => {
                setSavingNotes(false)
            }, 3000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
        };
    }, [savingNotes]);

    useEffect(() => {
        if (!isTourRunning) return;

        if (currentStep === ScribeBasicTourIdentifiers.NOTES_LOADING && isLoading) {
            setIsTourHidden(false);
        }

        if (currentStep === ScribeBasicTourIdentifiers.NOTES_LOADING && activeNotes && !notesStreaming) {
            setCurrentStep(currentStep + 1);
        }

    }, [isTourRunning, currentStep, isLoading, notesStreaming, activeNotes]);


    const copyToClipboardSection = async (key:string) => {
        try {
            if(notesSections){
                setCopyKey(key)
                let process_into_html = addMissingIndentLevels(preprocessText(notesSections[key]))
                
                let text_temp_html = filterNotMentionedHTML(fixIndentation(process_into_html, richtextRemoveSpacesBetweenSections)) // rich text

                let text_temp_plain
                if (pims === "ezyvet" || copyBehavior === "markdown") {
                    text_temp_plain = filterNotMentioned(revertPreprocessedText(process_into_html)); // plain with markdown
                } else {
                    text_temp_plain = filterNotMentioned(revertPreprocessedTextNoStyling(process_into_html)); // plain with no markdown
                }
    
                await executeCopyToClipboard(text_temp_plain, text_temp_html, richtextFontSize);

                notifyCompleted("Notes section copied to clipboard")
                va.track("History_CopyNotes", getLoggingProperties())
            }
        } catch (err) {
            clearNotification()
            setError("There was an issue copying the notes section. Please try again.")
            va.track("History_CopyNotes_Failed", getLoggingProperties())
        }
    };

    const copyToClipboard = async () => {
        try {
            setCopySuccess(true)
            let text_temp_html = filterNotMentionedHTML(fixIndentation(displayActiveNotes, richtextRemoveSpacesBetweenSections)) // rich text
            let text_temp_plain

            if (pims === "ezyvet" || copyBehavior === "markdown") {
                text_temp_plain = filterNotMentioned(revertPreprocessedText(displayActiveNotes)); // plain with markdown
            } else {
                text_temp_plain = filterNotMentioned(revertPreprocessedTextNoStyling(displayActiveNotes)); // plain with no markdown
            }

            await executeCopyToClipboard(text_temp_plain, text_temp_html, richtextFontSize);

            notifyCompleted("Notes copied to clipboard")
            va.track("History_CopyNotes", getLoggingProperties())
        } catch (err) {
            clearNotification()
            setError("There was an issue copying the notes. Please try again.")
            va.track("History_CopyNotes_Failed", getLoggingProperties())
        }
    };

    const saveNotes = async () => {
        if(activeSession){
            try {
                setDidLastSaveFail(false)
                setSavingNotes(true)
                notifyInProgress("Saving your notes...")

                await uploadActiveNotes(displayActiveNotes)
                
                notifyCompleted("Saved notes!")
                va.track("History_SaveNotes", getLoggingProperties())

                refreshActiveSession()
            } catch (err) {
                clearNotification()
                setError("There was an issue saving the notes. Please try again.")
                setDidLastSaveFail(true)
                va.track("History_SaveNotes_Failed", getLoggingProperties())
            } finally {
                setSavingNotes(false)
            }
        }
    }

    const handleQuillChange = useCallback(
        (value: string, delta: DeltaStatic, source: Sources) => {
            if (sessionWithPetArraySelectedMedicalNoteId) {
                setMedicalNotesEditCache({
                    ...medicalNotesEditCache,
                    [sessionWithPetArraySelectedMedicalNoteId]: value,
                });
            }
            setDisplayActiveNotes(value)
        },
        [medicalNotesEditCache, sessionWithPetArraySelectedMedicalNoteId, setDisplayActiveNotes, setMedicalNotesEditCache]
      );

    async function getDiagramForPDF(): Promise<string|undefined> {
        const element = document.getElementById('diagram');
        if (!element) return undefined;

        // Convert component to an image
        const canvas = await html2canvas(element, { scale: 2, ignoreElements: (element) => element.id === "divider" });
        const imgData = canvas.toDataURL('image/png');
        return imgData
    }

    async function handleDownloadPDF(): Promise<void> {
        try {
            notifyInProgress("Downloading PDF...")
            let image = await getDiagramForPDF()
            let signed_url = await downloadSoapPDF(activeSession?.id ?? "", await getToken({template:"supabase"}) ?? "", image, currentMedicalNoteIdRef.current)
            if(signed_url){
                // Trigger the PDF download
                const link = document.createElement('a');
                link.href = signed_url['signed_url'];
                link.target = '_blank';
                link.setAttribute('download', `${activeSession?.name}_notes.pdf`); // You can specify the file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                va.track("HistoryPage_DownloadSoapPDF", getLoggingProperties())
                notifyCompleted("PDF downloaded!")
            }
            else {
                clearNotification()
                setError("There was an issue downloading the PDF. Please try again.")
                va.track("HistoryPage_DownloadSoapPDF_FailedNoSignedUrl", getLoggingProperties())
            }
        } catch (err) {
            clearNotification()
            setError("There was an issue downloading the PDF. Please try again.")
            va.track("HistoryPage_DownloadSoapPDF_Failed", getLoggingProperties())
        }
    }

    function handleVetSpireExport(open:boolean): void {
        setVetSpireExport(open)
    }

    const handleOnPremPimsExport = (value:boolean) => {
        setOnPremPimsExport(value)
    }

    const notesOptions = useMemo(() => {
        let baseOptions = [{
            name:"Regenerate Notes",
            action: () => handleNotesTemplateChange(true),
            icon: <DocumentTextIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
        },{
            name:"Download PDF",
            action: () => handleDownloadPDF(),
            icon: <ArrowDownCircleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
        },{
            name:"Custom Vocabulary",
            action: () => setAddCustomSpellingModal(true),
            icon: <BookOpenIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
        },{
            name:"Find and Replace",
            action: () => setFindReplaceModal(true),
            icon: <ArrowsRightLeftIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
        }]
        //Pims needs to be on the organization as keys
        if(((organization?.publicMetadata.hasOwnProperty("pims")) && (organization.publicMetadata['pims'] as string).includes("vetspire"))){
            baseOptions.push({
                name:"Export to Vetspire",
                action: () => handleVetSpireExport(true),
                icon: <LinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
            })
        }
        if(pims && (pims === "on-prem-cornerstone") && syncOnPremPimsEnabled){
            baseOptions.push({
                name:`Export to ${pimsDisplayName}`,
                action: () => handleOnPremPimsExport(true),
                icon: <LinkIcon className="mr-3 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"/>
            })
        }
        if(isMobileDevice()){
            baseOptions.push({
                name:"Thumbs Up",
                action: () => handleThumbsInput(true, "Notes"),
                icon: <HandThumbUpIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
            })
            baseOptions.push({
                name:"Thumbs Down",
                action: () => handleThumbsInput(false, "Notes"),
                icon: <HandThumbDownIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
            })
            baseOptions.push({
                name:"Toggle Sections",
                action: () => handleToggleSections(),
                icon: <Squares2X2Icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
            })
        }
        return baseOptions
    }, [user, activeSession])

    function handleNotesToggle(): void {
        setNotesToggle(!notesToggle)
    }

    useEffect(() => {
        if(activeSession?.id !== previousVisit.current?.id)
        {
            setNotesToggle(true)
        }
        previousVisit.current = activeSession
    }, [activeSession])

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth < 640); // 640px is Tailwind's 'sm' breakpoint
        };

        // Check on mount
        checkScreenSize();

        // Add resize listener
        window.addEventListener('resize', checkScreenSize);

        // Cleanup
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    return(<>
        {(notesLoading || loadingSessionInfo) && !activeNotes && !transcriptProcessing && !notesProcessing && 
            <div>
                <Loading text={notesLoading ?? loadingSessionInfo ?? ""}/>
            </div>
        }
        {((transcriptProcessing || notesProcessing) && !notesStreaming && !activeNotes && !notesLoading) && 
            <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-6 mb-8 font-semibold'>
                {transcriptProcessing && !notesProcessing && !notesStreaming && <div>Transcribing recording 🤖...</div>}
                {notesProcessing && !notesStreaming && <div>Generating notes 🤖....</div>}
                <Spinner size="w-12 h-12" timer={true}/>
                <div className="text-sm text-center text-gray-700 font-normal">Notes will be ready within 60s.<br/>Feel free to navigate away if you need to.</div>
            </div>
        }
        {activeNotes && <div  className="flex xl:flex-row flex-col gap-y-4 justify-between gap-x-4 xl:items-center items-end">
            {!didLastSaveFail && notesStreaming && <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                <div className="flex grow items-center justify-center">
                    <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                        <div className="flex flex-row gap-x-4 items-center">
                            <div>
                                <DocumentTextIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="text-sm text-blue-900 font-semibold">We are finishing your notes. Feel free to navigate away, your notes will be ready within 60s or less.</div>
                        </div>
                    </div>
                </div>
            </div>}
            {!didLastSaveFail && !notesStreaming && <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                    <div className="flex grow items-center justify-center">
                        <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                            <div className="flex flex-row gap-x-4 items-center">
                                {!activeDiagram && <div>
                                    <LinkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                </div>}
                                {activeDiagram && <div>
                                    <SparklesIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                </div>}
                                {!activeDiagram && <div className="text-sm text-blue-900 font-semibold">Export your notes to your PIMS! <a className="cursor-pointer underline" href="/integrations">Learn more</a></div>}
                                {activeDiagram && <div className="text-sm text-blue-900 font-semibold">Dental Charts are currently in Beta! Please share feedback on chat or <a className="underline cursor-pointer" href="mailto:support@vetrec.io">email</a>.</div>}
                            </div>
                        </div>
                    </div>
                </div>}
            {didLastSaveFail && <WarningBanner warningText="There was an issue saving the notes. Please try again." />}
            <div className="flex flex-row gap-x-2 w-full sm:w-auto justify-end">
                <button
                    type="button"
                    title={activeSession && activeSession.status.general && activeSession.status.general === "Completed" ? "This session is completed" : "Mark this session as completed"}
                    className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-xs sm:text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 sm:min-w-fit ${activeSession && activeSession.status.general && activeSession.status.general === "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-blue-600 text-white hover:bg-main-button-hover'}`}
                    onClick={() => handleMarkComplete()}
                >
                    <CheckIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                    <span className="hidden sm:block">{activeSession && activeSession.status.general && activeSession.status.general === "Completed" ?  "Completed" : "Mark completed"}</span>
                </button>
                <button
                    data-tour-id="add-to-visit-button"
                    type="button"
                    title="Record additional information for this session which will be added to the notes."
                    className="whitespace-nowrap inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52"
                    onClick={() => handleAddToSession()}
                >
                    <MicrophoneIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                    <span className="hidden sm:block">Add to visit</span>
                </button>
                {davidEnabled && <div className="relative group w-full">
                    <button
                        disabled={multiPetSessionActive}
                        data-tour-id="chat-button"
                        type="button"
                        className={`inline-flex items-center justify-center gap-x-2 rounded-md bg-main-button px-2 py-2.5 font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 text-sm grow w-full sm:max-w-52 ${multiPetSessionActive ? 'bg-main-button/50 text-main-button-text/50 cursor-not-allowed hover:bg-main-button/50' : ''}`}
                        onClick={() => setShowChatBot(true)}
                    >
                        <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-main-button-text" aria-hidden="true" />
                        <span className="hidden sm:block">D.A.V.I.D</span>
                    </button>
                    {multiPetSessionActive && (
                        <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 hidden group-hover:block w-max z-10">
                            <div className="bg-gray-900 text-white text-xs rounded py-1 px-2">
                                D.A.V.I.D for multi-pet sessions coming soon!
                            </div>
                            <div className="w-2 h-2 bg-gray-900 transform rotate-45 absolute left-1/2 -translate-x-1/2 -top-1"></div>
                        </div>
                    )}
                </div>}
                {!notesStreaming && <div data-tour-id={isSmallScreen ? "copy-feedback-toggle" : undefined} className="sm:hidden flex flex-row gap-x-2">
                    {!toggleSectionsError && !notesProcessing && <button
                        type="button"
                        title="Save the notes"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => saveNotes()}>
                        {!savingNotes && (
                            <div className="flex flex-col items-center">
                                <CloudIcon className="h-4 w-4 sm:h-4 sm:w-4" aria-hidden="true" />
                                <span className="text-[10px] font-bold leading-none">Save</span>
                            </div>
                        )}
                        {savingNotes && <Spinner size="h-5 w-5" timer={false}/>}
                    </button>}
                    <button
                        type="button"
                        title="Copy the notes"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => copyToClipboard()}
                    >
                        {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                        {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
                    </button>
                    {!isMobileDevice() && <button
                        type="button"
                        title="Provide positive feedback on notes"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => handleThumbsInput(true, "Notes")}
                    >
                        <HandThumbUpIcon className="h-5 w-5 text-white hover:text-blue-400"/>
                    </button>}
                    {!isMobileDevice() && <button
                        type="button"
                        title="Provide negative feedback on notes"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => handleThumbsInput(false, "Notes")}
                    >
                        <HandThumbDownIcon className="h-5 w-5 text-white hover:text-blue-400"/>
                    </button>}
                    {!isMobileDevice() && <button
                        type="button"
                        title="Toggle Notes Display"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button flex text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => handleToggleSections()}
                    >
                        {toggleSections && <div className="h-4 w-4 border-4 border-white rounded-sm"/>}
                        {!toggleSections && <Squares2X2Icon className="h-5 w-5 text-white"/>}
                    </button>}
                </div>}
                <div className="whitespace-nowrap">
                    <MoreMenu 
                        moreText="Actions" 
                        options={notesOptions} 
                        height="h-10 sm:h-12"
                        buttonDataTourId="actions-button"
                        menuDataTourId="actions-button-menu"
                        disabled={notesStreaming || notesProcessing}
                    />
                </div>
            </div>
        </div>}
        {activeNotes && activePreHistory && <div className="flex flex-col gap-y-4 justify-end w-full px-1">
            <RecordsRecapSummary />
        </div>}
        {activeNotes && <div className="flex flex-col justify-end w-full">
            {multiPetSessionActive && activeSession && (
              <MultiPetTabs
                session={activeSession}
                activePetName={sessionWithPetArraySelectedName ?? ""}
                setActivePetName={handleSelectedPetChange}
                notesNotReady={(notesProcessing || (transcriptProcessing && !activeNotes))}
              />
            )}
            <div className="flex flex-col 2xl:flex-row relative h-full w-full gap-y-4 gap-x-2">
                {activeDiagram && <div className={`lg:h-[68vh] h-[65vh] thin-scrollbar border border-gray-300 rounded-md flex flex-col overflow-y-auto overflow-x-hidden relative ${notesToggle ? 'flex-grow flex-shrink-0' : 'w-full'}`}>
                    {activeSession?.diagram === "CanineDentalExam" && activeDiagram && <CanineDentalVertical dentalResults={activeDiagram} setDentalResults={setActiveDiagram} expandChart={handleNotesToggle} expand={notesToggle}/> }
                    {activeSession?.diagram === "FelineDentalExam" && activeDiagram && <FelineDentalVertical dentalResults={activeDiagram} setDentalResults={setActiveDiagram} expandChart={handleNotesToggle} expand={notesToggle}/>}
                </div>}
                <div className={`flex flex-row ${notesToggle ? "w-full" : ""}`}>
                    {notesToggle && <div className="flex flex-col items-end justify-end w-full">
                        {notesStreaming && <div className="absolute top-2 sm:top-2 right-4 sm:right-4 flex flex-col gap-y-2">
                            <div
                                className="flex flex-row relative items-center gap-x-2 rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold border border-gray-300 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 h-10 overflow-hidden justify-center z-[2] shadow"
                            >
                                <Spinner size="h-5 w-5" timer={false}/>
                                Writing notes... 
                            </div>
                        </div>}
                        {!notesStreaming && anyRecordingsWithNoData && <WarningBanner 
                            className="mb-2"
                            warningText="One or more recordings did not process correctly." 
                        >
                            <button 
                                className="relative rounded-md min-w-40 bg-yellow-600 px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 h-10 overflow-hidden justify-center z-[2] flex flex-row items-center"
                                onClick={() => handleTabChange("transcript")}
                                >
                                See Transcript tab for details
                                <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                            </button>
                        </WarningBanner>}
                        {isImpersonation && !notesStreaming && didUserSaveNotesAfterLatestGeneration && <WarningBanner 
                            className="mb-3 border border-yellow-600 border-dashed"
                            warningText="AI generated notes were edited by user" 
                            closeButtonEnabled
                        >
                            {displayOriginalNotes && <button
                                type="button"
                                className="relative rounded-md min-w-40 bg-yellow-600 px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 h-10 overflow-hidden justify-center z-[2] flex flex-row items-center"
                                onClick={() => setIsOriginalNotesView(!isOriginalNotesView)}
                            >
                                {isOriginalNotesView ? "See latest notes" : "See original notes"}
                            </button>}
                            {!displayOriginalNotes && <div className="text-sm font-semibold text-yellow-700">No original notes found</div>}
                        </WarningBanner>}
                        {!toggleSections && (isOriginalNotesView ? 
                            <ReactQuill 
                                theme='snow'
                                className={`block w-full lg:h-[68vh] h-[65vh] rounded-md border-0 ${isOriginalNotesView ? "bg-gray-100" : ""} text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar`}
                                value={displayOriginalNotes}
                                modules={{toolbar: false}}
                            /> : 
                            <div className="relative w-full">
                                {/* Will come back to this */}
                                {/* {!notesStreaming && !toggleSectionsError && !notesProcessing && 
                                    <SaveButton
                                        isMinimized={saveButtonMinimized}
                                        setIsMinimized={setSaveButtonMinimized}
                                        isOriginalNotesView={isOriginalNotesView}
                                        savingNotes={savingNotes}
                                        onSave={saveNotes}
                                    />
                                } */}
                                <QuillEditorWithToolbar
                                theme='snow'
                                key={sessionWithPetArraySelectedMedicalNoteId}
                                placeholder='Mr.Wiggles is looking mighty well today...'
                                className='block w-full lg:h-[68vh] h-[65vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                                value={displayActiveNotes}
                                modules={{toolbar: false}}
                                onChange={handleQuillChange}
                                onChangeSelection={handleSelectionChange}
                                onAddCustomSpellingPressed={() => setAddCustomSpellingModal(true)}
                                onThumbsDownPressed={() => handleThumbsInput(false, "Notes")}
                                onFindReplacePressed={() => setFindReplaceModal(true)}
                                readOnly={notesStreaming}
                            />
                            </div>
                        )}
                        {toggleSections && !notesSections && <div className="flex flex-col gap-y-4 w-full justify-center items-center mt-8 mb-8">
                            {!toggleSectionsError && <span>Splitting Notes into sections</span>}
                            {!toggleSectionsError && <Spinner size="h-10 w-10" timer={true}/>}
                            {toggleSectionsError && <ErrorBanner errorText="Unable to split notes as the template either does not exist or is inaccessible." />}
                        </div>}
                        {toggleSections && notesSections && <div className="flex flex-col gap-y-4 w-full">
                            {Object.keys(notesSections).filter(key => notesSections[key].length > 0 && !key.includes("vetrec_new_line")).map(key => (
                                <div key={key} className="text-sm text-gray-500 rounded-lg flex flex-col gap-y-2 w-full">
                                    <div className="flex flex-row gap-x-4 items-center">
                                        <span className="font-semibold text-gray-900">
                                            {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ').replace(/\b\w/g, char => char.toUpperCase())}
                                        </span>
                                        <div>
                                            <button
                                            type="button"
                                            className="relative -ml-px inline-flex justify-center items-center gap-x-1.5 rounded-md px-3 py-1 text-xs font-semibold text-main-text ring-1 ring-inset ring-gray-400 bg-main hover:bg-main-lighter w-full"
                                            onClick={() => copyToClipboardSection(key)}
                                            >
                                                {copyKey !== key && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4" aria-hidden="true" />}
                                                {copyKey === key && <Spinner size="h-4 w-4" timer={false}/>}
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                    <div onDoubleClick={() => setWarning("Toggle notes view to edit. The current view is read-only.")}>
                                        <ReactQuill
                                            theme='snow'
                                            placeholder='Mr.Wiggles is looking mighty well today...'
                                            className='block w-full h-full rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                                            value={preprocessText(notesSections[key])}
                                            modules={{toolbar: false}}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>}
                    <div className="hidden sm:flex sm:flex-col gap-y-2 justify-between items-end pl-2 h-full">
                        {!notesStreaming && notesToggle &&  <div data-tour-id={!isSmallScreen ? "copy-feedback-toggle" : undefined} className="flex flex-row sm:flex-col gap-y-2 items-center gap-x-3">
                            {!toggleSectionsError && !notesProcessing && <button
                                type="button"
                                title="Save the notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => saveNotes()}
                            >
                                {!savingNotes && (
                                    <div className="flex flex-col items-center">
                                        <CloudIcon className="h-4 w-4 sm:h-4 sm:w-4" aria-hidden="true" />
                                        <span className="text-[10px] font-bold leading-none">Save</span>
                                    </div>
                                )}
                                {savingNotes && <Spinner size="h-5 w-5" timer={false}/>}
                            </button>}
                            <button
                                type="button"
                                title="Copy the notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => copyToClipboard()}
                            >
                                {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                                {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
                            </button>
                            <button
                                type="button"
                                title="Provide positive feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(true, "Notes")}
                            >
                                <HandThumbUpIcon className="h-5 w-5 text-white"/>
                            </button>
                            <button
                                type="button"
                                title="Provide negative feedback on notes"
                                className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                                onClick={() => handleThumbsInput(false, "Notes")}
                            >
                                <HandThumbDownIcon className="h-5 w-5 text-white"/>
                            </button>
                            <button
                                type="button"
                                title="Toggle Notes View"
                                className="relative flex gap-x-2 rounded-md w-10 bg-main-button text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center items-center z-[2]"
                                onClick={() => handleToggleSections()}
                            >
                                {toggleSections && <div className="h-4 w-4 border-4 border-white rounded-sm"/>}
                                {!toggleSections && <Squares2X2Icon className="h-5 w-5 text-white"/>}
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
            <div className={`flex ${sessionTemplate ? "justify-between" : "justify-end"} w-full items-center`}>
                {sessionTemplate && <div className="text-sm text-gray-600 flex items-center">
                    {activeSessionTemplateUri ? <a
                    href={activeSessionTemplateUri}
                    target="_self"
                    className="ml-2 text-blue-600 hover:text-blue-800 flex items-center underline"
                    >
                        <span>Template: {sessionTemplate.name}</span>
                        <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                    </a> : <span>Template: {sessionTemplate.name}</span>}
                </div>}
                {!sessionTemplate && !toggleSections && <WarningBanner warningText="Notes generated using a template that was deleted or you do not have access to." className="mt-3 mr-3" />}
            </div>
        </div>}
        {activeTranscriptExists && !activeNotes && !notesLoading && (!notesProcessing || notesStreaming || notesStreamCompleted) && <>
            <div className="bg-blue-50 sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6 xl:flex xl:flex-row xl:justify-between xl:item-center">
                    <div>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">{"Generate Notes"}</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>{"Generate your notes for this visit."}</p>
                        </div>
                    </div>
                    <div className="xl:mt-0 mt-5 flex flex-col sm:flex-row md:items-center gap-x-4 gap-y-4">
                        <TemplatePicker template={template} templateId_force={activeSession?.template_id} setTemplate={setTemplate} className="h-10" templateBackground='bg-transparent' type={TemplateType.MEDICAL} external_template_id={activeSession?.external_template_id}/>
                        <button
                            type="button"
                            className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center"
                            onClick={() => processTranscript(activeNotes ? true : false)}
                            disabled={!template}
                        >
                            {!template && <Spinner size="h-5 w-5" timer={false}/>}
                            {template && <div>Generate</div>}
                            {template && <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                        </button>
                    </div>
                </div>
            </div>
        </>}
        <VetSpireModal vetspireExport={vetSpireExport} handleVetSpireExport={handleVetSpireExport} getChart={getDiagramForPDF}/>
        <OnPremPimsSyncModal onPremPimsSyncModalOpen={onPremPimsExport} handleExport={handleOnPremPimsExport} pimsDisplayName={pimsDisplayName}/>
    </>)
}

