import { ExclamationCircleIcon } from "@heroicons/react/24/solid"

import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface ErrorBannerProps {
    children?: React.ReactNode;
    errorText: string;
    closeButtonEnabled?: boolean;
    className?: string;
}

export const ErrorBanner: React.FC<ErrorBannerProps> = (props) => {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) return null;

    return (
        <div className={`rounded-lg bg-red-200 px-4 py-2 shadow grow flex items-center justify-center w-full ${props.className}`}>
            <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                <div className="flex flex-row gap-x-4 items-center">
                    <div>
                        <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="text-sm text-red-900 font-semibold">{props.errorText}</div>
                </div>
                <div className="flex flex-row gap-x-6 items-center">
                    {props.children}
                    {props.closeButtonEnabled && (
                        <button onClick={() => setIsVisible(false)} aria-label="Close error message">
                            <XMarkIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}