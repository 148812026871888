import { ArrowLeftCircleIcon, ArrowPathIcon, ArrowRightCircleIcon, ArrowsUpDownIcon, BoltIcon, CalendarDaysIcon, CloudArrowDownIcon, MicrophoneIcon, NewspaperIcon, PencilSquareIcon, SparklesIcon, TrashIcon } from "@heroicons/react/24/solid"
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid'
import { useNavigate } from "react-router-dom"
import { Spinner } from "./utils/Spinner"
import TemplatePicker from "./components/templates/TemplatePicker"
import SessionOverview from "./SessionOverview"
import Error from "./components/Error"
import UserPicker from "./components/UserPicker"
import Reminder from "./components/ReminderToast"
import Transcript from "./components/history/Transcript"
import PreHistory from "./components/history/PreHistory"
import Datepicker from "react-tailwindcss-datepicker"
import { Discharge } from "./components/history/Discharge"
import { Notes } from "./components/history/Notes"
import { useHistory } from "./providers/HistoryProvider"
import { HistoryTabs } from "./components/history/HistoryTabs"
import TemplateModal from "./components/templates/templateModal"
import CommunicationsTemplateModal from "./components/templates/communicationsTemplateModal"
import FeedbackModal from "./components/history/FeedbackModal"
import TranslateModal from "./components/history/translateModal"
import { TemplateType } from "./components/templates/TemplateUtils"
import CreateVisitModal from "./components/history/createVisitModal"
import DeleteModal from "./components/history/DeleteModal"
import WarningAlert from "./components/WarningAlert"
import RandomPetGraphic from "./components/common/RandomPetGraphic"
import UploadRecordingModal from "./components/history/uploadRecordingModal"
import { Chatbot } from "./components/chatbot/chatbot"
import FindReplaceModal from "./components/history/FindReplaceModal"
import AddCustomSpellingModal from "./components/history/AddCustomSpellingModal"
import { useVetRec } from "./providers/VetRecProvider"
import RegenerateRecapModal from "./components/recordsrecap/RegenerateRecapModal"
import { useState, useEffect, useMemo } from "react"
import { useTour } from "./providers/TourProvider"
import { ScribeBasicTourIdentifiers } from "./components/tours/scribeBasic/config"
import SessionListItem from "./components/history/SessionListItem"
import { allDoctors } from "./utils/MemberUtils"
import { SessionStateEnum } from "./utils/SessionUtils"
import EnumPickerInline from "./components/EnumPickerInline"
import { getPimsDisplayName } from "./utils/PimsUtils"

interface HistoryProps {
    
}

export default function History(props:HistoryProps) {

    const {activeSession, handleHistorySearch, searchDate, tab, setSearchDate, membersList, handleUserSearch, userSearch, listContainerRef, loadingSessions, filteredSessions, sessionFromParam, scrollToItemRef, selectSession, setActiveSession, activeRecordingBackUp, activeTranscriptExists, transcriptProcessing, notesLoading, transcriptLoading, setTemplate, template, error, setError, warning, setWarning, notification, notificationText, notificationLineColor, notificationIconColor, notificationTitle, setNotification, processRecordings, debouncedQuery, recordingCheckLoading, activeRecordingExist, activeSessionUpdated, setCreateVisitModal, setUploadRecordingModal, showChatbot, setShowChatBot, importVisitsFromVetspire, importAppointmentsFromOnPremPims, importingSessions, deleteRecapModal, deleteVisitModal, setDeleteRecapModal, handleDeleteRecap, setDeleteVisitModal, handleDeleteVisit, language, handleTabChange, sortLatest, handleSortLatest, filterState, handleFilterState} = useHistory()
    const { pims, syncVetspireEnabled, syncOnPremPimsEnabled } = useVetRec()
    const  navigate = useNavigate()
    const { currentStep } = useTour()

    const pimsDisplayName = useMemo(() => {
        return getPimsDisplayName(pims)
    }, [pims])

    const showOutOfRangeSession = useMemo(() => {
        return !!activeSession && !!filteredSessions && activeSession.status.general !== "Deleted" && !filteredSessions.some(session => session.id === activeSession.id)
    }, [activeSession, filteredSessions])
        
    const [selectedLanguage, setSelectedLanguage] = useState<string>('English')

    useEffect(() => {
        if (activeSession?.spoken_language) {
            setSelectedLanguage(activeSession.spoken_language)
        }
    }, [activeSession, selectedLanguage])

    useEffect(() => {
        if (tab === "discharge") {
            setSelectedLanguage(activeSession?.spoken_language ?? "English (US)")
        }
    }, [tab, language, activeSession])

    useEffect(() => {
        if (currentStep === ScribeBasicTourIdentifiers.RECORDS_RECAP_TAB) {
            handleTabChange("prehistory")
        }

        if (currentStep === ScribeBasicTourIdentifiers.CLIENT_DISCHARGE_TAB) {
            handleTabChange("discharge")
        }
    }, [currentStep, handleTabChange])

    function getDateWithOffset(input: Date | string, offsetDays: number, startOfDay: boolean = true): Date {
        let date = new Date()
        if(input instanceof Date){
            date = new Date(input.getTime())
        }
        else{
            date = new Date(input  + (startOfDay ? 'T00:00:00' : 'T23:59:59'))
        }

        date.setDate(date.getDate() + offsetDays)
        return date
    }

    function oneLessDay(){
        if(!searchDate || !searchDate.startDate || !searchDate.endDate) return
        const newStartDate = getDateWithOffset(searchDate.startDate, -1)
        const newEndDate = getDateWithOffset(searchDate.endDate, -1)
        setSearchDate({startDate: newStartDate, endDate: newEndDate})
    }

    function oneMoreDay(){
        if(!searchDate || !searchDate.startDate || !searchDate.endDate) return
        const newStartDate = getDateWithOffset(searchDate.startDate, 1)
        const newEndDate = getDateWithOffset(searchDate.endDate, 1)
        setSearchDate({startDate: newStartDate, endDate: newEndDate})
    }

    return(
        <div className="overflow-hidden flex py-0 justify-center sm:-ml-4 lg:-ml-8">
            <div className={`${!activeSession ? 'w-screen' : 'hidden sm:flex w-0 p-0'} min-w-72 sm:w-72 sm:border-r sm:border-gray-200 flex flex-col gap-y-1 mt-4 sm:px-3 h-[90vh] lg:h-[96vh]`} >
                <div className={`p-0 text-xl border-b border-gray-400 font-bold ${!activeSession ? 'block sm:hidden' : 'hidden'}`}>
                    <h2>All Visits</h2>
                </div>
                <div className="flex flex-col gap-y-4 mt-2">
                    <div className="flex justify-center w-full">
                        {<button
                            className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded-md w-full text-sm flex flex-row items-center justify-center gap-x-4"
                            onClick={() => setCreateVisitModal(true)}
                        >
                            <CalendarDaysIcon className='h-4 w-4'/>
                            Schedule a visit
                        </button>}
                    </div>
                    <div className="flex justify-center w-full">
                        {pims.includes("vetspire") && syncVetspireEnabled && <button
                            className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded-md w-full text-sm flex flex-row items-center justify-center gap-x-4 -mt-1"
                            onClick={() => importVisitsFromVetspire()}
                        >
                            <ArrowPathIcon className='h-4 w-4'/>
                            Sync Vetspire
                        </button>}
                        {syncOnPremPimsEnabled && pims && pims === "on-prem-cornerstone" && <button
                            className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded-md w-full text-sm flex flex-row items-center justify-center gap-x-4 -mt-1"
                            onClick={() => importAppointmentsFromOnPremPims()}
                        >
                            <ArrowPathIcon className='h-4 w-4'/>
                            Sync {pimsDisplayName}
                        </button>}
                    </div>
                    <div className="flex flex-row items-center gap-x-2">
                        <div onClick={() => oneLessDay()} className="cursor-pointer">
                            <ArrowLeftCircleIcon className="h-6 w-6 text-blue-600"/>
                        </div>
                        <div className="flex justify-center w-full relative z-[9999] items-center gap-x-2">
                            <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[99] rounded-md">
                                Date
                            </label>
                            <Datepicker 
                                inputClassName={`pl-2 h-10 rounded-lg w-full text-sm border-gray-300 border-1 placeholder:text-gray-400 text-center ${debouncedQuery ? "text-gray-400 bg-gray-100" : ""}`}
                                toggleClassName={"hidden"}
                                asSingle={false} 
                                useRange={false} 
                                value={searchDate} 
                                onChange={async (value) => {
                                    setSearchDate(value)
                                }} 
                                popoverDirection="down"
                                displayFormat={"MM/DD/YYYY"}  
                                placeholder="All Dates"
                                disabled={debouncedQuery ?  true : false}
                                separator=" - "
                            /> 
                        </div>
                        <div onClick={() => {oneMoreDay()}} className={`cursor-pointer`} >
                            <ArrowRightCircleIcon className={`h-6 w-6 text-blue-600`}/>   
                        </div>
                    </div>
                    <div className="w-full relative">
                        <label htmlFor="name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker">
                            Patient's Name
                        </label>
                        <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-full rounded-lg py-1.5 text-gray-900 border-1 border-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6 h-10"
                            placeholder="Search for patient"
                            onChange={(event) => handleHistorySearch(event.target.value)}
                        />
                    </div>
                    {membersList.length > 1 && <UserPicker userList={[allDoctors, ...membersList]} userSearch={userSearch} setUserSearch={(value) => {if(value) handleUserSearch(value)}} className="pr-6 h-10" text="Doctor / Staff"/>}
                </div>
                {showOutOfRangeSession && <div className={`relative sm:pr-0 pr-6 mt-4`}>
                    <ul className="divide-y divide-gray-100 w-full sm:pr-4">
                        <SessionListItem
                            session={activeSession!}
                            activeSession={activeSession}
                            sessionFromParam={sessionFromParam}
                            scrollToItemRef={scrollToItemRef}
                            selectSession={selectSession}
                            compressed={true}
                        />
                    </ul>
                </div>}
                <div className="flex flex-row justify-between items-center sm:pr-2 pr-6 pb-2 mt-3">
                    <EnumPickerInline enum={SessionStateEnum} selectedOption={filterState} setSelectedOption={handleFilterState} width="w-auto" defaultValue={SessionStateEnum.ALL}/>
                    <div className="flex flex-row grow justify-end gap-x-2 items-center text-sm text-gray-500 cursor-pointer" onClick={() => handleSortLatest()}>
                        {sortLatest ? "Newest" : "Oldest"}
                        <ArrowsUpDownIcon className="h-4 w-4 text-gray-500"/>
                    </div>
                </div>
                <div className={`thin-scrollbar overflow-y-auto sm:pr-0 pr-6 mt-4 flex-1`}>
                    <ul ref={listContainerRef} className="divide-y divide-gray-100 w-full sm:pr-4">
                        {loadingSessions && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-4'>
                            Loading visits 🥼...
                            <Spinner size="w-10 h-10" timer={false}/>
                        </div>}
                        {importingSessions && !loadingSessions && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-4'>
                            <div className="text-center">Importing visits from {pimsDisplayName}...</div>
                            <Spinner size="w-10 h-10" timer={false}/>
                        </div>}
                        {filteredSessions && !importingSessions && !loadingSessions && filteredSessions.length > 0 && <div>
                            {filteredSessions.map((session) => (
                                <SessionListItem
                                    key={session.id}
                                    session={session}
                                    activeSession={activeSession}
                                    sessionFromParam={sessionFromParam}
                                    scrollToItemRef={scrollToItemRef}
                                    selectSession={selectSession}
                                />
                            ))}
                        </div>}
                        {!loadingSessions && !importingSessions && filteredSessions && filteredSessions.length === 0 && <li>
                            <div className="flex flex-col gap-y-8 px-4 text-center pt-10 items-center justify-center">
                                <div>
                                    No visits on this day. Change the filters or create a new visit.
                                </div>
                                <button
                                type="button"
                                className="relative -ml-px inline-flex items-center justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-main-text ring-1 ring-inset ring-gray-400 bg-main hover:bg-main-lighter h-12 w-40"
                                onClick={() => navigate('/scribe')}
                                >
                                    <PencilSquareIcon className="h-6 w-6 text-main-text"/>
                                    <span className="block font-semibold">Start a visit</span>
                                </button>
                            </div>
                        </li>}
                    </ul>
                </div>
            </div>
            <div className={`${!activeSession ? 'w-0 hidden' : 'w-full'} sm:w-full sm:block sm:pl-6 sm:pt-6 overflow-x-hidden`}>
                <div className="pt-8 pb-4 sm:hidden sm:py-0 flex flex-row items-center justify-between">
                    <div className="text-xl truncate">
                        <span className="font-semibold">Patient:</span> {activeSession?.name}
                    </div>
                    <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-main-text ring-1 ring-inset ring-gray-400 bg-main hover:bg-main-lighter sm:hidden min-w-40`}
                    onClick={() => setActiveSession(undefined)}
                    >
                        <ArrowLongLeftIcon className="-ml-0.5 h-5 w-5 text-main-text" aria-hidden="true" />
                        Change Session
                    </button>
                </div>            
                {!activeSession && <div className="w-full">
                    <div className="md:flex md:items-center md:justify-between w-full border-b border-gray-400 pb-4 gap-x-8">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                                Select a visit
                            </h2>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                Pick a visit from the history and see the information associated.
                            </p>
                        </div>
                        {/* <div className="inline-flex justify-center items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-2.5 text-sm font-semibold text-accent-button-text shadow-md hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 min-w-36 cursor-pointer"
                        onClick={handleAddPatient}>
                            Patient History
                            <PlusCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        </div> */}
                    </div>
                    <div className="flex flex-col items-center gap-y-4 justify-center h-[80vh]">
                        <div className="text-2xl font-semibold">
                            What can we help with?
                        </div>
                        <div className="flex justify-center items-center w-full">
                            <div className="w-[220px] h-[220px] flex items-center justify-center">
                                <RandomPetGraphic 
                                    heightStyling="max-h-[200px] max-w-[200px] w-auto h-auto object-contain" 
                                    containerClassName="flex justify-center items-center"
                                />
                            </div>
                        </div>
                        <div className="flex justify-center items-start pt-8 overflow-x-hidden">
                            <div className="hidden md:flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 max-w-full px-4">
                                <button onClick={() => navigate('/scribe')} className="flex flex-col items-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-md hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center border-blue-600/50 border-2 bg-blue-100">
                                    <PencilSquareIcon className="h-7 w-7 text-gray-700"/>
                                    <span className="block text-sm font-semibold text-gray-700">Start a visit</span>
                                </button>
                                <button onClick={() => setCreateVisitModal(true)} className="flex flex-col items-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-md hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center border-blue-600/50 border-2 bg-blue-100">
                                    <CalendarDaysIcon className="h-7 w-7 text-gray-700"/>
                                    <span className="block text-sm font-semibold text-gray-700">Schedule a visit</span>
                                </button>
                                <button onClick={() => navigate('/prehistory')} className="flex flex-col items-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-md hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center border-blue-600/50 border-2 bg-blue-100">
                                    <BoltIcon className="h-7 w-7 text-gray-700"/>
                                    <span className="block text-sm font-semibold text-gray-700">Recap Records</span>
                                </button>
                                <button onClick={() => navigate('/templates')} className="flex flex-col items-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-md hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center border-blue-600/50 border-2 bg-blue-100">
                                    <NewspaperIcon className="h-7 w-7 text-gray-700"/>
                                    <span className="block text-sm font-semibold text-gray-700">Edit Templates</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}
                {activeSession && <div className="w-full mt-4 sm:mt-0 flex flex-col">
                    <HistoryTabs disabled={activeSession.status.general === "Deleted"} />
                    {activeSession?.status.general === "Deleted" && (
                        <div className="flex flex-col items-center justify-center h-[75vh] gap-y-4 text-gray-500">
                            <TrashIcon className="h-20 w-20 text-gray-400" />
                            <div className="text-2xl font-semibold">Visit Deleted</div>
                            <div className="text-center">
                                This visit has been deleted and is no longer available.
                                <br />
                                Please select another visit from the list.
                            </div>
                        </div>
                    )}
                    {activeSession.status.general !== "Deleted" && <div className="mt-2 flex flex-col h-[75vh] sm:h-[82vh] lg:h-[88vh] overflow-y-auto thin-scrollbar justify-between -mr-4 pr-4 relative">
                        <div className="flex flex-col gap-y-4 w-full relative">
                            {(!activeTranscriptExists) && !transcriptProcessing && (!transcriptLoading && !notesLoading && !recordingCheckLoading) && tab !== "prehistory" && <div className="bg-blue-50 sm:rounded-lg">
                                    {(activeRecordingExist || activeRecordingBackUp) && <div className="px-4 py-5 sm:p-6 sm:flex sm:flex-row sm:justify-between sm:items-center">
                                        <div>
                                            <h3 className="text-base font-semibold leading-6 text-gray-900">{activeSessionUpdated ? "Done updating?" : "Done recording?"}</h3>
                                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                <p>{activeSessionUpdated ? "Process updates to this visit" : "Process the recordings for this visit"}</p>
                                            </div>
                                        </div>
                                        <div className="sm:mt-0 mt-5 flex flex-col sm:flex-row sm:items-center gap-x-4 gap-y-4 sm:justify-center">
                                            <TemplatePicker template={template} templateId_force={activeSession.template_id} setTemplate={setTemplate} className="h-10" templateBackground='bg-[linear-gradient(to_bottom,transparent_50%,white_50%)]' type={TemplateType.MEDICAL}/>
                                            <button
                                                type="button"
                                                className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center"
                                                onClick={() => processRecordings(activeSessionUpdated, selectedLanguage, template)}
                                                disabled={!template}
                                            >
                                                {!template && <Spinner size="w-5 h-5" timer={true}/>}
                                                {template && <div>
                                                    {activeSessionUpdated ? "Update" : "Process"}
                                                </div>}
                                                {template && <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                                            </button>
                                        </div>
                                    </div>}
                                    {!activeRecordingExist && !activeRecordingBackUp && tab !== "prehistory" && <div className="px-4 py-5 sm:p-6 flex flex-col gap-y-6 items-center bg-blue-100 rounded-md text-center">
                                        <div className="flex items-center justify-start">
                                            <div className="ml-3 text-sm text-blue-900">
                                                <p>Looks like there are <span className="font-semibold">no recordings</span> for this visit.<br/>Create a recording by pressing the <span className="font-semibold">Add to visit</span> button.</p>
                                            </div>
                                        </div>
                                        {tab !== "overview" && <div className="flex flex-row gap-x-4">
                                            <button
                                                type="button"
                                                title="Record additional information for this session which will be added to the notes."
                                                className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 overflow-hidden sm:w-48 w-full justify-center h-10 sm:h-12"
                                                onClick={() => setUploadRecordingModal(true)}
                                            >
                                                <CloudArrowDownIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                                Upload Recording
                                            </button>
                                            <button
                                                type="button"
                                                title="Record additional information for this session which will be added to the notes."
                                                className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 overflow-hidden sm:w-48 w-full justify-center h-10 sm:h-12"
                                                onClick={() => navigate("/scribe?session=" + activeSession?.id)}
                                            >
                                                <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                                Add to visit
                                            </button>
                                        </div>}
                                    </div>}
                            </div>}
                            <Chatbot isOpen={showChatbot} onClose={() => setShowChatBot(false)}/>
                            {tab === "overview" && <SessionOverview />}
                            {tab === "prehistory" && <PreHistory />}
                            {tab === "transcript" && <Transcript />}
                            {tab === "notes" && <Notes />}
                            {tab === "discharge" && <Discharge />}
                        </div>
                        <div className='text-xs text-center sm:text-start mb-4 mt-4'>
                            <span className='font-semibold'>Visit ID:</span> {activeSession.id}
                        </div>
                    </div>}
                </div>}
            </div>
            <Error error={error} setError={setError}/>
            <WarningAlert warning={warning} setWarning={setWarning} />
            <Reminder show={notification} hide={() => setNotification(false)} title={notificationTitle ?? ""} text={notificationText ?? "" } lineColor={notificationLineColor} iconColor={notificationIconColor}/>
            <TemplateModal />
            <CommunicationsTemplateModal />
            <RegenerateRecapModal />
            <TranslateModal />
            <FeedbackModal />
            <FindReplaceModal />
            <AddCustomSpellingModal />
            <CreateVisitModal />
            <DeleteModal 
                showModal={deleteVisitModal} 
                setShowModal={setDeleteVisitModal} 
                handleDelete={handleDeleteVisit} 
                title="Delete Visit" 
                description="You are about to delete the visit" 
                patientName={activeSession?.name ?? "this pet"}
            />
            <DeleteModal 
                showModal={deleteRecapModal} 
                setShowModal={setDeleteRecapModal} 
                handleDelete={handleDeleteRecap} 
                title="Delete Recap" 
                description="You are about to delete the recap" 
                patientName={activeSession?.name ?? "this pet"}
            />
            <UploadRecordingModal />
        </div>
    )
}