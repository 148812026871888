import {
  MegaphoneIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  FolderPlusIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/solid";
import { RecapInteractiveWidgets } from "./RecapInteractiveWidgets";
import { SummarizedHistoryView } from "./SummarizedHistoryView";
import MoreMenu from "../moreMenu";
import { BoltIcon, MicrophoneIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useHistory } from "../../providers/HistoryProvider";
import { useVetRec } from "../../providers/VetRecProvider";
import { useMemo, useState } from "react";
import { preBuildRecordsRecapTemplate } from "../../utils/prebuiltTemplates";
import { downloadPreHistoryPDF } from "../../ServerActions";
import { useAuth } from "@clerk/clerk-react";
import va from "@vercel/analytics";

export function RecapView() {
  const {
    activeSession,
    preHistoryLoading,
    preHistoryStreaming,
    activePreHistory,
    shouldShowPreHistoryMoreMessage,
    handleAddToSession,
    handleMarkComplete,
    notifyCompleted,
    notifyInProgress,
    clearNotification,
    setError,
    handleThumbsInput,
    setShowChatBot,
    handleRegenerateRecordsRecap,
    setDeleteRecapModal,
    sessionPrehistoryTemplateName,
  } = useHistory();

  const { davidEnabled } = useVetRec();

  const { getToken } = useAuth();

  const [pdfDownloading, setPdfDownloading] = useState<boolean>(false);

  const activeSessionTemplateUri = useMemo(() => {
    const templateIdSplit =
      activeSession?.prehistory_metadata?.template_id?.split("/");
    if (templateIdSplit && templateIdSplit.length > 0) {
      const templateIdWithDynamic =
        templateIdSplit.length === 1 ? templateIdSplit[0] : templateIdSplit[1];
      const templateId = templateIdWithDynamic?.replace("dynamic_", "");

      if (templateId) {
        const uri = `templateBuilder?templateId=${templateId}`;

        if (
          preBuildRecordsRecapTemplate.find(
            (t) => t.id === templateIdWithDynamic
          )
        ) {
          return uri + "&duplicate=true";
        } else {
          return uri;
        }
      }
    }

    return undefined;
  }, [activeSession]);

  async function handleDownloadPDF(): Promise<void> {
    setPdfDownloading(true);
    notifyInProgress("Downloading PDF...");

    try {
      let signed_url = await downloadPreHistoryPDF(
        activeSession?.id ?? "",
        (await getToken({ template: "supabase" })) ?? ""
      );
      if (signed_url) {
        // Trigger the PDF download
        const link = document.createElement("a");
        link.href = signed_url["signed_url"];
        link.target = "_blank";
        link.setAttribute("download", `${activeSession?.name}_notes.pdf`); // You can specify the file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        let properties = {
          date: new Date().toUTCString(),
          session: activeSession?.id ?? "No Session",
        };
        va.track("HistoryPage_DownloadPreHistoryPDF", properties);
        notifyCompleted("PDF Downloaded!");
      } else {
        clearNotification();
        setError("There was an issue downloading the PDF. Please try again.");
      }
    } finally {
      setPdfDownloading(false);
    }
  }

  return (
    <div className="">
      <div className="flex xl:flex-row flex-col gap-y-4 justify-between gap-x-4 mb-4 xl:items-center items-end">
        <div className="rounded-lg bg-blue-50 px-4 shadow min-h-12 grow flex items-center justify-center w-full">
          <div className="flex grow items-center justify-center">
            <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
              <div className="flex flex-row gap-x-4 items-center">
                <div>
                  {shouldShowPreHistoryMoreMessage ? (
                    <MegaphoneIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <InformationCircleIcon
                      className="h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="text-sm text-blue-900">
                  {shouldShowPreHistoryMoreMessage ? (
                    <>
                      Click <span className="font-semibold">Actions</span> to
                      regenerate this recap, add recordings to this visit and
                      more!
                    </>
                  ) : (
                    <>
                      <span className="font-semibold">Records Recap</span> with
                      custom templates is still in beta. Use the thumbs up and
                      down buttons to give us your feedback
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-x-2 w-full sm:w-auto justify-end">
          <button
            type="button"
            title={
              activeSession &&
              activeSession.status.general &&
              activeSession.status.general === "Completed"
                ? "This session is completed"
                : "Mark this session as completed"
            }
            className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-xs sm:text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 sm:min-w-fit ${
              activeSession &&
              activeSession.status.general &&
              activeSession.status.general === "Completed"
                ? "bg-green-600 hover:bg-green-500 font-semibold text-white"
                : "bg-blue-600 text-white hover:bg-main-button-hover"
            }`}
            onClick={() => handleMarkComplete()}
          >
            <CheckCircleIcon
              className="-mr-0.5 h-5 w-5 block sm:hidden"
              aria-hidden="true"
            />
            <span className="hidden sm:block">
              {activeSession &&
              activeSession.status.general &&
              activeSession.status.general === "Completed"
                ? "Completed"
                : "Mark completed"}
            </span>
          </button>
          {davidEnabled && (
            <button
              type="button"
              className="inline-flex items-center justify-center gap-x-2 rounded-md bg-main-button px-2 py-2.5 font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 text-sm grow w-full sm:max-w-52"
              onClick={() => setShowChatBot(true)}
            >
              <ChatBubbleBottomCenterTextIcon
                className="h-5 w-5 text-main-button-text"
                aria-hidden="true"
              />
              <span className="hidden sm:block">D.A.V.I.D</span>
            </button>
          )}
          {!preHistoryLoading && !preHistoryStreaming && (
            <button
              type="button"
              title="Upload additional files to summarize and add to this recap."
              className="whitespace-nowrap inline-flex items-center gap-x-2 rounded-md bg-main-button px-2 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 justify-center grow w-full sm:max-w-52"
              onClick={() => handleRegenerateRecordsRecap(true)}
            >
              <FolderPlusIcon className="h-5 w-5" aria-hidden="true" />
              <span className="hidden sm:block">Add files</span>
            </button>
          )}
          <RecapInteractiveWidgets
            classes="sm:hidden flex flex-row gap-x-2"
            preHistoryStreaming={preHistoryStreaming}
            preHistoryProcessingMessage={undefined}
            activePreHistory={activePreHistory}
            handleThumbsInput={handleThumbsInput}
            handleDownloadPDF={handleDownloadPDF}
            pdfDownloading={pdfDownloading}
          />
          {!preHistoryLoading && !preHistoryStreaming && (
            <div className="whitespace-nowrap">
              <MoreMenu
                moreText="Actions"
                options={[
                  {
                    name: "Regenerate Recap",
                    action: () => handleRegenerateRecordsRecap(false),
                    icon: (
                      <BoltIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                    ),
                  },
                  {
                    name: "Add Recording",
                    action: handleAddToSession,
                    icon: (
                      <MicrophoneIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                    ),
                    description:
                      "Record additional information for this visit which will be added to the notes.",
                  },
                  {
                    name: "Delete Recap",
                    action: () => setDeleteRecapModal(true),
                    icon: (
                      <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                    ),
                  },
                ]}
                height="h-10 sm:h-12"
              />
            </div>
          )}
        </div>
      </div>
      {activeSession && activePreHistory && (
        <div className="flex flex-row gap-x-2 w-full sm:w-auto">
          <SummarizedHistoryView />
          <RecapInteractiveWidgets
            classes="hidden sm:flex sm:flex-col gap-y-2 justify-between items-end pl-2 h-full"
            preHistoryStreaming={preHistoryStreaming}
            preHistoryProcessingMessage={undefined}
            activePreHistory={activePreHistory}
            handleThumbsInput={handleThumbsInput}
            handleDownloadPDF={handleDownloadPDF}
            pdfDownloading={pdfDownloading}
          />
        </div>
      )}
      {sessionPrehistoryTemplateName && (
        <div className="mt-6 text-sm text-gray-600 flex items-center">
          {activeSessionTemplateUri ? (
            <a
              href={activeSessionTemplateUri}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 text-blue-600 hover:text-blue-800 flex items-center underline"
            >
              <span>Template: {sessionPrehistoryTemplateName}</span>
              <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
            </a>
          ) : (
            <span>Template: {sessionPrehistoryTemplateName}</span>
          )}
        </div>
      )}
    </div>
  );
}
