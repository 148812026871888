import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplatePicker from './TemplatePicker'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { useHistory } from '../../providers/HistoryProvider'
import { TemplateType } from './TemplateUtils'
import { isMobileDevice } from '../../utils/deviceUtils'
import { WarningBanner } from '../common/WarningBanner'
import { Spinner } from '../../utils/Spinner'

export default function TemplateModal() {

    const {changeTemplate, handleNotesTemplateChange, template, setTemplate, processTranscript, activeSession, didUserSaveNotesAfterLatestGeneration} = useHistory()
    const isMobile = useMemo(isMobileDevice, [])
    const [additionalInstructions, setAdditionalInstructions] = useState<string>()

    function handleTemplateChange(additionalInstructions?: string) {
        handleNotesTemplateChange(false)
        processTranscript(true, additionalInstructions)
    }

    useEffect(() => {
        setAdditionalInstructions(undefined)
    }, [changeTemplate])
    
    return (
        <Transition.Root show={changeTemplate} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={handleNotesTemplateChange}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <ArrowPathIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Generate Notes
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Reprocess your notes with a different template.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex flex-col gap-x-2 gap-y-4">
                            <TemplatePicker template={template} templateId_force={activeSession?.template_id} setTemplate={setTemplate} className={""} pickerWidth={'w-full'} templateBackground={"bg-white"} type={TemplateType.MEDICAL} pickerDirection={isMobile ? "above" : "below"} external_template_id={activeSession?.external_template_id}/>
                            <div className='relative'>
                                <label
                                    htmlFor="name"
                                    className={`absolute -top-2 left-2 px-1 text-xs font-medium text-main-text-darker z-[0] bg-white flex gap-x-2`}
                                >
                                    Additional Instructions <span className='bg-blue-600 text-white px-2 rounded-md'>Beta</span>
                                </label>
                                <textarea
                                    className='w-full border border-gray-300 rounded-md p-4 text-sm text-gray-600 z-[-1]'
                                    value={additionalInstructions}
                                    onChange={(event) => setAdditionalInstructions(event.target.value)}
                                    placeholder={"[Optional] Let VetRec know any additional instructions for the notes."}
                                />
                            </div>
                            {didUserSaveNotesAfterLatestGeneration && <WarningBanner warningText="Regenerating notes will override any manual edits you made." className=""/>}
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10"
                                onClick={() => handleTemplateChange(additionalInstructions)}
                                disabled={!template}
                            >
                                {template ? "Generate" : <Spinner size="h-5 w-5 text-white" timer={false}/>}
                            </button>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}