import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { ContentType } from './TemplateUtils'
import { SUPER_HIGH_Z_INDEX } from '../tours/constants'

interface ContentTypePickerProps{
    contentType:ContentType
    setContentType: (type:ContentType) => void
    subsection?:boolean
    raiseZIndex?:boolean
    buttonDataTourId?:string
    disabled?:boolean
}

export default function ContentTypePicker(props:ContentTypePickerProps) {

    const contentTypeOptions = [
        { name: 'Paragraph', value: ContentType.PARAGRAPH },
        { name: 'Number', value: ContentType.NUMBER },
        { name: 'List', value: ContentType.LIST },
        { name: 'Fixed text', value: ContentType.HARDCODED },
    ];
    
    if (props.subsection) {
        contentTypeOptions.push({ name: 'Subsection', value: ContentType.SUBSECTION });
    }
      
    const backwardsCompatibleContentTypeOptions: { [key in ContentType]: string } = {
        [ContentType.PARAGRAPH]: "Paragraph",
        [ContentType.NUMBER]: "Number",
        [ContentType.LIST]: "List",
        [ContentType.SUBSECTION]: "Subsection",
        [ContentType.HARDCODED]: 'Fixed text',
    }

    return (<div className={`w-36 lg:w-48 h-8 ${props.raiseZIndex ? SUPER_HIGH_Z_INDEX : ""}`}>
        <Listbox value={props.contentType} onChange={props.setContentType} disabled={props.disabled}>
            {({ open }) => (<>
                {open && <div className="absolute ml-52 p-2 text-gray-900 bg-gray-100 rounded-md shadow-lg border border-gray-300 w-72 lg:w-96 z-[999] text-sm">
                    <div className='flex flex-col gap-y-2'>
                        <div className='font-semibold'>Entry Types</div>
                        <ul className='list-disc list-inside'>
                            <li><span className='font-semibold'>Paragraph: </span>Simple text entry</li>
                            <li><span className='font-semibold'>Number: </span>Simple number entry</li>
                            <li><span className='font-semibold'>List: </span>List of text entries</li>
                            <li><span className='font-semibold'>Fixed Text: </span>Value will be used as provided</li>
                            <li><span className='font-semibold'>Subsection: </span>Nested section of entries</li>
                        </ul>
                    </div>
                </div>}
                <div className="relative">
                <Listbox.Button data-tour-id={props.buttonDataTourId} className="relative w-full rounded-md bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm cursor-pointer items-center  flex border border-gray-300 h-8">
                    <span className="block truncate">{backwardsCompatibleContentTypeOptions[props.contentType]}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm cursor-pointer z-50">
                        {contentTypeOptions.map((option, optionIdx) => (
                            <Listbox.Option
                                key={optionIdx}
                                className={({ active }) =>
                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                    active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                                    }`
                                }
                                value={option.value}
                            >
                                {({ selected }) => (
                                    <>
                                    <span
                                        className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                        }`}
                                    >
                                        {option.name}
                                    </span>
                                    {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </>)}
        </Listbox>
        </div>
    )
}