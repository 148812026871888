import { useState, useEffect, useMemo } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { deleteTemplate, fetchOrgTemplates, fetchSuperOrgTemplates, fetchTemplateLibrary, fetchTemplates, postDefaultDischargeTemplate, postDefaultRecordsRecapTemplate, postDefaultTemplate, shareTemplateSuperOrg, templateRequest } from './ServerActions';
import { ChevronDownIcon, ChevronUpIcon, DocumentDuplicateIcon, ExclamationCircleIcon, ExclamationTriangleIcon, PlusCircleIcon, ShareIcon, StarIcon, TrashIcon, VideoCameraIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { TemplateObject } from './utils/TemplateObject';
import { Spinner } from './utils/Spinner';
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react"
import va from '@vercel/analytics';
import { classNames } from './utils/ClassUtils';
import { useNavigate } from 'react-router-dom';
import Reminder from './components/ReminderToast';
import MoreMenu, { MoreMenuOption } from './components/moreMenu';
import TemplateDeleteModal from './components/templates/TemplateDeleteModal';
import ErrorAlert from './components/Error';
import { showDeleteTemplateOption, getTemplateTypeColor, getTemplateTypeDisplayName, isVetRecOwner, TemplateType } from './components/templates/TemplateUtils';
import { preBuildRecordsRecapTemplate, preBuiltDischargeTemplate, preBuiltTemplate } from './utils/prebuiltTemplates';
import { Switch } from '@headlessui/react';
import { useVetRec } from './providers/VetRecProvider';
import { useTour } from './providers/TourProvider';
import { TOUR_IDS } from './components/tours/constants';
import TemplateCreateModal from './components/templates/TemplateCreateModal';
import TemplateShareModal from './components/templates/TemplateShareModal';
import { TemplatesOverviewTourIdentifiers } from './components/tours/templates/config';
import { CallBackProps } from 'react-joyride';
import { ACTIONS } from 'react-joyride';
import { WarningBanner } from './components/common/WarningBanner';

// Define the types for your props
interface TemplatesProps {

}

enum TemplateSource {
    custom = "custom",
    soap_template = "basic",
    soap_advanced = "advanced"
}

export default function Templates(props:TemplatesProps) {
    // Providers
    const { getToken, orgId} = useAuth();
    const { user } = useUser()
    const  navigate = useNavigate()
    const {organization} = useOrganization()
    const { createEnabled, editEnabled, updatePreBuiltEnabled, prebuiltEnabled, organizationAdmin, preBuiltControlledByOrg, shareEnabled, defaultDischargeTemplate, defaultRecordsRecapTemplate, defaultTemplate, setDefaultDischargeTemplate, setDefaultTemplate, setDefaultRecordsRecapTemplate, superOrg, checkTourStatus, trialExpired} = useVetRec()

    // State
    const [loadingTemplates , setLoadingTemplates] = useState<string|undefined>(undefined)
    const [templates, setTemplates] = useState<TemplateObject[]>()
    const [filteredTemplates, setFilteredTemplates] = useState<TemplateObject[]>()
    const [orgTemplates, setOrgTemplates] = useState<TemplateObject[]>()
    const [filteredOrgTemplates, setFilteredOrgTemplates] = useState<TemplateObject[]>()
    const [superOrgTemplates, setSuperOrgTemplates] = useState<TemplateObject[]>()
    const [filteredSuperOrgTemplates, setFilteredSuperOrgTemplates] = useState<TemplateObject[]>()
    const [templateLibrary, setTemplateLibrary] = useState<TemplateObject[]>()
    const [templateLibraryFiltered, setTemplateLibraryFiltered] = useState<TemplateObject[]>()
    const [preBuiltTemplatesFiltered, setPreBuiltTemplatesFiltered] = useState<TemplateObject[]>()
    const [error, setError] = useState<string|undefined>(undefined)
    const createEnabledPermission = useMemo<boolean>(() => (createEnabled || organizationAdmin),[createEnabled, organizationAdmin])
    const editEnabledPermission = useMemo<boolean>(() => (editEnabled || organizationAdmin),[editEnabled, organizationAdmin])
    const shareTemplatePermission = useMemo<boolean>(() => (shareEnabled),[shareEnabled])
    const [sending, setSending] = useState<boolean>(false)
    const [sent, setSent] = useState<boolean>(false)
    const [request, setRequest] = useState<boolean>(false)
    const [templateDelete, setTemplateDelete] = useState<boolean>(false)
    const [templateToDelete, setTemplateToDelete] = useState<string>()
    const [tab, setTab] = useState<string>("yours")
    const [type, setType] = useState<string>("all")
    const [search, setSearch] = useState<string>()
    
    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
    const [templateToShare, setTemplateToShare] = useState<string>()
    
    // Onboarding Tour
    const { startTour, isTourRunning, currentStep, setCurrentStep, tourId, setIsTourHidden } = useTour()

    const filterTemplates = (templates: TemplateObject[], type: string, search: string = "") => {
        let filteredTemplates = templates;

        if (type !== "all") {
            filteredTemplates = filteredTemplates.filter(template => template.type === type || template.tag?.includes(type));
        }

        if (search) {
            const regex = new RegExp(search, 'i'); // 'i' for case-insensitive search
            filteredTemplates = filteredTemplates.filter(template => regex.test(template.name));
        }

        return filteredTemplates;
    }

    const getTemplates = async () => {
        setLoadingTemplates("Loading templates 📃...")
        let temp_templates : TemplateObject[] = await fetchTemplates(await getToken({template:"supabase"}) ?? '')
        let org_templates : TemplateObject[] = organization ? await fetchOrgTemplates(await getToken({template:"supabase"}) ?? '') : []
        let super_org_templates : TemplateObject[] = superOrg ? await fetchSuperOrgTemplates(await getToken({template:"supabase"}) ?? "") : []
        let template_library:TemplateObject[] = await fetchTemplateLibrary(await getToken({template:"supabase"}) ?? "")
        let english_templates = template_library.filter(template => !template.id.includes("spanish"))
        temp_templates = temp_templates.filter(template => !org_templates.some(template2 => template2.id === template.id))
        temp_templates = temp_templates.filter(template => !super_org_templates.some(template2 => template2.id === template.id))
        org_templates = org_templates.filter(template => !super_org_templates.some(template2 => template2.id === template.id))
        setTemplateLibraryFiltered(filterTemplates(english_templates, type, search))
        setTemplateLibrary(english_templates)
        setTemplates(temp_templates)
        setFilteredTemplates(filterTemplates(temp_templates, type, search))
        setSuperOrgTemplates(super_org_templates)
        setFilteredSuperOrgTemplates(filterTemplates(super_org_templates, type, search))
        setOrgTemplates(org_templates)
        setFilteredOrgTemplates(filterTemplates(org_templates, type, search))
        setLoadingTemplates(undefined)
    }

    useEffect(() => {
        getTemplates()
    }, [orgId])

    useEffect(() => {
        setFilteredTemplates([...filterTemplates(templates ?? [], type, search)])
        setFilteredOrgTemplates([...filterTemplates(orgTemplates ?? [], type, search)])
        setFilteredSuperOrgTemplates([...filterTemplates(superOrgTemplates ?? [], type, search)])
        setTemplateLibraryFiltered([...filterTemplates(templateLibrary ?? [], type, search)])
        setPreBuiltTemplatesFiltered([...filterTemplates([...preBuiltTemplate, ...preBuildRecordsRecapTemplate, ...preBuiltDischargeTemplate], type, search)])
    }, [type, search])

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (error) {
            timer = setTimeout(() => {
                setError(undefined);
            }, 3000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [error]);

    // Tour
    useEffect(() => {
        const hasCompletedTour = checkTourStatus(TOUR_IDS.TEMPLATES_OVERVIEW)
        if (isTourRunning || hasCompletedTour) {
            return;
        }

        if(
            !loadingTemplates 
            && createEnabledPermission 
            && trialExpired === false
            // If there are no templates, we don't want to show the tour
            && ((templates?.length ?? 0) > 0 || (orgTemplates?.length ?? 0)  > 0 || prebuiltEnabled) 
            ){
            startTour({tourId: TOUR_IDS.TEMPLATES_OVERVIEW, stepHandler: {
                [TemplatesOverviewTourIdentifiers.TEMPLATES_SEARCH_BAR]: async (data: CallBackProps) => {
                    if (data.action === ACTIONS.NEXT) {
                        setSearch("")
                        await sleep(20)
                    }
                },
                [TemplatesOverviewTourIdentifiers.TEMPLATES_TYPES_FILTER]: async (data: CallBackProps) => {
                    if (data.action === ACTIONS.NEXT) {
                        setType("all")
                    }
                },
                [TemplatesOverviewTourIdentifiers.TEMPLATE_ACTIONS]: async (data: CallBackProps) => {
                    if (data.action === ACTIONS.NEXT) {
                        setTab("library")
                    }
                },
                [TemplatesOverviewTourIdentifiers.TEMPLATE_LIBRARY]: async (data: CallBackProps) => {
                    if (data.action === ACTIONS.PREV) {
                        setTab("yours")
                        setType("all")
                        await sleep(20)
                    }
                }
            }})
        }
    }, [isTourRunning, loadingTemplates, trialExpired, templates])

    function getTourIdOnTemplateCardCollections(name: string) {
        const templateCollections = [
            {
                templates: filteredOrgTemplates,
                name: "Team templates",
            },
            {
                templates: filteredTemplates,
                name: "Your templates"
            },
            {
                templates: preBuiltTemplatesFiltered,
                name: "Pre-built templates"
            }
        ];

        // Find the first non-empty collection
        const firstNonEmptyCollection = templateCollections.find(collection => 
            (collection.templates?.length ?? 0) > 0
        );

        // Return template-card only if this is the first non-empty collection
        return firstNonEmptyCollection?.name === name ? "template-card" : undefined;
    }

    function createTemplateBuilder(): void {
        setCreateModalOpen(true)
    }

    async function editTemplate(template: TemplateObject): Promise<void> {
        if(isVetRecOwner(template.owner)){
            navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}&duplicate=true`)
        }
        else{
            navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}`)
        }
    }

    async function duplicateTemplate(template: TemplateObject, type:TemplateSource): Promise<void> {
        if(isVetRecOwner(template.owner)){
            navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}&duplicate=true`)
            let properties =  {
                date:(new Date()).toUTCString(),
                template: template.id,
            }
            va.track(`Template_Library_Dupe`, properties)
        }
        else{
            navigate(`/templateBuilder?templateId=${template.id.split("dynamic_")[1]}&duplicate=true`)
        }
    }

    async function handleDeleteTemplate(template_id:string): Promise<void> {
        setTemplateDelete(true)
        setTemplateToDelete(template_id)
    }

    async function handleShareTemplate(template_id:string): Promise<void> {
        setShareModalOpen(true)
        setTemplateToShare(template_id)
        let properties =  {
            date:(new Date()).toUTCString(),
            template: template_id,
        }
        va.track(`Template_Shared`, properties)
    }
    
    async function handleSuperOrgShare(templateId:string, add:boolean): Promise<void> {
        await shareTemplateSuperOrg(templateId, add, await getToken({template:"supabase"}) ?? "" )
        getTemplates()
    }

    async function confirmDeleteTemplate() {
        await deleteTemplate(templateToDelete ?? "", await getToken({template:"supabase"}) ?? "" )
        setTemplateDelete(false)
        setTemplateToDelete(undefined)
        getTemplates()
    }

    async function handleDefaultTemplate(template_id:string, type:string): Promise<void>{
        if(type === TemplateType.MEDICAL){
            setDefaultTemplate(template_id)
            await postDefaultTemplate(template_id,  await getToken({template:"supabase"}) ?? "")   
        } else if(type === TemplateType.COMMUNICATIONS){
            setDefaultDischargeTemplate(template_id)
            await postDefaultDischargeTemplate(template_id,  await getToken({template:"supabase"}) ?? "")
        } else if (type === TemplateType.RECORDSRECAP) {
            setDefaultRecordsRecapTemplate(template_id)
            await postDefaultRecordsRecapTemplate(template_id,  await getToken({template:"supabase"}) ?? "")
        }
        let properties =  {
            date:(new Date()).toUTCString(),
            type: type,
        }
        va.track(`Template_Default`, properties)
    }

    function sleep(ms: number | undefined) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function handleTemplateRequest(){
        setRequest(true)
        setSending(true)
        await templateRequest(await getToken({template:"supabase"}) ?? "")
        await sleep(500)
        setSent(true)
        setSending(false)
        await sleep(500)
        setSent(false)
        let properties =  {
            date:(new Date()).toUTCString(),
        }
        va.track(`Template_Request`, properties)
    }

    function capitalize(s: string) {
        if (typeof s !== 'string') return ''
        if (s.length === 2) return s.toUpperCase()
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    function handleTabChange(tab:string){
        setType("all")
        setTab(tab)
    }

    function handleTypeChange(type:string){
        setType(type)
    }

    interface TemplateItemProps {
        template:TemplateObject
        options:MoreMenuOption[]
        includeOwner?:boolean
        dataTourId?: string
    }
    
    const TemplateItem: React.FC<TemplateItemProps> = ({ template, options, includeOwner, dataTourId }) => {
        const possibleDefaults = [defaultTemplate, defaultDischargeTemplate, defaultRecordsRecapTemplate]
        return(
            <li key={template.id} className="col-span-1 flex flex-row items-center rounded-md shadow-sm h-20">
                <div className="bg-main flex w-16 h-full flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-main-text">
                    {possibleDefaults.includes(template.id) ? <StarIcon className='h-5 w-5' /> : (template.name.match(/[A-Za-z0-9]/)?.[0] || '').toUpperCase()}
                </div>
                <div className={`flex flex-1 min-w-0 h-full pl-4 pr-2 py-2 items-center justify-between rounded-r-md border-b border-r border-t border-gray-400 hover:bg-gray-200 ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "text-gray-500 bg-gray-200" : "bg-white"}`}>
                    <div className={`flex-1 min-w-0 truncate text-sm ${template.id.includes("vetworks") || !template.id.includes("dynamic") || !editEnabledPermission ? "pointer-events-none" : "cursor-pointer"} flex flex-col gap-y-1`} onClick={() => editTemplate(template)}>
                        {template.name}
                        {includeOwner && <div className='text-xs text-gray-500'>Owner: {template.owner}</div>}
                        <span className={`px-2 py-0.5 ${template.type ? `bg-${getTemplateTypeColor(template.type)}-100` : "bg-gray-100"} rounded-md w-fit`}>{getTemplateTypeDisplayName(template.type ?? "")}</span>
                        {template.tag}
                        <span className='text-xs'>{template.id.includes("vetworks") || !template.id.includes("dynamic") ? "Not available for editing. Contact support@vetrec.io." : ""}</span>
                    </div>
                    <MoreMenu 
                      menuDataTourId={dataTourId} 
                      buttonDataTourId={dataTourId ? `${dataTourId}-button` : undefined} 
                      options={options} 
                      height="h-12 border-0"
                    />
                </div>
            </li>
        )
    }

    interface TemplateItemCollectionProps{
        name:string
        description?:string
        templates: TemplateObject[]
        actionElement?: JSX.Element
        includeOwner?: boolean
        read_only?: boolean
        pre_built?: boolean
        max?: number
        dataTourId?: string
    }

    const TemplateItemCollection: React.FC<TemplateItemCollectionProps> = ({name, description, templates, actionElement, includeOwner , read_only = false, max = 6, dataTourId, pre_built = false}) => {
        const [expanded, setExpanded] = useState<boolean>(false)
        const { superOrgOwner } = useVetRec()
        return(<>
            {templates && templates?.length > 0 && <div>
                <div className='flex flex-row justify-between items-center'>
                    <div className='flex flex-row gap-x-2 gap-y-1 items-center'>
                        <h2 className="text-sm font-medium text-gray-500">{name}</h2>
                        {description && <div className='text-gray-500 text-xs'>({description})</div>}
                    </div>
                    {actionElement}
                </div>
                <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {templates.slice(0, expanded ? templates.length : max).map((org_template, index) => (
                        <TemplateItem dataTourId={index === 0 ? dataTourId : undefined} includeOwner={includeOwner} template={org_template} options={(!org_template.id.includes("vetworks") && org_template.id.includes("dynamic")) ? [
                            ...(createEnabledPermission ? [{
                                name:"Duplicate",
                                action: () => duplicateTemplate(org_template, TemplateSource.custom),
                                icon:<DocumentDuplicateIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                            }] : []),
                            ...(editEnabledPermission && showDeleteTemplateOption(org_template, user?.primaryEmailAddress?.emailAddress) && !read_only ? [{
                                name:"Delete",
                                action: () => handleDeleteTemplate(org_template.id),
                                icon:<TrashIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                            }] : []),
                            ...(shareTemplatePermission && !read_only ? [{
                                name:"Share",
                                action: () => handleShareTemplate(org_template.id),
                                icon:<ShareIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                            }] : []),
                            ...(superOrgOwner && !pre_built ? [{
                                name:org_template.super_org?.includes(superOrg) ? "Remove from org" : "Add to org",
                                action: () => handleSuperOrgShare(org_template.id, !org_template.super_org?.includes(superOrg)),
                                icon:org_template.super_org ? <XCircleIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' /> : <PlusCircleIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                            }]:[]),
                            {
                                name:"Set as default",
                                action: () => handleDefaultTemplate(org_template.id, org_template.type ?? TemplateType.MEDICAL),
                                icon:<StarIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                            }] : [
                            {
                                name:"Set as default",
                                action: () => handleDefaultTemplate(org_template.id, org_template.type ?? TemplateType.MEDICAL),
                                icon:<StarIcon className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                            }]
                        }/>
                    ))}
                </ul>
                {templates.length > max && max > 0 && <button
                    onClick={() => setExpanded(!expanded)}
                    className="mt-2 flex justify-between items-center p-2 w-full bg-blue-100 hover:bg-blue-200 text-blue-900 rounded-md focus:outline-none"
                >
                    {expanded ? <p>Collapse to see less.</p> : <p>Expand to see more.</p>}
                    {expanded ? (
                    <ChevronUpIcon className="w-5 h-5 text-blue-900" />
                    ) : (
                    <ChevronDownIcon className="w-5 h-5 text-blue-900" />
                    )}
                </button>}
            </div>}
        </>)
    }

    const anyCustomTemplates = useMemo(() => {
        return (superOrg && filteredSuperOrgTemplates && filteredSuperOrgTemplates.length > 0) 
            || (organization && filteredOrgTemplates && filteredOrgTemplates.length > 0)
            || (filteredTemplates && filteredTemplates.length > 0)
    }, [filteredSuperOrgTemplates, filteredOrgTemplates, filteredTemplates, superOrg, orgId])

    return(<>
        <TemplateCreateModal 
          open={createModalOpen} 
          setOpen={setCreateModalOpen} 
          templateLibrary={templateLibrary ?? []} 
        />
        <TemplateShareModal 
            open={shareModalOpen} 
            setOpen={setShareModalOpen} 
            templateToShare={templateToShare}
            setTemplateToShare={setTemplateToShare}
        />
        <div className="border-b border-gray-400 pb-5 mb-5 flex flex-col justify-between gap-y-2 py-4 sm:pt-10">
            <div className='flex flex-row justify-between items-center'>
                <div>
                    <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                        Templates
                    </h2>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500">
                        Create new templates or modify presets to let VetRec AI know how to organize and present your notes.
                    </p>
                </div>
                {createEnabledPermission && <button
                data-tour-id="create-template-button"
                type="button"
                className="ml-3 inline-flex gap-x-2 items-center rounded-md bg-main-button px-3 py-2 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 h-12"
                onClick={() => createTemplateBuilder()}
                >
                    <PlusIcon className="-mr-0.5 sm:h-5 sm:w-5 h-8 w-8" aria-hidden="true" />
                    <div className=''>Create template</div>
                </button>}
            </div>
        </div>
        <div className={`${createEnabledPermission && editEnabledPermission ? 'bg-accent':'bg-yellow-100'} w-full shadow-lg rounded-lg py-2 px-4 flex flex-col sm:flex-row justify-between items-center gap-x-8 overflow-hidden gap-y-6`}>
            {createEnabledPermission && editEnabledPermission && <div className='flex flex-col sm:flex-row gap-x-6 items-center justify-between gap-y-6 h-full grow w-full sm:w-2/3'>
                <div className='text-center lg:text-left'>
                    <span className='font-semibold'>Customize your templates to fit your needs.</span> VetRec supports templates from all specialties and practices.
                </div>
                <div className='flex gap-x-4'>
                    <a
                        href='https://www.youtube.com/watch?v=U47wB8VJc7s'
                        className="inline-flex items-center gap-x-3 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center min-w-36  sm:min-w-48"
                        target='_blank'
                        data-tour-id="templates-tutorial-video" rel="noreferrer"
                    >
                        <VideoCameraIcon className="-mr-0.5 sm:h-5 sm:w-5 h-8 w-8" aria-hidden="true" />
                        Watch Tutorial
                    </a>
                    <a
                        className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center min-w-36 sm:min-w-48"
                        onClick={() => handleTemplateRequest()}
                        target='_blank'
                        href='https://calendly.com/vetrec-connect/vetrec-template-assistance' rel="noreferrer"
                    >
                        {sending  ? "Notifying" : sent  ? "Notified" : "Request Template"}
                    </a>
                </div>
            </div>}
            {(!createEnabledPermission || !editEnabledPermission) && <div className='flex flex-col sm:flex-row gap-x-6 items-center justify-between gap-y-6 h-full grow w-full sm:w-2/3 py-2'>
                <div className='flex flex-row gap-x-2 items-center'>
                    <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
                    <div className='text-center lg:text-left'>
                        {!createEnabledPermission && !editEnabledPermission && <><span className='font-semibold'>Template creation, editing and library are disabled.</span> Contact your team admin to enable this feature.</>}
                        {!createEnabledPermission && editEnabledPermission && <><span className='font-semibold'>Template creation and library are disabled.</span> Contact your team admin to enable this feature.</>}
                        {createEnabledPermission && !editEnabledPermission && <><span className='font-semibold'>Template editing is disabled.</span> Contact your team admin to enable this feature.</>}
                    </div>
                </div>
            </div>}
        </div>
        {loadingTemplates && <div className='flex flex-col justify-center items-center pt-12 gap-y-6'>
            {loadingTemplates}
            <Spinner size='w-10 h-10' timer={false}/>
        </div>}
        {!loadingTemplates && <div className='flex flex-col gap-y-6 mb-28 mt-3 min-h-[50vh]'>
            <TemplateTabs 
              typesDataTourId="template-filters"
              searchDataTourId="template-search"
              tab={tab} 
              handleTabChange={(value) => handleTabChange(value)} 
              type={type} 
              handleTypeChange={handleTypeChange} 
              handleSearchChange={setSearch}
              search={search}
              showTabs={createEnabledPermission}
            />
            {tab === "yours" && <div data-tour-id="templates-list" className='flex flex-col gap-y-8'>
                {superOrg && <TemplateItemCollection name="Organization templates" description="Shared by your organization" includeOwner={true} templates={filteredSuperOrgTemplates ?? []} read_only={true}/>}
                {organization && <TemplateItemCollection name="Team templates" templates={filteredOrgTemplates ?? []} includeOwner={true} dataTourId={getTourIdOnTemplateCardCollections("Your templates")} />}
                <TemplateItemCollection name="Your templates" templates={filteredTemplates ?? []} dataTourId={getTourIdOnTemplateCardCollections("Your templates")} />
                {!prebuiltEnabled && !anyCustomTemplates && <WarningBanner warningText='Pre-built templates will continue to populate in template pickers because no other templates are available.' />}
                <TemplateItemCollection name="VetRec Pre-built templates" templates={preBuiltTemplatesFiltered ?? []} dataTourId={getTourIdOnTemplateCardCollections("Pre-built templates")} max={prebuiltEnabled ? 6 : 0} pre_built={true} actionElement={
                    <div className="flex flex-row gap-x-3 items-center">
                        <div className='flex flex-col gap-y-1'>
                            <span className="text-sm font-medium text-gray-500">Enable pre-built templates</span>
                            {preBuiltControlledByOrg && <span className='text-red-600 text-sm'>Controlled in team settings by admin.</span>}
                        </div>
                        <Switch
                            checked={prebuiltEnabled}
                            disabled={preBuiltControlledByOrg}
                            onChange={updatePreBuiltEnabled}
                            className={`${
                                prebuiltEnabled ? 'bg-blue-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span className="sr-only">Enable pre-built templates</span>
                                <span
                                    className={`${
                                    prebuiltEnabled ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                        </Switch>
                    </div>
                }/>
            </div>}
            {tab === "library" && createEnabledPermission && <div data-tour-id="template-library-content" className='flex flex-col gap-y-10'>  
                {templateLibraryFiltered && <div>
                    <h2 className="text-sm font-medium text-gray-500">Template Library</h2>
                    <p className='text-sm font-regular text-gray-500 mt-2 mb-2'>The template library includes curated templates developed by our team to help you get started. Choose from our selection and add them to your account. Customize them using our template builder to fit your style.</p>
                    <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
                        {templateLibraryFiltered.map((template) => (
                        <li key={template.name} className="col-span-1 flex rounded-md shadow-sm h-16">
                            <div className={classNames(
                                "bg-main", 'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-main-text'
                            )}
                            >
                                {template.id === defaultTemplate ? <StarIcon className='h-5 w-5' /> : template.name[0]}
                            </div>
                            <div className={`flex flex-1 min-w-0 h-full pl-4 pr-2 py-2 items-center justify-between rounded-r-md border-b border-r border-t border-gray-400 hover:bg-gray-200 ${template.id.includes("vetworks") || !template.id.includes("dynamic") ? "text-gray-500 bg-gray-200" : "bg-white"} ${createEnabledPermission ? "cursor-pointer" : "pointer-events-none"}`}>
                                <div className="flex-1 truncate text-sm flex flex-col gap-y-1" onClick={() => editTemplate(template)}>
                                    {template.name}
                                    <div className='flex flex-row gap-x-2'>
                                        <span className={`px-2 py-0.5 bg-${getTemplateTypeColor(template.type)}-100 rounded-md w-fit`}>            
                                            {getTemplateTypeDisplayName(template.type)}
                                        </span>
                                        {template.tag && <span className={`px-2 py-0.5 bg-gray-300 rounded-md w-fit`}>            
                                            {capitalize(template.tag[0])}
                                        </span>}
                                    </div>
                                </div>
                                {createEnabledPermission && <div className={`inline-flex justify-center items-center gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 h-12`} onClick={() => duplicateTemplate(template, TemplateSource[template.id as keyof typeof TemplateSource])}>
                                    <PlusIcon className='h-5 w-5 text-gray-400 group-hover:text-gray-500' />
                                </div>}
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>}
            </div>}
        </div>}
        {error && <ErrorAlert error={error} setError={setError}/>}
        <Reminder show={request} hide={() => setRequest(false)} title='Notification Sent' text='The team will get back to you in 24H with instructions.'/>
        <TemplateDeleteModal open={templateDelete} setOpen={setTemplateDelete} delete={confirmDeleteTemplate}/>
    </>)
}

interface TemplateTabsProps {
    tab: string,
    handleTabChange: (tab:string) => void,
    type:string
    handleTypeChange: (type:string) => void
    handleSearchChange: (search:string) => void
    showTabs?: boolean
    typesDataTourId?: string
    searchDataTourId?: string
    search?: string
}

export const TemplateTabs = (props: TemplateTabsProps) => {
    const tabs = [
        {
            value: "yours",
            label: "Your Templates",
        },
        {
            value: "library",
            label: "Library",
        }
    ]

    const types = [
        {
            value: "all",
            label: "All",
        },
        {
            value: "medical",
            label: "Notes",
        },
        {
            value: "communications",
            label: "Client",
        },
        {
            value: "recordsrecap",
            label: "Recap",
        }
    ]

    let additionalTypes = [
        {
            value: "specialty",
            label: "Specialty",
        },
        {
            value: "exotics",
            label: "Exotics",
        },
        {
            value: "equine",
            label: "Equine",
        },
        {
            value: "er",
            label: "ER",
        },
        {
            value: "phone",
            label: "Phone",
        }
    ]

    return(
        <div className="">
            {props.showTabs && (
                <>
                    <div className="xl:hidden relative">
                        <label
                        htmlFor="tabs"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
                        >
                            Section
                        </label>
                        <select
                        id="tabs"
                        name="tabs"
                        data-tour-id={window.innerWidth <= 1280 ? "template-tabs" : undefined}
                        className="block w-full rounded-md border-gray-300 focus:border-main-lighter focus:ring-main-lighter"
                        defaultValue={"Overview"}
                        value={props.tab}
                        onChange={(value) => props.handleTabChange(value.target.value)}
                        >
                            {tabs.map((tab) => (
                                <option key={tab.value} value={tab.value}>{tab.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden xl:block">
                        <div className="border-b border-gray-200">
                            <nav className="-mb-px flex flex-row space-x-6 w-full" aria-label="Tabs">
                                {tabs.map((tab) => (
                                    <div
                                        data-tour-id={tab.value === "library" && window.innerWidth > 1280 ? "template-tabs" : undefined}
                                        key={tab.value}
                                        onClick={() => props.handleTabChange(tab.value)}
                                        className={classNames(
                                        props.tab === tab.value
                                            ? 'border-main-lighter text-main'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                                        )}
                                        aria-current={props.tab === tab.value ? 'page' : undefined}
                                    >
                                        <span>{tab.label}</span>
                                </div>
                                ))}
                            </nav>
                        </div>
                    </div>
                </>
            )}
            <div className='flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between pt-4 gap-y-4 gap-x-6'>
                <div  data-tour-id={props.typesDataTourId} className='flex flex-row items-center gap-x-4 overflow-x-auto thin-scrollbar-x'>
                    <div className='mr-4'>
                        Type:
                    </div>
                    {types.map((type) => (
                        <div
                            data-tour-id={(type.label === "Client" && props.tab === "library") ? "create-template-modal-library-client-filter" : ""}
                            key={type.value}
                            onClick={() => props.handleTypeChange(type.value)}
                            className={classNames(
                            props.type === type.value
                                ? 'text-white bg-blue-600'
                                : 'text-gray-900 hover:bg-blue-300 bg-blue-100',
                            'group inline-flex items-center py-1 px-2 text-sm font-medium cursor-pointer rounded-md'
                            )}
                            aria-current={props.type === type.value ? 'page' : undefined}
                        >
                            <span>{type.label}</span>
                        </div>
                    ))}
                    {props.tab === "library" && additionalTypes.map((type) => (
                        <div
                            key={type.value}
                            onClick={() => props.handleTypeChange(type.value)}
                            className={classNames(
                            props.type === type.value
                                ? 'text-white bg-blue-600'
                                : 'text-gray-900 hover:bg-blue-300 bg-blue-100',
                            'group inline-flex items-center py-1 px-2 text-sm font-medium cursor-pointer rounded-md'
                            )}
                            aria-current={props.type === type.value ? 'page' : undefined}
                        >
                            <span>{type.label}</span>
                        </div>
                    ))}
                </div>
                <div  data-tour-id={props.searchDataTourId} className='flex flex-row gap-x-4 items-center'>
                    Search:
                    <input 
                        data-tour-id="template-search-input" 
                        placeholder='Type template name' 
                        className='w-60 rounded-md h-8' 
                        value={props.search}
                        onChange={(event) => props.handleSearchChange(event.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}
