import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BookOpenIcon, LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid'
import { Loading } from '../../utils/Loading'
import { WarningBanner } from '../common/WarningBanner'
import { useAuth } from '@clerk/clerk-react'
import va from '@vercel/analytics';
import { addCustomSpelling } from '../../serverActions/user'

interface AddCustomSpellingModalBasicProps {
    setNotification: (notification: boolean) => void
    setNotificationTitle: (notificationText: string) => void
    setNotificationText?: (notificationText: string) => void
    show: boolean
    close: () => void
    refresh: (currentSpelling?:string, desiredSpelling?:string, caseSensitive?:boolean) => Promise<void>
    highlightedText?: string
}

export default function AddCustomSpellingModalBasic(props: AddCustomSpellingModalBasicProps) {
    const { setNotification, setNotificationTitle, setNotificationText, close, show, refresh, highlightedText } = props
    const { getToken } = useAuth();

    const [currentSpelling, setCurrentSpelling] = useState<string>()
    const [desiredSpelling, setDesiredSpelling] = useState<string>()
    const [submitting, setSubmitting] = useState(false)
    const [caseSensitive, setCaseSensitive] = useState(false);
    const isSubmitDisabled = useMemo<boolean>(() => submitting || currentSpelling === "" || desiredSpelling === "", [submitting, currentSpelling, desiredSpelling]);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => setCurrentSpelling(highlightedText?.trim() ?? ""), [highlightedText])

    async function handleAddCustomSpelling() {
        try {
            setSubmitting(true)

            if(!currentSpelling || !desiredSpelling) {
                setError("Please enter both the current spelling and the desired spelling.")
                setSubmitting(false)
                return
            }

            // Save custom vocabulary to user's custom mappings
            await addCustomSpelling(currentSpelling, desiredSpelling, caseSensitive, await getToken({template:"supabase"}) ?? '')
            await refresh(currentSpelling, desiredSpelling, caseSensitive)

            setNotification(true)
            setNotificationTitle("Custom Vocabulary Added")
            if(setNotificationText) setNotificationText(`Custom vocabulary added for "${currentSpelling}" to be written as "${desiredSpelling}".`)
            
            va.track("AddCustomSpelling_Success", {currentSpelling, desiredSpelling, caseSensitive})

            onClose()
            setSubmitting(false)
        } catch (error) {
            setError("There was an issue adding your custom vocabulary. Please try again.")
            va.track("AddCustomSpelling_Fail", {})
            setSubmitting(false)
        }
    }

    function onClose() {
        close()
        setCurrentSpelling("")
        setDesiredSpelling("")
        setCaseSensitive(false)
        setError(undefined)
    }

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 space-y-4">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                        <BookOpenIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Add Custom Vocabulary
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Add a custom vocabulary or abbreviation for the selected text.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="currentSpelling" className="block text-sm font-medium text-gray-700">
                                        Incorrect spelling
                                    </label>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="text"
                                            name="currentSpelling"
                                            id="currentSpelling"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 mt-1 h-8"
                                            placeholder="Enter the word you want to spell differently"
                                            value={currentSpelling}
                                            onChange={(event) => setCurrentSpelling(event.target.value)}
                                        />
                                        <div className="flex items-center mt-1">
                                            <button
                                                type="button"
                                                className={`text-gray-500 hover:text-gray-700 border border-gray-300 py-1.5 px-2 rounded-md h-8 flex shadow-md ${caseSensitive ? 'bg-red-600 hover:bg-red-500' : 'bg-blue-600 hover:bg-blue-500'}`}
                                                onClick={() => setCaseSensitive(!caseSensitive)}
                                                title="Change case sensitivity"
                                            >
                                                {caseSensitive ? <LockClosedIcon className="h-4 w-4 text-white" aria-hidden="true" /> : <LockOpenIcon className="h-4 w-4 text-white" aria-hidden="true" />}
                                                <span
                                                    className={`inline-flex items-center justify-center rounded text-white`}
                                                    aria-hidden="true"
                                                >
                                                    Aa
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`text-xs font-medium ${caseSensitive ? 'text-red-600' : 'text-blue-600'} mt-2 border border-gray-200 ${caseSensitive ? 'bg-red-100' : 'bg-blue-100'} rounded-md px-2 py-1.5`}>
                                        {caseSensitive ? 'Exact match - upper and lowercase matters. E.g., \'Dog\' will not match \'dog\'' : 'Upper and lowercase letters don\'t matter. E.g., \'Dog\' will match \'dog\''}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="desiredSpelling" className="block text-sm font-medium text-gray-700">
                                        I want VetRec to always write
                                    </label>
                                    <input
                                        type="text"
                                        name="desiredSpelling"
                                        id="desiredSpelling"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 mt-1"
                                        placeholder="Enter your custom vocabulary or abbreviation"
                                        value={desiredSpelling}
                                        onChange={(event) => setDesiredSpelling(event.target.value)}
                                    />
                                </div>
                                {submitting && (
                                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-75 z-10">
                                        <Loading />
                                    </div>
                                )}
                                {currentSpelling && desiredSpelling && <WarningBanner warningText={`All future notes will use "${desiredSpelling}" instead of "${currentSpelling}".`} />}
                                {error && <div className="text-red-600 text-sm">{error}</div>}
                                <div className="flex space-x-4 mt-4 relative">
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ${(isSubmitDisabled || submitting) ? 'bg-gray-500' : 'bg-blue-600 hover:bg-blue-500 cursor-pointer '}`}
                                        onClick={handleAddCustomSpelling}
                                        disabled={isSubmitDisabled || submitting}
                                    >
                                        Save Custom Vocabulary and Apply
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

