import { Fragment } from "react"
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { SUPER_HIGH_Z_INDEX } from "./tours/constants";

interface LanguagePickerProps{
    language:string
    setLanguage: ((value: string) => void) | undefined
    languages:string[]
    className:string
    labelBackground:string
    direction?: string
    raiseZIndexOnOptions?: boolean
}

export default function LanguagePicker(props:LanguagePickerProps) {
    const direction = props.direction ? props.direction : "below"
    return(<>
        <div className="shrink-0 xl:w-60 lg:w-60 md:w-60 sm:w-60 w-full relative">
            <label
                htmlFor="name"
                className={`absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-main-text-darker z-[1] ${props.labelBackground}`}
            >
                Language
            </label>
            <Listbox value={props.language} onChange={props.setLanguage} defaultValue={props.languages[0]}>
                <div className="relative">
                <Listbox.Button className={`relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm z-[0] ${props.className}`}>
                    <span className="block truncate">{props.language}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className={`absolute ${direction === "below"? "mt-1" : "-mt-44"} max-h-32 thin-scrollbar w-full overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-gray-300 focus:outline-none sm:text-sm cursor-pointer ${props.raiseZIndexOnOptions ? SUPER_HIGH_Z_INDEX : "z-[99]"}`}>
                    {props.languages && props.languages.map((lang, langIdx) => (
                        <Listbox.Option
                        key={langIdx}
                        className={({ active }) =>
                            `relative select-none py-2 pl-4 flex flex-row gap-x-2 pr-4 cursor-pointer ${
                            active ? 'bg-accent text-main-text-darker' : 'text-main-text-darker'
                            } z-[999]`
                        }
                        value={lang}
                        >
                        {({ selected }) => (
                            <>
                            <span
                                className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                                }`}
                            >
                                {lang}
                            </span>
                            {selected ? (
                                <span className="flex items-center pl-3 text-gray-600">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            ) : null}
                            </>
                        )}
                        </Listbox.Option>
                    ))}
                    </Listbox.Options>
                </Transition>
                </div>
            </Listbox>
        </div>
    </>)
}
