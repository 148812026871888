import { UserStatus } from "../types/user";
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

export enum AlertType {
    Success = 'success',
    Info = 'info',
    Error = 'error',
}

export const getAlertIconColor = (type: AlertType): string => {
    switch (type) {
        case AlertType.Success:
            return 'text-green-600';
        case AlertType.Info:
            return 'text-blue-600';
        case AlertType.Error:
            return 'text-red-600';
        default:
            return 'text-gray-600';
    }
}

export const getAlertBackgroundColor = (type: AlertType): string => {
    switch (type) {
        case AlertType.Success:
            return 'bg-green-600';
        case AlertType.Info:
            return 'bg-blue-600';
        case AlertType.Error:
            return 'bg-red-600';
        default:
            return 'bg-gray-600';
    }
}

export const getAlertIcon = (type: AlertType): React.ElementType => {
    switch (type) {
        case AlertType.Success:
            return CheckCircleIcon;
        case AlertType.Info:
            return InformationCircleIcon;
        case AlertType.Error:
            return ExclamationCircleIcon;
        default:
            return QuestionMarkCircleIcon;
    }
}

// Function to get user status for display
export const getUserStatusDisplay = (status: UserStatus): { label: string; color: string } => {
    switch (status) {
      case 'active':
        return { label: 'Active', color: 'green' };
      case 'invited':
        return { label: 'Invited', color: 'blue' };
      default:
        return { label: 'Unknown', color: 'gray' };
    }
  };