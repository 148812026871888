import config from '../config.json'

const url = import.meta.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

export async function addCustomSpelling(current_spelling: string, desired_spelling: string, case_sensitive: boolean, jwt: string) {
    try {
        const response = await fetch(url + '/v1/add_custom_spelling', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ current_spelling, desired_spelling, case_sensitive })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error adding custom vocabulary: ${errorDetail.detail}`);
        }

        return true;
    } catch (error) {
        throw new Error('Failed to add custom vocabulary');
    }
}

export async function updateCustomSpelling(abbreviations:{[key:string]:any}, jwt: string) {
    try {
        const response = await fetch(url + '/v1/update_custom_spelling', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ abbreviations })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error updating custom vocabulary: ${errorDetail.detail}`);
        }

        return true;
    } catch (error) {
        console.error('Failed to update custom vocabulary:', error);
        return false;
    }
}

export async function getUserGlossary(jwt: string) {
    try {
        const response = await fetch(url + '/v1/get_custom_spelling', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error getting custom vocabulary: ${errorDetail.detail}`);
        }
        return response.json();
    } catch (error) {
        console.error('Failed to get custom vocabulary:', error);
        return {};
    }
}


interface UsageResponse {
    num_sessions: number;
}

export async function fetchUsagePerUser(client:string, jwt: string): Promise<UsageResponse> {
    const endpoint = url + '/v1/usage?client=' + client;
  
    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`, // Assuming the token is used for user validation
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const data: UsageResponse = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch usage data', error);
      return { num_sessions: 0 }; // Return a default object in case of error
    }
}

export interface TeamMemberPublicMetadata {
    default_template_id?: string,
    default_discharge_template_id?: string
    default_recordsrecap_template_id?: string
}

export async function fetchTeamMemberPublicMetadata(user_id: string, jwt: string): Promise<TeamMemberPublicMetadata> {
    try {
        const response = await fetch(url + '/v1/team_member_public_metadata/' + user_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            }
        });
        
        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error getting team member public metadata: ${errorDetail.detail}`);
        }

        const userMetadata: TeamMemberPublicMetadata = await response.json();
        return userMetadata
    } catch (error) {
        return {};
    }
}