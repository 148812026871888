import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloudArrowUpIcon, InformationCircleIcon, LinkIcon } from '@heroicons/react/24/solid'
import { useHistory } from '../../providers/HistoryProvider'
import { transferToVetspire } from '../../ServerActions'
import { useAuth } from '@clerk/clerk-react'
import { Spinner } from '../../utils/Spinner'
import { useVetRec } from '../../providers/VetRecProvider'

interface VetSpireModalProps {
    vetspireExport: boolean
    handleVetSpireExport(open: boolean): void
    getChart(): Promise<string | undefined>
}

export default function VetSpireModal(props: VetSpireModalProps) {

    const { vetspireExport, handleVetSpireExport, getChart } = props
    const { activeSession, setError, notifyCompleted, refreshVetspireVisit, sessionTemplate, refreshSessionObject , sessionWithPetArraySelectedMedicalNoteId, sessionWithPetArraySelectedName, multiPetSessionActive} = useHistory()
    const { createEncounterEnabled, defaultVetspireBehavior } = useVetRec()
    const [patientId, setPatientId] = useState<string | undefined>(activeSession?.external_patient_id)
    const [encounterId, setEncounterId] = useState<string | undefined>(activeSession?.external_record_id)
    const { getToken } = useAuth()
    const [exporting, setExporting] = useState(false)
    const [exported, setExported] = useState(false)
    const [retrieving, setRetrieving] = useState(false)
    const [missing, setMissing] = useState<string>()
    const templateType: string = useMemo(() => {
        let type: string = String(sessionTemplate?.mapping?.type ?? defaultVetspireBehavior ?? "Encounter")
        return type
    }, [sessionTemplate])

    const handleVetspireSend = async () => {
        if (!patientId) {
            setMissing("Patient ID is required")
            return
        }
        if ((templateType.toLowerCase() === "encounter" && !createEncounterEnabled && !encounterId) || (templateType.toLowerCase() === "attachment" && !encounterId)) {
            setMissing("Encounter ID is required")
            return
        }

        if (!activeSession) {
            setError("Something happened. Please try again.")
            return
        }

        try {
            setMissing(undefined);
            setExporting(true)
            let token = await getToken({ template: "supabase" }) ?? ''
            let chart = await getChart()
            await transferToVetspire(activeSession.id ?? "", patientId, token, encounterId, chart, sessionWithPetArraySelectedMedicalNoteId)
            await refreshSessionObject(activeSession)
            notifyCompleted("Notes exported to Vetspire!")
            setPatientId(undefined)
            setEncounterId(undefined)
            setExporting(false)
            setExported(true)
        } catch (err) {
            setExporting(false)
            setMissing("Failed to export notes to Vetspire. Try again. Make sure the encounter hasn't been signed.")
            setError("Failed to export notes to Vetspire. Try again. Make sure the encounter hasn't been signed.")
        }
    }

    useEffect(() => {
        const updateVisit = async () => {
            setExported(false)
            setExporting(false)
            setMissing(undefined)
            setPatientId(activeSession?.external_patient_id ?? "")
            setEncounterId(activeSession?.external_record_id ?? "")
            if (!activeSession?.external_record_id && activeSession?.external_visit_id) {
                setRetrieving(true)
                let encounterId = await refreshVetspireVisit()
                setEncounterId(encounterId)
                setRetrieving(false)
            }
        }
        if (vetspireExport) {
            updateVisit()
        }
    }, [vetspireExport])

    return (
        <Transition.Root show={vetspireExport} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={handleVetSpireExport}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                {!exported && <div>
                                    <div>
                                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                            <LinkIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Export to Vetspire
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Notes will export as a{templateType === "Quick Note" ? "" : "n"} <span className='font-semibold text-gray-900'>{templateType}</span> in Vetspire.
                                                </p>
                                                {multiPetSessionActive && sessionWithPetArraySelectedName && (
                                                    <p className="mt-2 text-sm bg-blue-50 rounded-md p-2 text-blue-700">
                                                        Exporting notes for: <span className="font-medium">{sessionWithPetArraySelectedName}</span>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {!retrieving && <div>
                                        <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center relative w-full" title='Provide a patient ID to write notes to an existing patient'>
                                            <label
                                                htmlFor="Patient ID"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                                            >
                                                Patient ID:
                                            </label>
                                            <input className="rounded-md h-10 w-full" onChange={(event) => {
                                                setMissing(undefined)
                                                setPatientId(event.target.value)
                                            }} title='Patient ID' placeholder='Patient ID found in Vetspire' disabled={exporting} value={patientId} />
                                        </div>
                                        {(templateType.toLowerCase() === "encounter" || templateType.toLowerCase() === "attachment") && <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-2 gap-y-2 justify-center items-center relative w-full" title='Provide an encounter ID to append notes to existing encounters'>
                                            <label
                                                htmlFor="Encounter ID"
                                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                                            >
                                                Encounter ID {createEncounterEnabled && templateType.toLowerCase() !== "attachment" ? "(Optional)" : "(Required)"}:
                                            </label>
                                            <input className="rounded-md h-10 w-full" onChange={(event) => {
                                                setMissing(undefined)
                                                setEncounterId(event.target.value)
                                            }} title='Encounter ID' placeholder='Optional Encounter Id for Vetspire' disabled={exporting} value={encounterId} />
                                        </div>}
                                        {missing && <div className="text-red-500 text-sm mt-2">{missing}</div>}
                                        {exporting && <div className='mt-5 w-full flex bg-blue-200 rounded-md p-2 shadow text-sm gap-x-2'>
                                            <InformationCircleIcon className='h-6 w-6 text-blue-600' />
                                            Once exported, refresh the Vetspire page to see the notes.
                                        </div>}
                                        <button
                                            type="button"
                                            className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 items-center mt-4 ${(exporting || !patientId || (templateType.toLowerCase() === "encounter" && !createEncounterEnabled && !encounterId) || (templateType.toLowerCase() === "attachment" && !encounterId)) ? 'cursor-not-allowed bg-gray-600' : 'cursor-pointer bg-blue-600 hover:bg-blue-500'}`}
                                            onClick={() => handleVetspireSend()}
                                            disabled={exporting || !patientId || (templateType.toLowerCase() === "encounter" && !createEncounterEnabled && !encounterId) || (templateType.toLowerCase() === "attachment" && !encounterId)}
                                        >
                                            {exporting ? <Spinner size='h-5 w-5' timer={false} /> : "Export"}
                                        </button>
                                    </div>}
                                    {retrieving && <div className='mt-5 flex flex-col items-center gap-y-2'>
                                        Retrieving encounter from Vetspire...
                                        <Spinner size='h-10 w-10' timer={true} />
                                    </div>}
                                </div>}
                                {exported && <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CloudArrowUpIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Notes exported to Vetspire
                                        </Dialog.Title>
                                        <div className="mt-2 text-sm text-gray-900 bg-blue-100 p-2 rounded-md">
                                            <span className="font-semibold">Note:</span> Refresh the Vetspire page to see the notes.
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 items-center mt-4 curosor-pointer bg-blue-600 hover:bg-blue-500`}
                                        onClick={() => handleVetSpireExport(false)}
                                    >
                                        Close
                                    </button>
                                </div>}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}