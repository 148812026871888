import { useEffect, useMemo, useRef } from "react"
import { DentalType, FelineDentalInfo } from "../../utils/DentalUtils"
import { Tooth } from "./Tooth"
import { DentalLegend } from "./DentalLegend"
import { useHistory } from "../../providers/HistoryProvider"
import { SparklesIcon } from "@heroicons/react/24/solid"

interface FelineDentalProps {
    dentalResults: { [key: string]: string } | undefined
    setDentalResults: (results: { [key: string]: string }) => void
    expand: boolean
    expandChart: () => void
}

export const FelineDentalVertical = (props: FelineDentalProps) => {
    // Vertical dental chart using tooth component and grid to organize teeth in right place
    const dentalInfo = useMemo<FelineDentalInfo>(() => new FelineDentalInfo(props.dentalResults ?? {}), [props.dentalResults])
    const dentalInfoRef = useRef<FelineDentalInfo>(dentalInfo)
    const { activeDiagramDescription } = useHistory()
    const tooltips = useMemo(() => activeDiagramDescription ? activeDiagramDescription : {}, [activeDiagramDescription])

    useEffect(() => {
        dentalInfoRef.current = dentalInfo
    }, [dentalInfo])

    const handleToothUpdate = (tooth: string, state: string) => {
        const newDentalInfo = { ...dentalInfoRef.current }; // Create a shallow copy to avoid mutation
        // Check if state is a valid key of DentalType
        if (state in DentalType) {
            // Cast 'tooth' as unknown first, then as keyof FelineDentalInfo
            newDentalInfo[tooth as unknown as keyof FelineDentalInfo] = DentalType[state as keyof typeof DentalType];
            props.setDentalResults({ ...newDentalInfo }); // Update parent component's state if necessary
        } else {
            console.error(`Invalid dental state: ${state}`);
        }
    };

    return (<div className="flex flex-col w-full items-center gap-y-2 relative">
        <div className="flex flex-row gap-x-2">
            <div className="bg-white-600 text-gray-900 rounded-md px-2 py-1 text-xs mt-4 shadow border border-blue-600 flex flex-row gap-x-2 items-center font-semibold">
                Beta <SparklesIcon className="w-3 h-3 text-blue-400 inline"/>
            </div>
            <DentalLegend />    
            <button
                type="button"
                title="Toggle Dental Chart"
                className="bg-blue-600 text-white rounded-md px-2 py-1 text-xs mt-4 hover:bg-blue-700 shadow sm:block hidden"
                onClick={() => props.expandChart()}
            >
                {!props.expand ? "Collapse Chart":"Expand Chart"}
            </button>
        </div>
        <div className="text-sm text-gray-500 italic">
            Click on the tooth to change its state
        </div>
        <div id="diagram">
            <div className={`flex ${!props.expand ? "flex-col lg:flex-row gap-x-2 justify-center items-center" : "flex-col items-center gap-y-2"} w-full relative`}>
                <div className="grid grid-cols-20 w-[600px] mt-4">
                    <div className="tooth col-start-5 row-start-8 w-16 h-16 -ml-4 mt-2">
                        <Tooth tooth={109} state={dentalInfo[109]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[109]}/>
                    </div>
                    <div className="tooth col-start-14 row-start-8 w-16 h-16 ml-10 mt-2">
                        <Tooth tooth={209} state={dentalInfo[209]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[209]}/>
                    </div>
                    <div className="tooth col-start-5 row-start-7 w-16 h-20 mt-6 -ml-8 mt-1">
                        <Tooth tooth={108} state={dentalInfo[108]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[108]}/>
                    </div>
                    <div className="tooth col-start-15 row-start-7 w-16 h-20 mt-6 -ml-6 mt-1">
                        <Tooth tooth={208} state={dentalInfo[208]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[208]}/>
                    </div>
                    <div className="tooth col-start-7 row-start-6 w-16 h-12 -ml-12 mt-4">
                        <Tooth tooth={107} state={dentalInfo[107]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[107]}/>
                    </div>
                    <div className="tooth col-start-14 row-start-6 w-16 h-12 ml-1 mt-4">
                        <Tooth tooth={207} state={dentalInfo[207]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[207]}/>
                    </div>
                    <div className="tooth col-start-7 row-start-5 w-16 h-8 -ml-6 -mt-2">
                        <Tooth tooth={106} state={dentalInfo[106]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[106]}/>
                    </div>
                    <div className="tooth col-start-14 row-start-5 w-16 h-8 -ml-0 -mt-2">
                        <Tooth tooth={206} state={dentalInfo[206]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[206]}/>
                    </div>
                    <div className="tooth col-start-6 row-start-3 w-16 h-20 mt-2 -ml-1">
                        <Tooth tooth={104} state={dentalInfo[104]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[104]}/>
                    </div>
                    <div className="tooth col-start-14 row-start-3 w-16 h-20 mt-2 -ml-7">
                        <Tooth tooth={204} state={dentalInfo[204]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[204]}/>
                    </div>
                    <div className="tooth col-start-8 row-start-2 w-5 h-8 -mt-2 -ml-5">
                        <Tooth tooth={103} state={dentalInfo[103]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[103]}/>
                    </div>
                    <div className="tooth col-start-13 row-start-2 w-5 h-8 -mt-2 -ml-1">
                        <Tooth tooth={203} state={dentalInfo[203]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[203]}/>
                    </div>
                    <div className="tooth col-start-9 row-start-1 w-5 h-8 ml-1">
                        <Tooth tooth={102} state={dentalInfo[102]} text_position="above" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[102]}/>
                    </div>
                    <div className="tooth col-start-12 row-start-1 w-5 h-8 ml-1">
                        <Tooth tooth={202} state={dentalInfo[202]} text_position="above" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[202]}/>
                    </div>
                    <div className="tooth col-start-10 row-start-1 w-5 h-8 -mt-2 ml-1">
                        <Tooth tooth={101} state={dentalInfo[101]} text_position="above" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[101]}/>
                    </div>
                    <div className="tooth col-start-11 row-start-1 w-5 h-8 -mt-2 ml-1">
                        <Tooth tooth={201} state={dentalInfo[201]} text_position="above" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[201]}/>
                    </div>
                </div>
                <div id={!props.expand ? "divider" : ""} className={`border border-gray-300 mt-10 ${!props.expand ? "w-[600px] lg:w-0 lg:h-[500px]" : "w-[600px]"}`}/>
                <div className="grid grid-cols-20 w-[600px] mt-4">
                    <div className="tooth col-start-10 row-start-6 w-5 h-8 ml-1 mt-6">
                        <Tooth tooth={401} state={dentalInfo[401]} text_position="below" type="Feline" handleToothClick={handleToothUpdate} gap="gap-y-1.5" tooltip={tooltips[401]}/>
                    </div>
                    <div className="tooth col-start-11 row-start-6 w-5 h-8 ml-1 mt-6">
                        <Tooth tooth={301} state={dentalInfo[301]} text_position="below" type="Feline" handleToothClick={handleToothUpdate} gap="gap-y-1.5" tooltip={tooltips[301]}/>
                    </div>
                    <div className="tooth col-start-9 row-start-6 w-5 h-8 ml-2 mt-4">
                        <Tooth tooth={402} state={dentalInfo[402]} text_position="below" type="Feline" handleToothClick={handleToothUpdate} gap="gap-y-0" tooltip={tooltips[402]}/>
                    </div>
                    <div className="tooth col-start-12 row-start-6 w-5 h-8 mt-4">
                        <Tooth tooth={302} state={dentalInfo[302]} text_position="below" type="Feline" handleToothClick={handleToothUpdate} gap="gap-y-0" tooltip={tooltips[302]}/>
                    </div>
                    <div className="tooth col-start-8 row-start-6 w-16 h-8 -ml-6 -mt-3">
                        <Tooth tooth={403} state={dentalInfo[403]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[403]}/>
                    </div>
                    <div className="tooth col-start-12 row-start-6 w-16 h-8 -mt-3 ml-6">
                        <Tooth tooth={303} state={dentalInfo[303]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[303]}/>
                    </div>
                    <div className="tooth col-start-6 row-start-5 w-16 h-20 -ml-5 mt-4">
                        <Tooth tooth={404} state={dentalInfo[404]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[404]}/>
                    </div>
                    <div className="tooth col-start-13 row-start-5 w-16 h-20 mt-4 -ml-2">
                        <Tooth tooth={304} state={dentalInfo[304]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[304]}/>
                    </div>
                    <div className="h-24 row-start-4">

                    </div>
                    <div className="tooth col-start-6 row-start-3 w-16 h-12 ml-4 mt-1">
                        <Tooth tooth={407} state={dentalInfo[407]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[407]}/>
                    </div>
                    <div className="tooth col-start-12 row-start-3 w-16 h-12 ml-4 mt-1">
                        <Tooth tooth={307} state={dentalInfo[307]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[307]}/>
                    </div>
                    <div className="tooth col-start-6 row-start-2 w-16 h-16 -ml-2 mt-2">
                        <Tooth tooth={408} state={dentalInfo[408]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[408]}/>
                    </div>
                    <div className="tooth col-start-12 row-start-2 w-16 h-16 ml-8 mt-2">
                        <Tooth tooth={308} state={dentalInfo[308]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[308]}/>
                    </div>
                    <div className="tooth col-start-6 row-start-1 w-16 h-20 -ml-6 -mt-2">
                        <Tooth tooth={409} state={dentalInfo[409]} text_position="left" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[409]}/>
                    </div>
                    <div className="tooth col-start-12 row-start-1 w-16 h-20 ml-12 -mt-2">
                        <Tooth tooth={309} state={dentalInfo[309]} text_position="right" type="Feline" handleToothClick={handleToothUpdate} tooltip={tooltips[309]}/>
                    </div>
                </div>
            </div>
            <div className="h-20" />
        </div>
    </div>)
}