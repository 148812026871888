import { TourConfig, Step } from "../types";
import WelcomeScreen from "../modals/WelcomeScreen";
import EndScreen from "../modals/EndScreen";
import { TOUR_IDS, tourStyles } from "../constants";
import { WrenchIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/outline";

export enum TemplateEditorTourIdentifiers {
  TEMPLATE_EDITOR_NAME_INPUT = 0,
  TEMPLATE_EDITOR_IMPORT_WITH_AI = 1,
  TEMPLATE_EDITOR_TYPE_SELECTION = 2,
  TEMPLATE_EDITOR_CLIENT_TYPE_TONE = 3,
  TEMPLATE_EDITOR_PREVIEW = 4,
  TEMPLATE_EDITOR_PREVIEW_CONTENT = 5,
  TEMPLATE_EDITOR_SECTION_HIGHLIGHT = 6,
  TEMPLATE_EDITOR_SECTION_NAME_EDIT = 7,
  TEMPLATE_EDITOR_SECTION_TYPE_EDIT = 8,
  TEMPLATE_EDITOR_ENTRY_HIGHLIGHT = 9,
  TEMPLATE_EDITOR_ENTRY_TYPE_EDIT = 10,
  TEMPLATE_EDITOR_ENTRY_INSTRUCTIONS = 11,
  TEMPLATE_EDITOR_ENTRY_DEFAULT = 12,
  TEMPLATE_EDITOR_ENTRY_DELETE = 13,
  TEMPLATE_EDITOR_DRAG_AND_DROP = 14,
  TEMPLATE_EDITOR_PREBUILTS = 15,
  TEMPLATE_EDITOR_SAVE_BUTTON = 16,
  TEMPLATE_EDITOR_SHARE_BUTTON = 17,
  TEMPLATE_EDITOR_SETTINGS_BUTTON = 18,
  TEMPLATE_EDITOR_VIDEO_TUTORIAL = 19
}

export const TEMPLATE_EDITOR_STEPS_DEFINITION: Step[] = [
  {
    id: "TEMPLATE_EDITOR_NAME_INPUT",
    target: '[data-tour-id="template-name-input"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          Start by giving your <span className="text-blue-600 font-semibold">template</span> a clear{" "}
          <span className="text-blue-600 font-semibold">name</span>. A memorable name makes it easy to find and identify later.
        </span>
      </div>
    ),
    placement: "top",
    disableBeacon: true,
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_IMPORT_WITH_AI",
    target: '[data-tour-id="template-import-with-ai"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          Already have templates you love to use? Quickly bring them into VetRec with{" "}
          <span className="text-blue-600 font-semibold">Import Template with AI</span>. You can even reuse prefilled notes from old visits!
        </span>
      </div>
    ),
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_TYPE_SELECTION",
    target: '[data-tour-id="template-type-selection"]',
    content: (
      <div className="leading-relaxed space-y-2">
        <p>Select your <span className="text-blue-600 font-semibold">template</span>’s type:</p>
        <p>
          <span className="text-red-600 font-semibold">Notes</span> for Medical Notes
        </p>
        <p>
          <span className="text-blue-600 font-semibold">Client</span> for Client Communications &amp; Discharges
        </p>
        <p>
          <span className="text-yellow-600 font-semibold">Recap</span> for summarizing existing info in Records Recap
        </p>
      </div>
    ),
    placement: "right",
    spotlightPadding: 6,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_CLIENT_TYPE_TONE",
    target: '[data-tour-id="template-client-type-tone"]',
    content: (
      <div className="leading-relaxed space-y-3">
        For <span className="text-blue-600 font-semibold">Client</span> templates, you can choose the tone and style of the information:
        <br />
        Use <span className="text-blue-600 font-semibold">Casual</span> or <span className="text-blue-600 font-semibold">Professional</span> to replace medical jargon with more layman-friendly terms.
      </div>
    ),
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_PREVIEW",
    target: '[data-tour-id="template-preview-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          Let's see how your <span className="text-blue-600 font-semibold">template</span> currently looks. Click on the{" "}
          <span className="text-blue-600 font-semibold">Preview</span> button to continue.
        </span>
      </div>
    ),
    spotlightClicks: true,
    spotlightPadding: 6,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
      buttonNext: tourStyles.buttons.next.disabled,
    },
  },
  {
    id: "TEMPLATE_EDITOR_PREVIEW_CONTENT",
    target: ".quill",
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          This is how your <span className="text-blue-600 font-semibold">Notes</span> template will appear with default text.
        </span>
        <div className="leading-relaxed">
          <span>
            You can always switch back to <span className="text-blue-600 font-semibold">Edit</span>{" "}
            <PencilIcon className="inline-block h-4 w-4 text-blue-600" /> mode once you're done previewing.
          </span>
        </div>
      </div>
    ),
    placement: "bottom",
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_SECTION_HIGHLIGHT",
    target: '[data-tour-id="template-section"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          This is a <span className="text-blue-600 font-semibold">Section</span> in your{" "}
          <span className="text-blue-600 font-semibold">template</span>. Sections break your template into meaningful chunks, and you can reposition them via <span className="text-blue-600 font-semibold">drag and drop</span>.
        </span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 0,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_SECTION_NAME_EDIT",
    target: '[data-tour-id="template-section-name-input"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          You can <span className="text-blue-600 font-semibold">name</span> each <span className="text-blue-600 font-semibold">Section</span> on this box.
        </span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 4,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_SECTION_TYPE_EDIT",
    target: '[data-tour-id="template-section-type-selection"]',
    content: (
      <div className="leading-relaxed space-y-3">
        Choose among available <span className="text-blue-600 font-semibold">Section Types</span> here. Different types can provide different text or formatting options within the section.
      </div>
    ),
    placement: "top",
    spotlightPadding: 3,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_ENTRY_HIGHLIGHT",
    target: '[data-tour-id="template-entry"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          <span className="text-blue-600 font-semibold">Entries</span> are the building blocks inside each section. Just like sections, you can <span className="text-blue-600 font-semibold"> drag and drop</span> to move them around.
        </span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_ENTRY_TYPE_EDIT",
    target: '[data-tour-id="template-entry-type-edit"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Similarly, you can choose the <span className="text-blue-600 font-semibold">Name</span> and select the <span className="text-blue-600 font-semibold">Type</span> of the entry here.</span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_ENTRY_INSTRUCTIONS",
    target: '[data-tour-id="template-entry-instructions-input"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Use <span className="text-blue-600 font-semibold">Instructions</span> to explain VetRec how you want to use fill out this <span className="text-blue-600 font-semibold">Entry</span> in your template. Yup! It's that easy! Just plain old English.</span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_ENTRY_DEFAULT",
    target: '[data-tour-id="template-entry-defaults-input"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          You can set <span className="text-blue-600 font-semibold">Default</span> values for the entry. This will be used when no abnormal values are detected for this particular entry. (e.g. "WNL", "NSF", "Normal", etc.)
        </span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_ENTRY_DELETE",
    target: '[data-tour-id="template-entry-delete"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          You can <span className="text-red-600 font-semibold">Delete</span> a{" "}
          <span className="text-blue-600 font-semibold">Section</span>,{" "}
          <span className="text-blue-600 font-semibold">Entry</span>, or{" "}
          <span className="text-blue-600 font-semibold">Sub-entry</span> by clicking here.
        </span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_DRAG_AND_DROP",
    target: '[data-tour-id="template-drag-and-drop"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          You can <span className="text-blue-600 font-semibold">Drag and Drop</span> from here to{" "}
          <span className="text-blue-600 font-semibold">Add</span> more sections and entries to your{" "}
          <span className="text-blue-600 font-semibold">template</span>.
        </span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 8,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_PREBUILTS",
    target: '[data-tour-id="template-prebuilt-sections"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          Need inspiration? Check out our <span className="text-blue-600 font-semibold">Prebuilt Sections and Entries</span>, including{" "}
          <span className="text-blue-600 font-semibold">Dental Charts</span> 🦷
        </span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_SAVE_BUTTON",
    target: '[data-tour-id="template-save-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          Once everything is ready, make sure to <span className="text-blue-600 font-semibold">Save</span> your work so you don't lose any changes.
        </span>
      </div>
    ),
    placement: "top",
    spotlightClicks: true,
    spotlightPadding: 4,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
    },
  },
  {
    id: "TEMPLATE_EDITOR_SHARE_BUTTON",
    target: '[data-tour-id="template-share-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          Use <span className="text-blue-600 font-semibold">Share</span> to make your templates accessible to anyone on VetRec! 🌎
        </span>
      </div>
    ),
    placement: "top",
    spotlightPadding: 4,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_SETTINGS_BUTTON",
    target: '[data-tour-id="template-settings-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>
          Looking for more control? Open <span className="text-blue-600 font-semibold">Settings</span> to tailor aspects like <span className="text-blue-600 font-semibold">styling</span> or <span className="text-blue-600 font-semibold">integrations</span>.
        </span>
      </div>
    ),
    placement: "left-start",
    spotlightPadding: 4,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: "TEMPLATE_EDITOR_VIDEO_TUTORIAL",
    target: '[data-tour-id="template-builder-tutorial-button"]',
    content: (
      <div className="leading-relaxed space-y-4">
        <span>
          Want to see it all in action? Check out our <span className="text-blue-600 font-semibold">video tutorial</span> where Crystal walks you through a full overview!
        </span>
        <div className="relative w-full pt-[56.25%]">
          <iframe
            className="absolute top-0 left-0 w-full h-full rounded-lg"
            src="https://www.youtube.com/embed/U47wB8VJc7s?si=Sq0JA6acnklwUvAh"
            title="Template Overview"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </div>
      </div>
    ),
    styles: {
      spotlight: tourStyles.spotlight.transparent,
    },
  },
];

const welcomeScreenProps = {
  icon: <WrenchIcon />,
  title: (
    <>
      Welcome to the <span className="font-bold text-blue-600">Template Builder</span>
    </>
  ),
  description:
    "We'll guide you through building, customizing, and sharing templates that perfectly suit your workflow.",
};

export const TemplateEditorTourConfig: TourConfig = {
  steps: TEMPLATE_EDITOR_STEPS_DEFINITION,
  tourId: TOUR_IDS.TEMPLATE_EDITOR,
  WelcomeModal: (props) => <WelcomeScreen {...props} {...welcomeScreenProps} />,
  EndModal: (props) => (
    <EndScreen
      {...props}
      title="You're All Set!"
      description="Now you know your way around the template builder. Ready to create your first template?"
      primaryButtonText="Done"
      secondaryActions={[
        { text: "Take me to my templates", navigateTo: "/templates" },
        { text: "Read more about templates", url: "https://help.vetrec.io/en/collections/8918950-templates" },
      ]}
    />
  ),
  showConfetti: true,
};
