export const TOUR_IDS = {
    SCRIBE_BASIC: 'scribe_basic',
    TEMPLATES_OVERVIEW: 'templates_page',
    TEMPLATE_EDITOR: 'template_editor',
  } as const;

// Tour style constants
const BASE_SPOTLIGHT_STYLE = {
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  borderRadius: '5px',
  border: '2px solid rgba(59, 130, 246, 0.5)',
} as const;

const BASE_BUTTON_DISABLED_STYLE = {
  opacity: 0.5,
  pointerEvents: 'none' as const,
} as const;

// Super high z-index to make sure the tour is always on top
export const SUPER_HIGH_Z_INDEX = 'z-[999999999]';

export const tourStyles = {
  spotlight: {
    default: BASE_SPOTLIGHT_STYLE,
    pulsing: {
      ...BASE_SPOTLIGHT_STYLE,
      animation: 'pulse 2s infinite',
      '@keyframes pulse': {
        '0%': {
          backgroundColor: 'rgba(255, 255, 255, 0.3)'
        },
        '50%': {
          backgroundColor: 'rgba(255, 255, 255, 0.6)'
        },
        '100%': {
          backgroundColor: 'rgba(255, 255, 255, 0.3)'
        }
      }
    },
    transparent: {
      backgroundColor: 'transparent'
    },
    transparentWithBorder: {
      ...BASE_SPOTLIGHT_STYLE,
      backgroundColor: 'transparent',
      border: '2px solid rgba(59, 130, 246, 0.5)',
    }
  },
  buttons: {
    next: {
      disabled: BASE_BUTTON_DISABLED_STYLE
    },
    back: {
      disabled: BASE_BUTTON_DISABLED_STYLE
    }
  },
  overlay: {
    transparent: {
      backgroundColor: 'transparent'
    }, 
    light: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    }
  }
} as const;

export type TourId = typeof TOUR_IDS[keyof typeof TOUR_IDS];