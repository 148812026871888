import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { UserRole } from '../../settings/UserRoles';
import { capitalize } from 'lodash';

interface RoleSelectorProps {
  selectedRole: UserRole | undefined;
  onSelectRole: (role: UserRole) => Promise<void>;
  className?: string;
  disabled?: boolean;
}

const RoleSelector: React.FC<RoleSelectorProps> = ({
  selectedRole,
  onSelectRole,
  className = '',
  disabled = false
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  // For roles, we'll only ever have one selection, so simplify to a string  
  const [localSelectedRole, setLocalSelectedRole] = useState<UserRole | undefined>(selectedRole);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [menuPlacement, setMenuPlacement] = useState<'bottom' | 'top'>('bottom');
  const roles = Object.values(UserRole);
  const NO_SELECTION = 'none';

  // Update local state when props change
  useEffect(() => {
    setLocalSelectedRole(selectedRole);
  }, [selectedRole]);

  // Filter roles based on search term
  const filteredRoles = useMemo(() => roles.filter(
    (role) => role.toLowerCase().includes(searchTerm.toLowerCase())
  ), [roles, searchTerm]);

  // Select role (only one at a time)
  const selectRole = (role: UserRole | typeof NO_SELECTION) => {
    // If selecting "No role" or clicking on the currently selected role, clear selection
    if (role === NO_SELECTION || role === localSelectedRole) {
      setLocalSelectedRole(undefined);
    } else {
      setLocalSelectedRole(role);
    }
  };

  // Save changes
  const saveChanges = async () => {
    if (localSelectedRole !== selectedRole) {
      await onSelectRole(localSelectedRole as UserRole);
    }

    setIsOpen(false);
  };

  // Find the currently selected role object
  const selectedRoleObject = useMemo(() =>
    localSelectedRole ? filteredRoles.find(role => role === localSelectedRole) : undefined,
    [filteredRoles, localSelectedRole]);

  // Filter out the selected role from available options
  const availableRoles = useMemo(() =>
    filteredRoles.filter(role => role !== localSelectedRole as UserRole),
    [filteredRoles, localSelectedRole]);

  const hasChanges = useMemo(() => {
    return selectedRole !== localSelectedRole;
  }, [selectedRole, localSelectedRole]);

  // Check position before opening menu
  const checkPosition = useCallback(() => {
    if (menuButtonRef.current) {
      const buttonRect = menuButtonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const menuHeight = Math.min((filteredRoles.length + 1) * 40 + 110, 350); // Height including search + items + "No role" option + save button
      setMenuPlacement(spaceBelow < menuHeight ? 'top' : 'bottom');
    }
  }, [filteredRoles.length]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) &&
        menuButtonRef.current && !menuButtonRef.current.contains(event.target as Node)) {
        // Reset local selection if closed without saving
        if (hasChanges) {
          setLocalSelectedRole(selectedRole);
        }
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', checkPosition, true);
    window.addEventListener('resize', checkPosition);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', checkPosition, true);
      window.removeEventListener('resize', checkPosition);
    };
  }, [checkPosition, hasChanges, selectedRole]);

  const toggleMenu = () => {
    if (!isOpen) {
      checkPosition();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative inline-block text-left ${className}`} ref={menuRef}>
      <button
        className={`inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm ${disabled ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-50'}`}
        disabled={disabled}
        ref={menuButtonRef}
        onClick={toggleMenu}
      >
        Role
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </button>

      {isOpen && (
        <div
          className={`absolute ${menuPlacement === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'} right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50`}
        >
          <div className="px-4 py-3">
            <input
              type="text"
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Search roles..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>

          <div className="max-h-60 overflow-y-auto">
            {/* Selected role section */}
            {selectedRoleObject && (
              <div className="py-1">
                <p className="px-4 py-2 text-xs font-semibold text-gray-500">SELECTED ROLE</p>
                <button
                  className="group flex items-center px-4 py-2 text-sm w-full text-left text-gray-900 hover:bg-gray-100"
                  onClick={() => selectRole(selectedRoleObject)}
                >
                  <svg className="mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                  <span className="ml-8">{capitalize(selectedRoleObject.replace("org:", "").replace("_", " "))}</span>
                </button>
              </div>
            )}
            {!selectedRoleObject && (
              <div className="py-1">
                <p className="px-4 py-2 text-xs font-semibold text-gray-500">NO ROLE SELECTED</p>
                <p className="px-4 py-2 text-xs text-red-600 italic">Select a role</p>
              </div>
            )}

            {/* Available options section */}
            <div className="py-1 border-t border-gray-100">
              <p className="px-4 py-2 text-xs font-semibold text-gray-500">AVAILABLE OPTIONS</p>

              {/* Available roles */}
              {availableRoles.map((role) => (
                <button
                  key={role}
                  className="group flex items-center px-4 py-2 text-sm w-full text-left text-gray-700 hover:bg-gray-100"
                  onClick={() => selectRole(role)}
                >
                  <span className="ml-8">{capitalize(role.replace("org:", "").replace("_", " "))}</span>
                </button>
              ))}

              {searchTerm && availableRoles.length === 0 && !selectedRoleObject && (
                <div className="text-center py-2 text-sm text-gray-500">
                  No role found
                </div>
              )}
            </div>
          </div>

          <div className="py-2 px-4 border-t border-gray-100 flex flex-col gap-y-2">
            <button
              type="button"
              className={`inline-flex justify-center w-full rounded-md border px-4 py-2 bg-blue-600 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${!hasChanges || !localSelectedRole ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={void saveChanges}
              disabled={!hasChanges || !localSelectedRole}
            >
              Save Changes
            </button>
            <span className='text-xs text-gray-500 italic whitespace-pre-line text-center'>
              Role will be assigned for all teams
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleSelector;
