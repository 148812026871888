import './App.css';
import { Route, Routes } from "react-router-dom";
import Dashboard from './Dashboard';
import Register from './auth/Register'
import Login from './auth/Login'
import React from "react";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { SignedIn, SignedOut } from '@clerk/clerk-react'
import Intake from './Intake';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement, LineController, BarController } from "chart.js";
import { VetRecProvider } from './providers/VetRecProvider';
import { TourProvider } from './providers/TourProvider';
import SSOLogin from './auth/SSOLogin';
import SSORedirect from './auth/SSORedirect';
import { isMobileDevice } from './utils/deviceUtils';
import Admin from './Admin';

function App() {
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController
  );

  return (
    <VetRecProvider>
      <div className='thin-scrollbar overflow-hidden'>
        <Analytics /> 
        <SpeedInsights /> 
          <SignedIn>
            <TourProvider>
              <Routes>
                <Route path="*" element={<Dashboard key="scribe" capability={'Scribe'}/>} />
                <Route path="/active" element={<Dashboard key="active" capability={'Active'}/>} />
                <Route path="/scribe" element={<Dashboard key="scribe" capability={'Scribe'}/>} />
                <Route path="/prehistory" element={<Dashboard key="prehistory" capability={'PreHistory'}/>} />
                <Route path="/history" element={<Dashboard key="history" capability={'History'}/>} />
                <Route path="/copilot" element={<Dashboard key="copilot" capability={'Copilot'}/>} />
                <Route path="/templates" element={<Dashboard key="templates" capability={'Templates'}/>} />
                <Route path="/templateBuilder" element={<Dashboard key="templateBuilder" capability={'TemplateBuilder'}/>} />
                <Route path="/settings" element={<Dashboard key="settings" capability={'Settings'}/>} />
                <Route path="/intake" element={<Intake />}/>
                <Route path="/integrations" element={<Dashboard key="integrations" capability={'Integrations'}/>} />
                <Route path="/analytics" element={<Dashboard key="analytics" capability={'Analytics'}/>} />
                <Route path="/admin" element={<Admin key="admin" capability={'Users'}/>} />
                <Route path="/admin/users" element={<Admin key="users" capability={'Users'}/>} />
                <Route path="/admin/teams" element={<Admin key="teams" capability={'Teams'}/>} />
                <Route path="/admin/tags" element={<Admin key="tags" capability={'Tags'}/>} />
                <Route path="/admin/settings" element={<Admin key="users" capability={'Users'}/>} />
                {isMobileDevice() && <Route path="/saml" element={<SSORedirect/>} />}
            </Routes>
          </TourProvider>
          </SignedIn>
          <SignedOut> 
            <Routes>
              <Route path="/login" element={<Login />}/>
              <Route path="/register" element={<Register />}/>
              <Route path="*" element={<Login />} />
              <Route path="/intake" element={<Intake />}/>
              {isMobileDevice() && <Route path='/saml' element={<SSOLogin />} />}
            </Routes>
          </SignedOut>
      </div>
    </VetRecProvider>
  );
}

export default App;
