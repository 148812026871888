import { MicrophoneIcon } from '@heroicons/react/20/solid'
import { useEffect, useMemo, useRef, useState, createRef } from 'react'
import {processRecording, saveName, reprocessRecording } from './ServerActions'
import { ArrowDownTrayIcon, ArrowRightCircleIcon, CheckBadgeIcon, ExclamationTriangleIcon, PlusCircleIcon, StopCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Spinner } from './utils/Spinner';
import { TemplateObject } from './utils/TemplateObject';
import va from '@vercel/analytics';
import { useNavigate } from "react-router-dom"
import { useAuth } from "@clerk/clerk-react"
import TemplatePicker from './components/templates/TemplatePicker';
import MicrophoneWave from './components/history/MicrophoneWave';
import { isMobileDevice } from './utils/deviceUtils';
import { formatTime,  } from './utils/RecordingUtils';
import NoSleep from 'nosleep.js';
import { useRecording, PermissionStatus, RecordingStatus } from './providers/RecordingProvider';
import PermissionsHelper from './components/recording/PermissionsHelper';
import MicrophonePicker from './components/history/MicrophonePicker';
import Reminder from './components/ReminderToast';
import ErrorAlert from './components/Error';
import UserPicker from './components/UserPicker'
import ConsentDialog from './components/recording/ConsentDialog'
import { TemplateType } from './components/templates/TemplateUtils'
import { useVetRec } from './providers/VetRecProvider';
import { useTour } from './providers/TourProvider';
import { ScribeBasicTourIdentifiers } from './components/tours/scribeBasic/config';
import { TOUR_IDS } from './components/tours/constants';
import LanguageSelectorButton from './components/LanguageSelectorButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaw } from '@fortawesome/free-solid-svg-icons'

// Define the types for your props
interface ScribeProps {
    
}

export default function Scribe(props:ScribeProps) {
    // Session controllers
    const isMobile = useMemo(isMobileDevice, [])
    const [error, setError] = useState<string|undefined>(undefined)
    const [remainder, setRemainder] = useState<string|undefined>(undefined)
    const { getToken } = useAuth();
        const  navigate = useNavigate()
    const startProcessingRef = useRef<HTMLButtonElement>(null);

    // Template Variables
    const [template, setTemplate] = useState<TemplateObject>()

    // Recording Variables
    const [processingContext, setProcessingContext] = useState<boolean>(false)
    const [buttonClick, setButtonClick] = useState<boolean>(false)
    const [reuploadClick, setReuploadClick] = useState<boolean>(false)
    const [canWeHearYou , setCanWeHearYou] = useState<boolean>(false)
    const {recordAsEnabled, organizationAdmin, chooseTemplateUsingActAs, trialExpired, checkTourStatus} = useVetRec()
    const recordAsEnabledPermission = useMemo<boolean>(() => (recordAsEnabled || organizationAdmin),[recordAsEnabled, organizationAdmin])
    const {
        sessionId,
        recordingState,
        stopRecording,
        startRecording,
        recordingAllowed,
        anyRecording,
        name,
        updateName,
        petNames,
        setPetNames,
        addToRecording,
        seconds,
        mediaStream,
        consent,
        consentPending,
        updateRecordAs,
        membersList,
        recordAs,
        existingTemplateId,
        handleUpload,
        uploadPercentage,
        spokenLanguage,
                selectedMicrophone,
        downloadRecording,
        externalTypeId
    } = useRecording()

    const { multipetEnabled } = useVetRec()

    // Determine if name can be updated based on recording state
    const shouldAllowNameUpdate = useMemo(() => {
        return (recordingState === RecordingStatus.NOTSTARTED || 
               recordingState === RecordingStatus.FAILED || 
               recordingState === RecordingStatus.FAILED_NAME || 
               recordingState === RecordingStatus.FAILED_REPEATED_NAME_IN_MULTIPET) && 
               !(addToRecording || anyRecording);
    }, [recordingState, addToRecording, anyRecording]);

    // Multi-pet
    const [multipet, setMultipet] = useState<boolean>(false);
    const inputRefs = useMemo(() => 
        Array(10).fill(null).map(() => createRef<HTMLInputElement>()), 
        []
    );
    const [isAddingNewPet, setIsAddingNewPet] = useState<boolean>(false);

    // Add a ref for the single pet input near the other refs
    const singleInputRef = useRef<HTMLInputElement>(null);

    // Function to check if a name is duplicated
    const isDuplicateName = (name: string, index: number): boolean => {
        return petNames.some((petName, i) => i !== index && petName.toLowerCase().trim() === name.toLowerCase().trim() && name !== '');
    };

    // Function to get input validation class
    const getValidationClass = (petName: string, index: number): string => {
        if (petName.trim() === '') return 'bg-red-50 ring-2 ring-red-500';
        if (isDuplicateName(petName, index)) return 'bg-red-50 ring-2 ring-red-500';
        return 'bg-blue-50 hover:bg-blue-100';
    };

    // Function to get input text validation class
    const getInputValidationClass = (petName: string, index: number): string => {
        const baseClass = "bg-transparent border-none focus:ring-0 p-0 w-[1ch] min-w-[8ch] max-w-[20ch]";
        if (petName.trim() === '' || isDuplicateName(petName, index)) {
            return `${baseClass} text-red-700 placeholder-red-500`;
        }
        return `${baseClass} text-gray-900`;
    };

    // Function to get delete button class
    const getDeleteButtonClass = (petName: string, index: number): string => {
        if (petName.trim() === '' || isDuplicateName(petName, index)) {
            return "ml-1 text-red-500 hover:text-red-700";
        }
        return "ml-1 text-blue-400 hover:text-blue-600";
    };

    useEffect(() => {
        if (petNames && petNames.length > 1) {
            setMultipet(true);
            // Focus on the last input only when adding a new pet
            if (isAddingNewPet) {
                setTimeout(() => {
                    // const lastRef = inputRefs[petNames.length - 1]?.current;
                    // Find first input that is not empty
                    const lastRef = inputRefs.find(ref => ref.current?.value === '')?.current ?? inputRefs[petNames.length - 1]?.current;
                    if (lastRef) {
                        lastRef.focus();
                    }
                }, 0);
                setIsAddingNewPet(false);
            }
        } else {
            setMultipet(false);
        }
    }, [petNames, isAddingNewPet]);


    // Parse multiple pet names from a single input
    const parsePetNames = (input: string): string[] => {
        if (!multipetEnabled) {
            return [input];
        }
        // Process for "and" (case insensitive)
        const lowerInput = input.toLowerCase();
        if (lowerInput.includes(' and ')) {
            // Use the original string's position to split to maintain original casing
            const andIndex = lowerInput.indexOf(' and ');
            const firstPart = input.slice(0, andIndex);
            const secondPart = input.slice(andIndex + 5); // 5 is length of " and "
            return [firstPart.trim(), secondPart.trim()];
        }
        return [input];
    };

    // Handle name input changes with automatic multi-pet detection
    const handleNameChange = (value: string) => {
        const detectedNames = parsePetNames(value);
        
        if (detectedNames.length > 1) {
            setIsAddingNewPet(true);
            setPetNames(detectedNames);
            setMultipet(true);
            updateName("");
        } else {
            updateName(value);
            setPetNames([]);
            setMultipet(false);
        }
    };

    // Update a pet name in the array
    const updatePetName = (index: number, value: string) => {
        // Check for " and " (case insensitive)
        const lowerValue = value.toLowerCase();
        if (lowerValue.endsWith(' and ')) {
            // Remove the separator and any trailing spaces
            const cleanValue = value.slice(0, -4).trim(); // Remove " and"
            
            // Update the current pet name
            const newPetNames = [...petNames];
            newPetNames[index] = cleanValue;
            
            // Add a new empty pet name if we haven't reached the limit and current name is valid
            if (petNames.length < 10 && !isDuplicateName(cleanValue, index) && cleanValue.trim() !== '') {
                setIsAddingNewPet(true);
                newPetNames.push('');
                setPetNames(newPetNames);
            } else {
                setPetNames(newPetNames);
            }
        } else {
            const newPetNames = [...petNames];
            newPetNames[index] = value;
            setPetNames(newPetNames);
        }
    };

    // Update the handleKeyDown function
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && e.currentTarget.value === '' && petNames[index] === '') {
            e.preventDefault();
            const newPetNames = [...petNames];
            
            if (index > 0) {
                newPetNames.splice(index, 1);
                
                if (newPetNames.length <= 1) {
                    const finalName = newPetNames[0] ?? "";
                    updateName(finalName);
                    setPetNames([]);
                    setMultipet(false);
                    
                    // Delay the focus until after state updates
                    setTimeout(() => {
                        if (singleInputRef.current) {
                            singleInputRef.current.focus();
                            const length = finalName.length;
                            singleInputRef.current.setSelectionRange(length, length);
                        }
                    }, 0);
                } else {
                    setPetNames(newPetNames);
                    
                    // Focus on the previous input
                    const previousInput = inputRefs[index - 1]?.current;
                    if (previousInput) {
                        previousInput.focus();
                        const length = previousInput.value.length;
                        previousInput.setSelectionRange(length, length);
                    }
                }
            }
        }
    };

    // Called when the user clicks the delete icon on a pet tag
    const handleDeleteTag = (index: number) => {
        const newPetNames = petNames.filter((_, i) => i !== index);
        if (newPetNames.length <= 1) {
            updateName(newPetNames[0] ?? "");
            setPetNames([]);
            setMultipet(false);
        } else {
            setPetNames(newPetNames);
        }
    };

    // Add a new pet to the list
    const addNewPet = () => {
        setIsAddingNewPet(true);
        setPetNames([...petNames, ""]);
        setMultipet(true);
    };

    // Language (Controls if we use Assembly or OpenAI Whispers for transcription)
    const [selectedLanguage, setSelectedLanguage] = useState<string>(spokenLanguage ?? "English (US)")

    useEffect(() => {
        if (spokenLanguage) {
            setSelectedLanguage(spokenLanguage)
        }
    }, [spokenLanguage])

    // Add tour context
    const { isTourRunning, currentStep, setCurrentStep, setIsTourHidden, startTour } = useTour();

    useEffect(() => {
        const handleBeforeUnload = async (e: BeforeUnloadEvent): Promise<void> => {
            if (recordingState === RecordingStatus.UPLOADING) {
                e.preventDefault();
                let properties =  {
                    date:(new Date()).toUTCString(),
                    sessionId: sessionId
                }
                va.track("Leaving before upload finished", properties)
            }
            else if (recordingState === RecordingStatus.RECORDING){
                e.preventDefault();
                let properties =  {
                    date:(new Date()).toUTCString(),
                    sessionId: sessionId
                }
                va.track("Leaving during recording", properties)
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        if(recordingState === RecordingStatus.FAILED_UPLOAD){
            setError("Failed to upload the recording. Please try again.")
        }else if (recordingState === RecordingStatus.FAILED_NAME){
            setError("Please enter a name for the session.")
        }else if (recordingState === RecordingStatus.FAILED_REPEATED_NAME_IN_MULTIPET){
            setError("Please enter a unique name for each pet.")
        }else if (recordingState === RecordingStatus.STOPPED){
            setRemainder("Upload successful! Start processing.")
        }
        else if(recordingState === RecordingStatus.FAILED_NOT_FOUND){
            setError("Visit not found. Please try again.")
        }

        return (): void => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [recordingState]);

    const noSleep = useMemo(() => new NoSleep(), [])
    function toggleNoSleep(enable:boolean) {
        if(enable){
            try{
                noSleep.enable();
                //alert(`click and enable noSleep`);
            }catch(error){
                console.error('Failed to activate wake lock:', error);
                let properties =  {
                    date:(new Date()).toUTCString(),
                    ua:navigator.userAgent,
                    session:sessionId
                }
                va.track("WakeLock_Failed", properties)
            }
        } else {
            try{
                noSleep.disable();
            }catch(error){
                console.error('Failed to release wake lock:', error);
            }
        }
    }

     // Tour Effects
     useEffect(() => {
        const hasCompletedTour = checkTourStatus(TOUR_IDS.SCRIBE_BASIC)
        if (isTourRunning || hasCompletedTour) return;
        
        if (trialExpired === false) {
            startTour({ tourId: TOUR_IDS.SCRIBE_BASIC });
        }
     }, [trialExpired, isTourRunning]);

     
     useEffect(() => {
        if (!isTourRunning) return;

        if (currentStep === ScribeBasicTourIdentifiers.SELECTING_TEMPLATE_ON_SCRIBE && !name) {
            updateName('Mr.Wiggles');
        }
    }, [isTourRunning, currentStep, name]);

    useEffect(() => {
        if (recordingAllowed !== PermissionStatus.GRANTED) {
            setIsTourHidden(true);
        } else {
            setIsTourHidden(false);
        }
    }, [isTourRunning, recordingAllowed]);


    
    async function handleRecord(): Promise<void> {
        try{
            const templateId = template ? `${template.organization}/${template.id}` : undefined;
            if(recordingState === RecordingStatus.NOTSTARTED || recordingState === RecordingStatus.FAILED_NAME || recordingState === RecordingStatus.FAILED || recordingState === RecordingStatus.FAILED_REPEATED_NAME_IN_MULTIPET){    
                setButtonClick(true)
                toggleNoSleep(true)
                await startRecording({ reset: true, pets: petNames, templateId })
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "start",
                    sessionId: sessionId
                }
                va.track("Recording_Button_Clicked", properties)
                setButtonClick(false)
                // If we are in the recording step of the tour, move to the next step
                if (isTourRunning && currentStep === ScribeBasicTourIdentifiers.START_RECORDING) {
                    setCurrentStep(currentStep + 1);
                }
            }
            else if(recordingState === RecordingStatus.RECORDING){
                setButtonClick(true)
                toggleNoSleep(false)
                await stopRecording({ templateId });
                setCanWeHearYou(false)
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "stop",
                    sessionId: sessionId
                }
                va.track("Recording_Button_Clicked", properties)
                setButtonClick(false)
                // If we are in the stop recording step of the tour, move to the next step
                if (isTourRunning && currentStep === ScribeBasicTourIdentifiers.STOP_RECORDING) {
                    setCurrentStep(currentStep + 1);
                }
            }
            else if(recordingState === RecordingStatus.STOPPED){
                setButtonClick(true)
                toggleNoSleep(true)
                await startRecording({ reset: false, pets: petNames, templateId })
                //toggleWakeLock(true)
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "resume",
                    sessionId: sessionId
                }
                va.track("Recording_Button_Clicked", properties)
                setButtonClick(false)
            }
        } catch (error){
            setError(recordingState === RecordingStatus.RECORDING ? "Had an issue stopping the recording. Please try again." : "Had an issue starting recording. Please try again.")
            toggleNoSleep(false)
            let properties =  {
                date:(new Date()).toUTCString(),
                mediaRecorderState: recordingState,
                sessionId: sessionId,
                error: error instanceof Error ? error.toString() : ""
            }
            va.track("Recording_Button_Clicked_Failed", properties)

            setButtonClick(false)
        }
    }

    async function processContext(): Promise<void> {
        setButtonClick(true)
        let properties:any =  {
            date:(new Date()).toUTCString(),
            mediaRecorderState: "stopped",
            sessionId: sessionId, 
            selectedSpokenLanguage: selectedLanguage,
            isMultiPet: multipet
        }
        va.track("ScribePage_ProcessRecording_Click", properties)

        // If we are in the process button step of the tour, hide the tour
        if (isTourRunning && currentStep === ScribeBasicTourIdentifiers.START_PROCESSING_NOTES) {
            setIsTourHidden(true);
            setCurrentStep(currentStep + 1);
        }

        if(anyRecording && recordingState === RecordingStatus.STOPPED){
            try {
                if(addToRecording){
                    setProcessingContext(true)
                    let templateId = `${template?.organization}/${template?.id}`
                    await reprocessRecording(sessionId, name, await getToken({template:"supabase"}) ?? "", false, templateId, true, petNames, selectedLanguage)
                    setProcessingContext(false)
                    navigate('/history?session_id=' + sessionId + "&tab=notes")
                }
                else{
                    setProcessingContext(true)
                    let templateId = `${template?.organization}/${template?.id}`
                    await processRecording(sessionId, name, await getToken({template:"supabase"}) ?? "", false, templateId, true, petNames, selectedLanguage)
                    setProcessingContext(false)
                    navigate('/history?session_id=' + sessionId + "&tab=notes")
                }
            }
            catch(error) {
                setError("Couldn't start processing the session. Please try again.")
                return
            }
        }
        await saveName(sessionId, name, await getToken({template:"supabase"}) ?? "", petNames)    
    }

    async function handleReupload(): Promise<void> {
        setReuploadClick(true)
        setRemainder("Trying to reupload the recording. Please wait...")
        await handleUpload(true)
        setReuploadClick(false)

        let properties =  {
            date:(new Date()).toUTCString(),
            sessionId: sessionId,
        }
        va.track("Scribe_Reupload_Attempt", properties)
    }

    return(<>
        <div className="border-b border-gray-400 pb-5 mb-5 py-4 sm:pt-10 flex flex-row justify-between gap-x-10 items-center">
            <div>
                <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                    {addToRecording ? "Add to visit" : "Scribe"}
                </h2>
                <p className="mt-2 max-w-4xl text-sm text-gray-500 hidden sm:block">
                    VetRec Scribe listens to your consultation and automatically generates a transcript <u>and</u> notes that you can use for your records. If you would like to customize the notes template used head over to the <a href="/templates" className="underline text-gray-400">templates section</a>.
                </p>  
            </div>
            <a href='/scribe' className="inline-flex justify-center items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-2.5 text-sm font-semibold text-accent-button-text shadow-md hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 min-w-36">
                Start a visit
                <PlusCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </a>
        </div>
        <div>
            <div className='flex sm:flex-row flex-col w-full gap-x-4 gap-y-4'>
                <div data-tour-id="patient-name-input" className="relative grow">
                    <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-main-text-darker rounded-full"
                    >
                        {multipet ? "Patients' Names" : "Patient's Name"}
                    </label>
                    {!multipet ? (
                        <div className="flex w-full">
                            <input
                                type="text"
                                id="name"
                                placeholder="Mr.Wiggles"
                                value={name}
                                onChange={(e) => handleNameChange(e.target.value)}
                                disabled={!shouldAllowNameUpdate}
                                ref={singleInputRef}
                                className={`block w-full h-10 sm:h-14 border-0 py-1.5 text-main-text-darker shadow-sm ring-2 ring-inset ring-blue-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 ${multipetEnabled ? 'rounded-l-md' : 'rounded-md'}`}
                            />
                            {multipetEnabled && <button
                                type="button"
                                onClick={() => {
                                    setIsAddingNewPet(true);
                                    setMultipet(true);
                                    setPetNames([name, ""]);
                                    updateName("");
                                }}
                                disabled={!shouldAllowNameUpdate}
                                className="inline-flex items-center justify-center h-10 sm:h-14 px-3 rounded-r-md bg-accent-button text-sm font-medium text-accent-button-text hover:bg-accent-button-hover transition-colors disabled:opacity-50 border-l border-gray-300"
                            >
                                <div className="flex flex-col items-center justify-center">
                                    <div className="flex items-center">
                                        <FontAwesomeIcon icon={faPaw} className="h-4 w-4" />
                                        <span className="font-bold ml-0.5">+</span>
                                    </div>
                                    <span className="hidden md:inline text-xs whitespace-nowrap">Multi-pet</span>
                                </div>
                            </button>}
                        </div>
                    ) : (
                        <div className="flex items-center w-full min-h-[2.5rem] sm:min-h-[3.5rem] ring-2 ring-inset ring-blue-400 rounded-md bg-white">
                            <div className="flex flex-wrap gap-2 p-2 pt-4 w-full justify-start">
                                <div className="flex flex-wrap gap-x-2 gap-y-2 items-center justify-between w-full">
                                    <div className="flex flex-wrap gap-x-2 gap-y-2 items-center">
                                        {petNames.map((petName, index) => (
                                            <div
                                                key={index}
                                                className={`inline-flex items-center ${getValidationClass(petName, index)} rounded-full px-3 py-1 text-sm font-medium transition-colors`}
                                            >
                                                <input
                                                    type="text"
                                                    value={petName}
                                                    onChange={(e) => updatePetName(index, e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    className={`${getInputValidationClass(petName, index)} max-w-[200px] truncate`}
                                                    placeholder={`Patient ${index + 1}`}
                                                    disabled={!shouldAllowNameUpdate}
                                                    ref={inputRefs[index]}
                                                />
                                                <button
                                                    onClick={() => handleDeleteTag(index)}
                                                    className={getDeleteButtonClass(petName, index)}
                                                    disabled={!shouldAllowNameUpdate}
                                                >
                                                    <XMarkIcon className={`h-4 w-4 ${!shouldAllowNameUpdate ? 'text-gray-300' : ''}`} />
                                                </button>
                                            </div>
                                        ))}
                                        <button
                                            onClick={addNewPet}
                                            disabled={petNames.length >= 10 || !shouldAllowNameUpdate}
                                            className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100 transition-colors disabled:opacity-50"
                                        >
                                            <PlusCircleIcon className="h-4 w-4 mr-1" />
                                            Add Patient
                                        </button>
                                    </div>
                                    <button
                                        onClick={() => {
                                            setMultipet(false);
                                            // Keep the first pet's name when switching back
                                            const firstPetName = petNames[0] || "";
                                            updateName(firstPetName);
                                            setPetNames([]);
                                        }}
                                        disabled={!shouldAllowNameUpdate}
                                        className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-100 transition-colors disabled:opacity-50 border border-gray-200"
                                    >
                                        <FontAwesomeIcon icon={faPaw} className="h-3.5 w-3.5 mr-1" />
                                        Switch to Single Patient
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {recordAsEnabledPermission && membersList && membersList.length > 1 && <div className='relative'>
                    <UserPicker userList={membersList} userSearch={membersList.find((u) => u.identifier === recordAs)} setUserSearch={(value) => updateRecordAs(value?.identifier ?? "")} className="h-10 sm:h-14" text='Record as' disabled={recordingState !== RecordingStatus.NOTSTARTED}/>
                </div>}
                <TemplatePicker 
                    dataTourId="template-picker"
                    raiseZIndex={isTourRunning && currentStep === ScribeBasicTourIdentifiers.SELECTING_TEMPLATE_ON_SCRIBE}
                    template={template} 
                    templateId_force={existingTemplateId} 
                    external_template_id={externalTypeId}
                    actAsUser={chooseTemplateUsingActAs ? membersList.find((u) => u.identifier === recordAs) : undefined}
                    setTemplate={setTemplate} 
                    className='h-10 sm:h-14' 
                    templateBackground='bg-white' 
                    type={TemplateType.MEDICAL}/>
            </div>
            <div className='flex flex-col sm:flex-row pt-4 gap-x-8 gap-y-8'>
                <div className='shadow-md sm:w-[100%] w-full rounded-lg flex flex-col'>
                    <div className="border border-gray-400 rounded-t-lg bg-white px-4 py-5 sm:px-6">
                        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                            <div className="ml-4 mt-4">
                                <h3 className="text-base font-semibold leading-6 text-main-text-darker">
                                    Capture Recording
                                </h3>
                            </div>
                            <div className="ml-4 mt-4 flex-shrink-0">
                                <LanguageSelectorButton 
                                    selectedLanguage={selectedLanguage}
                                    setSelectedLanguage={setSelectedLanguage}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-grow py-8 sm:py-12 border border-gray-300 rounded-b-lg justify-center'>
                        {recordingAllowed !== PermissionStatus.GRANTED && < PermissionsHelper />}
                        {recordingAllowed === PermissionStatus.GRANTED && !selectedMicrophone && 
                            <div className='flex flex-row gap-x-8 w-full justify-center px-4 sm:px-8 items-center'>
                                <ExclamationTriangleIcon className='h-6 w-6 text-red-600' />
                                <div className='flex flex-col gap-y-2'>
                                    <span className='font-semibold'>VetRec requires a microphone.</span> Please connect one. If one is connected, please try ensure it is enabled and refresh the page.
                                </div>
                            </div>
                        }
                        {recordingAllowed === PermissionStatus.GRANTED && selectedMicrophone && <div className='flex flex-col gap-y-8 justify-center items-center w-full px-2 sm:px-0'>
                            {multipet && (
                                <div className='inline-flex flex-col sm:flex-row items-center gap-y-2 sm:gap-x-2 bg-blue-50 border border-blue-200 rounded-md px-2 sm:px-4 py-3 shadow-sm mx-2 sm:mx-4 max-w-[calc(100%-1rem)] sm:max-w-none'>
                                    <div className='flex items-center text-blue-600 gap-x-1.5'>
                                        <div className='flex items-center'>
                                            <FontAwesomeIcon icon={faPaw} className="h-3.5 w-3.5" />
                                            <span className="font-semibold ml-0.5 text-xs">+</span>
                                        </div>
                                        <span className="text-xs font-medium">Multi-pet</span>
                                    </div>
                                    <p className='text-xs text-gray-600 text-center sm:text-left'>
                                        For optimal results, clearly state each patient's name while recording.
                                    </p>
                                </div>
                            )}
                            <button data-tour-id="record-button" type="button" className={`rounded-full ${recordingState === RecordingStatus.RECORDING ? "bg-red-600 hover:bg-red-500": (recordingState === RecordingStatus.UPLOADING || recordingState === RecordingStatus.FAILED_UPLOAD || recordingState === RecordingStatus.PREPARING || recordingState === RecordingStatus.FAILED_NOT_FOUND) ? "bg-gray-400" : "bg-main-button hover:bg-main-button-hover"} px-4 py-2.5 text-md font-semibold text-main-button-text shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-64 w-64 flex flex-col justify-center justify-items-center items-center gap-y-4`} onClick={() => handleRecord()} disabled={buttonClick || recordingState === RecordingStatus.FAILED_UPLOAD || recordingState === RecordingStatus.PREPARING || recordingState === RecordingStatus.FAILED_NOT_FOUND} >
                                {/*Recording button text*/}
                                {recordingState === RecordingStatus.PREPARING && <span className='text-white text-xl font-semibold'>Preparing to Record</span>}
                                {recordingState === RecordingStatus.NOTSTARTED && <span className='text-white text-xl font-semibold'>Start Recording</span>}
                                {recordingState === RecordingStatus.FAILED && <span className='text-white text-xl font-semibold'>Start Recording</span>}
                                {recordingState === RecordingStatus.FAILED_NAME && <span className='text-white text-xl font-semibold'>Start Recording</span>}
                                {recordingState === RecordingStatus.RECORDING && <span className='text-white text-xl font-semibold'>Pause Recording</span>}
                                {recordingState === RecordingStatus.STOPPED && <span className='text-white text-xl font-semibold'>Resume Recording</span>}
                                {(recordingState === RecordingStatus.UPLOADING || recordingState === RecordingStatus.REUPLOADING) && <span className='text-white text-xl font-semibold'>Uploading recording</span>}
                                {recordingState === RecordingStatus.FAILED_NOT_FOUND && <span className='text-white text-xl font-semibold'>Visit not found</span>}
                                {recordingState === RecordingStatus.FAILED_UPLOAD && <span className='text-white text-xl font-semibold'>Upload Failed</span>}
                                {/*Timer*/}
                                {seconds > 0 && (recordingState === RecordingStatus.RECORDING || recordingState === RecordingStatus.STOPPED ) && <span className='text-white text-lg font-semibold'>{formatTime(seconds)}</span>}
                                {/*Recording button icon*/}
                                {(recordingState === RecordingStatus.UPLOADING || recordingState === RecordingStatus.REUPLOADING) && <div className='text-white text-xl font-semibold'>
                                    {uploadPercentage} %
                                </div>}
                                {recordingState === RecordingStatus.PREPARING && <Spinner size='h-10 w-10' timer={false}/>}
                                {recordingState === RecordingStatus.NOTSTARTED && <MicrophoneIcon className='h-10'/>}
                                {recordingState === RecordingStatus.FAILED && <MicrophoneIcon className='h-10'/>}
                                {recordingState === RecordingStatus.FAILED_NAME && <MicrophoneIcon className='h-10'/>}
                                {recordingState === RecordingStatus.RECORDING && <StopCircleIcon className='h-10'/>}
                                {recordingState === RecordingStatus.STOPPED && <MicrophoneIcon className='h-10'/>}
                            </button>
                            {recordingState === RecordingStatus.RECORDING && mediaStream && <div className='-mt-[16rem] z-[-1] mb-8'>
                                <MicrophoneWave width={isMobile ? '350' : '500'} mediaStream={mediaStream} canWeHearYou={canWeHearYou} setCanWeHearYou={setCanWeHearYou}/>
                            </div>}
                            <MicrophonePicker raiseZIndex={isTourRunning && currentStep === ScribeBasicTourIdentifiers.SELECTING_MICROPHONE_ON_SCRIBE} dataTourId='microphone-picker'/>
                            {canWeHearYou && <div className='flex flex-row gap-x-4 -m-4'>
                                <CheckBadgeIcon className='h-6 w-6 text-green-600' />
                                We can hear you!
                            </div>}
                            {!canWeHearYou && recordingState === RecordingStatus.RECORDING && <div className='flex flex-row gap-x-4 -m-4'>
                                <ExclamationTriangleIcon className='h-6 w-6 text-red-600' />
                                We can't hear you.
                            </div>}
                            <div className='flex flex-col shrink-0 grow justify-end gap-x-8 gap-y-4 items-center max-w-full'>
                                {(recordingState !== RecordingStatus.FAILED_UPLOAD) && <button
                                    data-tour-id="process-button"
                                    ref={startProcessingRef}
                                    type="button"
                                    className={`inline-flex items-center justify-center gap-x-2 rounded-md ${((anyRecording && recordingState === RecordingStatus.STOPPED)) ? "bg-main-button hover:bg-main-button-hover text-main-button-text" : "bg-main-button-disabled text-main-button-disabled-text"} px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12 w-60 justify-center`}
                                    onClick={() => processContext()}
                                    disabled={!(anyRecording && recordingState === RecordingStatus.STOPPED) || buttonClick }
                                >
                                    {anyRecording && recordingState === RecordingStatus.STOPPED && !processingContext && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Generate notes
                                        <ArrowRightCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                    </div>}
                                    {processingContext && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Processing...
                                        <Spinner size='h-5 w-5' timer={false}/>
                                    </div>}
                                    {(recordingState === RecordingStatus.UPLOADING || recordingState === RecordingStatus.REUPLOADING) && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Uploading...
                                        <Spinner size='h-5 w-5' timer={false}/>
                                    </div>}
                                    { recordingState === RecordingStatus.RECORDING && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Recording...
                                        <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                    </div>}
                                    {recordingState === RecordingStatus.NOTSTARTED && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Start recording!
                                    </div>}
                                    {recordingState === RecordingStatus.PREPARING && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Preparing to record...
                                    </div>}
                                    {(recordingState === RecordingStatus.FAILED_NAME || recordingState === RecordingStatus.FAILED) && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Start recording!
                                    </div>}
                                    {recordingState ===  RecordingStatus.FAILED_NOT_FOUND && <div className='flex flex-row justify-center items-center gap-x-2'>
                                        Visit not found
                                    </div>}
                                </button>}
                                {(recordingState === RecordingStatus.FAILED_UPLOAD) && <button
                                    ref={startProcessingRef}
                                    type="button"
                                    className={`inline-flex items-center gap-x-2 rounded-md bg-main-button hover:bg-main-button-hover text-main-button-text px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12 w-60 justify-center`}
                                    onClick={() => handleReupload()}
                                    disabled={reuploadClick}
                                >
                                    <div className='flex flex-row justify-center items-center gap-x-2'>
                                        {!reuploadClick && <ExclamationTriangleIcon className='h-5 w-5 text-white-600'/>}
                                        {reuploadClick && <Spinner size='h-5 w-5' timer={false}/>}
                                        <div className='flex flex-col'>
                                        Error uploading recording. <span className='font-normal text-sm'>Click to try again.</span>
                                        </div>
                                    </div>
                                </button>}
                                {[RecordingStatus.UPLOADING, RecordingStatus.REUPLOADING, RecordingStatus.FAILED_UPLOAD, RecordingStatus.FAILED_NOT_FOUND].includes(recordingState) && <button 
                                    type="button" 
                                    className={`inline-flex items-center gap-x-2 rounded-md bg-white hover:bg-gray-100 text-gray-900 px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800 ring-1 ring-inset ring-gray-300 h-12 w-60 justify-center`}
                                    onClick={downloadRecording}    
                                >
                                    <ArrowDownTrayIcon className="h-5 w-5" />
                                    Download Recording
                                </button>}
                                {[RecordingStatus.FAILED_UPLOAD, RecordingStatus.FAILED_NOT_FOUND].includes(recordingState) && <span className="text-red-600 font-semibold text-sm -mt-3">
                                    We we are having issues uploading your recording. Download a local copy to safeguard your recording!
                                </span>}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className='flex flex-col-reverse sm:flex-row justify-between items-center w-full pt-8 pb-8 sm:pr-20 gap-y-8 gap-x-10'>
                <div className='text-xs text-center sm:text-start w-full truncate shrink overflow-hidden whitespace-nowrap min-w-20'>
                    <span className='font-semibold'>Visit ID:</span> {sessionId}
                </div>
            </div>
        </div>
        {error && <ErrorAlert error={error} setError={setError}/>}
        {remainder && <Reminder show={remainder ?  true: false} title={remainder} text="" hide={() => setRemainder(undefined)} />}
        {consentPending && !consent && <ConsentDialog />}
    </>)
}
