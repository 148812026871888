import React from 'react';
import { UserRole } from '../../../settings/UserRoles';
import MultiSelectListbox from '../listbox/MultiSelectListbox';
import SingleSelectListbox from '../listbox/SingleSelectListbox';
import { capitalize } from 'lodash';

interface SingleUserFormSectionProps {
    formData: {
        email: string;
        teams: string[];
        role: UserRole;
    };
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleTeamChange: (selected: string[]) => void;
    handleRoleChange: (selected: string) => void;
    handleSubmit: (e: React.FormEvent) => void;
    teams: Array<{ id: string; name: string }>;
    isSubmitting: boolean;
    onCancel?: () => void;
}

const SingleUserFormSection: React.FC<SingleUserFormSectionProps> = ({
    formData,
    handleChange,
    handleTeamChange,
    handleRoleChange,
    handleSubmit,
    teams,
    isSubmitting,
    onCancel
}) => {
    const roleOptions = Object.values(UserRole).map((role) => ({
        id: role,
        name: capitalize(role.replace("org:", "").replace(/_/g, ' '))
    }));

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email *
                </label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
                    required
                />
            </div>

            <div className="mb-4">
                <label htmlFor="teams" className="block text-sm font-medium text-gray-700 mb-2">
                    Teams *
                </label>
                <MultiSelectListbox
                    options={teams}
                    selected={formData.teams}
                    onChange={handleTeamChange}
                    placeholder="Select teams"
                    className="mt-1"
                />
                <p className="text-xs text-gray-500 mt-1">Select one or more teams</p>
            </div>

            <div className="mb-4">
                <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-2">
                    Role *
                </label>
                <SingleSelectListbox
                    options={roleOptions}
                    selected={formData.role}
                    onChange={handleRoleChange}
                    placeholder="Select a role"
                    className="mt-1"
                />
            </div>

            <div className="flex justify-end space-x-3">
                {onCancel && (
                    <button
                        type="button"
                        onClick={onCancel}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                        Cancel
                    </button>
                )}
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''
                        }`}
                >
                    {isSubmitting ? 'Inviting...' : 'Invite User'}
                </button>
            </div>
        </form>
    );
};

export default SingleUserFormSection;
