import { useUser, useOrganization, useAuth } from "@clerk/clerk-react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useState, useEffect } from "react";
import { notifyAccountDeletion } from "../../ServerActions";

export const DeleteWarningPage = () => {
  const { user, isLoaded: userLoaded } = useUser();
  const { organization, isLoaded: orgLoaded } = useOrganization();
  const { getToken } = useAuth();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [confirmText, setConfirmText] = useState("");
  const [hasSubscription, setHasSubscription] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const REQUIRED_TEXT = "Delete account";
  
  const isConfirmTextValid = confirmText === REQUIRED_TEXT;

  useEffect(() => {
    if (userLoaded && orgLoaded) {
      // Check if user has a tier in metadata, similar to how OrganizationControls accesses metadata
      const userTier = user?.publicMetadata?.tier as string | undefined || 
                      user?.unsafeMetadata?.tier as string | undefined;
      
      // Check if organization has a tier in metadata
      const orgTier = organization?.publicMetadata?.tier as string | undefined;
      
      // User has a subscription if either user or org has a tier
      setHasSubscription(!!(userTier || orgTier));
    }
  }, [userLoaded, orgLoaded, user, organization]);

  const handleNotifyAccountDeletion = async () => {
    if (!user) return;
    
    try {
      await notifyAccountDeletion(feedbackText, await getToken({ template: "supabase" }) ?? "");
    } catch (err) {
      // Log error but continue with account deletion
      console.error('Error in handleNotifyAccountDeletion:', err);
    }
  };

  const handleContinueToDelete = async () => {
    if (!user || !isConfirmTextValid) return;
    
    try {
      setIsDeleting(true);
      setError(null);
      
      // Send notification about account deletion for all users
      await handleNotifyAccountDeletion();
      
      // Use the Clerk SDK to delete the user directly
      await user.delete();
      
      // Note: After successful deletion, Clerk will automatically sign the user out
      // and redirect to the sign-in page, so we don't need additional navigation
    } catch (err) {
      setIsDeleting(false);
      setError("There was an error deleting your account. Please try again or contact support.");
      console.error("Error deleting account:", err);
    }
  };

  return (
    <div className="flex flex-col space-y-6">
      <div className={`${hasSubscription ? 'bg-amber-50 border-amber-400' : 'bg-gray-50 border-gray-400'} border-l-4 p-4 rounded-md`}>
        <div className="flex items-start">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className={`h-6 w-6 ${hasSubscription ? 'text-amber-400' : 'text-gray-400'}`} aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className={`text-sm font-medium ${hasSubscription ? 'text-amber-800' : 'text-gray-800'}`}>
              Important Notice About Your Account
            </h3>
            <div className={`mt-2 text-sm ${hasSubscription ? 'text-amber-700' : 'text-gray-700'}`}>
              {hasSubscription ? (
                // For users with a subscription
                <>
                  <p className="mb-2">
                    <strong>Deleting your account will NOT cancel your subscription.</strong>
                  </p>
                  <p className="mb-2">
                    If you want to cancel your subscription, please:
                  </p>
                  <ol className="list-decimal ml-5 mb-2 space-y-1">
                    <li>Contact our support team at <a href="mailto:support@vetrec.io" className="font-medium text-amber-800 underline hover:text-amber-600">support@vetrec.io</a></li>
                    <li>Contact us via the chat bubble on the bottom right of the page</li>
                  </ol>
                  <p className="mb-2">
                    If you delete your account without canceling your subscription, <strong>you will continue to be billed according to your subscription terms.</strong>
                  </p>
                </>
              ) : (
                // For users without a subscription
                <>
                  <p className="mb-2">
                    <strong>This action cannot be undone.</strong> Are you sure you want to delete your account?
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-md">
          <div className="flex">
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      {!showConfirmation ? (
        <div className="flex justify-end">
          <button
            onClick={() => setShowConfirmation(true)}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            I still want to delete my account
          </button>
        </div>
      ) : (
        <div className="mt-6 bg-gray-50 border border-gray-200 rounded-md p-6 shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Delete Account Confirmation</h3>
          
          <div className="mb-6">
            <p className="text-gray-700 mb-2">
              This action is permanent and cannot be undone.
            </p>
            <p className="text-gray-700 font-semibold">
              To confirm, please type "{REQUIRED_TEXT}" below:
            </p>
          </div>
          
          <div className="mb-6">
            <input aria-label="Confirmation input for account deletion"
              type="text"
              value={confirmText}
              onChange={(e) => setConfirmText(e.target.value)}
              placeholder={`Type "${REQUIRED_TEXT}" to confirm`}
              className={`w-full p-3 border ${
                confirmText && !isConfirmTextValid 
                  ? 'border-red-500 bg-red-50' 
                  : isConfirmTextValid 
                    ? 'border-green-500 bg-green-50'
                    : 'border-gray-300'
              } rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent`}
              disabled={isDeleting}
            />
            {confirmText && !isConfirmTextValid && (
              <p className="mt-2 text-sm text-red-600">
                Please type exactly "{REQUIRED_TEXT}" to proceed
              </p>
            )}
            {isConfirmTextValid && (
              <p className="mt-2 text-sm text-green-600">
                You can now click on the "Delete Account" button to delete your account.
              </p>
            )}
          </div>

          {/* Feedback input field */}
          <div className="mb-6">
            <label htmlFor="feedback" className="block text-sm font-medium text-gray-700 mb-2">
              Would you like to provide feedback on why you're deleting your account? (optional)
            </label>
            <textarea
              id="feedback"
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              placeholder="Please tell us why you're deleting your account"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
              rows={3}
              disabled={isDeleting}
            />
          </div>
          
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => {
                setShowConfirmation(false);
                setConfirmText("");
              }}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              disabled={isDeleting}
            >
              Cancel
            </button>
            <button
              onClick={handleContinueToDelete}
              className={`px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 flex items-center justify-center min-w-[120px] ${
                isConfirmTextValid && !isDeleting
                  ? 'bg-red-600 text-white hover:bg-red-700'
                  : 'bg-red-300 text-white cursor-not-allowed'
              }`}
              disabled={!isConfirmTextValid || isDeleting}
            >
              {isDeleting ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Deleting...
                </>
              ) : (
                "Delete Account"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}; 