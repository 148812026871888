import { CheckIcon, PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Team } from "../types/user";

interface TeamRowProps {
  team: Team;
  onEdit: (newName: string) => void;
  onDelete: () => void;
}

const TeamRow: React.FC<TeamRowProps> = ({ team, onEdit, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(team.name);

  const handleSave = () => {
    if (editName.trim()) {
      onEdit(editName);
      setIsEditing(false);
    }
  };

  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {isEditing ? (
          <input
            type="text"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            autoFocus
          />
        ) : (
          team.name
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {team.memberCount || 0}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex gap-x-2 justify-end">
        {isEditing ? (
        <div className="flex space-x-2 justify-end">
          <button
            type="button"
            onClick={handleSave}
            className="p-2 rounded-md bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <CheckIcon className="h-4 w-4 text-white" aria-hidden="true" />
            <span className="sr-only">Save</span>
          </button>
          <button
            type="button"
            onClick={() => {
              setIsEditing(false);
              setEditName(team.name);
            }}
            className="p-2 rounded-md bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <XMarkIcon className="h-4 w-4 text-white" aria-hidden="true" />
            <span className="sr-only">Cancel</span>
          </button>
        </div>
        ) : (
        <div className="flex space-x-2 justify-end">
          <button
            type="button"
            onClick={() => setIsEditing(true)}
            className="p-2 rounded-md bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <PencilIcon className="h-4 w-4 text-white" aria-hidden="true" />
            <span className="sr-only">Edit</span>
          </button>
        </div>
        )}
        {!isEditing &&<div className="flex space-x-2 justify-end">
          <button
            type="button"
            onClick={() => onDelete()}
            className="p-2 rounded-md bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <TrashIcon className="h-4 w-4 text-white" aria-hidden="true" />
            <span className="sr-only">Delete</span>
          </button>
        </div>}
      </td>
    </tr>
  );
};

export default TeamRow;