import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'

/**
 * Generic modal component for confirming deletion actions
 */
export interface ConfirmDeleteModalProps {
  isOpen: boolean;
  title: string;
  message: React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ConfirmDeleteModal({ 
  isOpen, 
  title, 
  message,
  confirmButtonText = "Delete", 
  cancelButtonText = "Cancel", 
  onConfirm, 
  onCancel 
}: ConfirmDeleteModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-[9999]">
      <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl">
        <div className="flex items-center mb-4 text-red-600">
          <ExclamationTriangleIcon className="h-6 w-6 mr-2" />
          <h3 className="text-lg font-medium">{title}</h3>
        </div>
        
        <div className="mb-6 text-gray-700">
          {message}
        </div>
        
        <div className="flex justify-end gap-3">
          <button
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100"
            onClick={onCancel}
          >
            {cancelButtonText}
          </button>
          <button
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </button>
        </div>
      </div>
    </div>
  );
} 