import { useEffect, useRef, useState, type FC } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { ContentType, EntryType, TemplatePermissions } from './TemplateUtils'
import ContentTypePicker from './contentTypePicker'
import { useTemplate } from './TemplateProvider'
import { ChevronUpIcon, WrenchIcon, XCircleIcon } from '@heroicons/react/24/solid'
import InputTooltip from './InputTooltip'
import { Disclosure, Switch } from '@headlessui/react'
import { improveInstructions } from '../../ServerActions'
import { useAuth } from '@clerk/clerk-react'
import { InstructionsInput } from './InstructionsInput'


interface EntryProps {
  sectionKey: string
  parentEntryKey?: string
  entryKey: string
  name: string | undefined
  emptyName?: boolean | false
  description: string | undefined
  defaultValue: string | undefined
  type: ContentType
  hardcodedValue?: string | undefined
  emptyHardcodedValue?: boolean | false
  subEntries?: EntryType[]
  dataTourId?: string
  raiseZIndex?: boolean
  ignoreEntryTypeUpdate?: boolean
  expandInstructions?: boolean
  expandDefaults?: boolean
}

export const AddEntryArea: FC = () => {
  const { handleSubEntryOver } = useTemplate()
  const ref = useRef<HTMLDivElement>(null)

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION, ItemTypes.RENDERED_SECTION, ItemTypes.SECTION, ItemTypes.TIMELINE, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.PHYSICAL_EXAM, ItemTypes.SUBJECTIVE, ItemTypes.VITALS, ItemTypes.FEAR_FREE],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item: any, monitor) {
      handleSubEntryOver(undefined)
    }
  }))

  drop(ref)

  return (
    <div ref={ref} className='border border-dashed border-gray-300 rounded-md p-4 text-center'>
      Add Entry to section
    </div>
  )
}

export const Entry: FC<EntryProps> = ({ sectionKey, entryKey, name, emptyName, description, defaultValue, type, hardcodedValue, emptyHardcodedValue, subEntries, parentEntryKey, dataTourId, raiseZIndex = false, ignoreEntryTypeUpdate = false, expandInstructions = false, expandDefaults = false }) => {
  const { deleteEntry, updateEntryDefaultValue, updateEntryDescription, updateEntryHardcodedValue, updateEntryEmptyHardcodedValue, updateEntryName, updateEntryEmptyName, updateEntryType, swapEntries, swapSubEntries, handleEntryOver, handleSectionOver, entryHoverIndex, sectionHoverIndex, handleSubSectionOver, handleSubEntryOver, subSectionHover, subEntryHoverIndex, entryDragged, hasPermission, templateType } = useTemplate()
  const ref = useRef<HTMLDivElement>(null)
  const typeRef = useRef<ContentType>(type)
  const [isImproveModalOpen, setIsImproveModalOpen] = useState(false)
  const { getToken } = useAuth();
  const [isImproving, setIsImproving] = useState(false)
  const [advancedModeVisible, setAdvancedModeVisible] = useState(false)

  useEffect(() => {
    if (!!description) {
      setAdvancedModeVisible(true)
    }
  }, [description])

  const handleEntryTypeUpdate = ignoreEntryTypeUpdate ? () => { } : updateEntryType

  useEffect(() => {
    typeRef.current = type
  }, [type])

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.RENDERED_ENTRY, ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item: any, monitor) {
      if (!ref.current) {
        return
      }
      if (item['entryKey'] === entryKey) {
        return
      }
      if ([ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION].includes(item['name'].toLowerCase().replace(" ", "_"))) {
        // Code to execute if condition is true
        handleSectionOver(sectionKey)
        if (parentEntryKey) {
          handleEntryOver(parentEntryKey)
          handleSubEntryOver(entryKey)
        }
        else {
          if (typeRef.current === ContentType.SUBSECTION) {
            handleSubSectionOver(true)
          }
          else {
            handleSubSectionOver(false)
          }
          handleEntryOver(entryKey)
        }
      }
      if (item['entryKey'] && item['name'] === ItemTypes.RENDERED_ENTRY && parentEntryKey === undefined) {
        swapEntries(sectionKey, entryKey, item['entryKey'])
      }
      else if (item['entryKey'] && item['name'] === ItemTypes.RENDERED_ENTRY && parentEntryKey !== undefined) {
        swapSubEntries(sectionKey, parentEntryKey, entryKey, item['entryKey'])
      }
    }
  }))

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RENDERED_ENTRY,
    item: () => {
      return { entryKey, "name": ItemTypes.RENDERED_ENTRY }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  if (hasPermission(TemplatePermissions.EDIT_ORDER)) drag(drop(ref))

  const handleImprove = async (additionalContext: string) => {
    setIsImproving(true)
    setIsImproveModalOpen(false)

    try {
      const response = await improveInstructions(name ?? "", type ?? "", description ?? "", additionalContext, await getToken({ template: "supabase" }) ?? "", templateType ?? "", defaultValue ?? "")
      updateEntryDescription(sectionKey, entryKey, response.improved_instructions, parentEntryKey)
    } finally {
      setIsImproving(false)
    }
  }

  return (
    <>
      {sectionHoverIndex === sectionKey && entryHoverIndex === entryKey && !subSectionHover && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
      {sectionHoverIndex === sectionKey && entryHoverIndex === parentEntryKey && subEntryHoverIndex === entryKey && subSectionHover && <div className='w-full h-1 bg-blue-600 rounded-md' />}
      <div ref={ref} className={`w-full cursor-move ${isDragging ? "border-2 border-blue-600 rounded-md" : ""}`} data-testid="section">
        <div data-tour-id={dataTourId} className={`flex flex-col gap-y-2 border border-gray-200 px-4 py-2 rounded-md ${isDragging ? "opacity-0" : "opacity-1"}`}>
          <div className='flex flex-row items-center justify-between'>
            <div data-tour-id={`${dataTourId}-type-edit`} className='flex flex-row gap-x-2 items-center'>
              <input className={`${hasPermission(TemplatePermissions.EDIT_ENTRY_NAMES) ? " text-gray-900" : "text-gray-400"} nodrag border-t-0 border-l-0 border-r-0 focus:rounded-md h-8 ${emptyName ? "bg-gray-200 rounded-sm" : ""} transition-colors duration-200 ease-in-out`} placeholder={emptyName ? "" : "Entry Name"} value={emptyName ? '' : name} onChange={(event) => updateEntryName(sectionKey, entryKey, event.target.value, parentEntryKey)} draggable={true} onDragStart={event => event.preventDefault()} disabled={!hasPermission(TemplatePermissions.EDIT_ENTRY_NAMES)} />
              <ContentTypePicker buttonDataTourId={`${dataTourId}-type-edit-button`} raiseZIndex={raiseZIndex} contentType={type} setContentType={(type) => handleEntryTypeUpdate(sectionKey, entryKey, type, parentEntryKey)} subsection={parentEntryKey ? false : true} disabled={!hasPermission(TemplatePermissions.EDIT_INPUT_TYPE)} />
              {type === ContentType.HARDCODED && (
                <div className="flex items-center gap-x-2 ml-4">
                  <span className="text-sm text-gray-600">Empty name</span>
                  <Switch
                    checked={emptyName}
                    onChange={(checked) => {
                      updateEntryEmptyName(sectionKey, entryKey, checked, parentEntryKey)
                      updateEntryName(sectionKey, entryKey, checked ? `${sectionKey}_vetrec_empty` : "", parentEntryKey)
                    }}
                    className={`${emptyName ? 'bg-blue-600' : 'bg-gray-200'
                      } relative inline-flex h-4 w-8 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                    disabled={!hasPermission(TemplatePermissions.EDIT_ENTRY_NAMES)}
                  >
                    <span className="sr-only">Empty Name</span>
                    <span
                      className={`${emptyName ? 'translate-x-4' : 'translate-x-1'
                        } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                </div>
              )}
            </div>
            {hasPermission(TemplatePermissions.EDIT_ORDER) && !advancedModeVisible && <div className='flex flex-row gap-x-2 items-center'>
              {type === ContentType.SUBSECTION && (
                <button
                  type="button"
                  className={`inline-flex items-center gap-x-1 text-sm ${advancedModeVisible ? 'text-blue-600 bg-blue-100' : 'text-gray-600 hover:text-blue-600 hover:bg-blue-100'} transition-colors mr-2 py-2 px-3 rounded-md`}
                  onClick={() => {
                    setAdvancedModeVisible(!advancedModeVisible)
                  }}
                >
                  <WrenchIcon className="h-4 w-4" />
                  Advanced
                </button>
              )}
            </div>}
            {hasPermission(TemplatePermissions.EDIT_ORDER) && <div data-tour-id={`${dataTourId}-delete`} className="cursor-pointer" onClick={() => deleteEntry(sectionKey, entryKey, parentEntryKey)}>
              <XCircleIcon className='w-5 h-5 text-blue-600 hover:text-blue-500' />
            </div>}
          </div>
          {[ContentType.LIST, ContentType.NUMBER, ContentType.PARAGRAPH].includes(type) && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              <div className="flex-1">
                <InstructionsInput
                  sectionEntryName={name ?? ""}
                  value={description ?? ''}
                  defaultValue={defaultValue ?? ''}
                  onChange={(value) => updateEntryDescription(sectionKey, entryKey, value, parentEntryKey)}
                  onImprove={handleImprove}
                  isImproving={isImproving}
                  disabled={!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS)}
                  placeholder='(Optional) Provide instructions to VetRec. e.g. Include dosage'
                  dataTourId={`${dataTourId}-instructions-input`}
                  expandInstructions={expandInstructions}
                  className={hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS) ? "text-gray-900" : "text-gray-400"}
                />
              </div>
            </div>
            <div className='flex flex-row gap-x-2 items-center justify-start'>
              Default:
              <InputTooltip direction="right" content={<div>
                <div className='font-semibold'>Default values</div>
                <ul className='list-disc list-inside'>
                  <li>Used when information for the entry is not provided in the session.</li>
                  <li>Format like you expect the information to be presented</li>
                </ul>
              </div>}>
                <textarea data-tour-id={`${dataTourId}-defaults-input`} className={`${hasPermission(TemplatePermissions.EDIT_DEFAULTS) ? " text-gray-900" : "text-gray-400"} nodrag rounded-md border-gray-300 w-full resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar ${expandDefaults ? "h-32" : ""}`} placeholder='(Optional) Provide default values like normals' value={defaultValue} onChange={(event) => updateEntryDefaultValue(sectionKey, entryKey, event.target.value, parentEntryKey)} draggable={true} onDragStart={event => event.preventDefault()} disabled={!hasPermission(TemplatePermissions.EDIT_DEFAULTS)} />
              </InputTooltip>
            </div>
          </div>}
          {advancedModeVisible && type === ContentType.SUBSECTION && <div className='w-full flex flex-row gap-x-2 items-center justify-start mb-2'>
            <div className="flex-1">
              <InstructionsInput
                sectionEntryName={name ?? ""}
                value={description ?? ''}
                defaultValue={defaultValue ?? ''}
                onChange={(value) => updateEntryDescription(sectionKey, entryKey, value, parentEntryKey)}
                onImprove={handleImprove}
                isImproving={isImproving}
                disabled={!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS)}
                placeholder='(Optional) Provide general instructions for this subsection to VetRec. e.g. Write in full sentences.'
                expandInstructions={expandInstructions}
                className={hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS) ? "text-gray-900" : "text-gray-400"}
                onDelete={() => {
                  updateEntryDescription(sectionKey, entryKey, "", parentEntryKey)
                  setAdvancedModeVisible(false)
                }}
              />
            </div>
          </div>}
          {[ContentType.SUBSECTION].includes(type) && <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                  <span>Sub-entries</span>
                  <ChevronUpIcon
                    className={`${open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-blue-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-0 pb-2 pt-0 text-sm text-gray-500 flex flex-col gap-y-2">
                  {subEntries && subEntries.map((entry) => (
                    <Entry key={entry.entryKey} name={entry.name} emptyName={emptyName} description={entry.description} defaultValue={entry.defaultValue} entryKey={entry.entryKey} type={entry.type} sectionKey={sectionKey} parentEntryKey={entryKey} hardcodedValue={entry.hardcodedValue} />
                  ))}
                  {sectionHoverIndex === sectionKey && entryHoverIndex === entryKey && subEntryHoverIndex === undefined && entryDragged && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
                  {subEntries?.length !== 0 && sectionHoverIndex === sectionKey && entryHoverIndex === entryKey && subSectionHover && <AddEntryArea />}
                  {subEntries?.length === 0 && <div className='flex flex-col items-center justify-center h-full mt-4'>
                    <div className='flex flex-col items-center gap-y-2'>
                      <div className='text-gray-500 text-md'>Drag and drop an entry</div>
                      <div className='text-gray-500 text-sm'>
                        Choose an empty entry or one of the pre-built options.
                      </div>
                    </div>
                  </div>
                  }
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>}
          {type === ContentType.HARDCODED && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              Value:
              <InputTooltip direction="right" content={<div className='flex flex-col'>
                <div className=''>This value will be added verbatim into the final set of notes.</div>
              </div>}>
                <textarea className={`${hasPermission(TemplatePermissions.EDIT_DEFAULTS) ? " text-gray-900" : "text-gray-400"} ${emptyHardcodedValue ? "bg-gray-200" : ""} nodrag rounded-md border-gray-300 w-full resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar`} placeholder={!emptyHardcodedValue ? 'Value will be inserted into the final notes verbatim.' : ''} value={hardcodedValue} onChange={(event) => updateEntryHardcodedValue(sectionKey, entryKey, event.target.value, parentEntryKey)} draggable={true} onDragStart={event => event.preventDefault()} disabled={!hasPermission(TemplatePermissions.EDIT_DEFAULTS) || emptyHardcodedValue} />
              </InputTooltip>
              <div className='flex items-center gap-x-2 ml-2'>
                <span className="text-sm text-gray-600 whitespace-nowrap">Empty Value</span>
                <Switch
                  checked={emptyHardcodedValue}
                  onChange={(checked) => {
                    updateEntryEmptyHardcodedValue(sectionKey, entryKey, checked, parentEntryKey)
                    if (checked) {
                      updateEntryHardcodedValue(sectionKey, entryKey, " ", parentEntryKey)
                    }
                  }}
                  className={`${emptyHardcodedValue ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-4 w-8 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                  disabled={!hasPermission(TemplatePermissions.EDIT_DEFAULTS)}
                >
                  <span className="sr-only">Empty Value</span>
                  <span
                    className={`${emptyHardcodedValue ? 'translate-x-4' : 'translate-x-1'
                      } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}
