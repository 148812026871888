import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { MegaphoneIcon, TrashIcon } from '@heroicons/react/24/solid'
import { useAuth } from '@clerk/clerk-react'
import { handleThumbsFeedbackServerSide } from '../../ServerActions'
import { useHistory } from '../../providers/HistoryProvider'

export default function FeedbackModal() {
    const [input, setInput] = useState<string>()
    const { getToken} = useAuth();
    const {feedbackModal, feedbackModalSource, highlightedText, setHighlightedText, activeSession, notifyCompleted, setFeedbackModal} = useHistory()
    const [submitting, setSubmitting] = useState(false)
    const [dataAccessConsent, setDataAccessConsent] = useState<boolean | undefined>(undefined)

    const shouldShowConsentOption = useMemo(() => feedbackModalSource.toLowerCase() === "notes", [feedbackModalSource])

    useEffect(() => {if (shouldShowConsentOption) setDataAccessConsent(false)}, [feedbackModalSource])

    function handleInputChange(value:string){
        setInput(value)
    }

    async function handleSubmitFeedback(){
        try{
            setSubmitting(true)
            await handleThumbsFeedbackServerSide(activeSession?.id ?? "", false, input ?? "N/A", feedbackModalSource, highlightedText, dataAccessConsent, await getToken({template:"supabase"}) ?? "")
            setSubmitting(false)
            notifyCompleted("Thank you for the feedback", "Feedback was shared with team!")
            onClose()
        }
        catch(error){
            setSubmitting(false)
        }
    }

    function onClose() {
        setFeedbackModal(false)
        setInput("")
        setHighlightedText(undefined)
        setDataAccessConsent(undefined)
    }

    return (
        <Transition.Root show={feedbackModal} as={Fragment}>
        <Dialog as="div" className="relative z-[999999]" onClose={onClose}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                        <MegaphoneIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Submit feedback
                        </Dialog.Title>
                        <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Feedback is crucial for VetRec to improve. Thank you for sharing your feedback!
                        </p>
                        </div>
                    </div>
                    </div>
                    {highlightedText && (
                        <div className="mt-4 p-2 bg-gray-100 rounded-md flex justify-between items-center">
                            <p className="text-sm text-gray-700">
                                <strong>Highlighted Text:</strong> {highlightedText.length > 250 ? `${highlightedText.slice(0, 125)} ... ${highlightedText.slice(-125)}` : highlightedText}
                            </p>
                            <button
                                type="button"
                                className="ml-2 text-gray-500 hover:text-gray-700"
                                onClick={() => setHighlightedText(undefined)}
                            >
                                <TrashIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    )}
                    <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 mt-4"
                        defaultValue={''}
                        onChange={(event) => {handleInputChange(event.target.value)}}
                    />
                    {shouldShowConsentOption && <div className={`mt-3 flex flex-row gap-x-4 items-center justify-between border border-gray-300 rounded-md p-4`}>
                        <label htmlFor="consent" className={`mr-2 text-sm text-gray-700`}>
                            {dataAccessConsent ? 
                                "VetRec can listen to the recording to troubleshoot" : 
                                "Can VetRec listen to the recording to troubleshoot?"}
                        </label>
                        <Switch
                            checked={dataAccessConsent}
                            onChange={setDataAccessConsent}
                            className={`${
                                dataAccessConsent ? 'bg-green-500' : 'bg-gray-500'
                            } relative inline-flex h-6 w-11 items-center rounded-full flex-shrink-0`}
                        >
                            <span
                                className={`${
                                dataAccessConsent ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </Switch>
                    </div>}
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-4"
                        onClick={() => handleSubmitFeedback()}
                        disabled={submitting}
                    >
                        Submit
                    </button>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
}
