import type { UserResource } from '@clerk/types';
import { TemplateType } from '../components/templates/TemplateUtils';

export interface MemberObject{
    name: string | undefined;
    identifier: string
    clerk_id?: string
    get_default_template?: (templateType: TemplateType | undefined) => Promise<string | undefined>
}

export function sortMembersList(memberList: MemberObject[] | undefined) {
    memberList?.sort((a, b) => {
        // Use name and then identifier (email). Ignore Dr. 
        const a_sort_id = a.name?.replace(/^Dr\.?\s*/, '') ?? a.identifier;
        const b_sort_id = b.name?.replace(/^Dr\.?\s*/, '') ?? b.identifier;

        // Move undefined to the end
        if (a_sort_id === undefined) return 1; 
        if (b_sort_id === undefined) return -1;
        
        return a_sort_id.localeCompare(b_sort_id);
    });
}

export function getUserFlag(user: UserResource | null | undefined, flag: string, default_value: boolean = false): boolean {
    const user_flags = user?.publicMetadata["flags"] as Record<string, boolean>
    return user_flags ? (user_flags[flag] ?? default_value) : default_value
}

export const ALL_DOCTORS = "All doctors"

export const allDoctors: MemberObject = {name: ALL_DOCTORS, identifier: ALL_DOCTORS}
