import {
  ChatBubbleBottomCenterTextIcon,
  CheckCircleIcon,
  CloudArrowUpIcon,
  MicrophoneIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
  PlusIcon
} from '@heroicons/react/24/solid'
import { useState, useEffect, useMemo, useCallback } from 'react'
import Datepicker, { type DateValueType } from "react-tailwindcss-datepicker"
import { convertTimestampToDate } from './utils/time'
import { useNavigate } from 'react-router-dom'
import UserPicker from './components/UserPicker'
import { useHistory } from './providers/HistoryProvider'
import Activity from './components/history/Activity'
import MoreMenu from './components/moreMenu'
import { useVetRec } from './providers/VetRecProvider'
import ConfirmDeleteModal from './components/ConfirmDeleteModal'

export default function PetOverview() {
  const [sessionNameEditor, setSessionNameEditor] = useState<boolean>(false)
  const [sessionDateEditor, setSessionDateEditor] = useState<boolean>(false)
  const [changeOwner, setChangeOwner] = useState<boolean>(false)
  const [editingPatients, setEditingPatients] = useState<boolean>(false)
  const [newPatientName, setNewPatientName] = useState<string>('')
  const [editingPetName, setEditingPetName] = useState<{index: number, name: string} | null>(null)
  const [duplicateNameError, setDuplicateNameError] = useState<string | null>(null)
  const [petDeleteConfirmModal, setPetDeleteConfirmModal] = useState<string | null>(null)

  // Keep track of the original pets array for rename logic
  const [originalPets, setOriginalPets] = useState<string[]>([])
  // Track a map of oldName -> newName when a rename occurs
  const [renameMap, setRenameMap] = useState<Record<string, string>>({})

  // Local copy of the pets array so we don't immediately push changes to the server.
  const [tempPets, setTempPets] = useState<string[]>([])

  const [showAddPatientInput, setShowAddPatientInput] = useState<boolean>(false)

  const navigate = useNavigate()
  const {
    handleSaveName,
    handleSaveDate,
    handleSavePatients,
    activeSession,
    setActiveSession,
    handleMarkComplete,
    membersList,
    handleSaveOwner,
    setShowChatBot,
    setDeleteVisitModal,
    setUploadRecordingModal,
    multiPetSessionActive
  } = useHistory()
  const { davidEnabled, multipetEnabled } = useVetRec()

  const sessionDateValue = useMemo(() => ({
    startDate: new Date((activeSession?.created_timestamp ?? 0) * 1000),
    endDate: new Date((activeSession?.created_timestamp ?? 0) * 1000),
  }), [activeSession?.created_timestamp])

  // Sync local pets & originalPets whenever activeSession updates
  useEffect(() => {
    setTempPets(activeSession?.pets || [])
    setOriginalPets(activeSession?.pets || [])
    setRenameMap({})
  }, [activeSession?.pets])

  // Memoized mapping check, to see which pet names are in SOAP or discharge nodes
  const petsOnMappings = useMemo(() => {
    if (!activeSession?.pet_to_soap_node_mapping && !activeSession?.pet_to_discharge_node_mapping) {
      return []
    }
    const soapMappings = Object.keys(activeSession.pet_to_soap_node_mapping || {})
    const dischargeMappings = Object.keys(activeSession.pet_to_discharge_node_mapping || {})

    return Array.from(new Set([...soapMappings, ...dischargeMappings]))
  }, [activeSession?.pet_to_soap_node_mapping, activeSession?.pet_to_discharge_node_mapping])

  /**
   * Called once the user hits the final check mark to "save" the local
   * tempPets changes to the server.
   */
  const handleUpdatePatients = () => {
    if (!activeSession) return

    // Create a local copy of pets to work with throughout this function
    let updatedPets = [...tempPets]
    let updatedRenameMap = {...renameMap}

    // If there's an in-progress edit that hasn't been confirmed yet, apply it first
    if (editingPetName !== null) {
      const trimmedName = editingPetName.name.trim()
      if (trimmedName) {
        // Check for duplicates, ignoring the current index
        const isDuplicate = updatedPets.some(
          (pet, i) => i !== editingPetName.index && pet.toLowerCase() === trimmedName.toLowerCase()
        )
        
        if (isDuplicate) {
          setDuplicateNameError(trimmedName)
          return
        }
        
        const oldName = updatedPets[editingPetName.index]
        updatedPets[editingPetName.index] = trimmedName
        
        // Update the rename map if needed
        if (
          oldName.toLowerCase() !== trimmedName.toLowerCase() &&
          originalPets.includes(oldName)
        ) {
          updatedRenameMap = {
            ...updatedRenameMap,
            [oldName]: trimmedName
          }
        }
      }
    }

    // If there's still something in the "new patient" input, try adding it locally
    const trimmedNewName = newPatientName.trim()
    if (trimmedNewName) {
      if (updatedPets.some((pet) => pet.toLowerCase() === trimmedNewName.toLowerCase())) {
        setDuplicateNameError(trimmedNewName)
        return
      }
      updatedPets.push(trimmedNewName)
    }

    // Figure out what changed
    const toAdd = updatedPets.filter((pet) => !originalPets.includes(pet))
    const toRemove = originalPets.filter((pet) => !updatedPets.includes(pet))

    // Build final renames from updatedRenameMap, ensuring old/new are valid
    const finalRenames: Record<string, string> = {}
    Object.entries(updatedRenameMap).forEach(([oldP, newP]) => {
      if (
        originalPets.includes(oldP) &&
        updatedPets.includes(newP) &&
        oldP.toLowerCase() !== newP.toLowerCase()
      ) {
        finalRenames[oldP] = newP
      }
    })

    // Optional payload for partial updates
    const petUpdates = {
      update: finalRenames,
      add: toAdd,
      remove: toRemove
    }

    // Update state and save to server
    setTempPets(updatedPets)
    setRenameMap(updatedRenameMap)
    handleSavePatients(updatedPets, petUpdates)

    // Clear local state
    setNewPatientName('')
    setEditingPatients(false)
    setEditingPetName(null)
    setDuplicateNameError(null)
  }

  /**
   * Add a new patient *locally* only (does not call the server).
   */
  const handleAddPatientLocally = () => {
    const trimmedName = newPatientName.trim()
    if (!trimmedName) return

    if (tempPets.length >= 10) return
    if (tempPets.some((pet) => pet.toLowerCase() === trimmedName.toLowerCase())) {
      setDuplicateNameError(trimmedName)
      return
    }

    setTempPets([...tempPets, trimmedName])
    setNewPatientName('')
    setDuplicateNameError(null)
  }

  /**
   * Remove a patient *locally* only (does not call the server).
   */
  const handleRemovePatientLocally = (petName: string) => {
    // Don't allow removing if it's the last one
    if (tempPets.length <= 1) return

    // If the pet has SOAP or discharge nodes, show a modal
    if (petsOnMappings.includes(petName)) {
      setPetDeleteConfirmModal(petName)
      return
    }

    // Otherwise delete immediately
    const updatedPets = tempPets.filter((pet) => pet !== petName)
    setTempPets(updatedPets)
  }

  // Confirm deletion from the modal
  const confirmPetDeletion = (petName: string) => {
    const updatedPets = tempPets.filter((pet) => pet !== petName)
    setTempPets(updatedPets)
    setPetDeleteConfirmModal(null)
  }

  /**
   * Update a pet name *locally* only (does not call the server).
   */
  const handleUpdatePetNameLocally = (index: number) => {
    if (!editingPetName) return

    const trimmedName = editingPetName.name.trim()
    if (!trimmedName) return

    // Check duplicates, ignoring the current pet at 'index'
    const isDuplicate = tempPets.some(
      (pet, i) => i !== index && pet.toLowerCase() === trimmedName.toLowerCase()
    )
    if (isDuplicate) {
      setDuplicateNameError(trimmedName)
      return
    }

    const updatedPets = [...tempPets]
    const oldName = updatedPets[index]
    updatedPets[index] = trimmedName

    setTempPets(updatedPets)

    // If the old name was actually different and was in the original set, track the rename
    if (
      oldName.toLowerCase() !== trimmedName.toLowerCase() &&
      originalPets.includes(oldName)
    ) {
      setRenameMap((prev) => ({
        ...prev,
        [oldName]: trimmedName
      }))
    }

    setEditingPetName(null)
    setDuplicateNameError(null)
  }

  const enableSessionDateEditor = useCallback(() => setSessionDateEditor(true), []);

  const onSessionDateChange = useCallback((value: DateValueType) => {
    const { startDate } = value || {};
    if (!startDate) return;
    const asDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
    if (activeSession && activeSession.id) {
      setActiveSession({
        ...activeSession,
        created_timestamp: asDate.getTime() / 1000,
      })
    }
  }, [activeSession, setActiveSession]);

  const onSessionDateChangeComplete = useCallback(() => {
    setSessionDateEditor(false);
    handleSaveDate();
  }, [handleSaveDate]);

  return (
    <>
      <div className="bg-white shadow rounded-lg border border-gray-300">
        <div className="px-4 py-6 sm:px-6 lg:items-center">
          <div className="flex xl:flex-row flex-col justify-between gap-x-4 gap-y-4 w-full items-center">
            {/* Session name editor */}
            <div className="flex flex-col gap-y-1 items-center xl:items-start">
              <div className="mt-1 text-lg font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row justify-center xl:justify-between gap-x-2 min-w-52">
                <div className="min-w-52 flex flex-row gap-x-4 xl:justify-start justify-center">
                  {!sessionNameEditor && (activeSession && activeSession.name !== '' ? activeSession.name : 'Not specified')}
                  {!sessionNameEditor && (
                    <div
                      className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                      onClick={() => setSessionNameEditor(true)}
                    >
                      <PencilIcon className="h-4 w-4" />
                    </div>
                  )}
                  {sessionNameEditor && (
                    <div className="flex flex-row items-center justify-center gap-x-3">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-40 sm:w-full sm:h-12 h-10 rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="Mr.Wiggles"
                        value={activeSession?.name ?? 'Not specified'}
                        onChange={(event) => {
                          if (activeSession && activeSession.id) {
                            setActiveSession({
                              ...activeSession,
                              name: event.target.value
                            })
                          }
                        }}
                      />
                      <div
                        className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                        onClick={() => {
                          setSessionNameEditor(false)
                          handleSaveName()
                        }}
                      >
                        <CheckCircleIcon className="h-6 w-6" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="min-w-52 flex flex-row gap-x-4 xl:justify-start justify-center items-center">
                {sessionDateEditor ? <>
                  <Datepicker asSingle useRange={false} value={sessionDateValue} onChange={onSessionDateChange} />
                  <div
                    className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                    onClick={onSessionDateChangeComplete}
                  >
                    <CheckCircleIcon className="h-6 w-6" />
                  </div>
                </> : <>
                  <div className="text-sm text-gray-700">
                    {convertTimestampToDate(activeSession?.created_timestamp ?? 0)}
                  </div>
                  <div
                    className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                    onClick={enableSessionDateEditor}
                  >
                    <PencilIcon className="h-4 w-4" />
                  </div>
                </>}
              </div>
            </div>

            {/* Top-right buttons */}
            <div className="flex flex-row gap-x-2 gap-y-4 w-full justify-center sm:justify-end">
              {/* Mark completed */}
              <button
                type="button"
                title={
                  activeSession?.status?.general === 'Completed'
                    ? 'This session is completed'
                    : 'Mark this session as completed'
                }
                className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-xs sm:text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 sm:min-w-fit ${
                  activeSession?.status?.general === 'Completed'
                    ? 'bg-green-600 hover:bg-green-500 font-semibold text-white'
                    : 'bg-blue-600 text-white hover:bg-main-button-hover'
                }`}
                onClick={() => handleMarkComplete()}
              >
                <CheckCircleIcon className="-mr-0.5 h-5 w-5 block xl:hidden" aria-hidden="true" />
                <span className="hidden xl:block">
                  {activeSession?.status?.general === 'Completed'
                    ? 'Completed'
                    : 'Mark completed'}
                </span>
              </button>

              {/* D.A.V.I.D */}
              {davidEnabled && (
                <div className="relative group">
                  <button
                    disabled={multiPetSessionActive}
                    type="button"
                    className={`inline-flex items-center justify-center gap-x-2 rounded-md px-2 py-2.5 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 text-sm grow w-full sm:max-w-52 ${
                      multiPetSessionActive
                        ? 'bg-main-button/50 text-main-button-text/50 cursor-not-allowed hover:bg-main-button/50'
                        : 'bg-main-button text-main-button-text hover:bg-main-button-hover'
                    }`}
                    onClick={() => setShowChatBot(true)}
                  >
                    <ChatBubbleBottomCenterTextIcon
                      className="h-5 w-5 text-main-button-text"
                      aria-hidden="true"
                    />
                    <span className="hidden sm:block">D.A.V.I.D</span>
                  </button>
                  {multiPetSessionActive && (
                    <div className="absolute top-full left-1/2 -translate-x-1/2 mt-2 hidden group-hover:block w-max z-10">
                      <div className="bg-gray-900 text-white text-xs rounded py-1 px-2">
                        D.A.V.I.D for multi-pet sessions coming soon!
                      </div>
                      <div className="w-2 h-2 bg-gray-900 transform rotate-45 absolute left-1/2 -translate-x-1/2 -top-1" />
                    </div>
                  )}
                </div>
              )}

              {/* Add to visit */}
              <button
                type="button"
                title="Record additional information for this session which will be added to the notes."
                className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 overflow-hidden sm:max-w-48 sm:min-w-fit w-full justify-center h-10 sm:h-12 truncate"
                onClick={() => navigate('/scribe?session=' + activeSession?.id)}
              >
                <MicrophoneIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                <span className="hidden sm:block">Add to visit</span>
              </button>

              {/* Delete session */}
              <button
                className="bg-red-500 px-3.5 py-2.5 rounded-md font-bold text-white text-xl h-10 sm:h-12 justify-center items-center flex"
                onClick={() => {
                  setDeleteVisitModal(true)
                }}
              >
                <TrashIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              </button>

              {/* More menu */}
              <MoreMenu
                moreText="Actions"
                options={[
                  {
                    name: 'Upload Recording',
                    action: () => setUploadRecordingModal(true),
                    icon: <CloudArrowUpIcon className="h-5 w-5" aria-hidden="true" />
                  }
                ]}
                height="h-10 sm:h-12 border-0"
              />
            </div>
          </div>
        </div>

        {/* Main body info */}
        {activeSession && (
          <div className="border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              {/* Staff Owner */}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-900">Staff</dt>
                <dd className="mt-2 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row gap-x-2">
                  {!changeOwner && (
                    <div className="flex flex-row gap-x-2 items-center">
                      <div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {membersList.find(
                          (u) => activeSession && u.identifier === activeSession.display_owner
                        )?.name ??
                          activeSession.display_owner ??
                          membersList.find(
                            (u) => activeSession && u.identifier === activeSession.owner
                          )?.name ??
                          activeSession.owner}
                      </div>
                      <div
                        className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                        onClick={() => setChangeOwner(true)}
                      >
                        <PencilIcon className="h-4 w-4" />
                      </div>
                    </div>
                  )}
                  {changeOwner && (
                    <div className="flex flex-row gap-x-2 items-center w-">
                      <UserPicker
                        userList={membersList}
                        userSearch={
                          membersList.find(
                            (u) => activeSession && u.identifier === activeSession.display_owner
                          ) ??
                          membersList.find(
                            (u) => activeSession && u.identifier === activeSession.owner
                          )
                        }
                        setUserSearch={(value) => {
                          if (activeSession && activeSession.id) {
                            setActiveSession({
                              ...activeSession,
                              display_owner: value?.identifier ?? ''
                            })
                          }
                        }}
                        className="h-10"
                        text="Doctor / Staff"
                      />
                      <div
                        className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                        onClick={() => {
                          setChangeOwner(false)
                          handleSaveOwner()
                        }}
                      >
                        <CheckCircleIcon className="h-6 w-6" />
                      </div>
                    </div>
                  )}
                </dd>
              </div>

              {/* Patients */}
              {multipetEnabled && tempPets.length > 0 && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Patients</dt>
                  <dd className="mt-2 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {!editingPatients ? (
                      <div className="flex flex-row gap-x-2 items-center">
                        <div className="flex flex-wrap gap-2">
                          {tempPets.map((pet, index) => (
                            <div
                              key={index}
                              className="inline-flex items-center bg-blue-50 rounded-full px-3 py-1 text-sm font-medium text-gray-900"
                            >
                              {pet}
                            </div>
                          ))}
                        </div>
                        <div
                          className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                          onClick={() => setEditingPatients(true)}
                        >
                          <PencilIcon className="h-4 w-4" />
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-y-2">
                        {/* The list of existing patients, now editable locally */}
                        <div className="flex flex-wrap gap-2">
                          {tempPets.map((pet, index) => (
                            <div
                              key={index}
                              className="flex items-center gap-x-1 bg-blue-50 px-2 py-1 rounded-md"
                            >
                              {editingPetName?.index === index ? (
                                <div className="flex flex-col gap-y-1">
                                  <div className="flex items-center gap-x-1">
                                    <input
                                      type="text"
                                      value={editingPetName.name}
                                      onChange={(e) => {
                                        setEditingPetName({ index, name: e.target.value })
                                        setDuplicateNameError(null)
                                      }}
                                      className={`block w-24 rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ${
                                        duplicateNameError === editingPetName.name.trim()
                                          ? 'ring-red-500 focus:ring-red-500'
                                          : 'ring-gray-300 focus:ring-blue-600'
                                      } placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          handleUpdatePetNameLocally(index)
                                        }
                                      }}
                                    />
                                    <button
                                      onClick={() => handleUpdatePetNameLocally(index)}
                                      className="text-gray-500 hover:text-gray-700"
                                    >
                                      <CheckCircleIcon className="h-4 w-4" />
                                    </button>
                                  </div>
                                  {duplicateNameError === editingPetName.name.trim() && (
                                    <div className="text-xs text-red-500">Name already exists</div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <span
                                    onClick={() => setEditingPetName({ index, name: pet })}
                                    className={ 'cursor-pointer hover:text-blue-600'}
                                  >
                                    {pet}
                                  </span>
                                  {tempPets.length > 1 && (
                                    <button
                                      onClick={() => handleRemovePatientLocally(pet)}
                                      className="text-gray-500 hover:text-gray-700"
                                      title="Remove patient"
                                    >
                                      <XMarkIcon className="h-4 w-4" />
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                          {/* Add new patient input */}
                          {tempPets.length <= 10 && (
                            <div className="flex items-center gap-x-2">
                              {!showAddPatientInput ? (
                                <button
                                  onClick={() => setShowAddPatientInput(true)}
                                  className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-50 px-3 py-1.5 text-sm font-medium text-blue-700 hover:bg-blue-100 transition-colors"
                                >
                                  <PlusIcon className="h-4 w-4" />
                                  Add another patient
                                </button>
                              ) : (
                                <div className="flex items-center gap-x-2">
                                  <div className="flex flex-col gap-y-1">
                                    <div className="relative">
                                      <input
                                        type="text"
                                        value={newPatientName}
                                        onChange={(e) => {
                                          setNewPatientName(e.target.value)
                                          setDuplicateNameError(null)
                                        }}
                                        placeholder="Enter patient name"
                                        className={`block w-40 rounded-md border-0 py-1.5 pr-16 text-gray-900 shadow-sm ring-1 ring-inset ${
                                          duplicateNameError === newPatientName.trim()
                                            ? 'ring-red-500 focus:ring-red-500'
                                            : 'ring-gray-300 focus:ring-blue-600'
                                        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            handleAddPatientLocally()
                                            if (!duplicateNameError) setShowAddPatientInput(false)
                                          } else if (e.key === 'Escape') {
                                            setShowAddPatientInput(false)
                                            setNewPatientName('')
                                            setDuplicateNameError(null)
                                          }
                                        }}
                                        autoFocus
                                      />
                                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 gap-x-1">
                                        <button
                                          onClick={() => {
                                            handleAddPatientLocally()
                                            setShowAddPatientInput(false)
                                          }}
                                          className="text-gray-500 hover:text-gray-700 transition-colors"
                                          title="Add patient"
                                        >
                                          <CheckCircleIcon className="h-4 w-4" />
                                        </button>
                                        <button
                                          onClick={() => {
                                            setShowAddPatientInput(false)
                                            setNewPatientName('')
                                            setDuplicateNameError(null)
                                          }}
                                          className="text-gray-500 hover:text-gray-700 transition-colors"
                                          title="Cancel"
                                        >
                                          <XMarkIcon className="h-4 w-4" />
                                        </button>
                                      </div>
                                    </div>
                                    {duplicateNameError === newPatientName.trim() && (
                                      <div className="text-xs text-red-500">Name already exists</div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {/* Final check mark to confirm changes */}
                              <div
                                className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                                onClick={handleUpdatePatients}
                              >
                                <CheckCircleIcon className="h-6 w-6" />
                              </div>
                            </div>
                          )}
                        </div>

                        {/* If we reached max patients */}
                        {tempPets.length === 10 && (
                          <p className="text-xs text-gray-500">
                            Maximum number of patients (10) reached
                          </p>
                        )}
                      </div>
                    )}
                  </dd>
                </div>
              )}

              {/* Summary */}
              {activeSession.summary && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">Summary</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {activeSession.summary}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        )}
        <div className="border-b border-gray-400 sm:mx-6 mx-4 py-4 text-md">Activity logs</div>
        <Activity />
      </div>
      <ConfirmDeleteModal
        isOpen={petDeleteConfirmModal !== null}
        title="Confirm Patient Deletion"
        message={
          <div>
            Are you sure you want to delete the patient <strong>{petDeleteConfirmModal}</strong>?
            <br />
            This will <strong>delete all notes</strong> associated with this patient.
          </div>
        }
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        onConfirm={() => petDeleteConfirmModal && confirmPetDeletion(petDeleteConfirmModal)}
        onCancel={() => setPetDeleteConfirmModal(null)}
      />
    </>
  )
}
