import { useRef, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { SparklesIcon, ArrowUturnLeftIcon, ArrowPathIcon, TrashIcon } from '@heroicons/react/24/solid'
import { ImproveInstructionsModal } from './ImproveInstructionsModal'
import InputTooltip from './InputTooltip'

interface InstructionsInputProps {
  sectionEntryName: string
  value: string
  defaultValue: string
  onChange: (value: string) => void
  onImprove?: (input: string) => Promise<void> 
  isImproving?: boolean
  disabled?: boolean
  placeholder?: string
  dataTourId?: string
  expandInstructions?: boolean
  className?: string
  title?: string
  onDelete?: () => void
}

export const InstructionsInput: React.FC<InstructionsInputProps> = ({
  sectionEntryName,
  value,
  defaultValue,
  onChange,
  onImprove,
  isImproving,
  disabled = false,
  placeholder,
  dataTourId,
  expandInstructions = false,
  className = '',
  title = 'Instructions',
  onDelete,
}) => {
  const [isImproveModalOpen, setIsImproveModalOpen] = useState(false)
  const [improveInput, setImproveInput] = useState('')
  const [history, setHistory] = useState<string[]>([])
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [isFocused, setIsFocused] = useState(false)

  const instructionsRef = useRef<HTMLTextAreaElement>(null)
  const lastValueRef = useRef(value)

  const sectionEntryContextIsEmpty = sectionEntryName.trim() === "" && value.trim() === "" && defaultValue.trim() === ""
  const improveInputIsEmpty = improveInput.trim() === ""
  const shouldDisableImproveSubmit = isImproveModalOpen && sectionEntryContextIsEmpty && improveInputIsEmpty

  useEffect(() => {
    if (value && history.length === 0) {
      setHistory([value])
      setCurrentIndex(0)
    }
  }, [])

  useEffect(() => {
    if (value !== lastValueRef.current && !isImproving) {
      lastValueRef.current = value
      setHistory(prev => [...prev.slice(0, currentIndex + 1), value])
      setCurrentIndex(prev => prev + 1)
    }
  }, [value, isImproving])

  const handleChange = (newValue: string) => {
    onChange(newValue)
    lastValueRef.current = newValue
    setHistory(prev => [...prev.slice(0, currentIndex + 1), newValue])
    setCurrentIndex(prev => prev + 1)
  }

  const handleUndo = () => {
    if (currentIndex > 0) {
      const previousValue = history[currentIndex - 1]
      onChange(previousValue)
      lastValueRef.current = previousValue
      setCurrentIndex(prev => prev - 1)
    }
  }

  const handleImprove = async (input: string) => {
    if (onImprove) await onImprove(input)
    setIsImproveModalOpen(false)
  }

  const canUndo = currentIndex > 0

  return (
    <div className="flex flex-col gap-y-1">
      {onImprove && <div className="flex justify-end gap-x-2 h-6">
        <button
          type="button"
          className={`p-1 rounded-md transition-colors duration-200 ${
            canUndo && !disabled
              ? 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
              : 'text-gray-300 cursor-not-allowed'
          }`}
          onClick={handleUndo}
          disabled={!canUndo || disabled}
          title="Undo"
        >
          <ArrowUturnLeftIcon className="h-4 w-4" />
        </button>
        <button
          type="button"
          className={`p-1 rounded-md transition-colors duration-200 ${
            isImproving || disabled || shouldDisableImproveSubmit
              ? 'text-gray-300 cursor-not-allowed'
              : (isFocused || isImproveModalOpen
                  ? 'text-blue-500 hover:text-blue-700 hover:bg-gray-100'
                  : 'text-gray-500 hover:text-blue-600 hover:bg-gray-100')
          } ${isImproveModalOpen && !isImproving ? 'ring-1 ring-blue-300' : ''} flex items-center`}
          onClick={() => {
            if (isImproveModalOpen && !isImproving) {
              handleImprove(improveInput)
            }
            if (!isImproving) {
              setIsImproveModalOpen(true)
            }
          }}
          title={
            isImproving
              ? "Improving instructions..."
              : isImproveModalOpen
              ? "Click again to improve instructions"
              : "Improve instructions"
          }
          disabled={disabled || isImproving || shouldDisableImproveSubmit}
        >
          {isImproving ? (
            <ArrowPathIcon className="h-4 w-4 animate-spin" />
          ) : (
            <>
              <SparklesIcon className="h-4 w-4" />
              <Transition
                show={isFocused || isImproveModalOpen}
                enter="transition transform ease-in-out duration-500"
                enterFrom="translate-x-4 opacity-0"
                enterTo="translate-x-0 opacity-100"
                leave="transition transform ease-in-out duration-300"
                leaveFrom="translate-x-0 opacity-100"
                leaveTo="translate-x-4 opacity-0"
              >
                <span className="ml-1 text-sm text-blue-600">
                  {sectionEntryContextIsEmpty ? "Create instructions with AI" : "Improve instructions with AI"}
                </span>
              </Transition>
            </>
          )}
        </button>
        {onDelete && <button
          type="button"
          className="text-gray-500 hover:text-red-500 transition-colors duration-200 ease-in-out"
          onClick={onDelete}
          title="Delete section instructions"
        >
          <TrashIcon className="h-4 w-4" />
        </button>}
      </div>}

      <div className="relative">
        <div className='flex flex-row gap-x-2 items-center'>
          <span>{title}:</span>
          <InputTooltip
            direction="right"
            content={
              <div className="flex flex-col">
                <div className="font-semibold">Recommendations</div>
                <ul className="list-disc list-inside">
                  <li>The simpler, the better</li>
                  <li>Be specific. Tell the system what exactly you want.</li>
                  <li>
                    Avoid negative language. (e.g. Do not include). Use positive language. (e.g. Include)
                  </li>
                  <li>Keep conditional statements to a minimum.</li>
                  <li>
                    Add examples for correct behavior. (e.g. Format medications as...) Avoid wrong behavior examples. (e.g. Do not format as)
                  </li>
                  <li>Use this format for examples: (e.g. Example for the entry)</li>
                  <li>Be consistent with names used and format.</li>
                </ul>
              </div>
            }
          >
            <textarea
              ref={instructionsRef}
              data-tour-id={dataTourId}
              className={`
                nodrag rounded-md border-gray-300 w-full resize-none h-10 focus:h-32 
                transition-all duration-300 ease-in-out thin-scrollbar 
                ${expandInstructions || isImproving ? 'h-32' : ''}
                ${isImproving ? 'animate-pulse bg-blue-50' : ''}
                ${className}
              `}
              placeholder={placeholder}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              draggable={true}
              onDragStart={(e) => e.preventDefault()}
              disabled={disabled || isImproving}
            />
          </InputTooltip>
        </div>

        <ImproveInstructionsModal
          isOpen={isImproveModalOpen}
          onClose={() => setIsImproveModalOpen(false)}
          onImprove={handleImprove}
          input={improveInput}
          onInputChange={setImproveInput}
          isLoading={isImproving}
          disableSubmit={shouldDisableImproveSubmit}
          contextIsEmpty={sectionEntryContextIsEmpty}
        />
      </div>
    </div>
  )
}