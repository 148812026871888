/**
 * Enum representing the context for the chatbot.
 * It can be either 'notes' or 'recap'.
 */
// enum for chatbot context: notes, recap
export enum ChatbotContext {
    Notes = 'notes',
    Recap = 'records_recap',
    None = 'no_context'
}

