import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { MainTemplate } from './components/templates/MainTemplate'
import { TemplateProvider } from './components/templates/TemplateProvider'
import { isMobileDevice } from './utils/deviceUtils'
import { VideoCameraIcon } from '@heroicons/react/24/solid'

export default function TemplateBuilder() {

  const isMobile = isMobileDevice()

  return (
    <>
      <main>
        <div className="border-b border-gray-400 pb-5 mb-5 flex flex-col justify-between gap-y-2 py-4 sm:pt-10">
            <div className='flex flex-row justify-between items-center'>
                <div>
                    <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                        Template Builder
                    </h2>
                    <p className="mt-2 text-sm text-gray-500">
                        Customize your notes to your style including sections, titles, default values and more. Provide custom instructions for VetRec to know how to best organize your notes.
                    </p>
                </div>
                <div data-tour-id="template-builder-tutorial-button" className='flex gap-x-4'>
                    <a
                        href='https://www.youtube.com/watch?v=U47wB8VJc7s'
                        className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center min-w-36 sm:min-w-48"
                        target='_blank'
                        data-tour-id="templates-tutorial-video" rel="noreferrer"
                    >
                        <VideoCameraIcon className="-mr-0.5 sm:h-5 sm:w-5 h-8 w-8" aria-hidden="true" />
                        Watch Tutorial
                    </a>
                </div>
            </div>
        </div>
        {isMobile && 
          <div className='bg-accent w-full shadow-lg rounded-lg py-8 px-8 flex flex-col sm:flex-row justify-between items-center gap-x-8 overflow-hidden gap-y-8 max-h-72 sm:max-h-60'>
            <div className='flex flex-col items-center justify-between gap-y-6 h-full grow w-full sm:w-2/3'>
                <div className='text-center w-full sm:w-[600px]'>
                  <span className='font-semibold'>The VetRec Template Builder is not yet available on mobile devices.</span><br/><br/>To configure templates, please visit VetRec of a computer or contact us at <a className="underline" href="mailto:support@vetrec.io">support@vetrec.io</a>.
                </div>
            </div>
        </div>}
        {!isMobile && 
        <TemplateProvider>
          <DndProvider backend={HTML5Backend}>
            <div className="max-w-full">
              <div className="gap-8 max-w-full lg:max-w-none">
                <div className="-mx-4 pb-8 pt-4 sm:mx-0 rounded-lg h-[75vh] sm:h-[75vh] lg:h-[85vh] w-full">
                  <MainTemplate />
                </div>
              </div>
            </div>
          </DndProvider>
        </TemplateProvider>}
      </main>
    </>
  )
}
