import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

export enum BannerType {
    Success = 'success',
    Info = 'info',
    Error = 'error',
}

export const getBannerColor = (type: BannerType): string => {
    switch (type) {
        case BannerType.Success:
            return 'green';
        case BannerType.Info:
            return 'blue';
        case BannerType.Error:
            return 'red';
        default:
            return 'gray';
    }
}

export const getBannerIcon = (type: BannerType): React.ElementType => {
    switch (type) {
        case BannerType.Success:
            return CheckCircleIcon;
        case BannerType.Info:
            return InformationCircleIcon;
        case BannerType.Error:
            return ExclamationCircleIcon;
        default:
            return QuestionMarkCircleIcon;
    }
}

interface BannerProps {
    bannerType: BannerType;
    message: string;
}

const Banner: React.FC<BannerProps> = ({ bannerType, message }: BannerProps) => {
    const Icon = getBannerIcon(bannerType);
    const color = getBannerColor(bannerType);

    return (
        <div className={`bg-${color}-50 border-l-4 border-${color}-400 p-4 rounded-lg flex items-center space-x-2 mt-4 gap-x-4 text-sm text-gray-700`}>
            <Icon className='h-6 w-6 text-gray-500' />
            {message}
        </div>
    );
};

export default Banner;