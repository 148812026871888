import { TourConfig } from "./types";
import { ScribeBasicTourConfig } from "./scribeBasic/config";
import { TOUR_IDS, TourId } from "./constants";
import { TemplateEditorTourConfig } from "./templateEditor/config";
import { TemplatesOverviewTourConfig } from "./templates/config";

const tourRegistry: Record<TourId, TourConfig> = {
  [TOUR_IDS.SCRIBE_BASIC]: ScribeBasicTourConfig,
  [TOUR_IDS.TEMPLATES_OVERVIEW]: TemplatesOverviewTourConfig,
  [TOUR_IDS.TEMPLATE_EDITOR]: TemplateEditorTourConfig,
};

export const getTourConfig = (tourId: TourId): TourConfig => {
  const config = tourRegistry[tourId];
  if (!config) {
    throw new Error(`Tour configuration not found for ID: ${tourId}`);
  }
  return config;
};