import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback } from 'react';
import { Tag, Team, User, UserStatus } from '../components/admin/types/user';
import { UserRole } from '../components/settings/UserRoles';
import { useAuth } from '@clerk/clerk-react';
import { add_existing_teams, bulk_update_tag_teams, bulk_update_users_tag, bulk_update_users_teams, change_users_role, create_tag, create_team, delete_tag, delete_team, get_tags, get_teams, get_users, invite_users, update_org_name, update_super_admins, update_tag_name, UserInvite } from '../serverActions/admin';
import { AlertType } from '../components/admin/utils/adminUtils';

// Define the shape of the admin context state
interface AdminContextState {
    teams: Team[];
    tags: Tag[];
    users: User[];
    loading: boolean;
    alertBanner: boolean;
    alertTitle: string;
    alertMessage: string;
    alertType: AlertType;
    hideAlertBanner: () => void;
    inviteUsers: (invites: UserInvite[]) => Promise<void>;
    createTeam: (team_name: string) => Promise<void>;
    addTeam: (teams: string[]) => Promise<void>;
    updateTeam: (team_id: string, team_name: string) => Promise<void>;
    deleteTeam: (team: Team) => Promise<void>;
    createTag: (tag_name: string, tag_teams: string[]) => Promise<void>;
    updateTag: (tag: Tag, group_name: string) => Promise<void>;
    deleteTag: (tag: Tag) => Promise<void>;
    bulkUpdateUsersTeams: (users: User[], teamsToAdd: string[], teamsToRemove: string[]) => Promise<void>;
    bulkUpdateUsersTag: (users: User[], tagsToAdd: Tag) => Promise<void>;
    bulkUpdateTagTeams: (tag: Tag, teamsToAdd: string[], teamsToRemove: string[]) => Promise<void>;
    updateUserRole: (users: User[], newRole: UserRole) => Promise<void>;
    updateSuperAdmins: (admins: string[]) => Promise<void>;
    handleAlertAction: (state: boolean, title: string, message: string, type: AlertType) => void;
}

// Create the context with a default value
const AdminContext = createContext<AdminContextState | undefined>(undefined);

// Props for the AdminProvider component
interface AdminProviderProps {
    children: ReactNode;
}

export const AdminProvider: React.FC<AdminProviderProps> = ({ children }) => {
    const { getToken } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [alertBanner, setAlertBanner] = useState<boolean>(false);
    const [alertTitle, setAlertTitle] = useState<string>("");
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertType, setAlertType] = useState<AlertType>(AlertType.Info);
    const [teams, setTeams] = useState<Team[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [token, setToken] = useState<string>();

    const handleAlertAction = (state: boolean, title: string, message: string, type: AlertType) => {
        setAlertBanner(state);
        setAlertTitle(title);
        setAlertMessage(message);
        setAlertType(type);
    }

    const hideAlertBanner = () => {
        setAlertBanner(false);
        setAlertTitle("");
        setAlertMessage("");
        setAlertType(AlertType.Info);
    }

    const inviteUsers = async (invites: UserInvite[]) => {
        try {
            handleAlertAction(true, "Info", "Inviting users...", AlertType.Info);
            // Check if user has already been invited to the team or has an active account that is part of the team
            // Remove from invites list if so or modify invite to only include teams that are not already part of the user's teams

            invites = invites.filter((invite) => {
                const user = users.find((user) => user.email === invite.email);
                if (user) {
                    invite.teams = invite.teams.filter((team) => !user.teams.includes(team));
                }
                return invite.teams.length > 0;
            });

            await invite_users(invites, token ?? "");
            await fetchData(["users", "teams"]);
            handleAlertAction(true, "Success", "Users invited successfully.", AlertType.Success);
        } catch (e) {
            handleAlertAction(true, "Error", "There was an error inviting the users.", AlertType.Error);
            console.error(e);
        }
    }

    const createTeam = async (team_name: string) => {
        try {
            handleAlertAction(true, "Info", "Creating team...", AlertType.Info);
            await create_team(team_name, token ?? "");
            await fetchData(["teams"]);
            handleAlertAction(true, "Success", "Team created successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error creating the team.", AlertType.Error);
            console.error(e);
        }
    }

    const addTeam = async (teams: string[]) => {
        try {
            handleAlertAction(true, "Info", "Adding team...", AlertType.Info);
            await add_existing_teams(teams, token ?? "");
            await fetchData(["teams", "users"]);
            handleAlertAction(true, "Success", "Team added successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error adding the team.", AlertType.Error);
            console.error(e);
        }
    }

    const updateTeam = async (team_id: string, team_name: string) => {
        try {
            handleAlertAction(true, "Info", "Updating team...", AlertType.Info);
            await update_org_name({
                organization_id: team_id,
                name: team_name
            }, token ?? "");
            await fetchData(["teams"]);
            handleAlertAction(true, "Success", "Team updated successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error updating the team.", AlertType.Error);
            console.error(e);
        }
    }

    const bulkUpdateUsersTeams = async (usersToUpdate: User[], teamsToAdd: string[], teamsToRemove: string[]) => {
        try {
            handleAlertAction(true, "Info", "Updating user's teams...", AlertType.Info);

            const request = {
                users: usersToUpdate.map(
                    (user) => {
                        return {
                            user_id: user.id,
                            role: user.role,
                            teams: user.teams
                        }
                    }
                ),
                teams_to_add: teamsToAdd,
                teams_to_remove: teamsToRemove
            }
            await bulk_update_users_teams(request, token ?? "");
            await fetchData(["users", "teams"]);
            handleAlertAction(true, "Success", "Users updated successfully.", AlertType.Success);
        } catch (e) {
            handleAlertAction(true, "Error", "There was an error updating users.", AlertType.Error);
            console.log(e);
        }
    }

    const deleteTeam = async (team: Team) => {
        try {
            handleAlertAction(true, "Info", "Deleting team...", AlertType.Info);
            if(team.memberCount && team.memberCount > 0){
                handleAlertAction(true, "Error", "Cannot delete team with members.", AlertType.Error);
                return;
            }
            await delete_team(team.id, token ?? "");
            await fetchData(["teams", "users", "tags"]);
            handleAlertAction(true, "Success", "Team deleted successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error deleting the team.", AlertType.Error);
            console.log(e);
        }
    }

    const createTag = async (tag_name: string, tag_teams: string[]) => {
        try {
            handleAlertAction(true, "Info", "Creating tag...", AlertType.Info);
            await create_tag(tag_name, tag_teams, token ?? "");
            await fetchData(["tags"]);
            handleAlertAction(true, "Success", "Tag created successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error creating the tag.", AlertType.Error);
            console.log(e);
        }
    }

    const updateTag = async (tag: Tag, tag_name: string) => {
        try {
            handleAlertAction(true, "Info", "Updating tag...", AlertType.Info);
            await update_tag_name({
                tag_id: tag.id,
                name: tag_name
            }, token ?? "");
            await fetchData(["tags"]);
            handleAlertAction(true, "Success", "Tag updated successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error updating the tag.", AlertType.Error);
            console.log(e);
        }
    }

    const bulkUpdateUsersTag = async (usersToUpdate: User[], tagToAdd: Tag) => {
        try {
            handleAlertAction(true, "Info", "Updating users...", AlertType.Info);
            if (tagToAdd.id !== "none") {
                // Check if any teams will be left without any users after tag is applied
                const teamsUsersWillBePartOf = tagToAdd.teams; // Teams that will not have users removed, but added
                const teamsUsersAreIn = usersToUpdate.flatMap(user => user.teams);
                const teamsThatWillLooseUsers = teamsUsersAreIn.filter(team => !teamsUsersWillBePartOf.includes(team)); // Teams that will have users removed

                // For the teams that will loose users, create a roster (dict) of all users in them where key is team id and value is an array of users (id and role)
                const teamsUsers = teamsThatWillLooseUsers.reduce<Record<string, User[]>>((acc, teamId) => {
                    const teamUsers = users.filter(user => user.teams.includes(teamId));
                    acc[teamId] = teamUsers;
                    return acc;
                }, {});

                // Simulate removing usersToUpdate from the teams they are in
                const teamsUsersAfterRemoval = teamsThatWillLooseUsers.reduce<Record<string, User[]>>((acc, teamId) => {
                    acc[teamId] = teamsUsers[teamId].filter(user => !usersToUpdate.some(u => u.id === user.id));
                    return acc;
                }, {});

                // Check if any teams will be left without any users or admins
                const teamsWithoutUsers = Object.entries(teamsUsersAfterRemoval).filter(([, users]) => users.length === 0);
                const teamsWithoutAdmins = Object.entries(teamsUsersAfterRemoval).filter(([, users]) => !users.some(user => user.role === UserRole.ADMIN));

                if (teamsWithoutUsers.length > 0 || teamsWithoutAdmins.length > 0) {
                    handleAlertAction(true, 'Cannot perform action', 'At least one user and one admin is required for each team', AlertType.Error);
                    return; // Prevent the operation from continuing
                }
            }

            const request = {
                users: usersToUpdate.map(
                    (user) => {
                        return {
                            user_id: user.id,
                            role: user.role,
                            teams: user.teams,
                            tags: user.tags
                        }
                    }
                ),
                tag_to_add: tagToAdd.id
            }
            await bulk_update_users_tag(request, token ?? "");

            await fetchData(["users", "tags", "teams"]);
            handleAlertAction(true, "Success", "User's tags updated successfully.", AlertType.Success);
        }catch(e){
            handleAlertAction(true, "Error", "There was an error updating tags for users.", AlertType.Error);
            console.log(e);
        }
    }

    const bulkUpdateTagTeams = async (tag: Tag, teamsToAdd: string[], teamsToRemove: string[]) => {
        try {
            handleAlertAction(true, "Info", "Updating tag...", AlertType.Info);

            if (teamsToRemove.length > 0) {
                const usersToUpdate = users.filter(user => user.tags.includes(tag.id));
                // For the teams that will loose users, create a roster (dict) of all users in them where key is team id and value is an array of users (id and role)
                const teamsUsers = teamsToRemove.reduce<Record<string, User[]>>((acc, teamId) => {
                    const teamUsers = users.filter(user => user.teams.includes(teamId));
                    acc[teamId] = teamUsers;
                    return acc;
                }, {});

                // Simulate removing usersToUpdate from the teams they are in
                const teamsUsersAfterRemoval = teamsToRemove.reduce<Record<string, User[]>>((acc, teamId) => {
                    acc[teamId] = teamsUsers[teamId].filter(user => !usersToUpdate.some(u => u.id === user.id));
                    return acc;
                }, {});

                // Check if any teams will be left without any users or admins
                const teamsWithoutUsers = Object.entries(teamsUsersAfterRemoval).filter(([, users]) => users.length === 0);
                const teamsWithoutAdmins = Object.entries(teamsUsersAfterRemoval).filter(([, users]) => !users.some(user => user.role === UserRole.ADMIN));

                if (teamsWithoutUsers.length > 0 || teamsWithoutAdmins.length > 0) {
                    handleAlertAction(true, 'Cannot perform action', 'At least one user and one admin is required for each team', AlertType.Error);
                    return; // Prevent the operation from continuing
                }
            }

            const request = {
                tag_id: tag.id,
                teams_to_add: teamsToAdd,
                teams_to_remove: teamsToRemove
            }
            await bulk_update_tag_teams(request, token ?? "");
            await fetchData(["tags", "users", "teams"]);
            handleAlertAction(true, "Success", "Teams tag updated successfully!", AlertType.Success);
        } catch (e) {
            handleAlertAction(true, "Error", "There was an error updating the teams in the tag.", AlertType.Error);
            console.error(e);
        }
    }

    const deleteTag = async (tag: Tag) => {
        try {
            handleAlertAction(true, "Info", "Deleting tag...", AlertType.Info);
            await delete_tag(tag.id, token ?? "");
            await fetchData(["tags"]);
            handleAlertAction(true, "Success", "Tag deleted successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error deleting the tag.", AlertType.Error);
            console.error(e);
        }
    }

    const updateUserRole = async (usersToUpdate: User[], newRole: UserRole) => {
        try {
            handleAlertAction(true, "Info", "Updating users...", AlertType.Info);

            const request_users = usersToUpdate.map(
                (user) => {
                    return {
                        user_id: user.id,
                        role: newRole,
                        teams: user.teams
                    }
                }
            )
            await change_users_role(request_users, token ?? "");
            await fetchData(["users"]);
            handleAlertAction(true, "Success", "Role updated successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error updating the role.", AlertType.Error);
            console.log(e);
        }
    }

    const updateSuperAdmins = async (admins: string[]) => {
        try {
            handleAlertAction(true, "Info", "Updating super admins...", AlertType.Info);
            await update_super_admins({ admins }, token ?? "");
            handleAlertAction(true, "Success", "Super admins updated successfully.", AlertType.Success);
        }
        catch (e) {
            handleAlertAction(true, "Error", "There was an error updating the super admins.", AlertType.Error);
            console.error(e);
        }
    }

    const fetchData = useCallback(async (what_to_load?: Array<'users' | 'teams' | 'tags'>) => {
        setLoading(true);
        if (what_to_load?.includes("users") || !what_to_load) {
            const temp_users = await get_users(token ?? "") as User[];
            // check that any users that have status invited for a team but also exist as a user with status Active and that same team under teams are omitted.
            const invited_users = temp_users.filter(user => user.status === UserStatus.Invited);
            const active_users = temp_users.filter(user => user.status === UserStatus.Active);
            const invited_users_to_remove = invited_users.filter(user => active_users.some(active_user => active_user.email === user.email && active_user.teams.some(team => user.teams.includes(team))));
            const users = temp_users.filter(user => !invited_users_to_remove.includes(user));
            setUsers(users);
        }
        if (what_to_load?.includes("teams") || !what_to_load) {
            setTeams(await get_teams(token ?? "") as Team[]);
        }
        if (what_to_load?.includes("tags") || !what_to_load) {
            setTags(await get_tags(token ?? "") as Tag[]);
        }
        setLoading(false);
    }, [token]);

    useEffect(() => {
        const fetchToken = async () => {
            setToken(await getToken({ template: "supabase" }) ?? "")
        };
        fetchToken().catch(console.error);
    }, [getToken]);

    useEffect(() => {
        // Fetch teams, groups, and users
        if (token) {
            fetchData().catch(console.error);
        }
    }, [fetchData, token]);

    // Combine state and functions to pass down via context
    const value: AdminContextState = {
        users, teams, tags,
        loading,
        alertBanner,
        alertTitle,
        alertMessage,
        alertType,
        hideAlertBanner,
        inviteUsers,
        createTeam,
        addTeam,
        updateTeam,
        bulkUpdateUsersTeams,
        deleteTeam,
        createTag,
        updateTag,
        bulkUpdateUsersTag,
        bulkUpdateTagTeams,
        deleteTag,
        updateUserRole,
        updateSuperAdmins,
        handleAlertAction
    };

    return (
        <AdminContext.Provider value={value}>
            {children}
        </AdminContext.Provider>
    );
};

// Custom hook to use admin context
export const useAdmin = (): AdminContextState => {
    const context = useContext(AdminContext);
    if (context === undefined) {
        throw new Error('useAdmin must be used within an AdminProvider');
    }
    return context;
};

export default AdminProvider;