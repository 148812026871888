import { Fragment, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

interface ReminderProps{
    show:boolean,
    hide: () => void,
    title:string,
    text:string,
    lineColor?:string,
    iconColor?:string
    icon?:React.ElementType 
}

export default function Reminder(props:ReminderProps) {

    const [reminderTimer, setReminderTimer] = useState<number>(0);
    let lineColor = props.lineColor ?? 'bg-blue-500'
    let iconColor = props.iconColor ?? 'text-blue-400'

    useEffect(() => {
        let hideTimer: NodeJS.Timeout | undefined;
        let progressTimer: NodeJS.Timeout | undefined;
        if (props.show) {
            setReminderTimer(0);
            // Give a tick for the DOM to register initial 0% width
            progressTimer = setTimeout(() => {
              setReminderTimer(100);
            }, 50);
            // Hide the reminder after 2500ms
            hideTimer = setTimeout(() => {
                props.hide();
            }, 4000);
        }
        return () => {
            if (hideTimer) clearTimeout(hideTimer);
            if (progressTimer) clearTimeout(progressTimer);
        };
    }, [props.show, props.title]);

    return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-12 lg:mt-0 z-[999]"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end mt-16 lg:mt-0">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={props.show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                    <div className="flex items-start">
                    <div className="flex-shrink-0">
                        {props.icon ? <props.icon className={`h-6 w-6 ${iconColor}`} aria-hidden="true"/> : <CheckCircleIcon className={`h-6 w-6 ${iconColor}`} aria-hidden="true" />}
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">{props.title}</p>
                        <p className="mt-1 text-sm text-gray-500">{props.text}</p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                        <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => {
                            props.hide()
                        }}
                        >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                    </div>
                </div>
                 <div className='w-full bg-gray-500'>
                        <div className={`${lineColor} h-[5px]`} style={{width:`${reminderTimer}%`, transition:'width 2.5s linear'}}/>
                </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}