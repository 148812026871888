import config from '../config.json'

const url = import.meta.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

interface UserObject {
    user_id: string;
    role: string;
    teams?: string[]
}

export interface UserInvite {
    email: string;
    teams: string[];
    role: string;
}

export async function invite_users(invites: UserInvite[], jwt: string) {
    const endpoint = url + `/v2/super_organization/invite_users`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(invites)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error inviting users.`);
    }
}

export async function get_users(jwt: string) {
    const endpoint = url + `/v2/super_organization/get_users`;
    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error getting users.`);
    }
}

export async function get_teams(jwt: string) {
    const endpoint = url + `/v2/super_organization/get_teams`;
    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error getting teams.`);
    }
}

export async function get_tags(jwt: string, tag_id?: string) {
    const endpoint = url + `/v2/super_organization/get_tags` + (tag_id ? `?tag_id=${tag_id}` : '');
    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error getting tags.`);
    }
}

export async function create_team(team_name:string, jwt: string) {
    const endpoint = url + `/v2/super_organization/create_team`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({team_name})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error creating team.`);
    }
}

export async function add_existing_teams(teams:string[], jwt: string) {
    const endpoint = url + `/v2/super_organization/add_existing_teams`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({teams})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error adding existing teams.`);
    }
}

interface UpdateOrgNameRequest {
    organization_id: string;
    name: string;
}

export async function update_org_name(request: UpdateOrgNameRequest, jwt: string) {
    const endpoint = url + `/v2/super_organization/update_org_name`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(request)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error updating organization name.`);
    }
}

interface BulkUpdateUsersTeamsRequest {
    users: UserObject[];
    teams_to_add: string[];
    teams_to_remove: string[];
}

export async function bulk_update_users_teams(request: BulkUpdateUsersTeamsRequest, jwt: string) {
    const endpoint = url + `/v2/super_organization/bulk_update_users_teams`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(request)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error updating users teams.`);
    }
}

export async function delete_team(team_id:string, jwt: string) {
    const endpoint = url + `/v2/super_organization/delete_team`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({team_id})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error deleting team.`);
    }
}

export async function create_tag(tag_name:string, tag_teams:string[], jwt: string) {
    const endpoint = url + `/v2/super_organization/create_tag`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({tag_name, tag_teams})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error creating tag.`);
    }
}

interface UpdateTagRequest {
    tag_id: string;
    name: string;
}

export async function update_tag_name(request: UpdateTagRequest, jwt: string) {
    const endpoint = url + `/v2/super_organization/update_tag_name`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(request)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error updating tag.`);
    }
}

interface BulkUpdateUsersTagRequest {
    users: UserObject[];
    tag_to_add?: string;
    tag_to_remove?: string;
}

export async function bulk_update_users_tag(request: BulkUpdateUsersTagRequest, jwt: string) {
    const endpoint = url + `/v2/super_organization/bulk_update_users_tag`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(request)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error updating users teams.`);
    }
}

export async function delete_tag(tag_id:string, jwt: string) {
    const endpoint = url + `/v2/super_organization/delete_tag`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({tag_id})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error deleting tag.`);
    }
}

export async function change_users_role(users: UserObject[], jwt: string) {
    const endpoint = url + `/v2/super_organization/change_users_role`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify({users})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error changing user role.`);
    }
}

interface BulkUpdateTagTeamsRequest{
    tag_id: string;
    teams_to_add: string[];
    teams_to_remove: string[];
}

export async function bulk_update_tag_teams(request: BulkUpdateTagTeamsRequest, jwt: string) {
    const endpoint = url + `/v2/super_organization/bulk_update_tag_teams`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(request)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error updating tag teams.`);
    }
}

interface UpdateSuperOrgAdminsRequest {
    admins: string[]
}

export async function update_super_admins(request:UpdateSuperOrgAdminsRequest, jwt: string) {
    const endpoint = url + `/v2/super_organization/update_super_admins`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(request)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error updating super admins.`);
    }
}

interface CreateSuperOrgRequest{
    name: string;
    tier?: string;
    domain?: string;
    support_tier?: string;
    teams?: string[];
    davidEnabled?: boolean;
}

export async function create_super_org(request:CreateSuperOrgRequest, jwt: string) {
    const endpoint = url + `/v1/super_organization/create_super_org`;
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwt
            },
            body: JSON.stringify(request)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        throw new Error(`There was an error creating super organization.`);
    }
}