import React, { useCallback } from 'react';
import { ProcessedUser } from './types';
import { UserRole } from '../../../settings/UserRoles';
import MultiSelectListbox from '../listbox/MultiSelectListbox';
import SingleSelectListbox from '../listbox/SingleSelectListbox';
import { capitalize } from 'lodash';

interface PreviewSectionProps {
    csvProcessed: ProcessedUser[];
    teams: Array<{ id: string; name: string }>;
    handleUserTeamChange: (index: number, selectedTeams: string[]) => void;
    handleUserRoleChange: (index: number, selectedRole: UserRole) => void;
    handleBulkInvite: () => void;
    handleCancelCsv: () => void;
    isSubmitting: boolean;
}

const PreviewSection: React.FC<PreviewSectionProps> = ({
    csvProcessed,
    teams,
    handleUserTeamChange,
    handleUserRoleChange,
    handleBulkInvite,
    handleCancelCsv,
    isSubmitting
}) => {
    const roleOptions = Object.values(UserRole).map((role) => ({
        id: role,
        name: capitalize(role.replace("org:", "").replace(/_/g, ' '))
    }));

    return (
        <div>
            <h3 className="font-medium text-lg mb-2">Preview Users</h3>
            <p className="text-sm text-gray-500 mb-4">
                Review the users to be invited. You can select teams and roles for each user. Valid users are ready to invite, invalid ones will be skipped.
            </p>

            <div className="border border-gray-300 rounded-md mb-6">
                <div className="overflow-x-auto overflow-y-auto h-[50vh] lg:h-[50vh] thin-scrollbar thin-scrollbar-x">
                    <table className="min-w-full max-w-full divide-y divide-gray-600 rounded-md">
                        <colgroup>
                            <col className="w-1/4" />
                            <col className="w-1/4" />
                            <col className="w-1/4" />
                            <col className="w-1/4" />
                        </colgroup>
                        <thead className="bg-gray-50 sticky top-0 z-[999999] border-b-1 border-gray-300 shadow-md">
                            <tr>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email*</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Teams*</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {csvProcessed.map((user, index) => (
                                <tr key={index} className={user.errors.length === 0 ? 'bg-white' : 'bg-red-50'}>
                                    <td className="truncate px-3 py-4 text-sm text-gray-500">
                                        {user.email || <span className="text-red-500">Missing</span>}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500">
                                        {user.email ? (
                                            <>
                                                <div className="w-full relative">
                                                    <MultiSelectListbox
                                                        options={teams}
                                                        selected={user.teams}
                                                        onChange={(selected) => {handleUserTeamChange(index, selected)}}
                                                        placeholder="Select teams"
                                                        maxHeight="max-h-40"
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <span className="text-gray-400">Not available</span>
                                        )}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500">
                                        {user.email ? (
                                            <>
                                                <div className="w-full relative">
                                                    <SingleSelectListbox
                                                        options={roleOptions}
                                                        selected={user.role}
                                                        onChange={(selected) => {handleUserRoleChange(index, selected as UserRole)}}
                                                        placeholder="Select a role"
                                                        maxHeight="max-h-40"
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <span className="text-gray-400">Not available</span>
                                        )}
                                    </td>
                                    <td className="px-3 py-4 text-sm">
                                        <span className={`${user.errors.length === 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} px-2 py-1 rounded-full text-xs inline-block`}>{user.errors.length > 0 ? user.errors[0] : "Ready"}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="flex justify-end space-x-3">
                <button
                    type="button"
                    onClick={handleCancelCsv}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    onClick={handleBulkInvite}
                    disabled={isSubmitting || csvProcessed.filter(u => u.errors.length === 0).length === 0}
                    className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 ${(isSubmitting || csvProcessed.filter(u => u.errors.length === 0).length === 0) ? 'opacity-75 cursor-not-allowed' : ''}`}
                >
                    {isSubmitting ? 'Inviting...' : `Invite ${String(csvProcessed.filter(u => u.errors.length === 0).length)} User${csvProcessed.filter(u => u.errors.length === 0).length !== 1 ? 's' : ''}`}
                </button>
            </div>
        </div>
    );
};

export default PreviewSection;
