import { TourConfig, Step } from "../types";
import WelcomeScreen from "../modals/WelcomeScreen";
import EndScreen from "../modals/EndScreen";
import { TOUR_IDS, tourStyles } from "../constants";
import { NewspaperIcon } from '@heroicons/react/24/outline';
import { DocumentDuplicateIcon, ShareIcon, StarIcon, TrashIcon } from '@heroicons/react/24/solid';

export enum TemplatesOverviewTourIdentifiers {
  TEMPLATES_LIST = 0,
  TEMPLATES_TYPES_FILTER = 1,
  TEMPLATES_SEARCH_BAR = 2,
  TEMPLATE_ACTIONS = 3,
  TEMPLATE_LIBRARY = 4,
  TEMPLATE_LIBRARY_FILTERS = 5,
  CREATE_TEMPLATE_BUTTON = 6,
  TEMPLATES_TUTORIAL_VIDEO = 7
}

const DEMO_MENU = (
  <div className="flex justify-center mt-4">
    <div
      className="bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 
                pointer-events-none w-56 overflow-hidden
                ring-2 ring-blue-200 ring-opacity-50
                shadow-[0_0_15px_rgba(59,130,246,0.3)]"
    >
      <div className="py-1">
        <div className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
          <DocumentDuplicateIcon className="mr-3 h-5 w-5 text-gray-400" />
          Duplicate
        </div>
        <div className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
          <TrashIcon className="mr-3 h-5 w-5 text-gray-400" />
          Delete
        </div>
        <div className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
          <ShareIcon className="mr-3 h-5 w-5 text-gray-400" />
          Share
        </div>
        <div className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
          <StarIcon className="mr-3 h-5 w-5 text-gray-400" />
          Set as default
        </div>
      </div>
    </div>
  </div>
);

export const TEMPLATES_OVERVIEW_STEPS_DEFINITION: Step[] = [
  {
    id: 'TEMPLATES_LIST',
    target: '[data-tour-id="templates-list"]',
    disableBeacon: true,
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Each of these cards represent your <span className="text-blue-600 font-semibold"> current templates</span></span>
      </div>
    ),
    spotlightPadding: 15,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: 'TEMPLATES_TYPES_FILTER',
    target: '[data-tour-id="template-filters"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Click on the highlighted buttons to filter between Medical <span className="text-red-600 font-semibold">Notes</span>, <span className="text-blue-600 font-semibold">Client</span> Communication, and Records <span className="text-yellow-600 font-semibold">Recap</span> templates.</span>
      </div>
    ),
    placement: 'top',
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing
    },
  },
  {
    id: 'TEMPLATES_SEARCH_BAR',
    target: '[data-tour-id="template-search"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Use the <span className="text-blue-600 font-semibold">search bar</span> to find your templates quickly! 🔍</span>
      </div>
    ),
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing
    },
  },
  {
    id: 'TEMPLATE_ACTIONS',
    target: '[data-tour-id="template-card-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>This button gives you quick access to actions like setting your <span className="text-blue-600 font-semibold">default template</span> or creating a <span className="text-blue-600 font-semibold">shareable link</span> to collaborate with others.</span>
        {DEMO_MENU}
      </div>
    ),
    spotlightPadding: 1,
    styles: {
      spotlight: tourStyles.spotlight.pulsing
    },
  },
  {
    id: 'TEMPLATE_LIBRARY',
    target: '[data-tour-id="template-tabs"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Explore our <span className="text-blue-600 font-semibold">template library</span> - a curated collection of popular, ready-to-use templates that you can customize to fit your needs.</span>
      </div>
    ),
    spotlightPadding: 4,
    placement: 'right',
    styles: {
      spotlight: tourStyles.spotlight.pulsing
    },
  },
  {
    id: 'TEMPLATE_LIBRARY_FILTERS',
    target: '[data-tour-id="template-filters"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Just like before you can use these buttons to filter between the different categories like <span className="text-blue-600 font-semibold">Specialty</span>, <span className="text-blue-600 font-semibold">ER</span>, <span className="text-blue-600 font-semibold">Exotics</span>, and  more in our library.</span>
      </div>
    ),
    placement: 'top',
    spotlightPadding: 8,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing
    }
  },
  {
    id: 'CREATE_TEMPLATE_BUTTON',
    target: '[data-tour-id="create-template-button"]',
    content: (
      <div className="leading-relaxed space-y-3">
        <span>Use the <span className="text-blue-600 font-semibold">Create Template</span> button to go to the <span className="text-blue-600 font-semibold">Template Builder</span> and make your own template</span>
      </div>
    ),
    spotlightPadding: 4,
    styles: {
      spotlight: tourStyles.spotlight.default,
    },
  },
  {
    id: 'TEMPLATES_TUTORIAL_VIDEO',
    target: '[data-tour-id="templates-tutorial-video"]',
    content: (
      <div className="leading-relaxed space-y-4">
        <span>Checkout out our <span className="text-blue-600 font-semibold">tutorial video</span> for a quick overview about templates!</span>
        <div className="relative w-full pt-[56.25%]">
          <iframe
            className="absolute top-0 left-0 w-full h-full rounded-lg"
            src="https://www.youtube.com/embed/U47wB8VJc7s"
            title="Template Builder Tutorial"
            allowFullScreen
          />
        </div>
      </div>
    ),
    spotlightPadding: 4,
    spotlightClicks: true,
    styles: {
      spotlight: tourStyles.spotlight.pulsing,
      tooltip: {
        width: 400  // Increased width to accommodate the video better
      }
    },
  },
];

const welcomeScreenProps = {
  icon: <NewspaperIcon />,
  title: (
    <>
      Welcome to {' '}
      <span className="font-bold text-blue-600">Templates</span>! 📚
    </>
  ),
  description: "Let's explore where all your templates live! We'll show you how to find, create, and manage your templates with ease.",
};

export const TemplatesOverviewTourConfig: TourConfig = {
  steps: TEMPLATES_OVERVIEW_STEPS_DEFINITION,
  tourId: TOUR_IDS.TEMPLATES_OVERVIEW,
  WelcomeModal: (props) => <WelcomeScreen {...props} {...welcomeScreenProps} />,
  EndModal: (props) => (
    <EndScreen
      {...props}
      icon="📚"
      title="You're All Set!"
      description="Now you know your way around templates. Ready to checkout the template builder?"
      primaryButtonText="Done for now"
      secondaryActions={[
        { text: "Take me to the template builder", navigateTo: "/templateBuilder" }
      ]}
    />
  ),
  showConfetti: true,
}; 