import React, { useMemo, useState } from 'react';
import { useAdmin } from '../../providers/AdminProvider';
import TeamRow from './subcomponents/TeamRow';
import ExistingTeamModal from './subcomponents/ExistingTeamModal';
import Reminder from '../ReminderToast';
import { getAlertBackgroundColor, getAlertIcon, getAlertIconColor } from './utils/adminUtils';
import { Spinner } from '../../utils/Spinner';
import { Team } from './types/user';
import ConfirmationModal from './subcomponents/ConfirmationModal';

const TeamManagement: React.FC = () => {
  const { teams, deleteTeam, createTeam, updateTeam, alertBanner, alertMessage, alertTitle, alertType, hideAlertBanner, loading } = useAdmin()
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [isAddingExistingTeam, setIsAddingExistingTeam] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const [teamSearch, setTeamSearch] = useState('');
  const [confirmTitle, setConfirmTitle] = useState<string>();
  const [confirmMessage, setConfirmMessage] = useState<string>();
  const [confirmAction, setConfirmAction] = useState<(() => Promise<void>)>();

  const handleCreateTeam = async () => {
    if (newTeamName.trim()) {
      await createTeam(newTeamName);
      setNewTeamName('');
      setIsAddingTeam(false);
    }
  };

  const handleEditTeam = async (teamId: string, newName: string) => {
    if (newName.trim()) {
      await updateTeam(teamId, newName);
    }
  };

  const handleDeleteTeam = (team: Team) => {
    setConfirmTitle('Delete Team');
    setConfirmMessage(`Are you sure you want to delete team "${team.name}"?`);
    setConfirmAction(() => async () => {
        await deleteTeam(team);
        setConfirmTitle(undefined);
        setConfirmMessage(undefined);
        setConfirmAction(undefined);
    });
  }

  const filteredTeams = useMemo(() => {
    return teams.filter((team) => team.name.toLowerCase().includes(teamSearch.toLowerCase()));
  }, [teams, teamSearch]);

  return (
    <>
      {confirmAction !== undefined && <ConfirmationModal showModal={confirmTitle !== undefined} confirmTitle={confirmTitle} confirmMessage={confirmMessage} confirmAction={confirmAction} rejectAction={() => { setConfirmTitle(undefined); setConfirmMessage(undefined); setConfirmAction(undefined) }} color='red'/>}
      <Reminder show={alertBanner} hide={hideAlertBanner} text={alertMessage} title={alertTitle} iconColor={getAlertIconColor(alertType)} icon={getAlertIcon(alertType)} lineColor={getAlertBackgroundColor(alertType)}/>
      <ExistingTeamModal showModal={isAddingExistingTeam} setShowModal={setIsAddingExistingTeam} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold text-gray-900">Teams</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all teams in your organization and their member counts.
            </p>
          </div>
          <div className='flex flex-row items-center gap-x-4'>
            <div className="mt-4 sm:mt-0 sm:flex-none">
              <button
                type="button"
                onClick={() => setIsAddingExistingTeam(true)}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
              >
                Add Existing Team
              </button>
            </div>
            <div className="mt-4 sm:mt-0 sm:flex-none">
              {!isAddingTeam ? (
                <button
                  type="button"
                  onClick={() => setIsAddingTeam(true)}
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                >
                  Create Team
                </button>
              ) : (
                <div className="flex space-x-2">
                  <input
                    type="text"
                    className="block rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder="Team name"
                    value={newTeamName}
                    onChange={(e) => setNewTeamName(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => void handleCreateTeam()}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsAddingTeam(false);
                      setNewTeamName('');
                    }}
                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <div className="-my-2 overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-visible border border-gray-300 ring-opacity-5 rounded-md max-w-full overflow-y-auto h-[75vh] thin-scrollbar">
                <table className="min-w-full max-w-full divide-y divide-gray-600 rounded-md">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Team Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Members
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 flex justify-end">
                        <input className='rounded-md text-sm placeholder:text-gray-500 font-normal' placeholder='Search for teams' onChange={(e) => setTeamSearch(e.target.value)} aria-label='Search for teams'/>
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredTeams.length > 0 && !loading ? (
                      filteredTeams.map((team) => (
                        <TeamRow
                          key={team.id}
                          team={team}
                          onEdit={(newName) => void handleEditTeam(team.id, newName)}
                          onDelete={() => handleDeleteTeam(team)}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                          <div className="flex flex-col items-center gap-y-2 w-full">
                            {loading && <Spinner timer={false} size='h-5 w-5' />}
                            {loading ? "Loading teams..." : "No Teams found"}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamManagement;
