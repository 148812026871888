import React, { Fragment, cloneElement } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import type { SVGProps } from 'react';

interface Step {
  icon: React.ReactElement<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
}

interface WelcomeModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  startTour: () => void;
  onSkip: () => void;
  icon: React.ReactElement<SVGProps<SVGSVGElement>>;
  title: React.ReactNode;
  description: string;
  steps?: Step[];
  primaryButtonText?: string;
  skipButtonText?: string;
}

const WelcomeScreen = ({
  isOpen,
  setIsOpen,
  startTour,
  onSkip,
  icon,
  title,
  description,
  steps,
  primaryButtonText = "Start Quick Tour",
  skipButtonText = "Skip"
}: WelcomeModalProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[999999]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-8 pb-8 pt-10 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-12">
                <div>
                  <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-blue-100">
                    {cloneElement(icon, {
                      className: "h-8 w-8 text-blue-600 animate-pulse",
                      "aria-hidden": "true"
                    })}
                  </div>
                  <div className="mt-6 text-center">
                    <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className="text-base text-gray-500">{description}</p>
                    </div>
                  </div>

                  {steps && (
                    <div className="mt-12">
                      <div className="grid grid-cols-3 gap-8">
                      {steps?.map((step, index) => (
                        <div key={index} className="text-center">
                          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-50">
                            <div className="text-blue-600">
                              {cloneElement(step.icon, { className: "h-6 w-6" })}
                            </div>
                          </div>
                          <h4 className="mt-2 font-medium text-gray-900">{step.title}</h4>
                          <p className="mt-1 text-sm text-gray-500">{step.description}</p>
                        </div>
                      ))}
                    </div>
                    </div>
                  )}
                </div>

                <div className="mt-12 sm:mt-14 space-y-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center items-center gap-x-2 rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    onClick={startTour}
                  >
                    <span>{primaryButtonText}</span>
                    <span className="text-lg">🚀</span>
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                      setIsOpen(false);
                      onSkip();
                    }}
                  >
                    {skipButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WelcomeScreen;
