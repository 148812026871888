import { SectionType } from "../components/templates/TemplateUtils";

export function filterNotMentioned(input:string) {
    return input.split('\n').filter(line => !/\[Not Mentioned\]/.test(line)).join('\n');
}

export function filterNotMentionedHTML(input:string) {
    // remove <span style="background-color: rgb(254, 240, 138);">[Not Mentioned]</span>
    return input.replace(/<span style="background-color: rgb\(254, 240, 138\);">\[Not Mentioned\]<\/span>/gi, '');
}
  
export function removeNotMentioned(input:string) {
    return input.split('\n').map(line => line.replace(/\[Not Mentioned\]$/, '')).join('\n');
}

export function extractFirstName(name: string) {
    const cleanedName = name
      .replace(/[^a-zA-Z\s]/g, '') // Remove non-letter characters
      .trim();                     // Remove extra whitespace
  
    return cleanedName ? cleanedName.split(/\s+/)[0] : name; // Return first word or full name if empty
}

export function toTitleCase(str:string) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());
}

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const preprocessText = (text: string) => {
    let htmlText = '';
    let inList = false; // Flag to track whether we are currently adding list items
    let nestingLevel = 0; // Flag for nested list items
    const decodedText = text.replace(/\\n/g, '\n');
    const lines = decodedText.split('\n');
    let prev_indent = 0;
    lines.forEach((line, index) => {
        let processedLine = line.replace(/\[\[(.*?)\]\]/g, '<span style="color: #ff0303;">$1</span>');
        processedLine = processedLine
                    .replace(/\[(Not Mentioned|not Mentioned|Not mentioned|not mentioned)\]/gi, '<span style="background-color: #fef08a;">[$1]</span>')
                    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold any text surrounded by **
                    .replace("Default:", "")
                    .replace("DEFAULT:", "")

        if (processedLine.trim().startsWith('-')) {
            if (nestingLevel > 0) {
                htmlText += '</ul>'.repeat(nestingLevel);  // Close the nested list
                nestingLevel = 0;
            }

            if (!inList) {
                htmlText += '<ul>';
                inList = true;
            }

            htmlText += `<li>${processedLine.trim().substring(1).trim()}</li>`;
        } else if (processedLine.trim().startsWith('*')) {
            const currentIndent = processedLine.match(/^(\s*)\*/)?.[1].length || 0;
            if (nestingLevel === 0 || currentIndent > prev_indent) {
                if (!inList) {
                    // This condition should not normally be met for well-formed input but is here as a safeguard
                    htmlText += '<ul>'; // Start the first-level list implicitly before starting a nested list
                    inList = true;
                }
                htmlText += '<ul>'; // Start a nested list
                nestingLevel += 1;
                prev_indent = currentIndent;
            } else if (currentIndent < prev_indent) {
                htmlText += '</ul>'
                nestingLevel -= 1;
                prev_indent = currentIndent;
            }

            htmlText += `<li>${processedLine.trim().substring(1).trim()}</li>`;
        } else {
            if (nestingLevel > 0) {
                htmlText += '</ul>'.repeat(nestingLevel); // Close the nested list and the parent list item
                nestingLevel = 0;
            }

            if (inList) {
                htmlText += '</ul>';
                inList = false;
            }
            // Apply SpacingText function if defined
            htmlText += `${SpacingText(processedLine.trim())}<br/>`;
        }
    });

    if (nestingLevel > 0) {
        htmlText += '</ul>'; // Ensure closing any open nested list
        nestingLevel -= 1;
    }
    if (inList) {
        htmlText += '</ul>'; // Ensure closing any open list
        inList = false;
    }

    // NOTE: Removing this to accomodate for custom new lines in the template, lmk if it might break something
    // htmlText = htmlText.replace(/(<br\s*\/?>){3,}/gi, '<br\/><br\/>'); // Remove empty lists
    // htmlText = htmlText.replace(/<\/ul>(<br\s*\/?>){2,}/gi, '</ul><br/>'); // Only one newline after each list
    return htmlText;
}

export const SpacingText = (text:string) => {
    // New lines to HTML
    const textWithLineBreaks = text.replace(/\n/g, '<br>');
    return textWithLineBreaks
}

export const preprocessDischargeText = (text:string) => {
    let htmlText = '';
    let inList = false; // Flag to track whether we are currently adding list items
    let inNestedList = false; // Flag for nested list items
    // convert escaped newlines to actual newlines
    const decodedText = text.replace(/\\n/g, '\n');
    const lines = decodedText.split('\n');
    lines.forEach(line => {
        let processedLine = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); // Bold any text surrounded by **
        if (processedLine.trim().startsWith('-')) {
            if (inNestedList) {
                htmlText += '</ul>'; // Close the nested list
                inNestedList = false;
            }
            if (!inList) {
                htmlText += '<ul>';
                inList = true;
            }
            htmlText += `<li>${processedLine.trim().substring(1).trim()}</li>`;
        } else if (processedLine.trim().startsWith('*')) {
            if (!inNestedList) {
                if (!inList) {
                    // This condition should not normally be met for well-formed input but is here as a safeguard
                    htmlText += '<ul>'; // Start the first-level list implicitly before starting a nested list
                    inList = true;
                }
                htmlText += '<ul>'; // Start a nested list
                inNestedList = true;
            }
            htmlText += `<li>${processedLine.trim().substring(1).trim()}</li>`;
        } else {
            if (inNestedList) {
                htmlText += '</ul></li>'; // Close the nested list and the parent list item
                inNestedList = false;
            }
            if (inList) {
                htmlText += '</ul>';
                inList = false;
            }
            // Apply SpacingText function if defined
            htmlText += `${SpacingText ? SpacingText(processedLine) : processedLine}<br/>`;
        }
    });

    if (inNestedList) {
        htmlText += '</ul>'; // Ensure closing any open nested list
        inNestedList = false;
    }
    if (inList) {
        htmlText += '</ul>'; // Ensure closing any open list
        inList = false;
    }

    htmlText = htmlText.replace(/(<br\s*\/?>){3,}/gi, '<br/><br/>'); // Remove empty lists

    return htmlText;
}

export const revertPreprocessedText = (htmlText: string): string => {
    let text = htmlText;

    // Step 1: Replace <br> and <br/> with \n
    text = text.replace(/<br\s*\/?>/gi, '\n');

    // Step 2: Remove <ul> and </ul>
    text = text.replace(/<\/?ul>/gi, '');

    //Step 3: Handle nested <li> items with class "ql-indent-{number}"
    text = text.replace(/<li[^>]*class="[^"]*ql-indent-(\d+)[^"]*"[^>]*>/gi, (match, p1) => {
        const indentLevel = parseInt(p1, 10);
        return ' '.repeat(2 * indentLevel) + '* ';
    });

    // Fallback for regular <li> items (non-indented)
    text = text.replace(/<li[^>]*>/gi, '- ');

    text = text.replace(/<\/li>/gi, '\n'); // Add a newline after each list item

    // Step 4: Convert <strong> tags back to text surrounded by **
    text = text.replace(/<strong>(.*?)<\/strong>/gi, '**$1**');

    // Step 5: Revert the highlighting of "[Not Mentioned]"
    text = text.replace(/<span style="background-color: rgb\(254, 240, 138\);">(.*?)<\/span>/gi, '$1');
    text = text.replace(/<span style="background-color: #fef08a;">(.*?)<\/span>/gi, '$1');

    // Step 6. Convert red text back to text surrounded by [[word]]
    text = text.replace(/<span style="color: #ff0303;">(.*?)<\/span>/gi, '$1');
    text = text.replace(/<span style="color: rgb\(255, 3, 3\);">(.*?)<\/span>/gi, '$1');

    // Get rid of spans
    text = text.replace(/<span[^>]*>(.*?)<\/span>/gi, '$1');

    // Step 7. Convert red text back to text surrounded by [[**word**]]
    text = text.replace(/<strong style="color: #ff0303;">(.*?)<\/strong>/gi, '**$1**');
    text = text.replace(/<strong style="color: rgb\(255, 3, 3\);">(.*?)<\/strong>/gi, '**$1**');

    text = text.replace(/<p>/gi, "")
    text = text.replace(/<\/p>/gi, "\n")
    text = text.replace(/\n\n\n/gi, "\n\n")

    // Fix special characters
    text = text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');
    text = text.replace(/&amp;/g, '&');
    text = text.replace(/&quot;/g, '"');
    text = text.replace(/&#39;/g, "'");
    text = text.replace(/&nbsp;/g, '');

    // Remove additional spacing and leftover tags if necessary
    text = text.trim();

    return text;
};

export const revertPreprocessedTextNoStyling = (htmlText: string): string => {
    let text = htmlText;

    // Step 1: Replace <br> and <br/> with \n
    text = text.replace(/<br\s*\/?>/gi, '\n');

    // Step 2: Remove <ul> and </ul>
    text = text.replace(/<\/?ul>/gi, '');

    //Step 3: Handle nested <li> items with class "ql-indent-{number}"
    text = text.replace(/<li[^>]*class="[^"]*ql-indent-(\d+)[^"]*"[^>]*>/gi, (match, p1) => {
        const indentLevel = parseInt(p1, 10);
        return ' '.repeat(2 * indentLevel) + '* ';
    });

    // Fallback for regular <li> items (non-indented)
    text = text.replace(/<li[^>]*>/gi, '- ');

    text = text.replace(/<\/li>/gi, '\n'); // Add a newline after each list item

    // Step 4: Convert <strong> tags back to text surrounded by **
    text = text.replace(/<strong>(.*?)<\/strong>/gi, '$1');

    // Step 5: Revert the highlighting of "[Not Mentioned]"
    text = text.replace(/<span style="background-color: rgb\(254, 240, 138\);">(.*?)<\/span>/gi, '$1');
    text = text.replace(/<span style="background-color: #fef08a;">(.*?)<\/span>/gi, '$1');

    // Step 6. Convert red text back to text surrounded by [[word]]
    text = text.replace(/<span style="color: #ff0303;">(.*?)<\/span>/gi, '$1');
    text = text.replace(/<span style="color: rgb\(255, 3, 3\);">(.*?)<\/span>/gi, '$1');

    // Get rid of spans
    text = text.replace(/<span[^>]*>(.*?)<\/span>/gi, '$1');

    // Step 7. Convert red text back to text surrounded by [[**word**]]
    text = text.replace(/<strong style="color: #ff0303;">(.*?)<\/strong>/gi, '$1');
    text = text.replace(/<strong style="color: rgb\(255, 3, 3\);">(.*?)<\/strong>/gi, '$1');

    text = text.replace(/<p>/gi, "")
    text = text.replace(/<\/p>/gi, "\n")
    text = text.replace(/\n\n\n/gi, "\n\n")
    
    // Fix special characters
    text = text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');
    text = text.replace(/&amp;/g, '&');
    text = text.replace(/&quot;/g, '"');
    text = text.replace(/&#39;/g, "'");
    text = text.replace(/&nbsp;/g, '');

    // Remove additional spacing and leftover tags if necessary
    text = text.trim();

    return text;
};

export function addMissingIndentLevels(htmlString: string): string {
    let indentLevel = 0;

    // Regular expression to match <li> and nested <ul>/<ol>
    const listRegex = /<\s*(\/?(?:ul|ol|li))(.*?)>/gi;

    return htmlString.replace(listRegex, (match, tagName) => {
        if (tagName === "ul" || tagName === "ol") {
            // Entering a nested list, increase indent level
            indentLevel++;
            return match;
        } else if (tagName === "/ul" || tagName === "/ol") {
            // Exiting a nested list, decrease indent level
            indentLevel = Math.max(indentLevel - 1, 0);
            return match;
        } else if (tagName === "li" && indentLevel > 1) {
            // Add ql-indent-{indentLevel-1} class to <li> tags if nested
            return match.replace(
                /<li(.*?>)/,
                `<li class="ql-indent-${indentLevel - 1}"$1`
            );
        }

        return match; // Leave the outermost <li> tags unchanged
    });
}

function escapeRegExp(string:string) {
    return string.replace(/[.*+?^${}()[\]\\]/g, '\\$&');  // Adds a backslash before special characters
}

export function splitNoteIntoSectionWithDefinition(
    note: string,
    definition: SectionType[]
): Record<string, string> {
    // Clean up new lines to ensure consistent formatting
    const cleanNewLines: string = note.replace(/\n /g, '\n');

    // Escape special characters in section names and join them into a regex alternation group
    const escapedSectionNames: string[] = definition
        .map(section => section.name)
        .filter((key): key is string => key !== undefined)
        .map(name => escapeRegExp(name)); // Escape special regex characters

    const dictionaryKeys: string = escapedSectionNames.join('|');

    // Build the regex pattern
    const pattern: string = `^\\s*(?:\\*{2}\\s*(${dictionaryKeys})\\s*\\*{2}|(${dictionaryKeys}))\\s*:\\s*`;

    // Compile the regex pattern with ignore case and multiline flags
    const regex: RegExp = new RegExp(pattern, 'im'); // 'i' for ignore case, 'm' for multiline

    // Find all matches of the headings
    let matches: RegExpExecArray | null;
    const sections: { heading: string; content: string }[] = [];
    const regexGlobal: RegExp = new RegExp(regex.source, 'gim'); // 'g' for global search

    // Keep track of the positions
    let lastIndex: number = 0;

    while ((matches = regexGlobal.exec(cleanNewLines)) !== null) {
        const heading: string = (matches[1] || matches[2]).trim();
        const headingStartIndex: number = matches.index;
        const headingEndIndex: number = regexGlobal.lastIndex;
        if (lastIndex < headingStartIndex) {
            // Content before this heading (belongs to the previous section)
            const content: string = cleanNewLines.substring(lastIndex, headingStartIndex).trim();
            if (sections.length > 0) {
                sections[sections.length - 1].content = content;
            }
        }

        // Add the new heading
        sections.push({ heading: heading, content: '' });

        lastIndex = headingEndIndex;
    }

    // Add the content after the last heading
    if (sections.length > 0 && lastIndex < cleanNewLines.length) {
        const content: string = cleanNewLines.substring(lastIndex).trim();
        sections[sections.length - 1].content = content
    }

    // Build the result object
    const result: Record<string, string> = definition.reduce(
        (acc: Record<string, string>, section: SectionType) => {
            if (section.name) {
                const key: string = section.name.toLowerCase().trim();
                const sectionData = sections.find(s => s.heading.toLowerCase() === key);
                acc[key] = sectionData ? sectionData.content : '';
            }
            return acc;
        },
        {}
    );

    return result;
}

export function fixIndentation(html: string, removeSpacesBetweenSections: boolean): string {
    try{
        html = html.replace(/<\/ul><br\/><ul>/g, '</ul><ul>');
        html = html.replace(/<\/ul><p><br><\/p><ul>/g, '</ul><ul>');
        
        if (removeSpacesBetweenSections) {
            html = html.replace(/<p><br><\/p>/g, '');
            html = html.replace(/<\/ul><br\/><strong>/g, '</ul><strong>');
        }

        // Parse the HTML string into a DOM document
        let parser = new DOMParser();
        let doc = parser.parseFromString(html, 'text/html');

        // Process all lists in the document
        let lists = doc.querySelectorAll('ul, ol');
        lists.forEach(list => {
            fixList(list);
        });

        // Return the corrected HTML as a string
        return doc.body.innerHTML;
    }
    catch(e){
        return html;
    }
}

function fixList(list: Element) {
    let stack: Element[] = [list];
    let lastIndentLevel = 0;

    // Collect child nodes to prevent issues during DOM manipulation
    let childNodes = Array.from(list.childNodes);

    for (let node of childNodes) {
        if (node.nodeType === Node.ELEMENT_NODE && node.nodeName.toLowerCase() === 'li') {
            let li = node as Element;
            let indentLevel = getIndentLevel(li);

            if (indentLevel > lastIndentLevel) {
                // Indentation increased; create sublists as needed
                for (let i = lastIndentLevel; i < indentLevel; i++) {
                    let newList = list.ownerDocument.createElement('ul');
                    let parentLi = stack[stack.length - 1].lastElementChild;

                    if (parentLi && parentLi.nodeName.toLowerCase() === 'li') {
                        parentLi.appendChild(newList);
                        stack.push(newList);
                    } else {
                        console.error('Cannot find parent <li> to append sublist');
                        return;
                    }
                }
            } else if (indentLevel < lastIndentLevel) {
                // Indentation decreased; move back up the stack
                for (let i = lastIndentLevel; i > indentLevel; i--) {
                    stack.pop();
                }
            }

            // Remove the <li> from its current parent and append it to the correct list
            if (li.parentNode !== stack[stack.length - 1]) {
                li.parentNode?.removeChild(li);
            }
            stack[stack.length - 1].appendChild(li);
            lastIndentLevel = indentLevel;
        }
    }
}

function getIndentLevel(li: Element): number {
    // Extract the indentation level from the class name
    let indentLevel = 0;
    li.classList.forEach(cls => {
        let match = cls.match(/ql-indent-(\d+)/);
        if (match) {
            indentLevel = parseInt(match[1]);
        }
    });
    return indentLevel;
}