import type { CSSProperties, FC } from 'react'
import { useDrag } from 'react-dnd'
import { useTemplate } from './TemplateProvider'

const style: CSSProperties = {
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  cursor: 'move',
  float: 'left',
}

export interface SectionBoxProps {
  name: string
  description:string
  type: string
  isBeta?: boolean
}

export const SectionBox: FC<SectionBoxProps> = function Box({ name, description, type, isBeta }) {
  const { handleEntryOver, handleSectionOver, handleSectionDragged} = useTemplate()
  const [{ isDragging }, drag] = useDrag(() => ({
    type: type,
    item: { name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    end: (item, monitor) => {
      handleSectionDragged(false)
      handleEntryOver(undefined)
      handleSectionOver(undefined)
    }
  }))

  const opacity = isDragging ? 0.4 : 1
  return (
    <div className='flex flex-row justify-between items-center rounded-md w-full border border-gray-300 shadow-blue-600 shadow'>
      <div ref={drag} className="relative flex flex-col gap-y-2" style={{ ...style, opacity }} data-testid={`box`}>
        <span>{name}</span>
        <span className='text-gray-500 text-sm'>{description}</span>
      </div>
      {isBeta && <span className="inline-flex items-center text-white bg-blue-800 rounded-md text-xs font-bold px-2 py-1 rounded-bl-md mr-4">
        Beta
      </span>}
    </div>
  )
} 