import { useEffect, useState } from "react";
import FileDropZone from "./FileDropZone";
import va from "@vercel/analytics";

interface FileUploaderProps {
  sessionId: string | undefined;
  onSubmitButtonClick: (files: File[]) => Promise<void>;
  submitButtonText?: string;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  sessionId,
  onSubmitButtonClick,
  submitButtonText,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState<string | undefined>(undefined);

  const getLoggingProperties = () => {
    return {
      date: new Date().toUTCString(),
      sessionId: sessionId ?? "undefined",
    };
  };

  const countImageFiles = (files: File[]) => {
    return files.filter(file => 
      file.type.startsWith('image/')
    ).length;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploadErrorMessage(undefined);
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      let filesArray = Array.from(uploadedFiles);
      const combinedFiles = [...files, ...filesArray];
      const deduplicatedFiles = combinedFiles.reduce((acc, currentFile) => {
        if (!acc.some(file => file.name === currentFile.name)) {
          acc.push(currentFile);
        }
        return acc;
      }, [] as File[]);

      // Check image file count
      const imageCount = countImageFiles(deduplicatedFiles);
      if (imageCount > 100) {
        setFileUploadErrorMessage("You can only upload up to 100 images at a time, please remove some images.");
      }
      
      setFiles(deduplicatedFiles);
    }

    va.track("PreHistory_Upload", getLoggingProperties());
  };

  async function handleSubmit() {
    if (files.length === 0) {
      setFileUploadErrorMessage("Please add files to summarize");
      return;
    }

    const empty_file = files.find(f => f.size === 0)

    if (empty_file) {
      setFileUploadErrorMessage(`The following file is empty: ${empty_file.name}. Please remove it and try again. If your PDF is compressed (e.g., zipped), please decompress (e.g., unzip) and try again.`)
      return
    }

    await onSubmitButtonClick(files);

    setFiles([]);
    va.track("PreHistory_Submit", getLoggingProperties());
  }

  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (files.length === 0) {
      setDisableSubmit(true);
      return
    }
    
    const empty_file = files.find(f => f.size === 0)
    if (empty_file) {
      setFileUploadErrorMessage(`The following file is empty: ${empty_file.name}. Please remove it and try again. If your PDF is compressed (e.g., zipped), please decompress (e.g., unzip) and try again.`)
      setDisableSubmit(true);
      return
    } 
      
    if (countImageFiles(files) > 100) {
      setFileUploadErrorMessage("You can only upload up to 100 images at a time, please remove some images.");
      setDisableSubmit(true);
      return
    } 

    setFileUploadErrorMessage(undefined);
    setDisableSubmit(false);
  }, [files])

  return (
    <div className="border border-gray-300 p-4 rounded-md">
      <div>
        <div className="text-start">
          <div className="text-base font-semibold leading-6 text-gray-900">
            Add Patient Prior Records
          </div>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Upload any prior records for the patient and let VetRec summarize
              them.
            </p>
          </div>
        </div>
      </div>
      <FileDropZone
        files={files}
        onFileUpload={handleFileUpload}
        invalidFilesUploaded={fileUploadErrorMessage !== undefined}
      />
      {fileUploadErrorMessage && (
        <span className="text-xs text-red-600">
          {fileUploadErrorMessage}
        </span>
      )}
      <button
        type="button"
        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-4 ${
          disableSubmit 
            ? 'bg-blue-300 cursor-not-allowed' 
            : 'bg-blue-600 hover:bg-blue-500'
        }`}
        onClick={() => handleSubmit()}
        disabled={disableSubmit}
      >
        {submitButtonText ?? "Summarize"}
      </button>
    </div>
  );
};
