import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSupabase } from '../supabase/SupabaseContext';
import { CheckRecordings, GetNotesForSessionWithPetArray, GetAuditLogs, GetDiagram, GetDiagramSignedURL, GetDischarge, GetNotes, GetNotesSignedURL, GetOriginalNotes, GetPreHistory, GetRecordings, GetRecordingsAndTranscripts, GetTemplate, GetTranscript, GetDischargeForSessionWithPetArray, GetDiagramForSessionWithPetArray, GetDischargeSignedURL, GetRecapUploadURL } from '../supabase/supabaseProxy';
import { ALL_DOCTORS, MemberObject } from '../utils/MemberUtils';
import { AuditLogs, filterCancelledSessions, filterSessions, RecordingTranscriptPair, SessionObject, SessionStateEnum } from '../utils/SessionUtils';
import { TemplateObject } from '../utils/TemplateObject';
import { useEffect,useRef, useState } from "react"
import { fetchSessionById, generateSoapNotes, processRecording, translateDischargeNotes, handleThumbsFeedbackServerSide, markSessionCompleted, saveName, createSession, saveOwner, fetchSessionsDate, fetchSessionsName, generateDischargeNotes, reprocessRecording, deleteSession, importVetspireVisits, deletePreHistory, updateVetspireVisit, savePatients, saveDate, importOnPremPimsAppointments} from "../ServerActions"
import { DateValueType } from 'react-tailwindcss-datepicker';
import { v4 as uuidv4 } from 'uuid';
import va from '@vercel/analytics';
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react"
import { preprocessDischargeText, preprocessText, revertPreprocessedText, splitNoteIntoSectionWithDefinition } from '../utils/TextUtils';
import { getTemplate } from '../utils/TemplateUtils';
import { isMobileDevice } from "../utils/deviceUtils"
import useDebounce from '../utils/useDebounce';
import { SectionType } from '../components/templates/TemplateUtils';
import { forEach } from 'lodash';
import { UnprivilegedEditor } from 'react-quill';
import { parseDentalInfo, ToothInfo } from '../utils/DentalUtils';
import { ChatbotContext } from '../utils/ChatbotUtils';
import { useVetRec } from './VetRecProvider';
import { objectToJsonFile } from '../utils/UploadUtils';

interface HistoryStateContextType {
    activeSession: SessionObject | undefined;
    setActiveSession: (value: SessionObject | undefined) => void;
    shouldSessionUseMetagraph: boolean;
    filteredSessions: SessionObject[] | undefined;
    availableSession: boolean;
    sessions: SessionObject[] | undefined;
    sessionTemplate: TemplateObject | undefined;
    sessionPrehistoryTemplateName: string | undefined;
    tab: string;
    chatBotContext: ChatbotContext;
    notesProcessing: boolean;
    transcriptProcessing: boolean;
    preHistoryProcessingMessage: string | undefined;
    preHistoryFailureMessage: string | undefined;
    dischargeProcessing: boolean;
    preHistoryStreaming: boolean;
    notesStreaming: boolean;
    dischargeStreaming: boolean;
    notesStreamCompleted: boolean;
    notesLoading: string | undefined;
    dischargeLoading: string | undefined;
    preHistoryLoading: string | undefined;
    recordingLoading: string | undefined;
    transcriptLoading: string | undefined;
    loadingSessionInfo: string | undefined;
    loadingSessions: boolean;
    importingSessions: boolean;
    activeRecordingBackUp: boolean | undefined;
    activeRecordingExist: boolean | undefined;
    recordingCheckLoading: string | undefined;
    listContainerRef: any;
    scrollToItemRef: any;
    sessionFromParam: string | undefined;
    activeSessionUpdated: boolean;
    showChatbot: boolean;
    sortLatest: boolean;
    filterState:SessionStateEnum;
    sessionWithPetArrayMedicalNotes: Record<string, string> | undefined;
    sessionWithPetArrayDischargeNotes: Record<string, string> | undefined;
    multiPetSessionActive: boolean;
    petArrayChanged: boolean;
    handleSortLatest: () => void;
    handleFilterState: (value: string) => void;
    handleTabChange: (value: string) => void;
    handleSelectedPetChange: (petName: string) => void
    setShowChatBot: (value: boolean) => void;
    setActiveNotes: (value: string) => void;
    handleSavePatients: (pets: string[], petsUpdates?: {
        update: Record<string, string>,
        add: string[],
        remove: string[]
    }) => Promise<void>;
}   

interface HistoryContentContextType{
    language: string;
    activeNotes: string | undefined;
    activeDiagram: {[key:string]:string} | undefined;
    activeDiagramDescription: ToothInfo | undefined;
    activeAuditLogs: AuditLogs[] | undefined;
    didUserSaveNotesAfterLatestGeneration: boolean;
    notesSections: Record<string, string> | undefined;
    sessionWithPetArraySelectedMedicalNoteId: string | undefined;
    sessionWithPetArraySelectedDischargeNoteId: string | undefined;
    sessionWithPetArraySelectedName: string | undefined;
    displayActiveNotes: string
    medicalNotesEditCache: Record<string, string> | undefined
    dischargeNotesEditCache: Record<string, string> | undefined
    displayOriginalNotes: string | undefined
    activeDischarge: string | undefined
    displayActiveDischarge: string
    activeRecordingsAndTranscripts: RecordingTranscriptPair[] | undefined;
    anyRecordingsWithNoData: boolean | undefined;
    activeTranscript: string | undefined;
    activeTranscriptExists: boolean;
    activePreHistory: {[key:string]:string} | undefined;
    shouldShowPreHistoryMoreMessage: boolean;
    shouldShowPrehistoryScannedDocumentsMessage: boolean;
    activeRecording: string[] | undefined;
    template: TemplateObject | undefined;
    templateDischarge: TemplateObject | undefined;
    sessionWithPetArrayDiagrams: Record<string, {[key:string]:string}> | undefined;
    setActiveDiagram: (value: {[key:string]:string}) => void
    setMedicalNotesEditCache: (value: Record<string, string>) => void
    setDischargeNotesEditCache: (value: Record<string, string>) => void
    setDisplayActiveNotes: (value: string) => void
    setDisplayActiveDischarge: (value: string) => void
    setTemplate: (value: TemplateObject) => void
    setTemplateDischarge: (value: TemplateObject) => void
    setLanguage: (value: string) => void
}

interface HistoryActionsContextType {
    feedbackDischarge: boolean
    toggleSections: boolean
    toggleSectionsError: boolean
    handleMarkComplete: () => void
    handleAddToSession: () => void
    handleThumbsInput: (value: boolean, type: string) => void
    handleLanguageChange: (change:boolean) => void
    handleCreatePatient: (value: string, pets:string[], owner?:string, timestamp?:number, consent?:boolean) => Promise<void>
    processTranscript: (value: boolean, additionalInstructions?:string) => void
    processRecordings: (reprocess:boolean, sessionSpokenLanguage?:string, template?:TemplateObject) => void
    handleSaveOwner: () => void
    translateDischarge: () => void
    handleToggleSections: () => void
    handleNotesTemplateChange: (change:boolean) => void
    setRegenerateRecapModal: (value:boolean) => void
    setRegenRecapShouldUploadFilesBeOpen: (value:boolean) => void
    handleRegenerateRecordsRecap: (shouldUploadFilesBeOpen: boolean) => void
    handleDischargeTemplateChange: (change:boolean) => void
    handleSaveName: () => void
    handleSaveDate: () => void
    refreshActiveSession: () => Promise<void>
    refreshAuditLogs: (sessionObject:SessionObject) => void
    handleUserSearch: (value: MemberObject) => void
    selectSession: (value: SessionObject) => void
    generateDischarge: (regenerate:boolean, additionalInstructions?:string) => void
    handleDeleteVisit: () => Promise<void>
    handleDeleteRecap: () => Promise<void>
    refreshSessionObject: (sessionObject:SessionObject) => Promise<SessionObject | undefined>
    uploadActiveNotes: (notesToSave: string) => Promise<void>
    uploadActiveDischarge: (dischargeToSave: string) => Promise<void>
    uploadLocalRecap: (recapToSave: {[key:string]:string} | undefined) => Promise<void>
    importVisitsFromVetspire: () => void
    refreshVetspireVisit: () => Promise<string>
    importAppointmentsFromOnPremPims: () => void
    handleSelectedPetChange: (petName:string, noteId:string, type:"medical_note"|"discharge") => void
    handleSavePatients: (pets:string[], petUpdates?:{update:Record<string,string>, add:string[], remove:string[]}) => void
}

interface HistoryFiltersContextType {
    searchDate: DateValueType
    membersList: MemberObject[]
    userSearch: MemberObject | undefined;
    debouncedQuery: string | undefined;
    setSearchDate: (value: DateValueType) => void
    handleHistorySearch: (value: string) => void
}

interface HistoryAlertsContextType {
    deleteVisitModal: boolean;
    deleteRecapModal: boolean;
    feedbackModal: boolean;
    findReplaceModal: boolean;
    addCustomSpellingModal: boolean;
    highlightedText: string | undefined;
    setHighlightedText: (value: string | undefined) => void
    createVisitModal: boolean;
    feedbackModalSource: string;
    changeTemplate: boolean;
    regenerateRecapModal: boolean;
    regenRecapShouldUploadFilesBeOpen: boolean;
    changeDischargeTemplate: boolean;
    changeLanguage: boolean;
    notification: boolean;
    notificationTitle: string | undefined;
    notificationText: string | undefined;
    notificationLineColor: string | undefined;
    notificationIconColor: string | undefined;
    error: string | undefined;
    warning: string | undefined;
    uploadRecordingModal: boolean;
    setNotification: (value: boolean) => void
    setNotificationTitle: (value: string | undefined) => void
    setNotificationText: (value:string | undefined) => void
    notifyInProgress: (title: string, text?: string) => void,
    notifyCompleted: (title: string, text?: string) => void,
    clearNotification: () => void,
    setError: (value: string | undefined) => void
    setWarning: (value: string | undefined) => void
    setFeedbackModal: (value: boolean) => void
    setFindReplaceModal: (value: boolean) => void
    setAddCustomSpellingModal: (value: boolean) => void
    handleSelectionChange: (
        range: { index: number; length: number } | null,
        source: string,
        editor: UnprivilegedEditor
    ) => void
    getLoggingProperties: () => Record<string, string>
    setCreateVisitModal: (value: boolean) => void
    setDeleteVisitModal: (value: boolean) => void
    setDeleteRecapModal: (value: boolean) => void
    setUploadRecordingModal: (value: boolean) => void
}

type CombinedHistoryContextType = 
    HistoryStateContextType & 
    HistoryContentContextType & 
    HistoryActionsContextType & 
    HistoryFiltersContextType & 
    HistoryAlertsContextType;

const HistoryContext = createContext<CombinedHistoryContextType | undefined>(undefined);

export const useHistory = () => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error('useHistory must be used within a HistoryProvider');
  }
  return context;
};

interface HistoryProviderProps {
  children: ReactNode;
}

export const HistoryProvider: React.FC<HistoryProviderProps> = ({ children }) => {

        // General controls
        const [sessions, setSessions] = useState<SessionObject[]>([])
        const [filteredSessions, setFilteredSessions] = useState<SessionObject[]>([])
        const [availableSession, setAvailableSessions] = useState<boolean>(false)
        const [searchDate, setSearchDate] = useState<DateValueType>({
            startDate: new Date(),
            endDate: new Date()
        });        
        const [searchValue, setSearchValue] =  useState<string|undefined>(undefined)
        const [notesLoading, setNotesLoading] = useState<string|undefined>(undefined)
        const [recordingLoading, setRecordingLoading] = useState<string|undefined>(undefined)
        const [transcriptLoading, setTranscriptLoading] = useState<string|undefined>(undefined)
        const [dischargeLoading, setDischargeLoading] = useState<string|undefined>(undefined)
        const [preHistoryLoading, setPreHistoryLoading] = useState<string|undefined>(undefined)
        const [loadingSessions , setLoadingSessions] = useState<boolean>(true)
        const [importingSessions, setImportingSessions] = useState<boolean>(false)
        const [loadingSessionInfo, setLoadingSessionInfo] = useState<string|undefined>(undefined)
        const { getToken, orgId} = useAuth();
        const { user } = useUser()
        const { organization } = useOrganization()
        const  navigate = useNavigate()
        const { isImpersonation, useMetagraphEnabled } = useVetRec()
        const [error, setError] = useState<string|undefined>(undefined)
        const [warning, setWarning] = useState<string|undefined>(undefined)
        const [tab, setTab] = useState<string>("overview")
        const [userSearch, setUserSearch] = useState<MemberObject | undefined>({
            name: (user?.firstName && user?.lastName) ? user.firstName + " " + user.lastName : undefined,
            identifier: user?.primaryEmailAddress?.emailAddress ?? "",
            clerk_id: user?.id
        })
        const [feedbackModal, setFeedbackModal] = useState<boolean>(false)
        const [findReplaceModal, setFindReplaceModal] = useState<boolean>(false)
        const [addCustomSpellingModal, setAddCustomSpellingModal] = useState<boolean>(false)
        const [highlightedText, setHighlightedText] = useState<string | undefined>(undefined)
        const [highlightedTextBuffer, setHighlightedTextBuffer] = useState<string | undefined>(undefined)
        const [feedbackModalSource, setFeedbackModalSource] = useState<string>("")
        const [sortLatest, setSortLatest] = useState<boolean>(JSON.parse(localStorage.getItem('sortLatest') ?? "true"))
        const [filterState, setFilterState] = useState<SessionStateEnum>(SessionStateEnum.ALL)
    
        // Selected session
        const [activeSession, setActiveSession] = useState<SessionObject|undefined>(undefined)
        const activeSessionRef = useRef(activeSession);

        const evaluateShouldSessionUseMetagraph = (useMetagraphEnabled: boolean, session: SessionObject|undefined) => 
            (useMetagraphEnabled && session?.has_metagraph) || ((session?.pets ?? []).length >= 1)
        const shouldSessionUseMetagraph = useMemo(() => {
            return evaluateShouldSessionUseMetagraph(useMetagraphEnabled, activeSession)
        }, [activeSession, useMetagraphEnabled])
        
        const [activeRecordingsAndTranscripts, setActiveRecordingsAndTranscripts] = useState<RecordingTranscriptPair[] | undefined>(undefined)
        const [activeTranscript, setActiveTranscript] = useState<string|undefined>(undefined)
        const [activeNotes, setActiveNotes] = useState<string|undefined>(undefined)
        const [activeDiagramDescription, setActiveDiagramDescription] = useState<ToothInfo|undefined>(undefined)
        const [activeDiagram, setActiveDiagram] = useState<{[key:string]:string}|undefined>(undefined)
        const [activeAuditLogs, setActiveAuditLogs] = useState<AuditLogs[]>()
        const [activePreHistory, setActivePreHistory] = useState<{[key:string]:string} | undefined>(undefined)
        const [shouldShowPreHistoryMoreMessage, setShouldShowPreHistoryMoreMessage] = useState<boolean>(false)
        const [shouldShowPrehistoryScannedDocumentsMessage, setShouldShowPrehistoryScannedDocumentsMessage] = useState<boolean>(false)
        const [activeRecording, setActiveRecording] = useState<string[]|undefined>(undefined);
        const [activeDischarge, setActiveDischarge] = useState<string|undefined>(undefined)
        const [activeRecordingBackUp, setActiveRecordingBackUp] = useState<boolean|undefined>(false);
        const [activeRecordingExist, setActiveRecordingExist] = useState<boolean|undefined>(false);
        const [activeSessionUpdated, setActiveSessionUpdated] = useState<boolean>(false)
        const [recordingCheckLoading, setRecordingCheckLoading] = useState<string|undefined>(undefined)
        const [transcriptProcessing, setTranscriptProcessing] = useState<boolean>(false)
        const [notesProcessing, setNotesProcessing] = useState<boolean>(false)
        const [dischargeProcessing, setDischargeProcessing] = useState<boolean>(false)
        const [preHistoryProcessingMessage, setPreHistoryProcessingMessage] = useState<string | undefined>(undefined)
        const [preHistoryFailureMessage, setPreHistoryFailureMessage] = useState<string | undefined>(undefined)
        const [preHistoryStreaming, setPreHistoryStreaming] = useState<boolean>(false)
        const [displayActiveNotes, setDisplayActiveNotes] = useState('');
        const [displayOriginalNotes, setDisplayOriginalNotes] = useState<string | undefined>(undefined);
        const [displayActiveDischarge, setDisplayActiveDischarge] = useState('');
        const [feedbackDischarge, setFeedbackDischarge] = useState<boolean>(false)
        const [toggleSections, setToggleSections] = useState<boolean>(false)
        const [toggleSectionsError, setToggleSectionsError] = useState<boolean>(false)
        const [notesSections, setNotesSections] = useState<Record<string, string>>()
        const [notification, setNotification] = useState<boolean>(false)
        const [notificationTitle, setNotificationTitle] = useState<string>()
        const [notificationText, setNotificationText] = useState<string>()
        const [notificationLineColor, setNotificationLineColor] = useState<string>()
        const [notificationIconColor, setNotificationIconColor] = useState<string>()
        const [notesStreaming, setNotesStreaming] = useState<boolean>(false)
        const [dischargeStreaming, setDischargeStreaming] = useState<boolean>(false)
        const [notesStreamCompleted, setNotesStreamCompleted] = useState<boolean>(false)
        const [sessionTemplate, setSessionTemplate] = useState<TemplateObject>()
        const [sessionPrehistoryTemplateName, setSessionPrehistoryTemplateName] = useState<string | undefined>(undefined)
        const [membersList, setMembersList] = useState<MemberObject[]>([])
        
        const activeTranscriptExists = useMemo(() => {
            return shouldSessionUseMetagraph 
                ? activeRecordingsAndTranscripts !== undefined && activeRecordingsAndTranscripts.length > 0 && activeRecordingsAndTranscripts.every(pair => pair.transcript_status === "Completed" || pair.transcript_status === "FailedNoData")
                : activeTranscript !== undefined
        }, [shouldSessionUseMetagraph, activeTranscript, activeRecordingsAndTranscripts])

        const anyRecordingsWithNoData = useMemo(() => {
            return !!activeRecordingsAndTranscripts?.some(pair => pair.transcript_status === "FailedNoData");
        }, [activeRecordingsAndTranscripts])
        
        //Scroll regs
        const { search } = useLocation();
        const listContainerRef = useRef<any>(null);
        const scrollToItemRef = useRef<any>(null);
        const [sessionFromParam, setSessionFromParam] = useState<string|undefined>(undefined)
    
        // Template Variables
        const [template, setTemplate] = useState<TemplateObject>()
        const [templateDischarge, setTemplateDischarge] = useState<TemplateObject>()
        const [language, setLanguage] = useState<string>('English')
        const [templateDefinition, setTemplateDefinition] = useState<SectionType[]>()
    
        // Modals
        const [deleteVisitModal, setDeleteVisitModal] = useState<boolean>(false)
        const [deleteRecapModal, setDeleteRecapModal] = useState<boolean>(false)
        const [createVisitModal, setCreateVisitModal] = useState<boolean>(false)
        const [changeTemplate, setChangeTemplate] = useState<boolean>(false)
        const [regenerateRecapModal, setRegenerateRecapModal] = useState<boolean>(false)
        const [regenRecapShouldUploadFilesBeOpen, setRegenRecapShouldUploadFilesBeOpen] = useState<boolean>(false)
        const [changeDischargeTemplate, setChangeDischargeTemplate] = useState<boolean>(false)
        const [changeLanguage, setChangeLanguage] = useState<boolean>(false)
        const [uploadRecordingModal, setUploadRecordingModal] = useState<boolean>(false)

        const didUserSaveNotesAfterLatestGeneration = useMemo(() => {
            const visitNotesSavedIndex = activeAuditLogs?.findIndex(log => log.action.toUpperCase() === 'VISIT_NOTES_SAVED');
            const visitLastProcessedIndex = activeAuditLogs?.findIndex(log => ['VISIT_PROCESSED', 'VISIT_REPROCESSED', 'VISIT_NOTES_REPROCESSED'].includes(log.action.toUpperCase()));

            return visitNotesSavedIndex !== undefined && visitNotesSavedIndex !== -1 && visitLastProcessedIndex !== undefined && visitNotesSavedIndex < visitLastProcessedIndex;
        }, [activeAuditLogs])
    
        // Chatbot
        const [showChatbot, setShowChatBot] = useState(false)
        const chatBotContext:ChatbotContext = useMemo(() => {
            return tab === "prehistory" ? (activeSession?.status.prehistory === "Completed" ? ChatbotContext.Recap : ChatbotContext.None) : (activeSession?.status.notes === "Completed" ? ChatbotContext.Notes : (activeSession?.status.prehistory === "Completed" ? ChatbotContext.Recap : ChatbotContext.None))
        }, [tab, activeSession])


        // Pet Array
        const multiPetSessionActive = useMemo(() => {
            if(activeSession){
                const hasPetsArray = activeSession.pets && activeSession.pets.length > 0;
                return hasPetsArray && activeSession.pets.length > 1;
            }
            return false;
        }, [activeSession])

        const petArrayChanged = useMemo(() => {
            if (activeSession?.pet_to_soap_node_mapping && activeSession.pets) {
                // Check if all "pets" are present in the pet_to_soap_node_mapping
                const allPetsPresent = activeSession.pets.every(petName => 
                    Object.prototype.hasOwnProperty.call(activeSession.pet_to_soap_node_mapping, petName)
                );
                return !allPetsPresent;
            }
            return false;
        }, [activeSession]);
        
        const [sessionWithPetArraySelectedName, setSessionWithPetArraySelectedName] = useState<string | undefined>(undefined);
        
        const [sessionWithPetArrayMedicalNotes, setSessionWithPetArrayMedicalNotes] = useState<Record<string, string> | undefined>(undefined);
        const [sessionWithPetArrayDischargeNotes, setSessionWithPetArrayDischargeNotes] = useState<Record<string, string> | undefined>(undefined);
        const [sessionWithPetArrayDiagrams, setSessionWithPetArrayDiagrams] = useState<Record<string, {[key:string]:string}> | undefined>(undefined);

        const [sessionWithPetArraySelectedMedicalNoteId, setSessionWithPetArraySelectedMedicalNoteId] = useState<string | undefined>(undefined);
        const [sessionWithPetArraySelectedDischargeNoteId, setSessionWithPetArraySelectedDischargeNoteId] = useState<string | undefined>(undefined);
        
        const [medicalNotesEditCache, setMedicalNotesEditCache] = useState<Record<string, string>>()
        const [dischargeNotesEditCache, setDischargeNotesEditCache] = useState<Record<string, string>>()
        
        function resetAllPetArrayFields() {
            // Reset selected pet info
            setSessionWithPetArraySelectedName(undefined);
            
            // Reset medical notes
            setSessionWithPetArrayMedicalNotes(undefined);
            setSessionWithPetArraySelectedMedicalNoteId(undefined);
            
            // Reset discharge notes
            setSessionWithPetArrayDischargeNotes(undefined);
            setSessionWithPetArraySelectedDischargeNoteId(undefined);
            
            // Reset diagrams
            setSessionWithPetArrayDiagrams(undefined);

            // Reset Cache Edits
            setMedicalNotesEditCache(undefined);
            setDischargeNotesEditCache(undefined);
        }

        function resetDischargePetArrayFields() {
            setSessionWithPetArrayDischargeNotes(undefined);
            setSessionWithPetArraySelectedDischargeNoteId(undefined);
            setDischargeNotesEditCache(undefined);
        }


        // Handle Pet Selection
        function handleSelectedPetChange(newPet: string) {
            setSessionWithPetArraySelectedName(newPet.trim());
          
            const medNoteId = activeSession?.pet_to_soap_node_mapping?.[newPet.trim()];
            const dischargeId = activeSession?.pet_to_discharge_node_mapping?.[newPet.trim()];
            setSessionWithPetArraySelectedMedicalNoteId(medNoteId);
            setSessionWithPetArraySelectedDischargeNoteId(dischargeId);
          
            if (medNoteId) {
                const notes = sessionWithPetArrayMedicalNotes?.[medNoteId] ?? ""
                setActiveNotes(notes);
                // If the user has edited the notes, use the edited notes, otherwise use the preprocessed notes
                const finalDisplayNotes = medicalNotesEditCache?.[medNoteId] ?? preprocessText(notes)
                setDisplayActiveNotes(finalDisplayNotes);
                // Set the diagram for the selected pet
                
                const diagram = sessionWithPetArrayDiagrams?.[medNoteId]
                setActiveDiagram(diagram)
                setActiveDiagramDescription(diagram ? parseDentalInfo(notes) : undefined)
            }
          
            if (dischargeId) {
                setActiveDischarge(sessionWithPetArrayDischargeNotes?.[dischargeId] ?? "");
                // If the user has edited the discharge notes, use the edited notes, otherwise use the preprocessed notes
                const finalDisplayDischarge = dischargeNotesEditCache?.[dischargeId] ?? preprocessDischargeText(sessionWithPetArrayDischargeNotes?.[dischargeId] ?? "")
                setDisplayActiveDischarge(finalDisplayDischarge);
            }
          }


          useEffect(() => {
            if (
              activeSession &&
              activeSession.pet_to_soap_node_mapping &&
              !sessionWithPetArraySelectedName // user hasn't manually picked a pet yet
            ) {
              // pick the first
              const firstPet = Object.keys(activeSession.pet_to_soap_node_mapping)[0];
              if (firstPet) {
                handleSelectedPetChange(firstPet);
              }
            }
          }, [
            activeSession,
            sessionWithPetArrayMedicalNotes,
          ]);

        const { removeNotesSubscriptions, removePreHistorySubscriptions, getCurrentStateOfNotes, getCurrentStateOfPreHistory, subscribeToNotesStream, subscribeToPreHistoryStream, getCurrentStateOfDischarge, subscribeToDischargeStream, removeDischargeSubscriptions, uploadToSupabaseSignedURL } = useSupabase()
    
        function getLoggingProperties() {
            return {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
            }
        }

        function handleSortLatest(){
            setSortLatest(!sortLatest)
            localStorage.setItem('sortLatest', JSON.stringify(!sortLatest));  
        }
    
        function handleUserSearch(member: MemberObject){
            setUserSearch(member)
        }

        function handleFilterState(filter:string){
            setFilterState(filter as SessionStateEnum)
        }
    
        useEffect(() => {
            setFilteredSessions(filterSessions(sessions, userSearch, sortLatest, filterState))
        }, [sessions, sortLatest, userSearch, filterState])

        async function getOrganizationMember(){
            if(orgId){
                let members = await organization?.getMemberships({pageSize:500})
                let memberList: MemberObject[] | undefined = members?.data.map((member) => { 
                    let name 
                    if(member.publicUserData.firstName && member.publicUserData.lastName){
                        name = member.publicUserData.firstName + " " + member.publicUserData.lastName
                    }
                    else{
                        name = undefined
                    }
                    return {
                        name: name,
                        identifier: member.publicUserData.identifier,
                        clerk_id: member.publicUserData.userId
                    }
                })
                if(memberList) setMembersList(memberList)
            }
            else{
                let name
                if(user?.firstName && user?.lastName){
                    name = user?.firstName + " " + user?.lastName
                } else {
                    name = undefined
                }
                setMembersList([{
                    name: name,
                    identifier: user?.primaryEmailAddress?.emailAddress ?? "",
                    clerk_id: user?.id
                }])
            }
        }
    
        useEffect(() => {
            activeSessionRef.current = activeSession;
        }, [activeSession]);

        const debouncedQuery = useDebounce(searchValue, 500); // 500ms delay
    
        useEffect(() => {
            // Ensure that all required values are available before fetching sessions.
            if (searchDate?.startDate && searchDate?.endDate) {
              getSessions()
            }
          }, [orgId, debouncedQuery, searchDate]);
    
        useEffect(() => {
            async function queryParamProcessingForSession(){
                if(sessions && !loadingSessions){
                    const queryParams = new URLSearchParams(search);
                    const sessionId = queryParams.get('session_id');
                    if(sessionId){
                        setSessionFromParam(sessionId ?? undefined)
                        let session = sessions.find(x => x.id === sessionId)
                        if(session)
                        {
                            selectSession(session)
                        } 
                        else{
                            // If no session is found, get it, but do not add it to the list
                            await fetchSessionById(sessionId ?? "", await getToken({template:"supabase"}) ?? "").then((session) => {
                                if(session && session.status.general !== "Deleted"){
                                    selectSession(session)
                                }
                            })
                        }
                    }
                }  
            }   
            queryParamProcessingForSession()
        }, [search, loadingSessions])

        useEffect(() => {
            const queryParams = new URLSearchParams(search);
            const tab = queryParams.get('tab');
            setTab(tab ?? "overview") 
        }, [])

        useEffect(() => {
            getOrganizationMember()
        }, [orgId])
    
        useEffect(() => {
            setTimeout(()=>{
                if (sessions && scrollToItemRef.current && listContainerRef.current && !isMobileDevice()) {
                    scrollToItemRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 500)
        }, [sessionFromParam]);
    
        useEffect(() => {
            let interval: string | number | NodeJS.Timeout | undefined;
        
            if (activeSession && (transcriptProcessing || notesProcessing || dischargeProcessing || preHistoryProcessingMessage)) {
              // Start the interval if any of the conditions is true
              interval = setInterval(() => {
                // Refresh your data here... Warning: this is an async call we do not await 
                refreshActiveSession()
              }, 3000); // Set the time as needed
            } else {
              // If neither condition is true, clear the interval
              if (interval) {
                clearInterval(interval);
              }
            }
        
            return () => {
              // Clear the interval when the component is unmounted or conditions change
              if (interval) {
                clearInterval(interval);
              }
            };
        }, [transcriptProcessing, notesProcessing, dischargeProcessing, preHistoryProcessingMessage]);

        const getSearchTimestamp = () => {
            let start_date = new Date();
            let end_date = new Date();
            
            if (searchDate?.startDate) {
                if(searchDate.startDate instanceof Date){
                    start_date = new Date(searchDate.startDate.getTime())
                }
                else{
                    start_date = new Date(searchDate.startDate  + 'T00:00:00')
                }
            }
            
            if (searchDate?.endDate) {
                if(searchDate.endDate instanceof Date){
                    end_date = new Date(searchDate.endDate.getTime())
                }
                else{
                    end_date = new Date(searchDate.endDate  + 'T23:59:59')
                }
            }
            start_date.setHours(0, 0, 0, 0);
            end_date.setHours(23, 59, 59, 999);
            let start_date_in_float = Math.floor(start_date.getTime() / 1000)
            let end_date_in_float = Math.floor(end_date.getTime() / 1000)
            return {start_date_in_float, end_date_in_float}
        }
    
        const getSessions = async (loading:boolean = true) => {
            try{
                if(loading) setLoadingSessions(true)
                if(debouncedQuery && debouncedQuery.length > 0){
                    let temp_sessions : SessionObject[] = await fetchSessionsName(await getToken({template:"supabase"}) ?? '', debouncedQuery)
                    let nonCancelledSessions : SessionObject[] = filterCancelledSessions(temp_sessions)
                    setAvailableSessions(nonCancelledSessions.length !== 0)
                    setSessions(nonCancelledSessions)
                    setFilteredSessions(filterSessions(nonCancelledSessions, userSearch, sortLatest, filterState))
                    if(loading) setLoadingSessions(false)
                    return nonCancelledSessions
                }
                else {
                    let {start_date_in_float, end_date_in_float} = getSearchTimestamp()
                    let temp_sessions : SessionObject[] = await fetchSessionsDate(await getToken({template:"supabase"}) ?? '', start_date_in_float.toString(), end_date_in_float.toString())
                    let nonCancelledSessions : SessionObject[] = filterCancelledSessions(temp_sessions)
                    setAvailableSessions(nonCancelledSessions.length !== 0)
                    setSessions(nonCancelledSessions)
                    setFilteredSessions(filterSessions(nonCancelledSessions, userSearch, sortLatest, filterState))
                    if(loading) setLoadingSessions(false)
                    return nonCancelledSessions
                }
            }
            catch{
                if(loading) setLoadingSessions(false)
                setError("Had an issue loading visits. Refresh the page.")
            }
        }

        async function refreshAuditLogs(sessionObject:SessionObject){
            let token = await getToken({template:"supabase"}) ?? ""
            let auditLogs:AuditLogs[] = (await GetAuditLogs(sessionObject.id, token))['logs']
            forEach(auditLogs, (log) => {
                log.actor = membersList.find(x => x.identifier === log.actor)?.name ?? log.actor
            })
            // Sort the logs by timestamp
            auditLogs.sort((a, b) => b.timestamp - a.timestamp)
            setActiveAuditLogs(auditLogs)
        }
    
        async function refreshNotes(sessionObject:SessionObject, refresh:boolean){
            if(sessionObject.status.notes === "Completed"){
                await removeNotesSubscriptions()
                let temp_notes = undefined
                let pet_array_temp_notes: Record<string, string> = {}
                // Select the first pet's document when refreshing notes
                const selectedPetDocumentId = sessionObject.pet_to_soap_node_mapping ? Object.values(sessionObject.pet_to_soap_node_mapping)[0] : ""
                if(sessionObject.pets && sessionObject.pets.length >= 1 && sessionObject.pet_to_soap_node_mapping){
                    pet_array_temp_notes = await GetNotesForSessionWithPetArray(sessionObject.id, await getToken({template:"supabase"}) ?? "")
                    setSessionWithPetArrayMedicalNotes(pet_array_temp_notes)
                    temp_notes = pet_array_temp_notes[selectedPetDocumentId] ?? ""
                } else {
                    temp_notes = await GetNotes(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                }
                let temp_diagram = undefined
                if(sessionObject.diagram){
                    if (sessionObject.pets && sessionObject.pets.length >= 1 && sessionObject.pet_to_soap_node_mapping){
                        let diagramsForSessionWithPetArray = await GetDiagramForSessionWithPetArray(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                        setSessionWithPetArrayDiagrams(diagramsForSessionWithPetArray)   
                        temp_diagram = diagramsForSessionWithPetArray[selectedPetDocumentId] ?? {}
                    }
                    else {
                        temp_diagram = await GetDiagram(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                    }
                    
                }
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveNotes(temp_notes ?? "")
                setActiveDiagram(temp_diagram)
                setActiveDiagramDescription(temp_diagram ? parseDentalInfo(temp_notes ?? "") : undefined)
                setDisplayActiveNotes(preprocessText(temp_notes ?? ""))

                // Save a copy of the notes for the edit cache
                if(sessionObject.pet_to_soap_node_mapping){
                    let petDisplayActiveNotesTemp: Record<string, string> = {};
                    Object.entries(pet_array_temp_notes ?? {}).forEach(([noteId, note]) => {
                        petDisplayActiveNotesTemp[noteId] = preprocessText(note)
                    })
                    setMedicalNotesEditCache(petDisplayActiveNotesTemp)
                }
                setNotesStreaming(false)
                setNotesProcessing(false)

                if (isImpersonation) {
                    // TODO: need to save the original for each note_id
                    let temp_notes_original = await GetOriginalNotes(sessionObject.id, await getToken({template:"supabase"}) ?? "")
                    setDisplayOriginalNotes(temp_notes_original ? preprocessText(temp_notes_original) : undefined)
                }
            }
            else if(sessionObject.status.notes === "Streaming"){
                await removeNotesSubscriptions()
                let temp_notes = await getCurrentStateOfNotes(sessionObject.id)
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveNotes(temp_notes ?? "")
                setDisplayActiveNotes(preprocessText(temp_notes ?? ""))
                setDisplayOriginalNotes(undefined)
                
                setNotesStreaming(true)
                setNotesProcessing(true)
                await subscribeToNotesStream(sessionObject.id, (notes:string|undefined, diagram:string|undefined) => {
                    if(notes){
                        if (sessionObject.id !== activeSessionRef.current?.id) return;
                        setActiveDiagram(diagram ? JSON.parse(diagram) : undefined)
                        setActiveDiagramDescription(diagram ? parseDentalInfo(notes) : undefined)
                        setActiveNotes(notes)
                        setDisplayActiveNotes(preprocessText(notes))
                        setDisplayOriginalNotes(undefined)
                    }
                }, () => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setNotesStreaming(false)
                })
            }
            else if(sessionObject.status.notes === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveNotes(undefined)
                setNotesProcessing(true)
                resetAllPetArrayFields()
            }
            else if(sessionObject.status.notes === "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveDiagram(undefined);
                setActiveNotes(undefined);
                setNotesProcessing(false);
                setNotesStreaming(false);
                resetAllPetArrayFields();
                if(refresh){
                    setError("We encountered an issue processing the notes. If error persists contact us at support@vetrec.io.")
                }
            }
            else{
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveDiagram(undefined);
                setActiveNotes(undefined);
                setDisplayActiveNotes("");
                setDisplayOriginalNotes(undefined);
                resetAllPetArrayFields();
            }
            if(!refresh) setNotesLoading(undefined);
        }

        async function refreshRecordingsAndTranscripts(sessionObject:SessionObject, refresh:boolean) {
            try{
                if (!(evaluateShouldSessionUseMetagraph(useMetagraphEnabled, sessionObject))) return;
                if (sessionObject.id !== activeSessionRef.current?.id) return;

                const response = await GetRecordingsAndTranscripts(sessionObject.id, await getToken({template:"supabase"}) ?? "")
                const pairs: RecordingTranscriptPair[] = response.pairs ?? []
                setActiveRecordingsAndTranscripts(pairs)

                const anyTranscriptsNotStarted = pairs.some(pair => pair.transcript_status === "Not Started")
                const anyTranscriptsProcessing = pairs.some(pair => pair.transcript_status === "Processing")
                const anyTranscriptsCompleted = pairs.some(pair => pair.transcript_status === "Completed")
                const anyTranscriptsFailed = pairs.some(pair => pair.transcript_status === "Failed")
                const anyTranscriptsFailedNoData = pairs.some(pair => pair.transcript_status === "FailedNoData")
                const anyTranscriptsStarted = anyTranscriptsProcessing || anyTranscriptsCompleted || anyTranscriptsFailed

                // If any transcript is processing, then transcriptProcessing is true. This controls refresh.
                setTranscriptProcessing(anyTranscriptsProcessing)

                // Only updated if at least one other transcript is past NotStarted
                setActiveSessionUpdated(anyTranscriptsStarted && (anyTranscriptsNotStarted))

                if(refresh && anyTranscriptsFailed){
                    setError("We encountered an issue processing the transcript. If error persists contact us at support@vetrec.io.")
                }
            } catch (error) {
                setError("An error occurred while fetching recordings and transcripts. Please refresh the page.")
                setActiveRecordingsAndTranscripts(undefined)
                setTranscriptProcessing(false)
                setActiveSessionUpdated(false)
            } finally {
                if (!refresh) setTranscriptLoading(undefined)
            }
        }
    
        async function refreshTranscript(sessionObject:SessionObject, refresh:boolean){
            // This refresh can be skipped if metagraph is enabled
            if (evaluateShouldSessionUseMetagraph(useMetagraphEnabled, sessionObject)) return;

            if(sessionObject.status.transcript === "Completed"){
                let transcript = await GetTranscript(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                if (transcript.length === 0) transcript = "No sound detected."
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setTranscriptProcessing(false)
                setActiveTranscript(transcript ?? "")
                setActiveSessionUpdated(false)
            }
            else if(sessionObject.status.transcript === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveTranscript(undefined)
                setTranscriptProcessing(true)
                setActiveSessionUpdated(false)
            }
            else if(sessionObject.status.transcript === "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveTranscript(undefined);
                setTranscriptProcessing(false);
                if(refresh){
                    setError("We encountered an issue processing the transcript. If error persists contact us at support@vetrec.io.")
                }
            }
            else if (sessionObject.status.transcript === "Updated"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveTranscript(undefined)
                setActiveSessionUpdated(true)
            }
            else{
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveTranscript(undefined)
            }
            if(!refresh) setTranscriptLoading(undefined);
        }
    
        async function refreshDischarge(sessionObject:SessionObject, refresh:boolean){
            if(sessionObject.status.discharge === "Completed"){
                await removeDischargeSubscriptions()
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                // Get Temps
                let temp_discharge_notes = undefined;
                let pet_array_temp_discharge_notes : Record<string, string> = {}
                
                // Simplified but still robust selection of discharge note ID
                let selectedPetDischargeNoteId = "";
                
                if (sessionObject.pet_to_discharge_node_mapping) {
                    // Try to use current selected pet, otherwise use first pet
                    const validSelectedPet = sessionWithPetArraySelectedName && 
                                            sessionObject.pet_to_discharge_node_mapping[sessionWithPetArraySelectedName];
                    
                    if (validSelectedPet) {
                        selectedPetDischargeNoteId = sessionObject.pet_to_discharge_node_mapping[sessionWithPetArraySelectedName];
                    } else if (Object.keys(sessionObject.pet_to_discharge_node_mapping).length > 0) {
                        const firstPetName = Object.keys(sessionObject.pet_to_discharge_node_mapping)[0];
                        selectedPetDischargeNoteId = sessionObject.pet_to_discharge_node_mapping[firstPetName];
                        
                        // Update selected pet if needed
                        if (firstPetName !== sessionWithPetArraySelectedName) {
                            handleSelectedPetChange(firstPetName);
                        }
                    }
                }
                
                if (sessionObject.pets && sessionObject.pets.length >= 1 && sessionObject.pet_to_discharge_node_mapping){
                    pet_array_temp_discharge_notes = await GetDischargeForSessionWithPetArray(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                    setSessionWithPetArrayDischargeNotes(pet_array_temp_discharge_notes)
                    temp_discharge_notes = pet_array_temp_discharge_notes[selectedPetDischargeNoteId] ?? ""
                }
                else {
                    temp_discharge_notes = await GetDischarge(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                }
                
                setDischargeProcessing(false)
                setDischargeStreaming(false)
                setActiveDischarge(temp_discharge_notes ?? "")
                setDisplayActiveDischarge(preprocessDischargeText(temp_discharge_notes ?? ""))

                // Save a copy of the discharge notes for edit cache
                if (sessionObject.pet_to_discharge_node_mapping) {
                    let petDisplayActiveDischargeTemp: Record<string, string> = {};
                    Object.entries(pet_array_temp_discharge_notes ?? {}).forEach(([noteId, note]) => {
                        petDisplayActiveDischargeTemp[noteId] = preprocessDischargeText(note)
                    })
                   setDischargeNotesEditCache(petDisplayActiveDischargeTemp)
                }

            }
            else if(sessionObject.status.discharge === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setDischargeProcessing(true)
                resetDischargePetArrayFields()
            }
            else if(sessionObject.status.discharge === "Streaming"){
                await removeDischargeSubscriptions()
                let temp_notes = await getCurrentStateOfDischarge(sessionObject.id)
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveDischarge(temp_notes ?? "Writing document...")
                setDisplayActiveDischarge(preprocessDischargeText(temp_notes ?? "Writing document..."))
                setDischargeStreaming(true)
                setDischargeProcessing(true)
                await subscribeToDischargeStream(sessionObject.id, (value:string) => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setActiveDischarge(value ?? "")
                    setDisplayActiveDischarge(preprocessDischargeText(value ?? ""))
                }, () => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setDischargeStreaming(false)
                })
            }
            else if(sessionObject.status.discharge === "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveDischarge(undefined);
                resetDischargePetArrayFields();
            }
            if(!refresh) setDischargeLoading(undefined);
        }
    
        async function refreshRecordingsExist(sessionObject:SessionObject){
            if(sessionObject.status.recording === "Completed" || sessionObject.status.transcript === "Completed" || sessionObject.status.notes === "Completed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveRecordingBackUp(true)
                setActiveRecordingExist(true)
                setRecordingCheckLoading(undefined)
            }
            else if(sessionObject.status.recording === "Processing" || sessionObject.status.recording === "Recording" || sessionObject.status.recording === "Updated"){
                let token = await getToken({template:"supabase"}) ?? ""
    
                // Check recordings
                // If we already have a transcript or notes, means we have recordings, so don't check.
                let check_recordings
                if (sessionObject.status.recording_files || sessionObject.status.recording_backup) {
                    check_recordings = {
                        recordings: sessionObject.status.recording_files ?? false,
                        backup: sessionObject.status.recording_backup ?? false
                    };
                } else if (sessionObject.status.transcript === "Completed" || sessionObject.status.notes === "Completed") {
                    check_recordings = {
                        recordings: true,
                        backup: true
                    };
                } else {
                    check_recordings = await CheckRecordings(sessionObject.id, token);
                }
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveRecordingBackUp(check_recordings.backup)
                setActiveRecordingExist(check_recordings.recordings)
                setRecordingCheckLoading(undefined)
            } else {
                setActiveRecordingBackUp(false)
                setActiveRecordingExist(false)
                setRecordingCheckLoading(undefined)
            }
        }

        useEffect(() => {
            if(activeRecordingExist && tab === "transcript" && !activeRecording){
                if(activeSessionRef.current){
                    loadRecordings(activeSessionRef.current)
                }
            }
        }, [activeRecordingExist, tab, activeRecording])
    
        async function loadRecordings(sessionObject:SessionObject){
            if (evaluateShouldSessionUseMetagraph(useMetagraphEnabled, sessionObject)) return;

            setRecordingLoading("Fetching Recordings 🎙️...")
            let token = await getToken({template:"supabase"}) ?? ""
            let recordingData = await GetRecordings(sessionObject.id, token)
            if (sessionObject.id === activeSessionRef.current?.id) {
                setActiveRecording(recordingData.urls)
            };
            setRecordingLoading(undefined)
        }

        function setPreHistoryFailure(showPopUpFailureMessage:boolean, popUpFailureMessage: string, staticFailureMessage: string) {
            setPreHistoryProcessingMessage(undefined)
            setPreHistoryFailureMessage(staticFailureMessage)
            if(showPopUpFailureMessage){
                setError(popUpFailureMessage)
            }
        }

        function parsePreHistoryJson(sessionObject:SessionObject, preHistory: string): {[key:string]:string} {
            return JSON.parse(preHistory ?? "{}");
        }

        function isWithinLastNHours(timestamp: number, lookback: number): boolean {
            // Get the current Unix timestamp in seconds
            const currentTimestamp = Math.floor(Date.now() / 1000);
            // Calculate the timestamp for N hours ago
            const lookbackHoursAgo = currentTimestamp - (lookback * 60 * 60);
            // Check if the given timestamp is within the last N hours
            return timestamp >= lookbackHoursAgo;
        }
    
        async function refreshPreHistory(sessionObject:SessionObject, refresh:boolean){
            if(!refresh) setPreHistoryLoading("Fetching Records Recap 📄...")
            
            setShouldShowPreHistoryMoreMessage(false) // reset
            setShouldShowPrehistoryScannedDocumentsMessage(false)

            if(sessionObject.status.prehistory === "Completed"){
                let preHistory = await GetPreHistory(sessionObject.id, await getToken({template:"supabase"}) ?? "")
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                const summary: {[key:string]:string} = parsePreHistoryJson(sessionObject, preHistory)
                setPreHistoryProcessingMessage(undefined)
                setPreHistoryFailureMessage(undefined)
                setActivePreHistory(summary)
                setShouldShowPreHistoryMoreMessage(isWithinLastNHours(sessionObject.modified_timestamp, 8))
            }
            else if(sessionObject.status.prehistory === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryProcessingMessage("Processing prior records")
                setPreHistoryFailureMessage(undefined)
                setActivePreHistory(undefined)
            }
            else if(sessionObject.status.prehistory === "Streaming"){
                await removePreHistorySubscriptions()
                let value = await getCurrentStateOfPreHistory(sessionObject.id)
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                const summary: {[key:string]:string} = parsePreHistoryJson(sessionObject, value)
                setActivePreHistory(summary)
                setPreHistoryStreaming(true)
                setPreHistoryProcessingMessage("Writing summary. Feel free to navigate away if you need to.")
                setPreHistoryFailureMessage(undefined)
                await subscribeToPreHistoryStream(sessionObject.id, (value:string) => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    if (value) {
                        const summary: {[key:string]:string} = parsePreHistoryJson(sessionObject, value)
                        setActivePreHistory(summary)    
                    }
                }, () => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setPreHistoryStreaming(false)
                })
            }
            else if (sessionObject.status.prehistory === "Chunking" || sessionObject.status.prehistory === "Merging" || sessionObject.status.prehistory === "ProcessingImages") {
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActivePreHistory(undefined)

                if (sessionObject.status.prehistory === "Chunking") {
                    setPreHistoryProcessingMessage("📖 Reading through all your records.")
                } else if (sessionObject.status.prehistory === "ProcessingImages") {
                    setPreHistoryProcessingMessage("👀 We've detected scanned records, we're reading through them now, this might take a bit longer.")
                    setShouldShowPrehistoryScannedDocumentsMessage(true)
                } else {
                    const num_merges_required = sessionObject.prehistory_metadata?.num_merges_required
                    const time_estimate = num_merges_required ? num_merges_required * 2 : undefined
                    setPreHistoryProcessingMessage(`🕐 Looks like you uploaded a lot of records!${time_estimate ? ` We should have your summary within ${time_estimate} minute${time_estimate > 1 ? "s" : ""}.` : " This may take a few minutes."}`)
                }
            }
            else if(sessionObject.status.prehistory === "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryFailure(refresh, "We encoutered an issue fetching your Records Recap. Please try again.", "There was an issue summarizing your records. Please try again.");
            }
            else if (sessionObject.status.prehistory === "FailedNoData") {
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryFailure(refresh, "We encoutered an issue fetching your Records Recap.", "Looks like there was no records to summarize!");
            }
            else if (sessionObject.status.prehistory === "FailedTooMuchData") {
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryFailure(refresh, "We encoutered an issue fetching your Records Recap.", "Looks like you gave us too many records to summarize! Note: Support for PDFs more than 150 pages is still in beta.");
            }
            else if (sessionObject.status.prehistory === "FailedTooManyPagesWithImages") {
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryFailure(refresh, "We encountered an issue with your Records Recap.", "We noticed you uploaded a scanned document with images that's over 100 pages. For help with larger documents, please reach out to us at support@vetrec.io - we're happy to assist!");
            }
            else {
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActivePreHistory(undefined)
                setPreHistoryFailureMessage(undefined)
            }
            if(!refresh) setPreHistoryLoading(undefined);
        }

        const refreshSessionObject = async (session: SessionObject) => {
            const token = await getToken({ template: "supabase" }) ?? "";
            const temp_session: SessionObject | undefined = await fetchSessionById(session.id, token);
            
            if (temp_session) {
                setSessions(prevSessions => {
                    const updatedSessions = prevSessions.map(session => 
                        session.id === temp_session.id ? temp_session : session
                    );
                    return updatedSessions;
                });
                
                setFilteredSessions(prevSessions => {
                    const updatedSessions = prevSessions.map(session => 
                        session.id === temp_session.id ? temp_session : session
                    );
                    return filterSessions(updatedSessions, userSearch, sortLatest, filterState);
                });

                setActiveSession(temp_session);
            }
            
            return temp_session;
        };
    
        const refreshActiveSession = async () => {
            if (activeSessionRef.current) {
                const prevSession = activeSessionRef.current;
                let temp_session : SessionObject | undefined = await refreshSessionObject(prevSession)
                if (temp_session && temp_session.id === activeSessionRef.current?.id) {
                    const refreshTasks = [];
                    
                    refreshTasks.push(refreshAuditLogs(temp_session));
                    if(!sessionTemplate){
                        refreshTasks.push(fetchTemplateForSession(temp_session))
                    }
                    if (!sessionPrehistoryTemplateName) {
                        refreshTasks.push(fetchPreHistoryTemplateNameForSession(temp_session))
                    }
                    if (temp_session.status.notes !== prevSession.status.notes) {
                        refreshTasks.push(refreshNotes(temp_session, true));
                    }
                    if (temp_session.status.prehistory !== prevSession.status.prehistory) {
                        refreshTasks.push(refreshPreHistory(temp_session, true));
                    }
                    if (temp_session.status.recording !== prevSession.status.recording) {
                        refreshTasks.push(refreshRecordingsExist(temp_session));
                    }
                    if (temp_session.status.transcript !== prevSession.status.transcript) {
                        refreshTasks.push(refreshTranscript(temp_session, true));
                    }
                    if (temp_session.status.recording !== prevSession.status.recording || temp_session.status.transcript !== prevSession.status.transcript) {
                        refreshTasks.push(refreshRecordingsAndTranscripts(temp_session, true));
                    }
                    if (temp_session.status.discharge !== prevSession.status.discharge) {
                        refreshTasks.push(refreshDischarge(temp_session, true));
                    }
        
                    if (refreshTasks.length > 0) {
                        await Promise.all(refreshTasks);
                    }
        
                    // Update the active session only if it remains the same
                    if (temp_session.id === activeSessionRef.current?.id) {
                        activeSessionRef.current = temp_session;
                        setActiveSession(temp_session);
                    }
                }
            }
        };

        function notifyInProgress(title: string, message?: string) {
            setNotification(true)
            setNotificationTitle(title)
            setNotificationText(message)
            setNotificationLineColor("bg-blue-500")
            setNotificationIconColor("text-blue-400")
        }

        function notifyCompleted(title: string, message?: string) {
            setNotification(true)
            setNotificationTitle(title)
            setNotificationText(message)
            setNotificationLineColor("bg-green-500")
            setNotificationIconColor("text-green-500")
        }

        function notifyError(title: string, message?: string) {
            setNotification(true)
            setNotificationTitle(title)
            setNotificationText(message)
            setNotificationLineColor("bg-red-500")
            setNotificationIconColor("text-red-500")
        }

        function clearNotification() {
            setNotification(false)
            setNotificationTitle(undefined)
        }

        async function uploadActiveNotes(notesToSave: string) {
            if (activeSession) {
                let hasPetArray = activeSession.pets && activeSession.pets.length > 0
                let correspondingNoteId;
                if (hasPetArray && sessionWithPetArraySelectedName) {
                    correspondingNoteId = activeSession.pet_to_soap_node_mapping?.[sessionWithPetArraySelectedName]
                }

                const blob = new Blob([revertPreprocessedText(notesToSave)], {type: 'text/plain'})
                const file = new File([blob], `${activeSession.id}.txt`, {type: 'text/plain'})
                let signedURL = await GetNotesSignedURL(activeSession.id, await getToken({template:"supabase"}) ?? '', correspondingNoteId)
                await uploadToSupabaseSignedURL(file, signedURL)    
                
                // Diagram
                if(activeDiagram){
                    const blob = new Blob([JSON.stringify(activeDiagram)], {type: 'application/json'})
                    const file = new File([blob], `${activeSession.id}.json`, {type: 'application/json'})
                    let signedURL = await GetDiagramSignedURL(activeSession.id, await getToken({template:"supabase"}) ?? '', correspondingNoteId)
                    await uploadToSupabaseSignedURL(file, signedURL)
                }
            }
        }

        async function uploadActiveDischarge(dischargeToSave: string) {
            if (activeSession) {
                let hasPetToDischargeMapping = activeSession.pet_to_discharge_node_mapping && Object.keys(activeSession.pet_to_discharge_node_mapping).length > 0
                let correspondingDischargeNoteId;
                if (sessionWithPetArraySelectedName && hasPetToDischargeMapping){
                    correspondingDischargeNoteId = activeSession.pet_to_discharge_node_mapping?.[sessionWithPetArraySelectedName]
                }
                const blob = new Blob([revertPreprocessedText(dischargeToSave)], {type: 'text/plain'})
                const file = new File([blob], `${activeSession.id}.txt`, {type: 'text/plain'})
                let signedURL = await GetDischargeSignedURL(activeSession.id, await getToken({template:"supabase"}) ?? '', correspondingDischargeNoteId)
                await uploadToSupabaseSignedURL(file, signedURL)  
            }
        }

        async function uploadLocalRecap(recapToSave: {[key:string]:string} | undefined) {
            try {
                if (activeSession && recapToSave) {
                    let signedURL = await GetRecapUploadURL(activeSession.id, await getToken({template:"supabase"}) ?? '')
                    const file = objectToJsonFile(recapToSave, `${activeSession.id}.json`, 'application/json')
                    await uploadToSupabaseSignedURL(file, signedURL)  
                    setActivePreHistory(recapToSave)
                    refreshActiveSession()
                    notifyCompleted("Recap has been saved!")
                }
            } catch (err) {
                setError("There was an issue saving your Records Recap. If error persists contact us at")
            }
        }
    
        async function fetchTemplateForSession(sessionObject:SessionObject){
            // Template name fetching
            if (sessionObject.template_id && sessionObject.id === activeSessionRef.current?.id) {
                try {
                    const template = await getTemplate(sessionObject.template_id.split("/")[1], await getToken({template: "supabase"}) ?? "");
                    setSessionTemplate(template);
                } catch (error) {
                    setSessionTemplate(undefined);
                }
            }
        }

        async function fetchPreHistoryTemplateNameForSession(sessionObject:SessionObject){
            // Records Recap Template name fetching
            if (sessionObject.prehistory_metadata?.template_id && sessionObject.id === activeSessionRef.current?.id) {
                try {
                    const template = await getTemplate(sessionObject.prehistory_metadata.template_id.split("/")[1], await getToken({template: "supabase"}) ?? "");
                    setSessionPrehistoryTemplateName(template['name']);
                } catch (error) {
                    setSessionPrehistoryTemplateName(undefined);
                }
            }
        }
    
        async function selectSession(sessionObject: SessionObject): Promise<void> {
            setSessionFromParam(sessionObject.id ?? undefined);
            setActiveSession(sessionObject);
            setActiveNotes(undefined);
            setActiveDiagram(undefined);
            setActiveTranscript(undefined);
            setActiveRecordingsAndTranscripts(undefined);
            setActiveRecording(undefined);
            setActiveDischarge(undefined);
            setActivePreHistory(undefined);
            setActiveRecordingBackUp(false);
            setActiveRecordingExist(false)
            setDisplayActiveDischarge('')
            setDisplayActiveNotes('')
            setDisplayOriginalNotes(undefined)
            setMedicalNotesEditCache(undefined)
            setDischargeNotesEditCache(undefined)
            setActiveAuditLogs(undefined);
            setNotesProcessing(false);
            setTranscriptProcessing(false);
            setPreHistoryProcessingMessage(undefined)
            setPreHistoryStreaming(false);
            setFeedbackDischarge(false);
            setNotesStreaming(false);
            setSessionTemplate(undefined);
            setSessionPrehistoryTemplateName(undefined);
            setTemplateDefinition(undefined);
            setPreHistoryFailureMessage(undefined);
            setNotesSections(undefined);

            resetAllPetArrayFields();
            
            const tempShouldSessionUseMetagraph = evaluateShouldSessionUseMetagraph(useMetagraphEnabled, sessionObject)
            setLoadingSessionInfo("Fetching Session 📅...");
            setNotesLoading("Fetching Notes 🤖...");
            setTranscriptLoading(tempShouldSessionUseMetagraph ? "Fetching Recordings 💿 and Transcripts 📄..." : "Fetching Transcript 📄...");
            setDischargeLoading("Fetching Communications 📄...")
            setRecordingCheckLoading("Checking Recording 💿...");
            navigate(`/history?session_id=${sessionObject.id}`);
    
            let temp_session : SessionObject | undefined = await refreshSessionObject(sessionObject)
            activeSessionRef.current = temp_session;
        
            if (sessionObject.id !== activeSessionRef.current?.id) return;
        
            // Prepare all refresh promises
            const refreshTasks = [
                fetchTemplateForSession(temp_session ?? sessionObject),
                fetchPreHistoryTemplateNameForSession(temp_session ?? sessionObject),
                refreshAuditLogs(temp_session ?? sessionObject),
                refreshNotes(temp_session ?? sessionObject, false),
                refreshPreHistory(temp_session ?? sessionObject, false),
                refreshTranscript(temp_session ?? sessionObject, false),
                refreshRecordingsAndTranscripts(temp_session ?? sessionObject, false),
                refreshDischarge(temp_session ?? sessionObject, false),
                refreshRecordingsExist(temp_session ?? sessionObject)
            ];
        
            // Execute all refresh operations in parallel
            await Promise.all(refreshTasks.map(task => task.catch(e => { console.error('Refresh task failed:', e); })));
        
            // Log the view event
            let properties = {
                date: (new Date()).toUTCString(),
                sessionId: sessionObject.id
            };
            va.track("HistoryPage_ViewSession_Click", properties);
        
            // Clear loading states
            setLoadingSessionInfo(undefined);
        }

        async function generateDischarge(regenerate:boolean = false, additionalInstructions?:string): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
                regenerate:regenerate
            }
            va.track("HistoryPage_GenerateDischarge_Click", properties)
    
            if (activeSession)
            {
                if (activeDischarge){
                    try{
                        setDischargeProcessing(true)
                        setActiveDischarge(undefined)
                        resetDischargePetArrayFields()

                        // If there's an active pet and this is a multi-pet session, switch back to the first pet
                        if (sessionWithPetArraySelectedName && activeSession.pet_to_discharge_node_mapping && Object.keys(activeSession.pet_to_discharge_node_mapping).length > 1) {
                            const selectedPet = Object.keys(activeSession.pet_to_discharge_node_mapping)[0]
                            handleSelectedPetChange(selectedPet)
                        }
                        
                        setActiveSession(currentSession => {
                            // Check if currentSession is not undefined before updating
                            if (currentSession) {
                                return {
                                ...currentSession,
                                status: {
                                    ...currentSession.status,
                                    discharge: "Processing",
                                },
                                };
                            }
                            return currentSession; // or return a new SessionObject if needed
                        });
                    }
                    catch(error){
                        setDischargeProcessing(false)
                        setActiveDischarge(undefined)
                        resetDischargePetArrayFields()
                        setError("There was a problem re-generating your communication notes. If error persists contact us at support@vetrec.io.")
                        return
                    }
                }
    
                setDischargeProcessing(true)
                let templateId = `${templateDischarge?.organization}/${templateDischarge?.id}`
                try{
                    setTab("discharge")
                    await generateDischargeNotes(activeSession?.id, activeSession?.name, templateId,  await getToken({template:"supabase"}) ?? "", additionalInstructions)
                    await getSessions(false)
                    // TODO show template name under discharge
                }
                catch(error) {
                    setDischargeProcessing(false)
                    setError("There was a problem generating communication notes. If error persists contact us at support@vetrec.io.")
                    return
                }
            }
            else{
                setError("Small hiccup, please try again later.")
            }
        }
    
        async function processTranscript(regenerate:boolean = false, additionalInstructions?:string): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
                regenerate:regenerate
            }
            va.track("HistoryPage_ProcessTranscript_Click", properties)
    
            if (activeSession)
            {
                if (activeNotes){
                    setActiveNotes(undefined)
                    setActiveDiagram(undefined)
                    resetAllPetArrayFields();
                }
                
                setActiveSession(currentSession => {
                    // Check if currentSession is not undefined before updating
                    if (currentSession) {
                        return {
                        ...currentSession,
                        status: {
                            ...currentSession.status,
                            notes: "Processing",
                            summary: "Processing",
                        },
                        };
                    }
                    return currentSession; // or return a new SessionObject if needed
                });
                setNotesProcessing(true)
                let templateId = `${template?.organization}/${template?.id}`
                try{
                    setTab("notes")
                    await generateSoapNotes(activeSession?.id, templateId, false, await getToken({template:"supabase"}) ?? "", activeSession?.name, additionalInstructions)
                    await getSessions(false)
                    if(templateId){
                        setSessionTemplate(await getTemplate(templateId.split("/")[1],  await getToken({template:"supabase"}) ?? ""))
                    }
                }
                catch(error) {
                    setNotesProcessing(false)
                    setError("There was a problem generating the notes. If error persists contact us at support@vetrec.io.")
                    return
                }
            }
            else{
                setError("Small hiccup, please try again later.")
            }
        }
        
        useEffect(() => {
            if(tab === "transcript" && activeSession){
                setLanguage(activeSession.spoken_language ?? "English (US)")
            }
        }, [tab, activeSession])
        
        async function processRecordings(reprocess:boolean = false, sessionSpokenLanguage?:string, template?:TemplateObject): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
                sessionSpokenLanguage: sessionSpokenLanguage ?? "English (US)"
            }
            va.track("HistoryPage_ProcessRecording_Click", properties)
    
            if(activeSession){
                setTranscriptProcessing(true)
                setActiveNotes(undefined)
                setActiveDiagram(undefined)
                resetAllPetArrayFields();
                setActiveTranscript(undefined)
                setActiveRecordingsAndTranscripts(undefined)
                setActiveSession(currentSession => {
                    // Check if currentSession is not undefined before updating
                    if (currentSession) {
                        return {
                        ...currentSession,
                        status: {
                            ...currentSession.status,
                            transcript: "Processing",
                            notes: "Processing",
                            summary: "Processing",
                        },
                        };
                    }
                    return currentSession; // or return a new SessionObject if needed
                });
                try{
                    let templateId = template ? `${template.organization}/${template.id}` : undefined
                    // If the tab is Transcript, do not change the tab
                    if(tab !== "transcript"){
                        setTab("notes")
                    }
                    if(reprocess){
                        await reprocessRecording(activeSession?.id, activeSession?.name, await getToken({template:"supabase"}) ?? "",  activeNotes !== undefined, templateId, true, activeSession?.pets, sessionSpokenLanguage)
                    }
                    else {
                        await processRecording(activeSession?.id, activeSession?.name, await getToken({template:"supabase"}) ?? "",  activeNotes !== undefined, templateId, true, activeSession?.pets, sessionSpokenLanguage)
                    }
                    await getSessions(false)
                }
                catch(error) {
                    setTranscriptProcessing(false)
                    setError("There was a problem generating the transcript. If error persists contact us at support@vetrec.io.")
                    return
                }
            }
            else{
                setError("Small hiccup, please try again later.")
            }
        }
    
        async function handleSaveName(): Promise<void> {
            if(activeSession)
            {
                await saveName(activeSession?.id, activeSession?.name, await getToken({template:"supabase"}) ?? "", activeSession?.pets)
                notifyCompleted("Visit name has been updated")
                await getSessions(false)
            }
        }

        async function handleSaveDate(): Promise<void> {
            if (activeSession) {
                try {
                    await saveDate(activeSession?.id, activeSession?.name, await getToken({template:"supabase"}) ?? "", activeSession?.created_timestamp)
                    notifyCompleted("Visit date has been updated")
                    await getSessions(false)
                } catch (error) {
                    notifyError("There was a problem updating the date. If error persists, please contact support")
                }
            }
        }

        async function handleSavePatients(pets: string[], petUpdates?: {
            update: Record<string, string>,
            add: string[],
            remove: string[]
        }): Promise<void> {
            if(activeSession)
            {
                try {
                    await savePatients(
                        activeSession?.id, 
                        pets,
                        await getToken({template:"supabase"}) ?? "", 
                        petUpdates
                    );
                    setActiveSession({
                        ...activeSession,
                        pets: pets
                    });
                    notifyCompleted("Patients have been updated")
                    await getSessions(false)
                }
                catch(error) {
                    setError("Small hiccup,  please try again later.");
                }
            }
        }

        const handleSelectionChange = (
            range: { index: number; length: number } | null,
            source: string,
            editor: UnprivilegedEditor
        ) => {
            if (range && range.length > 0) {
                const selectedText = editor.getText(range.index, range.length); // Get highlighted text
                setHighlightedTextBuffer(selectedText);
            } else {
                setHighlightedText(highlightedTextBuffer)
                setHighlightedTextBuffer(undefined)
            }
        }; 
    
        async function handleThumbsInput(input: boolean, location: string): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                session:activeSession?.id ?? "No Session",
                feedback: input
            }
            va.track(`${location}_ThumbsInput`, properties)
    
            if(!input){
                setFeedbackModal(true)
                setFeedbackModalSource(location)
            }
            else{
                notifyCompleted("Thank you for the feedback", "Feedback was shared with team!")
                let session = activeSession?.id ?? "No Session"
                try{
                    await handleThumbsFeedbackServerSide(session, input, "", location, undefined, undefined, await getToken({template:"supabase"}) ?? "")
                }
                catch(error){
                }
            }
        }
    
        function handleTabChange(value: string): void {
            setTab(value)
            setHighlightedText(undefined)
            let properties =  {
                date:(new Date()).toUTCString(),
                session:activeSession?.id ?? "No Session",
                tabClicked:value
            }
            va.track(`History_TabChange`, properties)
        }
    
        function handleHistorySearch(value:string): void {
            setSearchValue(value)
        }
    
        async function translateDischarge(): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
            }
            va.track("HistoryPage_TranslateDischarge", properties)
    
            if (activeSession)
            {
                if (activeDischarge){
                    try{
                        setActiveSession(currentSession => {
                            // Check if currentSession is not undefined before updating
                            if (currentSession) {
                                return {
                                ...currentSession,
                                status: {
                                    ...currentSession.status,
                                    discharge: "Processing",
                                },
                                };
                            }
                            return currentSession; // or return a new SessionObject if needed
                        });
                        setDischargeProcessing(true)
                        setActiveDischarge(undefined)
                    }
                    catch(error){
                        setDischargeProcessing(false)
                        setError("There was a problem translating your discharge notes. If error persists contact us at support@vetrec.io.")
                        return
                    }
                }
    
                setDischargeProcessing(true)
                try{
                    setTab("discharge")
                    await translateDischargeNotes(language, activeSession.id, await getToken({template:"supabase"}) ?? "")
                    await getSessions(false)
                }
                catch(error) {
                    setDischargeProcessing(false)
                    setError("There was a problem translating the discharge notes. If error persists contact us at support@vetrec.io.")
                    return
                }
            }
            else{
                setError("Small hiccup, please try again later.")
            }
        }
    
        function handleAddToSession(): void {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
            }
            va.track("HistoryPage_AddToSession", properties)
            navigate("/scribe?session=" + activeSession?.id)
        }
    
        function handleNotesTemplateChange(change:boolean): void{
            setChangeTemplate(change)
        }

        function handleDischargeTemplateChange(change:boolean): void{
            setChangeDischargeTemplate(change)
        }

        function handleRegenerateRecordsRecap(shouldUploadFilesBeOpen: boolean): void {
            setRegenerateRecapModal(true)
            setRegenRecapShouldUploadFilesBeOpen(shouldUploadFilesBeOpen)
        }
    
        function handleLanguageChange(change:boolean): void{
            setChangeLanguage(change)
        }
    
        async function handleMarkComplete(): Promise<void> {
            let completed = activeSession?.status.general === "Completed" ? false : true
            await markSessionCompleted(activeSession?.id ?? "", completed, await getToken({template:"supabase"}) ?? '')
            setActiveSession(currentSession => {
                return currentSession ? {
                  ...currentSession,
                  status: {
                    ...currentSession.status,
                    general: activeSession?.status.general === "Completed" ? "Not Started" : "Completed"
                  }
                } : undefined;
            });
            activeSession?.status.general === "Completed" ? notifyInProgress("Session has been marked as incomplete") : notifyCompleted("Session has been marked as completed")
            await getSessions(false)
        }
    
        async function handleCreatePatient(sessionName: string, pets:string[], owner?: string, timestamp?:number, consent?:boolean): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                session:activeSession?.id ?? "No Session",
            }
            va.track("HistoryPage_CreatePatient", properties)
            consent = consent ?? false
            await createSession({
                session: uuidv4(),
                name: sessionName,
                token: (await getToken({ template: "supabase" })) ?? "",
                manual_notes: false,
                recording: true,
                consent,
                pets,
                owner,
                timestamp,
            });
            await getSessions(true)
        }
    
        async function handleSaveOwner(): Promise<void> {
            if(activeSession){
                await saveOwner(activeSession?.id, activeSession?.display_owner ?? activeSession.owner, await getToken({template:"supabase"}) ?? "")
                notifyCompleted("Visit owner has been updated")
                await getSessions(false)
            }
        }

        function handleToggleSections(){
            setToggleSections(!toggleSections)
        }

        async function GetTemplateDefinition(templateId:string){
            let temp_definition = templateDefinition
            if(!temp_definition){
                let template = await GetTemplate(`${templateId}`, await getToken({template:"supabase"}) ?? '')
                temp_definition = JSON.parse(template ?? "") as SectionType[]
                setTemplateDefinition(temp_definition)
            }
            return temp_definition
        }

        async function handleDeleteVisit(){
            await deleteSession(activeSession?.id ?? "", await getToken({template:"supabase"}) ?? "")
            setActiveSession(undefined)
            await getSessions(true)
            setDeleteVisitModal(false)
        }

        async function refreshVetspireVisit(){
            let token = await getToken({template:"supabase"}) ?? "";
            let encounterId:string = (await updateVetspireVisit(activeSession?.id ?? "", activeSession?.external_visit_id ?? "", token))['encounterId']
            setActiveSession(currentSession => {
                if (currentSession) {
                    return {
                    ...currentSession,
                    external_record_id: encounterId
                    };
                }
                return currentSession; // or return a new SessionObject if needed
            })
            setSessions(prevSessions => {
                const updatedSessions = prevSessions.map(session => {
                    if (session.id === activeSession?.id) {
                        return {
                            ...session,
                            external_record_id: encounterId
                        };
                    }
                    return session; // Ensure the session is returned unchanged if it doesn't match
                });
                return updatedSessions;
            });
            return encounterId
        }

        async function importVisitsFromVetspire(){
            try{
                // Get today's date as a start and end in the current timezone
                setImportingSessions(true)
                const { start_date_in_float, end_date_in_float} = getSearchTimestamp()
                await importVetspireVisits(start_date_in_float, end_date_in_float, await getToken({template:"supabase"}) ?? "", (userSearch && userSearch.identifier !== user?.primaryEmailAddress?.emailAddress && userSearch.identifier !== ALL_DOCTORS) ? userSearch?.identifier : undefined)
                notifyCompleted("Vetspire sync completed!")
                setImportingSessions(false)
                await getSessions(true)
            } catch (error) {
                setImportingSessions(false)
                if (error instanceof Error) {
                    setError("Vetspire sync failed: " + error.message);
                } else {
                    setError("Vetspire sync failed: " + String(error));
                }
            }
        }
        
        async function handleDeleteRecap(){
            try {
                await deletePreHistory(activeSession?.id ?? "", await getToken({template:"supabase"}) ?? "")

                if (activeSession) {
                    await refreshActiveSession()
                }
    
                setDeleteRecapModal(false)    
            } catch (error) {
                setError("There was a problem deleting the recap. Please try again.")
            }
        }

        useEffect(() => {
            const fetchData = async () => {
                if(toggleSections && activeSession){
                    if(sessionTemplate){
                        let split_notes = splitNoteIntoSectionWithDefinition(revertPreprocessedText(displayActiveNotes) ?? "", await GetTemplateDefinition(sessionTemplate.id ?? ""));
                        setNotesSections(split_notes)
                    } else {
                        setToggleSectionsError(true)
                    }
                }
            };
    
            setToggleSectionsError(false)
            fetchData();
        },[toggleSections, activeSession, displayActiveNotes, sessionTemplate])

        async function importAppointmentsFromOnPremPims(){
            try{
                // Get today's date as a start and end in the current timezone
                setImportingSessions(true)
                const { start_date_in_float, end_date_in_float} = getSearchTimestamp()
                await importOnPremPimsAppointments(start_date_in_float, end_date_in_float, await getToken({template:"supabase"}) ?? "")
                // TODO: Do text "Cornerstone" dynamic
                notifyCompleted("Cornerstone sync completed!")
                setImportingSessions(false)
                await getSessions(true)
            } catch (error) {
                setImportingSessions(false)
                setError("Cornerstone sync failed")
            }
        }

    return (
        <HistoryContext.Provider value={{
            // HistoryStateContextType
            activeSession,
            setActiveSession,
            shouldSessionUseMetagraph,
            filteredSessions,
            availableSession,
            sessions,
            sessionTemplate,
            sessionPrehistoryTemplateName,
            tab,
            chatBotContext,
            notesProcessing,
            transcriptProcessing,
            preHistoryProcessingMessage,
            preHistoryFailureMessage,
            dischargeProcessing,
            preHistoryStreaming,
            notesStreaming,
            dischargeStreaming,
            notesStreamCompleted,
            notesLoading,
            dischargeLoading,
            preHistoryLoading,
            recordingLoading,
            transcriptLoading,
            loadingSessionInfo,
            loadingSessions,
            importingSessions,
            activeRecordingBackUp,
            activeRecordingExist,
            recordingCheckLoading,
            listContainerRef,
            scrollToItemRef,
            sessionFromParam,
            activeSessionUpdated,
            showChatbot,
            sortLatest,
            filterState,
            sessionWithPetArrayMedicalNotes,
            sessionWithPetArrayDischargeNotes,
            multiPetSessionActive,
            petArrayChanged,
            handleSortLatest,
            handleTabChange,
            handleSelectedPetChange,
            setShowChatBot,
            setActiveNotes,
            handleSavePatients,
            handleFilterState,

            // HistoryContentContextType
            language,
            activeNotes,
            activeDiagram,
            activeDiagramDescription,
            activeAuditLogs,
            didUserSaveNotesAfterLatestGeneration,
            notesSections,
            sessionWithPetArraySelectedMedicalNoteId,
            sessionWithPetArraySelectedDischargeNoteId,
            sessionWithPetArraySelectedName,
            displayActiveNotes,
            medicalNotesEditCache,
            dischargeNotesEditCache,
            displayOriginalNotes,
            activeDischarge,
            displayActiveDischarge,
            activeRecordingsAndTranscripts,
            anyRecordingsWithNoData,
            activeTranscript,
            activeTranscriptExists,
            activePreHistory,
            shouldShowPreHistoryMoreMessage,
            shouldShowPrehistoryScannedDocumentsMessage,
            activeRecording,
            template,
            templateDischarge,
            sessionWithPetArrayDiagrams,
            setActiveDiagram,
            setMedicalNotesEditCache,
            setDischargeNotesEditCache,
            setDisplayActiveNotes,
            setDisplayActiveDischarge,
            setTemplate,
            setTemplateDischarge,
            setLanguage,

            // HistoryActionsContextType
            feedbackDischarge,
            toggleSections,
            toggleSectionsError,
            handleMarkComplete,
            handleAddToSession,
            handleThumbsInput,
            handleLanguageChange,
            handleCreatePatient,
            processTranscript,
            processRecordings,
            handleSaveOwner,
            translateDischarge,
            handleToggleSections,
            handleNotesTemplateChange,
            setRegenerateRecapModal,
            setRegenRecapShouldUploadFilesBeOpen,
            handleRegenerateRecordsRecap,
            handleDischargeTemplateChange,
            handleSaveName,
            handleSaveDate,
            refreshActiveSession,
            refreshAuditLogs,
            handleUserSearch,
            selectSession,
            generateDischarge,
            handleDeleteVisit,
            handleDeleteRecap,
            refreshSessionObject,
            uploadActiveNotes,
            uploadActiveDischarge,
            uploadLocalRecap,
            importVisitsFromVetspire,
            refreshVetspireVisit,
            importAppointmentsFromOnPremPims,

            // HistoryFiltersContextType
            searchDate,
            membersList,
            userSearch,
            debouncedQuery,
            setSearchDate,
            handleHistorySearch,

            // HistoryAlertsContextType
            deleteVisitModal,
            deleteRecapModal,
            feedbackModal,
            findReplaceModal,
            addCustomSpellingModal,
            highlightedText,
            createVisitModal,
            feedbackModalSource,
            changeTemplate,
            regenerateRecapModal,
            regenRecapShouldUploadFilesBeOpen,
            changeDischargeTemplate,
            changeLanguage,
            notification,
            notificationTitle,
            notificationText,
            notificationLineColor,
            notificationIconColor,
            error,
            warning,
            uploadRecordingModal,
            setNotification,
            setNotificationTitle,
            setNotificationText,
            notifyInProgress,
            notifyCompleted,
            clearNotification,
            setError,
            setWarning,
            setFeedbackModal,
            setFindReplaceModal,
            setAddCustomSpellingModal,
            handleSelectionChange,
            getLoggingProperties,
            setCreateVisitModal,
            setDeleteVisitModal,
            setDeleteRecapModal,
            setUploadRecordingModal,
            setHighlightedText
        }}>
            {children}
        </HistoryContext.Provider>
    );
}