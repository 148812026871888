import { fetchTempalteById } from "../ServerActions";
import { TemplateObject } from "./TemplateObject";

export async function getTemplate(templateId: string, jwt: string) {
  let template = await fetchTempalteById(templateId, jwt);
  return template["template"];
}

// queryTemplateId is expected to be the "template_id" field from the sessinos object: format `{organization}/{id}`
export function getTemplateFromListById(
  templates: TemplateObject[],
  queryTemplateId: string | undefined,
  externalTemplateId: string | undefined
) {
  let template;

  if (queryTemplateId) {
    if(queryTemplateId.includes("/")) {
      const [organization, id] = queryTemplateId.split("/");
      template = templates.find((t) => t.organization === organization && t.id === id);
    }
    else {
      template = templates.find((t) => t.id === queryTemplateId);
    }
  }

  if (!template && externalTemplateId) {
    template = templates.find((t) => t.mapping?.id === externalTemplateId);
  }

  return template;
}
