import { Fragment, useMemo, useRef, useState } from "react"
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { ALL_DOCTORS, allDoctors, MemberObject } from "../utils/MemberUtils";

interface UserPickerProps {
    userList: MemberObject[];
    userSearch: MemberObject | undefined;
    setUserSearch: (value: MemberObject | undefined) => void;
    className?: string;
    text?:string
    disabled?: boolean;
}

const UserPicker: React.FC<UserPickerProps> = ({ userList, userSearch, setUserSearch, className, text, disabled }) => {
    const [query, setQuery] = useState('');

    const filteredUsers = useMemo(() => {
        let filteredList = userList
            .filter(user => user.identifier !== userSearch?.identifier)

        filteredList = filteredList.sort((a, b) => a.identifier === ALL_DOCTORS ? -1 : b.identifier === ALL_DOCTORS ? 1 : (a.name ?? a.identifier).localeCompare(b.name ?? b.identifier));

        if (query === '') {
            return [userSearch, ...filteredList].filter(Boolean);
        }
        return userList.filter((user) =>
            user.name?.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')) ?? user.identifier.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        ).sort((a, b) => (a.name ?? a.identifier).localeCompare(b.name ?? b.identifier));
    }, [query, userList, userSearch]);

    const handleSelect = (value: MemberObject) => {
        setUserSearch(value)
    };

    const buttonRef = useRef<HTMLButtonElement>(null);

    return (
        <div className="w-full relative z-[999]">
            {text && <label
                htmlFor="name"
                className={`absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-main-text-darker z-[1] bg-white`}
            >
                {text}
            </label>}
            <Combobox value={userSearch} onChange={(value) => handleSelect(value)} disabled={disabled ?? false}>
                {({ open }) => (
                    <div className="relative w-full">
                        <div className={`relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm border border-gray-300 ${className} flex flex-row items-center`}>
                            <Combobox.Input
                                className={`w-full border-none py-2 pl-3 pr-10 text-sm leading-5 ${userSearch?.identifier === ALL_DOCTORS ? "text-gray-400" : "text-gray-900"} focus:ring-0 h-full rounded-md border-blue-600`}
                                displayValue={(user: MemberObject | undefined) => user ? userList.find(u => u.identifier === user.identifier)?.name ?? userList.find(u => u.identifier === user.identifier)?.identifier ?? allDoctors.identifier : allDoctors.identifier}
                                onChange={(event) => setQuery(event.target.value)} 
                                onFocus={() => {
                                    if(!open){
                                        buttonRef.current?.click()
                                    }
                                }}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" ref={buttonRef}>
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                        >
                            <Combobox.Options className="absolute max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm thin-scrollbar z-[999] mt-2">
                                {filteredUsers.length === 0 && query !== '' ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                        Nothing found.
                                    </div>
                                ) : (
                                    filteredUsers.map((user) => (
                                        <Combobox.Option
                                            key={user?.identifier}
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-accent text-main-text-darker' : 'text-gray-900'}`
                                            }
                                            value={user}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                                        {user?.name ?? user?.identifier}
                                                    </span>
                                                    {selected && (
                                                        <span
                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-600'}`}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    ))
                                )}
                            </Combobox.Options>
                        </Transition>
                    </div>
                )}
            </Combobox>
        </div>
    );
};

export default UserPicker