import { Fragment, useEffect, useRef } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon, SparklesIcon } from '@heroicons/react/24/solid'

interface ImproveInstructionsModalProps {
  isOpen: boolean
  onClose: () => void
  onImprove: (input: string) => void
  input: string
  onInputChange: (value: string) => void
  contextIsEmpty: boolean
  isLoading?: boolean
  disableSubmit?: boolean
}

export const ImproveInstructionsModal: React.FC<ImproveInstructionsModalProps> = ({
  isOpen,
  onClose,
  onImprove,
  input,
  onInputChange,
  contextIsEmpty,
  isLoading = false,
  disableSubmit = false,
}) => {
  // Create a ref for the modal container
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isOpen) return

      if (event.key === 'Escape') {
        // Only close if the modal (or one of its children) is focused
        if (modalRef.current && modalRef.current.contains(document.activeElement) && !isLoading) {
          onClose()
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [isOpen, onClose])

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleImprove(input)
    }
  }

  const handleImprove = async (input: string) => {
    onImprove(input)
    onClose()
  }

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transition ease-out duration-200 transform"
      enterFrom="opacity-0 translate-y-1 scale-95"
      enterTo="opacity-100 translate-y-0 scale-100"
      leave="transition ease-in duration-150 transform"
      leaveFrom="opacity-100 translate-y-0 scale-100"
      leaveTo="opacity-0 translate-y-1 scale-95"
    >
      {/* Attach the ref to the modal container */}
      <div ref={modalRef} className="top-full right-0 mt-2 w-[calc(100%-2rem)] z-50 relative">
        <div className="absolute -top-2 right-6 w-0 h-0 border-l-4 border-r-4 border-b-4 border-l-transparent border-r-transparent border-b-blue-200"></div>
        <div className="absolute -top-1 right-6 w-0 h-0 border-l-3 border-r-3 border-b-3 border-l-transparent border-r-transparent border-b-white"></div>
        <div className="bg-blue-100 backdrop-blur-sm rounded-xl ring-1 ring-blue-200 p-2">
          <div className="flex items-center gap-x-2">
            <input
              type="text"
              className="flex-1 min-w-0 rounded-md border-gray-300 bg-white text-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm h-8 disabled:bg-gray-50"
              placeholder={contextIsEmpty ? "Explain how you would like VetRec to fill out this field on your notes" : "(Optional) Additional information to improve the instructions"}
              value={input}
              onChange={(e) => onInputChange(e.target.value)}
              onKeyDown={handleKeyPress}
              autoFocus
              disabled={isLoading}
            />
            <button
              type="button"
              className="shrink-0 rounded-md bg-blue-600 px-3 h-8 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:bg-blue-400"
              onClick={() => handleImprove(input)}
              disabled={isLoading || disableSubmit}
            >
              {isLoading ? 'Improving...' : 'Improve'}
            </button>
            <button
              type="button"
              className="shrink-0 text-gray-400 hover:text-gray-500 p-1 disabled:text-gray-300"
              onClick={onClose}
              disabled={isLoading}
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
          <div className="mt-1 text-[10px] text-gray-500 text-center">
            Press Enter to improve, and Esc to cancel.
          </div>
        </div>
      </div>
    </Transition>
  )
}

export const ImproveButton: React.FC<{
  onClick: () => void
}> = ({ onClick }) => {
  return (
    <button 
      className="absolute top-1/2 right-2 -translate-y-1/2 p-1 text-gray-400 hover:text-blue-600 transition-colors duration-200 z-40"
      onClick={onClick}
    >
      <SparklesIcon className="w-4 h-4" />
    </button>
  )
}