import { UserRole } from "../../settings/UserRoles";

export enum UserStatus {
  Active = 'active',
  //Inactive = 'inactive',
  Invited = 'invited',
  //Suspended = 'suspended'
}

export interface User {
  id: string;
  name: string;
  email: string;
  teams: string[];
  tags: string[];
  status: UserStatus;
  role: UserRole; // Add role to User interface
}

export interface Team {
  id: string;
  name: string;
  memberCount?: number;
}

export interface Tag {
  id: string;
  name: string;
  teams: string[];
  users: string[]
}
