// Legend using flex-row that displays colors and text for each dental state

import { useState } from "react";
import { DentalType, ToothColor, ToothFillColorTW } from "../../utils/DentalUtils";
import { XMarkIcon } from "@heroicons/react/24/solid";

export const DentalLegend = () => {
    let types = Object.keys(DentalType)
    const [toggleLegend, setToggleLegend] = useState(false)

    return (
        <>
            <button className="bg-blue-600 text-white rounded-md px-2 py-1 text-xs mt-4 shadow hover:bg-blue-700" onClick={() => setToggleLegend((prev) => !prev)}>{toggleLegend ? "Hide Legend" : "Show Legend"}</button>
            {toggleLegend && <div className="absolute left-4 flex flex-col justify-center bg-white rounded-md gap-y-2 mt-12 z-[99] px-4 py-4 border border-gray-300 shadow">
                {types.map((type, index) => (
                    <div key={index} className="flex flex-row items-center gap-x-2">
                        <div className={`w-4 h-4 rounded-md border-2 shadow flex items-center justify-center border-[${ToothColor[type as keyof typeof ToothColor]}] ${ToothFillColorTW[type as keyof typeof ToothFillColorTW].replace('fill', 'bg')} ${type === DentalType.TREATMENT ? "ring-2 ring-black ring-offset-2" : ""}`}> 
                            {type === DentalType.EXTRACTED && <XMarkIcon className="text-red-600"/>}
                        </div>
                        <div className="text-xs font-semibold">{type === DentalType.EXTRACTED ? "Extract" : type.slice(0,1) + type.replace("_", " ").slice(1).toLowerCase()}</div>
                    </div>
                ))}
            </div>}
            {/* Needed to properly load colors */}
            <div className="bg-red-300 bg-green-300 bg-gray-300 border-red-600 border-yellow-600 border-black-600 hidden border-green-600 border-gray-600"></div>
        </>
    );
}