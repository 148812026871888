import React, { useEffect } from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  XMarkIcon,
  Bars3Icon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'
import config from './config.json'
import { useAuth, useUser, useOrganizationList } from "@clerk/clerk-react"
import { ArrowLeftEndOnRectangleIcon, ArrowLeftIcon, BuildingOfficeIcon, TagIcon, UsersIcon } from '@heroicons/react/24/solid';
import { IntercomProvider } from 'react-use-intercom';
import logo from './assets/Logo.svg'
import logoSmall from './assets/LogoSmall.svg'
import { useVetRec } from './providers/VetRecProvider';
import UserManagement from './components/admin/UserManagement';
import TeamManagement from './components/admin/TeamManagement';
import AdminProvider from './providers/AdminProvider';
import TagManagement from './components/admin/TagManagement';
import Settings from './components/admin/Settings';
import CreateSuperOrg from './components/admin/subcomponents/CreateSuperOrg';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

// Define the types for your props
interface AdminDashboard {
  capability: string
}

export default function Admin(props: AdminDashboard) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(JSON.parse(localStorage.getItem('sidebarCollapsed') || 'false') as boolean)
  const { signOut, orgId } = useAuth();
  const { user } = useUser()
  const { setActive } = useOrganizationList()
  const { capability } = props
  const [tab, setTab] = useState<string>(props.capability)
  const { superOrgMetadata, adminCenterEnabled, superOrgOwner, isImpersonation, organizationAdmin } = useVetRec()

  const navigation = [
    { name: 'Users', tab: 'Users', icon: UsersIcon, current: capability == "Users", openInNewTab: false },
    { name: 'Teams', tab: 'Teams', icon: BuildingOfficeIcon, current: capability == "Teams", openInNewTab: false },
    { name: 'Tags', tab: 'Tags', icon: TagIcon, current: capability == "Tags", openInNewTab: false },
    // { name: 'Settings', tab: 'Settings', icon: Cog6ToothIcon, current: capability == "Settings", openInNewTab: false },
  ]

  useEffect(() => {
    const setActiveOrg = async () => {
      if (!orgId) {
        if (setActive && user?.organizationMemberships && user.organizationMemberships.length > 0 && capability != "Settings") {
          await setActive({ organization: user.organizationMemberships[0].organization })
        }
      }
    }
    
    setActiveOrg();
  }, []) // no dependencies, only run once at startup. No good way to check if org is already set

  useEffect(() => {
    if((!adminCenterEnabled || !superOrgOwner) && !isImpersonation) {
      window.location.href = '/'
    }
  }, [adminCenterEnabled, superOrgOwner, isImpersonation])

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
    localStorage.setItem('sidebarCollapsed', JSON.stringify(!sidebarCollapsed));
  };

  const setTabAndUrl = (tab: string) => {
    setTab(tab);
    window.history.pushState(null, '', `/admin/${tab.toLowerCase()}`);
  }

  return (
    <>
      <div className='overflow-hidden'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative lg:hidden z-[9999]" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-100 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-100 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-100"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => {setSidebarOpen(false)}}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-main-text" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className={`flex grow flex-col gap-y-5 h-screen overflow-y-auto thin-scrollbar bg-gray-600 px-6 pt-2 pb-2`}>
                    <div className="text-2xl text-main-text font-bold flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Company logo"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <div
                                  onClick={() => {setTabAndUrl(item.tab)}}
                                  className={classNames(
                                    tab == item.tab
                                      ? `bg-gray-600 text-main-text`
                                      : `text-accent-text hover:text-main-text hover:bg-gray-500`,
                                    'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      tab == item.tab ? `text-main-text` : `text-accent-text group-hover:text-main-text`,
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </div>
                              </li>
                            ))}
                            <li key={"back"}>
                              <a
                                href={"/"}
                                title={"Back to VetRec"}
                                className={classNames(
                                  `text-accent-text hover:text-main-text hover:bg-gray-500`,
                                  'group flex gap-x-3 items-center rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer',
                                  sidebarCollapsed ? 'justify-center' : 'justify-start'
                                )}
                              >
                                <ArrowLeftIcon
                                  className={classNames(
                                    `text-accent-text group-hover:text-main-text`,
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {!sidebarCollapsed && (
                                  <>
                                    {"Back to VetRec"}
                                  </>
                                )}
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="mt-auto w-full">
                          <div className="relative w-full">
                            <div className="flex flex-col gap-y-4 items-center gap-x-4 py-3 text-sm font-semibold leading-6 w-full">
                              <div className="flex items-center justify-center gap-x-2 w-full px-3 py-2 bg-gray-700/70 rounded-lg shadow-sm">
                                <BuildingOfficeIcon className="h-5 w-5 text-blue-400 flex-shrink-0" aria-hidden="true" />
                                <span className="text-main-text truncate">{superOrgMetadata?.name || "Organization"}</span>
                              </div>
                              <button onClick={() => {void signOut()}} className='flex bg-blue-600 text-accent-button-text border-accent-button border rounded-md text-md p-1 hover:bg-blue-500 w-full items-center justify-center h-12 font-semibold'>
                                Sign out
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col h-screen overflow-y-hidden transition-all duration-100 ease-in-out ${sidebarCollapsed ? 'lg:w-20' : 'lg:w-64'}`}>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className={`flex grow flex-col gap-y-5 overflow-y-auto thin-scrollbar bg-gray-600 ${sidebarCollapsed ? 'px-2' : 'px-6'}`}>
            <div className={`flex ${sidebarCollapsed ? "flex-col justify-center" : "flex-row items-center"} pt-8`}>
              <div className={`flex h-16 shrink-0 items-center ${sidebarCollapsed ? 'justify-center' : ''}`}>
                <img
                  className="h-8 w-auto"
                  src={sidebarCollapsed ? logoSmall : logo}
                  alt="Company logo"
                />
              </div>
              <div className={` ${!sidebarCollapsed ? 'absolute top-4 right-4' : 'flex justify-center w-full'}`}>
                <button
                  onClick={toggleSidebar}
                  className="text-accent-text hover:text-main-text p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                >
                  {sidebarCollapsed ? (
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>
            <nav className="flex flex-1 flex-col mt-4">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className={`space-y-1 ${sidebarCollapsed ? '' : '-mx-2'}`}>
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <div
                          onClick={() => {setTabAndUrl(item.tab)}}
                          title={item.name}
                          className={classNames(
                            tab == item.tab
                              ? `bg-gray-600 text-main-text`
                              : `text-accent-text hover:text-main-text hover:bg-gray-500`,
                            'group flex gap-x-3 items-center rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer',
                            sidebarCollapsed ? 'justify-center' : 'justify-start'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              tab == item.tab ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {!sidebarCollapsed && (
                            <>
                              {item.name}
                            </>
                          )}
                        </div>
                      </li>
                    ))}
                    <li key={"back"}>
                      <a
                        href={"/"}
                        title={"Back to VetRec"}
                        className={classNames(
                          `text-accent-text hover:text-main-text hover:bg-gray-500`,
                          'group flex gap-x-3 items-center rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer',
                          sidebarCollapsed ? 'justify-center' : 'justify-start'
                        )}
                      >
                        <ArrowLeftIcon
                          className={classNames(
                            `text-accent-text group-hover:text-main-text`,
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {!sidebarCollapsed && (
                          <>
                            {"Back to VetRec"}
                          </>
                        )}
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="mt-auto w-full">
                  <div className="relative w-full">
                    <div className={`flex flex-col items-center py-3 text-sm font-semibold leading-6 text-main-text w-full gap-y-4 ${sidebarCollapsed ? '' : 'justify-center'}`}>
                      <div className="flex items-center justify-center gap-x-2 w-full px-3 py-2 bg-gray-700/70 rounded-lg shadow-sm">
                        <BuildingOfficeIcon className="h-5 w-5 text-blue-400 flex-shrink-0" aria-hidden="true" />
                        <span className="text-main-text truncate">{superOrgMetadata?.name || "Organization"}</span>
                      </div>
                      <div className='flex flex-row items-center justify-center w-full gap-x-4'>
                        <img
                          className={`h-8 w-8 rounded-full bg-gray-600`}
                          src={user?.imageUrl}
                          alt=""
                        />
                        {!sidebarCollapsed && <span className="hidden lg:flex lg:items-center text-main-text font-semibold">
                          <span aria-hidden="true">{user?.fullName}</span>
                        </span>}
                      </div>
                      <button onClick={() => {void signOut()}} className={`shadow-md flex justify-center items-center text-accent-text bg-blue-600 rounded-md text-sm p-1 hover:bg-blue-500 font-semibold h-12 ${sidebarCollapsed ? 'w-12' : 'w-full'}`} title='Sign out'>
                        {sidebarCollapsed ? (
                          <ArrowLeftEndOnRectangleIcon className="h-5 w-5" />
                        ) : (
                          'Sign out'
                        )}
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className={`sticky top-0 z-40 flex items-center gap-x-6 bg-gray-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden`}>
          <button type="button" className={`-m-2.5 p-2.5 text-accent-text lg:hidden`} onClick={() => {setSidebarOpen(true)}}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-main-text">{config.BRANDED ? config.NAME : "VetRec"}</div>
          <div className='flex flex-row items-center gap-x-4'>
            <span className="lg:flex lg:items-center text-main-text text-sm">
              <span aria-hidden="true"><span className='font-semibold'>{user?.firstName}</span></span>
            </span>
            <img
              className={`h-8 w-8 rounded-full bg-gray-600`}
              src={user?.imageUrl}
              alt=""
            />
          </div>
        </div>
        <main className={`${sidebarCollapsed ? 'lg:pl-20' : 'lg:pl-64'} overflow-hidden transition-all duration-300 ease-in-out`}>
          <div className="px-4 sm:px-4 lg:px-4 overflow-hidden h-screen">{
            <IntercomProvider appId='okpouicg' autoBoot>
              {isImpersonation && organizationAdmin && !superOrgOwner && <CreateSuperOrg />}
              {(adminCenterEnabled || isImpersonation) && superOrgOwner &&  <AdminProvider>
                {tab == "Users" && <UserManagement />}
                {tab == "Teams" && <TeamManagement />}
                {tab == "Tags" && <TagManagement />}
                {tab == "Settings" && <Settings />}
              </AdminProvider>}
            </IntercomProvider>
          }</div>
        </main>
      </div>
    </>
  )
}
