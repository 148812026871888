import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { type IMicrophone, TimeoutError, checkMicrophonePermissions, getMicrophonesAvailable, mimeTypeExtensionMap } from '../utils/RecordingUtils';
import va from '@vercel/analytics';
import { useAuth, useOrganization, useUser } from '@clerk/clerk-react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { cancelSession, createSession, fetchSessionById, RecordingBackupStatus, RecordingConsent, RecordingFileStatus, recordSession, saveName, updatedSession } from '../ServerActions';
import { useSupabase } from '../supabase/SupabaseContext';
import { GetRecordingFullBackupSignedURL, GetRecordingsPartialBackupSignedURL, GetRecordingsSignedURL } from '../supabase/supabaseProxy';
import { MemberObject, sortMembersList } from '../utils/MemberUtils';
import { SessionObject } from '../utils/SessionUtils';
import { useVetRec } from './VetRecProvider';
import { getTemplateTypeDefaultKey, TemplateType } from '../components/templates/TemplateUtils';
import { PermissionStatus, RecordingStatus, type RecordingContextType, type StartRecordingArgs, type StopRecordingArgs} from './RecordingContextType';

export { PermissionStatus, RecordingStatus } from './RecordingContextType'

const RecordingContext = createContext<RecordingContextType | undefined>(undefined);

export const useRecording = () => {
  const context = useContext(RecordingContext);
  if (!context) {
    throw new Error('useRecording must be used within a RecordingProvider');
  }
  return context;
};

interface RecordingProviderProps {
  children: ReactNode;
}

export const RecordingProvider: React.FC<RecordingProviderProps> = ({ children }) => {
    // Providers
    const { user } = useUser()
    const { search } = useLocation();
    const { getToken, orgId } = useAuth();
    const { organization } = useOrganization()
    const {uploadRecordingToSupabase} = useSupabase()
    const { getTeamMemberPublicMetadata, language } = useVetRec()

    // Session Info
    const [sessionId, setSessionId] = useState<string>(uuidv4())
    const [name, setName] = useState<string>("")
    const [petNames, setPetNames] = useState<string[]>([]);
    const [consent, setConsent] = useState<boolean>(false)
    const [consentPending, setConsentPending] = useState<boolean>(false)
    const [recordingId, setRecordingId] = useState<string>(uuidv4())
    const [addToRecording, setAddToRecording] = useState<boolean>(false)
    const [owner, setOwner] = useState<string | undefined>(user?.primaryEmailAddress?.emailAddress)
    const [anyRecording, setAnyRecording] = useState<boolean>(false)
    const [selectedMicrophone, setSelectedMicrophone] = useState<IMicrophone>()
    const [activeRecording, setActiveRecording] = useState<boolean>(false)
    const [spokenLanguage, setSpokenLanguage] = useState<string>(language)

    // Context State
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
    const [mediaStream, setMediaStream] = useState<MediaStream>();  
    const [audioBlobs, setAudioBlobs] = useState<Blob[]>([]);
    const [backupStatusComplete, setBackupStatusComplete] = useState<boolean>(false);
    const [recordingState, setRecordingState] = useState<RecordingStatus>(RecordingStatus.NOTSTARTED);
    const [seconds, setSeconds] = useState<number>(0);
    const [timerActive, setTimeActive] = useState<boolean>(false);
    const [recordingAllowed, setRecordingAllowed] = useState<PermissionStatus>(PermissionStatus.CHECKING);
    const [microphones, setMicrophones] = useState<IMicrophone[]>();
    const [preferredMimeType, setPreferredMimeType] = useState<string>()
    const [membersList, setMembersList] = useState<MemberObject[]>([])
    const [recordAs, setRecordAs] = useState<string>(user?.primaryEmailAddress?.emailAddress ?? "")
    const [existingTemplateId, setExistingTemplateId] = useState<string | undefined>(undefined)
    const [externalTypeId, setExternalTypeId] = useState<string | undefined>(undefined)
    const [uploadPercentage, setUploadPercentage] = useState<number>(0)

    async function setupRecorder(): Promise<[MediaRecorder | undefined, string]> {
        if(mediaRecorder && mediaRecorder.state === "recording"){
            //If for some reason it is already recording, don't set up another recorder
            return [undefined, recordingId];
        }

        if(!selectedMicrophone){
            setRecordingState(RecordingStatus.FAILED)
            return [undefined, recordingId];
        }

        try{
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedMicrophone.deviceId } // if undefined, uses default microphone
            })
            setMediaStream(stream);
            let preferredTypes = ['audio/webm; codecs=opus', 'audio/webm', 'audio/mp4'];
            let mimeType = preferredTypes.find(type => MediaRecorder.isTypeSupported(type)) || undefined;
            setPreferredMimeType(mimeType)
            let options = mimeType ? { mimeType } : {}
            const recorder = new MediaRecorder(stream, options);
            const newRecordingId = uuidv4();
            setRecordingId(newRecordingId)
            setUploadPercentage(0)
            setAudioBlobs([]);
            let properties =  {
                date:(new Date()).toUTCString(),
                mimeType:mimeType ?? "Not default: " + recorder.mimeType,
                sessionId: sessionId
            }
            va.track("MediaRecorder MimeType", properties)
            setMediaRecorder(recorder);

            recorder.onstart = () => {
                setRecordingState(RecordingStatus.RECORDING);
            }

            recorder.onerror = (event) => {
                console.error("MediaRecorder Error: ", event)
                let properties =  {
                    date:(new Date()).toUTCString(),
                    sessionId: sessionId,
                    error: "Something happened"
                }
                va.track("MediaRecorder_Error", properties)
            }

            // Add blobs to state
            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setAudioBlobs((prevBlobs) => [...prevBlobs, event.data]);
                }
            };

            // Process recording stop
            recorder.onstop = () => {
                setRecordingState(RecordingStatus.UPLOADING);
                setTimeActive(false);
                if (stream) {
                    stream.getTracks().forEach(track => {
                        if (track.readyState === "live") { // Check if the track is still live
                            track.stop();
                        }
                    });
                    setMediaStream(undefined);
                }
            };

            return [recorder, newRecordingId];
        }catch(err){
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => {
                    if (track.readyState === "live") { // Check if the track is still live
                        track.stop();
                    }
                });
                setMediaStream(undefined);
            }
            setMediaRecorder(undefined);
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: sessionId,
                error: err instanceof Error ? err.toString() : ""
            }
            va.track("MediaRecorder_Setup_failed", properties)

            return [undefined, recordingId];
        }
    }

    async function getOrganizationMember(){
        if(orgId){
            let members = await organization?.getMemberships({pageSize:500})
            
            let memberList: MemberObject[] | undefined = members?.data.map((member) => { 
                let name 
                if(member.publicUserData.firstName && member.publicUserData.lastName){
                    name = member.publicUserData.firstName + " " + member.publicUserData.lastName
                }
                else{
                    name = undefined
                }
                return {
                    name: name,
                    identifier: member.publicUserData.identifier,
                    clerk_id: member.publicUserData.userId,
                    get_default_template: async (templateType: TemplateType | undefined) => {
                        const userMetadata = await getTeamMemberPublicMetadata(member.publicUserData.userId ?? "")
                        return userMetadata[getTemplateTypeDefaultKey(templateType) as keyof typeof userMetadata] as string
                    }
                }
            })

            sortMembersList(memberList)

            if(memberList) setMembersList(memberList)
        }
    }

    // Handle recording timer
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
    
        // Start the interval only if timerActive is true
        if (timerActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);
        }
    
        // Cleanup function to clear the interval
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timerActive]);  // Dependency on timerActive to restart the effect when it changes    

    // Handle session setup
    useEffect(() => {
        async function processQueryParams() {
            const queryParams = new URLSearchParams(search);
            const session_param = queryParams.get('session');
            if (session_param) {
                setAddToRecording(true);
                setSessionId(session_param); // Assuming this sets the state for sessionId used below.
    
                try {
                    setRecordingState(RecordingStatus.PREPARING);
                    const token = await getToken({ template: "supabase" });
                    const temp_session: SessionObject = await fetchSessionById(session_param, token || "");
                    let anyRecording = false;
                    if(temp_session.consent){
                        anyRecording = true;
                    }
                    else{
                        anyRecording = temp_session.status.recording_backup || temp_session.status.recording_files ? true : false;
                    }
                    if (temp_session) {
                        setName(temp_session.name);
                        setPetNames(temp_session.pets);
                        setAnyRecording(anyRecording);
                        setOwner(temp_session.owner);
                        setRecordAs(temp_session.owner)
                        setExistingTemplateId(temp_session.template_id);
                        setExternalTypeId(temp_session.external_template_id);
                        setSpokenLanguage(temp_session.spoken_language ?? "English (US)");
                    }
                    setRecordingState(RecordingStatus.NOTSTARTED);
                } catch (err) {
                    setRecordingState(RecordingStatus.FAILED_NOT_FOUND);
                    const properties = {
                        date: (new Date()).toUTCString(),
                        sessionId: session_param
                    };
                    va.track("Failed_Get_AddRecording", properties);
                }
            }
        }
        processQueryParams();
    }, []);

    useEffect(() => {
        getOrganizationMember()
    }, [orgId])

    useEffect(() => {
        setSpokenLanguage(language)
    }, [language])

    // Handle blobs added to array
    useEffect(() => {
        const uploadChunks = async (blobs: BlobPart[], isFull: boolean) => {
            const maxRetries = 3;
            let attempts = 0;
            let success = false;
    
            while (!success && attempts < maxRetries) {
                try {
                    const partial_id = uuidv4();
                    const mimeType = mediaRecorder?.mimeType ?? preferredMimeType ?? "audio/webm";
                    const recordingBlob = new Blob(blobs, { type: mimeType });
                    const fileExtension = mimeTypeExtensionMap[mimeType];
                    const fileName = isFull ? `${sessionId}_${recordingId}${fileExtension}` : `${sessionId}_${recordingId}_${partial_id}${fileExtension}`;
                    const recordingFile = new FormData();
                    recordingFile.append("file", recordingBlob, fileName);
                    const file = recordingFile.get("file");
                    const path = isFull ? `full/${recordingId}/` : `partial/${recordingId}/`;
    
                    // Ensure token retrieval is handled correctly
                    let token = await getToken({ template: "supabase" }) || await getToken({ template: "supabase" }) || "";
                    let signed_url = isFull 
                        ? await GetRecordingFullBackupSignedURL(sessionId, recordingId, fileExtension.split(".")[1], token)
                        : await GetRecordingsPartialBackupSignedURL(sessionId, recordingId, partial_id, fileExtension.split(".")[1], token);
                    
                    await uploadRecordingToSupabase(file, signed_url, mimeType, sessionId, (value) => {});
                    success = true;
                } catch (error) {
                    attempts++;
                    console.error(`Attempt ${attempts}: Silent failure in uploading ${isFull ? "full" : "partial"} backup.`);
                    if (attempts >= maxRetries) {
                        let properties = {
                            date: (new Date()).toUTCString(),
                            sessionId: sessionId,
                            error: error instanceof Error ? error.toString() : "Unknown Error"
                        };
                        va.track(isFull ? "Full_BackUp_Failed" : "Partial_BackUp_Failed", properties);
                    } else {
                        const backoffTime = 500 * Math.pow(2, attempts);
                        await new Promise(resolve => setTimeout(resolve, backoffTime));
                    }
                }
            }
        };

        const markBackupComplete = async () => {
            if (!backupStatusComplete) {
                await RecordingBackupStatus(sessionId, recordingId, await getToken({ template: "supabase" }) ?? "")
                setBackupStatusComplete(true);
            }
        }

        const handleUploads = async () => {
            if (mediaRecorder && recordingState === RecordingStatus.RECORDING) {
                if (audioBlobs.length === 1) {
                    if(audioBlobs[0].size > 1000){
                        await uploadChunks(audioBlobs, false);
                        markBackupComplete();
                    }
                }
                if (audioBlobs.length % 30 === 0 && audioBlobs.length > 0 && audioBlobs.length < 7200) {
                    const start = Math.max(1, audioBlobs.length - 31);
                    const finish = audioBlobs.length;
                    let firstFrame = audioBlobs[0].size > 1000 ? [audioBlobs[0]] : audioBlobs.slice(0,2);
                    await uploadChunks([...firstFrame, ...audioBlobs.slice(start, finish)], false);
                    markBackupComplete();
                }
                if (audioBlobs.length % 180 === 0 && audioBlobs.length > 0 && audioBlobs.length < 7200) {
                    await uploadChunks(audioBlobs, true);
                }
            }
        };
        
        handleUploads();

    }, [audioBlobs]);

    const downloadRecording = () => {
        if (audioBlobs.length === 0) return;

        const mimeType = mediaRecorder?.mimeType ?? preferredMimeType ?? 'audio/webm';
        const fileExtension = mimeTypeExtensionMap[mimeType];
        const fileName = `recording_${sessionId}${fileExtension}`;
        const recordingBlob = new Blob(audioBlobs, { type: mimeType });

        const url = URL.createObjectURL(recordingBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const handleUpload = async (reupload:boolean = false) => {

        if (reupload){
            setRecordingState(RecordingStatus.REUPLOADING);
        }

        if (!consent || audioBlobs.length === 0) {
            if (!consent) {
                setRecordingState(RecordingStatus.NOTSTARTED);
            } else if (audioBlobs.length === 0) {
                va.track("Recording_Upload_Empty", {
                    date: new Date().toUTCString(),
                    sessionId: sessionId
                });
            }
            return;
        }
        //Retry getting token
        let token = await getToken({ template: "supabase" });
        if(!token){
            token = await getToken({ template: "supabase" }) ?? "";
        }

        // Prep recording file
        const mimeType = mediaRecorder?.mimeType ?? preferredMimeType ?? 'audio/webm';
        const fileExtension = mimeTypeExtensionMap[mimeType];
        const fileName = `${sessionId}_${recordingId}${fileExtension}`;
        const recordingBlob = new Blob(audioBlobs, { type: mimeType });
        const recordingFile = new FormData();
        recordingFile.append("file", recordingBlob, fileName);
        const file = recordingFile.get("file");

        const maxRetries = 3; // Max retry attempts
        let attempt = 0;
        let success = false;

        // Upload using signed URL
        while (attempt < maxRetries && !success) {
            try {
                const signed_url = await GetRecordingsSignedURL(sessionId, recordingId, fileExtension.split(".")[1], token);
                await uploadRecordingToSupabase(file, signed_url, mimeType, sessionId, setUploadPercentage);
                setAnyRecording(true);
                setAudioBlobs([]);
                setRecordingState(RecordingStatus.STOPPED);
                success = true; // Break loop on success
                RecordingFileStatus(sessionId, recordingId, await getToken({ template: "supabase" }) ?? "")
            } catch (error) {
                attempt++;
                let errorMessage = "An unknown error occurred";
                if (error instanceof Error) {
                    errorMessage = error.message;
                }
                if (attempt >= maxRetries) {
                    setRecordingState(RecordingStatus.FAILED_UPLOAD);
                    va.track("Recording_Upload_Failed", {
                        date: new Date().toUTCString(),
                        sessionId: sessionId,
                        error: errorMessage
                    });
                } else {
                    const backoffTime = 500 * Math.pow(2, attempt); // Exponential backoff
                    await new Promise(resolve => setTimeout(resolve, backoffTime));
                }
            }
        }

        if (name && success) {
            await saveName(sessionId, name, token, petNames);
        }
    };
    
    // Handle upload
    useEffect(() => {
    
        if (recordingState === RecordingStatus.UPLOADING) {
            handleUpload(false);
        }
        else if(recordingState === RecordingStatus.FAILED){
            if(mediaRecorder && mediaRecorder.state === "recording"){
                setConsentPending(false)
                setConsent(false)
                mediaRecorder.onstop = () => {
                    setRecordingState(RecordingStatus.NOTSTARTED);
                }
                mediaRecorder.stop()
                setMediaRecorder(undefined)
            } 
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => {
                    if (track.readyState === "live") { // Check if the track is still live
                        track.stop();
                    }
                });
                setMediaStream(undefined);
            }
        }
    }, [recordingState]);

    // Handle permissions and setup
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const permission = await checkMicrophonePermissions(5000);
                if (permission) {
                    setRecordingAllowed(PermissionStatus.FETCHING);
                    const microphones = await getMicrophonesAvailable();
                    setMicrophones(microphones);
                    setSelectedMicrophone(microphones[0]);
                    setRecordingAllowed(PermissionStatus.GRANTED);
                } else {
                    setRecordingAllowed(PermissionStatus.DENIED);
                }
            } catch (err) {
                if (err instanceof TimeoutError) {
                    setRecordingAllowed(PermissionStatus.FAILED_TIMEOUT);
                    let properties = {
                        date: (new Date()).toUTCString(),
                        user: user?.primaryEmailAddress?.emailAddress ?? "",
                        error: "Permissions Timeout",
                        sessionId: sessionId
                    };
                    va.track("MicrophonePermission_timeout", properties);
                } else {
                    setRecordingAllowed(PermissionStatus.FAILED);
                    let properties = {
                        date: (new Date()).toUTCString(),
                        user: user?.primaryEmailAddress?.emailAddress ?? "",
                        error: String(err),
                        sessionId: sessionId
                    };
                    va.track("MicrophonePermission_failed", properties);
                }
            }
        };
    
        fetchPermissions();
    }, []);

    // Methods

    function createName(petNames: string[]): string {
        if (petNames.length === 0) return "";
        
        // If any name is empty, return empty string
        if (petNames.some(name => name.trim() === "")) return "";
        
        if (petNames.length === 1) return petNames[0];
        if (petNames.length === 2) return `${petNames[0]} and ${petNames[1]}`;
        return `${petNames.slice(0, -1).join(", ")} and ${petNames[petNames.length - 1]}`;
    }

    const startRecording = async ({ pets = [], session: sessionForced, owner: owner_forcer, reset, templateId }: StartRecordingArgs) => {
        let sessionName = name === "" && pets.length > 0 ? createName(pets) : name
        try{
            if(sessionForced) 
            {
                setActiveRecording(true) // if recording is forced, then it is from active
                setSessionId(sessionForced.id)
            }
            if(owner_forcer) setOwner(owner_forcer)
            if(reset) setSeconds(0)
            if(sessionName ||sessionForced) {
                const [mr, currentRecordingId] = await setupRecorder(); // Ensure setupRecorder is an async function
                let token = await getToken({template:"supabase"}) ?? ""
                if(mr){
                    mr.start(1000)
                    if(!addToRecording && !anyRecording && !sessionForced){
                        // New visit so create entry
                        await createSession({
                            session: sessionId,
                            name: sessionName,
                            token,
                            manual_notes: false,
                            recording: true,
                            consent: true,
                            pets,
                            owner:
                                recordAs === user?.primaryEmailAddress?.emailAddress
                                    ? undefined
                                    : recordAs,
                            template_id: templateId,
                        });
                    }
                    else{
                        // Adding to existing visit, so lets update status
                        await updatedSession(sessionForced ? sessionForced.id: sessionId, token)
                    }
                    let existingSession = sessionForced?.status.recording === "Processing" || sessionForced?.status.transcript === "Updated"
                    setConsentPending(!anyRecording && !existingSession ? true : false)
                    setConsent(anyRecording || existingSession ? true : false)
                    setTimeActive(true)
                    await recordSession({
                        session: sessionForced ? sessionForced.id : sessionId,
                        recording: true,
                        manual_notes: false,
                        microphone_name: selectedMicrophone?.label ?? "Default Microphone",
                        recording_id: currentRecordingId,
                        token,
                        template_id: templateId
                    })
                }
                else{
                    setRecordingState(RecordingStatus.FAILED)
                    throw new Error("Recording failed to start.")
                }
            }
            else{
                setRecordingState(RecordingStatus.FAILED_NAME)
            }
        }catch(err){
            setRecordingState(RecordingStatus.FAILED)
            setTimeActive(false)
            throw new Error("Failed to create session for recording.")
        }
    };

    const stopRecording = async ({ templateId }: StopRecordingArgs) => {
        mediaRecorder?.stop();
        setTimeActive(false)
        await recordSession({
            session: sessionId,
            recording: false,
            manual_notes: false,
            microphone_name: selectedMicrophone?.label ?? "Default Microphone",
            recording_id: recordingId,
            token: await getToken({template:"supabase"}) ?? "",
            template_id: templateId,
        })
    };

    const updateConsent = async (consent:boolean) => {
        if(consent){
            setConsent(true)
            setConsentPending(false)
            RecordingConsent(sessionId, await getToken({template:"supabase"}) ?? "")
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: sessionId
            }
            va.track("ConsentGrantedClick", properties)
        }
        else{
            mediaRecorder?.stop()
            setAudioBlobs([])
            setConsent(false)
            setConsentPending(false)
            if(!addToRecording && !activeRecording){ // if recordidng in active visit, can't cancel visit
                setSessionId(uuidv4())
                await cancelSession(sessionId, await getToken({template:"supabase"}) ?? "")
            }
            setSeconds(0)
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: sessionId,
            }
            va.track("ConsentCanceled", properties)
        }
    }

    const updateName = async (name:string) => {
        if(recordingState === RecordingStatus.FAILED_NAME){
            setRecordingState(RecordingStatus.NOTSTARTED)
        }
        setName(name)
    }

    const updateSelectedMicrophone = (microphone:IMicrophone) => {
        setSelectedMicrophone(microphone)
    }

    const updateRecordAs = async (recordAs:string) => {
        setRecordAs(recordAs)
        if(!addToRecording){
            setOwner(recordAs)
        }
        
        let properties =  {
            date:(new Date()).toUTCString(),
            sessionId: sessionId ?? "undefined",
            recordAs: recordAs
        }
        va.track("RecordAs_Select", properties)
    }

    return (
        <RecordingContext.Provider value={{
            anyRecording,
            seconds,
            sessionId,
            microphones,
            recordingState,
            startRecording,
            stopRecording,
            recordingAllowed,
            consent,
            updateConsent,
            name,
            updateName,
            petNames,
            setPetNames,
            addToRecording,
            updateSelectedMicrophone,
            selectedMicrophone,
            mediaStream,
            consentPending,
            membersList,
            recordAs,
            existingTemplateId,
            updateRecordAs,
            handleUpload,
            downloadRecording,
            uploadPercentage,
            spokenLanguage,
            externalTypeId
        }}>
            {children}
        </RecordingContext.Provider>
    );
};