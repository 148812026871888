import { processPDFSummary } from "../ServerActions";
import { GetPreHistoryUploadURL, SignedUrlResponse } from "../supabase/supabaseProxy";
import { SessionObject } from "./SessionUtils";
import { v4 as uuidv4 } from 'uuid';
import { TemplateObject } from "./TemplateObject";

export function convertPreHistoryToString(preHistory: {[key: string]: string}): string {
    const lines: string[] = [];
    for (const [key, value] of Object.entries(preHistory)) {
      const processedValue = formatPreHistorySectionForCopy(value)
      lines.push(`${key.replace(/_/g, ' ')}:\n${processedValue}`);
    }
    return lines.join("\n\n");
}

export function formatPreHistorySectionForCopy(value: string): string {
  return value.trim()
}

export async function uploadFilesAndStartRecap(
    sessionForRecap: SessionObject,
    files: File[],
    uploadToSupabaseSignedURL: (file: any, signedURL: SignedUrlResponse) => Promise<void>,
    supabaseToken: string,
    isRegen: boolean,
    onlySummarizeLatest: boolean,
    additionalInstructions: string | undefined,
    template: TemplateObject | undefined
  ) {
    const session_id = sessionForRecap.id;
    const upload_id = uuidv4();
    for (let i = 0; i < files.length; i++) {
      let extension = files[i].name.split(".").pop();
      let signedURL = await GetPreHistoryUploadURL(
        session_id,
        upload_id,
        extension ?? "",
        i.toString(),
        supabaseToken
      );

      await uploadToSupabaseSignedURL(files[i], signedURL);
    }

    await processPDFSummary(
      session_id, 
      supabaseToken, 
      isRegen, 
      onlySummarizeLatest ? upload_id : undefined, 
      additionalInstructions,
      template ? `${template.organization}/${template.id}` : "VetRec_RecordsRecap/dynamic_records_recap_basic");
  }