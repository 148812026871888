import config from '../config.json'

const url = import.meta.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

export async function get_user_visits(start:number, end:number, scope:string, jwt:string){
    const endpoint = url + `/v1/analytics/user_visits?start=${start}&end=${end}&scope=${scope}`;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error getting user visits data from the API.`);
    }
}

export async function get_user_visits_by_org(start:number, end:number, scope:string, jwt:string){
    const endpoint = url + `/v1/analytics/user_visits_by_org?start=${start}&end=${end}&scope=${scope}`;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error getting user visits by org data from the API.`);
    }
}

export async function get_org_visits(start:number, end:number, scope:string, jwt:string){
    const endpoint = url + `/v1/analytics/org_visits?start=${start}&end=${end}&scope=${scope}`;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error getting org visits data from the API.`);
    }
}

export async function get_org_util(start:number, end:number, scope:string, jwt:string){
    const endpoint = url + `/v1/analytics/org_util?start=${start}&end=${end}&scope=${scope}`;
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error getting org util data from the API.`);
    }
}