import { useAuth } from "@clerk/clerk-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useCallback, useEffect, useState } from "react";
import { GetUserSignInToken } from "../ServerActions";
import logo from '../assets/Logo.png'
 
export default function SSORedirect() {
  const { getToken, signOut } = useAuth()
  const [issue, setIssue] = useState<Boolean>(false)
  const [token, setToken] = useState<string>()

  const redirectWithToken = useCallback(async function redirectWithToken(){
    try{
      let token = await GetUserSignInToken(await getToken({template:"supabase"}) ?? "")
      if(token){
        setToken(token)
        window.open('vetrec://(tabs)/index?token=' + token, '_blank');
      }
      else{
        setIssue(true)
      }
    }
    catch(e){
      setIssue(true)
    }
  }, [getToken]);

  function openApp(){
    window.open('vetrec://home?token=' + token, '_blank');
  }

  useEffect(() => {
    setToken(undefined)
    setIssue(false)
    redirectWithToken()
  }, [redirectWithToken]);
  
  return <div className="flex flex-col h-screen w-screen items-center justify-start gap-y-8 mt-8">
    <div className="">
      <img
        className="h-20 w-auto"
        src={logo}
        alt="Company logo"
      />
    </div>
    <div className="w-full text-center text-gray-600">
      <span className="font-semibold text-gray-900 text-lg">You are logged in!</span><br/>Redirecting back to app automatically...
    </div>
    <div className="flex flex-row gap-x-4">
      {token && <button className="bg-blue-600 hover:bg-blue-500 rounded-md text-white font-semibold p-2" onClick={() => openApp()}>Open app</button>}
      {token && <button className="bg-red-600 hover:bg-red-500 rounded-md text-white font-semibold p-2" onClick={() => signOut()}>Sign out</button>}
    </div>
    {issue && <div className="flex flex-row mt-4 border border-gray-100 rounded-lg mx-auto px-4 py-2 bg-yellow-100 gap-x-4 w-[20rem] items-center text-sm shadow-xl text-center">
      <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600"/>
      There was an issue with the login. Please try again.
    </div>}
  </div>
}