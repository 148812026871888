import React, { useCallback } from 'react';
import { User, Team, Tag } from '../types/user';
import UserTableRow from './UserTableRow';
import { UserRole } from '../../settings/UserRoles';
import { useAdmin } from '../../../providers/AdminProvider';
import { Spinner } from '../../../utils/Spinner';

interface UserTableProps {
  users: User[];
  selectedUsers: string[];
  selectAll: boolean;
  teams: Team[];
  tags: Tag[];
  onSelectAll: () => void;
  onSelectUser: (userId: string) => void;
  onUpdateUserTeams: (user: User, teamsToAdd:string[], teamsToRemove:string[]) => Promise<void>;
  onUpdateUserTags: (user: User, tagToAdd:Tag) => Promise<void>;
  onUpdateUserRole: (users: User[], newRole: UserRole) => Promise<void>;
}

const UserTable: React.FC<UserTableProps> = ({
  users,
  selectedUsers,
  selectAll,
  teams,
  tags,
  onSelectAll,
  onSelectUser,
  onUpdateUserTeams,
  onUpdateUserTags,
  onUpdateUserRole
}) => {

  const { loading } = useAdmin();

  const getTeamById = useCallback((teamId: string): Team | undefined => {
    return teams.find(team => team.id === teamId);
  }, [teams]);

  const getTagById = useCallback((tagId: string): Tag | undefined => {
    return tags.find(tag => tag.id === tagId);
  }, [tags]);

  return (
    <div className="mt-6 flex flex-col">
      <div className="-my-2 -mx-4 overflow-visible sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full max-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className={`overflow-visible border border-gray-300 ring-opacity-5 rounded-md max-w-full overflow-y-auto ${selectedUsers.length > 0 ? "h-[40vh] lg:h-[65vh]" : "h-[50vh] lg:h-[65vh]"} thin-scrollbar thin-scrollbar-x`}>
            <table className="min-w-full max-w-full divide-y divide-gray-600 rounded-md">
              <thead className="bg-gray-50 sticky top-0 z-10 border-b-1 border-gray-300 shadow-md">
                <tr>
                  <th scope="col" className="relative py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    <input
                      type="checkbox"
                      className="absolute h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 left-4 top-1/2 -mt-2"
                      checked={selectAll}
                      onChange={onSelectAll}
                    />
                    <span className="ml-7">User</span>
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Teams</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tag</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {users.length > 0 && !loading ? (
                  users.map((user) => (
                    <UserTableRow
                      key={user.id}
                      user={user}
                      teams={teams}
                      tags={tags}
                      isSelected={selectedUsers.includes(user.id)}
                      onSelectUser={onSelectUser}
                      onUpdateUserTeams={onUpdateUserTeams}
                      onUpdateUserTags={onUpdateUserTags}
                      getTeamById={getTeamById}
                      getTagById={getTagById}
                      onUpdateUserRole={onUpdateUserRole}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      <div className="flex flex-col items-center gap-y-2 w-full">
                        {loading && <Spinner timer={false} size='h-5 w-5'/>}
                        {loading ? "Loading users..." :"No users found matching your filters"}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
