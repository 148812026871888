import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
  } from "react";
import { DateValueType } from "react-tailwindcss-datepicker";
import { DateTypeToFloat } from "../utils/TimeUtils";
import { get_org_util, get_org_visits, get_user_visits, get_user_visits_by_org } from "../serverActions/analytics";
import { DataToGraphFormat, UserDataIntoRows, UtilizationDataToGraphFormat } from "../utils/AnalyticsUtils";
import { getDatesBetween } from "../utils/time";

interface AnalyticsContextType {
  timeframe: string;
  handleTimeFrame: (timeframe: string) => void;
  scope: string;
  handleScope: (scope: string) => void;
  searchDate: DateValueType
  setSearchDate: (value: DateValueType) => void
  graphData: {
    labels: string[],
    datasets: any[]
  } | undefined
  userData: {[key:string] : {[key:string] : number}} | undefined
  loadingAnalytics: boolean
  totalVisits: number
  totalUsers: number
  totalMembers: number
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(
  undefined
);

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error(
      "useAnalytics must be used within a AnalyticsProvider"
    );
  }
  return context;
};

interface AnalyticsProviderProps {
  children: ReactNode;
}
  
export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const [timeframe, setTimeframe] = useState<string>("daily");
  const [searchDate, setSearchDate] = useState<DateValueType>({
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(new Date().setDate(new Date().getDate()))
  }); 
  const [scope, setScope] = useState<string>("user")
  const [graphData, setGraphData] = useState<{
    labels: string[],
    datasets: any[]
  }>()
  const [userData, setUserData] = useState<{[key:string] : {[key:string] : number}}>({})
  const { organization } = useOrganization()
  const { orgId, getToken} = useAuth()
  const { user } = useUser()
  const [loadingAnalytics, setLoadingAnalytics] = useState<boolean>(false)
  const [totalVisits, setTotalVisits] = useState<number>(0)
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [totalMembers, setTotalMembers] = useState<number>(0)

  const handleTimeFrame = (timeframe: string) => {
    setTimeframe(timeframe);
  }

  const handleScope = (scope: string) => {
    setScope(scope);
  }

  useEffect(() => {
    const getMemberships = async () => {
      if(!orgId){
        return
      }
      let memberships = await organization?.getMemberships({pageSize:500})
      setTotalMembers(memberships?.data.length ?? 0)
    }
    getMemberships()
  }, [orgId])

  const loadAnalytics = async () => {
    if(!searchDate){
      return
    }

    setLoadingAnalytics(true)

    let start = DateTypeToFloat(searchDate?.startDate, true)
    let end = DateTypeToFloat(searchDate?.endDate, false)
    let labels = getDatesBetween(start, end, timeframe)
    
    if(scope === "user"){
      let user_visits =  await get_user_visits(start, end, timeframe, await getToken({template:"supabase"}) ?? "")
      let {graphData , totalCount} = DataToGraphFormat(user_visits, timeframe)
      setTotalVisits(totalCount)
      setGraphData({
        labels: labels,
        datasets: graphData,
      })
      let user_rows = UserDataIntoRows(user_visits, labels.slice(-4), timeframe)
      setTotalUsers(Object.keys(user_rows).length)
      setUserData(user_rows)
    }
    else if(scope === "team"){
      let org_visits = await get_org_visits(start, end, timeframe, await getToken({template:"supabase"}) ?? "")
      let org_util = await get_org_util(start, end, timeframe, await getToken({template:"supabase"}) ?? "")
      let {graphData , totalCount} = DataToGraphFormat(org_visits, timeframe)
      setTotalVisits(totalCount)
      let util_data = UtilizationDataToGraphFormat(org_util, timeframe)
      //graphData.push(util_data)
      setGraphData({
        labels: labels,
        datasets: graphData,
      })
      let users_visits =  await get_user_visits_by_org(start, end, timeframe, await getToken({template:"supabase"}) ?? "")
      let user_rows = UserDataIntoRows(users_visits, labels.slice(-4), timeframe)
      setTotalUsers(Object.keys(user_rows).length)
      setUserData(user_rows)
    }
    setLoadingAnalytics(false)
  }

  useEffect(() => {
    loadAnalytics()
  }, [scope, timeframe, searchDate, orgId])
  
  return (
    <AnalyticsContext.Provider
      value={{
          timeframe,
          loadingAnalytics,
          handleTimeFrame,
          searchDate,
          setSearchDate,
          scope,
          handleScope,
          graphData,
          userData, 
          totalUsers,
          totalVisits,
          totalMembers
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
