import { OrganizationProfile, UserProfile, useAuth, useOrganization, useOrganizationList, useUser } from "@clerk/clerk-react"
import { useEffect, useState } from "react";
import {  UsersIcon, UserIcon, AdjustmentsHorizontalIcon, LanguageIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import va from '@vercel/analytics';
import { CreateOrganizationModal } from "./components/settings/CreateOrganizationModal";
import { UserControlsPage } from "./components/settings/UserControlsPage";
import { OrganizationControlsPage } from "./components/settings/OrganizationControls";
import { useVetRec } from "./providers/VetRecProvider";
import { UserOrganizationInvitationResource } from "@clerk/types";
import { useLocation, useNavigate } from "react-router-dom";
import { UserSpellingPage } from "./components/settings/UserSpellingPage";
import { getStringFromUserRole } from "./components/settings/UserRoles";
import { DeleteWarningPage } from "./components/settings/DeleteWarningPage";

// Define the types for your props
interface ConfigurationProps {
    
}

const tabs = [
    { name: 'Account', id: 'account', icon: UserIcon, current: true },
    { name: 'Team', id: 'team', icon: UsersIcon, current: true },
    // { name: 'Plan', id: 'plan', icon: CreditCardIcon, current: false },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Settings(props:ConfigurationProps) {
    const { user } = useUser()
    const navigate = useNavigate()
    const { membership } = useOrganization()
    const { inOrganization, organizationAdmin, invitations, acceptInvitation } = useVetRec()
    const location = useLocation();

    const [openInvitations, setOpenInvitations] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<string>("team")
    const [create, setCreate] = useState<boolean>(false)
    
    useEffect(() => {
        const userControlsHashes = ['#/user-controls', '#/user-vocabulary'];
        if (userControlsHashes.includes(location.hash)) {
            setSelectedTab('account');
        } else {
            setSelectedTab('team');
        }
    }, []);

    function handleTabChange(value:string): void {
        setSelectedTab(value)
        navigate(`/settings/`)
        let properties =  {
            date:(new Date()).toUTCString(),
            tab_destination: value
        }
        va.track("Settings_TabChange", properties)
    }

    function handleCreateClick(): void {
        setCreate(!create)
    }

    async function handleAcceptInvitation(invitation: UserOrganizationInvitationResource): Promise<void> {
        await acceptInvitation(invitation)
        setOpenInvitations(false)
    }

    return(<>
        {/*Header*/}
        <div className="border-b border-gray-400 pb-5 mb-3 py-4 sm:pt-10 flex flex-row items-center justify-between">
            <div className="flex flex-row gap-x-8 items-center">
                <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:text-3xl sm:tracking-tight">
                    Settings
                </h2>
                <SettingsTabs selectedTab={selectedTab} handleTabChange={handleTabChange}/>
            </div>
            {invitations && invitations.length > 0 && (
                <div className="flex flex-col justify-end relative">
                    <div
                        className="px-4 py-2 text-sm border border-gray-400 shadow rounded-md flex gap-x-2 items-center cursor-pointer hover:bg-gray-300 w-48"
                        onClick={() => setOpenInvitations((prevStatus) => !prevStatus)}
                    >
                        Pending Invitations{" "}
                        <span className="font-semibold text-white rounded-full bg-red-600 p-3 h-4 w-4 text-xs flex items-center justify-center">
                            {invitations.length}
                        </span>
                    </div>
                    {openInvitations && (
                        <div className="absolute top-full right-0 mt-2 border border-gray-300 rounded-md bg-white w-72 z-10">
                            <div className="flex flex-col gap-y-4 px-4 py-2 shadow">
                                <div className="border-b border-gray-300 pb-2 text-md font-semibold">
                                    Pending Invitations
                                </div>
                                {invitations.map((invitation) => (
                                    <div
                                        key={invitation.id}
                                        className="flex flex-row items-center justify-between py-2"
                                    >
                                        <div className="text-sm w-48 truncate">
                                            {invitation.publicOrganizationData.name}
                                        </div>
                                        <button
                                            className="text-xs text-main-button-text bg-main-button p-1 rounded-md hover:bg-main-button-hover"
                                            onClick={() => handleAcceptInvitation(invitation)}
                                        >
                                            Accept
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
        {/*Account*/}
        {selectedTab === "account" && <div className='flex flex-col sm:flex-row pt-1 gap-x-8 gap-y-8'>
            <div className='w-full rounded-lg flex flex-col'>
                <div className={`bg-white py-5 sm:px-6 h-full`}>
                    <div className="flex flex-col sm:flex-row sm:items-center gap-y-4">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Your Account</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Manage your account settings
                            </p>
                        </div>
                    </div>
                    <div className="w-full mt-8">
                        <UserProfile appearance={{
                            elements:{
                                rootBox:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth: "100%"
                                },
                                cardBox:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth: "100%",
                                    margin: "0px"
                                },
                                profileSection__danger: {
                                    display: "none"
                                }
                            }
                        }}>
                            <UserProfile.Page
                            label="Controls"
                            labelIcon={<AdjustmentsHorizontalIcon className="text-gray-500"/>}
                            url="user-controls"
                            >
                                <UserControlsPage/>
                            </UserProfile.Page>
                            <UserProfile.Page
                            label="Vocabulary"
                            labelIcon={<LanguageIcon className="text-gray-500"/>}
                            url="user-vocabulary"
                            >
                                <UserSpellingPage/>
                            </UserProfile.Page>
                            <UserProfile.Page
                            label="Delete Account"
                            labelIcon={<ExclamationTriangleIcon className="text-gray-500"/>}
                            url="delete-warning"
                            >
                                <DeleteWarningPage/>
                            </UserProfile.Page>
                        </UserProfile>
                    </div>
                </div>
            </div>
        </div>}
        {/*Team*/}
        {selectedTab === "team" && <div className='flex flex-col sm:flex-row pt-1 gap-x-8 gap-y-8'>
            <div className='w-full rounded-lg flex flex-col'>
                <div className={`bg-white px-4 py-5 sm:px-6 h-full`}>
                    <div className="flex flex-row sm:items-center justify-between gap-y-4 gap-x-10">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Team Members</h1>
                            <p className="mt-1 text-sm text-gray-500">
                                Manage the users within your team
                            </p>
                        </div>
                        <div className="flex flex-row items-center justify-center gap-x-4">
                            {!inOrganization && <button className="text-sm d p-1 rounded-lg bg-main-button text-main-button-text hover:bg-main-button-hover w-24 text-center font-semibold h-12" onClick={() => handleCreateClick()}>Create</button>}
                            {inOrganization && user && <div className="flex items-center justify-center text-sm d p-1 px-3 rounded-lg bg-gradient-to-br from-blue-700 to-blue-400 text-gray-100 text-center font-semibold h-10 shadow-md">{membership?.role ? getStringFromUserRole(membership.role) : undefined}</div>}
                        </div>
                    </div> 
                    <div className="w-full mt-8">
                        {inOrganization && !organizationAdmin && <OrganizationProfile appearance={{
                            elements:{
                                rootBox:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth: "100%"
                                },
                                cardBox:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth:"100%",
                                    margin: "0px"
                                }
                            }
                        }}>
                        </OrganizationProfile>}
                        {/*Weird behavior where it seems like the page inside of the profile needs to be added to the object and can't be conditional.*/}
                        {inOrganization && organizationAdmin && <OrganizationProfile appearance={{
                            elements:{
                                rootBox:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth: "100%"
                                },
                                cardBox:{
                                    width:"100%",
                                    minWidth:"100%",
                                    maxWidth:"100%",
                                    margin: "0px"
                                }
                            }
                        }}>
                            <OrganizationProfile.Page 
                                label="Controls"
                                labelIcon={<AdjustmentsHorizontalIcon className="text-gray-500"/>}
                                url="controls"
                            >
                                <OrganizationControlsPage/>
                            </OrganizationProfile.Page >
                        </OrganizationProfile>}
                    </div>
                </div>
            </div>
        </div>}
        <CreateOrganizationModal create={create} setCreate={setCreate}/>
    </>)
}

interface SettingsTabsProps {
    selectedTab: string;
    handleTabChange: (value:string) => void;
}

function SettingsTabs(props:SettingsTabsProps) {
    const {selectedTab, handleTabChange} = props
    return(<div>
        <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
                Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current)?.name}
                onChange={(event) => handleTabChange(event.target.value)}
            >
                {tabs.map((tab) => (
                    <option key={tab.id} value={tab.id}>{tab.name}</option>
                ))}
            </select>
        </div>
        <div className="hidden sm:block">
            <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                        <div
                            key={tab.name}
                            onClick={() => handleTabChange(tab.id)}
                            className={classNames(
                                selectedTab === tab.id
                                    ? 'border-main-lighter text-main'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'group inline-flex items-center border-b-2 py-2 px-1 text-sm font-medium cursor-pointer'
                            )}
                            aria-current={selectedTab === tab.id ? 'page' : undefined}
                        >
                            <tab.icon
                                className={classNames(
                                    selectedTab === tab.id ? 'text-main-lighter' : 'text-gray-400 group-hover:text-gray-500',
                                    '-ml-0.5 mr-2 h-5 w-5'
                                )}
                                aria-hidden="true" />
                            <span>{tab.name}</span>
                        </div>
                    ))}
                </nav>
            </div>
        </div>
    </div>)
}