import React, { useState, useEffect } from 'react';

const ControlsSettings: React.FC = () => {

    return (
        <div className="">
            <h2 className="text-xl font-bold mb-4">Organization Controls</h2>
        </div>
    );
};

export default ControlsSettings;