import { useState, useEffect, useMemo } from 'react'
import { Disclosure} from '@headlessui/react'
import { XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { cancelSession, fetchActiveSessions, fetchSessionById, processRecording, saveName } from './ServerActions';
import { BuildingOfficeIcon, CheckBadgeIcon, ChevronDownIcon, ExclamationTriangleIcon, MicrophoneIcon, PencilIcon, PencilSquareIcon, StopCircleIcon } from '@heroicons/react/24/solid';
import { TemplateObject } from './utils/TemplateObject';
import { Spinner } from './utils/Spinner';
import { SessionObject, displaySessionsInOrder } from './utils/SessionUtils';
import { formatTime } from './utils/RecordingUtils';
import va from '@vercel/analytics';
import { useNavigate } from 'react-router-dom';
import { useAuth, useOrganization, useUser } from '@clerk/clerk-react';
import TemplatePicker from './components/templates/TemplatePicker';
import MicrophoneWave from './components/history/MicrophoneWave';
import { isMobileDevice } from './utils/deviceUtils';
import EmptyState from './utils/EmptyState';
import { convertTimestampToDate } from './utils/time';
import NoSleep from 'nosleep.js';
import { RecordingStatus, useRecording, PermissionStatus } from './providers/RecordingProvider';
import MicrophonePicker from './components/history/MicrophonePicker';
import PermissionsHelper from './components/recording/PermissionsHelper';
import ErrorAlert from './components/Error';
import Reminder from './components/ReminderToast';
import { TemplateType } from './components/templates/TemplateUtils';
import ConsentDialog from './components/recording/ConsentDialog';

// Define the types for your props
interface HomeProps {

}

export default function Home(props:HomeProps) {
    const [loadingSession , setLoadingSessions] = useState<boolean>(false)
    const [loading, setLoading] = useState<string|undefined>(undefined)
    const [error, setError] = useState<string|undefined>(undefined)
    const [remainder, setRemainder] = useState<string|undefined>(undefined)
    const [sessions, setSessions] = useState<SessionObject[]>()
    const [petNameEditor, setPetNameEditor] = useState<string>('')
    const { getToken} = useAuth();
    const { user } = useUser()
    const {organization} = useOrganization()
    const  navigate = useNavigate()
    const [canWeHearYou , setCanWeHearYou] = useState<boolean>(false)
    const isMobile = useMemo(isMobileDevice, [])

    // Recording variables
    const [activeVisit, setActiveVisit] = useState<SessionObject|undefined>(undefined)

    // Template Variables... list bc one per session displayed
    const [sessionTemplateMapping, setSessionTemplateMapping] = useState<{[key:string]: TemplateObject}>({})

    const {sessionId, recordingState, stopRecording, startRecording, recordingAllowed, seconds, mediaStream, handleUpload, consent, consentPending} = useRecording()
    const [buttonClick, setButtonClick] = useState<boolean>(false)
    const [reuploadClick, setReuploadClick] = useState<boolean>(false)
    const [processingContext, setProcessingContext] = useState<boolean>(false)

    useEffect(() => {
        let isEnableNoSleep = false;
        const noSleep = new NoSleep();
        document.addEventListener(
          `click`,
          function enableNoSleep() {
            document.removeEventListener(`click`, enableNoSleep, false);
            try{
                noSleep.enable();
                isEnableNoSleep = true;
                //alert(`click and enable noSleep`);
            }catch(error){
                console.error('Failed to activate wake lock:', error);
                let properties =  {
                    date:(new Date()).toUTCString(),
                    ua:navigator.userAgent,
                    session:sessionId
                }
                va.track("WakeLock_Failed", properties)
            }
          },
          false
        );
        return () => {
          if (isEnableNoSleep) {
            try{
                noSleep.disable();
            }catch(error){
                console.error('Failed to release wake lock:', error);
            }
          }
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = async (e: BeforeUnloadEvent): Promise<void> => {
            if (recordingState === RecordingStatus.UPLOADING) {
                e.preventDefault();
                let properties =  {
                    date:(new Date()).toUTCString(),
                    sessionId: sessionId
                }
                va.track("Leaving before upload finished", properties)
            }
            else if (recordingState === RecordingStatus.RECORDING){
                e.preventDefault();
                let properties =  {
                    date:(new Date()).toUTCString(),
                    sessionId: sessionId
                }
                va.track("Leaving during recording", properties)
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        if(recordingState === RecordingStatus.FAILED_UPLOAD){
            setError("Failed to upload the recording. Please try again.")
        }else if (recordingState === RecordingStatus.FAILED_NAME){
            setError("Please enter a name for the session.")
        }else if (recordingState === RecordingStatus.FAILED_REPEATED_NAME_IN_MULTIPET){
            setError("Please enter a unique name for each pet.")
        }else if (recordingState === RecordingStatus.STOPPED){
            setRemainder("Upload successful! Start processing.")
        }

        return (): void => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [recordingState]);

    async function handleRecord(sesh:SessionObject): Promise<void> {
        try{
            if(recordingState === RecordingStatus.NOTSTARTED || recordingState === RecordingStatus.FAILED_NAME || recordingState === RecordingStatus.FAILED){    
                setButtonClick(true)
                setActiveVisit(sesh)
                await startRecording({ session: sesh, owner: sesh.owner, reset: true, pets: sesh.pets ?? [], templateId: sessionTemplateMapping[sesh.id]?.id })
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "start",
                    sessionId: sesh.id
                }
                va.track("Home_Recording_Button_Clicked", properties)
                setButtonClick(false)
            }
            else if(recordingState === RecordingStatus.RECORDING && activeVisit?.id === sesh.id){
                setButtonClick(true)
                await stopRecording({ templateId: sessionTemplateMapping[sesh.id]?.id })
                await refreshSession(sesh.id)
                //toggleWakeLock(false)
                setCanWeHearYou(false)
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "stop",
                    sessionId: sesh.id
                }
                va.track("Home_Recording_Button_Clicked", properties)
                setButtonClick(false)
            }
            else if(recordingState === RecordingStatus.STOPPED){
                setButtonClick(true)
                setActiveVisit(sesh)
                await startRecording({ session: sesh, owner: sesh.owner, reset: true, pets: sesh.pets ?? [], templateId: sessionTemplateMapping[sesh.id]?.id })
                let properties =  {
                    date:(new Date()).toUTCString(),
                    mediaRecorderState: "resume",
                    sessionId: sesh.id
                }
                va.track("Home_Recording_Button_Clicked", properties)
                setButtonClick(false)
            }
        } catch (error){
            setError(recordingState === RecordingStatus.RECORDING ? "Had an issue stopping the recording. Please try again." : "Had an issue starting recording. Please try again.")
            
            let properties =  {
                date:(new Date()).toUTCString(),
                mediaRecorderState: recordingState,
                sessionId: sesh.id,
                error: error instanceof Error ? error.toString() : ""
            }
            va.track("Home_Recording_Button_Clicked_Failed", properties)

            setButtonClick(false)
        }
    }

    const refreshSession = async (sessionId:string) => {
        try{
            let updated_session = await fetchSessionById(sessionId, await getToken({template:"supabase"}) ?? "")
            if(updated_session){
                setSessions((prev) => {
                    if(prev){
                        let new_sessions = prev.map((sesh) => {
                            if(sesh.id === sessionId){
                                return updated_session
                            }
                            return sesh
                        })
                        return displaySessionsInOrder(new_sessions)
                    }
                    return prev
                })
            }
        } catch(error){
            //setError("Had an issue refreshing the session. Please try again.")
        }
    }

    const getSessions = async () => {
        try{
            if(!loadingSession){
                setLoadingSessions(true)
                setSessions(undefined)
                let temp_sessions : SessionObject[] = await fetchActiveSessions(await getToken({template:"supabase"}) ?? "")
                setSessions(displaySessionsInOrder(temp_sessions))
                setLoadingSessions(false)
            }
        }
        catch{
            setError("Something happened when retrieving your sessions. Refresh the page.")
        }
    }

    async function handleCancelSession(sesh: SessionObject): Promise<void> {
        if(sessions) {
            let newSessions = sessions.filter(item => item.id !== sesh.id);
            setSessions(newSessions)
            await cancelSession(sesh.id, await getToken({template:"supabase"}) ?? "");
        }
    }

    async function handleNameChange(sessionId:string, name:string, pets:string[]): Promise<void> {
        setPetNameEditor(''); 
        await saveName(sessionId, name, await getToken({template:"supabase"}) ?? "", pets)
        await getSessions()
    }

    const handleInputChange = (id: any, newValue: any) => {
        if(sessions){
            setSessions(
                sessions.map(session =>
                  session.id === id ? { ...session, name: newValue } : session
                )
            );
        }
    };

    async function handleProcessRecording(session_process: SessionObject): Promise<void> {
        setProcessingContext(true)
        let properties =  {
            date:(new Date()).toUTCString(),
            sessionId: session_process.id ?? "undefined"
        }
        va.track("HomePage_ProcessRecording_Click", properties)

        const template = sessionTemplateMapping[session_process.id]

        let templateId = `${template?.organization}/${template?.id}`
        await processRecording(session_process.id, session_process.name, await getToken({template:"supabase"}) ?? "",  session_process.status.manual_notes !== "Not Started", templateId,true, session_process.pets ?? [])
        
        navigate('/history?session_id=' + session_process.id + "&tab=notes")
    }

    async function handleReupload(): Promise<void> {
        setReuploadClick(true)
        setRemainder("Trying to reupload the recording. Please wait...")
        await handleUpload(true)
        setReuploadClick(false)

        let properties =  {
            date:(new Date()).toUTCString(),
            sessionId: sessionId,
        }
        va.track("Home_Reupload_Attempt", properties)
    }

    useEffect(() => {
        getSessions()
    }, [])

    return(<div className='h-[90vh] lg:h-screen overflow-y-auto thin-scrollbar -mr-8 pr-8'>
        <div className="border-b border-gray-400 mb-5 flex justify-between items-center gap-y-10 py-4 sm:pt-10">
            <div>
                <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                    Welcome!
                </h2>
            </div>
            <a
                className="ml-3 inline-flex gap-x-2 items-center rounded-md bg-main-button px-3 py-2 text-sm font-semibold text-main-button-text shadow-md hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 h-12 self-center"
                href='/scribe'
            >
                <PencilSquareIcon className="-mr-0.5 sm:h-5 sm:w-5 h-8 w-8" aria-hidden="true" />
                <div className='block'>Start a visit</div>
            </a>
        </div>
        {loading && <div className='flex flex-col justify-center items-center pt-12 gap-y-6'>
            {loading}
            <Spinner size='w-10 h-10' timer={false}/>
        </div>}
        {!loading && <div className='mb-8'>
            <div className='w-full py-4 my-4'>
                <div className='flex flex-row gap-x-4 items-center justify-between sm:justify-start'>
                    <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                        Active Visits
                    </h2>
                </div>
                <div className='w-full flex sm:flex-row flex-col gap-y-8 gap-x-12 justify-between items-center'>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500 font-base">
                        Active visits are recordings you started but haven't submitted for transcription. Manage multiple patients here! <br/><br/>
                        {organization && <div className="sm:pr-2 pr-6 pb-2 flex justify-center sm:justify-start items-center gap-x-2 text-sm text-gray-500">
                            <BuildingOfficeIcon className="h-4 w-4 text-main-lighter" />= Colleague's
                        </div>}
                    </p>
                    <MicrophonePicker />
                </div>
            </div>
            {recordingAllowed !== PermissionStatus.GRANTED && < PermissionsHelper />}
            {recordingAllowed === PermissionStatus.GRANTED && <div className='grid gap-x-10 gap-y-6 grid-cols-1'>
                {sessions && sessions.length === 0 && <EmptyState />}
                {sessions && sessions.length > 0 && sessions?.map((sesh) => (<>
                    <Disclosure key={sesh.id} as="div" className='shadow-md rounded-lg flex flex-col w-full'>
                        {({ open }) => (<>
                            <Disclosure.Button className={`flex items-center justify-between ${open ? "rounded-t-lg bg-blue-300" : "rounded-lg bg-white"} border border-gray-400 px-4 py-5 sm:px-6 h-16`}>
                                <div className='flex flex-row gap-x-4 justify-center items-center'>
                                    {sesh.owner !== user?.primaryEmailAddress?.emailAddress && <div className="sm:h-8 sm:w-8 h-7 w-7 flex rounded-full bg-main items-center justify-center">
                                        <p className="text-center text-main-text">
                                            <BuildingOfficeIcon className="h-4 w-4"/>
                                        </p>
                                    </div>}
                                    <div className='flex flex-row gap-x-4 items-center'>
                                        <div className='flex flex-row gap-x-1'>
                                            {petNameEditor !== sesh.id && <h3 className="text-base font-semibold leading-6 text-main-text-darker">
                                            {sesh.name === "" ? "Pet" : sesh.name}
                                            </h3>}
                                            {petNameEditor !== sesh.id && <div className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer z-[999]"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setPetNameEditor(sesh.id)
                                            }}>
                                                <PencilIcon  className="h-4 w-4"/>
                                            </div>}
                                            {petNameEditor === sesh.id && <div className="flex flex-row items-center justify-center gap-x-3">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="block w-full h-10 rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 text-base"
                                            placeholder="Mr.Wiggles"
                                            value={sesh.name} // Bind value to state
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === ' ') {
                                                    event.stopPropagation();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleInputChange(sesh.id, event.target.value)
                                            }}
                                        />
                                        <div className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleNameChange(sesh.id, sesh.name, sesh.pets ?? [])}
                                        }>
                                            <CheckCircleIcon  className="h-6 w-6"/>
                                        </div>
                                            </div>}
                                        </div>
                                        <span className='text-sm text-gray-500'>{convertTimestampToDate(sesh.created_timestamp)}</span>
                                    </div>
                                </div>
                                <ChevronDownIcon className='h-6 w-6'/>
                            </Disclosure.Button>
                            <Disclosure.Panel className='flex flex-col gap-y-8 py-8 px-4 border border-gray-300 rounded-b-lg justify-center'>
                                <div className='flex flex-col sm:flex-row gap-y-8 gap-x-8 justify-center items-center'>
                                    <button
                                        type="button"
                                        className={`rounded-full ${recordingState === RecordingStatus.RECORDING && activeVisit?.id === sesh.id ? "bg-red-600 hover:bg-red-500": recordingState === RecordingStatus.RECORDING && activeVisit?.id !== sesh.id ? "bg-gray-600":"bg-main-button hover:bg-main-button-hover"} px-4 py-2.5 text-md font-semibold text-main-button-text shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-48 w-48 flex flex-col justify-center justify-items-center items-center gap-y-4`}
                                        onClick={() => handleRecord(sesh)} disabled={buttonClick}
                                    >
                                        <span>{recordingState === RecordingStatus.RECORDING && activeVisit?.id === sesh.id ? "Pause Recording" : recordingState === RecordingStatus.UPLOADING && activeVisit?.id === sesh.id ? "Uploading... ":"Resume Recording"}</span> 
                                        {recordingState === RecordingStatus.RECORDING && activeVisit?.id === sesh.id ? <StopCircleIcon className='h-12'/> : <MicrophoneIcon className='h-12'/>}
                                        {(recordingState === RecordingStatus.RECORDING || recordingState === RecordingStatus.STOPPED ) && activeVisit && sesh.id === activeVisit.id && <p>{formatTime(seconds)}</p>}
                                    </button>
                                    <div className='flex flex-col gap-y-4 sm:w-60 w-full'>
                                        <TemplatePicker 
                                            template={sessionTemplateMapping[sesh.id]} 
                                            templateId_force={sesh.template_id} 
                                            setTemplate={(template) => setSessionTemplateMapping({ ...sessionTemplateMapping, [sesh.id]: template })}
                                            className='h-12' 
                                            templateBackground='bg-white' 
                                            pickerWidth='w-full' 
                                            type={TemplateType.MEDICAL} 
                                            external_template_id={sesh.external_template_id}/>
                                        {(recordingState !== RecordingStatus.FAILED_UPLOAD) && sesh.id === activeVisit?.id && <button
                                            className={`inline-flex gap-x-2 items-center rounded-md ${!(recordingState === RecordingStatus.RECORDING || recordingState === RecordingStatus.UPLOADING || sesh.status.recording === "Recording") ? "bg-main-button hover:bg-main-button-hover text-main-button-text" : "bg-main-button-disabled text-main-button-disabled-text"} px-3 py-2 text-sm font-semibold text-main-button-text shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 h-12 self-center justify-center w-full`}
                                            onClick={() => handleProcessRecording(sesh)}
                                            disabled={recordingState === RecordingStatus.RECORDING || recordingState === RecordingStatus.UPLOADING || sesh.status.recording === "Recording" || processingContext}
                                        >
                                            {!processingContext && <CheckCircleIcon className="-mr-0.5 sm:h-5 sm:w-5 h-8 w-8" aria-hidden="true" />}
                                            {processingContext && <Spinner size='h-5 w-5' timer={false}/>}
                                            {sesh.id !== activeVisit?.id && <div className='block'>Start Processing</div>}
                                            {sesh.id === activeVisit?.id && !(recordingState === RecordingStatus.UPLOADING) && !(recordingState === RecordingStatus.RECORDING ) && <div className='block'>Start Processing</div>}
                                            {sesh.id === activeVisit?.id && !(recordingState === RecordingStatus.UPLOADING) && (recordingState === RecordingStatus.RECORDING ) && <div className="block">Recording...</div>}
                                            {sesh.id === activeVisit?.id && (recordingState === RecordingStatus.UPLOADING) && <div className="block">Uploading...</div>}
                                        </button>}
                                        {(recordingState === RecordingStatus.FAILED_UPLOAD) && sesh.id === activeVisit?.id && <button
                                            type="button"
                                            className={`inline-flex items-center justify-center gap-x-2 rounded-md bg-main-button hover:bg-main-button-hover text-main-button-text px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12 w-60`}
                                            onClick={() => handleReupload()}
                                            disabled={reuploadClick}
                                        >
                                            <div className='flex flex-row justify-center items-center gap-x-2'>
                                                {!reuploadClick && <ExclamationTriangleIcon className='h-5 w-5 text-white-600'/>}
                                                {reuploadClick && <Spinner size='h-5 w-5' timer={false}/>}
                                                <div className='flex flex-col'>
                                                Error uploading recording. <span className='font-normal text-sm'>Click to try again.</span>
                                                </div>
                                            </div>
                                        </button>}
                                        <button
                                            className={`inline-flex gap-x-2 items-center rounded-md ${!(recordingState === RecordingStatus.RECORDING || recordingState === RecordingStatus.UPLOADING || buttonClick) ? "bg-accent-button hover:bg-accent-button-hover" : "bg-main-button-disabled text-main-button-disabled-text"} px-3 py-2 text-sm font-semibold text-accent-button-text shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 h-12 self-center w-full justify-center`}
                                            onClick={() => handleCancelSession(sesh)} disabled={recordingState === RecordingStatus.RECORDING || recordingState === RecordingStatus.UPLOADING || buttonClick}
                                        >
                                            <XMarkIcon className="-mr-0.5 sm:h-5 sm:w-5 h-8 w-8" aria-hidden="true" />
                                            <div className='block'>Delete Visit</div>
                                        </button>
                                    </div>
                                </div>
                                {mediaStream && recordingState === RecordingStatus.RECORDING && sesh.id === activeVisit?.id &&<div className='-mt-[14.5rem] z-[-1]'>
                                    <MicrophoneWave width={isMobile ? '350' : '800'} mediaStream={mediaStream} canWeHearYou={canWeHearYou} setCanWeHearYou={setCanWeHearYou}/>
                                </div>}
                                {canWeHearYou && sesh.id === activeVisit?.id && <div className='flex flex-row gap-x-4 -m-4 w-full justify-center'>
                                    <CheckBadgeIcon className='h-6 w-6 text-green-600' />
                                    We can hear you!
                                </div>}
                                <div className='w-full flex justify-center sm:justify-start mt-auto text-xs'><span className='font-semibold'>Visit ID:</span>{sesh.id}</div>
                            </Disclosure.Panel>
                        </>)}
                    </Disclosure>
                </>))}
            </div>}
        </div>}
        {error && <ErrorAlert error={error} setError={setError}/>}
        {remainder && <Reminder show={remainder ?  true: false} title={remainder} text="" hide={() => setRemainder(undefined)} />}
        {consentPending && !consent && <ConsentDialog />}
    </div>)
}