import config from '../config.json'
import { ChatbotContext } from '../utils/ChatbotUtils';

const url = import.meta.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

export interface Message {
    content: string;
    isUser: boolean;
}

export const sendChatbotMessage = async (
    messages: Message[],
    sessionId: string, 
    context: ChatbotContext,
    jwt: string,
    onMessage: (data: string, conversationId:string) => void,
    onError: () => void,
    onEnd: (conversationId:string) => void, 
    conversationId:string,
    nodeId?: string,
) => {

    try{
        const response = await fetch(url + '/chatbot_message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({sessionId, messages, context, nodeId})  // Send data as JSON
        });

        if (!response.ok) {
            onError();
            return;
        }
    
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
    
        if (reader) {
            while (true) {
                const { done, value } = await reader.read();
                if(value){
                    const chunk = decoder.decode(value);
                    const events = chunk.split("[SEP]");
                    onMessage(events[Math.max(events.length-2,0)].replace("data: [END]\n\n", "").replace("data: ", ""), conversationId);
                }
                if (done) {
                    onEnd(conversationId);
                    break
                };
            }
        }
    }
    catch (error)
    {
        onError();
    }
};