import { SignIn } from "@clerk/clerk-react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";

export default function SignInPage() {

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      const button = document.querySelector('.cl-socialButtonsBlockButton__apple') as HTMLButtonElement;
      if (button) {
        button.style.display = 'none';
        observer.disconnect(); // Stop observing after the button is hidden
      }
      const grid = document.querySelector('.cl-socialButtons') as HTMLDivElement;
      if(grid){
        grid.style.display = 'flex';
      }
    });

    // Start observing the body for changes in children
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);
  
  return <div className="flex flex-col h-screen w-screen items-center justify-center">
      <SignIn signUpUrl="/register" signUpForceRedirectUrl={'/scribe'} appearance={{
        layout:{
          socialButtonsPlacement: 'top',
          socialButtonsVariant:"blockButton"
        }
      }}/>
      <div className="flex flex-row mt-4 border border-gray-100 rounded-lg mx-auto px-4 py-2 bg-yellow-100 gap-x-4 w-[25rem] items-center text-sm shadow-xl">
        <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600"/>
        Check spam folder if you don't see the email in your inbox.
      </div>
  </div>
}