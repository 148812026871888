import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { shareTemplate } from '../../ServerActions'
import { useAuth } from '@clerk/clerk-react'
import { ShareIcon } from '@heroicons/react/24/solid'
import { Spinner } from '../../utils/Spinner'
import va from '@vercel/analytics';

interface TemplateShareModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  templateToShare: string|undefined
  setTemplateToShare: (template: string | undefined) => void
}

export default function TemplateShareModal({ open, setOpen, templateToShare, setTemplateToShare }: TemplateShareModalProps) {
  const [loadingShare, setLoadingShare] = useState(true)
  const { getToken } = useAuth()
  const [shareURL, setShareURL] = useState<string | undefined>()
  const [error, setError] = useState<string | undefined>(undefined)
  const [copySuccess, setCopySuccess] = useState(false)

  useEffect(() => {
    const getTemplateShare = async () => {
      if(!templateToShare || !open) return
      setLoadingShare(true)
      let access_key_response = await shareTemplate(templateToShare,  await getToken({template:"supabase"}) ?? "")
      let access_key = access_key_response['access_key']
      const tempShareUrl = `https://app.vetrec.io/templateBuilder?templateId=${templateToShare.split("_")[1]}&share_access_key=${access_key}`
      setShareURL(tempShareUrl)
      await handleCopy(tempShareUrl)
      setLoadingShare(false)
    }

    try{
      getTemplateShare()
    }
    catch(e){
      setError("An error occurred while trying to share the template. Please try again later.")
      setLoadingShare(false)
    }
  },[templateToShare, open])

  const handleClose = () => {
    setError(undefined)
    setCopySuccess(false)
    setLoadingShare(false)
    setTemplateToShare(undefined)
    setOpen(false)
  }

  async function handleCopy(link?:string): Promise<void> {
    if(!shareURL || !templateToShare) return
    let text = new ClipboardItem({
      'text/plain': new Blob([link ?? shareURL], { type: 'text/plain' })
    });
    await navigator.clipboard.write([text]);
    setCopySuccess(true)
    va.track("TemplateShare_Copy", {templateId: templateToShare})
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <ShareIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                  Share Template
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                      Share the link with the user you want to have access to the template. For security purposes, the link will expire in 24 hour. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        {loadingShare && <div className="mt-5 flex justify-center items-center">
                          <Spinner size='h-5 w-5' timer={false}/>
                        </div>}
                        {error && <div className='w-full truncate px-2 bg-red-100 text-red-900 rounded-md py-2 mt-4 text-sm'>
                          {error}
                        </div>}
                        {shareURL && <div className='w-full truncate px-2 bg-gray-300 text-gray-900 rounded-md py-2 mt-4 text-sm cursor-pointer hover:text-gray-500 hover:bg-gray-200' onClick={()=> handleCopy()}>
                          {shareURL}
                        </div>}
                        {copySuccess && <div className='w-full truncate px-2 bg-green-100 text-green-900 rounded-md py-2 mt-4 text-sm'>
                          Copied to clipboard!
                        </div>}
                        <div className="mt-5 sm:mt-6 flex flex-row gap-x-2">
                          <button
                                type="button"
                                className="inline-flex w-full justify-center items-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10"
                                onClick={() => handleCopy()}
                            >
                                Copy
                            </button>
                            <button
                                type="button"
                                className="inline-flex w-full justify-center items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10"
                                onClick={() => handleClose()}
                            >
                                Close
                            </button>
                        </div>
                    </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}