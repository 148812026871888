import { type FC, useRef, useMemo, useState, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { EntryType, SectionTypes, TemplatePermissions } from './TemplateUtils'
import { Disclosure, Switch } from '@headlessui/react'
import { ChevronUpIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { Entry } from './Entry'
import { useTemplate } from './TemplateProvider'
import SectionTypePicker from './sectionTypePicker'
import InputTooltip from './InputTooltip'
import { InformationCircleIcon, WrenchIcon } from '@heroicons/react/24/solid'
import { improveInstructions } from '../../ServerActions'
import { useAuth } from '@clerk/clerk-react'
import { InstructionsInput } from './InstructionsInput'

interface SectionProps {
    sectionKey:string
    type: SectionTypes
    name: string|undefined
    emptyName?: boolean|false
    entries:EntryType[]
    description?:string|undefined
    defaultValue?:string|undefined
    hardcodedValue?:string|undefined
    emptyHardcodedValue?: boolean|false
    sectionDataTourId?:string|undefined
    entryDataTourId?:string|undefined
    raiseSectionTypePickerZIndex?:boolean
    raiseEntryTypePickerZIndex?:boolean
    ignoreSectionTypeUpdate?:boolean
    ignoreEntryTypeUpdate?:boolean
    shouldExpandInstructions?:boolean
    shouldExpandDefaults?:boolean
}

export const AddEntryArea: FC = () => {
  const {handleEntryOver} = useTemplate()
  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop(() => ({
    accept: [ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION, ItemTypes.RENDERED_SECTION, ItemTypes.SECTION, ItemTypes.TIMELINE, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.VITALS, ItemTypes.FELINE_DENTAL_CHART, ItemTypes.CANINE_DENTAL_CHART, ItemTypes.SPACES],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(){
      handleEntryOver(undefined)
    }
  }))

  drop(ref)

  return (
  <div ref={ref} className='border border-dashed border-gray-300 rounded-md p-4 text-center'>
    Add Entry to section
  </div>
  )
}

export const Section: FC<SectionProps> = ({sectionKey, name, emptyName, entries, type, description, defaultValue, hardcodedValue, emptyHardcodedValue, sectionDataTourId, entryDataTourId, raiseSectionTypePickerZIndex = false, raiseEntryTypePickerZIndex = false, ignoreSectionTypeUpdate = false, ignoreEntryTypeUpdate = false, shouldExpandInstructions = false, shouldExpandDefaults = false}) => {
  const {updateSectionName, updateSectionEmptyName, updateSectionDescription, updateSectionDefaultValue, deleteSection, updateSectionType, swapSectionsByKeys, handleSectionOver, sectionHoverIndex,entryHoverIndex, sectionDragged, entryDragged, updateSectionHardcodedValue, updateSectionEmptyHardcodedValue, handleSubSectionOver, hasPermission, templateType} = useTemplate()
  const ref = useRef<HTMLDivElement>(null)
  const { getToken } = useAuth();
  const [isImproving, setIsImproving] = useState(false)
  const [advancedModeVisible, setAdvancedModeVisible] = useState(false)

  useEffect(() => {
    if (!!description) {
      setAdvancedModeVisible(true)      
    }
  }, [description])

  const handleImprove = async (additionalContext:string) => {
    setIsImproving(true)
    
    try {
      const response = await improveInstructions(name ?? "", type ?? "", description ?? "", additionalContext, await getToken({ template: "supabase" }) ?? "", templateType ?? "")
      updateSectionDescription(sectionKey, response.improved_instructions)
    } finally {
      setIsImproving(false)
    }
  }

  const [{isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.ENTRY, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.OWNER_DISCUSSION, ItemTypes.RENDERED_SECTION, ItemTypes.SECTION, ItemTypes.TIMELINE, ItemTypes.CANINE_DENTAL_CHART, ItemTypes.FELINE_DENTAL_CHART, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.VITALS, ItemTypes.SPACES],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item:any){
      if (!ref.current) {
        return
      }
      if (item['sectionKey'] === sectionKey) {
        return
      }

      handleSectionOver(sectionKey)   
      
      handleSubSectionOver(false)

      if(item['sectionKey'] && item['name'] === ItemTypes.RENDERED_SECTION){
        swapSectionsByKeys(sectionKey, item['sectionKey'])
      }
    }
  }))

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RENDERED_SECTION,
    item: () => {
      return { sectionKey, "name":ItemTypes.RENDERED_SECTION}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const handleSectionTypeUpdate = (type:SectionTypes) => {
    if (ignoreSectionTypeUpdate) return
    updateSectionType(sectionKey, type)
  }


  const isSectionTitleEditDisabled = useMemo(() => [SectionTypes.DIAGRAM, SectionTypes.TIMELINE].includes(type) || !hasPermission(TemplatePermissions.EDIT_SECTION_NAMES), [type, hasPermission])

  const isSectionBeta = useMemo(() => [SectionTypes.TIMELINE].includes(type), [type])

  if(hasPermission(TemplatePermissions.EDIT_ORDER) && type !== SectionTypes.GENERAL_INSTRUCTIONS) drag(drop(ref))

  return (<>
    {sectionHoverIndex === sectionKey && sectionDragged && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
    <div data-tour-id={sectionDataTourId} ref={ref} className={`w-full cursor-move rounded-md ${isDragging ? "border-2 border-blue-600 rounded-md" : ""}`} data-testid="section text-gray-500">
      <div className={`flex flex-col gap-y-2 border border-gray-200 p-4 rounded-md ${isDragging ? "opacity-0" : "opacity-1"}`}>
        <div className='flex flex-row items-center justify-between'>
          {![SectionTypes.SPACES, SectionTypes.GENERAL_INSTRUCTIONS].includes(type)  && <input 
            data-tour-id={sectionDataTourId ? `${sectionDataTourId}-name-input` : undefined} 
            className={`nodrag border-t-0 border-l-0 border-r-0 border-gray-300 focus:rounded-md h-8 
              ${(!hasPermission(TemplatePermissions.EDIT_SECTION_NAMES) || emptyName) ? "text-gray-400":"text-gray-900"} 
              ${emptyName ? "bg-gray-200 rounded-sm" : ""} transition-colors duration-200 ease-in-out
              `} 
              
            placeholder={!emptyName ? 'Section Name' : ''} 
            value={emptyName ? '' : name} 
            onChange={(event) => updateSectionName(sectionKey, event.target.value)} 
            draggable={true} 
            onDragStart={event => event.preventDefault()} 
            disabled={isSectionTitleEditDisabled || emptyName}
          />}
          {type === SectionTypes.GENERAL_INSTRUCTIONS && <div className='flex flex-row gap-x-2 items-center'>
            <span className="inline-flex items-center px-3 py-1.5 text-md font-medium text-black bg-gray-100 rounded-md">
              General Instructions
            </span>
          </div>}
          {type === SectionTypes.SPACES && <div className='flex flex-row gap-x-2 items-center'>
            <span className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-600 bg-gray-100 rounded-md">
              Spaces
            </span>
          </div>}
          <div className='flex flex-row gap-x-2 items-center'>
            {isSectionBeta && <span className="inline-flex items-center px-2 py-1 text-xs font-bold text-white bg-blue-800 rounded-md">
              Beta
            </span>}
            {type === SectionTypes.HARDCODED &&(
                <div className="flex items-center gap-x-2 ml-4">
                  <span className="text-sm text-gray-600">Empty name</span>
                  <Switch
                    checked={emptyName}
                    onChange={(checked) => {
                      updateSectionEmptyName(sectionKey, checked)
                      updateSectionName(sectionKey, checked ? `${sectionKey}_vetrec_empty` : "")
                    }}
                    className={`${
                      emptyName ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex h-4 w-8 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                    disabled={!hasPermission(TemplatePermissions.EDIT_SECTION_NAMES)}
                  >
                    <span className="sr-only">Empty Name</span>
                    <span
                      className={`${
                        emptyName ? 'translate-x-4' : 'translate-x-1'
                      } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition-transform`}
                    />
                  </Switch>
                </div>
              )}
            {[SectionTypes.NORMAL, SectionTypes.COLLECTION, SectionTypes.LIST].includes(type) && !advancedModeVisible && (
                <button
                type="button"
                className={`inline-flex items-center gap-x-1 text-sm ${advancedModeVisible ? 'text-blue-600 bg-blue-100' : 'text-gray-600 hover:text-blue-600 hover:bg-blue-100'} transition-colors mr-2 py-2 px-3 rounded-md`}
                onClick={() => {
                  setAdvancedModeVisible(!advancedModeVisible)
                }}
                >
                <WrenchIcon className="h-4 w-4" />
                Advanced
                </button>
            )}
            <div className="flex flex-row gap-x-1">
              {![SectionTypes.SPACES, SectionTypes.GENERAL_INSTRUCTIONS].includes(type) && <SectionTypePicker sectionType={type} setSectionType={handleSectionTypeUpdate} disabled={isSectionTitleEditDisabled} dataTourId={sectionDataTourId ? `${sectionDataTourId}-type-selection` : undefined} raiseZIndex={raiseSectionTypePickerZIndex} />}
            </div>
            {hasPermission(TemplatePermissions.EDIT_ORDER) && <div className="cursor-pointer" onClick={() => deleteSection(sectionKey)}>
              <XCircleIcon className='w-5 h-5 text-blue-600 hover:text-blue-500' />
            </div>}
          </div>
        </div>
        {[SectionTypes.NUMBER, SectionTypes.PARAGRAPH, SectionTypes.SIMPLE_LIST].includes(type) && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              <div className="flex-1">
                <InstructionsInput
                  sectionEntryName={name ?? ""}
                  value={description ?? ''}
                  defaultValue={defaultValue ?? ''}
                  onChange={(value) => updateSectionDescription(sectionKey, value)}
                  onImprove={handleImprove}
                  isImproving={isImproving}
                  disabled={!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS)}
                  placeholder='(Optional) Provide instructions to VetRec. e.g. Include dosage'
                  className={hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS) ? "text-gray-900" : "text-gray-400"}
                />
              </div>
            </div>
            <div className='flex flex-row gap-x-2 items-center justify-start'>
              Default:
              <InputTooltip direction="right" content={<div>
                <div className='font-semibold'>Default values</div>
                <ul className='list-disc list-inside'>
                  <li>Used when information for the entry is not provided in the session.</li>
                  <li>Format like you expect the information to be presented</li>
                </ul>
              </div>}>
                <textarea className={`${hasPermission(TemplatePermissions.EDIT_DEFAULTS) ? " text-gray-900" : "text-gray-400"} nodrag rounded-md border-gray-300 w-full resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar`} placeholder='(Optional) Provide default values like normals' value={defaultValue} onChange={(event) => updateSectionDefaultValue(sectionKey, event.target.value)} draggable={true} onDragStart={event => event.preventDefault()} disabled={!hasPermission(TemplatePermissions.EDIT_DEFAULTS)}/>
              </InputTooltip>
            </div>
        </div>}
        {[SectionTypes.LIST, SectionTypes.NORMAL, SectionTypes.COLLECTION, SectionTypes.TIMELINE].includes(type) && <Disclosure>
          {({ open }) => (
            <>
              {type === SectionTypes.TIMELINE && <div className="rounded-lg bg-gray-50 px-4 shadow min-h-12 grow flex items-center justify-center w-full my-2">
                <div className="flex grow items-center justify-between">
                  <div className="flex items-center gap-x-4">
                    <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    <div className="text-sm text-gray-900">
                      The below entries will be populated for each date they pertain to. 
                    </div>
                  </div>
                </div>
              </div>}
              {advancedModeVisible && [SectionTypes.NORMAL, SectionTypes.COLLECTION, SectionTypes.LIST].includes(type) && <div className='w-full flex flex-row gap-x-2 items-center justify-center mb-2'>
                <div className="flex-1">
                  <InstructionsInput
                    sectionEntryName={name ?? ""}
                    value={description ?? ''}
                    defaultValue={defaultValue ?? ''}
                    onChange={(value) => updateSectionDescription(sectionKey, value)}
                    onImprove={handleImprove}
                    isImproving={isImproving}
                    disabled={!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS)}
                    placeholder='(Optional) Provide general instructions for this section. E.g. Write in full sentences.'
                    className={hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS) ? "text-gray-900" : "text-gray-400"}
                    onDelete={() => {
                      updateSectionDescription(sectionKey, "")
                      setAdvancedModeVisible(false)
                    }}
                  />
                </div>
              </div>}
              <Disclosure.Button data-tour-id={sectionDataTourId ? `${sectionDataTourId}-entries-button` : undefined} className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                <span>Entries</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-0 pb-2 pt-0 text-sm text-gray-500 flex flex-col gap-y-2">
                {entries.map((entry, index) => (
                  <Entry 
                    key={entry.entryKey} 
                    name={entry.name} 
                    emptyName={entry.emptyName}
                    description={entry.description} 
                    defaultValue={entry.defaultValue} 
                    entryKey={entry.entryKey} 
                    type={entry.type} 
                    sectionKey={sectionKey} 
                    subEntries={entry.subEntries} 
                    hardcodedValue={entry.hardcodedValue}
                    emptyHardcodedValue={entry.emptyHardcodedValue}
                    dataTourId={(sectionDataTourId && index === 0) ? `${entryDataTourId}` : undefined}
                    raiseZIndex={raiseEntryTypePickerZIndex && index === 0}
                    ignoreEntryTypeUpdate={ignoreEntryTypeUpdate}
                    expandInstructions={shouldExpandInstructions && index === 0}
                    expandDefaults={shouldExpandDefaults && index === 0}
                  />
                ))}
                {sectionHoverIndex === sectionKey && entryHoverIndex === undefined && entryDragged && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
                {entries.length > 0 && sectionHoverIndex === sectionKey && entryDragged && <AddEntryArea />}
                {entries.length === 0 && <div className='flex flex-col items-center justify-center h-full mt-4'>
                  <div className='flex flex-col items-center gap-y-2'>
                    <div className='text-gray-500 text-md'>Drag and drop an entry</div>
                      <div className='text-gray-500 text-sm'>
                        Choose an empty entry or one of the pre-built options.
                      </div>
                    </div>
                  </div>
                }
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>}
        {type === SectionTypes.HARDCODED && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              Value:
              <InputTooltip direction="right" content={<div className='flex flex-col'>
                <div className=''>This value will be added verbatim into the final set of notes.</div>
              </div>}>
                <textarea 
                  className={`${(!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS) || emptyHardcodedValue) ? "bg-gray-200" : ""} nodrag rounded-md border-gray-300 w-full resize-none h-10 focus:h-32 transition-height duration-300 ease-in-out thin-scrollbar`}
                  placeholder={emptyHardcodedValue ? "This will not be added to the final notes" : "Value will be inserted into the final notes verbatim."} 
                  value={hardcodedValue} 
                  onChange={(event) => updateSectionHardcodedValue(sectionKey, event.target.value)} 
                  draggable={true} 
                  onDragStart={event => event.preventDefault()} 
                  disabled={!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS) || emptyHardcodedValue}
                />
              </InputTooltip>
                <div className='flex items-center gap-x-2 ml-2'>
                <span className="text-sm text-gray-600 whitespace-nowrap">Empty Value</span>
                <Switch
                  checked={emptyHardcodedValue}
                  onChange={(checked) => {
                    updateSectionEmptyHardcodedValue(sectionKey, checked)
                    if(checked){
                      updateSectionHardcodedValue(sectionKey, " ")
                    }
                  }}
                  className={`${
                    emptyHardcodedValue ? 'bg-blue-600' : 'bg-gray-200'
                  } relative inline-flex h-4 w-8 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                  disabled={!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS)}
                >
                  <span className="sr-only">Empty Value</span>
                  <span
                    className={`${
                      emptyHardcodedValue ? 'translate-x-4' : 'translate-x-1'
                    } inline-block h-2.5 w-2.5 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
              </div>
            </div>
          </div>}
        {type === SectionTypes.SPACES && <div className='flex flex-col gap-y-2'>
            <div className='w-full flex flex-row gap-x-2 items-center justify-start'>
              <span className="text-sm text-gray-600 whitespace-nowrap">Height (lines):</span>
                <div className="flex items-center">
                  <input 
                    type="number"
                    className={`
                      ${!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS) ? "bg-gray-200" : ""}
                      nodrag rounded-md border-gray-300 w-20 h-8 px-2
                      focus:ring-blue-500 focus:border-blue-500
                    `}
                    placeholder="1" 
                    value={hardcodedValue || "1"} 
                    onChange={(event) => updateSectionHardcodedValue(sectionKey, event.target.value)} 
                    disabled={!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS)}
                  />
                </div>
            </div>
          </div>}
          {type === SectionTypes.GENERAL_INSTRUCTIONS && (<div className='w-full flex flex-row gap-x-2 items-center justify-start mt-2'>
            <div className="flex-1">
              <InstructionsInput
                sectionEntryName={name ?? ""}
                value={description ?? ''}
                defaultValue={defaultValue ?? ''}
                onChange={(value) => updateSectionDescription(sectionKey, value)}
                disabled={!hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS)}
                placeholder='(Optional) Provide general instructions for this template. E.g. Use first person.'
                className={hasPermission(TemplatePermissions.EDIT_INSTRUCTIONS) ? "text-gray-900" : "text-gray-400"}
              />
            </div>
          </div>)}
      </div>
    </div>
  </>)
}
